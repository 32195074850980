import { GetFileType, transformClass } from '@/utils/utils.js'
import GetRecallType from '@/utils/recallType'
import serialize from '@/utils/serialize'
// import xss from '@/utils/xss'
import DOMPurify from 'dompurify' // 过滤xss攻击的
// import { getObjArr } from '@/utils/localstorage'
export default {
  name: 'Print',
  components: {

  },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },

  },
  data() {
    return {
      dialogMsg: '', // 消息信息
      bodyHtml: {}, // 邮件信息
      attachments: [], // 附件
      flags: 0, // 星标
      form: '',
      to_s: [],
      cc_s: [],
      bcc_s: [],
      bodys: {},
      isHtml: true,
      replyValue: '',
      isUnfold: true,
      rows: 20,
      sentMailStatus: '', // 发件箱的发送状态
      sentMailStatusList: '', // 发件箱审核人的列表
      isReplyAll: false,
      isPrintMail: false, // 是否是邮件打印'
      firstMail: true, // 是第一封邮件
      lastMail: true, // 是最后一封邮件
      mailInfo: {}, // 邮件的基本信息
      // userConfig: '',
      tabList: [],
      auth_authority: 9, // 授权邮箱权限
      attaImg: [], // 要展示的附件附片
      fileListDisk: [], // 保存网盘选中的文件
      showDialogDisk: false, // 是否打开保存网盘
      isRouter: false,
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      contact_id: '',
      idEdit: false,
      // flowSrc: '/#/view-mail',
      showMemberInfo: false,
      loading: true,
      msgContent: {},
      audit_expired: false, // 邮件审核过期、撤回页面
      audit_expired_html: '', // 邮件审核过期、撤回页面 内容
      showMailbzDialog: false, // 邮件备注弹窗
      bzInfo: '', // 邮件备注信息
      mail_expired: '', // 当前邮件是否过期
      isMettingMail: false,
      isOrganizer: false,
      MettingCardData: null,
    }
  },
  computed: {
  },
  watch: {
  },
  beforeCreate() {
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$myData.uriDatas, this.$myData.uriModule, 'this.$myData.uriDatas')
      var _this = this
      _this.isPrintMail = this.$myData.uriModule
      _this.mailInfo = _this.$myData.uriDatas
      _this.showMsg_bodys_document()
      _this.auth_authority = this.$store.state.app.auth_authority // 授权邮箱权限
    })
  },
  methods: {
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
    // 邮箱名显示
    showName(i) {
      const type = this.$store.state.app.userGetConfig.show_tag_type
      const userInfo = [this.$store.state.app.user_mail_address, ...this.$store.state.app.user_permissions.all_alias]
      let name = ''
      if (this.isUnfold) {
        name = userInfo.indexOf(i[0]) !== -1 ? this.$t('message.view.me') : i[1]
      } else {
        if (type === 'long' && i[2] !== '') {
          name = userInfo.indexOf(i[0]) !== -1 ? this.$t('message.view.me') : i[1] + '[' + i[2] + ']'
        } else {
          name = userInfo.indexOf(i[0]) !== -1 ? this.$t('message.view.me') : i[1] + '<' + i[0] + '>'
        }
      }
      return name
    },
    getRecallType(name) {
      const type = new GetRecallType(name).toValue()
      return type
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 是否能够预览
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    // 获取读信页面详情
    getMail(istab) {
      const need_send_info = this.mailInfo.need_send_info ? this.mailInfo.need_send_info : 1
      const orderby = this.mailInfo.orderby ? this.mailInfo.orderby : 'uid'
      const sequence = this.mailInfo.sequence ? this.mailInfo.sequence : 'DESC'
      const type = this.mailInfo.type ? this.mailInfo.type : 'folder'
      const uid = this.mailInfo.uid
      const uidvalidity = this.mailInfo.uidvalidity
      const uv = this.mailInfo.uv
      const scopes = this.mailInfo.scopes
      const other = this.mailInfo.other
      const words = this.mailInfo.words
      const search_id = this.mailInfo.search_id
      const filter_query = this.mailInfo.filter_query
      const topping = this.mailInfo.topping
      const mbox = this.mailInfo.mbox
      const req = {
        'module': 'mail',
        'require': {
          action: 'getMail',
          filter_query: filter_query,
          need_body: 1,
          need_info: 1,
          need_position: 1,
          need_send_info: need_send_info,
          orderby: orderby,
          sequence: sequence,
          type: type,
          uid: uid,
          uidvalidity: uidvalidity,
          uv: uv,
          mail_psw: this.mail_psw,
          scopes: scopes,
          other: other,
          words: words,
          search_id: search_id,
          topping: topping,
          mbox: mbox,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        if (this.fromType === 'attachment') {
          // 如果是从附件管理进来，则需要给新标签重新赋值
          const readMail_data = {
            need_send_info: 0, // 是否返回邮件追踪信息 (只有发件箱有)1返回 0不返回
            orderby: res.result.mailData.orderby, // 邮件在当前列表的排序方式
            sequence: res.result.mailData.sequence, // 邮件在当前列表的排序顺序desc、asc
            type: res.result.mailData.type, // 查询类型，文件夹模式folder，搜索search
            scopes: res.result.mailData.scopes, // 高级搜索范围, subject(主题),body(正文),from(发件人),to(收件人),attachment(附件名), [{"subject":"fff"},{"body":"aaa"},{"time":"2023-03-01 , 2023-04-26"},{"from":"ff"},{"to":""},{"attachment":""}]
            words: res.result.mailData.words, // 搜索关键字
            search_id: res.result.mailData.search_id, // 搜索id，用于进行二次搜索
            mbox: this.$store.state.tagsView.tabMenu.mbox,
            flags: res.result.mailData.flags,
            mail_psw: res.result.mailData.mail_psw,
            topping: res.result.mailData.topping,
            readed_destory: res.result.mailData.readed_destory,
            is_expired: res.result.mailData.is_expired,
            tag: res.result.mailData.tag,
          }
          const readMail = this.readMailReqData
          this.mailInfo = {
            ...readMail,
            ...readMail_data,
          }
        }
        // 如果是过期邮件 还需要加邮件审核撤回的
        this.mail_expired = ''
        res.result.from === 0 ? this.firstMail = true : this.firstMail = false
        res.result.from + 1 === Number(res.result.count) ? this.lastMail = true : this.lastMail = false
        this.bodyHtml = res.result.mailData
        // 判断邮件是否过期

        // 如果是过期邮件，自动跳转上一封或者下一封
        // if (res.result.is_expired) {
        //   // console.log('过期邮件')
        //   // this.closeDialog()
        //   // return false
        // }
        if (res.result.mailData.bodys && res.result.mailData.bodys.length) {
          res.result.mailData.bodys[0].substring(0, 1) === 'h' ? this.isHtml = true : this.isHtml = false
        }
        this.showMemberInfo = true
        console.log(res.result, 'res.result')
        if (this.isHtml) {
          // 返回的是html格式
          this.bodys = res.result.mailData.bodys.length ? DOMPurify.sanitize(res.result.mailData.bodys[0].substring(1)) : ''
        } else {
          // 返回的是text格式
          this.bodys = res.result.mailData.bodys.length ? res.result.mailData.bodys[0].substring(1).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '\n<br/>').replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') : ''
        }

        this.loading = false
        // 显示
        this.showBody()
        // 拦截a链接
        // this.listenLink()
        if (res.result.mailData.readed_destory === '1') {
          this.mailInfo.readed_destory = true
          // 标记是否是销毁邮件
          // this.$store.commit('mail/ISDESTROY', true)
        }

        // 处理会议邀请信---->开始
        if (res.result.mailData.cal_data) {
          this.isMettingMail = true
          this.MettingCardData = res.result.mailData.cal_data
          this.isOrganizer = res.result.mailData.cal_data.is_organizer === 1
        } else {
          this.isMettingMail = false
          this.isOrganizer = false
          this.MettingCardData = null
        }
        // ------>end

        this.mail_psw = ''// 清空此次密码
        this.to_s = res.result.mailData.to_s
        this.cc_s = res.result.mailData.cc_s
        this.bcc_s = res.result.mailData.bcc_s
        this.attachments = res.result.mailData.attachments
        this.form = res.result.mailData.from_namemei
        this.flags = res.result.mailData.flags.charAt(3)
        this.tabList = res.result.mailData.tag

        if (this.mailInfo.mbox === 'Sent' && this.$store.state.app.auth_authority !== 0 && this.$store.state.app.auth_authority !== 1) {
          this.sentMailStatus = this.getRecallType(res.result.send_info.label)
          if (res.result.send_info.label === 'Auditing' || res.result.send_info.label === 'HasAudited') {
            let list = ''
            res.result.send_info.audit_list.map((item, index) => {
              if (res.result.send_info.audit_list.length - 1 === index) {
                list = list + item.audit_user_name + '<' + item.audit_user_mail + '>'
              } else {
                list = list + item.audit_user_name + '<' + item.audit_user_mail + '>;'
              }
            })
            this.sentMailStatusList = list
          }
        }
        this.bodyHtml.mbox = transformClass.mbox(this.bodyHtml.uidvalidity, this.$myData.mbox_list)
        const str = this.bodyHtml.flags.split('')
        if (str[1] === '0') {
          str[1] = 1
          this.bodyHtml.flags = str.join('')
          /** 邮箱文件夹未读数量减1 */
          const mbox_unseen = Number(this.$store.state.tagsView.menu_unseen[this.bodyHtml.mbox])
          this.$store.state.tagsView.menu_unseen[this.bodyHtml.mbox] = mbox_unseen - 1
          this.$store.state.tagsView.mail_list.forEach(item => {
            if (item.uid === this.bodyHtml.uid && item.uidvalidity === this.bodyHtml.uidvalidity) {
              item.flags = str.join('')
            }
          })
        }
        // 是否显示内嵌图片
        const domparser = new DOMParser()
        var doc = domparser.parseFromString(this.bodys, 'text/html')
        const arr = doc.body.getElementsByTagName('img')
        this.show_always_img(arr)
        // 是否显示附件图片
        if (this.$store.state.app.userGetConfig.show_att_image === 'yes') {
          this.show_attat_img()
        }
        // 回传父组件
        this.changeMaiModulelData()
      })
    },
    // 显示内嵌图片
    show_always_img(arr) {
      if (arr.length !== 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.width = 'auto'
        }
      }
      let haveimg
      if (arr.length === 0) {
        haveimg = false
      } else {
        haveimg = true
      }
      if (haveimg) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.display = 'block'
          arr[i].style.width = 'auto'
        }
        if (this.isHtml) {
          const imgDiv = window.document.createElement('div')
          // 内嵌图片示例
          // this.bodys = JSON.parse(JSON.stringify('<html><body>\r\n<div background="cid:_Foxmail.1@e6a26adb-7777-e5dc-74b5-5cff85f32aa8">\r\nsss\r\n<hr />\r\n<img src="cid:_Foxmail.1@e6a26adb-7777-e5dc-74b5-5cff85f32aa8" /><div background="cid:_Foxmail.1@66666666666"></div>\r\n</div>\r\n\r\n</body>\r\n</html>'))
          imgDiv.innerHTML = this.bodys
          const divbox = imgDiv.getElementsByTagName('*')
          if (this.$store.state.app.userGetConfig.show_image === 'always' || this.$store.state.app.userGetConfig.show_image === 'contact') {
            for (let i = 0; i < divbox.length; i++) {
            // 获取所有的background
              const back = divbox[i].getAttribute('background') || ''
              if (back !== '' && back.substring(0, 4) === 'cid:') {
                const new_back = this.showImg(back.substring(5))
                divbox[i].setAttribute('background', new_back)
              }
            }
            // 获取所有的imgs
            const imgs = imgDiv.getElementsByTagName('img')
            for (let i = 0; i < imgs.length; i++) {
              const img = imgs[i]
              // 图片不能太大, 变小后可以点击还原
              img.style.maxWidth = '95%'
              const url = img.src || ''
              if (url !== '' && img.src.substring(0, 4) === 'cid:') {
                const new_url = this.showImg(img.src.substring(5))
                img.src = new_url
              }

              // 还需要判断发件人是否在通讯录里
              if (this.$store.state.app.userGetConfig.show_image === 'contact' && !this.bodyHtml.show_in_images) {
                if (url !== '') {
                  img.src = ''
                  img.style.display = 'none'
                }
              }

              img.onload = () => {
                this.showBody()
              }
            }
          } else if (this.$store.state.app.userGetConfig.show_image === 'never') {
            // 获取所有的imgs
            const imgs = imgDiv.getElementsByTagName('img')
            for (let i = 0; i < imgs.length; i++) {
              const img = imgs[i]
              const url = img.src || ''
              if (url !== '') {
                img.src = ''
                img.style.display = 'none'
              }
              img.onload = () => {
                this.showBody()
              }
            }
          }
          this.bodys = JSON.parse(JSON.stringify(imgDiv.innerHTML))
          this.showBody()
        }
      } else {
        for (let i = 0; i < arr.length; i++) {
          arr[i].src = ''
          arr[i].style.display = 'none'
        }
      }
    },
    // 显示附件图片
    show_attat_img(mo, tab) {
      this.attaImg = []
      this.attachments.forEach(el => {
        if (this.getFileType(el.name) === 'img') {
          const req = {
            disposition: 'inline',
            encoding: el.encoding,
            mbox: this.mailInfo.mbox,
            module: 'mail_attachment',
            section: el.section,
            uid: this.mailInfo.uid,
            uv: this.mailInfo.uv,
            preview: 1,
          }
          const url = '/nsmail/mail.php/fn.' + this.getFileType(el.name) + '?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
          this.attaImg.push(url)
        }
      })
    },
    // 图片的转换  将内嵌图片cid的转换成邮件可读的图片
    showImg(cid) {
      let url = ''
      this.attachments.forEach(el => {
        if (el.cid.substr(2, el.cid.length - 3) === cid) {
          const req = {
            disposition: 'inline',
            encoding: el.encoding,
            mbox: this.mailInfo.mbox,
            module: 'mail_attachment',
            section: el.section,
            uid: this.mailInfo.uid,
            uv: this.mailInfo.uv,
            preview: 1,
          }
          url = '/nsmail/mail.php/fn.' + this.getFileType(el.name) + '?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
        }
      })
      return url
    },
    // iframe 显示读信
    showMsg_bodys_document() {
      const _this = this
      // 获取正常读信
      _this.getMail()
    },
    showBody() {
      // 给iframe里面送值
      // const viewer = this.msgContent.doc.getElementById('viewer') || this.msgContent.doc.createElement('div')
      // viewer.innerHTML = this.bodys
      // setTimeout(() => {
      this.showMsg_viewer_height()
      // }, 1000)
    },
    // iframe的宽高 每次加载完图片都需要重新调用
    showMsg_viewer_height() {
      const viewIframe = this.$refs['iframe']
      if (viewIframe) {
        const doc = viewIframe.contentWindow.document || '<div></div>'
        // console.log(doc.body.scrollHeight, 'doc.body.scrollHeight')

        setTimeout(() => {
          viewIframe.height = doc.body.scrollHeight < 500 ? 500 : doc.body.scrollHeight
        }, 1000)
        // 拦截a链接
        this.listenLink()
      }
    },
    // 是否展开详情
    Unfold(type) {
      type ? this.isUnfold = false : this.isUnfold = true
    },

    // 拦截 点击a标签的拦截
    async listenLink() {
      // var body = this.msgContent.doc.getElementById('viewer')
      var body = this.msgContent.doc.getElementById('viewer').getElementsByTagName('A')
      for (let index = 0; index < body.length; index++) {
        body[index].addEventListener('click', async(e) => {
          e.preventDefault()
          return
        })
      }
    },
    handVisibleChange(e) {
      if (e) {
        const a = JSON.parse(JSON.stringify(this.tabList))
        this.tabList = []
        this.tabList = a
      }
    },
    timeDispose(dates) {
      if (dates < 10) {
        return '0' + dates
      } else {
        return dates
      }
    },
    // 时间转换
    gettime(item) {
      var getTimes = new Date(parseInt(item) * 1000)
      var monthGet = getTimes.getMonth() + 1
      var time = getTimes.getFullYear() + '/' + this.timeDispose(monthGet) + '/' + this.timeDispose(getTimes.getDate()) + ' ' + this.timeDispose(getTimes.getHours()) + ':' + this.timeDispose(getTimes.getMinutes())
      return time
    },
  },
}
