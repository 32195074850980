import 'core-js'
import 'lib-flexible'
import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import store from './store'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'

import 'normalize.css/normalize.css'
import './styles/index.scss'
import axios from './api/axios-requery.js'
import apiData from './api/api-store.js'
import myApi from './api/myApi.js'
import myState from './api/myState'
import myData from './api/myData'
import './assets/icons'
import myUI from '@/components'
import { i18n } from '@/i18n/index.js'
import md5 from 'js-md5'
import xss from 'xss'
// import { refreshInit } from '@/utils/InitializePage'
import userstream from '@/views/compose/components/global'
// require('@/theme/theme-set.js')
import('element-ui/lib/theme-chalk/index.css')

ElementUI.Dialog.props.closeOnClickModal.default = false // 修改 el-dialog 默认点击遮照为不关闭
Vue.use(ElementUI, { size: 'small', zIndex: 3000, i18n: (key, value) => i18n.t(key, value) })
Vue.prototype.$axios = axios
Vue.prototype.$ajaxRequest = async(uri, reqData) => {
  return new Promise((resolve) => {
    Vue.prototype.$axios.post(uri, { req: JSON.stringify(reqData) }).then((res) => {
      resolve(res)
    }, () => {
      resolve({ res: { status: 'ERROR' }})
    }).catch(() => {
      resolve({ res: { status: 'ERROR' }})
    })
  })
}
window._kglobal_ajaxRequest = Vue.prototype.$ajaxRequest

Vue.prototype.$myConfirm = async(a, b, c, d, e, f) => {
  return new Promise((resolve) => {
    Vue.prototype.$confirm(a, b, c, d, e, f).then(() => {
      resolve('OK')
    }).catch(() => {
      resolve('CANCEL')
    })
  })
}

// 全局数据, 响应式
Vue.prototype.$myState = Vue.observable(myState)
window._kglobal_myState = Vue.prototype.$myState
// 全局 API
Vue.prototype.$myApi = myApi
window._kglobal_myApi = myApi
// 全局数据, 不是响应式
Vue.prototype.$myData = myData
window._kglobal_myData = myData
Vue.prototype.$apiData = apiData
Vue.prototype.$md5 = md5
Vue.prototype.xss = xss
Vue.prototype.userStreamCache = userstream
Vue.use(myUI)
// 点击元素外
Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})
// 字节大小转换
Vue.directive('format-bytes', {
  bind: function(el, binding) {
    const bytes = binding.value
    const decimals = binding.arg || 2
    if (bytes === 0) {
      el.innerHTML = '0 Bytes'
      return
    }
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    const formattedBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    el.innerHTML = formattedBytes
  },
})
Vue.directive('debounce', {
  inserted: function(el, binding) {
    let timeout
    el.addEventListener('input', () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        binding.value()
      }, binding.arg || 500)
    })
  },
})
Vue.directive('title', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function(el, binding) {
    const { value } = binding
    el.addEventListener('mouseenter', function(event) {
      if (el.scrollWidth > el.clientWidth) {
        el.title = value
      } else {
        el.title = ''
      }
    })
  },
})
Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 200)// 我这里设置的是2000毫秒也就是2秒
    })
  },
})
Vue.config.productionTip = false

window.vm = new Vue({
  // router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
