<template>
  <div class="tracking_box noBorder trunBottom">
    <p class="tit">
      <el-input
        v-model="search"
        :placeholder="$t('message.track.input_mail_sub')"
        clearable
        class="input-with-select"
        @clear="searchFun"
        @keydown.enter.native="searchFun"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchFun"
        />
      </el-input>
      <span>{{ $t('message.track.mail_track') }}</span>
    </p>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      :height="trackingTabHeight"
      class="table"
    >
      <el-table-column
        prop="envelope_date"
        :label="$t('message.track.time')"
        width="260"
      />

      <el-table-column
        prop=""
        :label="$t('message.track.to')"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            :open-delay="300"
            placement="right-start"
          >
            <div
              slot="content"
              style="max-width:500px"
            >{{ scope.row.to_mail }}</div>

            <span class="one-line-ellipsis"><span>{{ scope.row.to_mail }}</span></span>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column
        prop="subject"
        :label="$t('message.track.sub')"
      >
        <template slot-scope="scope">
          <p class="one-line-ellipsis">
            <el-tooltip
              class="item"
              effect="light"
              :open-delay="300"
              placement="right-start"
            >
              <div
                slot="content"
                style="max-width:500px"
              >{{ scope.row.subject ? scope.row.subject : $t('message.list.no_subject') }}</div>
              <span>{{ scope.row.subject ? scope.row.subject : $t('message.list.no_subject') }}</span>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        :label="$t('message.track.mail_status')"
      >
        <template slot-scope="scope">
          <p class="one-line-ellipsis">
            <el-tooltip
              class="item"
              effect="light"
              placement="right-start"
            >
              <div
                v-if="scope.row.status === 'Auditing' || scope.row.status === 'HasAudited'"
                slot="content"
                style="max-width:500px"
              >{{ scope.row.AuditingText }}</div>
              <div
                v-else
                slot="content"
                style="max-width:500px"
              >{{ $t(`message.mailstatus.${scope.row.status}`) }}</div>
              <span
                v-if="scope.row.status === 'Auditing' || scope.row.status === 'HasAudited'"
                style="color:#999"
              >{{ scope.row.AuditingText }}</span>
              <span
                v-else
                :style="{'color':scope.row.status==='succeed'?'#24b300':(scope.row.status==='insend' || scope.row.status==='AuditCallbacked' || scope.row.status==='CallBack' || scope.row.status==='CallBack' || scope.row.status==='Auditing' || scope.row.status==='HasAudited') ? '#222':'#EB0000'}"
              >{{ $t(`message.mailstatus.${scope.row.status}`) }}</span>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('message.track.action')"
        width="100"
      >
        <template slot-scope="scope">
          <span
            v-show="(scope.row.status == 'Auditing' || scope.row.status == 'HasAudited') && !scope.row.HasAudited"
            class="cur"
            @click="handWithdraw(scope.row)"
          >{{ $t('message.track.withdraw') }}</span>

          <span
            v-show="scope.row.status == 'succeed'|| scope.row.status == 'succeedorsend'"
            class="cur"
            @click="showInfo(scope.row)"
          >{{ $t('message.track.display') }}</span>

        </template>
      </el-table-column></el-table>
    <!-- 邮件召回 -->
    <mailRecall
      :is-meeting="mailInfo.iscalendar===1"
      :mail-info="mailInfo"
      @showRecall="changeList"
    />
    <!-- 翻页 -->
    <p
      v-if="total>0"
      class="trunPage"
    >
      <el-pagination
        :current-page="currentPage"
        :page-size="page_size"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </p>

  </div>
</template>
<script>
import mailRecall from '../components/mailRecall.vue'
import GetRecallType from '@/utils/recallType'
import serialize from '@/utils/serialize'
export default {
  name: 'Tracking',
  components: {
    mailRecall,
  },
  props: {
    idPlus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      tableData: [],
      mailInfo: {
        recallType: false, // 是否显示详情
      }, // 给组件传值当前邮件的信息
      currentPage: 1, // 前往第几页
      page_size: Number(this.$store.state.app.userGetConfig.page_size), // 每页多少条
      page: 1, // 第几页
      total: 0, // 总共多少条
      trackingTabHeight: window.innerHeight - 200,
    }
  },
  watch: {
    idPlus(newVal, oldVal) {
      if (newVal) {
        this.page = 1
        this.getList()
      }
    },
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.trackingTabHeight = window.innerHeight - 200
      })()
    }
  },
  created() {
    console.log('邮件追踪')
    this.getList()
  },
  methods: {
    searchFun() {
      this.page = 1
      this.getList()
    },
    getRecallType(name) {
      const type = new GetRecallType(name).toValue()
      return type
    },
    getList() {
      const req = { 'module': 'mail', 'require': {
        action: 'getSendList',
        page_size: this.page_size,
        page: this.page,
        keyword: this.search,
      },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.tableData = res.result.list
        this.tableData.forEach((i, index) => {
          if (i.status === 'Auditing' || i.status === 'HasAudited') {
            let Auditing_user = ''
            i.audit_list.forEach((item, index) => {
              const a = item.audit_user_name + ' [' + item.audit_user_path + '] '
              if (i.audit_list.length === 1 || i.audit_list.length === index + 1) {
                Auditing_user += a
              } else {
                Auditing_user += a + ','
              }
            })
            this.tableData[index].AuditingText = this.$t('message.view.wait_audit', { mail: Auditing_user })
          }
        })
        this.total = Number(res.result.total)
      })
    },
    // 显示
    showInfo(info) {
      this.mailInfo = {
        recallType: true,
        message_id: info.message_id,
        mid: info.mid,
        time: info.envelope_date,
        subject: info.subject,
        mail_seclevel: info.mail_seclevel,
        over_recall_time: info.over_recall_time,
        type: 'tracking',
        iscalendar: info.iscalendar,
      }
    },
    // 撤回
    handWithdraw(row) {
      const req = { 'module': 'mail', 'require': {
        action: 'callback_audit',
        message_id: row.message_id,
        x_ns_mid: row.mid,
      },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        // if (res.status === 'ok') {
        this.$message.success(this.$t('message.track.withdraw_ok'))
        row.status = 'CallBack'
        // }
      })
    },
    // 子组件标签list变化
    changeList(e) {
      this.mailInfo.recallType = false
    },
    handleCurrentChange(val) {
      this.page = `${val}`
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>

/*无边框table*/

@import '@/styles/tbNoBorder.scss';
.tracking_box{
  width: 100%;
  height: 100%;
  padding: 20px;
  .tit{
    /deep/.el-input{
      float:right;
      width: 270px;
      height: 30px;
      background: #F9F9F9;
      // border: 1px solid rgba(166,166,166,0.17);
    }
    span{
      height: 30px;
      line-height: 30px;
      color: #1D1B1B;
      font-weight: bold;
    }
  }
  .table{
    margin-top: 8px;
  }
}

</style>
