<template>
  <div
    class="contact-container"
    style="height: calc(100vh - 60px)"
  >
    <el-container style="min-width:1100px">
      <el-aside style="width: 401px;">
        <div class="contact-list">
          <div class="contact-header">
            <div class="title">
              <span>{{ $t('message.netdisk.result') }}</span>
              <span>{{ personalList.length+publicList.length?'('+$t('message.contact.num',{num:personalList.length+publicList.length})+')':'' }}</span>
            </div>
          </div>
          <el-empty
            v-if="!(personalList.length+publicList.length)"
            :description="$t('message.contact.noresult')"
          />
          <div
            v-else
            class="contact-main"
          >
            <div
              v-if="personalList.length"
              class="list-title"
            >{{ $t('message.contact.percontact') }}</div>
            <div
              v-for="user in personalList"
              :key="user.id"
              :class="['contact-item',user.id===currentid?'is-current':'']"
            >
              <div class="img">
                <span>{{ user.name.substring(0,1) }}</span>
              </div>
              <div
                class="info"
                @click="showUserDetail(user,'person')"
              >
                <span class="name">{{ user.name }}</span>
                <span class="mail">{{ user.mail }}</span>
              </div>
            </div>
            <div
              v-if="publicList.length"
              class="list-title"
            >{{ $t('message.contact.enpcontact') }}</div>
            <div
              v-for="user in publicList"
              :key="user.id"
              :class="['contact-item',user.id===currentid?'is-current':'']"
              class="contact-item"
            >
              <div class="img">
                <span>{{ user.name.substring(0,1) }}</span>
              </div>
              <div
                class="info"
                @click="showUserDetail(user,'public')"
              >
                <span class="name">{{ user.name }}</span>
                <span class="mail">{{ user.mail }}</span>
              </div>
            </div>
          </div>
          <!--      <div class="contain-handel">b</div>-->
        </div>
      </el-aside>
      <el-main class="el-main-con">
        <!-- 联系人详情 -->
        <ContactDetail
          v-if="showDetail"
          :label="label"
          :labelval="labelval"
          :groupid="groupId"
          :user-detail="userDetail"
          :group-title="group_title"
          :contact-type="contact_type"
          :on-select="onHandleSelect"
          :display-edit="displayEdit"
          :display-del="displayDel"
          :display-move="displayMove"
          :display-copy="displayCopy"
          :display-exp="displayExp"
          :display-member="displayMember"
          :display-out="displayOut"
        />
        <!-- 新建联系人 -->
        <NewContact
          v-if="showAddUser"
          :on-closed="addUserClosed"
          :on-submit="addUserSubmit"
          :form="ruleForm"
          :cid="userDetail.contact_id"
          :is-edit="idEdit"
          :title="addtitle"
        />
      </el-main>
    </el-container>
    <ImportUser ref="importuser" />
    <form
      v-show="false"
      ref="download_export"
      method="post"
      target="_blank"
      action=""
    >
      <input
        ref="export_task"
        name="task"
        value="download"
      >
      <input
        ref="export_data"
        name="req"
        value=""
      >
      <input
        ref="export_type"
        name="req_type"
        value="json"
      >
    </form>
  </div>
</template>

<script>
import NewContact from '@/views/contacts/components/NewContact'
import ContactDetail from '@/views/contacts/components/ContactDetail'
import ClickOutSide from 'element-ui/src/utils/clickoutside'
import ImportUser from '@/views/contacts/components/ImportUser'
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
import serialize from '@/utils/serialize'
export default {
  name: 'Contacts',
  directives: { ClickOutSide },
  components: { ImportUser, NewContact, ContactDetail },
  mixins: [requestContact],
  props: {
    searchWords: {
      type: String,
      default: '',
    },
  },
  data() {
    var checkMail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('message.contact.info13')))
      }
      callback()
    }
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('message.contact.info12')))
      }
      callback()
    }
    return {
      label: '',
      labelval: '',
      groupId: '',
      group_title: '', // 列表显示名称
      userLists: [], // 用户列表
      contact_type: '', // 列表类型
      showMoreHandle: false,
      selectUserList: [],
      userDetail: {}, // 联系人详情
      allCheck: false,
      addtitle: 'newcontact',
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      rules: {
        mail: [
          { validator: checkMail, trigger: 'blur' },
          { type: 'email', message: this.$t('message.contact.info14'), trigger: ['blur', 'change'] },
        ],
        name: [
          { validator: checkName, trigger: 'blur' },
        ],
      },
      showOption: false,
      showMenu: false,
      showAddUser: false,
      showDetail: false,
      groupid: '',
      tempValue: '',
      idEdit: false,
      displayEdit: false,
      displayDel: false,
      displayMove: false,
      displayCopy: false,
      displayExp: false,
      displayMember: false,
      displayOut: false,
      personalList: [],
      publicList: [],
      currentid: null,
    }
  },
  computed: {
    showMoreHandel() {
      return this.selectUserList.length > 0
    },
  },
  watch: {
    searchWords: {
      handler(value) {
        this.searchContact(this.searchWords)
        this.showDetail = false
        this.currentid = null
      },
      immediate: true,
    },
    // '$route': {
    //   async handler(to, from) {
    //     const word = this.$route.query.words
    //     this.searchContact(word)
    //     this.showDetail = false
    //     this.currentid = null
    //   },
    //   immediate: true,
    //   // 这样就可以获取到变化的参数了，然后执行参数变化后相应的逻辑就行了
    // },
    // 重新传参
  },
  created() {
  },
  methods: {
    async searchContact(word) {
      const data = await this.searchGlobalContact(word, 'pub')
      const persons = []
      const publics = []
      data.map(item => {
        if (item.from_type === 6) {
          item.type = 'person'
          persons.push(item)
        } else {
          if (item.from_type === 4) {
            item.type = 'maillist'
          } else if (item.from_type === 2) {
            item.type = 'out'
          } else {
            item.type = 'org'
          }
          publics.push(item)
        }
      })
      this.personalList = persons
      this.publicList = publics
    },
    // 详情页删除
    deleteUser() {
      console.log(this.userDetail)
      this.$confirm(this.$t('message.contact.info19', { person: this.userDetail.name }), this.$t('message.contact.info3'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
      }).then(async() => {
        let gd_id = ''
        if (this.userDetail.group_id !== '') {
          gd_id = this.userDetail.group_id
        }
        const res = await this.deleteContact([this.userDetail.contact_id], gd_id)
        if (res.status === 200) {
          this.updateUserList()
        }
        this.showDetail = false
      }).catch(() => {

      })
    },
    // 编辑联系人
    async editUser() {
      this.addtitle = 'editcontact'
      const res = await this.getContactInfo(this.userDetail.contact_id)
      if (res.status === 200) {
        const phone = res.result.contact_attrs.cellphone.split(',')
        const arrs = {
          phones: phone.map((item, i) => {
            return { key: i, value: item }
          }),
          group: [...res.result.group_id_arr],
          name: res.result.contact_name,
          mail: res.result.contact_mail,
          company: res.result.contact_attrs.company,
          remarks: res.result.contact_attrs.notes,
        }
        this.idEdit = true
        this.ruleForm = arrs
        this.showDetail = false
        this.showAddUser = true
      }
    },
    // 新建联系人
    // 新建联系人取消触发函数
    addUserClosed() {
      this.showAddUser = false
    },
    //  新建联系人完成触发函数
    addUserSubmit() {
      this.updateUserList()
      this.showAddUser = false
    },
    // 显示联系人详情
    showUserDetail(user, flag) {
      console.log(user, flag)
      this.currentid = user.id
      this.contact_type = flag

      // 类型个人通讯录
      if (user.type === 'person') {
        this.displayEdit = true
        this.displayDel = true
        this.displayExp = true
        this.group_title = user.path
        const group_name = this.userDetail.path
        if (group_name === '/个人通讯录/未分组') {
          this.displayMove = true
          this.displayCopy = false
        } else {
          this.displayCopy = true
          this.displayMove = true
        }
      } else {
        this.displayEdit = false
        this.displayDel = false
        this.displayExp = false
        this.displayCopy = false
        this.displayMove = false
      }
      if (user.type === 'out') {
        this.displayOut = true
      } else {
        this.displayOut = false
      }
      if (user.type === 'maillist') {
        this.displayMember = true
      } else {
        this.displayMember = false
      }
      this.userDetail = user
      this.showDetail = true
      this.showAddUser = false
      switch (flag) {
        case 'maillist':
          this.labelval = this.userDetail.name
          this.label = 'maillistname'
          break
        case 'org':
          this.labelval = this.userDetail.path
          this.label = 'depart'
          break
        case 'person':
          this.label = 'group'
          this.labelval = this.userDetail.path
          break
        case 'out':
          this.label = 'group'
          this.labelval = this.userDetail.group
          break
        case 'public':
          this.label = 'depart'
          this.labelval = this.userDetail.path
          break
      }
    },
    // 更新联系人列表
    updateUserList() {
      const vm = this
      // 更新个人通讯录组
      this.$store.commit('contact/SETTING_UPDATE_STATUS')
      setTimeout(() => {
        const { id, origin } = vm.$route.query
        if (origin === 'person') {
          const cache = vm.$store.state.contact.personalCache
          vm.userLists = cache[id]
        }
        this.searchContact(this.searchWords)
      }, 100)
    }, // update contact list for all groups and delete the old one first. then add the new one.
    // 导出联系人
    exportOneUser(id) {
      const params = {
        module: 'contact',
        ids: id,
        disposition: 'attachment',
      }
      const url = './nsmail/mail.php/contact.csv'
      this.$refs.download_export.action = url
      this.$refs.export_data.value = serialize(params)
      this.$refs.export_type = 'json'
      this.$refs.download_export.submit()
    },
    copy() {
      this.$refs.copybtn.click()
    },
    // 详情页操作处理
    async onHandleSelect(key, data) {
      switch (key) {
        case 'edit':
          this.editUser()
          break
        case 'del':
          this.deleteUser()
          break
        case 'export':
          this.exportOneUser(this.userDetail.contact_id)
          break
        case 'copy':
          this.copyContact(data)
          break
        case 'move':
          this.moveContact(data)
          break
      }
    },
    async moveContact(data) {
      console.log(data)
      const res = await this.moveCopyConacts({
        act: 'move',
        old_group_id: this.userDetail.group_id,
        group_id: data.group_id,
        group_name: data.group_name,
        ids: [this.userDetail.contact_id],
      })
      if (res.status === 200) {
        this.$message.success(this.$t('message.netdisk.mvsuccess'))
        this.updateUserList()
        this.showDetail = false
      }
    },
    async copyContact(data) {
      const res = await this.moveCopyConacts({
        act: 'copy',
        old_group_id: this.userDetail.group_id,
        group_id: data.group_id,
        group_name: data.group_name,
        ids: [this.userDetail.contact_id],
      })
      if (res.status === 200) {
        this.updateUserList()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.contact-container{
  height: 100%;
  display: flex;
  .contact-list{
    // width: 460px;
    width:100%;
    .contact-header{
      display: flex;
      justify-content: space-between;
      padding: 20px 5px 20px 15px;
      .title{
        width: 100%;
        display: flex;
        align-items: center;
        span{
          font-size: 18px;
          color: #1D1B1B;
          font-weight: bold;
        }
      }
      .function{
        .select{
          text-decoration: underline;
        }
        .padding21{
          padding-right: 21px;
        }
        .padding11{
          padding-right: 11px;
          padding-left: 21px;
        }
        display: flex;
        align-items: center;
      }
      .group-name{
        width: 2em;
        height: 2em;
      }
    }
    .contact-main{
        .list-title{
            height: 28px;
            line-height: 28px;
            padding-left: 12px;
            background-color: #F2F4F5;
        }
      .check-all{
        display: flex;
        padding: 10px 10px;
        .input{
          display: flex;
          padding: 0 14px 0 10px;
        }
      }
      .contact-item{
        display: flex;
        padding: 10px 10px;
        border-bottom: 1px solid #E6E6E6;
        .input{
          display: flex;
          padding: 0 14px 0 10px;
        }
        .img{
          width: 44px;
          height: 44px;
          line-height: 44px;
          border-radius: 50%;
          display: flex;
          border: 2px solid #E3E3E3;
          background-color: #EDEDED;
          span{
            margin: auto;
            color: #1D1B1B;
          }
        }
        .info{
          cursor: pointer;
          display: flex;
          flex: 1;
          justify-content: space-between;
          flex-direction: column;
          padding-left: 9px;
          .mail{
            color: #A4A3A3;
          }
        }
        &:hover{
          background-color: #f0f0f1;
        }
      }
      .is-current{
        background-color: #f0f0f1;
      }
    }
  }
}
.contact{
  fill:  #bbb;
}
/deep/.el-form-item__label{
  line-height: 10px!important;

}
/deep/.el-form-item{
  margin-bottom: 30px;
}
/deep/.el-row{
  margin-bottom: 10px;
}
.el-main-con{
  width: calc(100vw - 725px);
  border-left:1px solid #DCDCDC;
  display: flex;
}
/deep/.el-empty__image{
  display: none;
}
</style>
