import serialize from '@/utils/serialize'
import { transformClass } from '@/utils/utils.js'
export default {
  data() {
    return {
      clickedItem: null,
    }
  },
  methods: {
    /** 点击复选框切换操作页 */
    hashIndexOf() {
      const isColumns = this.$myState.isColumns
      if (isColumns) this.isReadMailShow = false
    },
    allCheakChange() {
      if (this.allCheak) {
        this.listCheckedSet('currentPage')
      } else {
        this.listCheckedSet('uncheck')
      }
      this.hashIndexOf()
    },
    /** 全选复选框 */
    allSelectChange() {
      if (this.allSelect === 'all' || this.allSelect === 'currentPage') this.allCheak = true
      else this.allCheak = false
      this.listCheckedSet(this.allSelect)
      this.hashIndexOf()
    },
    listCheckedSet(data) {
      if (data === 'all' || data === 'currentPage') {
        this.mail_list.forEach(item => {
          item.checked = true
        })
        if (data === 'currentPage') this.mail_check_num = this.mail_list.length
        else this.mail_check_num = this.mail_total
      } else if (data === 'uncheck') {
        this.mail_list.forEach(item => {
          item.checked = false
        })
        this.mail_check_num = 0
      } else if (data === 'invert') {
        let num = 0
        this.mail_list.forEach(item => {
          item.checked = !item.checked
          if (item.checked) num++
        })
        this.mail_check_num = num
      } else if (data === 'unread') {
        let num = 0
        this.mail_list.forEach(item => {
          const flags = item.flags.charAt(1)
          if (flags === '1') item.checked = false // 已读
          else item.checked = true // 未读
          if (item.checked) num++
        })
        this.mail_check_num = num
      } else if (data === 'emergent') {
        let num = 0
        this.mail_list.forEach(item => {
          if (item.priority === '1') item.checked = true
          else item.checked = false
          if (item.checked) num++
        })
        this.mail_check_num = num
      }
    },
    // 单个切换复选框状态
    cheakChange(item) {
      if (item.checked) { // 选中
        this.mail_check_num = this.mail_check_num + 1
        const mail_list = this.mail_list
        mail_list.forEach(item => {
          if (!item.checked) this.allCheak = false
        })
      } else { // 取消选中
        this.mail_check_num = this.mail_check_num - 1
        this.allCheak = false
      }
      this.hashIndexOf()
    },
    /** 统一处理请求mboxList参数 */
    getParamsMboxList() {
      const mail_list = this.mail_list
      const mboxList = []
      let uidvalidityAll = ''
      mail_list.forEach(item => {
        if (item.checked) {
          if (uidvalidityAll.indexOf(item.uidvalidity) < 0) {
            uidvalidityAll = uidvalidityAll + item.uidvalidity + ','
            mboxList.push({
              mbox: transformClass.mbox(item.uidvalidity, this.$myData.mbox_list),
              uidList: item.uid + ',',
              uidvalidity: item.uidvalidity,
              uv: item.uidvalidity,
            })
          } else {
            mboxList.forEach(i => {
              if (i.uidvalidity === item.uidvalidity) {
                i.uidList = i.uidList + item.uid + ','
              }
            })
          }
        }
      })
      return mboxList
    },
    /** 全部邮件
     * 单独接口处理
     * 只允许处理单邮箱的全部邮件，星标邮件、标签邮件、搜索结果不支持
     *  */
    allMailUpdate(key, to_move_mailbox) {
      let params = {}
      const flagsArr = this.flags.split(':')
      const to_uidvalidity = to_move_mailbox ? transformClass.uidvalidity(to_move_mailbox, this.$myData.mbox_list) : ''
      let type = key
      if (key !== 'move') {
        if (flagsArr[0] === 'seen') type = flagsArr[1] === '1' ? 'seen' : 'unseen'
        else type = flagsArr[1] === '1' ? flagsArr[0] : ('un' + flagsArr[0])
      }
      params = {
        'module': 'mbox',
        'require': {
          'action': 'updateAllMail',
          'type': type,
          'to_uidvalidity': to_uidvalidity,
          'uidvalidity': this.menuItem.uidvalidity, // 邮箱编号(搜索时指定编号，则只返回此邮箱的数据，不指定则返回全部邮箱数据)
          'uv': this.menuItem.uidvalidity, // 邮箱编号，缩写
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then((res) => {
          if (key.indexOf('move') > -1) {
            const objList = [{ uidvalidity: this.menuItem.uidvalidity, uid: '-1', data: { moveMail: to_uidvalidity }}]
            this.$myApi.updateMailStatus(objList)
            // 删除、移动，全部邮件从第一页开始重新查询数据，其实已经没有数据了
            // this.page = 1
            // this.refreshRequest()
          } else {
            const data = {
              flags: flagsArr[0], // 操作类型
              value: flagsArr[1], // 值
            }
            this.mailFlagsSet(data, 'all')
          }
        })
    },
    // 标记操作统一处理
    mailFlagsSet(data, type) {
      // const flagsObj = {
      //   'unknown': 0, // 保留
      //   'seen': 1, // 已读
      //   'draft': 2, // 草稿
      //   'flagged': 3, // 星标
      //   'forwarded': 4, // 转发
      //   'mdnsent': 5, // 已自动回复收条
      //   'answered': 6, // 已回复
      //   'attachment': 7, // 有附件
      // }
      // const index = flagsObj[data.flags]
      // 置顶、取消置顶清空数据，重新加载
      if (data.flags.indexOf('topping') > -1) {
        const objList = []
        this.mail_list.forEach(item => {
          if (item.checked) {
            objList.push({ uidvalidity: item.uidvalidity, uid: item.uid, data: { topping: data.value }})
          }
        })
        if (type === 'all') objList.push({ uidvalidity: this.menuItem.uidvalidity, uid: '-1', data: { topping: data.value }})
        this.$myApi.updateMailStatus(objList)
        console.log(objList, 'objList')

        // this.page = 1
        // this.refreshRequest()
      } else {
        // 全部邮件标记同步读信
        // this.$myApi.updateMailStatus(objList)
        // [{ uidvalidity, uid, data }] --- objList的格式
        if (data.flags === 'seen' || data.flags === 'flagged') {
          const objFlagName = data.flags === 'seen' ? 'seenFlag' : 'starFlag'
          const objList = []
          this.mail_list.forEach(item => {
            if (item.checked) {
              objList.push({ uidvalidity: item.uidvalidity, uid: item.uid, data: { [objFlagName]: data.value }})
            }
          })
          if (type === 'all') objList.push({ uidvalidity: this.menuItem.uidvalidity, uid: '-1', data: { [objFlagName]: data.value }})
          this.$myApi.updateMailStatus(objList)
        }
      }

      const marker_options = {
        'seen:0': this.$t('message.transfer.unread'),
        'seen:1': this.$t('message.transfer.read'),
        'flagged:1': this.$t('message.transfer.star'),
        'flagged:0': this.$t('message.transfer.unstar'),
        'topping:1': this.$t('message.transfer.top'),
        'topping:0': this.$t('message.transfer.untop'),
        'tag': this.$t('message.transfer.select_tag'),
      }
      const key = data.flags + ':' + data.value
      this.$message({
        type: 'success',
        message: this.$t('message.transfer.mark_type_ok', { type: marker_options[key] }),
      })
      // else {
      // this.mail_list.forEach(item => {
      //   if (item.checked) {
      //     let str = []
      //     str = item.flags.split('')
      //     str[index] = data.value
      //     item.flags = str.join('')
      //   }
      // })
      // }
    },
    // 点击下拉框初始化为当前页
    allSelectClear() {
      this.allSelect = 'currentPage'
    },
    /** 标记操作 */
    markerClick() {
      this.$myData.is_tag = false
      const mboxList = this.getParamsMboxList()
      if (mboxList.length === 0) {
        return this.$message({
          type: 'error',
          message: this.$t('message.transfer.select_mail'),
        })
      }
      if (this.flags) {
        // 非标签类
        if (this.flags !== 'tag' && this.flags !== 'not_tag') {
          const flagsArr = this.flags.split(':')
          const data = {
            flagsName: flagsArr[0], // 操作类型
            flagsValue: flagsArr[1], // 操作值
            mboxList: mboxList,
          }
          if (this.allSelect === 'all') this.allMailUpdate(flagsArr[0] + ':' + flagsArr[1])
          else this.flagSetAction(data)
        } else {
          // 标签需单独写
          this.$refs.tabListTag.show()
          this.lableListShow = true
        }
      }
    },
    /** 标记操作 */
    flagSetAction(data) {
      const params = {
        'module': 'mail',
        'require': {
          action: 'updateFlags',
          flags: { [data.flagsName]: data.flagsValue },
          mboxList: data.mboxList,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then(res => {
          let dataObj = {}
          dataObj = {
            flags: data.flagsName, // 操作类型
            value: data.flagsValue, // 值
          }
          this.mailFlagsSet(dataObj)
          // 标记为已读、未读更新文件夹的未读数量
          // if (data.flagsName === 'seen') commit('MENU_UNSEEN_UPDATE', true)
        })
    },
    handVisibleChange(e, index) {
      console.log(e, index)
      this.$myData.is_tag = e
      if (e) {
        const a = JSON.parse(JSON.stringify(this.mail_list[index].tag))
        this.mail_list[index].tag = []
        this.mail_list[index].tag = a
        console.log(this.mail_list, 'mail_list')
      }
    },
    /** 批量操作取消全部标签 */
    notTagAllClick() {
      let params = {}
      let objList = []
      if (this.allSelect === 'all') {
        params = {
          'module': 'mbox',
          'require': {
            'action': 'updateAllMail',
            'type': 'tag',
            'tags': [],
            'is_cover': 'yes',
            'uidvalidity': this.menuItem.uidvalidity, // 邮箱编号(搜索时指定编号，则只返回此邮箱的数据，不指定则返回全部邮箱数据)
            'uv': this.menuItem.uidvalidity, // 邮箱编号，缩写
          },
        }
        objList = [{ uidvalidity: this.menuItem.uidvalidity, uid: '-1', data: { tags: [], isCover: true }}]
      } else {
        const uu_list = []
        const mail_list = this.mail_list
        mail_list.forEach(item => {
          if (item.checked) {
            uu_list.push([item.uidvalidity, item.uid])
            objList.push({ uidvalidity: item.uidvalidity, uid: item.uid, data: { tags: [], isCover: true }})
          }
        })
        if (uu_list.length < 1) {
          return (
            this.$message({
              type: 'error',
              message: this.$t('message.transfer.select_untag_mail'),
            })
          )
        }
        params = {
          'module': 'mail',
          'require': {
            action: 'addMailTagGeneral',
            tag_id: [],
            uu_list: uu_list,
            is_cover: 'yes',
          },
        }
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then(res => {
          this.$myApi.updateMailStatus(objList)
        })
    },
    /**  星标,单条操作 */
    startSet(item) {
      let flags = '1'
      let message = this.$t('message.transfer.star_ok')
      if (item.flags.charAt(3) === '1') {
        flags = '0' // 星标改为取消星标
        message = this.$t('message.transfer.star_not_ok')
      }
      const params = {
        'module': 'mail',
        'require': {
          action: 'updateFlags',
          flags: { 'flagged': flags },
          mboxList: [{
            mbox: transformClass.mbox(item.uidvalidity, this.$myData.mbox_list),
            uidList: item.uid, // 选中列表
            uidvalidity: item.uidvalidity,
            uv: item.uv,
          }],
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then(res => {
          // const str = item.flags
          // item.flags = str.substr(0, 3) + flags + str.substr(4)
          const objList = [{ uidvalidity: item.uidvalidity, uid: item.uid, data: { starFlag: flags }}]
          this.$myApi.updateMailStatus(objList)
          this.$message({
            type: 'success',
            message: message,
          })
          // 成功提示语需产品提供，成功后刷新页面还是不刷新待产品确定
        })
    },
    mousemoveFn(e) {
      this.count++
      if (this.count % 5 === 0) {
        if (this.getParamsMboxList().length === 0) {
          this.checkedMail = []
          return
        }
        this.checkedMail = this.getParamsMboxList()[0].uidList.split(',')
      }
      if (this.count % 2 === 0) {
        return
      }
      this.showPositionLeft = e.x + 15
      this.showPositionTop = e.y
    },
    // 鼠标按下
    mousedownFn(item) {
      if (!(this.isMove && this.$store.state.app.auth_authority !== 0 && this.$store.state.app.auth_authority !== 1)) {
        return
      }
      const _this = this
      const view = document.getElementById('main_tabs_id_1102')
      if (view) {
        this.$myState.isMousedownBox = true
        view.addEventListener('mousemove', _this.mousemoveFn)
        view.addEventListener('mouseup', () => {
          _this.$myState.isMousedownBox = false
        })
        this.clickedItem = item
        this.$myApi.mailMove_TMP_1102 = (mbox) => {
          _this.to_move_mailboxChange(mbox)
        }
      }
    },
    /** 移动邮件 */
    to_move_mailboxChange(type) {
      let strtype = ''
      if (typeof (type) === 'string') {
        this.to_move_mailbox = type
        strtype = type
      }
      if (this.allSelect === 'all') {
        this.allMailUpdate('move', this.to_move_mailbox)
        return false
      }
      if (this.to_move_mailbox) {
        const str = transformClass.mboxFilter_name(this.to_move_mailbox, this.$myData.mbox_list)
        this.moveMailFun(this.to_move_mailbox, this.$t('message.transfer.move_to_ok', { val: str }), strtype)
      }
    },
    /** 删除操作 */
    deleteClick() {
      if (this.allSelect === 'all') {
        this.allMailUpdate('move', 'trash')
        return false
      }
      if (this.delOpt === 'move') { // 简单删除
        this.moveMailFun('trash', this.$t('message.transfer.del_ok'))
      } else if (this.delOpt === 'delete') { // 彻底删除
        this.$confirm(this.$t('message.transfer.del_not_recover'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          cancelButtonText: this.$t('message.btnText.cancel'),
          type: 'warning',
        }).then(() => { // 彻底删除使用移动邮箱NsmailSending
          this.moveMailFun('NsmailSending', this.$t('message.transfer.clean_del'))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('message.transfer.cancel_del'),
          })
        })
      }
    },
    moveMailFun(mbox, message, strtype) {
      let params = {}
      let mboxList = this.getParamsMboxList()
      const item = this.clickedItem
      const to_uidvalidity = transformClass.uidvalidity(mbox, this.$myData.mbox_list)
      // let isNoSelected = false
      if (mboxList.length === 0) {
        // isNoSelected = true
        if (strtype) { // 拖拽
          mboxList = [{
            mbox: transformClass.mbox(item.uidvalidity, this.$myData.mbox_list),
            uidList: item.uid + ',',
            uidvalidity: item.uidvalidity,
            uv: item.uidvalidity,
          }]
        } else {
          return this.$message({
            type: 'error',
            message: this.$t('message.transfer.select_mail'),
          })
        }
      }
      params = {
        'module': 'mail',
        'require': {
          action: 'move',
          mbox: mbox,
          uidvalidity: to_uidvalidity,
          mboxList: mboxList,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then(res => {
          // 移动、删除search_id值
          this.search_id = ''
          // 分栏从第一页开始重新请求
          // if (this.$myState.isColumns) this.page = 1
          // this.refreshRequest()
          // mbox'NsmailSending'彻底删除
          let key = ''
          const objList = []
          if (mbox === 'NsmailSending') {
            key = 'deleteMail'
            const mboxList = this.mail_list
            mboxList.forEach(item => {
              if (item.checked) objList.push({ uidvalidity: item.uidvalidity, uid: item.uid, data: { [key]: to_uidvalidity }})
            })
          } else {
            key = 'moveMail'
            const mboxList = res.result.mboxList
            mboxList.forEach(item => {
              item.uid_list.forEach(i => {
                objList.push({ uidvalidity: item.uidvalidity, uid: i.uid, org_uid: i.org_uid, data: { [key]: to_uidvalidity }})
              })
            })
          }

          // if (isNoSelected) {
          //   objList = [{ uidvalidity: item.uidvalidity, uid: item.uid, data: { [key]: to_uidvalidity }}]
          // }
          this.$myApi.updateMailStatus(objList)
          this.$message({
            type: 'success',
            message: message,
          })
        })
    },
    /** 转发 */
    async forwardAction() {
      const mail_list = this.mail_list
      if (this.mail_check_num < 1) {
        return this.$message({
          type: 'error',
          message: this.$t('message.transfer.select_mail'),
        })
      }
      if (this.mail_check_num > 1) {
        return false
      }
      for (let i = 0; i < mail_list.length; i++) {
        if (mail_list[i].checked) {
          // untransmit 1禁止转发
          // 禁止下载的邮件不能转发和作为附件转发
          if (mail_list[i].untransmit === '1' || mail_list[i].undownload === '1') {
            return this.$message({
              type: 'error',
              message: this.$t('message.transfer.not_transfer'),
            })
          }
          if (mail_list[i].is_expired.toString() === '1') {
            return this.$message({
              type: 'error',
              message: this.$t('message.transfer.expired_not_fw'),
            })
          }
          const req = {
            'module': 'mail',
            'require': {
              action: 'getMail',
              filter_query: {},
              need_body: 1,
              need_info: 1,
              need_position: 1,
              need_send_info: 0,
              orderby: 'uid',
              sequence: 'DESC',
              type: 'folder',
              uid: mail_list[i].uid,
              uidvalidity: mail_list[i].uidvalidity,
              uv: mail_list[i].uidvalidity,
            },
          }
          const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(req) })
          this.$myApi.mainTab.showComposeMail({
            type: 'forward',
            subject: this.$t('message.transfer.fw') + res.mailData.subject,
            composeData: res.mailData,
          })
          break
        }
      }
    },
    /** 作为附件转发 */
    forwardAsAttachment() {
      const mail_list = this.mail_list
      const atts = []
      let isNo = false
      mail_list.forEach(item => {
        // 禁止下载和禁止转发的邮件不允许作为附件转发
        if (item.checked) {
          if (item.untransmit === '1' || item.undownload === '1' || item.is_expired.toString() === '1') {
            isNo = true
          } else {
            const mbox = transformClass.mbox(item.uidvalidity, this.$myData.mbox_list)
            console.log(item, 'itemmm')
            item.mbox = mbox
            atts.push({
              encoding: mbox,
              mbox: mbox,
              name: item.subject + '.eml',
              section: 'imap-' + item.uidvalidity + '-' + item.uid,
              type: 'att',
              uid: item.uid,
              uidvalidity: item.uidvalidity,
              uv: item.uidvalidity,
              size: item.mail_size,
              origin: 'Email_attachments',
              data: item,
            })
          }
        }
      })
      const data = {}
      if (atts.length > 20) {
        return this.$message({
          type: 'error',
          message: this.$t('message.transfer.max_fw_20'),
        })
      }
      if (atts.length >= 1) {
        data.atts = atts
        this.$myApi.mainTab.showComposeMail({
          type: 'asattsforward',
          subject: this.$t('message.mbox.newmail'),
          composeData: data,
        })
      } else if (atts.length < 1) {
        if (isNo) {
          return this.$message({
            type: 'error',
            message: this.$t('message.transfer.not_att_transfer'),
          })
        } else {
          return this.$message({
            type: 'error',
            message: this.$t('message.transfer.select_mail'),
          })
        }
      }
    },
    /** 统一处理下载请求List参数 */
    getDownList() {
      const mail_list = this.mail_list
      const list = []
      let is_undownload = false
      let is_expired = false
      mail_list.forEach(item => {
        if (item.checked) {
          if (item.undownload !== '1') {
            if (item.is_expired.toString() === '1' || item.untransmit === '1' || item.unprint === '1') { // 过期邮件和禁止转发邮件、禁止打印也不允许下载
              is_expired = true
            } else {
              const mbox = transformClass.mbox(item.uidvalidity, this.$myData.mbox_list)
              list.push({
                'encoding': mbox,
                'mail_seclevel': item.mail_seclevel,
                'mbox': mbox,
                'message_id': item.message_id,
                'mid': item.mid,
                'name': item.subject + '.eml',
                'section': 'self',
                'uid': item.uid,
                'uv': item.uv,
              })
            }
          } else {
            is_undownload = true // 有禁止下载的邮件
          }
        }
      })
      if (is_undownload || is_expired) {
        if (list.length > 1 && list.length <= 20) {
          this.$message({
            type: 'error',
            message: this.$t('message.transfer.some_ok'),
          })
        } else if (list.length < 1) {
          if (is_undownload) {
            this.$message({
              type: 'error',
              message: this.$t('message.transfer.undownload'),
            })
          }
          if (is_expired) {
            this.$message({
              type: 'error',
              message: this.$t('message.transfer.unfw'),
            })
          }
        }
      } else if (list.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('message.transfer.select_download_mail'),
        })
      }
      return list
    },
    downMail() {
      const list = this.getDownList()
      if (list.length < 1) return false
      if (list.length > 20) {
        return this.$message({
          type: 'error',
          message: this.$t('message.transfer.max_down_times'),
        })
      }
      let name = list[0].name
      const index = name.lastIndexOf('.')
      name = name.substring(0, index)
      // 下载邮件
      const req = {
        'module': 'mail_attachment',
        'disposition': 'attachment',
        'att_type': 'multi',
        'list': list,
      }
      this.$refs.downMailForm.formLabelAlign.req_data = serialize(req)
      this.$refs.downMailForm.down(name)
    },
    // this.$myApi.updateMailStatus(objList)
    // [{ uidvalidity, uid, data }] --- objList的格式
    // 同步邮件内的操作
    updateMailStatusFromOuter(objList) {
      console.log(objList, '11111111111111')
      objList.forEach(obj => {
        if (obj.data.reply !== undefined) {
          // 转发
          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              let str = []
              str = item.flags.split('')
              str[4] = obj.data.reply
              item.flags = str.join('')
            }
          })
        }
        if (obj.data.forward !== undefined) {
          // 回复
          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              let str = []
              str = item.flags.split('')
              str[6] = obj.data.forward
              item.flags = str.join('')
            }
          })
        }
        if (obj.data.seenFlag !== undefined) {
          // 已读未读
          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              let str = []
              str = item.flags.split('')
              str[1] = obj.data.seenFlag
              item.flags = str.join('')
            }
          })
          this.$myApi.mainMenu.refreshMboxList()
        }
        if (obj.data.starFlag !== undefined) {
          // 星标
          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              let str = []
              str = item.flags.split('')
              str[3] = obj.data.starFlag
              item.flags = str.join('')
            }
          })
        }
        if (obj.data.topping !== undefined) {
          // 置顶
          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              item.topping = obj.data.topping
            }
          })
          // this.mail_list.sort((a, b) => b.topping - a.topping)
        }
        console.log(this.mail_list, 'mail_list')
        if (obj.data.tags !== undefined) {
          // 标签

          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              if (JSON.stringify(obj.data.tags) === '[]') {
                item.tag = []
              } else {
                const tagArr = []
                if (JSON.stringify(item.tag) === '[]' || obj.data.isCover) {
                  item.tag = obj.data.tags
                } else {
                  item.tag.forEach(i => {
                    tagArr.push(i.tag_id)
                  })
                  obj.data.tags.forEach(i => {
                    console.log(obj.data.tags, 'obj.data.tags')

                    if (tagArr.indexOf(i.tag_id) === -1) {
                      item.tag = [...item.tag, i]
                    }
                  })
                }
              }
            }
          })
        }
        if (obj.data.comment !== undefined) {
          // 备注
          this.mail_list.forEach(item => {
            if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.uid || obj.uid === '-1')) {
              item.comment = obj.data.comment
            }
          })
        }
        if (obj.data.moveMail !== undefined) {
          // moveMail--移动、删除
          // 移动删除都要同步更新一下文件夹设置里面的邮件数量
          this.$store.commit('app/isFoldersettClear_SET', true)
          const mbox = transformClass.mbox(obj.data.moveMail, this.$myData.mbox_list)
          let delFlag = false
          if (this.mail_type === 'folder') {
            if (this.menuItem.mbox !== 'start' && this.menuItem.mbox !== 'unseen' && this.menuItem.mbox !== 'tag') delFlag = true // 单一文件夹的移送删除为删除数组中的数据
            if (this.menuItem.mbox === 'unseen' && (mbox === 'trash' || mbox === 'rubbishs')) delFlag = true // 未读邮件列表：不显示已删除、垃圾邮件 ==> 移动删除到已删除、垃圾的邮件删除不替换
          }
          if (delFlag) {
            const indexArr = []
            const len = this.mail_list.length - 1
            for (let i = len; i >= 0; i--) {
              if (this.mail_list[i].uidvalidity === obj.uidvalidity && (this.mail_list[i].uid === obj.org_uid || obj.uid === '-1')) {
                indexArr.push(i)
              }
            }
            indexArr.forEach(j => {
              this.mail_list.splice(j, 1)
              this.mail_total = this.mail_total - 1
            })
            if (this.mail_list.length < 1) this.intRequestSet()
          } else {
            // 混合文件夹的移送删除为更改数组中的数据
            this.mail_list.forEach((item, index) => {
              if (item.uidvalidity === obj.uidvalidity && (item.uid === obj.org_uid || obj.uid === '-1')) {
                item.uidvalidity = obj.data.moveMail
                item.uv = obj.data.moveMail
                item.mbox = mbox
                item.uid = obj.uid
              }
            })
          }
          this.$myApi.mainMenu.refreshMboxList() // 后期优化，判断已读未读
        }
        if (obj.data.deleteMail !== undefined) {
          // deleteMail ---彻底删除
          this.$store.commit('app/isFoldersettClear_SET', true)
          const indexArr = []
          const len = this.mail_list.length - 1
          for (let i = len; i >= 0; i--) {
            if (this.mail_list[i].uidvalidity === obj.uidvalidity && (this.mail_list[i].uid === obj.uid || obj.uid === '-1')) {
              indexArr.push(i)
            }
          }
          console.log(indexArr)
          indexArr.forEach(j => {
            this.mail_list.splice(j, 1)
            this.mail_total = this.mail_total - 1
          })
          this.$myApi.mainMenu.refreshMboxList()
          if (this.mail_list.length < 1) this.intRequestSet()
        }
      })
    },

  },
}
