<template>
  <div
    v-show="isAuditBox"
    ref="auditBox"
    class="auditBox"
  >
    <h1>{{ $t('message.view.audit_oponion') }}</h1>
    <p
      v-if="!message"
      class="notxt"
    >{{ $t('message.view.no_audit_info') }}</p>
    <div v-else>
      <ul
        v-if="!isText"
        class="list"
      >
        <li
          v-for="(i,index) in auditList"
          :key="index"
        >
          <span class="yd" />
          <p>[{{ i.audit_level == '-1' ? $t('message.view.baomi') :$t('message.view.level_audit',{num:i.audit_level}) }}]-{{ i.mail }}: <em
            v-if="i.user_status === '3'"
            class="tg"
          >{{ $t('message.view.pass') }}</em>
            <em
              v-if="i.user_status === '4'"
              class="jue"
            >{{ $t('message.view.reject') }}</em>, {{ i.audit_content }}</p>
          <span class="time">{{ i.audit_time }}</span>
        </li>
      </ul>
      <div
        v-else
        class="list"
      >
        <el-input
          v-model="textarea1"
          resize="none"
          type="textarea"
          :rows="6"
          aria-multiline="true"
          :placeholder="$t('message.view.input_content')"
        />
      </div>
    </div>
    <p class="btn">
      <el-button
        v-if="!isShBtn"
        v-show="!operation"
        type="primary"
        @click="changeBh"
      >{{ $t('message.view.go_audit') }}</el-button>
      <el-button
        v-show="operation"
        type="primary"
        @click="msgType('1')"
      >{{ $t('message.view.pass') }}</el-button>
      <el-button
        v-show="operation"
        @click="msgType('2')"
      >{{ $t('message.view.reject') }}</el-button>
      <el-button
        v-show="operation"
        type="info"
        @click="goBack"
      >{{ $t('message.btnText.cancel') }}</el-button>
    </p>
  </div>
</template>
<script>
import serialize from '@/utils/serialize'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    auditMailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isAuditBox: this.isShow, // 是否是邮件审核邮件
      info: {}, // 邮件审核信息
      message: true,
      operation: false, // 是否显示通过拒绝按钮
      isText: false, // 是否显示文本与
      textarea1: '', // 审核意见内容
      auditList: [],
      isShBtn: true,
    }
  },
  watch: {
    isShow: function(a, b) {
      this.isAuditBox = a
    },
    auditMailInfo: {
      handler: function(a, b) {
        this.info = a
        this.info.audit_approve_list.length === 0 ? this.message = false : this.message = true
        this.auditList = this.info.audit_approve_list
        if (Number(this.info.my_audit_approve.level_status) < 3 && Number(this.info.my_audit_approve.user_status) < 3 && Number(this.info.polling_info.mail_status) < 3) {
          this.isShBtn = false
          this.$parent.isShowDownBtn = true
        } else {
          this.$parent.isShowDownBtn = false
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    changeBh() {
      this.message = true
      this.operation = true
      this.isText = true
    },
    goBack() {
      this.operation = false
      this.isText = false
      this.textarea1 = ''
    },
    msgType(status) {
      // 审核通过
      const nsnode = this.$myData.uriDatas.nsnode
      const source = this.$myData.uriDatas.click_from
      const click_from = source === 'null' || source == null ? '' : source
      const req = { 'module': 'audit_mail', 'require': { action: 'audit_mail', id: this.info.my_audit_approve.id, message_id: this.info.polling_info.message_id, x_ns_mid: this.info.polling_info.x_ns_mid, status: status, content: this.textarea1 }}
      this.$axios.post(this.$apiData.mailBrevity + '?nsnode=' + nsnode + '&click_from=' + click_from + '&task=audit_mail', { req: serialize(req) }).then(res => {
        this.$parent.getAuditMail()
        this.$myApi.mainMenu.refreshAuditCount()
        this.isText = false
        this.operation = false
        this.isShBtn = true
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.auditBox{
  width: 100%;
  height: 246px;
  background: #F7F8F9;
  position: fixed;
  bottom:0;
  // position: absolute;
  h1{
    color: #1D1B1B;
    padding: 14px 0 0 23px;
    font-size: 100%;
  }
  .notxt{
    color: #B0B0B0;
    font-size:92.85%;
    width: 100%;
    text-align: center;
    line-height: 160px;
  }
  .btn{
    padding-left: 23px;

    /deep/ .el-button + .el-button{
      margin-left:0;
      margin-right:20px;
    }
  }
  .list {
    height: 150px;
    margin:15px 20px 10px 23px;
    overflow-y: scroll;
    li {
      display: flex;
      clear: both;
        font-size: 92.85%;
        margin-bottom: 10px;
      p{
        flex: 1;
        line-height: 18px;
        word-break:break-all;
        em{
          font-style: normal;
        }
        .tg{
          color: #2DA823;
        }
        .jue{
          color: #D0161B;
        }
      }
      .yd{
        width: 8px;
        height: 8px;
        background: #E2E2E2;
        border-radius: 100%;
        display: block;
        margin: 3px 9px 0 0;
      }
      .time{
        color: #B0B0B0;
        margin-left:19px;
      }
    }
  }
}
</style>
