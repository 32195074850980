<template>
  <el-dialog
    :title="$t('message.calendar.cal7')"
    :visible.sync="calendartips"
    :modal-append-to-body="false"
    :append-to-body="true"
    width="600px"
    custom-class="tipsClass"
    @closed="resetData"
    @open="getValarmListData"
  >
    <div
      v-if="tableData.length"
      style="height: 100%; overflow: auto;"
    >
      <div v-if="tipdetail">
        <p>{{ $t('message.calendar.cal8') }}：{{ tipdetail.ky_summary }}</p>
        <p>{{ $t('message.calendar.cal9') }}：{{ tipdetail.ky_location||'--' }}</p>
        <p>{{ $t('message.calendar.cal10') }}：{{ tipdetail.start }}</p>
      </div>
      <el-table
        border
        :data="tableData"
        style="width: 100%"
        highlight-current-row
        :height="tipdetail?'255px':'345px'"
        size="mini"
        @row-click="rowClick"
      >
        <el-table-column
          prop="ky_summary"
          :label="$t('message.calendar.cal8')"
          align="center"
        />
        <el-table-column
          prop="start"
          :label="$t('message.calendar.cal11')"
          align="center"
        />
      </el-table>

    </div>
    <div
      v-else
      class="notips"
    >
      {{ $t('message.calendar.cal12') }}
    </div>
    <div
      slot="footer"
      class="footer-btn"
    >
      <span
        class="cur text-color"
        style="font-weight:bold"
        @click="ignoreAll"
      >{{ $t('message.calendar.cal13') }}({{ tableData.length }})</span>
      <span>
        <el-select
          v-model="value"
          :disabled="!tipdetail"
          :placeholder="$t('message.calendar.cal14')"
          style="width: 120px;margin-right: 20px;"
          @change="laterTips"
        >
          <el-option
            v-for="item in tipsModel1"
            :key="item.value"
            :label="$t(`message.calendar.${item.label}`)"
            :value="item.value"
          />
        </el-select>
        <el-button
          type="primary"
          :disabled="!tipdetail"
          @click="check"
        >{{ $t('message.calendar.cal15') }}</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import calendar from '../calendar'
export default {
  mixins: [calendar],
  data() {
    return {
      tableData: [],
      tipdetail: null,
      calendartips: false,
      value: '',
      tipsModel1: [
        {
          value: '5M',
          label: 'cal16',
        }, {
          value: '10M',
          label: 'cal17',
        }, {
          value: '15M',
          label: 'cal18',
        }, {
          value: '30M',
          label: 'cal24',
        },
        {
          value: '1H',
          label: 'cal19',
        }, {
          value: '2H',
          label: 'cal20',
        }, {
          value: '6H',
          label: 'cal21',
        }, {
          value: '12H',
          label: 'cal22',
        }, {
          value: '18H',
          label: 'cal25',
        }, {
          value: '1D',
          label: 'cal23',
        }, {
          value: '2D',
          label: 'cal26',
        },
      ],
    }
  },
  methods: {
    // 日程提醒
    async getValarmListData() {
      const res = await this.getValarmList()
      if (res.status === 200) {
        this.tableData = res.result
      }
    },
    rowClick(row, column, event) {
      console.log(column)
      this.tipdetail = row
    },
    resetData() {
      console.log('dsds')
      this.tipdetail = null
      this.value = ''
      this.$emit('on-close')
    },
    show() {
      this.calendartips = true
    },
    async laterTips() {
      const params = {
        cal_obj_id: this.tipdetail.cal_obj_id,
        valarm: this.value,
        start: this.tipdetail.start,
        end: this.tipdetail.end,
      }
      const res = await this.laterValarm(params)
      if (res.status === 200) {
        const a = this.tableData.find(item => item.cal_obj_id === this.tipdetail.cal_obj_id)
        this.tableData.splice(this.tableData.indexOf(a), 1)
        if (this.tableData.length === 0) {
          this.calendartips = false
          this.$message.success(this.$t('message.calendar.cal27'))
        }
      }
    },
    ignoreAll() {
      this.tableData = []
      this.calendartips = false
    },
    check() {
      this.calendartips = false
      this.$emit('check', this.tipdetail)
    },
  },

}
</script>

<style lang="scss" scoped>
/deep/.tipsClass{
  .el-dialog__body{
    height: 378px;
    padding: 30px 20px;
    p{
      margin-bottom: 10px;
    }
    .notips{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      color: #b6b6b6;
    }
  }
  .el-dialog__footer{
    text-align: initial;
    .footer-btn{
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
