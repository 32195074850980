<template>
  <div class="firstBox">
    <el-dialog
      :title="title"
      width="520px"
      :visible.sync="dialogMailPassword"
      :modal-append-to-body="false"
      :show-close="false"
    >
      <el-form
        ref="weakForm"
        :model="form"
        :rules="rules"
        :label-width="formLabelWidth"
        label-position="left"
      >
        <el-form-item
          :label="$t('message.view.psw')"
          prop="password"
        >
          <div
            class="showPass showPassHeight"
          >
            <p
              v-if="!eyes1"
            >
              <input
                v-model="pwdCover1"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown"
                @input="setPassword"
                @focus="tips1 = true"
                @blur="tips1 = false"
              >
              <span @click="showPwd(true,'eyes1')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.password"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @input="setPwdCover"
                @focus="tips1 = true"
                @blur="tips1 = false"
              >
              <span @click="showPwd(false,'eyes1')"><svg-icon icon-class="showPassword" /></span>
            </p>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submitForm('weakForm')"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="resetForm('weakForm')">{{ $t('message.btnText.cancel') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { PassWord, PwdCover } from '@/utils/changePassword'
import serialize from '@/utils/serialize'
export default ({
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    mailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      title: this.$t('message.view.input_psw'), // 修改弱密码
      dialogMailPassword: this.isShow,
      eyes1: false, // 是否查看密码
      cursorStart1: 0,
      cursorEnd1: 0,
      pwdCover1: '',
      tips1: false,
      form: {
        password: '',
      },
      formLabelWidth: '80px',
      rules: {
        password: [
          { required: true, message: this.$t('message.login.placeholder2'), trigger: 'blur' },
        ],

      },
      error_html: '', // 密码提示
      newMailInfo: this.mailInfo,
      passwordResult: false,
    }
  },
  watch: {
    isShow: function(a, b) {
      this.dialogMailPassword = a
      this.eyes1 = false
    },
    mailInfo: function(a, b) {
      this.newMailInfo = a
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 显示密码
    showPwd(status, eyes) {
      if (eyes === 'eyes1') {
        status ? this.eyes1 = true : this.eyes1 = false
        this.pwdCover1 = this.pwdCover1.slice(0, this.form.password.length)
      }
    },
    // 初始密码
    handlerKeydown(event) {
      this.cursorStart1 = event.target.selectionStart
      this.cursorEnd1 = event.target.selectionEnd
    },
    // 初始密码 用户登录密码框提换值
    setPassword(event) {
    // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    // this.capsTooltip = false
      if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
        // this.err_msg = true
        // this.err_msg_txt = '禁止输入中文'
        this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
      } else {
        const b = new PassWord(event, this.pwdCover1, this.form.password, this.cursorStart1, this.cursorEnd1)
        this.pwdCover1 = b.toValue()[0]
        this.form.password = b.toValue()[1]
      }
    },
    // 初始密码 用户登录密码框提换值
    setPwdCover(event) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
        // this.err_msg = true
        // this.err_msg_txt = '禁止输入中文'
        this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
      } else {
        const a = new PwdCover(this.pwdCover1, this.form.password)
        this.pwdCover1 = a.toValue()
      }
    },

    submitForm(formName) {
      const req = {
        'module': 'mail',
        'require': {
          action: 'checkMailPsw',
          uidvalidity: this.newMailInfo.uidvalidity,
          uid: this.newMailInfo.uid,
          mail_psw: this.form.password,
        }}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.passwordResult = res.result
        this.$emit('showPasswordFun', [this.passwordResult, this.form.password])
        if (res.result) {
          this.form.password = ''
          this.pwdCover1 = ''
          this.tips1 = false
        }
      })
    },
    resetForm() {
      this.dialogMailPassword = false
      this.$parent.showMailPasswordDialog = false
      this.pwdCover1 = ''
      this.form.password = ''
    },
  },
})
</script>
<style lang="scss" scoped>
@import '@/styles/password.scss';

</style>
