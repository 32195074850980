
// 密码值
export class PassWord {
  constructor(e, pwdCover, password, cursorStart, cursorEnd) {
    this.pwdCover = pwdCover
    this.password = password
    this.cursorStart = cursorStart
    this.cursorEnd = cursorEnd
    this.e = e
    this.pwdCover.length === 0 ? this.password = '' : ''
    const nDot = /[^●]/g // 非圆点字符
    let index = -1 // 新输入的字符位置
    let lastChar = void 0 // 新输入的字符
    const start = this.e.target.selectionStart // 删除时开始位置
    const length = Math.abs(this.cursorEnd - this.cursorStart)
    const realArr = this.password.split('') // 真实密码数组
    const coverArr = this.e.target.value.split('') // 文本框显示密码数组
    const coverLen = this.e.target.value.length // 文本框字符串长度
    const realLen = this.password.length // 真实密码长度

    // 找到新输入的字符及位置
    coverArr.forEach((el, idx) => {
      if (nDot.test(el)) {
        index = idx
        lastChar = el
      }
    })
    if (length === 0) {
      if (coverLen < realLen) {
        realArr.splice(start, 1)
      } else {
        realArr.splice(this.cursorEnd, 0, this.pwdCover.slice(this.cursorEnd, start))
      }
    } else {
      if (realLen < coverLen) {
        realArr.splice(index, 0, lastChar)
      } else if (coverLen <= realLen && index !== -1) {
        // 替换字符（选取一个或多个字符直接替换）
        realArr.splice(index, realLen - (coverLen - 1), lastChar)
      } else if (realLen > coverLen) {
        // 当删除●后及时删除真实密码框的长度
        realArr.splice(start, realLen - coverLen)
      }
    }
    // 将 pwdCover 替换成 ●
    const a = this.e.target.value.substr(this.e.target.value.length - 1, this.e.target.value.length)
    if (a === ' ') {
      this.e.target.value = this.e.target.value.slice(0, this.e.target.value.length - 1)
      this.pwdCover = this.pwdCover.slice(0, this.pwdCover.length - 1)
      this.pwdCover += '●'
    } else {
      this.pwdCover = this.e.target.value.replace(/\S/g, '●')
      // if (!this.e.target.value.isTrusted) {
      // }
    }
    // this.pwdCover = val.replace(/\S/g, '●')
    this.password = realArr.join('')
  }
  toValue() {
    return [this.pwdCover, this.password]
  }
}

// 用户登录密码框提换值...
export class PwdCover {
  constructor(pwdCover, password) {
    // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    this.pwdCover = pwdCover
    this.password = password
    const realArr = this.pwdCover.split('') // 虚拟实密码数组
    // 将 pwdCover 替换成 ●
    if (this.password.length > this.pwdCover.length) {
      realArr.splice(this.pwdCover.length, 1, '●')
    } else if (this.password.length < this.pwdCover.length) {
      realArr.splice(0, 1)
    }
    this.pwdCover = realArr.join('')
  }
  toValue() {
    return this.pwdCover
  }
}
// 用户登录密码框提换值...
// export class AllPassword {
//   constructor(val, password, pwdCover) {
//     // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
//     this.pwdCover = pwdCover
//     this.password = password
//     const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
//     const nDot = /[^●]/g // 非圆点字符
//     let index = -1 // 新输入的字符位置
//     let lastChar = void 0 // 新输入的字符
//     const realArr = this.password.split('') // 真实密码数组
//     const coverArr = val.split('') // 文本框显示密码数组
//     const coverLen = val.length // 文本框字符串长度
//     const realLen = this.password.length // 真实密码长度
//     // 找到新输入的字符及位置
//     coverArr.forEach((el, idx) => {
//       if (nDot.test(el)) {
//         index = idx
//         lastChar = el
//       }
//     })
//     // 判断输入的字符是否符合规范，不符合的话去掉该字符
//     if (lastChar && !reg.test(lastChar)) {
//       coverArr.splice(index, 1)
//       this.pwdCover = coverArr.join('')
//       return
//     }
//     if (realLen < coverLen) {
//       // 新增字符
//       realArr.splice(index, 0, lastChar)
//     } else if (coverLen <= realLen && index !== -1) {
//       // 替换字符（选取一个或多个字符直接替换）
//       realArr.splice(index, realLen - (coverLen - 1), lastChar)
//     } else {
//       // 删除字符，因为 val 全是 ● ，没有办法匹配，不知道是从末尾还是中间删除的字符，删除了几个，不好对 password 处理，所以可以通过光标的位置和 val 的长度来判断
//       const pos = document.getElementById('pwd').selectionEnd // 获取光标位置
//       realArr.splice(pos, realLen - coverLen)
//     }
//     // 将 pwdCover 替换成 ●
//     this.pwdCover = val.replace(/\S/g, '●')
//     this.password = realArr.join('')
//   }
//   toValue() {
//     return [this.pwdCover, this.password]
//   }
// }
// sm4加密
import md5 from 'js-md5'
import { sm4_encrypt } from './sm4'
import Hex from './Hex'
// export class PasswordSm4 {
//   constructor(username, pwd) {
//     console.log(username + 'Qr9z6c7s#')
//     // 如果是手机登录，username则代表手机号
//     this.username = username
//     this.pwd = pwd
//     this.PwdVal = ''
//     // var hex_key = Hex.utf8StrToBytes(md5($.trim(username) + 'Qr9z6c7s#').substr(8, 16))
//     // sm4加密
//     // const SM4 = require('gm-crypt').sm4
//     // const sm4Config = {
//     //   // 配置sm4参数
//     //   key: md5(this.username + 'Qr9z6c7s#').substr(8, 16), // 这里这个key值是跟后端要的
//     //   mode: 'ecb', // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，不过要是cbc的话下面还要加一个iv的参数，ecb不用
//     //   cipherType: 'base64', //
//     // }
//     const s = sm4_encrypt(pwd, md5(username + 'Qr9z6c7s#').substr(8, 16))
//     // const sm4 = new SM4(sm4Config)// 这里new一个函数，将上面的sm4Config作为参数传递进去。然后就可以开心的加密了
//     this.PwdVal = btoa(s)// 密码加密
//   }
//   toValue() {
//     return this.PwdVal
//   }
// }
function Uint8ToString(u8a) {
  var CHUNK_SZ = 0x8000
  var c = []
  for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
    c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)))
  }
  return c.join('')
}

function PasswordSm4(username, pwd) {
  this.username = username
  this.pwd = pwd
}
PasswordSm4.prototype.toValue = function() {
  var data = Hex.utf8StrToBytes(this.pwd)
  var re = data.length % 16
  if (re >= 0) {
    for (var i = 0; i < 16 - re; i++) {
      data.push(0x00)
    }
  }
  var hex_key = Hex.utf8StrToBytes(md5(this.username + 'Qr9z6c7s#').substr(8, 16))
  var s = sm4_encrypt(data, hex_key)
  // console.log("sm4加密:" + s);
  //  * base64加密
  var u8s = new Uint8Array(s)
  var result = btoa(Uint8ToString(u8s))
  // console.log("base64加密:" + result);
  return result
}
export { PasswordSm4 }
