<!-- 锁屏 -->
<template>
  <div
    ref="lock_screen"
    class="lock-screen-hide app-view-class14"
    style="position: relative;"
    :style="{backgroundImage:'url('+ $store.state.app.loginSetInfo.login_background_url+')'}"
  >
    <div
      style="background-color: #ffffff;position: absolute;top: 0;width: 100%;height: 80px;padding-left: 10%;"
    >
      <img
        :src="$store.state.app.user_permissions.web_logo"
        alt="logo"
        style="width: 550px;"
      >
    </div>
    <div class="lock-screen-main">
      <div class="lock-screen-from">
        <div class="lock-screen-user theme-background-color user-abbr-theme">{{ username.slice(0, 1) }}</div>
        <div class="lock-screen-mail">{{ username }}</div>
        <div style="display: flex;align-items: center;">
          <div class="lock-screen-password">
            <!-- <svg-icon
              icon-class="password_lock"
              style="width: 1.2em;"
            /> -->
            <!-- <input
              v-if="!eyes1"
              v-model="pwdCover1"
              class="lock-password-input noelInput"
              placeholder="请输入密码"
              autocomplete="off"
              style="font-size: 100%;"
              @keydown="handlerKeydown"
              @input="setPassword"
              @focus="tips1 = true"
              @blur="tips1 = false"
              @keyup.enter="lockScreenFun"
            >
            <input
              v-else
              v-model="form.password"
              class="lock-password-input noelInput"
              placeholder="请输入密码"
              autocomplete="off"
              style="font-size: 100%;"
              @input="setPwdCover"
              @focus="tips1 = true"
              @blur="tips1 = false"
              @keyup.enter="lockScreenFun"
            > -->
            <input
              v-model="form.password"
              class="lock-password-input noelInput"
              :placeholder="$t('message.login.placeholder2')"
              autocomplete="off"
              style="font-size: 100%;"
              :type="inputType"
              @focus="tips1 = true"
              @blur="tips1 = false"
              @keyup.enter="lockScreenFun"
            >
            <div style="width: 30px;cursor: pointer;display: flex;align-items: center;">
              <svg-icon
                v-if="!eyes1"
                icon-class="hidePassword"
                @click="showPwd(true,'eyes1')"
              />
              <svg-icon
                v-else
                icon-class="showPassword"
                @click="showPwd(false,'eyes1')"
              />
            </div>
            <el-button
              type="text"
              style="font-size: 100%;font-weight:bold;width: 50px;margin-right: 10px;"
              @click="lockScreenFun"
            >{{ $t('message.login.btnText') }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #ffffff;position: absolute;bottom: 0;width: 100%;display: flex;align-items: center;justify-content: center;font-size: 14px;padding-top: 20px;padding-bottom: 20px;">
      <div
        style="width:70%;text-align: center;"
        class="two-line-ellipsis"
      >{{ $store.state.app.loginSetInfo.copyright }}</div>
    </div>
  </div>
</template>
<script>
import { PasswordSm4 } from '@/utils/changePassword'
// import { PassWord, PwdCover, PasswordSm4 } from '@/utils/changePassword'
import serialize from '@/utils/serialize'
import { loginOut } from '@/utils/InitializePage'
export default {
  data() {
    return {
      // pwdCover1: '',
      inputType: 'password',
      form: {
        password: '',
      },
      isPW_show: false,
      eyes1: false, // 是否查看密码
      tips1: false,
      // cursorStart1: 0,
      // cursorEnd1: 0,
      lastClickTime: '',
      lock_timer: '', // 锁屏定时器
      isTimeNum: '',
      // location_origin: location.origin,
    }
  },
  computed: {
    username() {
      let username = this.$store.state.app.user_mail_address
      if (this.$store.state.app.auth_info.curr_auth_mail) username = this.$store.state.app.user_permissions.org_name
      return username
    },
  },
  created() {
    this.lastClickTime = new Date().getTime() // 获取当前时间
    // // 超时时间大于锁屏时间，本页面的超时退出时间为超时-锁屏，因为触发锁屏时已经有锁屏时间内未操作
    // this.isTimeNum = this.$store.state.app.over_time - this.$store.state.app.lock_time
    // /** 锁屏时间大于超时时间,超时退出 */
    // if (this.$store.state.app.lock_time > this.$store.state.app.over_time) {
    //   this.isTimeNum = this.$store.state.app.over_time // 超时时间，单位分钟
    // } else {
    //   this.isTimeNum = this.$store.state.app.lock_time // 锁屏时间，单位分钟
    // }
    this.isTimeNum = this.$store.state.app.over_time
    this.timeOutAction()
    const tabViews = this.$store.state.tagsView.tabViews
    for (let i = 0; i < tabViews.length; i++) {
      tabViews[i].data.cache = ''
    }
  },
  beforeDestroy() {
    clearInterval(this.lock_timer)
    this.lock_timer = ''
  },
  methods: {
    // 锁屏页面定时器
    timeOutAction() {
      // 每分钟更新判读是否超时
      this.lock_timer = setInterval(() => {
        // const nowTime = new Date().getTime() // 获取当前时间
        // if (nowTime - this.lastClickTime >= 60000 * this.isTimeNum) {
        // 提示一下用户
        this.$message({ type: 'warning', message: this.$t('message.mailmodule.overtime_logout') })
        // 这里要清除定时器，结束任务
        clearInterval(this.lock_timer)
        // 退出登录
        this.$myApi.lockScreenSet(false)
        loginOut()
        clearInterval(this.lock_timer)
        // }
      }, 60000 * this.isTimeNum)
    },
    // 显示密码
    showPwd(status, eyes) {
      if (eyes === 'eyes1') {
        status ? this.eyes1 = true : this.eyes1 = false
        this.inputType = status ? 'text' : 'password'
      }
    },
    sm4(username, pwd) {
      // sm4加密
      const SM4 = require('gm-crypt').sm4
      const sm4Config = {
        // 配置sm4参数
        key: this.$md5(username + 'Qr9z6c7s#').substr(8, 16), // 这里这个key值是跟后端要的
        mode: 'ecb', // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，不过要是cbc的话下面还要加一个iv的参数，ecb不用
        cipherType: 'base64', //
      }
      const sm4 = new SM4(sm4Config)// 这里new一个函数，将上面的sm4Config作为参数传递进去。然后就可以开心的加密了
      const Pwd = sm4.encrypt(pwd) // 密码加密
      return Pwd
    },
    lockScreenFun() {
      let username = this.$store.state.app.user_mail_address
      if (this.$store.state.app.auth_info.curr_auth_mail) username = this.$store.state.app.user_permissions.org_name
      const time = Date.parse(new Date()) / 1000
      const key = 'e0a36b45dfcg9z1fy2hx0931c35b7ffcf255389ea8062a7e630c'
      let strcode = ''
      time
        .toString()
        .split('')
        .forEach(function(el) {
          strcode = strcode + key.charAt(el)
        })
      const req = {
        'module': 'user',
        'require': {
          action: 'relieveLock',
          password: new PasswordSm4(
            username,
            this.form.password,
          ).toValue(),
          strcode: strcode,
        }}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        const err_no = res.result.err_no
        if (err_no > 0) {
          if (err_no >= 5) {
            // 退出登录
            this.$myApi.lockScreenSet(false)
            loginOut()
          } else {
            return this.$message({
              type: 'error',
              message: res.msg,
            })
          }
        } else {
          this.form.password = ''
          this.tips1 = false
          this.$myApi.lockScreenSet(false)
        }
      }).catch(res => {
        // 退出登录
        this.$myApi.lockScreenSet(false)
        loginOut()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/*去除ie edge的密码框默认出现的小眼睛，同理clear也是*/

input[type="password"]::-ms-reveal{
  display: none;
}

input[type="password"]::-ms-clear{
  display: none;
}

input[type="password"]::-o-clear{
  display: none;
}
.lock-screen-hide {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction:column;
    background-size:100% 100%;
}
.lock-screen-main{
  flex:1;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
.lock-screen-from{
    width: 400px;
    height: 250px;
    text-align: center;
}
.lock-screen-user{
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: auto;
    border-radius: 70px 70px 70px 70px;
    border: 5px solid;
    font-size: 286%;
    font-weight: 500;
    color: #FFFFFF;
}
.lock-screen-mail{
    font-size: 143%;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 25px;
}
.lock-screen-password {
    flex:1;
    // width: 100%;
    height: 42px;
    line-height: 42px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #dedede;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 12px;
  .lock-password-input{
    height: 30px;
    flex:1;
    border: none;
    margin-left: 12px;
  }
}

</style>
