<template>
  <div class="">
    <!-- <img
      alt="Vue logo"
      src="../assets/logo.png"
    > -->
  </div>
</template>

<script>
import { isFirstLogin } from '@/views/login/login'
import { PasswordSm4 } from '@/utils/changePassword'
export default {
  name: 'PrePath',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    // 判断是否为登录状态
    // 先判断有没有引用外部文件，没有的话自动跳转登录页
    const { is_login, mail, isAudit } = this.$myData.uriDatas
    this.$myApi.routeSet('/pre-path', { is_login, mail, isAudit })
    if (is_login === '1') {
      // this.$router.replace({ path: '/home-page' })
      this.$store.commit('app/IS_LOCK_SHOW_SETTING', true) // 是否显示锁屏
      console.log('邮件审核参数待确定')
      const role = this.$myData.uriDatas.isAudit ? 'viewAudit' : ''
      const userInfo = {
        user_mail: mail,
        user_pwd: new PasswordSm4(mail, 'switch').toValue(),
        user_type: 'admin', // 登录方式
      }
      this.$store.commit('app/USERINFO', userInfo)
      isFirstLogin(mail, role, 'admin')
      // 请求用户信息
    } else {
      this.$myApi.topGoto('login')
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
