<template>
  <div @click="selectFiles">
    <FileDrags
      v-if="drag"
      :on-drag="onDragFiles"
      :draginnertext="info"
    />
    <slot
      v-else
      name="trigger"
    />

    <input
      ref="input"
      class="el-upload__input"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @change="handleChange"
    >
  </div>
</template>

<script>
import SparkMd5 from 'spark-md5'
import FileDrags from './dragzone.vue'
import axios, { CancelToken } from 'axios'
import serialize from '@/utils/serialize'
import { saveObjArr, getObjArr, clearLocalStorage } from '@/utils/localstorage'
import { debounce } from '@/utils/utils'
var instance = axios.create({})
function noop() {}
export default {
  components: { FileDrags },
  provide() {
    return {
      superParams: this,
    }
  },
  props: {
    drag: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    onSuccess: {
      type: Function,
      default: noop,
    },
    onRemove: {
      type: Function,
      default: noop,
    },
    onChange: {
      type: Function,
      default: noop,
    },
    onStart: {
      type: Function,
      default: noop,
    },
    onError: {
      type: Function,
      default: noop,
    },
    limit: {
      type: Number,
      default: 0,
    },
    onExceed: {
      type: Function,
      default: noop,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    beforeUpload: {
      type: Function,
      default: undefined,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chunkSize: 4 * 1024 * 1024,
      uploadFiles: [],
      postFiles: [],
      tempIndex: 1,
      upload_id: 'uf_' + new Date().getTime(),
      fileStatus: {
        wait: 'wait',
        uploading: 'uploading',
        success: 'success',
        error: 'error',
        secondPass: 'secondPass',
        pause: 'pause',
        resume: 'resume',
      }, // 单个文件的状态
      // 单个文件的状态 对应描述
      fileStatusStr: {
        wait: '待上传',
        uploading: '上传中',
        success: '成功',
        error: '失败',
        secondPass: '已秒传',
        pause: '暂停',
        resume: '恢复',
      },
      info: this.$t('message.compose.attinfo'),
      cancels: {},
    }
  },
  watch: {

  },
  methods: {
    selectFiles() {
      if (this.disabled) return
      this.$refs.input.click()
    },
    handleChange: debounce(function(ev) {
      const files = ev.target.files
      const checkfiles = Array.prototype.slice.call(files)
      if (!files) return
      let isNull = false
      for (let index = 0; index < checkfiles.length; index++) {
        const element = checkfiles[index]
        if (element.size === 0) {
          isNull = true

          break
        }
      }
      if (isNull) {
        this.$message.error(this.$t('message.compose.err13'))
        this.$refs.input.value = null
        return false
      }
      const { limit_atta_type } = this.$store.state.app
      let limit_types = limit_atta_type.split(',')
      if (this.accept[0] === '.') {
        limit_types = limit_types.filter(item => item !== this.accept.substring(1))
      }
      console.log('limit_types', limit_types)
      const isUpload = checkfiles.filter(item => limit_types.includes(item.name.substr(item.name.lastIndexOf('.') + 1).toLowerCase()))
      if (isUpload.length > 0) {
        this.$message.error(this.$t('message.compose.format', { type: limit_atta_type }))
      }
      const canupload = checkfiles.filter(item => !limit_types.includes(item.name.substr(item.name.lastIndexOf('.') + 1).toLowerCase()))
      if (!this.beforeUpload) {
        return this.handleFiles(canupload)
      }
      const before = this.beforeUpload(Array.prototype.slice.call(canupload))
      if (before && before.then) {
        before.then(files => {
          this.handleFiles(files)
        })
      } else if (before && before !== false) {
        this.handleFiles(canupload)
      } else {
        this.$refs.input.value = null
      }
    }, 100),
    handleFiles(files) {
      if (this.limit && files.length > this.limit) {
        this.onExceed && this.onExceed(files, this.uploadFiles)
        return false
      }
      this.postFiles = [...this.postFiles, ...Array.prototype.slice.call(files)]

      if (!this.multiple) { this.postFiles = this.postFiles.slice(0, 1) }

      if (this.postFiles.length === 0) { return }
      this.onChange(files)
      if (this.autoUpload) {
        this.postFiles.forEach(rawFile => {
          this.handleStart(rawFile)
        })
      }
    },
    handleStart(rawFile) {
      rawFile.uid = this.upload_id + '-' + this.tempIndex
      const chunks = this.createFileChunk(rawFile)
      const file = {
        status: null,
        name: rawFile.name,
        size: rawFile.size,
        percentage: 0,
        uid: rawFile.uid,
        id: this.tempIndex,
        raw: rawFile,
        chunklist: chunks,
        hash: null,
        hashProgress: 0,
        requests: [],
        type: 'splitupload',
        origin: 'loc_upload',
      }
      this.tempIndex++
      this.uploadFiles.push(file)
      if (this.autoUpload) {
        this.onStart && this.onStart([file])
      }
      // this.createFileChunk(rawFile)
      this.calculateHash(rawFile)
    },
    // 提交要上传的文件
    submit() {
      this.postFiles.forEach(rawFile => {
        this.handleStart(rawFile)
      })
      this.onStart && this.onStart(this.uploadFiles)
    },
    upload() {
      this.$refs.input.value = ''
      if (!this.uploadFiles) return
      this.postFiles = []
      this.uploadFiles.filter(({ status }) => status === 'wait').forEach(rawFile => {
        const { chunklist, id, name, size, hash } = rawFile
        chunklist.map((chunk, index) => {
          const formData = new FormData()
          const rtt = new Blob([chunk.file])
          formData.append('file', rtt)
          const options = {
            headers: {
              fileCheck: hash,
              fileId: id,
              fileMod: 'html5',
              fileName: encodeURIComponent(name),
              fileOffset: this.chunkSize,
              fileSize: size,
              fileSplit: true,
              fileStart: index * this.chunkSize,
              md5: hash,
              abort: 'false',
              'Content-type': 'multipart/form-data',
            },
            data: formData,
          }
          chunk.options = options
        })
        this.post(rawFile)
      })
    },
    post(file) {
      const { uid, chunklist } = file
      const currentFile = this.getFile(file)
      currentFile.status = this.fileStatus.uploading
      const requestDataList = chunklist
        .filter(({ uploaded }) => !uploaded)
      const total = requestDataList.length
      let finished = 0
      const cancel = []
      const handler = () => {
        if (finished < requestDataList.length) {
          const form = requestDataList[finished].options
          const upid = {
            upload_id: this.upload_id,
            ...this.data,
          }
          this.setAxios(form.headers)
          instance.post(`/nsmail/mail.php?task=upload&req_type=json&req=${encodeURIComponent(serialize(upid))}`, form.data, {
            cancelToken: new CancelToken((c) => {
              cancel.push(c)
              this.cancels[uid] = cancel
            }),
            onUploadProgress: this.createProgresshandler(requestDataList[finished], file),
            timeout: 0,
          }).then(({ data }) => {
            if (data.status) {
              this.addChunkStorage(uid, finished)
              if (data.fileQuota === '秒传') {
                this.onSuccess({
                  name: file.name,
                  type: 'upload',
                  size: file.size,
                  md5: file.hash,
                  upid: uid,
                }, currentFile, this.uploadFiles)
                finished = requestDataList.length
                currentFile.status = this.fileStatus.success
                currentFile.percentage = 100
              } else {
                requestDataList[finished].uploaded = true
                finished++
                handler()
              }
            } else {
              currentFile.status = this.fileStatus.pause
              this.onError(data.fileQuota, currentFile, this.uploadFiles)
            }
          }).catch(err => {
            currentFile.status = this.fileStatus.pause
            this.onError(err, currentFile, this.uploadFiles)
          })
        }
        if (finished >= total) {
          currentFile.status = this.fileStatus.success
          this.onSuccess({
            name: file.name,
            type: 'upload',
            size: file.size,
            md5: file.hash,
            upid: uid,
          }, currentFile, this.uploadFiles)
          clearLocalStorage(uid)
        }
      }
      handler()
    },
    // 根据uid获取文件
    getFile(rawFile) {
      const fileList = this.uploadFiles
      let target
      fileList.every(item => {
        target = rawFile.uid === item.uid ? item : null
        return !target
      })
      return target
    },
    // 创建文件切片
    createFileChunk(file) {
      const fileChunkList = []
      var count = 0
      while (count < file.size) {
        fileChunkList.push({
          file: file.slice(count, count + this.chunkSize),
          progress: 0,
          uploaded: false,
          options: null,
        })
        count += this.chunkSize
      }
      return fileChunkList
    },
    // 生成文件 hash（web-worker）
    calculateHash(file) {
      const _self = this
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunks = Math.ceil(file.size / this.chunkSize)
      let percentage = 0
      let currentChunk = 0
      const spark = new SparkMd5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        const currentFile = _self.getFile(file)
        if (currentFile) {
          spark.append(e.target.result)
          currentChunk++
          if (currentChunk < chunks) {
            percentage = currentChunk / chunks * 100
            currentFile.hashProgress = Number(percentage.toFixed(0))
            loadNext()
          } else {
            const md5 = spark.end()
            currentFile.hashProgress = 100
            currentFile.hash = md5
            currentFile.status = _self.fileStatus.wait
            _self.upload()
          }
        }
      }

      fileReader.onerror = function(e) {
        console.warn(e)
      }

      function loadNext() {
        const start = currentChunk * _self.chunkSize
        let end = start + _self.chunkSize
        if (end > file.size) {
          end = file.size
        }
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    },
    // md5是否是否全部计算完成
    isCalculateHashComplete() {
      const isSuccess = this.uploadFiles.every(file => file.hashProgress === 100)
      return isSuccess
    },
    isCompleted() {
      if (this.uploadFiles.length === 0) return true
      const isSuccess = this.uploadFiles.every(file => file.percentage === 100)
      return isSuccess
    },
    // axios 设置axios参数
    setAxios(ContentType) {
      for (const i in ContentType) {
        instance.defaults.headers.common[i] = ContentType[i]
      }
    },
    // 继续上传
    handleResume(rawFile) {
      this.post(rawFile)
    },
    // 暂停上传
    handlePause(rawFile) {
      const { uid } = rawFile
      const currentFile = this.getFile(rawFile)
      currentFile.status = this.fileStatus.pause
      while (this.cancels[uid].length > 0) {
        this.cancels[uid].pop()('取消请求')
      }
    },
    // 中断请求
    abort(rawFile) {
      const { uid } = rawFile
      while (this.cancels[uid]?.length > 0) {
        this.cancels[uid].pop()('取消请求')
      }
    },
    // 删除文件
    handleRemove(rawFile) {
      const currentFile = this.getFile(rawFile)
      const fileList = this.uploadFiles
      fileList.splice(fileList.indexOf(currentFile), 1)
      this.onRemove(currentFile, this.uploadFiles)
      this.abort(currentFile)
    },
    // 清空上传文件
    clearFiles() {
      this.uploadFiles = []
      this.postFiles = []
      this.$refs.input.value = null
    },
    // 删除要上传的文件列表
    deleteFileList(file) {
      this.postFiles.splice(this.postFiles.indexOf(file), 1)
      this.onChange(this.postFiles)
    },
    // 切片上传进度
    createProgresshandler(item, file) {
      return (p) => {
        item.progress = parseInt(String((p.loaded / p.total) * 100))
        this.fileProgress(file)
      }
    },
    // 文件总进度
    fileProgress(file) {
      const currentFile = this.getFile(file)
      if (currentFile) {
        const uploadProgress = currentFile.chunklist.filter(({ progress }) => progress === 100).map(item => item.file.size * item.progress).reduce((acc, cur) => acc + cur, 0)
        var currentFileProgress = parseInt((uploadProgress / currentFile.size).toFixed(2))
        currentFile.percentage = currentFileProgress
      }
    },
    // 存储已上传完成的切片下标
    addChunkStorage(name, index) {
      const data = [index]
      const arr = getObjArr(name)
      if (arr) {
        saveObjArr(name, [...arr, ...data])
      } else {
        saveObjArr(name, data)
      }
    },
    // 获取已上传完成的切片下标
    getChunkStorage(name) {
      return getObjArr(name)
    },
    onDragFiles(files) {
      this.handleFiles(files)
    },
  },
}
</script>

