import { loginInit } from './InitializePage'
import { deepCopy } from './utils'

let lastRoutePath = ''

const buildRoutePathFragment = (module, data) => {
  let path = '#' + module
  // 验证: data是不是很简单的格式
  let isSimple = false
  for (; ;) {
    if (typeof data !== 'object') {
      break
    }
    let existNoneString = false
    let k
    for (k in data) {
      if (typeof data[k] !== 'string') {
        existNoneString = true
        break
      }
    }
    if (existNoneString) {
      break
    }
    isSimple = true
    break
  }
  //
  if (isSimple) {
    const ps = []
    let k
    for (k in data) {
      ps.push(k + '=' + encodeURIComponent(data[k]))
    }
    if (ps.length) {
      path += '?' + ps.join('&')
    }
  } else {
    if (data && JSON.stringify(data) !== '{}') {
      path += '?' + JSON.stringify(data)
    }
  }
  return path
}

const topGotoByRedirect = (module, data, options) => {
  let url = window.location.href
  url = url.split('?')[0].split('#')[0]
  url = url + buildRoutePathFragment(module, data)
  window.location = '?redirect#' + encodeURIComponent(url)
}

// 添加路由 data 必须是一个对象{} module 是模块路由
const routeSet = (module, data, options) => {
  data = deepCopy(data)
  if (window._kglobal_myData.routeHistroy.module === module && window._kglobal_myData.routeHistroy.data === JSON.stringify(data)) {
    // console.log('重复了')
    return false
  }
  window._kglobal_myData.routeHistroy.module = module
  window._kglobal_myData.routeHistroy.data = JSON.stringify(data)
  // console.log('添加记录一条', module, window._kglobal_myData.routeHistroy.module)
  const obj = { module }
  obj.data = data || {}
  let pathObj = data
  if (options && options.pathArgs) {
    pathObj = options.pathArgs
  }
  const path = buildRoutePathFragment(module, pathObj)

  obj.module = module
  obj.path = path
  lastRoutePath = path
  history.pushState(obj, null, path)
  if (module === 'login') {
    // 手动输入登录地址是重新设置history
    history.pushState({}, null, '#' + 'login')
  }
}
const appFirstGogo = () => {
  // 判断是不是redirect
  for (; ;) {
    const hs = window.location.href.split('?redirect#', 2)
    if (hs.length !== 2) {
      break
    }
    if ((hs[0].split('#', 2) > 1) || (hs[0].split('?', 2) > 1)) {
      break
    }
    const redirect = decodeURIComponent(hs[1])
    if (redirect.length < 1) {
      break
    }
    console.log('redirect to', redirect)
    window.location = redirect
    return
  }
  //
  if (location.href.indexOf('#') < 0) {
    return window._kglobal_myApi.topGoto('login')
  }
  const hrefArr = location.href.split('#', 2)
  let uriModule = hrefArr[1]
  let uriData = {}
  if (uriModule.indexOf('?') > -1) {
    const uriArr = uriModule.split('?', 2)
    uriModule = decodeURIComponent(uriArr[0])
    let args = decodeURIComponent(uriArr[1])
    // console.log(args, 'args')
    if (args[0] === '{' || args[0] === '"') {
      try {
        uriData = JSON.parse(args)
      } catch {
        uriData = {}
      }
    } else {
      args = uriArr[1]
      const ps = args.split('&')
      ps.forEach(p => {
        const kv = p.split('=', 2)
        if (kv.length === 1) {
          return
        }
        uriData[kv[0]] = decodeURIComponent(kv[1])
      })
    }
  } else {
    uriModule = decodeURIComponent(uriModule)
  }
  window._kglobal_myData.uriDatas = uriData
  window._kglobal_myData.uriModule = uriModule
  let needAuth = false
  let needLogin = false
  switch (uriModule) {
    case undefined:
    case null:
    case 'login':
    case '/':
      needLogin = true
      break
    case '/pre-path':
    case 'forgetPassword':
    case 'lock-screen':
    case '/netdisk_share':
    case '/convert-view':
    case '/api-view/':
    case '/clientLogin':
    case '/audit':
    case '/banner-view/':
      break
    default:
      needAuth = true
  }
  if (needAuth) {
    loginInit(() => {
      window._kglobal_myApi.topGoto(uriModule, uriData, { firstGogo: true })
    })
    return
  }
  if (needLogin) {
    window._kglobal_myApi.topGoto('login', {}, { firstGogo: true })
    return
  }
  window._kglobal_myApi.topGoto(uriModule, uriData, { firstGogo: true })
}

export function routeInit() {
  window.onpopstate = function(e) {
    if (!e || !e.state) {
      return
    }
    // 可以在这里处理页面跳转逻辑
    if (e.state.module === 'login') {
      // 点击上下文历史记录时重新设置history
      history.pushState({}, null, '#' + 'login')
    }
    const tmpPath = lastRoutePath
    lastRoutePath = e.state.path || ''
    // 根据路由跳转 isHistroy是否从点击历史记录调用
    window._kglobal_myApi.topGoto(e.state.module, e.state.data, { isHistory: true, lastRoutePath: tmpPath })
  }
  window._kglobal_myApi.buildRoutePathFragment = buildRoutePathFragment
  window._kglobal_myApi.topGotoByRedirect = topGotoByRedirect
  window._kglobal_myApi.routeSet = routeSet
  // window._kglobal_myApi.routeGoto = routeGoto
  window._kglobal_myApi.appFirstGogo = appFirstGogo
}
