// 定义内容
export default {
  login: {
    usermenu: 'User manual',
    admmenu: 'Administrator manual',
    userlogin: 'User Login',
    admlogin: 'Administrator Login',
    placeholder1: 'please enter your email account',
    placeholder2: 'please enter password',
    placeholder3: 'Please enter your phone number',
    placeholder4: 'Please enter the verification code',
    placeholder5: 'Please enter the PIN code',
    placeholder6: 'Please enter your email account（eg:user@example.com）',
    placeholder7: 'Please select a question',
    placeholder8: 'Please enter answer',
    remmberusername: 'Remember username',
    remmberaccount: 'Remember account',
    ssllogin: 'SSL login',
    forgetpsw: ' Forget password',
    btnText: 'Sign in',
    step1: 'Enter your email account',
    step2: 'Verify identidy',
    step3: 'Set new password',
    step4: 'Reset password successfully',
    mailaccount: 'Email account',
    resetbtx: 'Reset',
    confirminfo1: 'To confirm that it was your own operation, please complete identity verification:',
    confirminfo2: 'Verified by security question:',
    newpsw: 'New password',
    selectq: 'Select question',
    inputa: 'Enter answer',
    confirmbtx: 'OK',
    setsuccess: 'Setup successful',
    singinagain: 'Account password reset successfully, please sign in again.',
    back: 'Return to sign-in page',
    q1: 'Where was your birthplace?',
    q2: `What is your father's name?`,
    q3: `What is your mother's name?`,
    q4: `What is your spouse's name?`,
    q5: `What's your birthday?`,
    q6: `What is your city of work?`,
    q7: 'Custom questions',
    confirmpsw: 'Confirm Password',
    inputmail: 'Please enter an email address',
    inputrightmail: 'Please enter the correct email address',
    inputcontent: 'Please enter content',
    err1: 'Please enter the new password again.',
    err2: 'The password length does not meet the requirements.',
    err3: 'The passwords entered twice are not the same.',
  },
  btnText: {
    save: 'Save',
    cancel: 'Cancel',
    ok: 'OK',
    info: 'Prompt',
    close: 'Close',
    swipecode: 'Swipe right to get the CAPTCHA',
  },
  info: {
    m1: 'Operation successful',
  },
  mainpage: {
    home: 'Home',
    mail: 'Email',
    addressbook: 'Contacts',
    calendar: 'Calendar',
    netdisk: 'Drive',
    setting: 'Settings',
    lock: 'Lock screen',
    help: 'Help manual',
    changepassword: 'Change password',
    logginout: 'Log out',
    authorized: 'Switch authorization email',
    authorizedmy: 'Return to my email',
    placeholder: 'Please enter keywords',
    advanced: 'Advanced Search',
    quote: 'quotes',
    to: '-',
    sdate: 'Start Date',
    edate: 'End Date',
    togglemannger: 'Switch to Administrator Platform',
  },
  compose: {
    adv1: 'Subject',
    adv2: 'Body',
    adv3: 'Date',
    adv4: 'Quotes',
    subject: 'Subject',
    sub: 'Subject',
    body: 'Body',
    from: 'Sender',
    to: 'To',
    cc: 'Cc',
    bcc: 'Bcc',
    attsname: 'Attachment name',
    date: 'Date',
    send: 'Send',
    timesend: 'Schedule Send',
    settimesend: 'Schedule Send',
    savedraft: 'Save as Draft',
    priview: 'Preview',
    closepeiview: 'Close',
    cancel: 'cancel',
    single: 'A mass of single show',
    unsingle: 'Cancel a mass of single show',
    contact: 'contacts',
    addBcc: 'Add Bcc',
    unaddBcc: 'Delete Bcc',
    addatts: 'Attach file',
    noneatts: 'None',
    download: 'Download',
    del: 'Delete',
    goon: 'Continue',
    scan: '{num}% in file scan%',
    numatts: '{num} files',
    singn: 'Signature',
    saveto: `Save to 'Sent Items'`,
    emergent: 'Urgent',
    receipt: 'Receipt Required',
    securemail: 'Secure mail',
    secureprop: 'Security attribute',
    watermark: 'Enterprise watermark',
    destroy: 'Burn After Reading',
    setpsw: 'Set email password',
    cancelset: 'Unset',
    changeset: 'Modify Settings',
    big: 'Big',
    loaclfile: 'Browse this computer',
    myfile: 'My files',
    netfile: 'Drive',
    fileman: 'Attachment Management',
    nodate: 'No data',
    filetrans: 'File Transfer Station',
    superatt: 'Large accessories',
    mailpsw: 'Email password',
    sendto: 'Send to',
    atts: 'Attachment',
    viewatts: 'Check the attachment',
    nums: '{num}',
    more: 'More',
    info1: 'The recipient will be prohibited from forwarding, replying, copying, printing, downloading, and other operations on this email content ',
    info2: 'Only supports preview of attachments of the following types: txt, pdf, png, jpe, jpeg, jpg, gif. Other types of attachments may not be viewable. Please be cautious when using secure attribute emails to send email with attachments.',
    info3: 'After turned on the burn after reading，the email is read once, the original email content will be destroyed',
    info4: 'Set the validity period of the email to be read. After the time limit is exceeded, the email cannot be viewed',
    info5: 'If you select the email password mode to send an email, the entire email will be encrypted, and you need to use the password to read it',
    info6: 'The password contains 6 to 32 digits, letters, and special characters',
    info7: 'The email password cannot be empty',
    info8: 'Please set a custom time',
    info9: ' You have set the secure email mode',
    info10: 'You have set the secure email mode, the email will not be forwarded, reply, copy, print, download and other operations, and will expire on {date}',
    info11: 'The attachment is being uploaded and will be sent automatically after uploading',
    info12: 'Cancel auto send',
    info13: '{num} files are too large, attachments will be uploaded as cloud attachments,',
    info14: '{num} days valid, if deleted or expired, recipients will not be able to download!',
    info15: 'Please select',
    info16: 'After the setting is successful, click the "Send" button on the letter page, and the mail will be temporarily stored in the draft box and sent at the set time',
    info17: 'Send time',
    info18: 'Select date',
    info19: 'Select time',
    info20: 'Send time zone',
    info21: 'Select date time',
    info22: 'Expiration time',
    info23: 'A mass of single show is sent one to one separately. Each recipient can only see his or her own email address',
    info24: 'The selected date and time have expired',
    info25: 'If you select the email password mode to send an email, the entire email will be encrypted, and you need to use the password to read it',
    info26: 'Attachment is being uploaded, are you sure to interrupt or abandon action on this letter?',
    info27: 'Scheduled sending has been cancelled',
    info28: 'The expired email time you set has expired',
    info29: 'Email password cannot be empty',
    info30: 'The email password must contain at least six characters',
    info31: 'The email password is invalid',
    info32: 'You have set the secure email mode, the email will not be forwarded, reply, copy, print, download and other operations, and set the burn after reading property',
    info33: 'You have set the secure email mode, the email will not be forwarded, reply, copy, print, download and other operations, and will expire on {time}',
    info34: 'You have set the secure email mode, and the email has been turned on the "burn after reading" and "prohibit operation" attributes enabled, and will expire on {time}',
    info35: 'You have set the secure email mode, the email will not be forwarded, reply, copy, print, download and other operations',
    info36: 'This operation will delete the file permanently. Do you want to continue?',
    info37: 'Note: Some attachment types in secure emails may not be previewed.',
    info38: 'Click here to view the attachment types that support preview.',
    info39: 'Currently no attachment type support preview!',
    day0: 'Unlimited',
    day1: '1 day',
    day3: '3 days',
    day7: '7 days',
    day8: 'Custom',
    day9: '3 days',
    day10: '7 days',
    day11: '1 day',
    err1: 'Please enter at least one recipient address',
    err2: 'The To contains an incorrect email address',
    err3: 'The CC contains the wrong email address',
    err4: 'The BCC contains the wrong email address',
    err5: 'Sending failed, the email expiration time is earlier than the current sending time',
    err6: 'The time you set for sending emails has expired',
    err7: 'Attachment not uploaded, please confirm whether to continue sending?',
    err8: 'The subject is empty, please confirm whether to continue sending?',
    err9: 'Writing a letter, do you want to save the draft before closing?',
    err10: 'If the scheduled sending time is later than the email expiration time, the email cannot be read normally. Do you want to continue sending?',
    err11: 'Unselected file',
    err12: 'Upload failed. The total size of the attachment exceeded the limit of {num}M',
    err13: 'Uploading empty files is prohibited',
    err14: 'Invalid email address',
    success: 'Successfully sent',
    success1: 'Successfully saved the draft',
    neverexpire: 'Permanent',
    fw: 'Fw：',
    ry: 'Re：',
    label0: 'Auto',
    label1: 'UTC-12:00 (Baker Island)',
    label2: 'UTC-11:00 (Midway Islands)',
    label3: 'UTC-10:00 (Honolulu)',
    label4: 'UTC-09:30 (The Marquesas Islands)',
    label5: 'UTC-09:00 (Anchorage)',
    label6: 'UTC-08:00 (Los Angeles)',
    label7: 'UTC-07:00 (Denver)',
    label8: 'UTC-06:00 (Chicago)',
    label9: 'UTC-05:00 (New York)',
    label10: 'UTC-04:00 (Santiago)',
    label11: 'UTC-03:00 (Buenos Aires)',
    label12: 'UTC+01:00 (Paris)',
    label13: 'UTC+03:00 (Moscow)',
    label14: 'UTC+04:00 (Dubai)',
    label15: 'UTC+05:30 (New Delhi)',
    label16: 'UTC+07:00 (Bangkok)',
    label17: 'UTC+08:00 (Peking)',
    label18: 'UTC+09:00 (Tokyo)',
    label19: 'UTC+10:00 (Sydney)',
    label20: 'UTC+12:00 (Auckland)',
    attinfo: 'Drive, drag or paste images from the network (ctrl+v),Upload from Computer',
    all: 'All',
    img: 'Image',
    music: 'Audio',
    video: 'Vedio',
    doc: 'Document',
    zip: 'Compressed file',
    other: 'Others',
    mydriev: 'My Drive',
    format: 'Prohibit uploading {type} format files',
  },
  homepage: {
    lingchen: 'Good morning!',
    zaoshang: 'Good morning!',
    shangwu: 'Good morning!',
    zhongwu: 'Good afternoon!',
    xiawu: 'Good afternoon!',
    wanshang: 'Good evening!',
    unread: 'Unread Emails',
    stared: 'Starred Emails',
    notification: 'Review notifications',
    track: 'Email Tracking',
    maillimit: 'Email Limit',
    mailcapacity: 'Mailbox Capacity',
    tansfercapacity: 'File Transfer Station Capacity',
    drivecapacity: 'Drive Capacity',
    limit: 'Limit',
    used: 'Used',
    total: 'Total',
    recentlogin: 'Recent login',
    recentloginstatus: 'recent login status',
    lastchangepsw: 'Last password change',
    lastchangedpsw: 'last password changed',
    mysetting: 'My settings',
    autoreplaysetting: 'Email auto reply',
    clientauthcode: 'Email client authorization code',
    autoforword: 'Email auto forward',
    forword: 'Forward to',
    envelope: ' ',
    on: 'on',
    off: 'off',
    noEmailPermission: 'No email permission, unable to view',
  },
  mbox: {
    newmail: 'New Email',
    receiveemail: 'Receive Email',
    inbox: 'Inbox',
    sentitems: 'Sent',
    drafts: 'Drafts',
    deleteditems: 'Deleted Items',
    starred: 'Starred',
    unread: 'Unread',
    archive: 'Archive',
    junkemail: 'Junk Email',
    emailtracking: 'Email Tracking',
    reviewnotifications: 'Review notifications',
    attachmentmanagement: 'Attachment Management',
    ftStation: 'File Transfer Station',
    myfolders: 'My Folders',
    labelmail: 'Tag',
  },
  maillist: {
    delete: 'Delete',
    deleteper: 'Delete Permanently',
    move: 'Move to',
    mark: 'Mark',
    unread: 'Unread',
    read: 'Read',
    stared: 'Starred',
    unstar: 'Unstar',
    top: 'Display on Top',
    untop: 'Unpinned',
    tag: 'Assign Tags',
    untag: 'Untag',
    forword: 'Forward',
    forwordasatt: 'Forward as attachment',
    filter: 'Filter',
    sort: 'Sort',
    bytimen2o: 'By time from new to old',
    bytimeo2n: 'By time from old to new',
    btfromdesc: 'In descending order by sender',
    btfromasc: 'In ascending order by sender',
    bysubdesc: 'In descending order by subject',
    bysubasc: 'In ascending order by subject',
    bysizedesc: 'In descending order by size',
    bysizedasc: 'In ascending order by size',
    urgent: 'Urgent',
    hasatt: 'Has attachment',
    hasnotes: 'Has notes',
    all: 'All',
    more: 'More',
    download: 'Download Email',
    upload: 'Upload Email',
  },
  netdisk: {
    diskshare: 'Network disk sharing',
    diskfshare: 'Network disk sharing',
    dir: 'Directory',
    noname: 'No file name',
    nofname: 'No folder name',
    dayslayer: 'days later',
    expired: 'expired',
    folder: 'Folder',
    file: 'File',
    all: 'All',
    image: 'Image',
    audio: 'Audio',
    vodeo: 'Video',
    doc: 'Document',
    zip: 'Compressed File',
    other: 'others',
    upload: 'Upload',
    createfolder: 'Creat Folder',
    delete: 'Delete',
    move: 'Move to',
    sendmail: 'Send Email',
    share: 'Share',
    download: 'Download',
    rename: 'Rename',
    mydrive: 'My Drive',
    filename: 'Name',
    modifiedtime: 'Modified',
    type: 'type',
    size: 'size',
    myshare: 'My shared files',
    used: 'Used Drive Capacity',
    total: 'Total',
    Unshare: 'Unshare',
    copylink: 'Copy Link',
    sharename: 'Name',
    sharetime: 'Share Time',
    expiretime: 'Expiration',
    unexpire: 'Period of validity',
    sharefile: 'Share Files',
    crearelink: 'Create link',
    checktimes: 'View Times',
    downloadtimes: 'Download Times',
    confirmselfiles: 'Are you sure to delete the selected files? ',
    movetof: 'Move to',
    copysend: 'Copy to send email',
    copylinkcode: 'copy link and access code',
    mv: 'Move',
    result: 'Search result',
    namenotnull: 'Name cannot be empty',
    canceldel: 'Cancelled deletion',
    delsuccess: 'Deleted successfully',
    cresuccess: 'Created successfully',
    mvsuccess: 'Moved successfully',
    resuccess: 'Renamed  successfully',
    link: 'Link',
    code: 'Extract code',
    info1: 'Link copied successfully',
    info2: 'After canceling sharing, the selected link will not be accessible and downloaded. Are you sure to cancel sharing?',
    info3: 'Operation canceled',
    info4: 'Cancel sharing successfully',
    info5: 'The shared file has been cancelled or expired',
    day1: '1 day',
    day2: '7 days',
    day3: '30 days',
    day4: 'Permanently valid',
    err1: 'Please select a file ',
    err2: 'Destination directory that cannot be empty',
    err3: 'Not supported / : * ? < > |',
    err4: 'Please select a file or folder',
    err5: 'Please select a download file',
    err6: 'Only supports selecting files',
    err7: 'The selected file size for online sharing exceeds 200M. Please download it in batches or individually',
    filenum: '{num}documents,etc',
    drivesharelink: 'Network disk sharing link',
    sharepsw: 'Please enter the sharing password',
  },
  contact: {
    group: 'Group',
    enpcontact: 'Enterprise Contacts',
    orgcontact: 'Organization Contacts',
    percontact: 'Personal Contacts',
    commoncontact: 'Frequent Contacts',
    maillist: 'Emailing list',
    maillistname: 'Maillist name',
    allcontact: 'All contacts',
    ungroup: 'Ungrouped',
    newcontact: 'New contact',
    editcontact: 'Edit contact',
    newgroup: 'New contact groups',
    newfenzu: 'New group',
    editgroup: 'Edit Contact Group',
    delgroup: 'Delete Contact Group',
    importcontact: 'Imported contacts',
    outcontact: 'External contacts',
    info1: 'This group currently has no contacts',
    info2: 'No contacts',
    info3: 'Confirm deletion',
    info4: 'After deleting the group, the contacts within the group will be disbanded to all contacts.',
    info5: 'Synchronize deletion of contacts belonging to this group in the address book.',
    info6: 'Successfully deleted contact group',
    info7: 'Please enter a group name',
    info8: 'Display name does not support English semicolons, single quotes, double quotes,<,>,/, %& ￥ special characters!',
    info9: 'Group name not compliant',
    info10: 'Created successfully',
    info11: 'Please enter the correct contact information',
    info12: 'Display name cannot be empty',
    info13: 'Email address cannot be empty',
    info14: 'Please enter the correct email address',
    info15: 'The display name is illegal',
    info16: 'Successfully edited',
    info17: 'Please enter a group name',
    info18: 'Successfully copied',
    info19: 'Do you want to delete contact {person}?',
    info20: '{num} contacts have been selected. Are you sure you want to delete them?',
    info21: '{num} contacts have been selected. Do you want to delete them within the group?',
    info22: 'Added {num} person to personal address book',
    more: 'More',
    retract: 'Collapse',
    select: 'Select',
    unselect: 'Deselect',
    allselect: 'Select All',
    num: '{num} contacts',
    addperon: 'Add to personal Contacts',
    tocompose: 'To compose',
    depart: 'Department',
    mailaddress: 'Email address',
    crossmail: 'Correspondence',
    compose: 'Compose',
    edit: 'Edit',
    del: 'Delete',
    mv: 'Move',
    copy: 'Copy',
    export: 'Export',
    copymail: 'Copying Email address',
    ckcrossmail: 'Viewing correspondence',
    selectgroup: 'Selection group',
    displayname: 'Display name',
    phone: 'Phone',
    addphone: 'Add new phone number',
    quote: 'Note',
    unit: 'Company',
    noresult: 'No search results',
    placeholder1: 'Enter group name',
    placeholder2: 'Please enter the contact group name',
    member: 'Member',
    view: 'View',
    unview: 'View not allowed',
    memberlist: 'Mail member list',
    dynamiclist: 'Dynamic member',
    designlist: 'Specify members',
    externallist: 'External members',
    departmail: 'Department email',
    importgroup: 'Import to group',
    selectimportfile: 'Select the file to import',
    repeatmail: 'If encountering records with duplicate addresses',
    selectfile: 'Select file',
    tips: 'Please import in template format, only supports. csv format files.',
    downtips: 'Download CSV format document',
    unimport: 'Do not import',
    repeat: 'Overwrite',
    reflect: 'Please do field mapping',
    selcctreflect: 'Please select a mapping',
    importsuccess: 'Import completed',
    gocheck: 'Go check',
    konw: 'I see',
    imported: 'Successfully imported {num} items,',
    conflicts: 'Address conflicts {num},',
    error1: 'Format error {num} entries.',
    error2: 'Download error',
    error3: 'Please choose to upload a file!',
    error4: 'Please select a contact group',
    error5: 'List of email format errors',
    error6: 'Only documents in. csv format are supported',
    importinfo1: 'Import succeeded',
    importinfo2: 'Address conflict',
    importinfo3: 'Format error',
    clear: 'Clear All',
    addto: 'Add to ',
    placeholder: 'Enter name/email address to search for contacts',
    participant: 'Participant',
  },
  setting: {
    opnetdisk: 'Operation netdisk',
    optranfer: 'Operation transfer station',
    tipsset: 'Reminder settings',
    mailmove: 'Email move',
    accountsec: 'Account and Security',
    changepsw: 'Change password',
    changeque: 'Modify security',
    checklog: 'Self service log query',
    auditlog: 'Audit record query',
    clientset: 'Client configuration',
    auth: 'Authorization management',
    mailset: 'Email settings',
    displayset: 'Display settings',
    sendreve: 'Sending and receiving settings',
    revrule: 'Receiving rules',
    quickreply: 'Quick reply',
    autoreply: 'Automatic reply',
    autoforword: 'Automatic forwarding',
    blacklist: 'Blacklist',
    whitelist: 'Whitelist',
    signset: 'Signature settings',
    tagset: 'Label management',
    floderset: 'Folder settings',
    notify: 'Message notification',
    general: 'General settings',
    skinset: 'Skin settings',
    emaiPassword: 'Email password',
    oldpsw: 'Old password',
    new: 'New password',
    confirmpsw: 'Confirm password',
    info1: 'Password successfully modified',
    info2: 'Please enter your email login password to verify your identity before modifying the security',
    info3: 'Successfully modified security',
    err1: 'Please enter the initial password',
    err2: 'Please enter a new password',
    err3: 'IP address non-compliance',
    operator: 'Operator',
    operatorip: 'Operation IP',
    operatortime: 'Operation time',
    operatortype: 'Operation type',
    operatorcontent: 'Operation content',
    clicksearch: 'Click here to search',
    viewlog: 'Log record query',
    p1: 'Please select an operation type',
    operate1: 'Login',
    operate2: 'Quit',
    operate3: 'Send email',
    operate4: 'Read email',
    operate5: 'Operation email',
    operate6: 'Operating contacts',
    operate7: 'Operation attachment',
    operate8: 'Set a property',
    operate9: 'Risk operations',
    operate10: 'Switch authorization email',
    operate11: 'Recall email',
    operate12: 'Review email',
    operate13: 'Delete email',
    fzset: 'Font size setting',
    timeformat: 'Time display format',
    h12: '12 hour system',
    h24: '24-hour system',
    langset: 'Language settings',
    zh: 'Simplified Chinese',
    zhf: 'Traditional Chinese',
    en: 'English',
    saveinfo: 'Successfully saved',
    skinstyle: 'Skin style',
    sk01: 'Default',
    sk02: 'Skin 02',
    sk03: 'Skin 03',
    sk04: 'Skin 04',
    sk05: 'Skin 05',
    sk06: 'Skin 06',
    sk07: 'Skin 07',
    sk08: 'Skin 08',
    sk09: 'Skin 09',
    sk10: 'Skin 10',
    sk11: 'Skin 11',
    sk12: 'Skin 12',
    sk13: 'Skin 13',
    sk14: 'Skin 14',
    sk15: 'Skin 15',
    sk16: 'Skin 16',
    newnotice: 'New email notification',
    range: 'Notification range',
    mbox: 'Inbox',
    allmail: 'All emails',
    notifytype: 'Notification method',
    webpropmpt: 'web pop-up',
    audittime: 'Audit date',
    from: 'From',
    subject: 'Subject',
    auditrecords: 'Audit records',
    auditdetail: 'Audit details',
    viewdetail: 'View details',
    audit: 'To be reviewed',
    approved: 'Approved',
    reject: 'rejected',
    contain: 'contains',
    thename: 'The sender',
    thesubject: 'The subject',
    record: '',
    clientcode: 'Mail Client Configuration',
    usercode: 'Client Authorization Code Settings',
    clientinfo1: 'After using the client authorization code to log in and enable the client authorization login code, the Email password will not be able to be used when logging in to the client.',
    clientinfo2: '* The client authorization code is a dedicated password used to log in to third-party Email clients and is suitable for logging in to the following services: POP3/SMTP/IMAP services.',
    clientinfo3: 'New authorization codes ',
    clientinfo4: 'After enabling the client authorization code login, only the client authorization code can be used to log in to the client, and the email password login will be impossible. Are you sure to enable it?',
    clientinfo5: 'After closing the client authorization code login, all client authorization codes will become invalid, and the email password login client permission will be restored. Are you sure to close?',
    clientinfo6: '* The administrator has enabled the user authorization code to log in. You must set the user authorization code to use the user to send and receive emails.',
    clientinfo7: '(up to 5 authorization codes can be added)',
    clientinfo8: 'Set client authorization code',
    clientinfo9: 'The client authorization code is only displayed once. Please manually copy or click the button below to copy the authorization code',
    clientinfo10: 'Use device name',
    clientinfo11: 'Client authorization code',
    clientinfo12: 'Confirm and copy authorization code',
    clientinfo13: 'After turning on the client authorization code to log in, only the client authorization code can be used to log in to the client, and the email password cannot be used to log in. Are you sure you want to turn it on?',
    clientinfo14: 'After closing the client authorization code login, all client authorization codes will become invalid, and the email password login client permission will be restored. Are you sure to close?',
    clientname: 'Device name',
    clicenttime: 'Activation time',
    clientoperate: 'Operation',
    clientdel: 'Delete',
    error1: 'The device name cannot be empty',
    error2: 'Successfully added',
    error3: 'Up to 5 authorization codes can be added',
    error4: 'Up to 5 authorization management items can be added',
    error5: 'Please select a time period',
    selectauthobj: 'Select authorization object',
    addobj: 'Add',
    selectauth: 'Permission selection',
    placeholder1: 'Please select permissions',
    placeholder2: 'Reading permission: (can only read emails)',
    placeholder3: 'Write permission: (Add write, reply, and forward permissions on top of reading permission)',
    placeholder4: 'Management permissions: (can operate on all functional modules except for settings)',
    placeholder5: 'Authorization validity period',
    radio1: 'Long term effectiveness',
    radio2: '30 days',
    radio3: '1 day',
    radio4: 'Custom date',
    radio5: '7 days',
    authobj: 'Authorization object',
    author: 'Authorization permissions',
    authtime: 'Effective time',
    authexpire: 'Expiration time',
    edit: 'Edit',
    au: 'Authorization',
    tipsset1: 'Remote login reminder',
    tipsset2: 'After activation, when the account logs in from another location, the system will send an email reminder',
    tipsset3: 'Password modification reminder',
    tipsset4: 'After activation, when the account password is modified, the system will send an email reminder',
    tipsset5: 'Automatically forward reminders',
    tipsset6: 'After activation, when the account is set to automatically forward, the system will send an email reminder.',
    tipsset7: 'Reminder for setting client authorization codes',
    tipsset8: 'After activation, when the account is set for client authorization, the system will send an email reminder.',
    mailmove1: 'Management side settings use ',
    mailmove2: ' for mailbox migration',
    mailmove3: 'Please enter the original email ',
    mailmove4: 'Original system email address',
    mailmove5: 'Original system email password',
    mailmove6: 'Please ensure the accuracy of the input information, otherwise it may affect the migration results.',
    mailmove7: 'After submitting the original email account password, the migration progress will be handled by the system administrator. If there are urgent migration needs, please contact the management personnel directly.',
    mailmove8: 'IMAP protocol',
    mailmove9: 'IMAP authorization code',
    mailmove10: 'POP3 protocol',
    mailmove11: 'POP3 authorization code',
    mailmove12: 'Exchange protocol',
    mailmove13: 'Exchange authorization code',
    // 显示设置
    disset: 'Start page',
    disset1: 'Start to open the homepage',
    disset2: 'Start to open the inbox',
    disset3: 'Email view',
    disset4: 'List mode',
    disset5: 'Column mode',
    disset6: 'Email summary',
    disset7: 'Displays the Email summary',
    disset8: 'Email display',
    disset9: 'Sender and receiver display format',
    disset10: 'Setting the display of recipients and senders on mailbox list, read mail and write mail pages.',
    disset11: 'Show embedded images',
    disset12: 'Number of emails per page',
    disset13: 'Restore last opened tab',
    disset14: 'After accidentally logging out, tabs can be automatically restored when logging in again.',
    disset15: 'Show attachment image',
    disset16: 'Name+Department',
    disset17: 'Name+Email',
    disset18: 'Always',
    disset19: 'Emails from contacts',
    disset20: 'Never',
    // 文件夹设置
    floderset1: 'New Folder',
    floderset2: 'Folder name',
    floderset3: 'Email Quantity',
    floderset4: 'Display',
    floderset5: 'Action',
    floderset6: 'Empty Email',
    floderset7: 'Rename',
    floderset8: 'Delete',
    floderset9: 'Are you sure to delete this folder? Please enter the security code:',
    floderset10: 'There are {num} emails in the folder. Please confirm whether to clear it? Please enter the security code:',
    floderset11: 'Parent folder',
    floderset12: 'My folder',
    floderset13: 'There are still messages in the folder. Please clear the folder before deleting it.',
    floderset14: 'Security code is wrong',
    floderset15: 'Deleted folder successfully',
    floderset16: 'Mail folder cleared successfully',
    floderset17: 'Mail folder renamed successfully',
    floderset18: 'Please enter a folder name',
    floderset19: 'New folder successfully created',
    // 标签管理
    tagset1: 'New tag',
    tagset2: 'Enter a tag name',
    tagset3: 'Tag color',
    tagset4: 'Are you sure to delete the tag:{name}?',
    tagset5: 'Please enter the tag name',
    tagset6: 'Please enter the tag color',
    tagset7: 'No labels',
    tagset8: 'Tag Name',
    tagset9: 'Unread/Total Messages',
    tagset10: 'Edit',
    tagset11: 'Delete',
    tagset12: 'Edit tag',
    tagset13: 'Operation succeeded!',
    tagset14: 'Deleted successfully!',
    // 收信规则
    revrule1: 'Add New Rule',
    revrule2: 'Rule Name',
    revrule3: 'Status',
    revrule4: 'Modify',
    revrule5: 'Delete ',
    revrule6: 'No email receiving rules',
    revrule7: 'Status',
    revrule8: 'Receiving rule name',
    revrule9: 'Condition type',
    revrule10: 'Trigger conditions',
    revrule11: 'Execution action',
    revrule12: ' Thoroughly delete ',
    revrule13: '(completely delete Email cannot be restored, please choose carefully)',
    revrule14: 'Satisfy all conditions',
    revrule15: 'Satisfy any condition',
    revrule16: ' Please enter a rule name',
    revrule17: 'Move to',
    revrule18: 'No further filtering rules will be executed after executing this rule.',
    revrule19: 'Recipient',
    revrule20: 'Sender',
    revrule21: 'Subject',
    revrule22: 'including',
    revrule23: 'excluding',
    revrule24: 'Please enter triggering conditions',
    revrule25: 'Please select',
    revrule26: 'Please confirm whether to delete this rule?',
    revrule27: 'Input',
    revrule28: 'Address non-compliance',
    revrule29: 'Please select an action to execute',
    revrule30: 'Please choose to move to',
    // 快速回复
    quickreply1: 'Enter reply content',
    quickreply2: 'To add to the quick reply list',
    quickreply3: 'Rule name',
    quickreply4: 'Edit',
    quickreply5: 'Delete',
    quickreply6: 'Adding empty data is not supported',
    // 发信和收信设置.
    mailset1: 'Sending Settings',
    mailset2: 'Save to "Send Items"',
    mailset3: 'Auto save to "Drafts"',
    mailset4: 'Never',
    mailset5: '1 minute',
    mailset6: '3 minute',
    mailset7: '5 minute',
    mailset8: '10 minute',
    mailset9: 'Prefix of reply subject',
    mailset10: 'Reply:',
    mailset11: 'Re:',
    mailset12: 'Prefix for forwarding subject',
    mailset13: 'Forward:',
    mailset14: 'Fw:',
    mailset15: 'Include the original text when replying',
    mailset16: 'Auto add recipients to the Contacts',
    mailset17: 'Attachment reminder keywords',
    mailset18: 'Default request receipt',
    mailset19: 'Email font setting',
    mailset20: 'Receiving settings',
    mailset21: 'Send read receipt',
    mailset22: 'Manually select',
    mailset23: 'Ignore all',
    mailset24: 'Reply automatically',
    mailset25: 'Archive setting period',
    mailset26: '7 days',
    mailset27: '15 days',
    mailset28: '30 days',
    mailset29: 'NO archiving',
    mailset30: 'Multiple keywords is separated by commas',
    mailset31: 'Emails sent from Webmail will be saved to the “Sent Items ” ',
    mailset32: 'Emails in the "Inbox" are archived by default',
    // 自动回复
    autoreply1: 'Enable automatic reply',
    autoreply2: 'Reply content',
    autoreply3: 'Enable automatic reply within',
    autoreply4: 'Select start date time',
    autoreply5: 'Select end date time',
    autoreply8: 'Automatically reply only to contacts within the enterprise',
    autoreply9: 'Please set the start and end times for enabling automatic replies correctly',
    autoreply10: 'End time before start time',
    autoreply11: 'The start time cannot be greater than the end time',
    autoreply12: 'The automatic reply content cannot be empty',
    // 自动转发
    autofwd1: 'Automatic forwarding',
    autofwd2: 'Enable automatic forwarding within',
    autofwd3: 'Automatic forwarding address',
    autofwd4: 'Address {num}',
    autofwd5: 'Delete letters from this email after automatic forwarding',
    autofwd6: 'Please set the start and end times for enabling automatic forwarding correctly',
    autofwd7: 'The start time cannot be greater than the end time',
    autofwd8: 'Please enter at least one Email address',
    // 黑名单
    blacklist1: 'Enter Email address',
    blacklist2: 'Add',
    blacklist3: 'Enter an email address or domain name suffix, adding it will result in rejection of letters from that address. For example: mail@example.com,example.com',
    blacklist4: 'Blacklist List',
    blacklist5: 'Delete',
    blacklist6: 'Please confirm whether to remove this email address and stop blocking emails from this address?',
    blacklist7: 'Email cannot be empty',
    blacklist8: 'Email address format is incorrect',
    blacklist9: 'Enter keyword search',
    // 白名单
    whitelist1: 'Enter Email address',
    whitelist2: 'Add',
    whitelist3: 'Enter an Email address or domain name suffix, adding it will securely trust the Email from that address. For example：mail@example.com,example.com',
    whitelist4: 'Whitelist list',
    whitelist5: 'Delete',
    whitelist6: 'Once deleted, emails from this address will no longer be released. Please confirm whether to delete it?',
    whitelist7: 'Email cannot be empty',
    whitelist8: 'Email address format is incorrect.',
    whitelist9: 'Enter keyword search',
    // 签名设置
    signature1: 'New Signature',
    signature2: 'Signature Name ',
    signature3: 'Apply to New Email',
    signature4: 'Apply to Forward/Reply',
    signature5: 'Signature Location',
    signature6: 'Action',
    signature7: 'Preview',
    signature8: 'Modify',
    signature9: 'Delete',
    signature10: 'No Signature',
    signature11: 'Please enter a signature name',
    signature12: 'Set to default for new messages',
    signature13: 'Settings Default for reply/forward',
    signature14: 'Signature location',
    signature15: 'Quote content above when replying to forwarding',
    signature16: 'Quote content below when replying and forwarding',
    signature17: 'Signature preview',
    signature18: 'Close preview',
    signature19: 'Are you sure to delete the signature?',
    signature20: 'Please enter a signature name',
    signature21: 'Created successfully',
    signature22: 'Saved successfully',
    signature23: 'This signature is in use,Please confirm whether to delete?',
    signature24: 'Edit signature',
    signature25: 'Timed transmission',
    signature26: 'Save as Draft',
    signature27: 'Preview',
    signature28: 'Cancel',
    signature29: 'Subject',
    signature30: 'Recipient: xxx',
    signature31: 'Sample style for the message body part',
    signature32: 'Quotation',
    signature33: 'Send',
    signature34: 'Above the quote content',
    signature35: 'Below quote content',
    signature36: 'New emails',
  },
  calendar: {
    view_link: 'View Calendar Link',
    view_indo: 'Calendar link is used to mount to third-party clients to view shared calendars.',
    cal_link: 'Calendar Link',
    share_link: 'Share Link',
    change_auth: 'Modify permissions',
    cal1: '{month} {year}',
    cal2: 'Today',
    cal3: 'Day',
    cal4: 'Week',
    cal5: 'Month',
    cal6: 'Schedule sharing',
    cal7: 'Schedule reminder',
    cal8: 'Subject',
    cal9: 'Place',
    cal10: 'Time',
    cal11: 'Start time',
    cal12: 'No reminder at the moment',
    cal13: 'Ignore All',
    cal14: 'Remind later',
    cal15: 'View calendar',
    cal16: 'Before 5 minutes',
    cal17: 'Before 10 minutes',
    cal18: 'Before 15 minutes',
    cal19: 'Before 1 hour',
    cal20: 'Before 2 hours',
    cal21: 'Before 6 hours',
    cal22: 'Before 12 hours',
    cal23: 'Before 1 day',
    cal24: 'Before 30 minutes',
    cal25: 'Before 18 hours',
    cal26: 'Before 2 days ',
    cal27: 'Operation successful',
    cal28: '(No subject)',
    cal29: 'Forward Meeting',
    cal30: 'Reminder',
    cal31: 'Organizer',
    cal32: 'Participant',
    cal33: 'The meeting has expired',
    cal34: 'Accept',
    cal35: '{num}',
    cal36: 'Tentative',
    cal37: 'Reject',
    cal38: 'View participant responses',
    cal39: 'Date',
    cal40: 'Name',
    cal41: 'View details',
    cal42: 'Delete',
    cal43: 'Reply to organizer',
    cal44: 'Reply all',
    cal45: 'Reply to organizer',
    cal46: 'Cancel Meeting',
    cal47: 'Edit',
    cal48: 'None',
    cal49: 'Before 1 day',
    acbtn1: 'Accept',
    acbtn2: 'Accepted',
    acbtn3: 'Rejected',
    acbtn4: 'The meeting has been cancelled',
    acbtn5: 'Re select',
    acbtn6: ' already ',
    acbtn7: 'Send response now',
    acbtn8: 'Send response after editing',
    acbtn9: 'Do not send a response',
    more: 'More',
    fullcal: 'Not responding',
    info1: 'Schedule information',
    info2: 'Meeting invitation',
    del1: 'Deleted successfully',
    del2: 'Delete failed',
    confirm: 'Are you sure you want to delete this schedule?',
    confirm2: 'Meeting cancelled successfully',
    info3: 'Create a new activity',
    info4: 'Create a new schedule',
    info5: 'Create a new meeting',
    info6: 'My Calendar',
    info7: 'There is currently no schedule',
    info8: 'Please enter a label name',
    info9: 'Rename',
    info10: 'Subscription Calendar',
    info11: 'No data',
    info12: 'Sharing source',
    info13: 'Create schedule',
    info14: 'Delete subscription',
    info15: 'Send Meeting',
    info16: 'Save schedule',
    info17: 'Switch schedule',
    info18: 'Switch meetings',
    info19: 'Change Meeting',
    info20: 'Change schedule',
    info21: 'Participant',
    info22: 'Contacts',
    info23: 'Subject',
    info24: 'Place',
    info25: 'Time',
    info26: 'Start date',
    info27: 'End date',
    info28: '-',
    info29: 'All day',
    info30: 'Busy',
    info31: 'Free',
    info32: 'Busy',
    info33: 'Tentative',
    info34: 'Out',
    info35: 'Working elsewhere',
    info36: 'Reminder',
    info37: 'Please select',
    info38: 'Close activity cycle',
    info39: 'Set activity cycle',
    info40: 'Add a attachment',
    info41: 'None',
    info42: '{num} attachments',
    info43: 'Big',
    info44: 'Download',
    info45: 'Continue',
    info46: 'The sharing I received',
    info47: 'My initiated sharing',
    info48: 'Return to calendar',
    info49: 'Please enter the name or email address of the sharing member',
    info50: 'Sharing members',
    info51: 'Sharing permissions',
    info52: 'Action',
    info53: 'Delete',
    info54: 'Add sharing members',
    info55: 'Delete all',
    info56: 'Return',
    info57: 'Calendar name',
    info58: 'Sharing statistics',
    info59: 'Shared by {num} people',
    info60: 'Detail',
    info61: 'Ignore all',
    info62: 'Sharing source',
    info63: 'Sharing scope',
    info64: 'Permission level',
    info65: 'Ignore',
    info66: 'Full Editing',
    info67: 'Selected members',
    info68: 'Enter calendar name',
    info69: '{num}% in file scan',
    info70: 'Share details',
    info71: 'View busy time',
    info72: 'View schedule details',
    info73: 'Editable',
    info74: 'Label color cannot be empty',
    info75: 'Please enter a calendar name',
    info76: 'Meeting changes',
    info77: 'The schedule time cannot be empty',
    info78: 'Successfully changed the meeting',
    info79: 'Are you sure you want to cancel this meeting?',
    info80: 'You have not filled in the topic, do you want to continue saving?',
    info81: 'Time cannot be empty',
    info82: 'You have not filled in the topic, do you want to continue sending?',
    info83: 'Please select a sharing member',
    info84: 'Edited successfully',
    info85: 'Editing failed',
    info86: `Not supported, special characters such as, % ' " \\ / ；| &.*`,
    info87: 'No permission to create schedule',
    info88: 'Please confirm whether to delete the subscription calendar for {mail}? Unable to continue viewing this calendar after deletion.',
    info89: 'Confirm deletion',
    info90: 'The member has been added',
    info91: 'Deleting the calendar will synchronously clear the schedule under the calendar. Do you want to continue?',
    info92: 'Schedule Title',
    info93: 'Quick editing only supports text input within 100 words',
    info94: 'New meeting created successfully',
    info95: 'New schedule success',
    info96: 'Schedule change successful',
    cyle1: 'Repeat pattern',
    cyle2: 'Daily',
    cyle3: 'Weekly',
    cyle4: 'Monthly',
    cyle5: 'Yearly',
    cyle6: 'Every',
    cyle7: 'day',
    cyle8: 'Every working day',
    cyle9: 'week',
    cyle10: 'month',
    cyle11: 'Every',
    cyle12: '',
    cyle13: 'year',
    cyle14: 'month',
    cyle15: 'day',
    cyle16: 'End date',
    cyle17: 'Never expires',
    cyle18: 'Monday',
    cyle19: 'Tuesday',
    cyle20: 'Wednesday',
    cyle21: 'Thursday',
    cyle22: 'Friday',
    cyle23: 'Saturday',
    cyle24: 'Sunday',
    cyle25: 'Please fill in the complete activity cycle',
    cyle26: 'Please fill in the end date of the activity cycle',
    cyle27: 'No subject ',
  },
  upload: {
    info1: 'There are still attachments that have not been uploaded yet. Please wait for the upload to complete or cancel, and then try again.',
    info2: 'Please fill in at least one participant',
    info3: 'The participant has the wrong email addresses',
  },
  list: {
    mail_quote: 'Email notes',
    input_content: 'Please enter content',
    mail_quote_add_success: 'Email note added successfully',
    mail_quote_del_success: 'You have successfully deleted the email note',
    INBOX: 'Inbox',
    Sent: 'Sent Items',
    Drafts: 'Drafts',
    trash: 'Deleted Items',
    start: 'Starred',
    unseen: 'Unread',
    archive: 'Archive',
    rubbishs: 'Junk Email',
    tracking: 'Email Tracking',
    audit: 'Review notifications',
    attachment: 'Attachment management',
    fileWebDisk: 'File transfer station',
    file_transfer_opc: 'File transfer station space',
    file_num: 'Number of files',
    used: 'usage',
    save_time: 'Storage',
    my_folder: 'my folder',
    tag_mail: 'Tag',
    auth_no_compose: 'Permissions do not match, unable to write an email',
    not_move_folder: 'Cannot move to this folder',
    close_tag: 'Close all tabs',
    no_subject: 'No subject',
    income_out_mail: 'Incoming and outgoing mails',
    write_mail: 'New email',
    composing_close_save_draft: 'You are writing a letter, do you want to save the draft before closing?',
    forever_time: 'Permanent',
    num_day: '{num} days',
  },
  view: {
    not_support_type: 'This file type does not support preview',
    loading: 'Loading desperately',
    no_subject: 'No subject',
    time: 'Time',
    replace_send: 'Sent by {mail}',
    expand: 'Show more',
    close: 'Close',
    send_to: 'Send to',
    num_person: 'etc. {num} people',
    to: 'recipient',
    cc: 'cc',
    bcc: 'bcc',
    att: 'attachment',
    view_att: 'View attachment',
    have_att: ' {num} ',
    etc: ' etc',
    mail_expired: 'This email has expired',
    mail_will_expired: 'This email will expire on {date}',
    wait_audit: 'Waiting for {mail} review',
    wait: 'Waiting for',
    audit: 'review',
    income_track_view: '(Click to enter the email tracking page to view)',
    click_view_status: '(Click the button to view the email status)',
    click_view_and_recall: '(Click the button to view the email status and recall the email)',
    withdraw: 'Withdraw',
    risk_info: 'Risk Tip',
    warn_info: 'Be alert! This email is an external email and may be risky, please treat it with caution!',
    mail_psw_info: 'This is an encrypted email. The recipient needs to decrypt it before viewing the email content. Email password: {psw}',
    secrity: 'This is a secure email. The recipient will not be allowed to forward, reply, copy, print, download, etc. the content of this email',
    and: ' and ',
    expired: 'expires on',
    and_view_once: ' and can only be viewed once',
    total: 'Total',
    pack_download: 'Package and Download',
    no_att_name: 'No attachment name',
    priview: 'Preview',
    download: 'Download',
    save_drive: 'Save to netdisk',
    back_audit: 'Return to review notification',
    input_content: 'Please enter content',
    more_replay: 'More quick reply content',
    metting_num: 'Number of participants',
    invite_metting: 'Invite you to the meeting',
    have_people: '{num} people',
    location: 'Location',
    metting_expire: 'The meeting has expired',
    cancel_metting: 'Cancel meeting',
    al_cancel_metting: 'The meeting has been cancelled',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    confirm_cancel_metting: 'Please confirm whether to cancel the meeting?',
    back: 'Return',
    edit_again: 'Edit again',
    reply: 'Reply',
    reply_all: 'Reply to all',
    reply_all_att: 'Reply all with attachments',
    fw: 'Forward',
    as_att_fw: 'Forward as attachment',
    mark: 'Mark',
    unread: 'Unread',
    read: 'Read',
    flagged: 'Starred',
    cancel_flagged: 'Cancel star',
    top: 'Display on Top',
    un_top: 'Unpinned',
    select_tag: 'Select tag',
    cacel_all_tag: 'Cancel all tags',
    move: 'Move to',
    del: 'Delete',
    real_del: 'Delete Permanently',
    more: 'More',
    copy: 'Copy',
    down_mail: 'Download email',
    print: 'Print email',
    look: 'View Source',
    new_tab_view: 'Open in a New Tab',
    cancel_tag_success: 'Successfully canceled all tags',
    real_del_not_find: 'Are you sure to delete it permanently? Emails cannot be retrieved after they are permanently deleted.',
    cancel_del: 'Deletion canceled',
    set_destory_not_view: 'This email has been set to burn after reading, and the header cannot be viewed',
    copy_success: 'Copied successfully',
    audit_oponion: 'Review comments',
    no_audit_info: 'No review information yet',
    pass: 'Pass',
    reject: 'Reject',
    baomi: 'confidentiality office',
    level_audit: 'Level{num} reviewer',
    go_audit: 'Go to reviewer',
    test_info: 'Test tip!',
    p1: `Dear friend, I'm sorry, you have been tricked! `,
    p2: 'This is a security test email. When you see this page, it means that your security awareness is quite weak and you belong to a high-risk group. When you receive any emails from unknown sources that contain links or attachments, be extremely vigilant and do not open them easily. If you are unsure, please contact the information center or security department. ',
    p3: 'Please watch the [Email Security Learning] video',
    test_title: 'This is an email security awareness test email! ',
    guanbi: 'close',
    receipt_tip: 'receipt reminder',
    is_send_receipt: 'The sender has requested a read receipt for this email. Do you want to send a "read" receipt?',
    mail_view_receipt: 'Mail read receipt',
    subject: 'Subject',
    not_support: 'Your browser does not support the video tag.',
    attack_info: 'Unfortunately, I was attacked by a phishing email╮(╯▽╰)╭Come and learn! After learning is completed, you can close the window and resume using your email.',
    mail_already_set_psw: 'This email has an expiration time set, it has expired and cannot be read',
    warning: 'Warning',
    konw: 'I got it',
    psw_error: 'Wrong password!',
    code_already_send: 'The verification code notification letter has been sent, please check it',
    subject_mail_view_rec: 'Subject: Email read receipt:',
    nail_view_rec_under: 'Email read receipt: your following email',
    at_time_read: 'The recipient has read on {date}.',
    quick_replay: 'Quick reply to ',
    quick_replay_all: 'Quickly reply to all',
    quick_rq: 'quick reply',
    mark_mail_ok: 'Mail marked as {flag} successfully',
    me: 'Me',
    replay_ok: 'Reply successful',
    risk_mail: 'This email comes from a suspicious sending address. Accessing the attachment may cause operational risks. Do you still want to continue?',
    strange_mail: 'This email comes from a strange email address. Accessing the attachment may cause operational risks. Do you still want to continue?',
    risk_mail_save_drive: 'This email comes from a suspicious sending address. Saving to the netdisk may cause operational risks. Do you still want to continue?',
    strange_mail_save_drive: 'This email comes from a strange email address. Saving to the netdisk may cause operational risks. Do you still want to continue?',
    mail_auditing_withdraw: 'The email is waiting for review, please confirm whether you want to withdraw the email',
    withdraw_ok_move_draft: 'The email was successfully withdrawn and moved to the draft box.',
    risk_mail_tolink: 'This email comes from a suspicious sending address. Accessing the link may cause operational risks. Do you still want to continue?',
    strange_mail_tolink: 'This email comes from a strange email address. Accessing the link may cause operational risks. Do you still want to continue?',
    add_new_contact: 'New contact',
    edit_contact: 'Edit contact',
    destory_mail_once_read: 'This email is an email that will be burned after being read. It can only be read once. Please read it carefully.',
    secrity_mail: 'Secure mail',
    reading_mail_rec_code: 'You are reading a secure email, {val} will receive a verification code email',
    send_msg_code: 'Send verification code notification email',
    psw: 'Password',
    input_psw: 'Please enter your email password',
    ok: 'OK',
    copy_that: 'Got it',
    thanks: 'Thank you',
    mail_quote: 'Email notes',
    mail_quote_add_success: 'Email note added successfully',
    mail_quote_del_success: 'You have successfully deleted the email note',
    save_to: 'Save to',
    sace_drive_ok: 'Successfully saved to netdisk',
    go_look: 'Go to view',
    my_drive: 'My Drive',
    select_save_att: 'Please select the attachment to be saved',
    add_contact: 'Add to address book',
    refuse_mail: 'Reject this person',
    refuse_mail_not_rec: 'You cannot receive emails sent from this address after rejection, please confirm!',
    add_ok: 'Added successfully!',
    add_tag: 'New tag',
    confirm: 'OK',
    input_tag: 'Please enter the tag',
    tag_color: 'Tag color',
    input_tag_name: 'Please enter the tag name',
    input_tag_color: 'Please select the tag color',
    max_five_tag: 'Add up to 5 tags',
    less_one_tag: 'Please select at least one tag',
    mail_reccall: 'Mail recall',
    recieve_status: 'Receive status',
    recacll: 'recall',
    recall_info: 'Note: Only emails sent within 48 hours can be recalled',
    recall_one_key: 'One-click recall',
  },
  mailstatus: {
    'ok': 'successful',
    'sendstate': 'Send status',
    'deepsearch': 'Email deep tracking',
    'succeed': 'Sent successfully',
    'nosucceed': 'Sending failed',
    'checkdetails': 'View details',
    'succeedorsend': 'Sending failed or sending',
    'sending': 'Please go to the email tracking to check the email status',
    'insend': 'Sending',
    'abnormal': 'An exception occurred, please try again',
    'hidden': 'Hidden',
    'show': 'Show',
    'addressee': 'Recipient',
    'canyourecall': 'Can it be recalled',
    'time': 'Time',
    'madereading': 'Read',
    'recall': 'Recall',
    'nosysadmemail': 'Not a system mailbox,unable to recall',
    'thenrecallto': 'Has been recalled',
    'thenrecall': 'Recalled',
    'receivenoread': 'Received but not read',
    'recallfall': 'Recall failed',
    'recallsucceed': 'Recalled successfully',
    'recallfallwitting': 'Recall failed, the other party already knows',
    'inaudit': 'Under review',
    'nopass': 'Review failed',
    'mailaudit_known': 'Already known',
    'mailaudit_read': 'Read',
    'mailaudit_delete': 'Delete',
    'mailaudit_reply': 'Reply',
    'mailaudit_mark': 'Add mark',
    'mailaudit_mark_draft': 'Mark as draft',
    'mailaudit_mark_unread': 'Mark as unread',
    'mailaudit_mark_delete': 'Mark as not deleted',
    'mailaudit_mark_reply': 'Mark as not replied',
    'mailaudit_cancel_mark': 'Unmark',
    'mailaudit_cancel_mark_draft': 'Unmark as draft',
    'mailaudit_pop_receive_del': 'POP receive and delete',
    'mailaudit_pop_receive': 'POP receive',
    'mailaudit_recall_succeed': 'Recalled successfully',
    'mailaudit_rule_nopass': 'Sending failed (system rules do not match)',
    'mailaudit_rule_bounce': 'Rejected by rules',
    'Auditing': 'Waiting for review',
    'AuditRefused': 'Rejected delivery by rules (by review rule )',
    'HasAudited': 'Under review',
    'AuditCallbacked': 'Withdrawn',
    'deferred': 'Temporary delivery error, will try again',
    'bounced': 'Bounced',
    'CallBack': 'Withdrawn',
    'reject': 'Rule rejection',
    'listallrecvrej': 'Bounced by sending and receiving strategy ',
    'listallsecrej': 'Bounced by confidentiality strategy ',
    'domainrej': 'The sender does not belong to the domain in this list and returns the  message',
    'listrej': 'List bounce',
    'specrej': 'The sender is not allowed to send a return message to the list',
    'listnull': 'Bounced by an empty list',
    'undefpol': 'The list does not define any strategy  and is bounced',
    'RaisingRefused': 'Delivery refused by temporary privilege escalation rules',
    'SecretRefused': 'Delivery refused by confidentiality rules ',
    'list_persecrej': `The member's confidentiality level did not match and the letter was bounced`,
    'list_perrecvrej': `The member's sending and receiving did not match and the letter was bounced`,
    'sentrecv': 'The individual failed to pass the sending and receiving strategy and was bounced',
    'secret': 'The individual failed to pass the confidentiality strategy and was bounced',
    'sentfailed': 'The echo process failed and the message was bounced',
    'mailaudit_retr': 'Viewed',
    'mailaudit_pop_seen': 'POP view',
    'FilterRefused': 'Delivery refused by  filtering rules',
    'mailaudit_move': 'Moved',
    'unknowuser': 'Email address does not exist',
    'set_topping': 'Display on Top',
    'unset_topping': 'Unpinned',
    'add_tag': 'Add tag',
    'del_tag': 'Untag',
  },
  other: {
    view_member: 'View list members',
    add_depart_mail: 'Department mailing list',
    psw_mail: 'Encrypted mail',
    open_audit_code: 'You are opening the review notification email, please enter the verification code',
    go_mail_view_code: 'Please go to the email to view the verification code',
    input_code: 'Please enter the verification code',
  },
  track: {
    mail_track: 'Email Tracking',
    input_mail_sub: 'Please enter the subject of the email',
    time: 'Time',
    to: 'Recipient',
    sub: 'Subject',
    mail_status: 'Status',
    action: 'Operation',
    withdraw: 'Withdraw',
    display: 'Show',
    withdraw_ok: 'Withdraw successfully and save to draft box',
  },
  audit: {
    audit_tips: 'Review notification',
    input_mail_sub: 'Please enter the subject of the email',
    time: 'Time',
    to: 'Sender',
    sub: 'Subject',
    action: 'Operation',
    auditing: 'Review',
  },
  att: {
    input_att_name: 'Please enter the name of the attachment',
    all: 'All files',
    img: 'Image',
    audio: 'Multimedia',
    doc: 'Document',
    zip: 'Compressed file',
    other: 'others',
    send: 'Send',
    down: 'Download',
    save_drive: 'Save to My Drive',
    name: 'File name',
    no_name: 'No file name',
    time: 'Time',
    from: 'Sender',
    sub: 'Subject',
    size: 'Size',
    view: 'Preview',
    form_mail: 'Back to Original Email',
    select_file: 'Please select the file',
    select_folder: 'Please select folder',
  },
  colmail: {
    all: 'All emails',
    currentPage: 'Current page',
    uncheck: 'Uncheck',
    invert: 'Invert',
    unread: 'Unread',
    emergent: 'Urgent',
    search_res: 'Search results',
    hight_search: 'Advanced search: {total} emails found',
    an_mail: 'By mailbox',
    an_from: 'By sender',
    an_to: 'By recipient',
    an_time: 'By time',
    an_has_att: 'By whether to include attachments',
    has_att: 'With attachments',
    no_att: 'No attachment',
    foder_no_mail: 'There is no mail in this folder',
    me: 'Me',
    read_destory: 'Email that burns after reading',
    mail_expired: 'This email will expire on {date}',
    security_mail: 'Security attribute mail',
    psw_mail: 'Password email',
    add_psw_mail: 'Encrypted email***',
    will_send: 'will be sent on {date}',
    no_mail: 'No mail selected yet',
    select_mails: '{num} emails have been selected',
    transfer: 'Forward',
    astt_transfer: 'Forward as attachment',
    mark: 'Mark',
    move: 'Move to',
    del: 'Delete',
    more: 'More',
    down_mail: 'Download mail',
    upload_mail: 'Upload email',
    selectmails: '{num} emails selected',
    find_ok: 'Found',
    total_num_mail: 'have {num} emails',
    one_week: 'within a week',
    o_mon: 'Within a month',
    t_mon: 'within three months',
    since: 'earlier',
  },
  transfer: {
    input_att_name: 'Please enter the name of the attachment',
    upload: 'Upload file',
    del: 'Delete',
    del_ok: 'Deleted successfully',
    send: 'Send',
    increace_time: 'Renew',
    increace_time_ok: 'Rnewed successfully',
    save_drive: 'Save to My Drive',
    file_name: 'File name',
    no_file_name: 'No file name',
    create_time: 'Creation time',
    expired: 'Expiration time',
    forever: 'Permanent',
    size: 'Size',
    download_times: 'Download times',
    preview: 'Preview',
    down: 'Download',
    rename: 'Rename',
    rename_ok: 'Renamed successfully',
    select_files: 'Please select files',
    not_support: 'Not supported \ / : * ? < > |',
    delete_not_down: 'Once deleted, those who receive the large attachment will not be able to download it. Are you sure to delete the selected file? ',
    unread: 'Unread',
    read: 'Read',
    star: 'Star',
    unstar: 'Unstar',
    top: 'Display on Top',
    untop: 'Unpinned',
    select_tag: 'Select tag',
    mark_type_ok: 'Marked as {type} successfully',
    select_mail: 'Please select email',
    select_untag_mail: 'Please select the emails you want to unlabel all',
    star_ok: 'Set star successfully',
    star_not_ok: 'Unstar successfully',
    move_to_ok: 'Move to {val} successfully',
    del_not_recover: 'Are you sure to permanently delete the email? Once deleted, the email cannot be recovered',
    clean_del: 'Successfully permanently deleted',
    cancel_del: 'Deletion canceled',
    not_transfer: 'The email has security attributes set and forwarding is not allowed',
    expired_not_fw: 'Expired emails are not allowed to be forwarded',
    fw: 'Forward:',
    max_fw_20: 'A maximum of 20 emails are allowed to be forwarded at the same time',
    not_att_transfer: 'The email has security attributes set and is not allowed to be forwarded as an attachment',
    some_ok: 'Some emails were downloaded successfully, emails of prohibited download type are not allowed to be downloaded',
    undownload: 'Download-prohibited emails are not allowed to be downloaded',
    unfw: 'Forward-prohibited emails are not allowed to be downloaded',
    select_download_mail: 'Please select the email to download',
    max_down_times: 'A maximum of 20 emails are allowed to be downloaded at one time',
  },
  mailmodule: {
    no_auth: 'Permissions do not match, draft cannot be edited',
    edit_later_send: 'Are you sure to edit the scheduled email? Once confirmed, the sending of the scheduled email will be cancelled',
    bzinfo: 'Notes information',
    newcontact: 'New contact',
    support_type: 'Only zip type is supported for uploading emails',
    editcontact: 'Edit contact',
    check_import: '{num1} emails that can be imported were detected, {num2} were imported successfully, and other duplicate or formatted emails have been ignored. ',
    max_select_contact: 'Select up to {num} addresses',
    depart_list: 'Department mailing list',
    already_add: 'The contact has been added',
    uploading: '{num} files are being uploaded',
    all_cancel: 'Cancel all',
    upoad_fail: 'Upload failed',
    pause: 'Pause',
    goon: 'continue',
    options: 'alternative options',
    confirm_cancel_task: 'Are you sure to cancel all upload tasks? ',
    oversize: 'The file size exceeds the maximum limit of 2G',
    not_upload_type: 'Upload type limited',
    zone_small: 'Insufficient space',
    transfer_small: 'Insufficient space in the file transfer station',
    netdisk_small: 'Insufficient netdisk space',
    search_type: 'Please enter search conditions',
    new_mail: 'You have {num} new emails',
    go_view: 'Go to view',
    save_fail: 'Failed to save',
    depart: 'Department',
    toggle: 'Switch',
    first_change: 'Change password for the first time',
    init_psw: 'Original password',
    newpsw: 'New password',
    confirm_psw: 'Confirm password',
    set_answer: 'Set answer',
    reset: 'Reset',
    input_q: 'Please enter the question',
    change_ok: 'Modified successfully',
    cloud_att: `Cloud attachment sent from sender's email`,
    overtime_logout: 'Timed out, logged out',
  },
}
