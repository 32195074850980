import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'

/** 新建js缓存文件需要固定添加 stateInfo、stateDataClear、stateDataSet,参照modules下的任意文件 */

Vue.use(Vuex)
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // modulesFiles.keys()获取所有js文件的相对路径
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  // 对文件路径进行处理，获取文件名 比如 './app.js' => 'app'
  const value = modulesFiles(modulePath)
  value.default.mutations.stateDataClear(value.default.state)
  // 获取一个js文件(state)下的所有信息,这一步相当于 -- import 路径
  modules[moduleName] = value.default
  // 整合到一个对象modules中
  return modules
  // 开始下一个迭代，直到所有文件被浏览
}, {})

const store = new Vuex.Store({
  modules,
  getters,
  plugins: [createPersistedState({ storage: window.sessionStorage })],
})

export default store
