<template>
  <div>
    <span v-if="status==='1'">
      <actionButton
        icon-class="accept"
        drop
        type="接受"
        @click="responseMetting(1,$t('message.calendar.acbtn1'))"
        @command="selectCommand"
      >{{ $t('message.calendar.acbtn1') }}</actionButton>
      <actionButton
        icon-class="waiting"
        drop
        type="暂定"
        @click="responseMetting(2,$t('message.calendar.cal36'))"
        @command="selectCommand"
      >{{ $t('message.calendar.cal36') }}</actionButton>
      <actionButton
        icon-class="refuse"
        drop
        type="拒绝"
        @click="responseMetting(3,$t('message.calendar.cal37'))"
        @command="selectCommand"
      >{{ $t('message.calendar.cal37') }}</actionButton>
    </span>
    <span v-else-if="status==='2'">
      <actionButton
        icon-class="accept"
      >{{ $t('message.calendar.acbtn2') }}</actionButton>
    </span>
    <span v-else-if="status==='3'">
      <actionButton
        icon-class="waiting"
      >{{ $t('message.calendar.cal36') }}</actionButton>
    </span>
    <span v-else-if="status==='4'">
      <actionButton
        icon-class="refuse"
      >{{ $t('message.calendar.acbtn3') }}</actionButton>
    </span>
    <span v-else-if="status==='5'">
      <button
        type="button"
        class="btn1"
      >{{ $t('message.calendar.acbtn4') }}</button>
    </span>
    <div
      v-if="['2','3'].includes(status)"
      class="reselect cur"
      @click="changeStatus('1')"
    >
      <svg-icon
        icon-class="fresh"
        style="width: 15px;height:15px"
      />
      <span class="title">{{ $t('message.calendar.acbtn5') }}</span>
    </div>
  </div>
</template>

<script>
import actionButton from './actionButton.vue'
import calendar from '../calendar'
export default {
  components: { actionButton },
  mixins: [calendar],
  props: {
    clickInfo: { type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {

    }
  },
  methods: {
    changeStatus(status) {
      this.$emit('changeStatus', status)
    },
    // 响应会议
    responseMetting(id, des) {
      this.nowHandelMetting(id, des)
      this.changeStatus(String(id + 1))
    },
    selectCommand(type, id) {
      switch (type) {
        case '接受':
          if (id === '3') {
            this.handleMetting(1)
          } else if (id === '1') {
            this.nowHandelMetting(1, type)
          } else {
            this.afterEditHandleMetting(1, type)
          }
          this.changeStatus('2')
          break
        case '暂定':
          if (id === '3') {
            this.handleMetting(2)
          } else if (id === '1') {
            this.nowHandelMetting(2, type)
          } else {
            this.afterEditHandleMetting(2, type)
          }
          this.changeStatus('3')
          break
        case '拒绝':
          if (id === '3') {
            this.handleMetting(3)
          } else if (id === '1') {
            this.nowHandelMetting(3, type)
          } else {
            this.afterEditHandleMetting(3, type)
          }
          this.changeStatus('4')
          break
        default:
          break
      }
    },
    // 不发送响应
    async handleMetting(id) {
      const params = {
        cal_obj_id: this.clickInfo.cal_obj_id,
        meeting_feedback: id,
        from_id: this.clickInfo.from_id || '',
      }
      const res = await this.directFeedbackMetting(params)
      console.log(res)
      if (res.status === 200) {
        this.$emit('callback')
      }
    },
    // 立即发送响应
    async nowHandelMetting(id, des) {
      const params = {
        cal_obj_id: this.clickInfo.cal_obj_id,
        meeting_feedback: id,
        is_meeting: 1,
        from_id: this.clickInfo.from_id || '',
        to: [this.clickInfo.ky_organizer],
        subject: `${des}：` + this.clickInfo.title,
        desc: `${this.$store.state.app.userInfo.user_mail}${this.$t('message.calendar.acbtn6')}${des}`, // 描述
      }
      const res = await this.SendMetting(params)
      if (res.status === 200) {
        this.$emit('callback')
      }
    },
    // 编辑后发送响应
    afterEditHandleMetting(id, des) {
      console.log(this.clickInfo)
      const params = {
        cal_obj_id: this.clickInfo.cal_obj_id,
        meeting_feedback: id,
        is_meeting: 1,
        from_id: this.clickInfo.from_id || '',
        to: this.clickInfo.ky_organizer,
        subject: `${des}：` + this.clickInfo.title,
      }

      this.$myApi.mainTab.showComposeMail({ type: 'calendar', subject: this.$t('message.mbox.newmail'), composeData: params })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn1{
  display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
}
.reselect{
  display: inline-block;
  padding: 3px 10px;
  border-left: 1px solid #dcdfe6;
  margin-left: 3px;
  .title{
    padding-left: 5px;
  }
}
</style>
