<template>
  <div
    v-show="isAuditBox"
    ref="printBox"
    class="printBox"
  >
    <p class="btn">
      <el-button
        type="primary"
        @click="print"
      >{{ $t('message.view.print') }}</el-button>
      <el-button
        type="primary"
        @click="closePrint"
      >{{ $t('message.view.guanbi') }}</el-button>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAuditBox: this.isShow, // 是否是邮件审核邮件
      message: true,
      operation: false, // 是否显示通过拒绝按钮
      isText: false, // 是否显示文本与
      textarea1: '', // 审核意见内容
    }
  },
  watch: {
    isShow: function(a, b) {
      this.isAuditBox = a
    },
  },
  mounted() {
  },
  methods: {
    print() {
      window.print()
    },
    closePrint() {
      window.close()
      // this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>

@page {
  margin-top: 0;
  margin-bottom: 0;
}

@media print   {
  .btn{
    display: none;
  }
}
.printBox{
  width: 100%;
  background: #F7F8F9;
  position: fixed;
  bottom:0;
  .btn{
    padding:25px 0 25px 23px;
    /deep/ .el-button{
      margin-left:0;
      margin-right:20px;
    }
  }
}
</style>
