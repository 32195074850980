<template>
  <div class="login_warp">
    <div
      class="herder"
    >
      <div class="hdbox">
        <p>
          <span>帮助中心</span>
          <span>客户端设置</span>
        </p>
        <img
          src="../../assets/icons/login/l_logo.png"
          alt="logo"
        >
      </div>
    </div>
    <div class="login_main">
      <div
        class="login_div"
      >
        <p class="dl"><input
          v-model="code"
          type="text"
        ><span
          v-if="!isShow"
          @click="sendCode"
        >发送验证码通知邮件</span><span v-else>已发送</span></p>
        <p
          v-if="!isShow"
          class="txt"
        >{{ $t('message.other.open_audit_code') }}</p>
        <p
          v-else
          class="txt"
        >{{ $t('message.other.go_mail_view_code') }}</p>
        <p
          class="btn"
          @click="login"
        >{{ $t('message.login.confirmbtx') }}</p>
      </div>
    </div>
    <div class="footer">中标软件版权所有©2001-2023 ICP备案号</div>
  </div>
</template>
<script>

import serialize from '@/utils/serialize'
export default {
  name: 'Login',
  data() {
    return {
      code: '',
      isShow: false,
    }
  },
  created() {

  },
  mounted() {
    console.log(this.$myData.uriDatas, this.$myData.uriModule, 'this.$myData.uriDatas')
  },
  methods: {
    sendCode() {
      const req = {
        'module': 'audit_mail',
        'require': {
          action: 'client_send_code',
          token: this.$myData.uriDatas.token,
        },
      }
      // 获取系统配置信息
      this.$axios.post(this.$apiData.mail, { req: serialize(req) })
        .then(res => {
          if (res === 200) {
            this.isShow = true
          }
          alert(res.msg)
        })
    },
    login() {
      const req = {
        'module': 'audit_mail',
        'require': {
          action: 'client_send_code',
          code: this.code,
        },
      }
      // 获取系统配置信息
      this.$axios.post(this.$apiData.mail, { req: serialize(req) })
        .then(res => {
          if (res === 200) {
            this.isShow = false
          }
          alert(res.msg)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.login_warp {
  background: url('../../assets/icons/login/bj1.jpg') no-repeat top center;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  .clear{
    clear: both;
  }
  .login_main {
    width: 1200px;
    margin: 0 auto;
    .login_div{
      width: 600px;
      height: 220px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #CACACA;
      margin: 25vh auto 0;
      .dl{
        position: relative;
        width: 467px;
        height: 51px;
        margin: 41px auto 17px;
        span{
          position: absolute;
          line-height: 50px;
          right: 20px;
          color: #D0161B;
          // width: 80px;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
        }
        input{
          position: absolute;
          width: 260px;
          height: 46px;
          background: #FFFFFF;
          border-radius: 3px 3px 3px 3px;
          border: 1px solid #D7D7D7;
          padding-right: 170px;
          padding-left: 36px;
          background: url(../../assets/icons/login/l_password.png) 12px 13px
        no-repeat #fff;

        }
      }
      .txt{
        text-align: center;
        width: 469px;
        color: #D0161B;
        margin: 0 auto;
      }
      .btn{
        width: 469px;
        height: 46px;
        background: #D0161B;
        border-radius: 3px 3px 3px 3px;
        color: #fff;
        font-weight: bold;
        margin: 16px auto 0;
        text-align: center;
        font-size: 16px;
        line-height: 46px;
        cursor: pointer;
      }
    }
  }
  .herder {
    width: 100%;
    height: 110px;
    line-height: 110px;
    background: #fff;
    .hdbox{
      width: 1200px;
      margin: 0 auto;
    }
    img{
      height: 90px;
      vertical-align: middle;
    }
    p{
      float:right;
      font-weight: bold;
      padding: 0 20px 0 0;
      span{
        cursor: pointer;
      }
      span:first-child{
        margin-right: 60px;
      }
    }
  }
  .login_div_left{
    margin: 166px 0 100px 0;
  }
  .login_div_center{
    margin: 166px auto 100px;
  }
  .footer {
    width: 100vw;
    height:110px;
    background: #fff;
    text-align: center;
    line-height:110px;
    font-size: 16px;
    position: fixed;
    bottom: 0;
  }
  .codeImg{
    width: 342px;
    height: 260px;
    margin: 32px auto 0;
  }
}
</style>
