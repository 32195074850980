<template>
  <!-- 通讯录模块 -->
  <div class="contacts-moudle-main">
    <el-container class="contacts-moudle-menu">
      <!-- 通讯录模块左侧菜单 -->
      <el-aside
        width="260px"
        style="border-right: 1px solid #dcdcdc;height: calc(100vh - 53px);"
      >

        <!-- 企业通讯录 -->
        <div
          v-if="Number($store.state.app.yes_no_public)===1"
          class="contacts-tree"
        >
          <div class="contacts-tree-title">
            <span>
              <svg-icon
                icon-class="group1"
                style="vertical-align: sub;width: 1.3em;height: 1.3em;"
              />
              <span style="margin-left: 3px;">{{ $t('message.contact.enpcontact') }}</span>
            </span>
          </div>
          <el-tree
            ref="publictree"
            :props="defaultProps"
            :load="loadNode"
            node-key="id"
            lazy
            :highlight-current="publight"
            :expand-on-click-node="false"
            @node-click="handlePublicClickPub"
          >
            <span
              slot-scope="{ node }"
              class="tree-node"
            >
              <!-- <span
                style="overflow: hidden;text-overflow: ellipsis;"
              >{{ node.label }}</span> -->

              <el-tooltip
                class="item"
                effect="light"
                placement="top"
                :open-delay="300"
              >
                <div
                  slot="content"
                  style="max-width:500px"
                >{{ node.label }}</div>
                <span
                  style="overflow: hidden;text-overflow: ellipsis;"
                >{{ node.label }}</span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
        <!-- 个人通讯录 -->
        <div
          class="contacts-tree"
          style="border-top: 1px solid #DEDCDC;"
        >
          <div class="contacts-tree-title">
            <span>
              <svg-icon
                icon-class="group1"
                style="vertical-align: sub;width: 1.3em;height: 1.3em;"
              />
              <span style="margin-left: 3px;">{{ $t('message.contact.percontact') }}</span>
            </span>
            <span>
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('message.contact.newcontact')"
                placement="top"
              >
                <span>
                  <svg-icon
                    icon-class="add"
                    style="cursor: pointer"
                    class="addiocn"
                    @click="addNewContacts"
                  /></span>
              </el-tooltip>

              <span
                class=" addcascader"
                style="position: relative;"
                @click="showMenu=true"
              >
                <el-popover
                  v-model="showMenu"
                  placement="bottom-end"
                  popper-class="custompopper"
                  width="60px"
                >
                  <div
                    class="el-select-dropdown__item"
                    @click="handleChange('addGroup')"
                  >{{ $t('message.contact.newgroup') }}</div>
                  <div
                    class="el-select-dropdown__item"
                    @click="handleChange('import')"
                  >{{ $t('message.contact.importcontact') }}</div>
                  <svg-icon
                    slot="reference"
                    icon-class="menu"
                    style="width: 1.3em;height: 1.3em;"
                  />
                </el-popover>
              </span>

            </span>
          </div>
          <el-tree
            ref="persontree"
            :data="presonsallists"
            :props="defaultProps2"
            :expand-on-click-node="false"
            :highlight-current="perlight"
            node-key="id"
            class="tree"
            @node-click="handlePersonClick"
          >
            <span
              slot-scope="{ node, data }"
              class="tree-node"
            >
              <div
                v-if="isEdit==node.id"
                style="display:flex;justify-content: space-between;align-items: center;width:100%"
              >
                <el-input
                  ref="cateNameInput"
                  v-model.trim="cateNameInput"
                  v-ClickOutSide="clickOutside"
                  class="contactinput"
                  style="width:150px"
                  placeholder=""
                  size="mini"
                  maxlength="25"
                  show-word-limit
                  @keyup.native.enter="$event.target.blur"
                  @blur="$event => editSave($event, node)"
                  @click.stop.native="stopNodeClick($event)"
                />
                <div style="margin-left:0px">
                  <svg-icon
                    icon-class="g_confirm"
                    style="cursor: pointer;margin-right:13px"
                    @click="editGroup(data)"
                  />
                  <svg-icon
                    icon-class="g_cancel"
                    style="cursor: pointer"
                    @click="cancelEditGroup"
                  />
                </div>
              </div>
              <span
                v-else
                style="display: flex;align-items: center;"
              >
                <!-- <span
                  v-title="node.label"
                  class="title"
                >{{ node.label }}</span><span>({{ data.num }})</span> -->

                <el-tooltip
                  class="item"
                  effect="light"
                  placement="top"
                  :open-delay="300"
                >
                  <div
                    slot="content"
                    style="max-width:500px"
                  >{{ node.label }}</div>>
                  <span
                    class="title"
                  >{{ node.label }}</span>
                </el-tooltip>
                <span>({{ data.num }})</span>
              </span>
              <el-popover
                placement="bottom-end"
                popper-class="custompopper"
                width="60px"
                trigger="hover"
              >
                <div
                  class="el-select-dropdown__item"
                  @click="editCateName(node)"
                >{{ $t('message.contact.editgroup') }}</div>
                <div
                  class="el-select-dropdown__item"
                  @click="showDelGroupDialog(data)"
                >{{ $t('message.contact.delgroup') }}</div>
                <svg-icon
                  v-show="code===data.id&&!$store.state.contact.allandungroup.includes(data.id)"
                  slot="reference"
                  icon-class="menu"
                  class="menu"
                  style="width: 1.3em;height: 1.3em;cursor: pointer"
                />
              </el-popover>

            </span>
          </el-tree>
        </div>
      </el-aside>
      <el-dialog
        :title="$t('message.contact.info3')"
        :visible.sync="delGroupDialog"
        :append-to-body="false"
        :modal-append-to-body="false"
        custom-class="delDialog"
        width="440px"
        :before-close="delGroupDialogBeforeClose"
      >
        <p style="color:#1d1b1b"> {{ $t('message.contact.info4') }}</p>
        <p style="color:#1d1b1b"><el-checkbox
          v-model="checked"
          style="margin-right: 8px;"
        />{{ $t('message.contact.info5') }}<span
          class="text-color"
          style="padding-left:5px"
        /></p>
        <div slot="footer">
          <el-button @click="delGroupDialog = false">{{ $t('message.btnText.cancel') }}</el-button>
          <el-button
            type="primary"
            @click="delCateName"
          >{{ $t('message.btnText.ok') }}</el-button>
        </div>
      </el-dialog>
      <CreateGroup
        ref="creategroupdia"
        @updateGroup="updatePersonalGroup"
      />
      <ImportUser ref="importuserdia" />

      <!-- 通讯录模块列表路由显示区域 -->
      <!-- <el-container>
        <router-view />
      </el-container> -->
      <!-- 通讯录模块列表组件显示区域 -->
      <SearchGlobalContact
        v-if="isSearch&&initAllOver"
        :search-words="searchWords"
      />
      <contactView
        v-if="!isSearch&&initAllOver"
        :contacts-obj-data="contactsObjData"
        :contacts-sole-data="contactsSoleData"
      />
    </el-container>
  </div>
</template>
<script>
import ClickOutSide from 'element-ui/src/utils/clickoutside'
import ImportUser from '@/views/contacts/components/ImportUser'
import CreateGroup from '@/views/contacts/components/CreateGroup'
import serialize from '@/utils/serialize'
import requestContact from './contact-request'
import eventCenter from './eventBus'
import contactView from '@/views/contacts/index.vue'
import SearchGlobalContact from '@/views/contacts/SearchGlobalContact.vue'
export default {
  components: { CreateGroup, ImportUser, contactView, SearchGlobalContact },
  directives: { ClickOutSide },
  mixins: [requestContact],
  data() {
    return {
      isSearch: false,
      searchWords: '',
      searchWordsFirst: null,
      selfActived: false,
      initAllOver: false,
      value: '',
      input: '',
      code: true,
      showpop: false,
      publight: true,
      perlight: true,
      isEdit: '',
      cateNameInput: '', // 编辑节点
      publiclists: [],
      defaultProps: {
        label: 'name',
        isLeaf: 'leaf',
      },
      presonsallists: [],
      defaultProps2: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'user_list',
      },
      tempNode: '',
      delGroupDialog: false, // 删除组提示
      checked: false, // 同步删除通讯录中属于此分组的联系人
      selectGroup: null,
      showMenu: false,
      currentNode: null,
      contactsObjData: {}, // 通讯录节点信息
      contactsSoleData: '', // 唯一值
      isAllGroup: '', // 是否是全部分组
      all_item: {}, // 个人通讯录全部联系人数据
    }
  },
  watch: {
    '$store.state.contact.watchAddcontact': {
      handler(value) {
        this.loadPersonsalAndShow()
        this.getGroups()
      },
    },
    '$store.state.contact.updateGroup': {
      handler(value) {
        this.updateSlide()
      },
    },
  },
  created() {
    const thisobj = this
    this.$myApi.activeModuleContact = async(data, options) => {
      if (!thisobj.selfActived) {
        await thisobj.initLoadAll()
        thisobj.selfActived = true
        if (data === undefined) {
          thisobj.isSearch = false
        }
      }
      if (typeof data === 'object') {
        if (data.action === 'search') {
          return thisobj.contactSearch(data.data || '')
        }
      }
      if (!options || options.isHistory !== true) {
        this.$myApi.routeSet('contact')
      }
    }
    this.$myApi.contactSearch = (data) => {
      this.$myApi.topGoto('contact', { action: 'search', data: data })
    }
  },
  mounted() {
  },
  methods: {
    contactSearch(data) {
      if (JSON.stringify(data) === '{}') {
        return
      }
      this.searchWords = data
      this.isSearch = true
    },
    async initLoadAll() {
      this.initAllOver = false
      // 加载个人通讯录
      await this.loadPersonsal()
      // 加载个人通讯录组
      await this.getGroups()
      this.initAllOver = true
    },
    clickOutside() {
      this.code = true
      const _this = this
      setTimeout(() => {
        _this.isEdit = true
      }, 100)
    },
    // 点击个人通讯录节点
    // hoverNode(data, node) {
    //   this.code = data.id
    // },
    handlePersonClick(item) {
      this.perlight = true
      this.publight = false
      this.code = item.id
      if (this.$refs.publictree) {
        this.$refs.publictree.setCurrentKey(null)
      }
      this.$store.commit('contact/SETTING_FROM_ORIGIN', 'person')
      this.$store.commit('contact/SETTING_clickPersonNode', item)
      this.currentNode = item
      // this.$router.push({ name: 'contacts', query: { id: item.id, origin: 'person', group_name: item.name, group_id: item.id, type: item.type }})
      const objData = {
        id: item.id,
        origin: 'person',
        group_name: item.name,
        group_id: item.id,
        type: item.type,
      }
      this.isAllGroup = item.id
      this.contactsSoleData = 'person' + item.id
      this.contactsObjData = JSON.parse(JSON.stringify(objData))
      this.isSearch = false
    },
    // 点击公共通讯录节点
    handlePublicClickPub(item) {
      if (this.$refs.persontree) {
        this.$refs.persontree.setCurrentKey(null)
      }
      this.$store.commit('contact/SETTING_FROM_ORIGIN', 'public')
      this.$store.commit('contact/SETTING_clickPersonNode', item)
      this.perlight = false
      this.publight = true
      // this.$router.push({ name: 'contacts', query: { id: item.id, origin: 'public', group_name: item.name, type: item.type }})

      const objData = {
        id: item.id,
        origin: 'public',
        group_name: item.name,
        type: item.type,
      }
      this.contactsSoleData = 'public' + item.id
      this.contactsObjData = JSON.parse(JSON.stringify(objData))
      this.isSearch = false
    },
    // 加载个人通讯录
    async loadPersonsalAndShow() {
      await this.loadPersonsal()
      // this.isSearch = true
    },
    async updateSlide() {
      const res = await this.getPersonalContact()
      const data = res.map(item => {
        item.checked = false
        return item
      })
      this.presonsallists = data
    },
    async loadPersonsal() {
      const res = await this.getPersonalContact()
      const data = res.map(item => {
        item.checked = false
        return item
      })
      this.allcontact_id = res[0].id
      // lwz-----> start 存储全部联系人数据
      this.all_item = res[0]
      // lwz-----> end
      this.$store.commit('contact/SETTING_ALLPERSONLISTID', this.allcontact_id)
      this.$store.commit('contact/SETTING_ALL_UNGROUP', [res[0].id, res[res.length - 1].id])
      this.presonsallists = data
      console.log(this.presonsallists, '---------------presonsallists')

      const { clickPersonNode } = this.$store.state.contact
      // 重新传值
      // if (this.$route.name === 'searchGlobalContact') {
      //   return false
      // }
      this.$nextTick(() => {
      // selectId：绑定的 node-key
        this.perlight = false
        this.publight = true
        if (this.$refs.persontree && clickPersonNode) {
          this.$refs.persontree.setCurrentKey(clickPersonNode.id)
        }
      })
      if (clickPersonNode && Number(this.$store.state.app.yes_no_public) === 1) {
        // this.$router.push({ name: 'contacts', query: { id: clickPersonNode.id, origin: this.$store.state.contact.fromOrign, group_name: clickPersonNode.name, group_id: clickPersonNode.id, type: clickPersonNode.type }})
        const objData = {
          id: clickPersonNode.id,
          origin: this.$store.state.contact.fromOrign,
          group_name: clickPersonNode.name,
          group_id: clickPersonNode.id,
          type: clickPersonNode.type,
        }
        this.contactsSoleData = 'contacts' + clickPersonNode.id
        this.contactsObjData = JSON.parse(JSON.stringify(objData))
      } else {
        // this.$router.push({ name: 'contacts', query: { id: this.allcontact_id, origin: 'person', group_name: this.$t('message.contact.allcontact'), group_id: this.allcontact_id, type: 'person' }})
        const objData = {
          id: this.allcontact_id,
          origin: 'person',
          group_name: this.$t('message.contact.allcontact'),
          group_id: this.allcontact_id,
          type: 'person',
        }
        this.contactsSoleData = 'contacts' + this.allcontact_id
        this.contactsObjData = JSON.parse(JSON.stringify(objData))
      }
    },
    // 加载公共通讯录
    async loadNode(node, resolve) {
      if (node.level === 0) {
        const list = [{
          name: this.$t('message.contact.orgcontact'),
          id: '',
          leaf: false,
          type: 'org',
        }, {
          name: this.$t('message.contact.maillist'),
          id: 'maillist',
          leaf: true,
          type: 'maillist',
        }, {
          name: this.$t('message.contact.outcontact'),
          id: 'out',
          leaf: false,
          type: 'out',
        }]
        return resolve(list)
      } else {
        const list = await this.getPublicContact(node.data.id)
        const { clickPersonNode } = this.$store.state.contact
        this.$nextTick(() => {
          // selectId：绑定的 node-key
          this.perlight = true
          this.publight = false
          if (this.$refs.publictree) {
            this.$refs.publictree.setCurrentKey(clickPersonNode?.id || '')
          }
        })
        return resolve(list)
      }
    },
    // 导入联系人 编辑联系人
    handleChange(data) {
      this.showMenu = false
      if (data === 'addGroup') {
        this.showCreateGroup()
      }
      if (data === 'import') {
        this.$refs.importuserdia.showImportdialog()
      }
    },
    delGroupDialogBeforeClose() {
      this.delGroupDialog = false
    },
    // 创建组
    showCreateGroup() {
      this.$refs.creategroupdia.showGroupdialog()
    },
    // 更新个人通讯录
    updatePersonalGroup() {
      this.loadPersonsalAndShow()
    },
    editCateName(node) {
      this.isEdit = node.id
      this.code = ''
      this.cateNameInput = node.data.name
      this.$nextTick(() => {
        this.$refs.cateNameInput.focus()
      })
    },
    showDelGroupDialog(data) {
      this.selectGroup = data
      this.delGroupDialog = true
      this.checked = false
    },
    // 删除组
    delCateName() {
      const params = {
        module: 'contact',
        require: {
          action: 'deleteGroup',
          group_id: this.selectGroup.id,
        },
      }
      if (this.checked) {
        params.require.del_user = 1
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(async({ result }) => {
        this.delGroupDialog = false
        this.loadPersonsalAndShow()
        this.$message.success(this.$t('message.contact.info6'))
        this.$store.commit('contact/SETTING_UPDATE_STATUS')
        // this.$router.push({ name: 'contacts-work' })
        this.handlePersonClick(this.all_item)
      })
    },
    editSave(e, data) {
      setTimeout(() => {
        // this.isEdit = false
        // this.code = data.id
      }, 100)
    },
    stopNodeClick(event) {
      event.stopPropagation()
    },
    // 编辑组
    editGroup(data) {
      if (this.cateNameInput === '') {
        return this.$message.error(this.$t('message.contact.info7'))
      }
      if (!/^[^;'"<>\\/\\%&...￥]+$/.test(this.cateNameInput)) {
      // if (!/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test()) {
        return this.$message.error(this.$t('message.contact.info8'))
      }
      const cache = this.$store.state.contact.personalCache
      const ids = cache[data.id].map(item => item.contact_id)
      const params = {
        module: 'contact',
        require: {
          action: 'updateGroup',
          data: {
            contact_list: ids,
            group_id: data.id,
            group_name: this.cateNameInput,
          },
          group_id: data.id,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        this.$message.success(this.$t('message.info.m1'))
        this.loadPersonsalAndShow()
        this.$store.commit('contact/SETTING_CONTACT_TITLE', this.cateNameInput)
        const { clickPersonNode } = this.$store.state.contact
        clickPersonNode.name = this.cateNameInput
        this.$store.commit('contact/SETTING_clickPersonNode', clickPersonNode)
      })
    },
    addNewContacts() {
      if (!this.isAllGroup) {
        // this.$router.push({ name: 'contacts', query: { id: this.allcontact_id, origin: 'person', group_name: this.$t('message.contact.allcontact'), group_id: this.allcontact_id, type: 'person' }})
        // const objData = {
        //   id: this.allcontact_id,
        //   origin: 'person',
        //   group_name: this.$t('message.contact.allcontact'),
        //   group_id: this.allcontact_id,
        //   type: 'person',
        // }
        // this.contactsSoleData = 'contacts' + this.allcontact_id
        // this.contactsObjData = JSON.parse(JSON.stringify(objData))
        this.isSearch = false
        setTimeout(() => {
          eventCenter.$emit('addnew', true)
        }, 100)
      } else {
        eventCenter.$emit('addnew', true)
      }
    },
    cancelEditGroup() {
      this.isEdit = ''
    },
  },
}
</script>
<style lang="scss">
// .el-tree-node.is-expanded>.el-tree-node__children{
//   overflow: auto;
// }
.custompopper{
  padding: 2px!important;
  min-width: 100px!important;
   .poperitem{
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>
/deep/.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    // 设置颜色
    background-color: #F0F0F1; // 透明度为0.2的skyblue，作者比较喜欢的颜色
  }
/deep/.el-tree-node__content{
  height: 30px;
  color: #1d1b1b;
  &:hover{
    background-color: #F0F0F1;
  }
}
/deep/.tree{
  padding: 0 10px!important;

  .title{
    padding: 3px 0;
  }
}
.contacts-moudle-main {
  width: 100%;
  height: 100%;
  color: #1D1B1B;
  .el-container{
    height: 100%;
    overflow-y: auto;
  }
}
.contacts-moudle-menu{
  .el-container{
    display: block;
  }
}
.addcascader{
/deep/ .el-input__inner{
        // border: 0 !important;
        width: 50px;
      }
}
.contacts-tree {
  margin-bottom: 20px;
  width: max-content;
  min-width: 259px;
    .contacts-tree-title {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 10px;
        font-weight: bold;
        color: #1D1B1B;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img{
          vertical-align: bottom;
        }
        .addiocn{
          margin-right: 11px;
          width: 1.3em;height: 1.3em;
        }
    }
    // /deep/ .el-tree-node.is-focusable{
    //   border-top:1px solid #DEDCDC;
    // }
    /deep/ .el-tree{
        // margin-left: 20px;
        // margin-right: 20px;
        padding: 0 20px;
        .tree-node{
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          display: flex;
          justify-content: space-between;
          .title{
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 163px;
          }
        }
    }

}
/deep/.delDialog.el-dialog {
    .el-dialog__header{
        padding-bottom: 10px;
    }
    p{
      padding: 5px;
    }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body{
    padding: 0 20px;
    cursor: default;
  }
}
/deep/.contactinput{
  .el-input__inner{
    padding-right: 50px;
  }
}
</style>
