<template>
  <div style="width: 100%;height: 100%;">
    <!-- 默认加载空div -->
    <div v-if="!settingBoxShow" />
    <div
      class="mail-moudle-menu"
    >
      <!-- 设置模块左侧菜单 -->

      <div class="menu-moudle-main">
        <div
          class="settingBox"
        >
          <div
            class="settingLeft"
          >
            <div
              v-for="i in $myData.settingSetMenu"
              :key="i.value"
              class="its"
              :style="i.isOpen ? 'background:#F0F0F1' : ''"
              @click="showFun(i,'parent')"
            >
              <svg-icon
                :class="i.isOpen ?'svg-theme-color': 'svg-original-color'"
                :style="i.value==='set_notify'?'width:14px':'width:15px'"
                :icon-class="'set_menu_'+i.value"
              />
              <span
                v-show="i.show===true"
                :class="i.isOpen?'text-color':''"
              >{{ $t(`message.setting.${i.lable}`) }}</span></div>

          </div>
          <div class="settingCenter">
            <div
              v-for="d in childList"
              v-show="d.show===true"
              :key="d.value"
              class="its"
              :style="d.isOpen ? 'background:#F0F0F1' : ''"
              @click="showFun(d,'child')"
            >
              <span
                style="white-space:nowrap;"
                :class="d.isOpen?'text-color':''"
                :title="$t(`message.setting.${d.lable}`)"
              >{{ $t(`message.setting.${d.lable}`) }}</span></div>
          </div>

          <div
            class="settingRight"
          >
            <!-- 右侧组件内容 -->
            <setMoudle :current="current" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setMoudle from '@/views/set-module/setMoudle.vue'
export default {
  components: {
    setMoudle,
  },
  data() {
    return {
      selfActived: false,
      childList: [], // 当前的菜单
      current: {}, // 当前的页面信息
      idx: [0, 0], // 默认打开索引值
      settingBoxShow: false,
    }
  },
  created() {
    const thisobj = this
    this.$myApi.activeModuleSetting = async(data, options) => {
      if (!thisobj.selfActived) {
        thisobj.selfActived = true
        await thisobj.initLoadAll()
      }
      thisobj.settingGoto(data ? data.path : undefined, options ? options.isHistory : undefined)
    }
    this.$myApi.settingGoto = (moduleID, isHistory) => {
      return thisobj.$myApi.topGoto('setting', { path: moduleID }, { isHistory: isHistory })
    }
  },
  mounted() {
  },
  methods: {
    async initLoadAll() {
      // console.log(this.$myData.settingSetMenu, 'settingSetMenu')
      // this.settingGoto('labelmanagement')
      // this.showFun(this.$myData.settingSetMenu[this.idx[0]], 'parent')

      // this.linkFn(this.$myData.settingSetMenu[0])
    },
    settingGoto(moduleId, isHistory) {
      if (!this.settingBoxShow) this.settingBoxShow = true
      // moduleId 路由唯一值
      if (moduleId === undefined || moduleId === '') {
        moduleId = this.$myData.settingLastSelectedModuleId || 'blacklist-set'
      }
      this.$myData.settingLastSelectedModuleId = moduleId
      this.idx = this.findPath(moduleId)
      this.showFun(this.$myData.settingSetMenu[this.idx[0]], 'parent', isHistory)
    },
    findPath(moduleId) {
      // 否则，遍历当前节点的所有子节点
      for (let i = 0; i < this.$myData.settingSetMenu.length; i++) {
        const child = this.$myData.settingSetMenu[i].children
        for (let j = 0; j < child.length; j++) {
          if (child[j].path === moduleId) {
            return ([i, j])
          }
        }
        // 如果找到了目标值，返回路径
      }
      return (0, 0)
    },
    showFun(item, position, isHistory) {
      if (position === 'parent') {
        this.$myData.settingSetMenu.forEach(el => {
          el.isOpen = false
        })
        item.isOpen = true
        this.childList = item.children
        // 第二级菜单恢复默认
        this.childList.forEach(el => {
          el.isOpen = false
        })
        this.childList[this.idx[1]].isOpen = true
        this.current = this.childList[this.idx[1]]
        this.idx = [0, 0] // 重置索引
        console.log(isHistory, 'isHistory-------------------------')
        if (isHistory === undefined) {
          this.$myApi.routeSet('setting', { path: this.childList[this.idx[1]].path })
        }
      } else {
        this.childList.forEach(el => {
          el.isOpen = false
        })
        item.isOpen = true
        this.current = item
        this.$myData.settingLastSelectedModuleId = item.path
        if (!isHistory) {
          this.$myApi.routeSet('setting', { path: item.path })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './../common.scss';
</style>
