<template>

  <div>
    <el-dialog
      :title="$t('message.view.test_title')"
      :visible.sync="dialogTips"
      :modal-append-to-body="false"
      :show-close="false"
      width="50%"
      :append-to-body="true"
    >
      <div class="tipsTxt">
        <p>{{ $t('message.view.test_info') }}</p>
        <p class="color">{{ $t('message.view.p1') }}</p>
        <p>{{ $t('message.view.p2') }}</p>
        <p class="color positiionCenter">{{ $t('message.view.p3') }}</p>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="oks"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogTips: this.isShow,
    }
  },
  watch: {
    isShow: function(a, b) {
      this.dialogTips = a
    },
  },
  methods: {

    oks() {
      // 观看视频
      this.dialogTips = false
      // 向父组件传值
      this.$emit('Phishing_mail', 'video')
      // this.checkLearnFishing()
    },
  },
}
</script>
<style lang="scss" scoped>
.tipsTxt{
  line-height: 25px;
.positiionCenter{
  text-align: center;
  margin-top: 20px;
}
.color{
  color: #D0161B;
}
}
</style>
