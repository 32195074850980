<template>
  <div>
    <el-dialog
      :title="$t('message.view.save_to')"
      :visible.sync="dialogDisk"
      :modal-append-to-body="false"
      :before-close="handleClose"
      append-to-body
      width="520px"
    >
      <el-tree
        ref="indexTree"
        :props="props"
        :load="loadNode"
        node-key="nd_id"
        class="treeDia"
        lazy
        @node-click="handleNodeClick"
      >
        <template slot-scope="{ data }">
          <p><svg-icon
            icon-class="wpwjj"
            class="action_icon icos-size-14 middle"
          /> {{ data.nd_name }}</p>
        </template>
      </el-tree>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="$parent.showDialogDisk = false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="saveTreeFree"
        >{{ $t('message.btnText.ok') }}</el-button>
      </div>
    </el-dialog>

    <!-- 消息提示 -->
    <el-dialog
      :title=" $t('message.btnText.info')"
      :visible.sync="dislogMsg"
      :modal-append-to-body="false"
      append-to-body
      width="30%"
    >
      <span>{{ $t('message.view.sace_drive_ok') }}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="goNetworkcontent"
        >{{ $t('message.view.go_look') }}</el-button>
        <el-button
          @click="dislogMsg = false"
        >{{ $t('message.view.konw') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import serialize from '@/utils/serialize'
export default {
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isType: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogDisk: this.isShow,
      dislogMsg: false,
      type: this.isType,
      props: {
        label: 'nd_name',
        children: 'son',
        isLeaf: 'leaf',
      },
      data: [],
      // page_size:Number(this.$store.state.app.userGetConfig.page_size), // 每页多少条
      page_size: 200, // 每页多少条
      page: 1, // 第几页
      total: 0, // 总共多少条
      orderby: 'nd_ctime', // 默认排序
      sequence: 'DESC',
      row: {}, // 点击的目录
      fileRow: this.fileList,
      nd_id_list: [], // 文件id
      file_id: [], // 文件夹id
      node_had: '',
      resolve_had: '',
    }
  },
  watch: {
    isShow: function(a, b) {
      this.row = {}
      this.clearTree()
      this.dialogDisk = a
    },
    fileList: {
      handler(a, b) {
        this.fileRow = a
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    isType: {
      handler(a, b) {
        this.type = a
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    clearTree() { // 清空树的方法
      if (this.node_had !== '') {
        this.node_had.childNodes = []
        this.loadNode(this.node_had, this.resolve_had) // 再次执行懒加载的方法
      }
    },
    handleNodeClick(data) {
      this.row = data
    },
    loadNode(node, resolve) {
      const that = this
      if (node.level === 0) {
        this.node_had = node // 保存一下根节点
        this.resolve_had = resolve // 保存一下根节点
        return resolve([{ nd_name: this.$t('message.view.my_drive'), nd_id: 'top' }])
      }
      if (node.level >= 1) {
        that.loadNodeChildren(node.data, (datas) => {
          const arr = datas
          return resolve(arr)
        })
      }
    },
    // 树的懒加载获取子节点
    async loadNodeChildren(node, callback) {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getDirList',
          data: {
            is_mail_attachment: 0, // 0 网盘 1文件中转站
            parent_nd_id: node.nd_id,
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        if (res.result.list) {
          if (callback) {
            callback(res.result.list)
          }
        }
      })
    },
    handleClose() {
      this.$parent.showDialogDisk = false
      this.dialogDisk = false
    },
    // 保存到网盘
    saveTreeFree(type) {
      if (!this.fileRow.length) {
        this.$message.error(this.$t('message.view.select_save_att'))
      }
      if (!this.row.nd_id) { return this.$message.error(this.$t('message.att.select_folder')) }

      this.nd_id_list = []
      if (this.type.file === 'attachment' || this.type.file === 'viewMail') {
        this.fileRow.forEach(el => {
          this.nd_id_list.push({
            mbox: el.mail_mbox,
            uv: el.uidvalidity,
            uid: el.uid,
            section: el.att_section,
            encoding: el.att_encoding,
            name: el.att_name ? el.att_name : this.$t('message.view.no_att_name'),
          })
        })
        // 附件管理、读信的保存
        let actions = ''// 单个保存还是批量保存
        actions = !this.type.isAll ? 'saveToNetdisk' : 'batchSaveToNetdisk'
        const req = {
          'module': 'mail',
          'require': {
            action: actions,
            netdisk_nd_id: this.row.nd_id,
            list: this.nd_id_list,
            from_type: this.type.file === 'attachment' ? 'attachment' : 'mail',
          },
        }
        console.log(req)
        this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
          this.$parent.showDialogDisk = false
          this.dislogMsg = true
        })
      }
      if (this.type.file === 'fileWebdDisk') {
        this.fileRow.forEach(el => {
          this.nd_id_list.push(el.nd_id)
        })
        // 文件中转站的保存
        const req = {
          'module': 'netdisk',
          'require': {
            action: 'saveToNetdisk',
            data: {
              nd_id_list: this.nd_id_list.toString(),
              parent_nd_id: this.row.nd_id,
            },
          },
        }
        this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
          this.$parent.showDialogDisk = false
          this.dislogMsg = true
        })
      }
    },
    // 前往查看
    goNetworkcontent() {
      // this.$router.push({ name: 'networkcontent', query: { parent_nd_id: this.row.nd_id }})
      this.dislogMsg = false
      this.$myApi.netdiskGoto({ parent_nd_id: this.row.nd_id })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body{
  padding: 10px 20px;
}
.treeDia{
  height: 320px;
  overflow-y: auto;
}
</style>
