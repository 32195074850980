<template>
  <div class="down">
    <el-form
      ref="formLabelAlign"
      :model="formLabelAlign"
      method="post"
      target="_blank"
      :action="Url"
    >
      <el-form-item>
        <el-input
          v-model="formLabelAlign.task"
          name="task"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formLabelAlign.req_data"
          name="req"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formLabelAlign.req_type"
          name="req_type"
        />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import serialize from '@/utils/serialize'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formLabelAlign: {
        task: 'download',
        req_type: 'json',
        req_data: '',
      },
      Url: '',
    }
  },
  watch: {
    isShow: function(a, b) {
      if (a) {
        this.formLabelAlign.req_data = serialize(this.$parent.downAttaReq)
        this.down(this.$parent.downAttaReq.attachment_name)
      }
    },
  },
  methods: {
    down(filename) {
      console.log(filename, 'filename')
      var url = '/nsmail/mail.php/'
      url += encodeURI(filename.replace(/[\/ ?\t\r\n:\|\\*\"\#\'<>]/g, ' '))
      this.Url = url
      this.$nextTick(() => {
        this.$refs.formLabelAlign.$el.submit()
        this.$parent.isAttaDown = false
      })
    },

  },
}

</script>
<style lang="scss" scoped>
.down{
  display: none;
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
}
</style>
