<template>
  <!-- 网盘模块 -->
  <div
    v-if="selfActived"
    style="width: 100%;height: 100%;position: relative;"
  >
    <div class="mail-moudle-menu">
      <!-- 设置模块左侧菜单 -->
      <div
        class="menu-moudle-aside disable-select"
        style="border-right: 1px solid #DEDCDC;"
      >
        <div class="menu-list-container">
          <div
            class="menu-list"
            style="padding-top: 15px;"
          >
            <div
              class="menu-list-item theme-hover-background"
              :class="Type==='all'?'theme-click-background':''"
              @click="handleNodeClick({nd_type:'all', label: '全部文件'})"
            >
              <div
                class="menu-list-item"
              >
                <span
                  class="menu-list-item-text menu-list-item-text-network"
                  style="margin-left: -5px;font-weight: bold;"
                >{{ $t('message.netdisk.all') }}</span>
              </div>
            </div>
            <div
              v-for="(item, index) in navList"
              :key="index"
              class="menu-list-item theme-hover-background"
              :class="Type===item.nd_type?'theme-click-background':''"
              @click="handleNodeClick(item)"
            >
              <div
                class="menu-list-item"
              >
                <svg-icon
                  style="width:15px"
                  :icon-class="item.icon"
                />
                <span
                  class="menu-list-item-text menu-list-item-text-network"
                  style="white-space: nowrap;"
                >{{ $t(`message.netdisk.${item.name}`) }}</span>
              </div>
            </div>
            <div
              style="border-top: 1px solid #DEDCDC;cursor: pointer;margin-top:20px;padding-top:20px;"
            >
              <div
                class="menu-list-item theme-hover-background"
                :class="Type==='share'?'theme-click-background':''"
                @click="clickShare"
              >
                <div class="menu-list-item">
                  <span
                    class="menu-list-item-text menu-list-item-text-network"
                    style="font-weight: bold;"
                  >{{ $t('message.netdisk.myshare') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav_foot">
          <p>
            <span>{{ $t('message.netdisk.used') }}：{{ quota | sizefilter }}</span>
            <span style="padding-right:15px;white-space: nowrap;">{{ $t('message.netdisk.total') }}{{ quota_total | sizefilter }}</span>
          </p>
          <el-progress
            :percentage="((Number(quota)/Number(quota_total))*100)"
            color="text-color"
            :show-text="false"
            style="width:95%;height:8px;display:inline-block;"
          />
        </div>
      </div>
      <div class="menu-moudle-main">
        <!-- <networkcontent /> -->
        <div class="attachment_box trunBottom">
          <!-- 我的分享 -->
          <div
            v-if="Type==='share'"
            style="width: 100%;"
          >
            <div

              class="action-header"
            >
              <div>
                <span class="search-data-label">
                  {{ $t('message.netdisk.myshare') }}
                </span>
              </div>
              <div style="display: flex;align-items: center;margin-top:10px;">
                <el-button
                  v-if="$store.state.app.user_permissions.mailbox === 1"
                  type="primary"
                  @click="composeAction('all')"
                >
                  {{ $t('message.netdisk.sendmail') }}
                </el-button>
                <el-button
                  type="primary"
                  @click="delShareAction('all')"
                >
                  {{ $t('message.netdisk.Unshare') }}
                </el-button>
              </div>
            </div>
            <div
              class="networkcontent-action"
            >{{ $t('message.netdisk.myshare') }}
            </div>
            <!-- 我的分享 列表 -->
            <div
              class="multipleTable-share-class"
            >
              <el-table
                ref="multipleShareTable"
                :data="tableShareData"
                style="width: 100%"
                highlight-current-row
                :height="scrollerShareHeight"
                tooltip-effect="light"
                class="table"
                @selection-change="handleShareChange"
              >
                <el-table-column
                  type="selection"
                  align="center"
                  width="55"
                />
                <el-table-column
                  :label="$t('message.netdisk.sharename')"
                  prop="share_name"
                >
                  <template
                    slot-scope="scope"
                  >
                    <p
                      class="one-line-ellipsis"
                      :title="scope.row.share_name"
                      style="cursor: pointer;"
                      @click="openFolderAction('tab',scope.row)"
                    >
                      <svg-icon
                        class="attaClass middle netdiskicon"
                        :icon-class="getFileType(scope.row.share_name)+'icon'"
                        class-name="atticon"
                      />
                      {{ scope.row.share_name == '' ? $t('message.netdisk.noname') : scope.row.share_name }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('message.netdisk.sharetime')"
                  width="200"
                >
                  <template slot-scope="scope">
                    <span style="color:#606266">{{ scope.row.share_ctime | dateFilter }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('message.netdisk.expiretime')"
                  width="100"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <p
                      v-if="scope.row.residue_expire_day <=30"
                      :style="scope.row.residue_expire_day>0&&scope.row.residue_expire_day <3 ? 'color:red;' : 'color:#606266'"
                    >
                      {{ scope.row.residue_expire_day <= 0 ? $t('message.netdisk.expired'): (scope.row.residue_expire_day + $t('message.netdisk.dayslayer')) }}
                    </p>
                    <span
                      v-else-if="scope.row.expire_time=='253402271999'"
                      style="color:#606266"
                    >{{ $t('message.netdisk.day4') }}</span>
                    <span
                      v-else
                      style="color:#606266"
                    >{{ scope.row.residue_expire_day|dateFilter }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="view_count"
                  :label="$t('message.netdisk.checktimes')"
                  align="center"
                  :width="$i18n.locale==='english'?'150':'100'"
                >
                  <template slot-scope="scope"><span style="color:#606266">{{ scope.row.view_count?scope.row.view_count:'--' }}</span></template>
                </el-table-column>
                <el-table-column
                  prop="download_count"
                  :label="$t('message.netdisk.downloadtimes')"
                  align="center"
                  :width="$i18n.locale==='english'?'150':'100'"
                >
                  <template slot-scope="scope"><span style="color:#606266">{{ scope.row.download_count?scope.row.download_count:'--' }}</span></template>
                </el-table-column>
                <el-table-column
                  label=" "
                  width="300"
                >
                  <template
                    slot-scope="scope"
                  >
                    <div>
                      <span
                        v-if="scope.row.residue_expire_day>0&&$store.state.app.user_permissions.mailbox === 1"
                        class="button-span text-color"
                        @click="composeAction('one', scope.row)"
                      >
                        {{ $t('message.netdisk.sendmail') }}
                      </span>
                      <span
                        v-if="scope.row.residue_expire_day>0"
                        class="button-span text-color"
                        @click="copyShareAction(scope.row)"
                      >{{ $t('message.netdisk.copylink') }}</span>
                      <span
                        class="button-span text-color"
                        @click="delShareAction('one',scope.row)"
                      >{{ $t('message.netdisk.Unshare') }}</span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 我的分享翻页 -->
            <div class="trunPage">
              <el-pagination
                v-if="totalShare>0"
                :current-page="Number(pageShare)"
                :page-size="page_sizeShare"
                layout="total, prev, pager, next, jumper"
                :total="totalShare"
                @current-change="handleCurrentChangeShare"
              />
            </div>
          </div>
          <!-- 全部文件 -->
          <div
            v-if="Type!=='share'&&!headerSearch"
            style="width: 100%;"
          >
            <div
              class="action-header"
              style="display: flex;align-items: center;"
            >
              <!-- <span class="search-data-label">
                {{ $t('message.netdisk.all') }}
              </span> -->
              <el-button
                type="primary"
                icon="el-icon-upload2"
                size="mini"
                class="netdisk-btn"
                @click="SplitUploadClick"
              >
                {{ $t('message.netdisk.upload') }}</el-button>
              <el-button
                icon="el-icon-folder-add"
                size="mini"
                class="netdisk-btn"
                :disabled="Type!=='all'"
                @click="addFolderClick"
              >{{ $t('message.netdisk.createfolder') }}</el-button>
              <div
                class="Opea-svg-select-noBorder del"
                @click="delRow('all','')"
              >
                <svg-icon
                  class="icos-size-14"
                  icon-class="view_del"
                />
                <span class="Opea-svg-select-noBorder-span">{{ $t('message.netdisk.delete') }}</span>
              </div>
              <div
                class="Opea-svg-select-noBorder"
                @click="getMoveFolderList('all')"
              >
                <svg-icon
                  class="icos-size-14"
                  icon-class="view_move"
                />
                <span class="Opea-svg-select-noBorder-span">{{ $t('message.netdisk.move') }}</span>
              </div>
              <div
                v-if="$store.state.app.user_permissions.mailbox === 1"
                class="Opea-svg-select-noBorder"
                @click="attachmentCompose('all')"
              >
                <svg-icon
                  class="icos-size-14"
                  icon-class="compose"
                />
                <span class="Opea-svg-select-noBorder-span">{{ $t('message.netdisk.sendmail') }}</span>
              </div>
              <div
                class="Opea-svg-select-noBorder"
                @click="shareDialogShow('all')"
              >
                <svg-icon
                  class="icos-size-14"
                  icon-class="wp_zf"
                />
                <span class="Opea-svg-select-noBorder-span">{{ $t('message.netdisk.share') }}</span>
              </div>
              <div
                class="Opea-svg-select-noBorder"
                @click="downAtta"
              >
                <svg-icon
                  class="icos-size-14"
                  icon-class="download"
                />
                <span class="Opea-svg-select-noBorder-span">{{ $t('message.netdisk.download') }}</span>
              </div>
            </div>
            <!-- 全部文件 -->
            <div class="networkcontent-action">
              <i
                class="el-icon-arrow-left"
                @click="parentsFolderAction"
              />
              <i
                class="el-icon-refresh-left"
                style="margin:0 3px 0 10px"
                @click="getList"
              />
              <span style="width: 20px;display: inline-block;text-align: center;">|</span>
              <span
                v-for="(item, index) in lastFourItems"
                :key="index"
                style="cursor: pointer;"
                @click="openFolderAction('id',item)"
              >{{ item[0]==='top'?$t('message.netdisk.mydrive'):item[1]|ellipsis }}
                <span
                  v-show="index!=(lastFourItems.length -1)"
                  style="display: inline-block;text-align: center;"
                ><b v-show="index===0&&lastFourItems.length>3">...</b><span style="padding: 0 3px;">></span></span>
              </span>
            </div>
            <!-- 全部文件 列表 -->
            <div class="multipleTable-class">
              <el-table
                ref="multipleTableSearch"
                :data="tableData"
                style="width: 100%"
                highlight-current-row
                :height="scrollerHeight"
                class="table"
                @selection-change="handleSelectionChange"
                @sort-change="sortTabl"
              >
                <el-table-column
                  type="selection"
                  align="center"
                  width="55"
                />
                <el-table-column
                  :label="$t('message.netdisk.filename')"
                  prop="nd_name"
                  sortable="custom"
                >
                  <template
                    slot-scope="scope"
                  >
                    <p
                      v-if="scope.row.nd_is_dir==1"
                      class="one-line-ellipsis"
                      style="cursor: pointer;"
                      @click="openFolderAction('tab',scope.row)"
                    >
                      <svg-icon
                        class="attaClass middle netdiskicon"
                        icon-class="folder"
                        class-name="atticon"
                      />

                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top"
                        :open-delay="300"
                      >
                        <div
                          slot="content"
                          style="max-width:500px"
                        >{{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</div>
                        <span> {{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</span>
                      </el-tooltip>

                    </p>
                    <p
                      v-else
                      class="one-line-ellipsis"
                      style="cursor: pointer;"
                    >
                      <svg-icon
                        class="attaClass middle netdiskicon"
                        :icon-class="getFileType(scope.row.nd_name)+'icon'"
                        class-name="atticon"
                      />
                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top"
                        :open-delay="300"
                      >
                        <div
                          slot="content"
                          style="max-width:500px"
                        >{{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</div>
                        <span> {{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</span>
                      </el-tooltip>
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nd_ctime"
                  :label="$t('message.netdisk.modifiedtime')"
                  sortable="custom"
                  width="200"
                >
                  <template slot-scope="scope">
                    {{ scope.row.nd_ctime | dateFilter }}
                  </template>
                </el-table-column>
                <!-- <el-table-column
          prop="parent_nd_name"
          label="所在目录"
        >
          <template slot-scope="scope">
            <span>{{ scope.row | parent_nd_nameFilter }}</span>
          </template>
        </el-table-column> -->
                <el-table-column
                  :label="$t('message.netdisk.type')"
                  width="100"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.nd_is_dir==1">{{ $t('message.netdisk.folder') }}</span>
                    <span v-else>{{ scope.row.nd_name_suffix }} {{ $t('message.netdisk.file') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nd_size"
                  :label="$t('message.netdisk.size')"
                  width="100"
                  sortable="custom"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.nd_is_dir==1">--</span>
                    <span v-else>{{ scope.row.nd_size | mailSizefilter }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="300"
                >
                  <template slot-scope="scope">
                    <div>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.download')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon cur"
                            icon-class="download"
                            @click="oneDown(scope.row)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.mv')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon"
                            icon-class="view_move"
                            @click="getMoveFolderList('one',scope.row)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.share')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            v-if="$store.state.app.user_permissions.mailbox === 1"
                            class="svgIcon"
                            icon-class="wp_zf"
                            @click="shareDialogShow('one',scope.row)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.sendmail')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            v-if="$store.state.app.user_permissions.mailbox === 1"
                            class="svgIcon"
                            icon-class="compose"
                            @click="attachmentCompose('one',scope.row,scope.$index)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.delete')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon"
                            icon-class="view_del"
                            @click="delRow('one',scope.row,scope.$index)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.rename')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon"
                            icon-class="chongming"
                            @click="renameNew(scope.row)"
                          />
                        </span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="trunPage">
              <el-pagination
                :current-page="Number(page)"
                :page-size="page_size"
                layout="total, prev, pager, next, jumper"
                :total="total"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
          <!-- 搜索结果 -->
          <div
            v-if="headerSearch"
            style="width: 100%;"
          >
            <div
              class="networkcontent-action"
            >{{ $t('message.netdisk.result') }}
            </div>
            <div class="multipleTable-class">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style="width: 100%"
                highlight-current-row
                :height="scrollerHeight"
                class="table"
                @selection-change="handleSelectionChange"
                @sort-change="sortTabl"
              >
                <el-table-column
                  type="selection"
                  align="center"
                  width="55"
                />
                <el-table-column
                  :label="$t('message.netdisk.filename')"
                  prop="nd_name"
                  sortable="custom"
                >
                  <template
                    slot-scope="scope"
                  >
                    <p
                      v-if="scope.row.nd_is_dir==1"
                      class="one-line-ellipsis"
                      style="cursor: pointer;"
                      @click="openFolderAction('tab',scope.row)"
                    >
                      <svg-icon
                        class="attaClass middle netdiskicon"
                        icon-class="folder"
                        class-name="atticon"
                      />

                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top"
                        :open-delay="300"
                      >
                        <div
                          slot="content"
                          style="max-width:500px"
                        >{{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</div>
                        <span> {{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</span>
                      </el-tooltip>

                    </p>
                    <p
                      v-else
                      class="one-line-ellipsis"
                      style="cursor: pointer;"
                    >
                      <svg-icon
                        class="attaClass middle netdiskicon"
                        :icon-class="getFileType(scope.row.nd_name)+'icon'"
                        class-name="atticon"
                      />
                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top"
                        :open-delay="300"
                      >
                        <div
                          slot="content"
                          style="max-width:500px"
                        >{{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</div>
                        <span> {{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}</span>
                      </el-tooltip>
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nd_ctime"
                  :label="$t('message.netdisk.modifiedtime')"
                  sortable="custom"
                  width="200"
                >
                  <template slot-scope="scope">
                    {{ scope.row.nd_ctime | dateFilter }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="parent_nd_name"
                  :label="$t('message.netdisk.dir')"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.parent_nd_id === 'top'?$t('message.netdisk.mydrive'): scope.row.parent_nd_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('message.netdisk.type')"
                  width="100"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.nd_is_dir==1">{{ $t('message.netdisk.folder') }}</span>
                    <span v-else>{{ scope.row.nd_name_suffix }} {{ $t('message.netdisk.file') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nd_size"
                  :label="$t('message.netdisk.size')"
                  width="100"
                  sortable="custom"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.nd_is_dir==1">--</span>
                    <span v-else>{{ scope.row.nd_size | mailSizefilter }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="300"
                >
                  <template slot-scope="scope">
                    <div>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.download')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon cur"
                            icon-class="download"
                            @click="oneDown(scope.row)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.mv')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon"
                            icon-class="view_move"
                            @click="getMoveFolderList('one',scope.row)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.share')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            v-if="$store.state.app.user_permissions.mailbox === 1"
                            class="svgIcon"
                            icon-class="wp_zf"
                            @click="shareDialogShow('one',scope.row)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.sendmail')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            v-if="$store.state.app.user_permissions.mailbox === 1"
                            class="svgIcon"
                            icon-class="compose"
                            @click="attachmentCompose('one',scope.row,scope.$index)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.delete')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon"
                            icon-class="view_del"
                            @click="delRow('one',scope.row,scope.$index)"
                          />
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        class="item cur tooltip"
                        effect="light"
                        :content="$t('message.netdisk.rename')"
                        :open-delay="300"
                        placement="top-start"
                      >
                        <span>
                          <svg-icon
                            class="svgIcon"
                            icon-class="chongming"
                            @click="renameNew(scope.row)"
                          />
                        </span>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="trunPage">
              <el-pagination
                :current-page="Number(page)"
                :page-size="page_size"
                layout="total, prev, pager, next, jumper"
                :total="total"
                @current-change="handleCurrentChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新建文件夹 -->
    <el-dialog
      :title="$t('message.netdisk.createfolder')"
      :visible.sync="addFolder_dialog"
      width="520px"
      :modal-append-to-body="false"
      :append-to-body="true"
      :before-close="handleClose"
      custom-class="shareclass"
    >
      <el-form
        ref="addName"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="add_name_prop">
          <el-input
            v-model="form.addFolder_name"
            maxlength="64"
            show-word-limit
            class="formInputLimit"
          /></el-form-item>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="addFolderAction('addName')"
        >{{ $t('message.btnText.ok') }}</el-button>
      </div>
    </el-dialog>
    <!-- 移动到文件夹 -->
    <el-dialog
      :title="$t('message.netdisk.movetof')"
      :visible.sync="to_move_dialog"
      :modal-append-to-body="false"
      :before-close="handleMoveClose"
      width="520px"
      custom-class="moveFolder"
    >
      <el-tree
        :props="props"
        :load="loadNode"
        node-key="nd_id"
        highlight-current
        lazy
        @node-click="toMoveFolderSet"
      >
        <span
          slot-scope="{ node }"
          class="span-ellipsis"
        >

          <el-tooltip
            class="item"
            effect="light"
            placement="top-start"
            :open-delay="300"
          >
            <div
              slot="content"
              style="max-width:500px"
            >{{ node.label }}</div>

            <span
              class="one-line-ellipsis"
              style="width: 400px;display:block;"
            >{{ node.label }}</span>
          </el-tooltip>
        </span>
      </el-tree>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="to_move_dialog = false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="to_move_folderChange"
        >{{ $t('message.btnText.ok') }}</el-button>
      </div>
    </el-dialog>
    <!-- 重命名 -->
    <el-dialog
      :title="$t('message.netdisk.rename')"
      class="rename"
      width="420px"
      :visible.sync="dialogRename"
      :modal-append-to-body="false"
      :before-close="newRenameClose"
    >
      <el-form
        ref="newRename"
        :model="form"
        :rules="rules"
      >
        <el-form-item prop="ruls_new_nd_name">
          <p
            class="wtbox"
          >
            <el-input
              v-model="form.new_nd_name"
              autocomplete="off"
              :maxlength="row.nd_is_dir==1?64:128"
              show-word-limit
            />
          </p>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="newRenameClose">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="submitRenameNew('newRename')"
        >{{ $t('message.btnText.ok') }}</el-button>
      </div>
    </el-dialog>
    <!-- 分享 -->
    <el-dialog
      :title="$t('message.netdisk.share')"
      :visible.sync="shareDialog"
      :modal-append-to-body="false"
      width="600px"
      custom-class="shareclass"
    >
      <div class="share-dialog">
        <div class="share-dialog-name">
          <div
            style="flex: 1;height: 100%;"
            class="one-line-ellipsis"
          >
            {{ $t('message.netdisk.sharefile') }}： {{ shareData | shareNameFilter }}
          </div>
          <div
            v-if="shareData.length>1"
            style="width: 165px;height: 100%;"
          >{{ $t('message.netdisk.filenum',{num:shareData.length}) }}</div>
        </div>
        <div style="margin-top: 10px;height: 30px;line-height: 30px;">{{ $t('message.netdisk.unexpire') }}:</div>
        <div style="height: 30px;line-height: 30px;">
          <el-radio-group v-model="share_expiration">
            <el-radio :label="1">{{ $t('message.netdisk.day1') }}</el-radio>
            <el-radio :label="7">{{ $t('message.netdisk.day2') }}</el-radio>
            <el-radio :label="30">{{ $t('message.netdisk.day3') }}</el-radio>
            <el-radio :label="0">{{ $t('message.netdisk.day4') }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="shareDialog = false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="shareDialogClick"
        >{{ $t('message.netdisk.crearelink') }}</el-button>
      </div>
    </el-dialog>
    <!-- 分享链接 -->
    <el-dialog
      :title="$t('message.netdisk.share')"
      :visible.sync="shareLinkDialog"
      :modal-append-to-body="false"
      width="600px"
      custom-class="shareclass"
    >
      <div class="share-dialog">
        <div class="share-dialog-name">
          <div
            style="flex: 1;height: 100%;"
            class="one-line-ellipsis"
          >
            {{ $t('message.netdisk.sharefile') }}： {{ shareData | shareNameFilter }}
          </div>
          <div
            v-if="shareData.length>1"
            style="width: 165px;height: 100%;"
          >{{ $t('message.netdisk.filenum',{num:shareData.length}) }}</div>
        </div>
        <div style="margin-top: 10px;height: 30px;line-height: 30px;">{{ $t('message.netdisk.link') }}:</div>
        <div style="padding:5px;border-radius: 2px;border: 1px solid #D7D7D7;">
          {{ share_link }}
        </div>
        <div style="margin-top: 10px;height: 30px;line-height: 30px;">{{ $t('message.netdisk.code') }}:</div>
        <div style="padding:5px;border-radius: 2px;border: 1px solid #D7D7D7;">
          {{ share_password }}
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="$store.state.app.user_permissions.mailbox !== 1"
          @click="composeAction('one', {'share_link':share_link, 'share_password':share_password})"
        >{{ $t('message.netdisk.copysend') }}</el-button>
        <el-button
          type="primary"
          @click="copyShareAction({'share_link':share_link,'share_password':share_password})"
        >{{ $t('message.netdisk.copylinkcode') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import serialize from '@/utils/serialize'
import { GetFileType, copy, transformClass } from '@/utils/utils.js'
export default {
  filters: {
    // 大小（接口返回字节）
    sizefilter(size) {
      return transformClass.mailSize(size.toString())
    },
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
    shareNameFilter(data) {
      let name = ''
      if (data.length === 1) name = data[0].nd_name
      else {
        data.forEach((item) => {
          name = name + item.nd_name + '、'
        })
      }
      return name
    },
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    },
  },
  data() {
    return {
      search: '',
      tableData: [],
      tableShareData: [], // 我的分享列表
      multipleSelection: [],
      multipleShare: [],
      currentPage: 1, // 前往第几页
      currentPageShare: 1,
      page_size: this.$store.state.app.userGetConfig.page_size, // 每页多少条
      page_sizeShare: this.$store.state.app.userGetConfig.page_size,
      page: 1, // 第几页
      pageShare: 1,
      total: 0, // 总共多少条
      totalShare: 0,
      orderby: 'nd_is_dir', // 默认排序
      sequence: 'DESC',
      dialogRename: false, // 重命名文件夹
      form: {
        addFolder_name: '',
        new_nd_name: '', // 新文件名称
      },
      row: {}, // 需要的参数
      addFolder_dialog: false, // 新建文件夹
      to_move_dialog: false,
      to_move_folder: 'top', // 移动目标文件夹
      props: {
        label: 'nd_name',
        children: 'son',
        isLeaf: 'leaf',
      },
      node_had: '',
      resolve_had: '',
      openFolderId: 'top', // 要打开文件夹
      allFolderObj: [['top', '我的网盘']], // 上一级文件夹
      scope_row: {}, // 列表某一项
      shareDialog: false,
      shareLinkDialog: false, // 分享链接
      share_expiration: 7,
      shareData: [],
      shareLinkData: {},
      Type: '', // 列表类型:全部文件、我的分享
      share_link: '', // 分享链接
      share_password: '', // 提取码
      downAttaReq: {},
      headerSearch: false,
      scrollerHeight: window.innerHeight - 230,
      scrollerShareHeight: window.innerHeight - 250,
      lang: localStorage.getItem('lang'),
      selfActived: false,
      navList: [
        {
          nd_type: '1',
          label: '图片',
          name: 'image',
          icon: 'wp_img',
        }, {
          nd_type: '2',
          label: '音频',
          name: 'audio',
          icon: 'wp_music',
        }, {
          nd_type: '3',
          label: '视频',
          name: 'vodeo',
          icon: 'wp_video',
        }, {
          nd_type: '4',
          label: '文档',
          name: 'doc',
          icon: 'wp_file',
        }, {
          nd_type: '5',
          label: '压缩包',
          name: 'zip',
          icon: 'wp_raa',
        }, {
          nd_type: '0',
          label: '其他',
          name: 'other',
          icon: 'wp_more',
        }],
      quota: 0,
      quota_total: 10,
    }
  },
  computed: {
    rules() {
      const validateAddNdName = (rule, value, callback) => {
        if (this.form.addFolder_name === '') {
          callback(new Error(this.$t('message.netdisk.namenotnull')))
        } else {
          const str = '\/:*?<>|,'
          let isFlag = true
          for (let i = 0; i < str.length; i++) {
            if (this.form.addFolder_name.indexOf(str[i]) !== -1) {
              isFlag = false
              break
            }
          }
          if (!isFlag) {
            callback(new Error(this.$t('message.netdisk.err3')))
          } else {
            callback()
          }
        }
      }
      const validateNdName = (rule, value, callback) => {
        if (this.form.new_nd_name === '') {
          callback(new Error(this.$t('message.netdisk.namenotnull')))
        } else {
          const str = '\/:*?<>|,'
          let isFlag = true
          for (let i = 0; i < str.length; i++) {
            if (this.form.new_nd_name.indexOf(str[i]) !== -1) {
              isFlag = false
              break
            }
          }
          if (!isFlag) {
            callback(new Error(this.$t('message.netdisk.err3')))
          } else {
            callback()
          }
        }
      }
      return {
        ruls_new_nd_name: [
          { required: true, validator: validateNdName, trigger: 'blur' },
        ],
        add_name_prop: [
          { required: true, validator: validateAddNdName, trigger: 'blur' },
        ],
      }
    },
    /** 监听上传是否成功 */
    isUpload_successWatch() {
      return this.$store.state.network.isUpload_success
    },
    lastFourItems() {
      let arr = []
      if (this.allFolderObj.length > 4) {
        arr = this.allFolderObj.slice(-3)
      } else {
        arr = this.allFolderObj.slice(1)
      }
      return [this.allFolderObj[0], ...arr]
    },
    prompt() {
      return this.$t('message.btnText.info')
    },
    info1() {
      return this.$t('message.netdisk.confirmselfiles')
    },
    ok() {
      return this.$t('message.btnText.ok')
    },
    cancel() {
      return this.$t('message.btnText.cancel')
    },
  },
  watch: {
    isUpload_successWatch(newVal, oldVal) {
      if (newVal === true) {
        this.$store.commit('network/IS_UPLOAD_SUCCESS_SETTING', false)
        this.actionFilter()
        // 更新进度条
        this.getQuota()
      }
    },
  },
  created() {
    const thisobj = this
    this.$myApi.activeModuleNetdisk = (data, options) => {
      this.getQuota()
      if (!thisobj.selfActived) {
        thisobj.selfActived = true
      }
      if (data !== undefined) {
        thisobj.netdiskGoto(data, options)
      } else {
        thisobj.handleNodeClick({ nd_type: 'all', label: '全部文件' })
      }
      if (!options || options.isHistory !== true) {
        this.$myApi.routeSet('netdisk')
      }
    }
    this.$myApi.netdiskGoto = (data) => {
      this.$myApi.topGoto('netdisk', data)
    }
    this.$myApi.netdiskSearch = (data) => {
      this.$myApi.topGoto('netdisk', data)
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.scrollerHeight = window.innerHeight - 230
        this.scrollerShareHeight = window.innerHeight - 250
      })()
    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      if (this.$refs['multipleTable']) this.$refs['multipleTable'].doLayout()
    })
  },
  methods: {
    netdiskGoto(data) {
      this.headerSearch = false
      if (JSON.stringify(data) === '{}' || data === undefined) {
        return this.handleNodeClick({ nd_type: 'all', label: '全部文件' })
      }
      if (Object.prototype.hasOwnProperty.call(data, 'search') && data.search) {
        // 搜索
        this.headerSearch = true
        this.Type = ''
        this.page = 1
        this.search = data.search
        this.allFolderObj = [['top', '我的网盘']]
        this.openFolderId = ''
        this.getList()
      }
      if (Object.prototype.hasOwnProperty.call(data, 'parent_nd_id')) {
        this.openFolderId = data.parent_nd_id
        // this.handleNodeClick({ nd_type: 'all', label: '全部文件' })
        this.Type = 'all'
        this.search = ''
        this.page = 1
        this.getList()
      }
    },
    /** 进度条 */
    getQuota() {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getQuota',
          data: {
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        this.quota = res.result.quota[1]
        this.quota_total = res.result.quota[0]
        this.$store.commit('network/GET_QUOTA_SETTING', {
          quota: this.quota,
          quota_total: this.quota_total,
        })
      })
    },
    handleNodeClick(item) {
      this.headerSearch = false
      this.Type = item.nd_type
      this.search = ''
      this.$myState.headerSearchWords = ''
      this.openFolderId = this.Type === 'all' ? 'top' : ''
      this.page = 1
      this.getList()
    },
    actionFilter() {
      this.headerSearch = false
      if (this.Type === 'share') {
        this.getTableShareData()
      } else {
        if (this.Type === 'all') {
          this.getList()
        } else {
          this.getList() // 类型搜索
        }
      }
    },
    SplitUploadClick() {
      this.$store.commit('network/UPLOAD_TYPE_SETTING', 'network') // 上传类型为网盘
      this.$store.commit('network/OPEN_FOLDER_ID_SETTING', this.openFolderId) // 上传为哪个文件夹
      this.$store.commit('network/IS_NETWORK_UPLOAD_SETTING', true) // 触发上传
    },
    /** 发送邮件，复制链接 */
    composeAction(type, item) {
      const data = []
      if (type === 'all') {
        if (this.multipleShare.length < 1) {
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err4'),
          })
        } else {
          this.multipleShare.forEach(i => {
            data.push({ 'share_link': i.share_link, 'share_password': i.share_password })
          })
        }
      } else {
        data.push({ 'share_link': item.share_link, 'share_password': item.share_password })
      }
      this.shareLinkDialog = false
      this.$myApi.mainTab.showComposeMail({ type: 'share', subject: this.$t('message.mbox.newmail'), composeData: data })
    },
    /** 发送邮件，全部文件以附件格式 */
    attachmentCompose(type, item) {
      const data = []
      let is_dir = true
      let ids = ''
      if (type === 'all') {
        if (this.multipleSelection.length < 1) {
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err1'),
          })
        } else {
          this.multipleSelection.forEach(i => {
            if (i.nd_is_dir.toString() === '1') {
              is_dir = false
              return this.$message({
                type: 'error',
                message: this.$t('message.netdisk.err6'),
              })
            } else {
              ids = ids + i.nd_id + ','
              data.push({ 'nd_id': i.nd_id, 'nd_name': i.nd_name, 'nd_size': i.nd_size })
            }
          })
        }
      } else {
        if (item.nd_is_dir.toString() === '1') {
          is_dir = false
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err6'),
          })
        } else {
          ids = ids + item.nd_id + ','
          data.push({ 'nd_id': item.nd_id, 'nd_name': item.nd_name, 'nd_size': item.nd_size })
        }
      }
      if (is_dir) {
        const params = {
          module: 'netdisk',
          require: {
            action: 'setSuperatt',
            data: {
              nd_id_list: ids,
            },
          },
        }
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(res => {
          const list = res.result.list
          data.forEach(i => {
            i.expire_time = list[i.nd_id][1]
            i.nd_url = list[i.nd_id][0]
          })

          this.$myApi.mainTab.showComposeMail({ type: 'netdisk', subject: this.$t('message.mbox.newmail'), composeData: data })
        })
      }
    },
    /** 打开分享弹窗 */
    shareDialogShow(type, item) {
      this.share_expiration = 7
      this.shareData = []
      if (type === 'one') {
        this.scope_row = item
        this.shareData.push(item)
      } else if (type === 'all') {
        this.scope_row = {}
        if (this.multipleSelection.length < 1) {
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err4'),
          })
        } else {
          this.shareData.push(...this.multipleSelection)
        }
      }
      this.shareDialog = true
    },
    clickShare() {
      this.search = ''
      this.$myState.headerSearchWords = ''
      this.pageShare = 1
      this.Type = 'share'
      this.getTableShareData()
    },
    /** 查询我的分享列表 */
    getTableShareData() {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getShareList',
          data: {
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        this.tableShareData = res.result.list
        this.totalShare = res.result.total
        // 清空全部文件的数据，解决某一类型为空闪现上次结果问题
        this.allFolderObj = [['top', '我的网盘']]
        this.$store.commit('network/Set_allFolderObj', this.allFolderObj)
        this.tableData = []
      })
    },
    /** 复制链接 */
    copyShareAction(item) {
      const str = `${this.$t('message.netdisk.link')}：` + item.share_link + `\r\n${this.$t('message.netdisk.code')}：` + item.share_password
      copy.copyText(str)
      this.$message({
        message: this.$t('message.netdisk.info1'),
        type: 'success',
      })
      this.shareLinkDialog = false
    },
    /** 取消分享 */
    delShareAction(type, item) {
      let share_id = ''
      if (type === 'one') {
        this.scope_row = item
        share_id = item.share_id
      } else if (type === 'all') {
        this.scope_row = {}
        if (this.multipleShare.length < 1) {
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err4'),
          })
        } else {
          this.multipleShare.forEach(item => {
            share_id = share_id + item.share_id + ','
          })
        }
      }
      this.$confirm(this.$t('message.netdisk.info2'), this.prompt, {
        confirmButtonText: this.ok,
        cancelButtonText: this.cancel,
        type: 'warning',
      }).then(() => {
        const req = {
          'module': 'netdisk',
          'require': {
            action: 'deleteShare',
            data: {
              is_mail_attachment: 0, // 0 网盘 1文件中转站
              share_id: share_id,
            },
          },
        }
        this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.netdisk.info4'),
          })
          this.getTableShareData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('message.netdisk.info3'),
        })
      })
    },
    /** 获取可移动文件夹 */
    getMoveFolderList(type, item) {
      if (type === 'one') this.scope_row = item
      if (type === 'all') {
        this.scope_row = {}
        if (this.multipleSelection.length < 1) {
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err4'),
          })
        }
      }
      this.clearTree()
      this.to_move_dialog = true
      this.to_move_folder = ''
    },
    clearTree() { // 清空树的方法
      if (this.node_had !== '') {
        this.node_had.childNodes = []
        this.loadNode(this.node_had, this.resolve_had) // 再次执行懒加载的方法
      }
    },
    loadNode(node, resolve) {
      const that = this
      if (node.level === 0) {
        this.node_had = node // 保存一下根节点
        this.resolve_had = resolve // 保存一下根节点
        return resolve([{ nd_name: this.$t('message.netdisk.mydrive'), nd_id: 'top' }])
      }
      if (node.level >= 1) {
        that.loadNodeChildren(node.data, (datas) => {
          const arr = datas
          return resolve(arr)
        })
      }
    },
    // 树的懒加载获取子节点
    async loadNodeChildren(node, callback) {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getDirList',
          data: {
            is_mail_attachment: 0, // 0 网盘 1文件中转站
            parent_nd_id: node.nd_id,
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        if (res.result.list) {
          if (callback) {
            callback(res.result.list)
          }
        }
      })
    },
    toMoveFolderSet(val) {
      this.to_move_folder = val.nd_id
    },
    /** 移动到文件夹 */
    to_move_folderChange() {
      if (!this.to_move_folder) {
        return this.$message.error(this.$t('message.att.select_folder'))
      }
      let nd_id_list = []
      if (JSON.stringify(this.scope_row) !== '{}') {
        nd_id_list.push(this.scope_row.nd_id)
      } else {
        this.multipleSelection.forEach(e => {
          nd_id_list.push(e.nd_id)
        })
      }
      nd_id_list = nd_id_list.toString()
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'move',
          data: {
            nd_id_list: nd_id_list,
            parent_nd_id: this.to_move_folder,
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        this.$message({
          type: 'success',
          message: this.$t('message.netdisk.mvsuccess'),
        })
        this.to_move_dialog = false
        // this.page = 1
        this.getList()
      })
    },
    /** 打开新建文件夹弹窗 */
    addFolderClick() {
      this.addFolder_dialog = true
      this.form.addFolder_name = ''
    },
    /** 关闭弹窗 */
    handleMoveClose() {
      this.to_move_dialog = false
    },
    handleClose() {
      this.addFolder_dialog = false
      this.$nextTick(e => {
        this.$refs['addName'].resetFields()
      })
    },
    newRenameClose() {
      this.dialogRename = false
      this.$nextTick(e => {
        this.$refs['newRename'].resetFields()
      })
    },
    /** 新建文件夹 */
    addFolderAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const req = {
            'module': 'netdisk',
            'require': {
              action: 'create',
              data: {
                nd_name: this.form.addFolder_name,
                parent_nd_id: this.openFolderId,
                is_mail_attachment: '0',
              },
            },
          }
          this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.netdisk.cresuccess'),
            })
            this.handleClose()
            this.page = 1
            this.getList()
          })
        }
      })
    },
    /** 将打开文件夹 */
    openFolderAction(type, val) {
      this.headerSearch = false
      if (type === 'tab') this.openFolderId = val.nd_id
      else if (type === 'id') this.openFolderId = val[0]
      if (this.search) {
        // 清空搜索框值
        this.search = ''
        this.$myState.headerSearchWords = ''
      }
      //  else {
      this.page = 1
      this.getList()
      // }
    },
    /** 查看上一级文件夹 */
    parentsFolderAction() {
      const len = this.allFolderObj.length
      if (len > 1) {
        const arr = this.allFolderObj[len - 2]
        this.openFolderId = arr[0]
        this.page = 1
        this.getList()
      }
    },
    /** 分享弹窗 */
    shareDialogClick() {
      if (!this.share_expiration.toString()) {
        return this.$message({
          type: 'error',
          message: '请选择有效期',
        })
      } else {
        const nd_id_list = []
        let share_name = this.shareData[0].nd_name
        if (this.shareData.length > 1) share_name = share_name + '...'
        this.shareData.forEach(item => {
          nd_id_list.push(item.nd_id)
        })
        const req = {
          'module': 'netdisk',
          'require': {
            action: 'share',
            data: {
              nd_id_list: nd_id_list.toString(),
              share_name: share_name,
              share_expiration: this.share_expiration,
            },
          },
        }
        this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
          this.shareDialog = false
          this.shareLinkDialog = true
          this.share_link = res.result.share_link
          this.share_password = res.result.share_code
        })
      }
    },
    /** 删除 */
    delRow(type, row, index) {
      let nd_id_list = []
      if (type === 'all') {
        if (this.multipleSelection.length < 1) {
          return this.$message({
            type: 'error',
            message: this.$t('message.netdisk.err4'),
          })
        } else {
          this.multipleSelection.forEach(e => {
            nd_id_list.push(e.nd_id)
          })
        }
      }
      nd_id_list = nd_id_list.toString()
      this.$confirm(this.info1, this.prompt, {
        confirmButtonText: this.ok,
        cancelButtonText: this.cancel,
        type: 'warning',
      }).then(() => {
        const req = {
          'module': 'netdisk',
          'require': {
            action: 'remove',
            data: {
              nd_id_list: type === 'one' ? row.nd_id : nd_id_list,
              is_mail_attachment: 0, // 0 网盘 1文件中转站
            },
          },
        }
        this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
          this.$message.success(this.$t('message.netdisk.delsuccess'))
          this.getList()
          // 更新进度条
          this.getQuota()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('message.netdisk.canceldel'),
        })
      })
    },
    // 文件类型
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    getFileTypeShare(name) {
      const i = name.indexOf('...')
      const n = i < 0 ? name : name.slice(0, i)
      const type = GetFileType.fileType(n)
      return type
    },
    // 列表选择值
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    /** 分享列表选择值 */
    handleShareChange(val) {
      this.multipleShare = val
    },
    // 文件中转站、网盘下载
    downAtta() {
      if (this.multipleSelection.length < 1) {
        this.$message.error(this.$t('message.netdisk.err5'))
        return false
      }
      let name = this.multipleSelection[0].nd_name
      const index = name.lastIndexOf('.')
      name = name.substring(0, index)
      // 打包下载
      const nd_id_list = this.rows()
      this.downAttaReq = {
        nd_id_list: nd_id_list,
        is_mail_attachment: 0,
      }
      this.downZIP('all', nd_id_list)
    },
    oneDown(el) {
      if (el.nd_is_dir.toString() === '1') {
        let name = el.nd_name
        const index = name.lastIndexOf('.')
        name = name.substring(0, index)
        // 打包下载
        this.downAttaReq = {
          nd_id_list: el.nd_id,
          is_mail_attachment: 0,
        }
        this.downZIP('one', el.nd_id)
      } else {
        // 单个下载
        this.downAttaReq = {
          nd_id: el.nd_id,
          is_mail_attachment: 0,
        }
        const req = {
          'module': 'netdisk',
          ...this.downAttaReq,
        }
        const url = '/nsmail/mail.php/fn.dat?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
        window.open(url)
      }
    },
    // 打包下载前查询文件大小是否超过限制
    downZIP(type, nd_id_list) {
      console.log(type)
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getFileSize',
          data: {
            'nd_id_list': nd_id_list,
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        const file_size = res.result.file_size
        const num = 1024
        const m = (file_size / Math.pow(num, 2)).toFixed(2)
        if (m > 200) {
          console.log('大于200M')
          if (type === 'all' && this.multipleSelection.length === 1 && this.multipleSelection[0].nd_is_dir.toString() !== '1') this.oneDown(this.multipleSelection[0])
          else return this.$message.error(this.$t('message.netdisk.err7'))
        } else {
          const req = {
            'module': 'netdisk',
            ...this.downAttaReq,
          }
          const url = '/nsmail/mail.php/fn.dat?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
          window.open(url)
        }
      })
    },
    // 获取列表
    getList() {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getList',
          data: {
            words: this.search,
            limit: this.page_size,
            page: this.page,
            is_mail_attachment: 0, // 0 网盘 1文件中转站
            parent_nd_id: this.search ? '' : this.openFolderId, // search有值为搜索结果，不需要传parent_nd_id
            orderby: this.orderby,
            sequence: this.sequence,
            simpleMode: 0,
          },
        },
      }
      if (this.Type !== 'all' && this.Type !== 'share') {
        req.require.data.nd_type = this.Type
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        this.total = res.result.total
        this.tableData = res.result.list
        let parents = []
        if (Object.prototype.hasOwnProperty.call(res.result, 'parents')) parents = res.result.parents
        if (this.tableData.length === 0 && this.total > 0 && this.page > 1) {
          this.page = this.page - 1
          this.getList()
        } else {
          if (this.Type !== 'all' && this.Type !== 'share') {
            this.allFolderObj = [['top', '我的网盘']]
          } else {
            if (!this.search) {
              if (JSON.stringify(parents) === '[]') {
                this.allFolderObj = [['top', '我的网盘']]
              } else {
                this.allFolderObj = [['top', '我的网盘']]
                this.allFolderObj = this.allFolderObj.concat(parents)
              }
            }
          }
          this.$store.commit('network/Set_allFolderObj', this.allFolderObj)
        }
      })
    },
    rows() {
      const nums = []
      this.multipleSelection.forEach(el => {
        nums.push(el.nd_id)
      })
      return nums.toString()
    },
    // 重命名
    renameNew(row) {
      this.dialogRename = true
      this.row = row
      this.form.new_nd_name = row.nd_name
    },
    submitRenameNew(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.new_nd_name === this.row.nd_name) {
            this.dialogRename = false
            return false
          }
          const req = {
            'module': 'netdisk',
            'require': {
              action: 'rename',
              data: {
                nd_id: this.row.nd_id,
                is_mail_attachment: 0, // 0 网盘 1文件中转站
                new_nd_name: this.form.new_nd_name,
              },
            },
          }
          this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
            this.dialogRename = false
            this.row.nd_name = this.form.new_nd_name
            this.getList()
            this.$message.success(this.$t('message.netdisk.resuccess'))
          })
        }
      })
    },
    // 翻页
    handleCurrentChange(val) {
      this.page = `${val}`
      this.getList()
    },
    handleCurrentChangeShare(val) {
      this.pageShare = `${val}`
      this.getTableShareData()
    },
    // 排序事件
    sortTabl(column) {
      this.orderby = !column.order ? 'nd_is_dir' : column.prop
      this.sequence = column.order === 'ascending' ? 'ASC' : 'DESC'
      this.page = 1
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
@import './../common.scss';
.nav_foot{
    width: 240px;
    box-sizing: border-box;
    padding-left: 19px;
    position: absolute;
    bottom: 33px;
    left: 0px;
    background: #fff;
    p{
      display: flex;
      justify-content: space-between;
    }
  }
</style>
<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
@import '@/styles/tbNoBorder.scss';
.netdisk-btn{
  padding: 7px 10px;
}
.del{
  margin-left: 16px!important;
}
.Opea-svg-select-noBorder:not(:first-child){
  margin: 0 12px;
}
/deep/.el-table--small .el-table__cell{
  padding: 10px 0;
}
/deep/.moveFolder{
  .el-dialog__body{
    padding: 10px 20px;
    color:#1d1b1b;
  }
}
/deep/.shareclass{
  .el-dialog__body{
    padding: 10px 20px;
    color: #606266;
  }
}
/deep/.el-tree-node__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.netdiskicon{
  width: 1.2em!important;
  height: 1.2em!important;
}
.attachment_box {
  width: 100%;
  height: 100%;
  padding: 20px;
  .multipleTable-class{
     .el-table__row{
      &:hover .svgIcon{
          display: block;  // 设置父元素hover时子元素的样式 【实现要点！！！！！】
      }
    }
  }
 .multipleTable-share-class{
  .el-table__row{
      &:hover .button-span{
          display: block;  // 设置父元素hover时子元素的样式 【实现要点！！！！！】
      }
    }
 }
  .button-span{
    margin: 0 10px;
    float: left;
    cursor: pointer;
    display: none;
  }

  .tooltip{

    margin: 0 10px;
    float: left;
    width: 1.2em;
    height: 1.2em;
  }
  .svgIcon{
    display: none;
    width: 1.2em;
    height: 1.2em;
  }

  // .table{
  //   margin-top: 8px;
  // }
  .wtbox /deep/.el-input--small .el-input__inner {
    padding-right: 80px;
  }
  .wtbox {
    position: relative;
  }
  .wt_nums {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
}
.share-dialog{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: left;
  .share-dialog-name{
    height: 30px;
    line-height: 30px;
    background: #F6F6F6;
    border-radius: 1px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
}
.networkcontent-action {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  margin-top: 15px;
  padding-left: 20px;
  background: #f3f3f3;
}
.search-data-label{
  // width: 72px;
  height: 18px;
  font-size: 128%;
  font-weight: bold;
  margin-right:10px;
}
.rename /deep/ .el-dialog__body{
  padding: 10px 20px 0 20px;
}
</style>
