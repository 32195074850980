<template>
  <div>
    <el-dialog
      :title="$t('message.mainpage.authorized')"
      :visible.sync="dialogTableVisible"
      :modal-append-to-body="false"
      width="800px"
    >
      <el-table :data="gridData">
        <el-table-column
          property="mail"
          :label="$t('message.calendar.cal40')"
          min-width="330px"
        />
        <el-table-column
          property="gd_path_name"
          :label="$t('message.mailmodule.depart')"
          min-width="330px"
        />
        <el-table-column
          property="address"
          width="100"
          :label="$t('message.setting.clientoperate')"
        >
          <template slot-scope="scope">
            <el-button @click="changUser(scope.row)">{{ $t('message.mailmodule.toggle') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

import serialize from '@/utils/serialize'
import { loginInit, sqVuexClear } from '@/utils/InitializePage'
export default {
  data() {
    return {
      dialogTableVisible: false,
      gridData: [],
    }
  },
  computed: {
    /** 监听授权列表 */
    isAuthWatch() {
      return this.$store.state.app.auth_info.userOfficeMail
    },
  },
  watch: {
    isAuthWatch(newVal, oldVal) {
      if (JSON.stringify(this.$store.state.app.auth_info) !== '{}') {
        // 因为被授权人返回userOfficeMail:{orig:{}}与列表格式不符合table报错，所以添加判断(或者使用curr_auth_mail判断是否授权，来决定赋值方式)
        if (Array.isArray(newVal)) this.gridData = newVal
        else this.gridData = []
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (Array.isArray(this.$store.state.app.auth_info.userOfficeMail)) this.gridData = this.$store.state.app.auth_info.userOfficeMail
      else this.gridData = []
    })
  },
  methods: {
    changUser(el) {
      // const _this = this
      const loading = this.$loading()
      const data = {
        'module': 'mail',
        'require': {
          action: 'jump_authority_mail',
          official_email_id: el.official_email_id,
          mail: el.mail,
          is_orig: this.$store.state.app.auth_info.curr_auth_mail ? 'yes' : 'no',
        },

      }
      this.$axios.post(this.$apiData.mail, { req: serialize(data) }).then(res => {
        // 取消授权邮箱权限
        // if (res.status === 50040) {
        //   loading.close()
        //   this.$message({
        //     message: res.msg,
        //     type: 'error',
        //   })
        //   return false
        // }
        // this.$store.commit('tagsView/MAIL_VUEX_LS_CLEAR') /** 切换用户，删除所有数据，包括localStorage */
        if (res.status === 200) {
          sqVuexClear() // 清空vuex会话缓存
          this.dialogTableVisible = false
          // 切换授权邮箱跳转页面   重新请求用户配置信息
          loading.close()
          if (res) {
            this.$myApi.topGotoByRedirect('mail')
            return
          }
          loginInit('auth')
        // 切换邮箱后刷新页面换皮肤
        } else {
          loading.close()
          this.$message({
            message: res.msg,
            type: 'error',
          })
          return false
        }
      }).catch(() => {
        console.log('错误')
        loading.close()
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
