<template>
  <el-dialog
    :title="$t('message.contact.newgroup')"
    :visible.sync="dialogVisible"
    :append-to-body="false"
    :modal-append-to-body="false"
    width="440px"
    custom-class="addDialog"
    close-on-click-modal
    @closed="handleClose"
  >
    <el-form
      ref="numberValidateForm"
      style="margin-bottom:24px"
      :model="form"
      label-width="100px"
      class="group"
      :rules="rules"
      label-position="top"
    >
      <el-form-item
        prop="group"
      >
        <span slot="label" />
        <el-input
          v-model="form.group"
          autocomplete="off"
          :placeholder="$t('message.contact.placeholder1')"
          show-word-limit
          maxlength="25"
        />
        <!-- 隐藏的输入框作用于防止ie11回车刷新事件 -->
        <input
          id="hiddenText"
          type="text"
          style="display:none"
        >
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">{{ $t('message.btnText.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="submitForm('numberValidateForm')"
      >{{ $t('message.btnText.ok') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import serialize from '@/utils/serialize'
export default {
  name: 'CreateGroup',
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('message.contact.info7')))
      } else if (!/^[^;'"<>\\/\\%&...￥]+$/.test(value)) {
        callback(this.$t('message.contact.info8'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {
        group: '',
      },
      rules: {
        group: [
          { validator: checkAge, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    showGroupdialog() {
      this.dialogVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            module: 'contact',
            require: {
              action: 'updateGroup',
              data: {
                contact_list: [],
                group_name: this.form.group,
              },
              group_id: 'new',
            },
          }
          this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
            if (result.status === 200) {
              this.$message.success(this.$t('message.contact.info10'))
              this.dialogVisible = false
              this.$emit('updateGroup', result.result.group_id)
              this.$store.commit('contact/SETTING_UPDATE_STATUS')
            }
          })
        }
      })
    },
    handleClose() {
      this.form.group = ''
      this.resetForm('numberValidateForm')
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },

}
</script>

<style lang="scss" scoped>
/deep/.addDialog.el-dialog {
    .el-dialog__header{
        padding-bottom: 0px;
    }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body{
    padding: 0 20px;
    cursor: default;
  }
}
.group{
    /deep/.el-form-item{
    margin-bottom: 8px!important;
}
}
</style>
