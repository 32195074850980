<template>
  <!-- 邮件审核页面 -->
  <div
    id="view_warp"
    ref="view_warp"
    v-loading="loading"
    :element-loading-text="$t('message.view.loading')"
    class="view_warp"
  >
    <div
      v-if="!audit_expired"
      id="mailboxAll"
      class="mailboxAll"
    >
      <div
        v-if="!loading"
        class="view_top"
      >
        <p class="subject">
          <span
            class="txt"
          >{{ bodyHtml.subject ? bodyHtml.subject : $t('message.view.no_subject') }}</span>
          <span class="clean" />
        </p>
        <p class="time">{{ bodyHtml.from_name_l ? showName(bodyHtml.from_name_l) :'' }} {{ dateFilter(bodyHtml.envelope_date) }}
          <svg-icon
            v-show="bodyHtml.is_strange ==='1' && bodyHtml.is_suspicious ==='0' "
            :icon-class="langicon()"
            class="middle kym"
          />
          <span
            v-show="bodyHtml.is_suspicious ==='1'"
            class="formtips"
          ><svg-icon
            icon-class="ky"
            class="middle kym"
          />({{ $t('message.view.replace_send',{mail:bodyHtml.x_sender}) }})</span>
        </p>
        <div class="peoList">
          <span
            v-if="isUnfold"
            class="zs"
            @click="Unfold(true)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.expand') }}</span>
          <span
            v-else
            class="zs"
            @click="Unfold(false)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.close') }}</span>
          <p
            v-if="isUnfold"
            class="to_s one-line-ellipsis"
          >
            <em>{{ $t('message.view.send_to') }}: </em>
            <span
              v-for="(i,index) in bodyHtml.to_s"
              :key="index"
              class="cur"
            >
              <mailContactCardPopovrer
                :contact-name="i[1]"
                :contact-mail="i[0]"
                :contact-group="i[2]"
                :text-content="showName(i)"
              />
              <span v-show="to_s && to_s.length-1 !== index "> , </span>
            </span><span v-show="to_s.length > 1"> &nbsp;{{ $t('message.view.num_person',{num: to_s.length}) }}</span>
          </p>
          <div
            v-show="!isUnfold"
            class="to_s"
          >
            <em>{{ $t('message.view.to') }}: </em>
            <!-- <p class="tosList">
            <span
              v-for="(i,index) in to_s"
              :key="index"
            >{{ i[1]+'<'+i[0]+'>' }}<span v-show="to_s && to_s.length-1 !== index "> , </span></span>
          </p> -->
            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in to_s"
                :key="index"
              >
                <mailContactCardPopovrer
                  :contact-name="i[1]"
                  :contact-mail="i[0]"
                  :contact-group="i[2]"
                  :text-content="showName(i)"
                />
                <span v-show="to_s && to_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <div
            v-show="!isUnfold && cc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.cc') }}: </em>
            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in cc_s"
                :key="index"
              >
                <mailContactCardPopovrer
                  :contact-name="i[1]"
                  :contact-mail="i[0]"
                  :contact-group="i[2]"
                  :text-content="showName(i)"
                />
                <span v-show="cc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <!-- 需要判断是否在发件箱 -->

          <div
            v-show="!isUnfold && bcc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.bcc') }}: </em>
            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in bcc_s"
                :key="index"
              >
                <mailContactCardPopovrer
                  :contact-name="i[1]"
                  :contact-mail="i[0]"
                  :contact-group="i[2]"
                  :text-content="showName(i)"
                />
                <span v-show="bcc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
        </div>
        <p v-if="attachments && attachments.length">

          <em>{{ $t('message.view.att') }}: </em>
          <span>
            <svg-icon
              class="attaClass middle"
              :icon-class="getFileType(attachments[0].name)+'icon'"
              class-name="atticon"
            /> {{ $t('message.view.att') }}{{ $t('message.view.have_att',{num:attachments.length}) }}({{ attachments[0].name }}<em v-show="attachments.length >1">{{ $t('message.view.etc') }}</em>)</span>
          <span
            class="lookAtta audit-color cur"
            @click="scrollAtta"
          > {{ $t('message.view.view_att') }}</span>
        </p>

      </div>

      <!-- 会议信息卡 -->
      <calendarCard
        v-if="isMettingMail"
        :card-info="MettingCardData"
      />
      <!-- 邮件内容 -->
      <div
        class="view_center"
      >
        <div
          ref="mailbody"
          class="view_html"
        >

          <iframe
            ref="iframe"
            src=""
            frameborder="0"
            width="100%"
            height="500"
            scrolling="auto"
          />
        </div>
        <!-- <div
          ref="mailbody"
          class="view_html"
          v-html="bodys"
        /> -->
        <img
          v-for="(i,index) in attaImg"
          :key="index"
          class="imgpadding"
          :src="i"
        >
        <!-- 附件区 -->
        <div
          v-show="attachments && attachments.length"
          id="view_atta"
          ref="view_atta_box"
          class="view_atta"
        >
          <div class="atta_tit">{{ $t('message.view.att') }}(<span>{{ $t('message.view.have_att',{num:attachments.length}) }}, {{ $t('message.view.total') }}{{ bodyHtml.att_size | mailSizefilter }}</span>)
            <a
              v-if=" isShowDownBtn && bodyHtml.undownload !== '1'"
              target="_blank"
              class="audit-color cur"
              :href="auditDownAtta('download','allDown')"
            >({{ $t('message.view.pack_download') }})</a>
          </div>
          <div class="atta_html">
            <div
              v-for="(i,index) in attachments"
              :key="index"
              class="mv-att-item"
            >
              <div
                cs2c_id="icon"
                class="big-icon icon-att4-file attaLeft"
              >
                <svg-icon
                  class="attaClass"
                  :icon-class="getFileType(i.name)+'icon'"
                  class-name="atticon"
                />
              </div>
              <div class="attaRight">
                <div class="attaR_top">
                  <el-tooltip
                    class="item"
                    effect="light"
                    placement="top-start"
                  >
                    <div
                      slot="content"
                      style="max-width:500px"
                    >{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</div>
                    <span
                      class="atta_name"
                    >{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</span>
                  </el-tooltip>&nbsp;<span>(</span><span class="atta_size">{{ i.size | mailSizefilter }}</span><span>)</span>
                </div>
                <div
                  class="attaR_bot"
                >
                  <!-- 审核预览 -->
                  <a
                    v-if="isShowDownBtn && isPreView(i.name)"
                    class="preview audit-color"
                    target="_blank"
                    :href="auditDownAtta('preview',i)"
                  >{{ $t('message.view.priview') }} </a>
                  <!-- 审核下载 -->
                  <a
                    v-if="isShowDownBtn && bodyHtml.undownload != '1'"
                    class="preview audit-color"
                    target="_blank"
                    :href="auditDownAtta('download',i)"
                  >{{ $t('message.view.download') }} </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 过期邮件 -->
    <div
      v-else
      class="mailboxAll"
    >
      <svg-icon
        class="audit_expired"
        icon-class="audit_expired"
      />
      <p class="audit_expired_html">{{ audit_expired_html }}</p>

    </div>

    <!-- 邮件审核 -->
    <auditMail
      :is-show="!audit_expired"
      :audit-mail-info="auditMailInfo"
    />

    <!-- 消息提示 -->
    <el-dialog
      :title="$t('message.btnText.info')"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="30%"
    >
      <span>{{ dialogMsg }}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="isKmd"
          @click="dialogVisible = false"
        >{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="closeDialog"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <!-- 下载附件 -->
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />

    <el-dialog
      title=""
      :visible.sync="dialogVisibleContact"
      width="498px"
      append-to-body
      :show-close="false"
      class="cutomDialog"
    >
      <NewContact
        v-if="dialogVisibleContact"
        :on-closed="addUserClosed"
        :on-submit="addUserSubmit"
        :form="ruleForm"
        :cid="contact_id"
        :is-edit="idEdit"
        :title="addtitle"
        is-card
      />
    </el-dialog>
    <!-- 审核邮件发送验证码 -->
    <el-dialog
      :title="''"
      :visible.sync="sendAuditVisible"
      :modal-append-to-body="false"
      width="660px"
      :show-close="false"
      :before-close="handleClose"
    >
      <p style="margin-top: 20px">{{ auditTxt }}</p>
      <p class="yzmInpuy">
        <el-input
          v-model="yzmAuditInpuy"
          maxlength="6"
          class="input"
          oninput="value=value.replace(/^\+|[^\d]/g,'')"
        /><span
          class="cur"
          @click="sendAuditCodeFun"
        >{{ $t('message.view.send_msg_code') }} </span>
      </p>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="medium"
          @click="submitFrom()"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import view from './components/view.js'
import auditMail from './components/auditMail.vue'
import downAttaForm from '../components/downAtta.vue'
import mailContactCardPopovrer from '@/components/mailmodule/mail-contactCard-popover.vue'
import NewContact from '@/views/contacts/components/NewContact'
import calendarCard from './components/calendarCard.vue'
export default {
  components: {
    auditMail,
    downAttaForm,
    mailContactCardPopovrer,
    NewContact,
    calendarCard,
  },
  mixins: [view],
  methods: {
    goBack() {
      this.$emit('goBackList')
    },
    langicon() {
      const lang = localStorage.getItem('lang')
      if (lang === 'english') {
        return 'mo_en'
      } else {
        return 'ms'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./common.scss";
</style>
