<template>
  <div
    class="home-view-header"
  >
    <div
      class="home-view-header-img"
      :style="{background:'url('+skin_headerData[$myState.userConfig.skin_colour]+')' }"
    />
    <div
      class="header-web-title hearder_theme_bg one-line-ellipsis"
    >
      <img
        :src="$myState.user_permissions.web_logo"
        alt="logo"
        style="width: 340px;margin-left: 10px;"
      >
      <!-- <span style="margin-left: 14px">{{ $store.state.app.web_title }} </span> -->
    </div>
    <div class="header-right">
      <div
        style="display: flex;
        flex-direction: row-reverse;
        margin-left: 25px;
        "
        class="hearder_theme_bg"
      >
        <div class="hearder-mail-opea">
          <div class="width50">
            <div class="user-abbr user-abbr-theme">
              <span class="user-abbr-span">{{ $store.state.app.user_mail_address.slice(0, 1) }}</span>
            </div>
          </div>
          <div class="hearder-mail one-line-ellipsis">{{ $store.state.app.user_mail_address }}</div>
          <el-select
            v-model="input"
            style="width: 100%; position: absolute; left: 0"
          >
            <p
              v-for="(item, index) in opeaOption"
              :key="index"
            >
              <el-option
                v-if="item.type !== 'Auth'"
                :value="item.value"
                class="flax-align-items-center"
                @click.native="optionClick(item.value)"
              >
                <svg-icon
                  class="icos-size-14"
                  style="width:1.1em;height: 1.1em"
                  :icon-class="item.icon"
                />
                <span style="font-weight: 400; color: #1d1b1b; margin-left: 8px">{{ $t(`message.mainpage.${item.lable}`) }}</span>
              </el-option>
            </p>
          </el-select>
        </div>
        <div
          v-for="(item, index) in headerData"
          :key="index"
          style="display: flex;align-items: center;"
        >
          <!-- v-if="item.type !== 'Auth'" -->
          <div
            v-if="headerDataFilter(item)"
            :style="index === headerData.length - 1 ? 'margin-left: 10px;' : ''"
            class="width50 header-opea"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="$t(`message.mainpage.${item.lable}`)"
              placement="bottom"
            >
              <span
                style="display: flex;"
              >

                <svg-icon
                  class="svg-header-style svg-theme-color"
                  :icon-class="item.icon"
                  @click="linkFn(item.path)"
                />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- 简单搜索 -->
      <div
        v-if="isSearchIcon"
        style="width: 25px;"
      >
        <svg-icon
          icon-class="search"
          style="position: relative;top:5px;"
          @click="searchInputShowClick"
        />
      </div>
      <div
        v-if="isSearchInput"
        class="global-search-div"
      >
        <el-autocomplete
          ref="searchBtn"
          v-model="$myState.headerSearchWords"
          popper-class="el-autocomplete-suggestion"
          :fetch-suggestions="querySearchAsync"
          :debounce="BounceTime"
          :placeholder="$t('message.mainpage.placeholder')"
          style="width: 35vw;height: 50px;display: flex;align-items:center;min-width: 230px;"
          clearable
          @select="handleSelect"
          @clear="handleSelectClear"
          @keydown.enter.native="
            handleSelect({ scopes: 'subject', value: 'subject' })
          "
        >
          <el-select
            slot="prepend"
            v-model="$myState.headerSearchType"
            style="width: 94px;text-align: center;"
            @change="searchSelectChange"
          >
            <el-option
              v-if="$store.state.app.user_permissions.mailbox === 1"
              :label="$t('message.mainpage.mail')"
              value="mail"
            />
            <el-option
              v-if="$store.state.app.auth_info.curr_auth_mail ? $store.state.app.auth_authority === 2 : true"
              :label="$t('message.mainpage.addressbook')"
              value="contact"
            />
            <el-option
              v-if="$store.state.app.auth_info.curr_auth_mail ? ($store.state.app.auth_authority === 2 && $store.state.app.user_permissions.netdisk === 1) : $store.state.app.user_permissions.netdisk === 1"
              :label="$t('message.mainpage.netdisk')"
              value="netdisk"
            />
          </el-select>
          <template slot-scope="{ item }">
            <div v-if="item.scopes !== 'highsearch'">
              {{ $t(`message.compose.${item.value}`) }}：
              <!-- <span v-if="queryString">包含“</span> -->
              <span class="text-color">{{ $myState.headerSearchWords }}</span>
              <!-- <span v-if="queryString">”的邮件</span> -->
            </div>
            <div
              v-if="item.scopes === 'highsearch'"
              class="text-color"
            >
              {{ $t('message.mainpage.advanced') }}>>
            </div>
          </template>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSelect({ scopes: 'subject', value: '主题' })"
          />
        </el-autocomplete>
      </div>
    </div>
  </div>
</template>
<script>
import { loginOut, loginInit, sqVuexClear } from '@/utils/InitializePage'
// import { delCookie } from '@/utils/cookie'
import serialize from '@/utils/serialize'
// const path = require('path')
// import { asynLoad } from '@/utils/utils'
// import themeSet from '@/theme/theme-set.js'
export default {
  components: {},
  // mixins: [themeSet],
  inject: ['refreshFn', 'reload', 'msg'],
  data() {
    return {
      BounceTime: 0,
      input: '',
      headerData: [],
      opeaOption: [],
      queryString: '',
      url: '',
      skin_headerData: {
        'skin01': require('@/assets/theme/skin01_header.jpg'),
        'skin02': require('@/assets/theme/skin02_header.jpg'),
        'skin03': require('@/assets/theme/skin03_header.jpg'),
        'skin04': require('@/assets/theme/skin04_header.jpg'),
        'skin05': require('@/assets/theme/skin05_header.jpg'),
        'skin06': require('@/assets/theme/skin06_header.jpg'),
        'skin07': require('@/assets/theme/skin07_header.jpg'),
        'skin08': require('@/assets/theme/skin08_header.jpg'),
        'skin09': require('@/assets/theme/skin09_header.jpg'),
        'skin10': require('@/assets/theme/skin10_header.jpg'),
        'skin11': require('@/assets/theme/skin11_header.jpg'),
        'skin12': require('@/assets/theme/skin12_header.jpg'),
        'skin13': require('@/assets/theme/skin13_header.jpg'),
        'skin14': require('@/assets/theme/skin14_header.jpg'),
        'skin15': require('@/assets/theme/skin15_header.jpg'),
        'skin16': require('@/assets/theme/skin16_header.jpg'),
      },
      isSearchIcon: false,
      isSearchInput: true,
    }
  },
  computed: {
    /** 监听是否清空网盘关键字搜索 */
    // isSearchWordsClearWatch() {
    //   return this.$store.state.network.search_words_clear
    // },
    /** 监听是否切换了授权 */
    isCurrAuthWatch() {
      return this.$store.state.app.auth_info.curr_auth_mail
    },
    /** 监听路由变化是否需要修改搜索下拉框默认值 */
    // selectOptionWatch() {
    //   return this.$route.path
    // },
  },
  watch: {
    isCurrAuthWatch(newVal, oldVal) {
      if (JSON.stringify(this.$store.state.app.auth_info) !== '{}') this.refresh()
    },
    // 无路由，重新传参
    // selectOptionWatch(newVal, oldVal) {
    //   if (newVal.indexOf('networkcontent') > -1) {
    //     const show = this.$store.state.app.auth_info.curr_auth_mail ? (this.$store.state.app.auth_authority === 2 && this.$store.state.app.user_permissions.netdisk === 1) : this.$store.state.app.user_permissions.netdisk === 1
    //     if (show) this.$myState.headerSearchType = 'netdisk'
    //   } else if (newVal.indexOf('contacts-work') > -1 || newVal.indexOf('contact') > -1 || newVal.indexOf('searchGlobalContact') > -1) {
    //     const show = this.$store.state.app.auth_info.curr_auth_mail ? this.$store.state.app.auth_authority === 2 : true
    //     if (show) this.$myState.headerSearchType = 'contact'
    //   } else {
    //     if (this.$store.state.app.user_permissions.mailbox === 1) {
    //       this.$myState.headerSearchType = 'mail'
    //     } else {
    //       this.$myState.headerSearchType = ''
    //     }
    //   }
    // },
  },
  created() {
    // this.$myState.headerSearchType = this.$myState.user_permissions.mailbox === 1 ? 'mail' : ''
  },
  mounted() {
    if (window.innerWidth < 700) {
      this.isSearchIcon = true
      this.isSearchInput = false
    } else {
      this.isSearchIcon = false
      this.isSearchInput = true
    }
    window.onresize = () => {
      return (() => {
        console.log(window.innerWidth)
        if (window.innerWidth < 700) {
          this.isSearchIcon = true
          this.isSearchInput = false
        } else {
          this.isSearchIcon = false
          this.isSearchInput = true
        }
      })()
    }
  },
  methods: {
    loadOnce() {
      this.$nextTick(() => {
        this.BaseInfoDataSet()
        // this.get_login_setting()
      })
      const skin_colour = this.$myState.userConfig.skin_colour
      var head = document.getElementsByTagName('head')[0]
      var linkSkin1 = document.createElement('link')
      var linkSkin2 = document.createElement('link')
      linkSkin1.rel = 'stylesheet'
      linkSkin2.rel = 'stylesheet'
      linkSkin1.id = 'linkSkin1'
      linkSkin2.id = 'linkSkin2'
      // console.log(require.resolve(`/theme/${skin_colour}/theme.css`))
      console.log(process.env.NODE_ENV)
      linkSkin1.href = `/nsmail/theme/${skin_colour}/theme.css`
      linkSkin2.href = `/nsmail/theme/${skin_colour}/style/index.css`
      head.appendChild(linkSkin1)
      head.appendChild(linkSkin2)
      // import(`@/theme/${skin_colour}/theme.scss`)
      // import(`@/theme/${skin_colour}/style/index.css`)
    },
    setScssFile() {
      // const skin_colour = this.$store.state.app.userGetConfig.skin_colour
      // const link = document.createElement('link')
      // link.type = 'text/css'
      // link.id = 'theme' + skin_colour
      // link.rel = 'stylesheet'
      // link.href = '../../../theme/skin13/style/index.css'
      // document.getElementsByTagName('head')[0].appendChild(link)
      // console.log(skin_colour, 'skin_colour')
      // document.getElementsByTagName('body')[0].setAttribute('class', `app-view-class14 ${this.$store.state.app.userGetConfig.skin_colour}`)
    },
    refresh() {
      this.BaseInfoDataSet()
      this.get_login_setting()
      this.reload
    },
    headerDataFilter(item) {
      let flag = false
      if (item.type !== 'Auth') {
        if (item.icon === 'lockscreen') flag = this.$store.state.app.isLockShow
        else if (item.icon === 'set' && this.$store.state.app.auth_info.curr_auth_mail) flag = false
        else flag = true
      }
      return flag
    },
    // 获取右上角用户数据
    BaseInfoDataSet() {
      this.opeaOption = [
        //   {
        //   value: 'user',
        //   lable: '账号概况',
        //   icon: 'user_over',
        //   type: 'noAuth',
        // },
        {
          value: 'password',
          lable: 'changepassword',
          icon: 'password_lock',
          type: 'noAuth',
        },
        {
          value: 'logout',
          lable: 'logginout',
          icon: 'logout',
          type: 'noAuth',
        },
      ]
      this.headerData = [{
        lable: 'help',
        icon: 'help',
        path: 'help',
        type: 'noAuth',
        isShow: 0,
      },
      {
        lable: 'lock',
        icon: 'lockscreen',
        path: '/lock-screen',
        type: 'noAuth',
        isShow: 0,
      },
      {
        lable: 'setting',
        icon: 'set',
        path: '/set-work',
        type: 'noAuth',
        isShow: 0,
      }]

      if (this.$store.state.app.isFirstLlogin !== 1 && this.$store.state.app.settingInfo.weakPassowrd === 'yes') {
        // 是否弹出修改密码
        // this.$parent.$refs.weakPassowrd.dialogWeakPassword = true
        this.$parent.isWeakPassowrd = true
      }
      // 以下是做一些基本设置
      let data = {}
      // 如果是本人登录 并且 授权列表不为空 则出现切换授权邮箱按钮
      // 如果不是本人登录 则currauthtype代表授权权限
      if (this.$store.state.app.auth_info.curr_auth_mail) {
        data = {
          value: 'authorizedMy',
          lable: 'authorizedmy',
          icon: 'user_authorized',
          type: 'noAuth',
        }
        if (this.opeaOption.indexOf(data) === -1) {
          this.opeaOption.unshift(data)
        }
      } else if (
        !this.$store.state.app.auth_info.curr_auth_mail &&
        this.$store.state.app.auth_info.userOfficeMail &&
        this.$store.state.app.auth_info.userOfficeMail.length
      ) {
        data = {
          value: 'authorized',
          lable: 'authorized',
          icon: 'user_authorized',
          type: 'noAuth',
        }
        if (this.opeaOption.indexOf(data) === -1) {
          this.opeaOption.unshift(data)
        }
      }
      if (!this.$store.state.app.auth_info.curr_auth_mail) {
        this.$store.commit('app/AUTH_AUTHORITY', 9) // 根据后端返回值修改授权权限 0读信 1写信 2管理  9没有开启授权邮箱
      }
      if (this.$store.state.app.auth_authority !== 9) {
        // 授权权限
        this.headerData.forEach((el) => {
          el.icon === 'set' ? (el.type = 'Auth') : (el.type = 'noAuth')
        })
        this.opeaOption.forEach((el) => {
          el.value === 'password' ? (el.type = 'Auth') : (el.type = 'noAuth')
        })
      }
      if (this.$store.state.app.settingInfo.is_ent_admin && !this.$store.state.app.auth_info.curr_auth_mail) {
        const data = {
          lable: 'togglemannger',
          icon: 'user',
          path: '/nsmail_admin',
          type: 'noAuth',
        }
        this.headerData.push(data)
      }
      // 判断设置二级菜单（授权邮箱、客户端配置、邮箱搬家）的权限
      this.settingAuthority()
      // this.$store.state.app.auth_info.grantMail ? this.opeaOption.unshift(data) : ''
      // })
    },
    get_login_setting() {
      this.$axios.get(this.$apiData.get_login_setting).then(res => {
        if (res.status === 200) {
          const data = res.result
          /** 存储系统背景图、logo、版权信息，锁屏、header等使用 */
          this.$store.commit('app/LOGIN_SETINFO_SETTING', {
            login_background_url: data.login_background,
            login_logo: data.login_logo,
            copyright: data.copyright,
          })
        }
      })
    },
    // 切换管理员的链接
    changeAdminUrl() {
      const data = {
        'module': 'mail',
        'require': {
          'action': 'switch_role',
          pass: this.$store.state.app.userInfo.user_pwd,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(data) }).then(res => {
        if (res.result.url) {
          // delCookie('ci_session', null, -1) // 清除管理端的cookie
          // sessionVuexClear() // 清空vuex会话缓存
          location.href = location.origin + res.result.url
        }
      })
    },
    searchSelectChange() { },
    querySearchAsync(queryString, cb) {
      if (this.$myState.headerSearchType === 'mail') {
        const loadAll = [
          { scopes: 'subject', value: 'sub' },
          { scopes: 'body', value: 'body' },
          { scopes: 'from', value: 'from' },
          { scopes: 'to', value: 'to' },
          { scopes: 'attachment', value: 'attsname' },
          { scopes: 'highsearch' },
        ]
        this.queryString = queryString
        cb(loadAll)
      } else {
        cb([])
      }
    },
    handleSelectClear(item) {
      if (this.$myState.headerSearchType === 'netdisk') { // 网盘
        this.$myApi.netdiskGoto()
      }
      return false
    },
    handleSelect(item) {
      // 删除输入框内容时，item为undefined
      if (typeof item === 'undefined') {
        if (this.$myState.headerSearchType === 'mail') return false
        this.$myState.headerSearchWords = ''
      }
      if (this.$myState.headerSearchType === 'mail') {
        // 高级搜索,弹窗及具体时间在/home-view/index.vue中处理
        if (item.scopes === 'highsearch') {
          this.$emit('highSearchShow')
        } else {
          // 普通搜索
          this.$myState.headerSearchWords = this.queryString
          // 添加type列表类型search(搜索)
          this.$myApi.mainTab.showMailSearch({
            scopes: item.scopes,
            words: this.queryString,
            type: 'search',
            mbox: 'search',
            uid: 'search',
          })
        }
      } else if (this.$myState.headerSearchType === 'netdisk') { // 网盘
        if (!this.$myState.headerSearchWords) {
          this.$message({
            type: 'error',
            message: this.$t('message.mailmodule.search_type'),
          })
          return false
        } else {
          this.$myApi.netdiskGoto({ 'search': this.$myState.headerSearchWords })
        }
      } else if (this.$myState.headerSearchType === 'contact') { // 跳转通讯录
        if (!this.$myState.headerSearchWords) {
          this.$message({
            type: 'error',
            message: this.$t('message.mailmodule.search_type'),
          })
          return false
        }
        console.log('sss')
        this.$myApi.contactSearch(this.$myState.headerSearchWords)
      }
      this.$refs.searchBtn.activated = false
    },
    optionClick(val) {
      // 退出
      if (val === 'logout') {
        loginOut()
      } else if (val === 'password') {
        this.$myApi.settingGoto('blacklist-set')
      } else if (val === 'authorized') {
        this.$store.state.app.auth_info.curr_auth_mail
          ? this.$emit('changeDia', false)
          : this.$emit('changeDia', true)
        // this.$store.commit('tagsView/MAIL_VUEX_LS_CLEAR')
      } else if (val === 'authorizedMy') {
        // 返回我的邮箱
        const loading = this.$loading()

        const data = {
          'module': 'mail',
          'require': {
            action: 'jump_authority_mail',
            official_email_id: this.$store.state.app.auth_info.userOfficeMail.orig.official_email_id,
            mail: this.$store.state.app.auth_info.userOfficeMail.orig.mail,
            is_orig: this.$store.state.app.auth_info.curr_auth_mail ? 'yes' : 9,
          },

        }
        this.$axios.post(this.$apiData.mail, { req: serialize(data) }).then(res => {
          loading.close()
          this.$store.commit('tagsView/MAIL_VUEX_LS_CLEAR') /** 切换用户，删除所有数据，包括localStorage */

          sqVuexClear() // 清空vuex会话缓存
          if (res) {
            this.$myApi.topGotoByRedirect('mail')
            return
          }
          // 切换授权邮箱跳转页面   重新请求用户配置信息
          loginInit('authorizedMy')
          // 切换邮箱后刷新页面换皮肤
          // 如果为ie浏览器
          // if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          //   setTimeout(() => {
          //     this.$router.go(0)
          //   }, 3000)
          // } else {
          //   setTimeout(() => {
          //     this.$router.go(0)
          //   }, 2000)
          // }
        }).catch(() => {
          console.log('错误')
          loading.close()
        })

        // this.$store.commit('tagsView/MAIL_VUEX_LS_CLEAR')
      }
    },
    // loginAction() {
    //   const params = {
    //     'module': 'user',
    //     'require': {
    //       'action': 'getConfig',
    //     },
    //   }
    //   this.$axios.post(this.$apiData.mail, { req: serialize(params) })
    //     .then(res => {
    //       this.$store.commit('app/USER_GET_CONFIG_DATA__SETTING', res.result)
    //       this.$myApi.updateUserConfig(this.$myState.userConfig, res.result)
    //     })
    // },
    linkFn(path) {
      if (path === '/lock-screen') {
        this.$myApi.lockScreenSet(true)
      }
      if (path === 'help') {
        const origin = location.origin
        window.open(origin + '/nsmail/pub_source/用户手册.pdf')
      }
      if (path === '/nsmail_admin') {
        this.changeAdminUrl()
      }
      if (path === '/set-work') {
        this.$myApi.settingGoto()
      }
    },
    settingAuthority() {
      // 提醒设置本期不做
      const arr = ['remindersettings'] // 提醒设置
      if (this.$store.state.app.auth_info.grantMail !== 1) {
        arr.push('authorizedMail') // 授权管理
      }
      if (this.$store.state.app.open_client !== 1) {
        arr.push('clientConfiguration') // 客户端配置
      }
      if (this.$store.state.app.settingInfo.move_mail_type === 0) {
        arr.push('mailboxMoving') // 邮箱搬家
      }
      const setMenu = this.$myData.settingSetMenu[0].children.map(item => {
        arr.indexOf(item.path) === -1 ? item.show = true : item.show = false
        return item
      })
      if (this.$store.state.app.user_permissions.autoForward !== 1) {
        // 自动转发
        const setMenuTwo = this.$myData.settingSetMenu[1].children.map(item => {
          item.path === 'automaticforwarding' ? item.show = false : item.show = true
          return item
        })
        this.$myData.settingSetMenu[1].children = setMenuTwo
      }
      this.$myData.settingSetMenu[0].children = setMenu
    },
    searchInputShowClick() {
      console.log(this.isSearchInput)
      this.isSearchInput = !this.isSearchInput
    },
  },
}
</script>
<style lang="scss" scoped>
.home-view-header {
  width: 100%;
  height: 50px;
  border-radius: 0px 0px 0px 0px;
  // opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .home-view-header-img{
    width: 100%;
    height: 100%;
    position: absolute;
    // opacity: 0.1;
    top: 0;
    left: 0;
    z-index: -100;
    background: #fff;
  }
}

.header-web-title {
  width: 320px;
  height: 100%;
  min-width: 320px;
}

.header-right {
  width: calc(100vw - 240px);
  height: 100%;
  display: flex;
  flex-direction: row-reverse;

  .el-input {
    width: 283px;
  }

  .width50 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%;

    .svg-header-style {
      cursor: pointer;
    }
  }

  .header-opea {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .header-opea:hover {
    background-color: #d9d9d9;
    border-radius: 5px;
    width: 36px;
    height: 36px;
  }

  .user-abbr {
    width: 32px;
    height: 32px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    .user-abbr-span {
      font-weight: 500;
    }
  }

  .hearder-mail-opea {
    display: flex;
    margin-right: 20px;
    position: relative;

    /deep/ .el-input__inner {
      padding: 0 !important;
      opacity: 0 !important;
    }

    /deep/ .el-input__suffix {
      right: -5px !important;
    }

    /deep/.el-select-dropdown__item {
      font-weight: 400;
      color: #1d1b1b;
    }

    /deep/ .el-select__caret {
      color: #000;
      font-weight: bold !important;
    }
  }

  .hearder-mail {
    min-width: 50px;
    max-width: 182px;
    margin-right: 18px;
  }

  .global-search-div {
    // width: 560px;height: 50px;display: flex;align-items:center;
    /deep/.el-input-group__append {
      text-align: center;
      font-size: 15px;
      color: #a6a4a4;
    }

    /deep/.el-input__inner {
      padding: 0 12px 0;
    }

    /deep/.el-input__suffix {
      right: 1px !important;
    }
  }
}

/deep/ .el-input-group .el-input-group__prepend {
  background-color: #ffffff !important;
}

// /deep/ .el-input-group {
//   box-shadow: 0px 1px 4px 1px #dedddd;
// }
// 媒体查询 屏幕小于720px时  隐藏input
// @media (max-width: 720px) {
//   .global-search-div{
//     display: none;
//   }
// }
</style>
<style lang="scss">
.el-autocomplete-suggestion {
  width: auto !important;
}

</style>
