import Vue from 'vue'
import axios from 'axios'
// import router from '../router'
// import store from '@/store'
import qs from 'qs'
// import { MessageBox } from 'element-ui'
// 创建一个axios实例
const service = axios.create({
  baseURL: '/nsmail', // url = base url + request url
  // withCredentials: true, // 当跨域请求时发送cookie
  headers: { // 若单个接口需要修改headers值，在this.$axios第三个参数中修改
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
  timeout: 400000, // 请求超时
})
// 请求拦截
service.interceptors.request.use(
  config => {
    const ContentType = config.headers['Content-Type']
    if (ContentType !== 'multipart/form-data;') config.data = qs.stringify(config.data) // 非上传文件时
    return config
  },
  error => {
    // 处理请求错误
    console.log(error)
    return Promise.reject(error)
  },
)
let messageErrorOut = 0 // 标识错误退出返回登陆页,只提示一次
function response_format(r) {
  function _parseInt(a) {
    a = parseInt(a)
    if (isNaN(a)) return 0
    return a
  }
  var i, tmp, n, tp
  for (i in r) {
    if (i === 'uidvalidity') r.uv = r.uidvalidity
  }
  for (i in r) {
    tp = typeof (r[i])
    if (tp === 'object' || tp === 'array') {
      response_format(r[i])
    } else if (tp === 'string') {
      tmp = r[i]
      n = _parseInt(tmp)
      // if (n + '' === tmp + '') r[i] = n
      // else r[i] = tmp
      if (i === 'total') r[i] = n
      else r[i] = tmp
    }
  }
  return r
}

// 响应拦截
service.interceptors.response.use(
  response => {
    let res = response.data
    if (typeof res !== 'object') {
      res = { status: 'UNKNOWN', data: { info: res }}
    }
    if (res.status !== 200) {
    // if (res.status !== 'ok') {
      const hash = window.location.hash
      // 登录、忘记密码特殊处理不抛错,页面单独处理报错
      if (hash.indexOf('login') > 0 || hash.indexOf('forget') > 0) {
        const new_res = response_format(res)
        return Promise.resolve(new_res)
      } else {
        // console.log(res.status, 'res.status')
        if (res.status === 60000) {
          // FIXME, 应该判断是不是审计, 如果
          // if (!res.status) {
          //   // 跳转到审核登录页面
          //   if (messageErrorOut < 1) {
          //     this.$message.error('缺少登录参数，废弃了路由')
          //     window._kglobal_myApi.topGoto('login')
          //   }
          //   setTimeout(() => {
          //     messageErrorOut = 0
          //   }, 3000)
          //   // return false
          // } else
          if (messageErrorOut < 1 && this.$myData.uriModule !== '/banner-view/' && this.$myData.uriModule !== '/audit') {
            Vue.prototype.$message({
              message: res.msg,
              type: 'error',
            })
            // store.dispatch('user/logout')
            // router.push({ path: '/' })

            // window._kglobal_myApi.topGoto('login')
            setTimeout(() => {
              messageErrorOut = 0
            }, 3000)
          }
          messageErrorOut++
          // throw new Error(res.msg)
        } else if (res.status === 9999 || res.status === 10000) {
          // 邮件审核未登录
          const new_res = response_format(res)
          return Promise.resolve(new_res)
          // router.push({ path: '/' })
          // window._kglobal_myApi.topGoto('login')
        } else if (res.status === 50040) {
          // 授权已被取消
          Vue.prototype.$message({
            message: res.msg,
            type: 'error',
          })
          // const new_res = response_format(res)
          // return Promise.resolve(new_res)
        } else if (res.status === 50001 || res.status === 60003) {
          // 审核页面邮件被撤回时单独处理
          const new_res = response_format(res)
          return Promise.resolve(new_res)
        } else if (res.status === 20020 || res.status === 2020 || res.status === 20019) {
          // 登录页面、忘记密码单独处理---后期需要优化
          const new_res = response_format(res)
          return Promise.resolve(new_res)
        } else if (res.status === 260001) {
          const new_res = response_format(res)
          return Promise.resolve(new_res)
        } else {
          // if (res.hasOwnProperty('state')) {
          //   MessageBox.confirm(res.info, '提示', {
          //     showCancelButton: false,
          //     confirmButtonText: '确定',
          //     cancelButtonText: '取消',
          //     type: 'error',
          //   }).then(() => {
          //   }).catch(() => {
          //   })
          // }
          // const new_res = response_format(res)
          // return new_res
          Vue.prototype.$message({
            message: res.msg,
            type: 'error',
          })
          throw new Error(res.msg)
        }
      }
    } else {
      const new_res = response_format(res)
      // return new_res
      return Promise.resolve(new_res)
    }
  },
  error => {
    Vue.prototype.$message({
      message: error,
      type: 'error',
    })
    return Promise.reject(error)
  },
)
export default service
