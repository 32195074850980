<template>
  <div class="action-btn">
    <div class="group">
      <button
        class="btn"
        :style="{borderRadius:drop?'':'4px'}"
        :disabled="disabled"
        @click.stop="handleClick"
      >
        <svg-icon
          :icon-class="iconClass"
          style="width: 12px;height: 12px;margin-right: 5px;"
        />
        <slot />
      </button>
      <button
        v-if="drop"
        v-clickoutside="hide"
        class="btn"
        @click="showmenu"
      >
        <i :class="[visible?'el-icon-arrow-up':'el-icon-arrow-down']" />
      </button>
    </div>
    <transition name="el-fade-in">
      <div
        v-show="visible"
        :class="['drop-menu',ispos?'istop':'']"
        @click="sendRes"
      >
        <ul>
          <li
            class="el-select-dropdown__item"
            data-type="1"
          >{{ $t('message.calendar.acbtn7') }}</li>
          <li
            class="el-select-dropdown__item"
            data-type="2"
          >{{ $t('message.calendar.acbtn8') }}</li>
          <li
            class="el-select-dropdown__item"
            data-type="3"
          >{{ $t('message.calendar.acbtn9') }}</li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import clickoutside from 'element-ui/src/utils/clickoutside'
export default {
  directives: { clickoutside },
  props: {
    iconClass: {
      type: String,
      default: 'add',
    }, // 按钮图标
    drop: {
      type: Boolean,
      default: false,
    }, // 下拉菜单
    disabled: {
      type: Boolean,
      default: false,
    }, // 是否禁用按钮
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      ispos: false,
    }
  },
  methods: {
    showmenu(e) {
      if (window.innerHeight - e.clientY < 110) {
        this.ispos = true
      }
      this.visible = !this.visible
    },
    sendRes(e) {
      this.$emit('command', this.type, e.target.dataset.type)
      this.visible = false
    },
    hide() {
      this.visible = false
    },
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.action-btn{
    display: inline-block;
    position: relative;
    margin-right: 5px;
    .group{

    }
    .drop-menu{
        position: absolute;
        background-color: #fff;
        left: 0;
        top: 34px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
    }
    .istop{
      top: -110px;
    }
    .btn{
        display: inline-block;
        white-space: nowrap;
        cursor: pointer;
        background: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 0;
        transition: .1s;
        font-weight: 500;
        padding: 5px 10px;
        font-size: 14px;
        &:not(:last-child){
            border-radius: 4px 0 0 4px;
            border-right: none;
        }
        &:last-child{
            border-radius: 0 4px  4px 0;
            padding: 5px;
        }
    }
}
</style>
