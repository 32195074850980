
const timeZoneJson = [
  { value: '0', label: 'label0' },
  { value: '-1200', label: 'label1' },
  { value: '-1100', label: 'label2' },
  { value: '-0100', label: 'label3' },
  { value: '-0930', label: 'label4' },
  { value: '-0900', label: 'label5' },
  { value: '-0800', label: 'label6' },
  { value: '-0700', label: 'label7' },
  { value: '-0600', label: 'label8' },
  { value: '-0500', label: 'label9' },
  { value: '-0400', label: 'label10' },
  { value: '-0300', label: 'label11' },
  { value: '+0100', label: 'label12' },
  { value: '+0300', label: 'label13' },
  { value: '+0400', label: 'label14' },
  { value: '+0530', label: 'label15' },
  { value: '+0700', label: 'label16' },
  { value: '+0800', label: 'label17' },
  { value: '+0900', label: 'label18' },
  { value: '+1000', label: 'label19' },
  { value: '+1200', label: 'label20' },
]
export default timeZoneJson
