import { transformClass } from '@/utils/utils'
let stateInfo = {}
const state = {
  isDestroy: false, // 是否是销毁邮件
  expire_mail: 999, // 过期邮件的下标
  audit_route_query: {}, // 外界进入审核邮件存储
  getStation: {}, // 文件中转站信息
}

const mutations = {
  ISDESTROY: (state, data) => {
    state.isDestroy = data
  },
  stateDataClear: (state, data) => {
    stateInfo = transformClass.deepClone(state)
  },
  stateDataSet: (state, data) => {
    for (const key in stateInfo) {
      state[key] = stateInfo[key]
    }
  },
  EXPIRE_MAIL: (state, data) => {
    state.expire_mail = data
  },
  AUDIT_ROUTE_QUERY: (state, data) => {
    state.audit_route_query = data
  },
}

export default {
  namespaced: true,
  // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
  // 例： SET_NAME => user/SET_NAME
  state,
  mutations,
}
