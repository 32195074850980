<template>
  <div class="menu-con">
    <svg-icon
      icon-class="g_selectUser"
      class="g_selectUser"
    />
    <div class="info">{{ $t('message.contact.info2') }}</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.menu-con{
  min-width: 300px;
    flex: 1;
    text-align: center;
    margin-top: 250px;
    .g_selectUser{
        width: 6em;
        height: 6em;
    }
    .menu{
        display: flex;
    }
    .info{
        color: #A4A3A3;
        padding: 29px 0 53px 0;
    }
    .arrow{
        cursor: pointer;
    }
}
</style>
