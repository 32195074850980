<template>
  <div v-if="dialogVideo">
    <el-dialog
      title=""
      :visible.sync="dialogVideo"
      :show-close="closeable"
      :modal-append-to-body="false"
      width="50%"
      @close="colseDialog"
    >
      <!-- 视频：h5的视频播放video -->
      <!-- <video
        ref="video"
        :src="videourl"
        autoplay
        webkit-playsinline
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="landscape|portrait"
        preload="meta"
        controlslist="nodownload"
        x-webkit-airplay="allow"
        disablePictureInPicture
        currentTime="0"
        width="100%"
        height="100%"
        muted
      > -->
      <div class="dialogVideo-body">
        <video
          ref="video"
          :src="videourl"
          controls
          autoplay
          controlslist="noplaybackrate nodownload nofullscreen noremoteplayback"
          disablePictureInPicture
          width="100%"
          height="100%"
          muted
          @timeupdate="timeupdate"
        >
          {{ $t('message.view.not_support') }}
        </video>
        <!-- <div class="kong" /> -->
      </div>

      <div
        color="#1989fa"
        background="#ecf9ff"
        left-icon="info-o"
      >
        {{ $t('message.view.attack_info') }}
      </div>

      <span
        v-show="closeable"
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="colseDialog"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import serialize from '@/utils/serialize'
export default {
  props: {
    videosrc: {
      type: String,
      default: 'https://www.apple.com.cn/105/media/us/iphone-14-pro/2022/a3e991f3-071e-454c-b714-1b2319bb97a8/anim/cinematic-mode/large_2x.mp4',
    },
  },
  data() {
    return {
      dialogVideo: false,
      videourl: this.videosrc, // ‘获取钓鱼邮件视频的链接'
      closeable: false,
      currTime: null,
      maxTime: 0,
    }
  },
  watch: {
    videosrc: function(a, b) {
      this.videourl = a
    },

  },
  created() {
  },
  methods: {
    /** 记录视频是否快进
     * '视频记录', e.srcElement.currentTime
     * '本地记录', this.currTime
     */
    timeupdate(e) {
      if (e.srcElement.currentTime - this.currTime > 1) {
        e.srcElement.currentTime = this.currTime > this.maxTime ? this.currTime : this.maxTime
        this.closeable = false
        return false
      }
      this.currTime = e.srcElement.currentTime
      this.maxTime = this.currTime > this.maxTime ? this.currTime : this.maxTime
      if (e.srcElement.duration === e.srcElement.currentTime) {
        this.currTime = null
        this.maxTime = 0
        this.VideoEnded()
      }
    },
    // toggleCom(num) {
    //   this.comNum = num
    // },
    colseDialog() {
      this.dialogVideo = false
      this.closeable = false
    },
    // 点击按钮出现弹窗播放视频
    checkVideoFun() {
      // 打开了视频
      this.dialogVideo = true
    },
    // 视频播放完毕
    VideoEnded(e) {
      var that = this
      // setTimeout(() => {
      var elevideo = this.$refs.video
      elevideo.addEventListener('ended', function() { // 结束
        console.log('结束了')
        that.closeable = true
        // 告诉后端有没有看完视频
        that.doneLearning()
      }, false)
      // }, 2000)
    },
    async doneLearning() {
      const req = {
        'module': 'phishingMail',
        'require': {
          action: 'doneLearning',
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        // '关闭了播放视频'
      })
    },
  },
}
</script>
  <style scoped lang="scss">
  .dialogVideo-body{
    position: relative;
    .kong{
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 0;
    }
  }
    .van-icon{
      font-size: 22px!important;
    }
.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}
/* 内容层 z-index要比遮罩大，否则会被遮盖 */
.videomasks {
  max-width: 1200px;
  position: fixed;
  left: 0;
  top: 48%;
  margin-top: -30%;
  z-index: 20;
  /* transform: translate(-50%, -50%); */
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-ms-media-controls-fullscreen-button {
    display: none;
    }
</style>
