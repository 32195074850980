<template>
  <el-dialog
    :visible.sync="showUpload"
    width="780px"
    custom-class="previewDialog"
    :append-to-body="false"
    :modal-append-to-body="false"
    top="5vh"
    @opened="requesLists"
  >
    <div class="upload-container">
      <div class="nav">
        <div
          :class="['nav-item' ,itemIndex===1?'navitem-active':'']"
          @click="handoff(1)"
        >{{ $t('message.compose.loaclfile') }}</div>
        <div
          :class="['nav-item',itemIndex===2?'navitem-active':'']"
          @click="handoff(2)"
        >{{ $t('message.compose.myfile') }}</div>
        <div
          v-if="$store.state.app.user_permissions.netdisk === 1"
          :class="['nav-item',itemIndex===3?'navitem-active':'']"
          @click="handoff(3)"
        >{{ $t('message.compose.netfile') }}</div>
      </div>
      <div class="content">
        <div
          v-show="itemIndex === 1"
          class="my-att"
        >
          <div
            v-if="uploadFiles.length>0"
            class="attArea"
            style="height:260px;max-height: inherit;"
          >
            <div
              v-for="(item,index) in uploadFiles"
              :key="index"
              class="attArea-item"
            >
              <div class="img">
                <svg-icon
                  :icon-class="getFileType(item.name)+'icon'"
                  class-name="atticon"
                />
              </div>
              <div class="info">
                <div class="filename">
                  <span
                    v-title="item.name"
                    class="name"
                  >{{ item.name }}</span>
                  <span class="size"><span>({{ item.size|mailSizefilter }})</span></span>
                </div>
                <div class="handle">
                  <span
                    class="text-color"
                    style="margin-right:10px;padding:5px 0"
                    @click="handleRemovepload(item)"
                  >{{ $t('message.compose.del') }}</span>
                  <!-- <span>{{ item.hashProgress==100?'扫描完成':`文件扫描中${item.hashProgress}%` }}</span> -->
                </div>
              </div>
            </div>
          </div>
          <SplitUpload
            ref="uploadfils"
            style="flex:1"
            :on-change="fileChange"
            :on-success="fileUploadSuccess"
            :on-remove="fileRemove"
            :on-start="handleStart"
            :before-upload="handleBefore"
            drag
          />
        </div>
        <div
          v-show="itemIndex === 2"
          class="attArea"
          style="height: 377px;overflow:hidden;max-height:inherit;"
        >
          <div class="localAtts">
            <el-tabs
              v-model="activeName"
            >
              <el-tab-pane
                :label="$t('message.compose.fileman')"
                name="attsmanger"
              >
                <div
                  style="height:320px;overflow:auto"
                >
                  <div
                    v-if="!atts_manager.length"
                    style="text-align: center;line-height: 320px;"
                  >{{ $t('message.compose.nodate') }}</div>
                  <div
                    v-for="(att,index) in atts_manager"
                    :key="att.id"
                    class="attArea-item"
                    style="max-height:100%;"
                  >
                    <div>
                      <el-checkbox
                        v-model="att.checked"
                        :label="index"
                        class="css-checkbox"
                        @change="attsManagerChange(att,$event)"
                      />
                    </div>
                    <div
                      class="img"
                      style="margin-right:10px;margin-left:0"
                    >
                      <svg-icon
                        :icon-class="getFileType(att.att_name)+'icon'"
                        class-name="atticon"
                      />
                    </div>
                    <div class="filename">
                      <span
                        :class="['name',att.checked?'fw':'']"
                        style="color:#1D1B1B"
                      >{{ att.att_name }}</span>
                    &nbsp;&nbsp;
                      <span
                        class="size"
                        style="color: #8E8D8D;"
                      >
                        (<span
                          v-format-bytes="att.att_size"
                        />)
                      </span>

                    </div>
                  </div>

                </div>
              </el-tab-pane>
              <el-tab-pane
                :label="$t('message.compose.filetrans')"
                name="attstemp"
              >
                <div
                  style="height:320px;overflow:auto"
                >
                  <div
                    v-if="!attsTransferList.length"
                    style="text-align: center;line-height: 320px;"
                  >{{ $t('message.compose.nodate') }}</div>
                  <div
                    v-for="(att,index) in attsTransferList"
                    :key="index"
                    class="attArea-item"
                    style="max-height:100%;"
                  >
                    <div>
                      <el-checkbox
                        v-model="att.checked"
                        :label="index"
                        class="css-checkbox"
                        @change="attsTransferChange(att,$event)"
                      />
                    </div>
                    <div
                      class="img"
                      style="margin-right:16px;margin-left:0"
                    >
                      <svg-icon
                        :icon-class="getFileType(att.nd_name)+'icon'"
                        class-name="atticon"
                      />
                    </div>
                    <div class="filename">
                      <span
                        :class="['name',att.checked?'fw':'']"
                        style="color:#1D1B1B"
                      >{{ att.nd_name }}</span>
                    &nbsp;&nbsp;
                      <span
                        class="size"
                        style="color: #8E8D8D;"
                      >
                        (<span
                          v-format-bytes="Number(att.mail_attachment_size)"
                        />)
                      </span>

                    </div>
                  </div>

                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div
          v-show="itemIndex === 3"
          class="attArea"
          style="height: 377px;overflow:hidden;max-height:inherit;"
        >
          <div style="height: 419px;">
            <el-tabs
              v-model="activeName2"
              @tab-click="tabClick"
            >
              <el-tab-pane
                v-for="(item,index) in tablist"
                :key="index"
                :label="$t(`message.compose.${item.label}`)"
                :name="item.name"
              >
                <div v-if="activeName2==='all'">
                  <span
                    v-for="item in breadcrumbs"
                    :key="item.id"
                    style="cursor:pointer;margin-left:5px"
                    class="el-link el-link--default"
                    @click="jumpCreadCrumb(item)"
                  >{{ '>'+item.label }}</span>
                </div>
                <div
                  style="height:300px;overflow:auto"
                >
                  <div
                    v-if="!item.files.length"
                    style="text-align: center;line-height: 300px;"
                  >{{ $t('message.compose.nodate') }}</div>
                  <div
                    v-for="file in item.files"
                    :key="file.nd_id"
                    class="attArea-item"
                    :style="{'max-height':'100%','padding-left':file.nd_is_dir==='1'?'30px':'',cursor:file.nd_is_dir==='1'?'pointer':''}"
                    @click="getFileBycatalog(file)"
                  >
                    <div>
                      <el-checkbox
                        v-if="file.nd_is_dir==='0'"
                        v-model="file.checked"
                        :label="index"
                        class="css-checkbox"
                        @change="attsNetdiskChange(file,$event)"
                      />
                    </div>
                    <div
                      class="img"
                      style="margin-right:16px;margin-left:0"
                    >
                      <svg-icon
                        :icon-class="file.nd_is_dir==='1'?'g_folder':getFileType(file.nd_name)+'icon'"
                        class-name="atticon"
                      />
                    </div>
                    <div class="filename">
                      <span
                        :class="['name',file.checked?'fw':'']"
                        style="color:#1D1B1B"
                      >{{ file.nd_name?file.nd_name:$t('message.compose.superatt') }}</span>
                    &nbsp;&nbsp;
                      <span
                        v-if="file.nd_is_dir==='0'"
                        class="size"
                        style="color: #8E8D8D;"
                      >
                        (<span
                          v-format-bytes="file.nd_size"
                        />)
                      </span>

                    </div>
                  </div>

                </div>

              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="cancelUploadFiles">{{ $t('message.btnText.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="confirmUploadFiles"
      >{{ $t('message.btnText.ok') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetFileType, transformClass, getAfterTimer } from '@/utils/utils'
import SplitUpload from '@/components/SplitUpload/index.vue'
import { BigAttachmentsText } from '@/views/compose/components/template'
import netdisk from '@/views/compose/components/netdisk'
import serialize from '@/utils/serialize'
export default {
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
  },
  components: { SplitUpload },
  mixins: [netdisk],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showUpload: false, // 上传附件弹窗
      itemIndex: 1,
      uploadFiles: [],
      activeName: '',
      activeName2: 'all',
      atts_manager: [], // 附件管理附件
      fakeAttachmentList: [], // 显示附件列表
      req: {
        atts: [],
      },
      attsList: [],
      bigAttachmentList: [], // 大附件列表
      haveatts: false,
    }
  },
  watch: {
    activeName: {
      handler(value) {
        if (value === 'attsmanger' && !this.haveatts) {
          this.getAttchment()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.activeName = 'attsmanger'
  },
  methods: {
    // 获取附件管理中附件
    getAttchment(flag) {
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(this.atts) }).then(({ result }) => {
        result.list.map(item => {
          item.checked = false
          this.atts_manager.push(item)
        })
        this.haveatts = true
      })
    },
    showuploadDia() {
      this.showUpload = true
    },
    handoff(value) {
      this.itemIndex = value
    },
    // 确认选择附件
    async confirmUploadFiles() {
      if (this.itemIndex === 1) {
        if (!this.uploadFiles.length) {
          return this.$message.error(this.$t('message.compose.err11'))
        }
        this.showUpload = false
        this.$refs.uploadfils.submit()
        this.uploadFiles = []
      } else if (this.itemIndex === 2) {
        if (this.selecttedTransferList.length === 0 && this.attsManagerFiles.length === 0) return this.$message.error(this.$t('message.compose.err11'))
        if (this.selecttedTransferList.length > 0) {
          const attNetdiskFiles = await this.getNetdiskAttachmentInfo(1)
          this.fakeAttachmentList = [...this.fakeAttachmentList, ...attNetdiskFiles]
          this.bigAttachmentList = [...this.bigAttachmentList, ...attNetdiskFiles]
          this.showUpload = false
          this.selecttedTransferList = []
          this.cancelUploadFiles()
        }
        if (this.attsManagerFiles.length > 0) {
          const attManagersFiles = await this.attachmentMultiForwardOne()
          if (!attManagersFiles.length) {
            return this.$message.error(this.$t('message.compose.err11'))
          }
          this.fakeAttachmentList = [...this.fakeAttachmentList, ...attManagersFiles]
          this.attsList = [...this.attsList, ...attManagersFiles]
          this.showUpload = false
          this.attsManagerFiles = []
          this.cancelUploadFiles()
        }
      } else {
        const attNetdiskFiles = await this.getNetdiskAttachmentInfo()
        console.log(attNetdiskFiles)
        if (!attNetdiskFiles.length) {
          return this.$message.error(this.$t('message.compose.err11'))
        }
        this.fakeAttachmentList = [...this.fakeAttachmentList, ...attNetdiskFiles]
        this.bigAttachmentList = [...this.bigAttachmentList, ...attNetdiskFiles]
        this.showUpload = false
        this.attsNetdiskFiles = []
        this.cancelUploadFiles()
      }
      this.$emit('input', this.fakeAttachmentList)
    },
    // 获取附件管理附件详细信息
    attachmentMultiForwardOne() {
      const requestLists = this.attsManagerFiles.map(attachment => {
        return {
          charset: attachment.att_charset,
          disposition: attachment.att_disposition,
          encoding: attachment.att_encoding,
          mbox: attachment.mail_mbox,
          name: attachment.att_name,
          psection: attachment.att_psection,
          section: attachment.att_section,
          size: attachment.att_size,
          subtype: attachment.att_subtype,
          type: attachment.att_type,
          uid: attachment.uid,
          uv: attachment.uidvalidity,
        }
      })
      const params = {
        module: 'mail',
        require: {
          action: 'attachmentMultiForwardOne',
          list: requestLists,
        },

      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          const atts = result.map((attachment, index) => {
            return {
              cid: attachment.cid,
              name: attachment.fileName,
              type: 'upload',
              size: attachment.fileSize,
              upid: attachment.upid,
              fileuptype: 'attsManager',
              origin: 'attaManage',
              originData: requestLists[index],
            }
          })
          this.attsManagerFiles.map(item => {
            item.checked = false
          })
          resolve(atts)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    cancelUploadFiles() {
      this.uploadFiles.map(file => {
        this.$refs.uploadfils.deleteFileList(file)
      })
      this.uploadFiles = []
      this.attsTransferList.map(item => {
        item.checked = false
      })
      this.attsManagerFiles.map(item => {
        item.checked = false
      })
      this.attsNetdiskFiles.map(item => {
        item.checked = false
      })
      this.selecttedTransferList = []
      this.attsNetdiskFiles = []
      this.showUpload = false
    },
    attsNetdiskChange(att, e) {
      if (e) {
        this.attsNetdiskFiles.push(att)
      } else {
        this.attsNetdiskFiles.splice(this.attsNetdiskFiles.indexOf(att), 1)
      }
    },
    tabClick(tab) {
      this.tablist.map((v, i) => {
        if (tab.name === v.name) {
          v.active = true
        } else {
          v.active = false
        }
      })
      this.getNetdiskFilesByCategory(tab.name)
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 删除弹窗文件
    handleRemovepload(file) {
      this.$refs.uploadfils.deleteFileList(file)
      this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1)
    },
    // 选择文件时触发
    fileChange(files) {
      const arrs = Array.prototype.slice.call(files)
      arrs.map(item => {
        if (this.uploadFiles.indexOf(item) === -1) {
          this.uploadFiles.push(item)
        }
      })
    },
    // 文件上传成功回调
    async fileUploadSuccess(res, file, filelist) {
      const { lifespan_forever, station_lifespan } = this.$store.state.app
      if (res.size > this.$store.state.app.upload_mode_limit) {
        const { result } = await this.uploadNetdisk(res)
        if (result === 'error') {
          this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(file), 1)
          this.$emit('input', this.fakeAttachmentList)
          return false
        }
        this.bigAttachmentList.push({
          name: res.name,
          size: res.size,
          type: 'upload',
          expire: station_lifespan,
          upid: res.upid,
          fileuptype: 'big',
          nd_url: this.getdownloadlargefileURL(result.magic_id),
          bodys: BigAttachmentsText({
            url: this.getdownloadlargefileURL(result.magic_id),
            name: res.name,
            expire: lifespan_forever === '1' ? this.$t('message.compose.neverexpire') : getAfterTimer(new Date(), station_lifespan),
          }),
        })
        this.bigCount++
      } else {
        this.attsList.push(res)
      }
    },
    // 删除写信附件区域文件(可视附件)
    fileRemove(file, filelist) {
      // console.log(this.fakeAttachmentList.indexOf(file))
      // this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(file), 1)
      const current = this.attsList.find(({ upid }) => upid === file.uid)
      if (this.attsList.indexOf(current) !== -1) {
        this.attsList.splice(this.attsList.indexOf(current), 1)
      }
      this.$emit('input', this.fakeAttachmentList)
    },
    // 上传开始时处理
    handleStart(filelist) {
      console.log(filelist)
      filelist.map(file => {
        if (this.fakeAttachmentList.indexOf(file) === -1) {
          this.fakeAttachmentList.push(file)
        }
      })
      console.log(this.fakeAttachmentList)
      this.$emit('input', this.fakeAttachmentList)
    },
    // 禁止上传附件类型
    handleBefore(files) {
      let isNull = false
      for (let index = 0; index < files.length; index++) {
        const element = files[index]
        if (element.size === 0) {
          isNull = true
          break
        }
      }
      if (isNull) {
        this.$message.error(this.$t('message.compose.err13'))
        return false
      }
      const { limit_atta_type } = this.$store.state.app
      const type = limit_atta_type.split(',')
      const isUpload = files.some(item => !type.includes(item.name.substr(item.name.lastIndexOf('.') + 1).toLowerCase()))
      if (isUpload) {
        return true
      } else {
        return true
      }
    },
    // 监听ManagerLists改变
    attsManagerChange(att, e) {
      if (e) {
        this.attsManagerFiles.push(att)
      } else {
        this.attsManagerFiles.splice(this.attsManagerFiles.indexOf(att), 1)
      }
    },
    attsTransferChange(att, e) {
      if (e) {
        this.selecttedTransferList.push(att)
      } else {
        this.selecttedTransferList.splice(this.selecttedTransferList.indexOf(att), 1)
      }
    },
    requesLists() {
      this.getNetdiskFiles()// 获取网盘列表
      this.gerTransferList()// 获取中转站列表
    },
    // 删除写信附件区域文件(组件内部)
    handleRemove(file) {
      console.log(file)
      return this.$confirm(this.$t('message.compose.info36'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(() => {
        if (file.type === 'splitupload') {
          // this.$refs.uploadfils.handleRemove(file)
          // if (file.size > this.$store.state.app.upload_mode_limit) {
          //   this.deleteBigAttachment(file.uid)
          // }
          this.fakeAttachmentList = this.fakeAttachmentList.filter(item => item.uid !== file.uid)
          if (Number(file.size) > Number(this.$store.state.app.upload_mode_limit)) {
            this.bigAttachmentList = this.bigAttachmentList.filter(item => item.upid !== file.uid)
            this.bigCount--
          }
          this.$refs.uploadfils.handleRemove(file)
          this.$emit('input', this.fakeAttachmentList)
        } else if (file.type === 'netdisk') {
          // this.deleteReqFiles(file)
          // this.deleteBigAttachment(file.upid)
          this.fakeAttachmentList = this.fakeAttachmentList.filter(item => item.upid !== file.upid)
          this.bigAttachmentList = this.bigAttachmentList.filter(item => item.upid !== file.upid)
          this.$emit('input', this.fakeAttachmentList)
        } else {
          this.deleteReqFiles(file)
        }
        console.log(this.bigAttachmentList)
        console.log(this.attsList)
      }).catch(() => {

      })
    },
    // 删除真实附件数据
    deleteReqFiles(raw) {
      this.attsList.splice(this.attsList.indexOf(raw), 1)
      this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(raw), 1)
      this.$emit('input', this.fakeAttachmentList)
      // if (raw.size > this.$store.state.app.upload_mode_limit) {
      //   this.deleteBigAttachment(raw.upid)
      // }
    },
    // 删除待发送大附件列表
    deleteBigAttachment(id) {
      const current = this.bigAttachmentList.find(({ upid }) => upid === id)
      const fake_index = this.fakeAttachmentList.indexOf(current)
      const big_index = this.bigAttachmentList.indexOf(current)
      if (fake_index !== -1) this.fakeAttachmentList.splice(fake_index, 1)
      if (big_index !== -1) this.bigAttachmentList.splice(big_index, 1)
      this.$emit('input', this.fakeAttachmentList)
    },
    // 断点续传
    handleResumeUpload(file) {
      this.$refs.uploadfils.handleResume(file)
    },
    isCompleted() {
      if (!this.$refs.uploadfils) return true
      return this.$refs.uploadfils.isCompleted()
    },
    // 获取大附件下载链接
    getdownloadlargefileURL(magic_id) {
      const { netdisk_prefix } = this.$myData.sysConfig
      const { node } = this.$store.state.app
      return `${netdisk_prefix || ''}downloadlargefile.php?nsnode=s1_${node}&magic_id=${magic_id}`
    },
    // 清空文件
    clearFileNull() {
      this.fakeAttachmentList = []
      this.attsList = []
      this.bigAttachmentList = []
      if (!this.$refs.uploadfils) return
      this.$refs.uploadfils.clearFiles()
    },
    setEditOrinData(data) {
      this.attsList = [...data]
      this.fakeAttachmentList = [...data]
      this.$emit('input', this.fakeAttachmentList)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-tabs__nav-wrap::after{
  background-color: transparent;
}
/deep/.el-breadcrumb__inner{
  cursor: pointer!important;
}
/deep/.previewDialog.el-dialog {
  .el-dialog__header {
    display: none;
  }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer{
    padding: 20px;
  }
}
.upload-container{
  .nav{
    display: flex;
    justify-content: space-between;
    border: 1px solid #e9e9e9;
    .nav-item{
      font-weight: bold;
      color: #1d1b1b;
      flex: 1;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }
    .active{
      border-bottom: 3px solid #d0161b;
    }
  }
  .content{
    padding: 0 38px;
    .attArea{
      max-height: 251px;
      overflow: auto;
      .localAtts{
        height: 100%;
        .title{
          padding-bottom: 6px;
          margin-right: 35px;
        }
        .active{
          border-bottom: 2px solid #d0161b;
        }
      }
      .attArea-item{

        border-bottom: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        padding: 10px 0;
        .img{
          margin-left: 16px;
          .atticon{
            width: 2em;
            height: 2em;
          }
        }
        .info{
          height: 100%;
          padding-left: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .filename{
            display: inline-block;
            .size{
              color:#A5A4A4;
            }
            .name{
              display: inline-block;
              max-width: 500px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: text-top;
            }
          }
          .handle{
            display: flex;
            align-items: center;
            .text-color{
              cursor: pointer;
              color: #d0161b!important;
            }
          }
        }
      }
    }
    .my-att{
      display: flex;
    flex-direction: column;
    height: 377px;
      padding-top: 17px;
      .haveatts{
          height: 121px;
      }
      .contentInfo{
        margin: auto;
      }

    }
  }
}
.css-checkbox{
  /deep/.el-checkbox__label{
    display: none;
  }
}
.el-checkbox{
  margin-right: 20px;
}
</style>
