import { GetFileType, transformClass, getQueryVariable, iframeEventPop } from '@/utils/utils.js'

import { getCookie } from '@/utils/cookie'
// import GetRecallType from '@/utils/recallType'
import serialize from '@/utils/serialize'
// import xss from '@/utils/xss'
import DOMPurify from 'dompurify' // 过滤xss攻击的
// import { getObjArr } from '@/utils/localstorage'
export default {
  name: 'Login',
  components: {

  },
  props: {
    readMailReqData: {
      type: Object,
      default() {
        return {}
      },
    },
    readMailUidMbox: {
      type: String,
      default() {
        return 'a'
      },
    },
    tabId: {
      type: String,
      default() {
        return 'a'
      },
    },
    fromType: {
      type: String,
      default() {
        return 'a'
      },
    },
  },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },

  },
  data() {
    return {
      dialogVisible: false, // 消息提示
      sendYzmVisible: false, // 发送验证码通知信
      dialogMsg: '', // 消息信息
      bodyHtml: {}, // 邮件信息
      attachments: [], // 附件
      flags: 0, // 星标
      form: '',
      to_s: [],
      cc_s: [],
      bcc_s: [],
      bodys: {},
      isHtml: true,
      replyValue: '',
      isUnfold: true,
      rows: 20,
      sentMailStatus: '', // 发件箱的发送状态
      sentMailStatusList: '', // 发件箱审核人的列表
      isReplyAll: false,
      replyTxt: '', // 快捷回复的内容
      isReplyTxt: false,
      recallMailInfo: {
        recallType: false, // 是否需要邮件召回
      },
      isPhishingEmail: false, // 是否打开钓鱼邮件
      videosrc: '', // 钓鱼视频链接
      dialogReceipt: false, // 是否需要回执
      isAuditMail: false, // 是否是邮件审核'
      auditMailInfo: {}, // 邮件审核的信息
      isPrintMail: false, // 是否是邮件打印'
      firstMail: true, // 是第一封邮件
      lastMail: true, // 是最后一封邮件
      mailInfo: { 'uid': '00000' }, // 邮件的基本信息
      userConfig: '',
      replyList: [], // 快速回复列表
      tabList: [],
      downAttaReq: {}, // 下载附件参数
      isAttaDown: false,
      token: '', // 邮件审核的token
      nsnode: '', // 邮件审核节点
      click_from: '', // 邮件审核来源
      isShowDownBtn: false, // 审核是否完成
      isKmd: 'view', // kyms可疑、陌生、钓鱼邮件  view 邮件召回中的撤回邮件  down下载
      urlOrgin: '', // 点击页面的a链接
      auth_authority: 9, // 授权邮箱权限
      showMailPasswordDialog: false, // 是否显示密码邮件框
      mail_psw: '', // 邮件密码
      attaImg: [], // 要展示的附件附片
      fileListDisk: [], // 保存网盘选中的文件
      showDialogDisk: false, // 是否打开保存网盘
      isRouter: false,
      downType: '', // ky\ms下载类型--临时存储
      downEl: '', // ky\ms下载内容--临时存储
      webDisk: {}, // ky\ms转存网盘--临时存储
      dialogVisibleContact: false,
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      contact_id: '',
      addtitle: this.$t('message.view.add_new_contact'),
      idEdit: false,
      reply_receipt: this.$store.state.app.userGetConfig.reply_receipt, // 设置中回执选项
      // flowSrc: '/#/view-mail',
      showMemberInfo: false,
      loading: true,
      msgContent: {},
      audit_expired: false, // 邮件审核过期、撤回页面
      audit_expired_html: '', // 邮件审核过期、撤回页面 内容
      yzmInpuy: '', // 验证码
      urlPath: '', // 当前url路径
      urlReaded_destroy: '', // 是否是阅后即焚
      urlTo: '', // 收件人
      message_id: '',
    }
  },
  computed: {
  },
  watch: {

  },
  beforeCreate() {
  },
  created() {
    // this.urlPath = this.$route.path
    // this.urlReaded_destroy = this.$route.query.readed_destroy // 是否是阅后即焚
    // this.urlTo = this.$route.query.to // 收件人
    // this.message_id = this.$route.query.message_id
    // this.nsnode = this.$route.query.nsnode

  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$myData.uriDatas, this.$myData.uriModule, 'this.$myData.uriDatas')
      var _this = this
      this.token = this.$myData.uriDatas.token
      this.urlPath = this.$myData.uriModule
      // this.urlReaded_destroy = this.readMailReqData.readed_destroy // 是否是阅后即焚
      this.urlTo = this.$myData.uriDatas.to // 收件人
      this.message_id = this.$myData.uriDatas.message_id
      this.nsnode = this.$myData.uriDatas.nsnode
      this.loading = true
      if (_this.$myData.uriModule === '/convert-view') {
        // const name = this.message_id + '_convert_code'
        const name = 'protect_convert_code'
        if (getCookie(name)) {
          // 如果已经输入过验证码，则直接读取内容
          // this.submitMailFun()
          this.showMsg_bodys_document()
        } else {
          _this.sendYzmVisible = true
        }
      } else {
        _this.showMsg_bodys_document()
      }
    })
  },
  beforeDestroy() {
    // this.$store.commit('mail/ISDESTROY', false)
  },
  methods: {
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
    showName(i) {
      let name = ''
      if (this.isUnfold) {
        name = i[1]
      } else {
        name = i[1] + '<' + i[0] + '>'
      }
      return name
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    // 获取读信页面详情
    getMail(istab) {
      const req = {
        'module': 'mail',
        'require': {
          action: 'singleGetMail',
          token: this.token,
          mail_psw: this.mail_psw,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        // 判断是否是加密邮件
        if (res.result.mail_psw && res.result.mail_psw === 'yes') {
          this.showMailPasswordDialog = true
          this.mailInfo = {
            uv: res.result.uv,
            uid: res.result.uid,
            uidvalidity: res.result.uv,
          }
          return
        }
        // 如果是过期邮件 还需要加邮件审核撤回的
        if (res.result.expire_time === 'yes') {
          this.bodyHtml.subject = this.$t('message.view.mail_already_set_psw')
          this.$confirm(this.$t('message.view.mail_already_set_psw'), this.$t('message.view.warning'), {
            confirmButtonText: this.$t('message.view.konw'),
            showCancelButton: false,
            showClose: false,
            type: 'warning',
          }).then(() => {

          }).catch(() => {
            return
          })
        }
        res.result.from === 0 ? this.firstMail = true : this.firstMail = false
        res.result.from + 1 === Number(res.result.count) ? this.lastMail = true : this.lastMail = false
        this.bodyHtml = res.result.mailData
        // console.log(res.result.mailData, 'res.result.mailData')
        // 判断邮件是否过期

        // 如果是过期邮件，自动跳转上一封或者下一封
        if (res.result.is_expired) {
          this.closeDialog()
          return false
        }
        if (this.bodyHtml.bodys && this.bodyHtml.bodys.length) {
          this.bodyHtml.bodys[0].substring(0, 1) === 'h' ? this.isHtml = true : this.isHtml = false
        }
        this.showMemberInfo = true
        if (this.isHtml) {
          // this.bodys = this.bodyHtml.bodys.length ? DOMPurify.sanitize(this.bodyHtml.bodys[0].substring(1)) : ''
          this.bodys = this.bodyHtml.bodys.length ? DOMPurify.sanitize(this.bodyHtml.bodys[0].substring(1)) : ''
        } else {
          this.bodys = this.bodyHtml.bodys.length ? this.bodyHtml.bodys[0].substring(1).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '\n<br/>').replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') : ''
        }
        // this.$store.commit('mail/MAILBODY', DOMPurify.sanitize(this.bodys))

        this.loading = false
        // 显示
        this.showBody()
        // 拦截a链接
        this.listenLink()
        // console.log(this.$store.state.mail.mailBody, 'kokoko')
        // 向iframe传值
        // const mapFrame = this.$refs['iframe']
        // const iframeWin = mapFrame.contentWindow
        // iframeWin.postMessage(DOMPurify.sanitize(this.bodys), '*',
        // )
        // console.log(this.bodys, '读信内容', DOMPurify.sanitize(this.bodys))
        if (this.bodyHtml.readed_destory === '1') {
          // 标记是否是销毁邮件
          // this.$store.commit('mail/ISDESTROY', true)
        }

        this.mail_psw = ''// 清空此次密码
        this.to_s = this.bodyHtml.to_s
        this.cc_s = this.bodyHtml.cc_s
        this.bcc_s = this.bodyHtml.bcc_s
        this.attachments = this.bodyHtml.attachments
        this.form = this.bodyHtml.from_name
        this.textarea = '快捷回复给' + this.bodyHtml.from_name
        this.flags = this.bodyHtml.flags.charAt(3)
        this.tabList = this.bodyHtml.tag
        this.dialogReceipt = this.bodyHtml.receipt.length !== 0 && this.bodyHtml.flags.charAt(5) !== '1' && this.bodyHtml.mbox !== 'Sent'
        // 是否显示内嵌图片
        const domparser = new DOMParser()
        var doc = domparser.parseFromString(this.bodys, 'text/html')
        const arr = doc.body.getElementsByTagName('img')
        this.show_always_img(arr)
      })
    },
    // 显示内嵌图片
    show_always_img(arr) {
      if (arr.length !== 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.width = 'auto'
        }
      }
      let haveimg
      if (arr.length === 0) {
        haveimg = false
      } else {
        haveimg = true
      }
      if (haveimg) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.display = 'block'
          arr[i].style.width = 'auto'
        }
        if (this.isHtml) {
          const imgDiv = window.document.createElement('div')
          // 内嵌图片示例
          // this.bodys = JSON.parse(JSON.stringify('<html><body>\r\n<div background="cid:_Foxmail.1@e6a26adb-7777-e5dc-74b5-5cff85f32aa8">\r\nsss\r\n<hr />\r\n<img src="cid:_Foxmail.1@e6a26adb-7777-e5dc-74b5-5cff85f32aa8" /><div background="cid:_Foxmail.1@66666666666"></div>\r\n</div>\r\n\r\n</body>\r\n</html>'))
          imgDiv.innerHTML = this.bodys
          const divbox = imgDiv.getElementsByTagName('*')
          // if (this.$store.state.app.userGetConfig.show_image === 'always' || this.$store.state.app.userGetConfig.show_image === 'contact') {
          for (let i = 0; i < divbox.length; i++) {
            // 获取所有的background
            const back = divbox[i].getAttribute('background') || ''
            if (back !== '' && back.substring(0, 4) === 'cid:') {
              const new_back = this.showImg(back.substring(5))
              divbox[i].setAttribute('background', new_back)
            }
          }
          // 获取所有的imgs
          const imgs = imgDiv.getElementsByTagName('img')
          for (let i = 0; i < imgs.length; i++) {
            const img = imgs[i]
            // 图片不能太大, 变小后可以点击还原
            img.style.maxWidth = '95%'
            const url = img.src || ''
            if (url !== '' && img.src.substring(0, 4) === 'cid:') {
              const new_url = this.showImg(img.src.substring(5))
              img.src = new_url
            }

            // 还需要判断发件人是否在通讯录里
            if (this.$store.state.app.userGetConfig.show_image === 'contact' && !this.bodyHtml.show_in_images) {
              if (url !== '') {
                img.src = ''
                img.style.display = 'none'
              }
            }

            img.onload = () => {
              this.showBody()
            }
          }
          // } else if (this.$store.state.app.userGetConfig.show_image === 'never') {
          //   // 获取所有的imgs
          //   const imgs = imgDiv.getElementsByTagName('img')
          //   for (let i = 0; i < imgs.length; i++) {
          //     const img = imgs[i]
          //     const url = img.src || ''
          //     if (url !== '') {
          //       img.src = ''
          //       img.style.display = 'none'
          //     }
          //     img.onload = () => {
          //       this.showBody()
          //     }
          //   }
          // }
          this.bodys = JSON.parse(JSON.stringify(imgDiv.innerHTML))
          this.showBody()
        }
      } else {
        for (let i = 0; i < arr.length; i++) {
          arr[i].src = ''
          arr[i].style.display = 'none'
        }
      }
    },
    // 图片的转换  将内嵌图片cid的转换成邮件可读的图片
    showImg(cid) {
      let url = ''
      this.attachments.forEach(el => {
        if (el.cid.substr(2, el.cid.length - 3) === cid) {
          const req = {
            disposition: 'inline',
            encoding: el.encoding,
            mbox: this.mailInfo.mbox,
            module: 'mail_attachment',
            section: el.section,
            uid: this.mailInfo.uid,
            uv: this.mailInfo.uv,
            preview: 1,
          }
          url = '/nsmail/mail.php/fn.' + this.getFileType(el.name) + '?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
        }
      })
      return url
    },
    showMsg_bodys_document() {
      // 向iframe传值
      const viewIframe = this.$refs['iframe']
      const doc = viewIframe.contentWindow.document || '<div></div>'
      let h = '<!DOCTYPE html><HTML>'
      h += '<HEAD>'
      h += '<TITLE>应该到加载normalize.css, 以保证显示效果一致</TITLE>'
      h += '<META CHARSET="utf-8"><META HTTP-EQUIV="X-UA-Compatible">'
      h += '</HEAD>'
      h += '<BODY style="'
      h += 'overflow: hidden;'
      h += 'width: 100%;'
      h += 'margin: 0px;'
      h += 'padding: 0px;'
      h += 'display: inline-block;'
      // h += 'word-break: break-all;';
      // h += 'white-space: pre-wrap;';
      h += this.urlPath === '/convert-view' ? 'user-select:none;' : ''
      h += 'font-family: FZLTHJW;'
      h += 'background-color:white'
      h += '">'
      h += '<DIV ID="viewer"></DIV>'
      h += '</BODY>'
      h += '</HTML>'
      doc.open()
      doc.write(h)
      doc.close()
      this.msgContent.doc = doc
      // console.log(viewIframe, doc, 'kkkkk')

      // this.$refs.mailbody.append(this.bodys)
      iframeEventPop(doc) // 解除iframe的事件
      const _this = this
      // 获取审核邮件详情
      if (_this.$myData.uriModule === '/convert-view') {
        // this.showBody() // 安全邮件属性页面
        _this.submitMailFun()
      } else {
        _this.getMail() // 第三方api页面
      }
    },
    showBody() {
      // 给iframe里面送值
      const viewer = this.msgContent.doc.getElementById('viewer') || this.msgContent.doc.createElement('div')
      // console.log(this.bodys, 'this.bodys')
      viewer.innerHTML = this.bodys
      this.showMsg_viewer_height()
    },
    // iframe的宽高
    showMsg_viewer_height() {
      const viewIframe = this.$refs['iframe']
      if (viewIframe) {
        const doc = viewIframe.contentWindow.document || '<div></div>'
        viewIframe.height = doc.body.scrollHeight < 500 ? 500 : doc.body.scrollHeight
      }

      // 拦截a链接
      this.listenLink()
      // console.log(viewIframe.scrollHeight, '高度', doc.body.scrollHeight)
      // viewIframeWidth.value = doc.body.scrollWidth + "px";
      // viewIframeHeight.value = doc.body.scrollHeight + "px";
      // let contentWrap: any = document.getElementById(contentWrapId.value);
      // let h = contentWrap.clientHeight - viewIframe.offsetTop;
      // if (h < 100) {
      //   h = 100;
      // }
      // viewIframeMinHeight.value = h + "px";
    },
    // 是否展开详情
    Unfold(type) {
      type ? this.isUnfold = false : this.isUnfold = true
    },
    // 子组件密码邮件变化
    mailPasswordFun(e) {
      if (!e[0]) {
        this.$message.error(this.$t('message.view.psw_error'))
      } else {
        this.mail_psw = e[1]
        this.getMail()
        this.showMailPasswordDialog = false
      }
    },
    downMail() {
      // 下载邮件
      const req = {
        disposition: 'attachment',
        mail_seclevel: this.bodyHtml.mail_seclevel,
        mbox: 'INBOX',
        message_id: this.bodyHtml.message_id,
        module: 'mail_attachment',
        section: 'self',
        // subject: encodeURI(this.bodyHtml.subject.replace(/[\/ ?\t\r\n:\|\\*\"\'<>]/g, ' ')) + '.eml',
        subject: this.bodyHtml.subject + '.eml',
        uid: this.bodyHtml.uid,
        uv: this.bodyHtml.uv,
      }
      this.$refs.downMailForm.formLabelAlign.req_data = serialize(req)
      this.$refs.downMailForm.down(this.bodyHtml.subject + '.eml')
    },
    // 安全邮件的预览
    auditDownAtta(type, el) {
      const path = '/nsmail/mail.php?nsnode=' + this.nsnode + '&task=convert_mail&req={' + '"module":"mail","require":{"action":"preConvAttachment","token":"' + this.token + '","index": "' + el.index + '"}}'
      return path
    },
    // 邮件页面打包下载
    downAtta(type, el) {
      this.downAction(type, el)
    },

    // 邮件页面打包下载
    downAction(type, el) {
      this.isAttaDown = true
      const disposition = el === 'allDown' ? 'attachment' : el.disposition
      let name = this.attachments[0].name
      const index = name.lastIndexOf('.')
      name = name.substring(0, index)

      if (el === 'allDown') {
        // 打包下载
        this.downAttaReq = {
          list: this.attachments,
          module: 'mail_attachment',
          att_type: 'multi',
          disposition: 'attachment',
          from_type: 'mail',
          attachment_name: name + '.zip',
          uid: this.bodyHtml.uid,
          uv: this.bodyHtml.uv,
          mbox: 'INBOX',
          subject: this.bodyHtml.subject,
        }
      } else {
        // 单个下载
        this.downAttaReq = {
          module: 'mail_attachment',
          atta_seclevel: el.atta_seclevel,
          attachment_name: el.name,
          encoding: el.encoding,
          mbox: 'INBOX',
          message_id: this.bodyHtml.message_id,
          secret_terminal: 0,
          section: el.section,
          uid: this.bodyHtml.uid,
          uv: this.bodyHtml.uv,
        }
        if (type === 'preview') {
          this.downAttaReq.preview = 1
          this.downAttaReq.disposition = 'inline'
          this.downAttaReq.suffix = el.name.substring(el.name.lastIndexOf('.') + 1).toLowerCase()
        } else {
          this.downAttaReq.disposition = disposition
        }
      }
    },
    // 点击关闭撤回弹窗-自动跳转到下一封
    closeDialog() {
      this.dialogVisible = false
    },
    // 是否点击a链接
    attackFishing(type) {
      window.open(this.urlOrgin)
    },
    // 通知信点击审核跳转
    auditLinkUrl(url) {
      /** 跳转读信 */
      const req = {
        token: getQueryVariable.url('token', url),
        nsnode: getQueryVariable.url('nsnode', url),
        isAudit: getQueryVariable.url('isAudit', url),
        client_from: getQueryVariable.url('client_from', url),
      }
      // this.$myApi.mainTab.showWholeMail('audit', req)
      window.open(window.location.origin + '/#/audit?' + JSON.stringify(req))
    },
    // 拦截
    async listenLink() {
      // const body = this.$refs.mailbody
      // const body = this.msgContent.doc.getElementById('viewer')
      var body = this.msgContent.doc.getElementById('viewer').getElementsByTagName('A')
      for (let index = 0; index < body.length; index++) {
        body[index].addEventListener('click', async(e) => {
          e.preventDefault()
          // window.event.returnValue = false// IE
          // if (e.preventDefault) {
          // e.preventDefault()
          this.urlOrgin = body[index].href
          if (this.isPrintMail) {
            return
          }
          if (getQueryVariable.url('isAudit', this.urlOrgin)) {
            // 如果是审核通知信地址
            this.auditLinkUrl(this.urlOrgin)
          } else {
            this.attackFishing('url')
          }
          // } else {
          //   window.event.returnValue = false// IE
          //   // 注意：这个地方是无法用return false代替的
          // }
        })
      }
    },
    timeDispose(dates) {
      if (dates < 10) {
        return '0' + dates
      } else {
        return dates
      }
    },
    gettime(item) {
      var getTimes = new Date(parseInt(item) * 1000)
      var monthGet = getTimes.getMonth() + 1
      var time = getTimes.getFullYear() + '/' + this.timeDispose(monthGet) + '/' + this.timeDispose(getTimes.getDate()) + ' ' + this.timeDispose(getTimes.getHours()) + ':' + this.timeDispose(getTimes.getMinutes())
      return time
    },
    sendCodeFun() {
      const req = {
        'module': 'mail',
        'require': {
          action: 'convertCode',
          token: this.token,
        },
      }
      this.$axios.post(this.$apiData.convert_mail + '&nsnode=' + this.nsnode + '&time=' + new Date() * 1, { req: serialize(req) }).then(res => {
        if (res.result === true) {
          this.$message.success(this.$t('message.view.code_already_send'))
        }
      })
    },

    submitMailFun() {
      // const name = this.message_id + '_convert_code'
      if (this.sendYzmVisible && this.yzmInpuy === '') {
        this.$message.error(this.$t('message.login.placeholder4'))
        return
      }
      const req = {
        'module': 'mail',
        'require': {
          action: 'convertGetMail',
          token: this.token,
          code: this.yzmInpuy,
          // cookie: getCookie(name) ? name : '',
        },
      }

      this.$axios.post(this.$apiData.convert_mail + '&nsnode=' + this.nsnode + '&time=' + new Date() * 1, { req: serialize(req) }).then(res => {
        // console.log(res, '读信')

        this.sendYzmVisible = false
        // this.loading = false
        if (res.status === 60003) {
          // 弹出验证码框
          this.sendYzmVisible = true
          return false
        }
        if (res.status === 50001) {
          // 已过期或者撤回
          this.loading = false
          this.audit_expired = true
          this.audit_expired_html = res.msg
          return false
        }
        this.isHtml = true
        // this.bodyHtml = res.result
        // this.bodyHtml.from_name_l = res.result.from
        this.bodyHtml.to_s = res.result.to
        this.bodyHtml.cc_s = res.result.cc
        this.bodyHtml.attachment = res.result.attachment
        this.bodyHtml.from_name_l = res.result.from
        this.bodyHtml.bodys = res.result.body
        this.bodyHtml.expire = res.result.expire
        this.bodyHtml.envelope_date = res.result.date
        this.bodyHtml.subject = res.result.subject
        this.bodyHtml.att_size = res.result.att_size
        this.bodyHtml.readed_destory = res.result.readed_destory
        this.bodys = DOMPurify.sanitize(this.bodyHtml.bodys)
        this.to_s = res.result.to
        this.cc_s = res.result.cc
        // this.bcc_s = this.bodyHtml.bcc_s
        this.attachments = res.result.attachment
        this.form = res.result.from
        // this.showMsg_bodys_document()

        this.loading = false
        // 显示
        this.showBody()

        // 是否显示内嵌图片
        const domparser = new DOMParser()
        var doc = domparser.parseFromString(this.bodys, 'text/html')
        const arr = doc.body.getElementsByTagName('img')
        this.show_always_img(arr)
      })
    },

    scrollAtta() {
      var element = document.getElementById('mailboxAll') // 获取需要滚动的元素
      element.scrollTop = this.$refs.view_atta_box.offsetTop // 滚动到距离顶部100像素的位置
    },
  },
}
