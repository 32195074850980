<template>
  <div
    ref="dragArea"
    :class="['drag',ishaveatt?'':'',ondropClass?'dragover':'']"
    @dragenter="dragenter"
    @dragleave="dragleave"
    @dragover="dragover"
    @drop="dragdrop"
    @paste="pasteMe"
  >
    <div class="drag__text">{{ draginnertext }}</div>
    <input
      ref="uploadInput"
      type="file"
      name="file"
      multiple="multiple"
      style="display:none"
    >
  </div>
</template>

<script>
function noop() {}
export default {
  inject: ['superParams'],
  props: {
    draginnertext: {
      type: String,
      default: '',
    },
    onDrag: {
      type: Function,
      default: noop,
    },
  },
  data() {
    return {
      ondropClass: false,
      ishaveatt: false,
    }
  },
  watch: {
    'superParams.postFiles': {
      immediate: true,
      handler(list) {
        this.ishaveatt = list.length > 0
      },
    },
  },
  mounted() {
  },
  methods: {
    dragenter(e) {
      e.preventDefault()
      this.ondropClass = true
    },
    dragleave(e) {
      e.preventDefault()
      this.ondropClass = false
    },
    dragover(e) {
      e.preventDefault()
    },
    dragdrop(e) {
      e.preventDefault()
      this.ondropClass = false
      const items = e.dataTransfer.files
      this.onDrag(items)
    },
    pasteMe(e) {
      var clipboardData = e.clipboardData // IE
      if (!clipboardData) {
        // chrome
        clipboardData = e.originalEvent.clipboardData
      }
      var items = ''
      items = (e.clipboardData || window.clipboardData).items
      let file = null
      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地或请打开图片再复制')
        return
      }
      // 搜索剪切板items
      const fileData = []
      for (let i = 0; i < items.length; i++) {
        // if (items[i].type.indexOf('image') !== -1) {//在这里可做文件类型过滤
        file = items[i].getAsFile()
        fileData.push(file)
        // break;
        // }
      }
      if (file) { // 对复制黏贴的类型进行判断，若是非文件类型，比如复制黏贴的文字，则不会调用上传文件的函数
        this.onDrag(fileData)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.drag{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border: 1px dashed #d9d9d9;
    background-color: #fbfbfb;
    border-radius: 2px;
    cursor: pointer;
    transition: height .3;
    .drag__text{
        text-align: center;
        color: #c5c5c5;
        font-weight: bold;
    }
}

.haveatts{
          height: 100px;
      }
</style>
