<template>
  <div
    v-if="selfActived"
    class="calendar-container"
  >
    <el-container>
      <el-aside style="width: 260px;position: relative; overflow:hidden;border-right: 1px solid rgb(220, 220, 220);">
        <div class="top-btn cur">
          <div
            class="button theme-background-color"
            @click="createEvent"
          >
            <i class="el-icon-plus" />
            {{ $t('message.calendar.info3') }}
          </div>
          <div class="menu theme-background-color">
            <el-popover
              v-model="showDropMenu"
              width="200"
              trigger="hover"
              popper-class="calendarproper"
            >
              <div
                class="el-select-dropdown__item"
                style="text-align: center;color:#1d1b1b"
                @click="fullCreate(1)"
              > {{ $t('message.calendar.info4') }}</div>
              <div
                class="el-select-dropdown__item"
                style="text-align: center;color:#1d1b1b;border-top: 1px solid #e9e9e9;"
                @click="fullCreate(0)"
              > {{ $t('message.calendar.info5') }}</div>
              <div
                slot="reference"
                class="el-icon-arrow-down"
              />
            </el-popover>

          </div>
        </div>
        <div class="tagarea">
          <div
            ref="myShareList"
            class="my-calendar"
          >
            <div class="top-line">
              <div>
                <i
                  :class="['el-icon-caret-right',showMyList?'rotate360':'rotate0']"
                  @click="play"
                />
                <span>{{ $t('message.calendar.info6') }}</span>
              </div>
              <div>
                <span class="tail-icon">
                  <span class="tail-icon-item">
                    <svg-icon
                      icon-class="add"
                      class-name="compose-icon"
                      @click="handleClick"
                    />
                  </span>
                </span>
              </div>
            </div>
            <el-collapse-transition>
              <el-tree
                v-show="showMyList"
                ref="mytree"
                :data="calendarTagsList"
                node-key="tag_id"
                :empty-text="$t('message.calendar.info7')"
                :props="defaultProps"
                :allow-drop="allowDrop"
                :show-checkbox="false"
                :highlight-current="false"
                @node-click="handleNodeClick"
              >
                <div
                  slot-scope="{ node,data }"
                  class="tree-node"
                >
                  <div
                    v-if="isEdit===data.tag_id"
                    v-ClickOutSide="closeEdit"
                    class="createlist"
                    style="padding: 0;"
                  >
                    <el-input
                      v-model="editTagName"
                      size="mini"
                      :placeholder="$t('message.calendar.info8')"
                      maxlength="128"
                      show-word-limit
                      class="tagsinput"
                    />
                    <i
                      class="el-icon-circle-check cur"
                      style="font-size: 19px;color: #909399;margin: 0 8px"
                      @click="saveEdit(data)"
                    />
                    <i
                      class="el-icon-circle-close cur"
                      style="font-size: 19px;color: #909399;"
                      @click="closeEdit"
                    />
                  </div>

                  <div
                    v-show="isEdit!==data.tag_id"
                    class="tagscolor-con"
                  >
                    <span
                      class="tagscolor"
                      :style="{backgroundColor:data.tag_color,marginRight:'3px',width:$store.state.app.userGetConfig.font_size+'px',height:$store.state.app.userGetConfig.font_size+'px'}"
                      @click="myvisibleChange(data)"
                    ><i
                      v-show="data.is_show==='1'"
                      class="el-icon-check"
                      :style="{color:'#fff',fontSize:$store.state.app.userGetConfig.font_size+'px'}"
                    /></span>
                    <span
                      v-title="node.label"
                      class="title"
                    >{{ node.label }}</span>
                  </div>

                  <el-popover
                    placement="right"
                    trigger="hover"
                    :close-delay="0"
                    popper-class="calendarclass"
                  >
                    <ul style="width:105px;margin: auto;">
                      <li
                        class="el-select-dropdown__item"
                        style="text-align: center;"
                        @click="newCreateEvent(data)"
                      >{{ $t('message.calendar.info4') }}</li>
                      <li
                        v-if="data.tag_type!=='1'"
                        class="el-select-dropdown__item"
                        style="text-align: center;"
                        @click="renameCalendar(data)"
                      >{{ $t('message.calendar.info9') }}</li>
                      <li
                        v-if="data.tag_type!=='1'"
                        class="el-select-dropdown__item"
                        style="text-align: center;"
                        @click="delEventTags(data.tag_id)"
                      >{{ $t('message.calendar.cal42') }}</li>
                      <li>
                        <div
                          class="tagscolor-con"
                          style="flex-wrap: wrap;"
                        >
                          <span
                            v-for="(item,index) in eventColor"
                            :key="index"
                            class="tagscolor"
                            :style="{backgroundColor:item,margin:'3px'}"
                            @click="selectColor(data,index)"
                          />
                        </div>
                      </li>
                    </ul>
                    <span
                      v-show="isEdit!==data.tag_id"
                      slot="reference"
                    >
                      <svg-icon
                        v-show="slevent===data.tag_id"
                        icon-class="menu"
                        class-name="compose-icon"
                      />
                    </span>

                  </el-popover>
                </div>
              </el-tree>
            </el-collapse-transition>
            <div
              v-if="showCreate"
              v-ClickOutSide="closeCreate"
              class="createlist"
            >
              <el-input
                v-model="tagName"
                size="mini"
                :placeholder="$t('message.calendar.info8')"
                maxlength="128"
                show-word-limit
                class="tagsinput"
              />
              <i
                class="el-icon-circle-check cur"
                style="font-size: 19px;color: #909399;margin: 0 8px"
                @click="createTags"
              />
              <i
                class="el-icon-circle-close cur"
                style="font-size: 19px;color: #909399;"
                @click="closeCreate"
              />
            </div>
          </div>
          <div class="my-calendar">
            <div class="top-line">
              <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                <i
                  :class="['el-icon-caret-right',showSubscribeList?'rotate360':'rotate0']"
                  @click="playSubscribe"
                />
                <span>{{ $t('message.calendar.info10') }}</span>
              </div>
            </div>
            <el-collapse-transition>
              <el-tree
                v-show="showSubscribeList"
                ref="subscribetree"
                class="newtree"
                style="margin-top: 5px;"
                :data="calendarSubTagsList"
                node-key="tag_id"
                :empty-text="$t('message.calendar.info11')"
                :props="defaultProps2"
                :highlight-current="false"
                @node-click="handleSubClick"
              >
                <div
                  slot-scope="{ node,data }"
                  class="tree-node"
                  :title="`${$t('message.calendar.info12')}:${data.from_mail}`"
                >
                  <div
                    v-if="isSubEdit===data.from_id"
                    v-ClickOutSide="closeSubEdit"
                    class="createlist"
                    style="padding: 0;"
                  >
                    <el-input
                      v-model="editSubTagName"
                      size="mini"
                      :placeholder="$t('message.calendar.info8')"
                      maxlength="128"
                      show-word-limit
                      class="tagsinput"
                    />
                    <i
                      class="el-icon-circle-check cur"
                      style="font-size: 19px;color: #909399;margin: 0 8px"
                      @click="saveSubEdit(data)"
                    />
                    <i
                      class="el-icon-circle-close cur"
                      style="font-size: 19px;color: #909399;"
                      @click="closeSubEdit"
                    />
                  </div>

                  <div
                    v-show="isSubEdit!==data.from_id"
                    class="tagscolor-con"
                    :title="data.from_mail"
                  >
                    <span
                      class="tagscolor"
                      :style="{backgroundColor:data.color,marginRight:'3px',width:$store.state.app.userGetConfig.font_size+'px',height:$store.state.app.userGetConfig.font_size+'px'}"
                      @click="subvisibleChange(data)"
                    ><i
                      v-show="data.is_show==='1'"
                      class="el-icon-check"
                      style="color:#fff"
                    /></span>
                    <span
                      v-title="node.label"
                      class="title"
                    >{{ node.label }}</span>
                  </div>

                  <el-popover

                    placement="right"
                    trigger="hover"
                    :close-delay="0"
                    popper-class="calendarclass"
                  >
                    <ul style="width:105px;margin: auto;">
                      <li
                        v-if="data.privilege===2"
                        class="el-select-dropdown__item"
                        style="text-align: center;"
                        @click="createSubscribeEvent(data)"
                      >{{ $t('message.calendar.info13') }}</li>
                      <li
                        class="el-select-dropdown__item"
                        style="text-align: center;"
                        @click="renameSubCalendar(data)"
                      >{{ $t('message.calendar.info9') }}</li>
                      <li
                        class="el-select-dropdown__item"
                        style="text-align: center;"
                        @click="cancelSubcribeTag(data)"
                      >{{ $t('message.calendar.info14') }}</li>
                      <li>
                        <div
                          class="tagscolor-con"
                          style="flex-wrap: wrap;"
                        >
                          <span
                            v-for="(item,index) in eventColor"
                            :key="index"
                            class="tagscolor"
                            :style="{backgroundColor:item,margin:'3px'}"
                            @click="selectSubscribeColor(data,index)"
                          />
                        </div>
                      </li>
                    </ul>
                    <span
                      v-show="isSubEdit!==data.from_id"
                      slot="reference"
                    >
                      <svg-icon
                        v-show="subSlevent===data.from_id"
                        icon-class="menu"
                        class-name="compose-icon"
                      />
                    </span>

                  </el-popover>
                </div>
              </el-tree>
            </el-collapse-transition>
          </div>
        </div>
        <calendar
          ref="calendar"
          :on-select="selectedDate"
        />
      </el-aside>
      <el-main class="mian-container">
        <div style="min-width:1100px">

          <Fullcalendar
            v-show="fullEdit===1"
            ref="fullcalendar"
            :get-time="getTimeStamp"
            :get-range-time="getRangeTime"
            @getCardInfo="getEventDetail"
            @on-share="openSharePage"
          />
          <div
            v-show="fullEdit===2"
            class="fulledit"
          >
            <div class="calendarheader">
              <div v-if="!editStatus">
                <el-button
                  type="primary"
                  @click="saveFullCreateEvent"
                >{{ isMetting?$t('message.calendar.info15'):$t('message.calendar.info16') }}</el-button>
                <el-button @click="switchMetting">{{ isMetting?$t('message.calendar.info17'):$t('message.calendar.info18') }}</el-button>
              </div>
              <div v-else>
                <el-button
                  type="primary"
                  @click="saveEditEvent(isMettingEdit)"
                >{{ isMettingEdit?$t('message.calendar.info19'):$t('message.calendar.info20') }}</el-button>
                <el-button
                  v-if="showtoggleEdit"
                  @click="switchEdit"
                >{{ toggleEdit?$t('message.calendar.info18'):$t('message.calendar.info18') }}</el-button>
              </div>
              <svg-icon
                icon-class="cal_close"
                class="cur"
                style="margin-left: 10px;"
                @click="cancelCreatEvent"
              />
            </div>
            <div style="padding: 0 20px;">
              <div
                v-show="isMetting||isMettingEdit"
                class="border-bottom to"
              >
                <div class="mail-info-header">
                  <span
                    style="white-space: nowrap;"
                    class="underline compose-font-colo cal_time text-hover cur"
                    @click="openContacts('to')"
                  >{{ $t('message.calendar.info21') }}</span>
                </div>
                <div
                  class="mail-info-header1 address"
                  style="flex:1"
                >
                  <EmailAddress
                    ref="tagsto"
                    v-model="tagsto"
                    @on-more="selectMore"
                  />
                </div>
                <div class="mail-info-header2">
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="$t('message.calendar.info22')"
                    placement="top"
                  >
                    <span class="tail-icon">
                      <span class="tail-icon-item">
                        <svg-icon
                          icon-class="add"
                          class-name="compose-icon"
                          @click="openContacts('to')"
                        />
                      </span>
                    </span>
                  </el-tooltip>
                </div>
              </div>
              <div class="line border-bottom">
                <div class="mail-info-header">
                  <span
                    class="compose-font-colo "
                    v-html="$t('message.calendar.info23')"
                  />
                </div>
                <div class="mail-info-header1 address">
                  <input
                    v-model="fullcCarlendarReq.subject"
                    maxlength="256"
                    type="text"
                    class="mail-input"
                    style="font-weight: bold;"
                  >
                </div>
                <div class="mail-info-header2">
                  <EventSelect
                    v-model="selectedTag"
                    :tags="eventTags"
                  />
                </div>
              </div>
              <div class="line border-bottom">
                <div class="mail-info-header">
                  <span
                    class="compose-font-colo "
                    v-html="$t('message.calendar.info24')"
                  />
                </div>
                <div class="mail-info-header1 address">
                  <input
                    v-model="fullcCarlendarReq.location"
                    type="text"
                    class="mail-input"
                  >
                </div>
                <div class="mail-info-header2" />
              </div>
              <div class="line border-bottom">
                <div class="mail-info-header ">
                  <span
                    class="compose-font-colo cal_time"
                    v-html="$t('message.calendar.info25')"
                  />
                </div>
                <div class="mail-info-header1 address">
                  <el-date-picker
                    v-model="datetime"
                    style="width: 500px"
                    :default-time="defaultTime"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    type="datetimerange"
                    :range-separator="$t('message.calendar.info28')"
                    :start-placeholder="$t('message.calendar.info26')"
                    :end-placeholder="$t('message.calendar.info27')"
                  />
                  <el-checkbox
                    v-model="fullcCarlendarReq.all_day_type"
                    style="margin-left: 20px;"
                    true-label="1"
                    false-label="0"
                    @change="changeFullDay"
                  >{{ $t('message.calendar.info29') }}</el-checkbox>
                </div>
                <div class="mail-info-header2" />
              </div>
              <div class="line border-bottom">
                <div class="mail-info-header">
                  <span
                    class="compose-font-colo "
                    v-html="$t('message.calendar.info30')"
                  />
                </div>
                <div class="mail-info-header1 address">
                  <el-radio
                    v-model="fullcCarlendarReq.transp"
                    label="FREE"
                  >{{ $t('message.calendar.info31') }}</el-radio>
                  <el-radio
                    v-model="fullcCarlendarReq.transp"
                    label="BUSY"
                  >{{ $t('message.calendar.info32') }}</el-radio>
                  <el-radio
                    v-model="fullcCarlendarReq.transp"
                    label="TENTATIVE"
                  >{{ $t('message.calendar.info33') }}</el-radio>
                  <el-radio
                    v-model="fullcCarlendarReq.transp"
                    label="OOF"
                  >{{ $t('message.calendar.info34') }}</el-radio>
                  <el-radio
                    v-model="fullcCarlendarReq.transp"
                    label="WORKINGELSEWHERE"
                  >{{ $t('message.calendar.info35') }}</el-radio>
                </div>
                <div class="mail-info-header2" />
              </div>
              <div class="line border-bottom">
                <div class="mail-info-header">
                  <span
                    class=" compose-font-colo "
                    v-html="$t('message.calendar.info36')"
                  />
                </div>
                <div class="mail-info-header1 address">
                  <el-select
                    v-model="tipsCycle"
                    style="width: 300px"
                    :placeholder="$t('message.calendar.info37')"
                    size="mini"
                    @change="setNoice"
                  >
                    <el-option
                      v-for="item in tipsModel"
                      :key="item.value"
                      :label="$t(`message.calendar.${item.label}`)"
                      :value="item.value"
                    />
                  </el-select>

                  <el-button
                    type="text"
                    @click="timeCycle=!timeCycle"
                  >{{ timeCycle?$t('message.calendar.info38'):$t('message.calendar.info39') }}</el-button>
                </div>
                <div class="mail-info-header2" />
              </div>
              <ActivityCycle
                v-show="timeCycle"
                ref="cycleTime"
              />
              <div
                v-show="isMetting||isMettingEdit"
                class="line"
                style="justify-content: flex-start"
              >

                <div class="mail-info-header">
                  <span
                    class="annex-title compose-font-colo"
                    @click="openUpDia"
                  >
                    {{ $t('message.calendar.info40') }}</span>
                  <span class="no-annex compose-font-colo">{{ attachment.length===0?$t('message.calendar.info41'):$t('message.calendar.info41',{num:attachment.length}) }}</span>
                </div>
              </div>
              <div
                v-if="attachment.length!==0"
                class="attachment"
              >
                <div
                  v-for="item in attachment"
                  :key="item.upid"
                  class="attachment-item"
                >
                  <div style="display: flex">
                    <div class="attachment-icon">
                      <svg-icon
                        :icon-class="getFileType(item.name)+'icon'"
                        class-name="atticon"
                      />
                      <div
                        v-if="item.size>$store.state.app.upload_mode_limit&&item.type==='splitupload'"
                        class="tag"
                      >{{ $t('message.calendar.info43') }}</div>
                    </div>
                    <div class="attachment-info">
                      <div style="display:flex">
                        <span class="title">{{ item.name }}</span>
                        <span
                          class="size"
                          style="color: #8E8D8D;"
                        >
                          <span>({{ item.size|mailSizefilter }})</span>
                        </span>
                      </div>
                      <div
                        style="display:flex"
                        class="bottom"
                      >
                        <!-- <span
                        v-if="isPreView(item.name)"
                        class="delete text-color el-button--text"
                        @click="previewAtta(item)"
                      >预览</span> -->

                        <span
                          class="delete text-color el-button--text"
                          @click="downloadAtt(item)"
                        >{{ $t('message.calendar.info44') }}</span>

                        <span
                          class="delete text-color el-button--text"
                          @click="handleRemove(item)"
                        >{{ $t('message.calendar.cal42') }}</span>
                        <span
                          v-if="['pause','error'].includes(item.status)"
                          class="delete text-color el-button--text"
                          @click="handleResumeUpload(item)"
                        >{{ $t('message.calendar.info45') }}</span>
                        <span v-if="item.hashProgress!==100&&item.type==='splitupload'">{{ $t('message.calendar.info69',{num:item.hashProgress}) }}</span>
                        <el-progress
                          v-if="item.hashProgress==100&&item.percentage!==100"
                          :stroke-width="3"
                          :percentage="item.percentage"
                          style="flex:1"
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <UploadFile
                ref="uploadfiles"
                v-model="attachment"
              />
              <div>
                <tinymce
                  ref="ED"
                  v-model="fullcCarlendarReq.desc"
                />
              </div>

            </div>
          </div>
          <div v-show="fullEdit===3">
            <div class="share-header">
              <div class="con">
                <div
                  :class="['menu',!myshare?'selected':'']"
                  class="menu"
                  @click="myshare=false"
                >{{ $t('message.calendar.info46') }}</div>
                <div
                  :class="['menu' ,myshare?'selected':'']"
                  @click="myshare=true;showDetail=false"
                >{{ $t('message.calendar.info47') }}</div>
              </div>
              <div class="share-top-btn">
                <el-button
                  type="primary"
                  @click="fullEdit=1"
                >{{ $t('message.calendar.info48') }}</el-button>
              </div>

            </div>
            <div
              v-show="myshare"
              style="padding: 10px 20px;margin-top:15px"
            >
              <div
                v-show="showDetail"
                class="share-detail"
              >
                <div class="title"><b :title="detailTitle.length>25?detailTitle:''">"{{ detailTitle|handelCharA }}"{{ $t('message.calendar.info70') }}</b></div>
                <div class="search">
                  <el-input
                    v-model="searchShare"
                    :placeholder="$t('message.calendar.info49')"
                  />
                </div>
              </div>
              <el-table
                v-show="showDetail"
                ref="multipleTable1"
                size="mini"
                border
                :data="shareUserList.filter(data => !searchShare || data.user_name.toLowerCase().includes(searchShare.toLowerCase())||data.user_mail.toLowerCase().includes(searchShare.toLowerCase()))"
                :height="scrollerHeight1"
                tooltip-effect="light"
                row-key="to_user_id"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  :reserve-selection="true"
                  width="55"
                />
                <el-table-column
                  prop="tag_name"
                  :label="$t('message.calendar.info50')"
                >
                  <template slot-scope="scope">{{ `${scope.row.user_name}<${scope.row.user_mail}>` }}</template>
                </el-table-column>

                <el-table-column
                  prop="privilege"
                  :label="$t('message.calendar.info51')"
                >
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.privilege"
                      :placeholder="$t('message.calendar.info37')"
                      size="mini"
                    >
                      <el-option
                        v-for="item in privilegeList"
                        :key="item.value"
                        :label="$t(`message.calendar.${item.label}`)"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  :label="$t('message.calendar.info52')"
                  align="center"
                  width=""
                  show-overflow-tooltip
                >
                  <template slot-scope="{row}">
                    <el-button
                      type="text"
                      @click="cancelShare(row)"
                    >{{ $t('message.calendar.cal42') }}</el-button>
                    <el-button
                      v-if="row.share_url"
                      type="text"
                      style="padding-left: 0!important;"
                      @click="viewShareLink(row,false)"
                    >{{ $t('message.calendar.view_link') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div v-show="showDetail">
                <div class="addshareuser">
                  <span @click="dialogShare=true"><i class="el-icon-plus" />{{ $t('message.calendar.info54') }}</span>
                  <el-button
                    type="text"
                    :disabled="multipleSelection.length===0"
                    @click="showChangeAuth=true"
                  >{{ $t('message.calendar.change_auth') }}</el-button>
                  <el-button
                    type="text"
                    :disabled="multipleSelection.length===0"
                    @click="deleteRows"
                  >{{ $t('message.calendar.info53') }}</el-button>
                </div>

                <div class="addsharebtn">
                  <el-button
                    style="padding: 6px 12px!important;"
                    type="primary"
                    @click="saveShareUser"
                  >{{ $t('message.btnText.save') }}</el-button>
                  <el-button @click="showDetail=false">{{ $t('message.calendar.info56') }}</el-button>
                </div>
              </div>
              <el-table
                v-show="!showDetail"
                ref="multipleTable"
                size="mini"
                border
                :data="myCreateShareList"
                :height="scrollerHeight"
                tooltip-effect="light"
                @row-click="goShareDetail"
              >
                <el-table-column
                  prop="tag_name"
                  :label="$t('message.calendar.info57')"
                  show-overflow-tooltip
                />

                <el-table-column
                  prop="count"
                  :label="$t('message.calendar.info58')"
                >
                  <template slot-scope="scope">{{ $t('message.calendar.info59',{num:scope.row.count}) }}</template>
                </el-table-column>
                <el-table-column
                  :label="$t('message.calendar.info52')"
                  align=""
                  width="100"
                >
                  <template slot-scope="">
                    <el-button
                      type="text"
                      style="padding-left: 0!important;"
                    >{{ $t('message.calendar.info60') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              v-show="!myshare"
              style="padding: 0 20px"
            >
              <div style="padding: 10px 0;">
                <el-button
                  type="primary"
                  :disabled="myReieveShareList.length===0"
                  @click="ignoreAllShare"
                >{{ $t('message.calendar.info61') }}</el-button>
              </div>
              <el-table
                ref="multipleTable"
                size="mini"
                :data="myReieveShareList"
                :height="scrollerHeight2"
                border
                tooltip-effect="light"
                style="width: 100%"
              >
                <el-table-column
                  :label="$t('message.calendar.info62')"
                >
                  <template slot-scope="scope">{{ scope.row.share_from }}</template>
                </el-table-column>
                <el-table-column
                  prop="tag_name"
                  :label="$t('message.calendar.info63')"
                />
                <el-table-column
                  prop="privilege"
                  :label="$t('message.calendar.info64')"
                />
                <el-table-column
                  prop="address"
                  :label="$t('message.calendar.info52')"
                  show-overflow-tooltip
                >
                  <template slot-scope="{row}">
                    <el-button
                      v-if="row.status==='0'||row.status==='2'"
                      type="text"
                      style="padding-left: 0!important;"
                      @click="acceptIgnore(row,1)"
                    >{{ $t('message.calendar.cal34') }}</el-button>
                    <el-button
                      v-if="row.status==='0'||row.status==='1'"
                      type="text"
                      style="padding-left: 0!important;"
                      @click="acceptIgnore(row,2)"
                    >{{ $t('message.calendar.info65') }}</el-button>
                    <el-button
                      type="text"
                      style="padding-left: 0!important;"
                      @click="viewShareLink(row,true)"
                    >{{ $t('message.calendar.view_link') }}</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div v-show="fullEdit===4">
            <div class="calendarheader">
              <div>
                <h2 style="word-break: break-all;font-size: 16px;">{{ detailInfo.title||$t('message.calendar.cyle27') }}</h2>
              </div>
              <div class="detail-icon">
                <svg-icon
                  v-if="([1,2].includes(calendarType))&&meeting_status!=='6'&&(!detailInfo.privilege||detailInfo.privilege==='2')"
                  icon-class="cal_edit"
                  class="cur"
                  style="margin-left: 10px;"
                  @click="editDetailEvent"
                />
                <svg-icon
                  v-if="[5].includes(calendarType)&&detailInfo.privilege==='2'"
                  icon-class="cal_edit"
                  class="cur"
                  style="margin-left: 10px;"
                  @click="editDetailEvent"
                />
                <svg-icon
                  v-if="([1,2].includes(calendarType))&&meeting_status!=='6'&&(!detailInfo.privilege||detailInfo.privilege==='2')"
                  icon-class="cal_del"
                  class="cur"
                  style="margin-left: 10px;"
                  @click="delDetailEvent"
                />
                <svg-icon
                  v-if="[5].includes(calendarType)&&detailInfo.privilege==='2'"
                  icon-class="cal_del"
                  class="cur"
                  style="margin-left: 10px;"
                  @click="delDetailEvent"
                />
                <svg-icon
                  v-if="[3].includes(calendarType)&&meeting_status!=='6'"
                  icon-class="cal_del"
                  class="cur"
                  style="margin-left: 10px;"
                  @click="delreceiveMetting"
                />
                <svg-icon
                  icon-class="cal_close"
                  class="cur"
                  style="margin-left: 10px;"
                  @click="fullEdit=1"
                />
              </div>
            </div>
            <div style="padding:0 20px 20px">
              <div class="detail-title">
                <p>{{ $t('message.calendar.cal10') }}：{{ detailInfo.start_time }}{{ $t('message.calendar.info28') }}{{ detailInfo.end_time }}</p>
                <p>{{ $t('message.calendar.cal9') }}：{{ detailInfo.ky_location }}</p>
                <p>{{ $t('message.calendar.cal30') }}： {{ noticeReflet[detailInfo.ky_valarm_str]? $t(`message.calendar.${noticeReflet[detailInfo.ky_valarm_str]}`)||'-' :'---' }}</p>
                <p v-if="[2,3].includes(detailInfo.type)">{{ $t('message.calendar.cal31') }}：{{ detailInfo.ky_organizer[0]||'-' }}</p>
                <p v-if="[2,3].includes(detailInfo.type)">{{ $t('message.calendar.cal32') }}：{{ detailInfo.attendee }}</p>
                <div
                  v-if="detailInfo.type===2&&meeting_status!=='6'&&(!detailInfo.privilege||detailInfo.privilege==='2')"
                  class="accept-info"
                >
                  <div class="showfeedbacks">
                    <div class="tag">{{ $t('message.calendar.cal35',{num:feedbacks_status[0]}) }}<span style="color:#19AD19">{{ $t('message.calendar.cal34') }}</span>，{{ $t('message.calendar.cal35',{num:feedbacks_status[1]}) }}<span style="color:#E7A201">{{ $t('message.calendar.cal36') }}</span>，{{ $t('message.calendar.cal35',{num:feedbacks_status[2]}) }}<span style="color:#D1060A">{{ $t('message.calendar.cal37') }}</span></div>
                    <el-button
                      type="text"
                      @click="showfeedbacks=!showfeedbacks"
                    >{{ $t('message.calendar.cal38') }}</el-button>
                  </div>

                  <el-table
                    v-if="showfeedbacks"
                    :data="tableData"
                    border
                    size="mini"
                    style="width: 100%;margin-top: 10px;"
                    :show-header="false"
                    max-height="100"
                  >
                    <el-table-column
                      prop="mail"
                      :label="$t('message.calendar.cal39')"
                    />
                    <el-table-column
                      prop="feedback"
                      :label="$t('message.calendar.cal40')"
                      align="center"
                      width="150"
                    >
                      <template slot-scope="{row}">
                        <span :style="{color:feedbacks_color[row.feedback]}">{{ feedbacks[row.feedback] }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <button
                  v-if="meeting_status==='6'"
                  type="button"
                  class="btn1"
                >{{ $t('message.calendar.cal33') }}</button>
                <responseMetting
                  v-if="detailInfo.type===3&&meeting_status!=='6'"
                  :click-info="detailInfo"
                  :status="meeting_status"
                  @changeStatus="changeMettingStatus"
                />
              </div>
              <div
                class="detail-body"
                v-html="detailInfo.desc"
              />
              <!-- 预览附件 -->
              <attachment
                :attachments="detailInfo.attachments"
                @download="downloadAtt"
              />
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="$t('message.calendar.info13')"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      :modal-append-to-body="true"
      custom-class="createEvent-custom"
      :width="lang==='english'?'800px':'700px'"
      :before-close="handleCreateClose"
      @close="handleCreateClose"
    >
      <createEvent1
        ref="crateEventData"
        :tags="eventTags1"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="text"
          style="font-weight:bold"
          @click="fullEdotModel(false)"
        >{{ $t('message.calendar.info66') }}</el-button>
        <div>
          <el-button
            @click="fullEdotModel(true)"
          >{{ $t('message.calendar.info18') }}</el-button>
          <el-button
            type="primary"
            @click="saveCreateEvent"
          >{{ $t('message.calendar.info16') }}</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('message.calendar.info54')"
      :visible.sync="dialogShare"
      :append-to-body="false"
      :modal-append-to-body="false"
      :width="lang==='english'?'650px':'600px'"
      :before-close="handleClose"
      @closed="closedShare"
    >
      <el-form
        ref="shareRef"
        label-position="right"
        :label-width="lang==='english'?'150px':'100px'"
        :model="formLabelAlign"
      >
        <el-form-item
          :label="$t('message.calendar.info63')"
          prop="tag_id"
        >
          <span :title="detailTitle.length>25?detailTitle:''">{{ detailTitle|handelCharA }}</span>
        </el-form-item>
        <el-form-item :label="$t('message.calendar.info64')">
          <el-radio-group v-model="formLabelAlign.privilege_level">
            <el-radio :label="0">{{ $t('message.calendar.info71') }}</el-radio>
            <el-radio :label="1">{{ $t('message.calendar.info72') }}</el-radio>
            <el-radio :label="2">{{ $t('message.calendar.info73') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('message.calendar.info50')"
          prop="share_user_id"
        >
          <div>
            <el-input
              v-model="searchWord"
              v-debounce="searchUsers"
              :placeholder="$t('message.contact.placeholder')"
              size="mini"
              clearable
              class="searchInput"
              style="width: 200px;"
              @clear="clearSearch"
              @input="clearSearch(1)"
            />
            <div
              v-if="showSearch"
              v-click-outside="clickout"
              class="searchlists"
            >
              <div
                v-for="(item,index) in searchList"
                ref="optionsList"
                :key="index"
                :class="['dropdown__item']"
                class=""
                @click="SearchListadd(item)"
              >
                <div
                  v-title="`${ item.name}(${ item.mail})`"
                  style="color: rgb(51, 51, 51);font-weight: bold;margin-bottom:2px;overflow: hidden;text-overflow: ellipsis;"
                >{{ item.name }}({{ item.mail }})</div>
                <div style="color:#b4b4b4;">{{ item.path }}</div>
              </div>
            </div>

          </div>
          <div class="contact-con">
            <div class="title">{{ $t('message.contact.orgcontact') }}</div>
            <div>
              <el-tree
                v-if="dialogShare"
                ref="mettingtree"
                class="newtree"
                :props="defaultProps1"
                :load="loadNode"
                show-checkbox
                node-key="id"
                lazy
                :expand-on-click-node="false"
                @check-change="handleCheckChange"
                @check="ShareUserChange"
              >
                <span
                  slot-scope="{ node }"
                  class="tree-node"
                >
                  <span
                    v-title="node.label"
                    style="overflow: hidden;text-overflow: ellipsis;"
                  >{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
          </div>
          <div
            class="arrow"
            @click="pushmail"
          >
            <i
              class="el-icon-d-arrow-right"
              :class="[isHave?'text-color':'']"
              style="font-size: 20px;"
            />
          </div>
          <div class="contact-con">
            <div class="title">{{ $t('message.calendar.info67') }}</div>
            <div
              v-for="(item,index) in shareperlist"
              :key="item.id"
              class="mail"
            >
              <div
                v-title="item.name+item.mail"
                class="flex-title"
              >
                <span class="name">{{ item.name }}</span>&nbsp;&nbsp;<span class="mail-item">({{ item.mail }})</span>
              </div>
              <i
                class="el-icon-close showclose"
                @click="deMail(index)"
              />
            </div>

          </div>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogShare = false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="confirmShare"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <ContactsPopper
      ref="ContactsPopper"
      :mettinglist="tagsto"
      ismetting
      :title="$t('message.contact.participant')"
      @get-address-data="getAdressData"
    />
    <el-dialog
      :title="$t('message.calendar.info10')"
      :visible.sync="showSubscribeTags"
      :append-to-body="false"
      :modal-append-to-body="false"
      custom-class="createEvent-custom"
      width="400px"
      @closed="resetForm"
    >
      <el-form
        v-if="showSubscribeTags"
        ref="tagForm"
        :model="tagForm"
        :rules="tagRules"
        :inline="false"
        size="normal"
      >
        <el-form-item
          label=""
          prop="tag_name"
        >
          <el-input
            v-model="tagForm.tag_name"
            :placeholder="$t('message.calendar.info68')"
            maxlength="25"
            show-word-limit
            size="small"
          />
        </el-form-item>
        <el-form-item
          prop="tag_color"
          class="color-form-item"
        >
          <div class="tagscolor-con">
            <span
              v-for="(item,index) in eventColor"
              :key="index"
              class="tagscolor"
              :style="{backgroundColor:item}"
              @click="selectSubscribeColor(index)"
            >
              <i
                v-if="selectedColor===index"
                class="el-icon-check"
                style="color: #fff;line-height: 15px;"
              />
            </span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click=" showSubscribeTags= false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="acceptIgnore(acceptRow,1)"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('message.calendar.cal_link')"
      :visible.sync="showShareLink"
      :append-to-body="false"
      :modal-append-to-body="false"
      custom-class="copylink-custom"
      width="550px"
    >
      <p style="color:#f00">*{{ $t('message.calendar.view_indo') }}</p>
      <table>
        <tr v-show="isself">
          <td><nobr>{{ $t('message.calendar.info62') }}：</nobr></td>
          <td>{{ linkobj.share_from }}</td>
        </tr>
        <tr v-show="isself">
          <td><nobr>{{ $t('message.calendar.info63') }}：</nobr></td>
          <td>{{ linkobj.tag_name }}</td>
        </tr>
        <tr v-show="isself">
          <td><nobr>{{ $t('message.calendar.info64') }}：</nobr></td>
          <td>{{ linkobj.privilege }}</td>
        </tr>
        <tr>
          <td>{{ $t('message.calendar.share_link') }}：</td>
          <td>
            <el-input
              v-model="shareLink"
              disabled
              placeholder=""
              style="width:300px"
            /></td>
        </tr>
      </table>
      <div slot="footer">
        <el-button @click="copylink">{{ $t('message.netdisk.copylink') }}</el-button>
        <el-button
          type="primary"
          @click="showShareLink = false"
        >{{ $t('message.btnText.close') }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="$t('message.calendar.change_auth')"
      :visible.sync="showChangeAuth"
      width="400px"
      :append-to-body="false"
      :modal-append-to-body="false"
      custom-class="copylink-custom"
    >
      <table>
        <tr>
          <td><nobr>{{ $t('message.calendar.info51') }}：</nobr></td>
          <td>
            <el-select
              v-model="auth"
              :placeholder="$t('message.calendar.info37')"
              size="mini"
            >
              <el-option
                v-for="item in privilegeList"
                :key="item.value"
                :label="$t(`message.calendar.${item.label}`)"
                :value="item.value"
              />
            </el-select>
          </td>
        </tr>
      </table>
      <div slot="footer">
        <el-button @click="showChangeAuth = false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="confirmChange"
        >{{ $t('message.btnText.ok') }}</el-button>
      </div>
    </el-dialog>
    <downatt :is-show="isAttaDown" />
  </div>
</template>

<script>
import downatt from '@/views/home-view/components/calendar-work/components/downatt'
import { countCharacters } from '@/utils/utils'
import UploadFile from './components/UploadFile.vue'
import ClickOutSide from 'element-ui/src/utils/clickoutside'
import calendar from '@/views/home-view/components/calendar-work/components/calendar'
import Fullcalendar from '@/views/home-view/components/calendar-work/components/Fullcalendar'
import createEvent1 from './components/createEvent.vue'
import tinymce from '@/components/Tinymce/index.vue'
import EmailAddress from '@/views/compose/components/EmailAddress'
import ContactsPopper from '@/components/ContactPopper'
import request from '@/components/ContactPopper/request'
import calendarreq from '@/views/home-view/components/calendar-work/calendar'
import ActivityCycle from './components/activityCycle.vue'
import EventSelect from './components/eventSelect.vue'
import { transformClass, GetFileType } from '@/utils/utils'
import netdisk from '@/views/compose/components/netdisk'
import attachment from './components/attachment'
import responseMetting from './components/responseMetting.vue'
import { copy } from '@/utils/utils.js'
export default {
  directives: { ClickOutSide },
  components: { downatt, responseMetting, attachment, calendar, Fullcalendar, createEvent1, tinymce, EmailAddress, ContactsPopper, ActivityCycle, EventSelect, UploadFile },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
    handelCharA(val) {
      if (val.length > 25) {
        return val.substring(0, 25) + '...'
      } else {
        return val
      }
    },
  },
  mixins: [request, calendarreq, netdisk],
  data() {
    const checkColor = (rule, value, callback) => {
      if (this.selectedColor === null) {
        return callback(new Error(this.$t('message.calendar.info74')))
      } else {
        callback()
      }
    }
    return {
      multipleSelection: [],
      linkobj: {},
      showShareLink: false,
      shareLink: '',
      showChangeAuth: false,
      auth: '0',
      isself: true,
      selfActived: false,
      feedbacks_color: {
        0: '',
        1: '#19AD19',
        2: '#E7A201',
        3: '#D1060A',
      },
      feedbacks: {
        0: this.$t('message.calendar.fullcal'),
        1: this.$t('message.calendar.acbtn2'),
        2: this.$t('message.calendar.cal36'),
        3: this.$t('message.calendar.acbtn3'),
      },
      isAttaDown: false,
      downAttaReq: {
        cal_obj_id: '',
        upid: '',
        from_id: '',
        attachment_name: '',
      },
      searchWord: '',
      searchList: [],
      showSearch: false,
      tableData: [],
      editStatus: false, // 编辑日程状态
      isMettingEdit: false, // 是否是会议编辑
      searchShare: '', // 分享详情搜索
      // submenu: false, // 订阅日历菜单
      eventTags: this.$myData.calendar.eventTags, // 完整
      eventTags1: this.$myData.calendar.eventTags, // 弹窗
      oldStatus: '', // 上一次视图显示
      showDropMenu: false,
      selectedTag: this.$myData.calendar.defaultTag, // 创建日程或会议的日历标签
      dialogShare: false, // 添加分享成员弹窗
      showSubscribeTags: false, // 订阅日历
      fullEdit: 1, // 切换日历视图1 分享3 新建日程2
      isEdit: null, // 编辑左侧tag
      isSubEdit: null, // 编辑左侧订阅tag
      editTagName: '', // 编辑左侧tag的名称
      editSubTagName: '', // 编辑左侧订阅tag的名称
      showCreate: false, // 创建左侧标签
      showMyList: true, // 展开我的日历
      showSubscribeList: true, // 展开订阅日历
      fullday: false, // 新建日程全天
      isMetting: false, // 切换会议
      slevent: null, // 左侧我的日历选中id
      subSlevent: null, // 左侧订阅日历选中
      datetime: '', // 新建日程时间
      tipsCycle: '15M', // 新建日程提醒
      tagsto: [], // 参会人
      myshare: false, // 切换分享列表和被分享列表
      eventColor: ['#D0161B', '#069547', '#7030A0', '#FF9800', '#0070C0', '#65A236', '#B548DC', '#954611', '#808080', '#214F75'], // 日历标签颜色
      defaultProps: {
        label: 'tag_name',
        isLeaf: 'leaf',
      },
      defaultProps1: {
        label: 'name',
        isLeaf: 'leaf',
      },
      defaultProps2: {
        label: 'show_name',
        isLeaf: 'leaf',
      },
      tagForm: {
        tag_name: '',
        tag_color: '',
      },
      tagRules: {
        tag_name: [
          { required: true, message: this.$t('message.calendar.info75'), trigger: 'blur' },
        ],
        tag_color: [
          { validator: checkColor, message: this.$t('message.calendar.info74'), trigger: 'blur' },
        ],
      },
      // sharerules: {
      //   share_user_id: [{ validator: shareUser, message: '请选择分享成员', trigger: 'blur' }],
      // },
      selectedColor: null,
      selectedSubColor: null,
      showError: false, // 显示错误信息
      tagName: '', // 创建标签名字
      timeCycle: false, // 设置活动周期
      attachment: [], // 附件区域显示列表
      fullcCarlendarReq: {
        to: [], // 接收人
        atts: [], // 附件列表
        subject: '', // 主题
        location: '', // 地点
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        valarm_time: '15', // 提醒时间
        valarm_type: 'M', // 提醒类型
        tag_id: '', // 标签id
        rule: '', // 规则
        add_type: 2, // 添加类型
        desc: '', // 描述
        transp: 'BUSY', // 传输类型
        all_day_type: '0', // 全天类型
        privilege: '',
      },
      calendarType: null, // 日程类型 1:创建的日程2：创建的会议3：接收的会议
      detailInfo: {}, // 日程详情信息
      editId: null, // 日程编辑id
      fromId: null, // 订阅日程id,
      meeting_status: '1',
      feedbacks_status: [], // 响应人数详情
      showfeedbacks: false,
      toggleEdit: true,
      showtoggleEdit: false,
      isHave: false,
      scrollerHeight: (window.innerHeight - 200) + 'px',
      scrollerHeight1: (window.innerHeight - 300) + 'px',
      scrollerHeight2: (window.innerHeight - 229) + 'px',
      lang: localStorage.getItem('lang'),
    }
  },
  computed: {
    // scrollerHeight: function() { // 列表固定高度
    //   return (window.innerHeight - 200) + 'px'
    // },
    // scrollerHeight1: function() { // 列表固定高度
    //   return (window.innerHeight - 300) + 'px'
    // },
    // scrollerHeight2: function() { // 列表固定高度
    //   return (window.innerHeight - 229) + 'px'
    // },
    defaultTime() {
      return ['2023-09-11 09:00:00', '2023-09-11 09:30:00']
    },
  },
  watch: {
    fullEdit(val, old) {
      this.oldStatus = old
      this.showfeedbacks = false
      if (val === 1) {
        if (this.$refs.fullcalendar) this.$refs.fullcalendar.freshRender()
      }
    },
    myshare(val) {
      this.getAllShareList()
    },
    shareperlist(arr) {
      const list = []
      arr.map(item => {
        list.push({
          privilege: '0',
          to_user_id: item.user_id,
          user_mail: item.mail,
          user_name: item.name,
        })
      })
      this.formLabelAlign.share_user_id = list
    },
    tagsto: {
      handler(value) {
        const arr = value.map(obj => [obj.mail, obj.name])
        this.fullcCarlendarReq.to = this.uniqueArray(arr)
      },
      immediate: true,
    },
  },
  created() {
    const thisobj = this
    this.$myApi.activeModuleCalendar = async(data, options) => {
      if (!thisobj.selfActived) {
        thisobj.selfActived = true
        await thisobj.initLoadAll()
      }
      if (!options || options.isHistory !== true) {
        this.$myApi.routeSet('calendar')
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.location.hash === '#calendar' && this.fullEdit === 1) {
        this.$refs.fullcalendar.freshRender()
      }
    })
  },
  methods: {
    viewShareLink(row, flag) {
      this.isself = flag
      this.linkobj = row
      this.shareLink = row.share_url
      this.showShareLink = true
    },
    copylink() {
      copy.copyText(this.shareLink)
      this.$message({
        message: this.$t('message.netdisk.info1'),
        type: 'success',
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    confirmChange() {
      this.multipleSelection.map(item => {
        item.privilege = this.auth
      })
      this.showChangeAuth = false
    },
    deleteRows() {
      this.shareUserList = this.shareUserList.filter(row => {
        return !this.multipleSelection.includes(row)
      })
      this.$refs.multipleTable1.clearSelection()
    },
    async initLoadAll() {
      await this.getCalendarTags()
      await this.getSubscribeTagList()
      await this.getOrgList('')
      // await this.getEventsList()
    },
    // 处理会议响应状态
    changeMettingStatus(status) {
      this.meeting_status = status
    },
    // 参数人去重
    uniqueArray(arr) {
      const result = []
      const obj = {}
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i].toString()
        if (!obj[str]) {
          result.push(arr[i])
          obj[str] = true
        }
      }
      return result
    },
    handleRemove(file) {
      this.$refs.uploadfiles.handleRemove(file)
    },
    handleResumeUpload(file) {
      this.$refs.uploadfiles.handleResumeUpload(file)
    },
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 全天状态修改
    changeFullDay(flag) {
      if (this.datetime === '') {
        if (flag === '1') {
          // 创建一个新的日期对象
          var currentDate = new Date()

          // 从日期对象获取年、月、日、时、分、秒
          var year = currentDate.getFullYear()
          var month = ('0' + (currentDate.getMonth() + 1)).slice(-2) // Months are 0 based, hence add 1
          var day = ('0' + currentDate.getDate()).slice(-2)
          // 组合成一个字符串,并添加必要的分隔符
          this.datetime = [year + '-' + month + '-' + day + ' ' + '00:00:00', year + '-' + month + '-' + day + ' ' + '23:59:59']
        }
      } else {
        console.log(this.datetime[0].substring(0, 10))
        this.datetime = [this.datetime[0].substring(0, 10) + ' ' + '00:00:00', this.datetime[1].substring(0, 10) + ' ' + '23:59:59']
      }
    },
    getRangeTime() {
      return this.$refs.calendar.getTimeRange()
    },
    // 取消创建日程
    cancelCreatEvent() {
      this.fullEdit = this.oldStatus
      this.resetdata()
      // 清空状态
      this.calendarType = null
    },
    // 获取活动周期数据
    getCycleTime(data) {
      console.log(this.$refs['cycleTime'].getDate())
    }, //
    async saveEditEvent(flag) {
      if (flag) {
        if (this.$refs.tagsto) this.$refs.tagsto.checkInputMail()
        if (this.$refs.uploadfiles && !this.$refs.uploadfiles.isCompleted()) {
          return this.$alert(this.$t('message.upload.info1'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
        if (this.fullcCarlendarReq.to.length === 0) {
          return this.$alert(this.$t('message.upload.info2'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
        if (this.tagsto.some(item => !item.isValid)) {
          return this.$alert(this.$t('message.upload.info3'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
        if (this.$refs.uploadfiles) {
          this.fullcCarlendarReq.atts = [...this.$refs.uploadfiles.attsList]
          let bodycontent = ''
          this.$refs.uploadfiles.bigAttachmentList.map(item => {
            bodycontent += item.bodys
          })
          this.fullcCarlendarReq.desc = bodycontent + this.fullcCarlendarReq.desc
        }
        this.fullcCarlendarReq.subject = `${this.fullcCarlendarReq.subject.indexOf(this.$t('message.calendar.info76')) !== -1 ? '' : this.$t('message.calendar.info76') + '：'}` + this.fullcCarlendarReq.subject
      }
      if (!this.datetime) return this.$message.error(this.$t('message.calendar.info77'))
      this.fullcCarlendarReq.start_time = this.datetime[0]
      this.fullcCarlendarReq.end_time = this.datetime[1]
      this.fullcCarlendarReq.tag_id = this.selectedTag.tag_id
      if (this.timeCycle) {
        const res = this.$refs['cycleTime'].getDate()
        console.log(res)
        if (res.status) {
          this.fullcCarlendarReq.rule = res.data
        } else {
          return this.$alert(res.msg, this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
      } else {
        this.fullcCarlendarReq.rule = ''
      }
      if (flag) {
        this.fullcCarlendarReq.is_meeting = 1
        const res = await this.SendMetting({ ...this.fullcCarlendarReq, cal_obj_id: this.editId })
        if (res.status === 200) {
          this.dialogVisible = false
          this.$refs['fullcalendar'].getEventList()
          this.resetdata()
          this.$message.success(this.$t('message.calendar.info78'))
          this.fullEdit = 1
        }
      } else {
        const res = await this.editCarlendar(this.editId, this.fromId, this.fullcCarlendarReq)
        if (res.status === 200) {
          this.$message.success(this.$t('message.calendar.info96'))
          this.fullEdit = 1
          this.dialogVisible = false
          this.$refs['fullcalendar'].getEventList()
          this.resetdata()
        }
      }
      // 清空状态
      this.calendarType = null
    },
    // 下载附件
    downloadAtt(item) {
      if (item.is_meeting) {
        this.downAttaReq.cal_obj_id = this.detailInfo.cal_obj_id
        this.downAttaReq.upid = item.upid
        this.downAttaReq.from_id = this.detailInfo.from_id || ''
        this.downAttaReq.attachment_name = item.name
        this.isAttaDown = true
      } else {
        this.downloadAtta(item)
      }
    },
    // 日程卡片编辑/
    async getEventDetail(data, isDetail) {
      console.log(data, isDetail)
      const { title, start_time, privilege, over_time, attachments, attendee_arr, end_time, ky_valarm_str, from_id, ky_transp, ky_rrule, type, ky_location, ky_tag_id, ky_all_day, desc, cal_obj_id } = data
      this.editId = cal_obj_id
      this.fromId = from_id || ''
      this.calendarType = type
      if (type === 1 || type === 5) {
        this.showtoggleEdit = true
      } else {
        this.showtoggleEdit = false
      }
      // 查看详情
      if (isDetail) {
        console.log(type)
        this.detailInfo = data
        this.calendarType = type
        this.meeting_status = data.meeting_status
        this.tableData = data.attendee_detail_arr
        if (type === 2) {
          if (new Date().getTime() > over_time * 1000) {
            this.meeting_status = '6'
          }
          let a = 0; let b = 0; let c = 0; let d = 0
          data.attendee_detail_arr.map(item => {
            if (item.feedback === 1) {
              a++
            } else if (item.feedback === 2) {
              b++
            } else if (item.feedback === 3) {
              c++
            } else {
              d++
            }
          })
          this.feedbacks_status = [a, b, c, d]
        }
        if (type === 3) {
          if (new Date().getTime() > over_time * 1000) {
            this.meeting_status = '6'
          }
        }
        this.fullEdit = 4
        return
      }
      this.fullcCarlendarReq.subject = title
      if (this.fromId) {
        this.fullcCarlendarReq.from_id = from_id
        const res = await this.getSubscribeShareTagList(data.from_id)
        this.eventTags = res.result
        this.selectedTag = res.result.find(item => item.tag_id === ky_tag_id)
      } else {
        this.eventTags = this.$myData.calendar.eventTags
        this.selectedTag = this.$myData.calendar.eventTags.find(item => item.tag_id === ky_tag_id)
      }
      if (ky_rrule) {
        const cyle = this.parsingRules(ky_rrule)
        this.timeCycle = true
        this.$refs.cycleTime.setOriginValue(cyle)
      }
      this.$refs.ED.setContent(desc || '')
      this.editStatus = true
      this.isMettingEdit = false
      this.fullcCarlendarReq.all_day_type = ky_all_day
      this.fullcCarlendarReq.transp = ky_transp
      this.tipsCycle = ky_valarm_str
      this.fullcCarlendarReq.location = ky_location
      this.fullcCarlendarReq.privilege = privilege
      this.datetime = [start_time, end_time]
      // 处理附件
      const atts = []
      attachments.map(item => {
        atts.push({
          ...item,
          origin: 'mail',
        })
      })
      this.$refs.uploadfiles.setEditOrinData(atts)
      // 自己发起的会议
      if (type === 2) {
        this.isMettingEdit = true
        const mails = []
        attendee_arr.map(item => {
          mails.push({
            mail: item[0],
            name: item[1],
            isValid: true,
          })
        })
        this.tagsto = mails
      }
      this.fullEdit = 2
    },
    // 详情编辑
    async editDetailEvent() {
      const { cal_obj_id, title, start_time, end_time, attachments, type, attendee_arr, ky_valarm_str, from_id, ky_rrule, ky_location, ky_tag_id, ky_all_day, desc } = this.detailInfo
      this.fullcCarlendarReq.subject = title
      this.editId = cal_obj_id
      this.fromId = from_id || ''
      if (this.fromId) {
        this.fullcCarlendarReq.from_id = from_id
        const res = await this.getSubscribeShareTagList(from_id)
        this.eventTags = res.result
        this.selectedTag = res.result.find(item => item.tag_id === ky_tag_id)
      } else {
        this.selectedTag = this.$myData.calendar.eventTags.find(item => item.tag_id === ky_tag_id)
      }
      if (ky_rrule) {
        const cyle = this.parsingRules(ky_rrule)
        this.timeCycle = true
        this.$refs.cycleTime.setOriginValue(cyle)
      }
      this.$refs.ED.setContent(desc || '')
      this.editStatus = true
      this.isMettingEdit = false
      this.fullcCarlendarReq.all_day_type = ky_all_day
      this.tipsCycle = ky_valarm_str
      this.fullcCarlendarReq.location = ky_location
      this.datetime = [start_time, end_time]
      // 处理附件
      const atts = []
      attachments.map(item => {
        atts.push({
          ...item,
          origin: 'mail',
        })
      })
      this.$refs.uploadfiles.setEditOrinData(atts)
      if (type === 2) {
        this.isMettingEdit = true
        const mails = []
        attendee_arr.map(item => {
          mails.push({
            mail: item[0],
            name: item[1],
            isValid: true,
          })
        })
        this.tagsto = mails
      }
      this.fullEdit = 2
    },
    // 删除查看详情id
    delDetailEvent() {
      let a = ''
      if ([1, 5].includes(this.detailInfo.type)) {
        a = this.$t('message.calendar.confirm')
      } else {
        a = this.$t('message.calendar.info79')
      }
      this.$confirm(a, this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(() => {
        if ([1, 5].includes(this.detailInfo.type)) {
          this.$refs.fullcalendar.outDelEvent(this.detailInfo.cal_obj_id, this.detailInfo.from_id)
          this.fullEdit = 1
        } else {
          this.$refs.fullcalendar.outCancelMetting(this.detailInfo.cal_obj_id, this.detailInfo.from_id)
          this.fullEdit = 1
        }
      }).catch(() => {
      })
    },
    delreceiveMetting() {
      this.$confirm(this.$t('message.calendar.confirm'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(async() => {
        const params = {
          cal_obj_id: this.detailInfo.cal_obj_id,
          meeting_feedback: '3',
          from_id: this.detailInfo.from_id || '',
        }
        const res = await this.directFeedbackMetting(params)
        if (res.status === 200) {
          this.$refs.fullcalendar.getEventList()
          this.fullEdit = 1
        }
      }).catch(() => {
      })
    },
    // 完整编辑
    fullEdotModel(flag) {
      this.dialogVisible = false
      const { date, desc, location, tag, subject, all_day_type, tips, openCycle, ruleOrigin, from_id } = this.$refs['crateEventData'].getOrinData()
      if (from_id) {
        this.fullcCarlendarReq.from_id = from_id
      }
      this.fullcCarlendarReq.subject = subject
      this.fullcCarlendarReq.all_day_type = all_day_type
      this.$refs.ED.setContent(desc)
      this.tipsCycle = tips
      this.fullcCarlendarReq.location = location
      this.fullcCarlendarReq.valarm_time = tips.substring(0, tips.length - 1)
      this.fullcCarlendarReq.valarm_type = tips.charAt(tips.length - 1)
      this.datetime = date
      this.selectedTag = tag
      this.timeCycle = openCycle
      if (this.timeCycle) {
        this.$refs['cycleTime'].setOriginValue(ruleOrigin)
      }
      console.log(flag)
      if (flag) {
        this.isMetting = true
        this.eventTags = this.eventTags1
      }
      this.fullEdit = 2
    },
    // 重置数据
    resetdata() {
      this.fullcCarlendarReq = {
        to: [], // 接收人
        atts: [], // 附件列表
        subject: '', // 主题
        location: '', // 地点
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        valarm_time: '15', // 提醒时间
        valarm_type: 'M', // 提醒类型
        tag_id: '', // 标签id
        rule: '', // 规则
        add_type: 2, // 添加类型
        desc: '', // 描述
        transp: 'BUSY', // 传输类型
        all_day_type: '0', // 全天类型
        privilege: '',
      }
      // this.calendarType = null
      this.tipsCycle = '15M'
      this.datetime = ''
      this.editStatus = false
      this.isMettingEdit = false
      this.isMetting = false
      this.tagsto = []
      this.editId = null
      this.fromId = null
      this.attachment = []
      this.selectedTag = this.$myData.calendar.defaultTag
      this.$refs.ED.setContent('')
      if (this.timeCycle) {
        this.$refs['cycleTime'].resetData()
      }
      this.timeCycle = false
      this.$refs.uploadfiles.clearFileNull()
    },
    // 快速创建日程
    async saveCreateEvent() {
      const res = this.$refs['crateEventData'].getReqData()
      if (res.status) {
        console.log(res.result)
        if (res.result.subject === '') {
          this.$confirm(this.$t('message.calendar.info80'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            cancelButtonText: this.$t('message.btnText.cancel'),
            type: 'warning',
          }).then(async() => {
            const data = await this.addCarlendar(res.result)
            if (data.status === 200) {
              this.$message.success(this.$t('message.calendar.info95'))
              this.dialogVisible = false
              this.$refs['fullcalendar'].getCurrentMonthEvents()
            }
          }).catch(() => {

          })
        } else {
          const data = await this.addCarlendar(res.result)
          if (data.status === 200) {
            this.$message.success(this.$t('message.calendar.info95'))
            this.dialogVisible = false
            this.$refs['fullcalendar'].getCurrentMonthEvents()
          }
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 标签新建日程
    newCreateEvent(data) {
      this.eventTags1 = this.$myData.calendar.eventTags
      this.dialogVisible = true
      setTimeout(() => {
        this.$refs['crateEventData'].setTag(data)
      }, 100)
    },
    // 设置提醒
    setNoice(value) {
      this.fullcCarlendarReq.valarm_time = value.substring(0, value.length - 1)
      this.fullcCarlendarReq.valarm_type = value.charAt(value.length - 1)
    },
    // 验证发送会议数据
    vilidataMettingData() {
      console.log(this.fullcCarlendarReq.to.length)
      if (this.$refs.uploadfiles && !this.$refs.uploadfiles.isCompleted()) {
        return this.$alert(this.$t('message.upload.info1'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        }).catch(() => {})
      }
      if (this.fullcCarlendarReq.to.length === 0) {
        return this.$alert(this.$t('message.upload.info2'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        }).catch(() => {})
      }
      if (this.tagsto.some(item => !item.isValid)) {
        return this.$alert(this.$t('message.upload.info3'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        }).catch(() => {})
      }
    },
    // 完整创建日程/发送会议
    async saveFullCreateEvent() {
      if (this.isMetting || this.isMettingEdit) {
        if (this.$refs.tagsto) this.$refs.tagsto.checkInputMail()
        if (this.$refs.uploadfiles && !this.$refs.uploadfiles.isCompleted()) {
          return this.$alert(this.$t('message.upload.info1'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
        if (this.fullcCarlendarReq.to.length === 0) {
          return this.$alert(this.$t('message.upload.info2'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
        if (this.tagsto.some(item => !item.isValid)) {
          return this.$alert(this.$t('message.upload.info3'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
        if (this.$refs.uploadfiles) {
          this.fullcCarlendarReq.atts = [...this.$refs.uploadfiles.attsList]
          let bodycontent = ''
          this.$refs.uploadfiles.bigAttachmentList.map(item => {
            bodycontent += item.bodys
          })
          this.fullcCarlendarReq.desc = bodycontent + this.fullcCarlendarReq.desc
        }
      }
      if (!this.datetime) {
        return this.$alert(this.$t('message.calendar.info81'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        }).catch(() => {})
      }
      this.fullcCarlendarReq.start_time = this.datetime[0]
      this.fullcCarlendarReq.end_time = this.datetime[1]
      this.fullcCarlendarReq.tag_id = this.selectedTag.tag_id
      console.log(this.timeCycle, 'timeCycle')
      if (this.timeCycle) {
        const res = this.$refs['cycleTime'].getDate()
        if (res.status) {
          this.fullcCarlendarReq.rule = res.data
        } else {
          return this.$alert(res.msg, this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          }).catch(() => {})
        }
      } else {
        this.fullcCarlendarReq.rule = ''
      }
      if (this.isMetting) {
        this.fullcCarlendarReq.is_meeting = 1
        if (this.fullcCarlendarReq.subject === '') {
          this.$confirm(this.$t('message.calendar.info82'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            cancelButtonText: this.$t('message.btnText.cancel'),
            type: 'warning',
          }).then(async() => {
            const res = await this.SendMetting(this.fullcCarlendarReq)
            if (res.status === 200) {
              this.fullEdit = 1
              this.dialogVisible = false
              this.$refs['fullcalendar'].getCurrentMonthEvents()
              this.resetdata()
              this.$message.success(this.$t('message.calendar.info94'))
            }
          }).catch(() => {
          })
        } else {
          const res = await this.SendMetting(this.fullcCarlendarReq)
          if (res.status === 200) {
            this.fullEdit = 1
            this.dialogVisible = false
            this.$refs['fullcalendar'].getCurrentMonthEvents()
            this.resetdata()
            this.$message.success(this.$t('message.calendar.info94'))
          }
        }
      } else {
        if (this.fullcCarlendarReq.subject === '') {
          this.$confirm(this.$t('message.calendar.info80'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            cancelButtonText: this.$t('message.btnText.cancel'),
            type: 'warning',
          }).then(async() => {
            const res = await this.addCarlendar(this.fullcCarlendarReq)
            if (res.status === 200) {
              this.$message.success(this.$t('message.calendar.info95'))
              this.fullEdit = 1
              this.dialogVisible = false
              this.$refs['fullcalendar'].getCurrentMonthEvents()
              this.resetdata()
            }
          }).catch(() => {
          })
        } else {
          const res = await this.addCarlendar(this.fullcCarlendarReq)
          if (res.status === 200) {
            this.$message.success(this.$t('message.calendar.info95'))
            this.fullEdit = 1
            this.dialogVisible = false
            this.$refs['fullcalendar'].getCurrentMonthEvents()
            this.resetdata()
          }
        }
      }
    },
    // 分享详情页
    goShareDetail(row, column, event) {
      this.getEditShareInfo(row.tag_id)
      this.detailTitle = row.tag_name
      this.formLabelAlign.tag_id = row.tag_id
    },
    // 保存分享详情
    saveShareUser() {
      const params = {
        tag_id: this.formLabelAlign.tag_id,
        share_tag_list: [],
      }
      this.shareUserList.map(item => {
        params.share_tag_list.push({
          to_user_id: item.to_user_id,
          privilege: item.privilege,
        })
      })
      this.editShare(params)
      this.searchShare = ''
    },
    // 编辑收到的分享(接受或忽略)
    async acceptIgnore(row, flag) {
      const params = {
        share_from_id: row.id,
        status: flag,
      }
      const res = await this.editRecShareTag(params)
      if (res.status === 200) {
        this.getMyReieveList()
        this.getSubscribeTagList()
        this.$refs.fullcalendar.getEventList()
      }
    },
    // 删除分享成员
    cancelShare(row, isall) {
      if (isall) {
        this.shareUserList = []
      } else {
        this.shareUserList.splice(this.shareUserList.indexOf(row), 1)
      }
    },
    // 确认分享
    confirmShare() {
      if (this.formLabelAlign.share_user_id.length === 0) {
        this.$message.error(this.$t('message.calendar.info83'))
        return false
      }
      const list = []
      const userList = []
      this.shareUserList.map(item => userList.push(item.to_user_id))
      this.shareperlist.map(item => {
        if (!userList.includes(item.user_id)) {
          list.push({
            privilege: this.formLabelAlign.privilege_level + '',
            to_user_id: item.user_id,
            user_mail: item.mail,
            user_name: item.name,
          })
        }
      })
      this.shareUserList = [...this.shareUserList, ...list]
      this.dialogShare = false
    },
    ShareUserChange(data, node) {
      console.log(data, node)
    },
    // 打开日历分享
    openSharePage() {
      this.fullEdit = 3
      this.getAllShareList()
    },
    getAllShareList() {
      if (this.myshare) {
        this.getMyShareList()
      } else {
        this.getMyReieveList()
      }
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    // 显示订阅日历弹窗
    showsubscribeCalendar(row) {
    },
    // 只允许第一级拖拽
    allowDrop(draggingNode, dropNode, type) {
      console.log(draggingNode.level, dropNode.level)
      // 注掉的是同级拖拽
      if (draggingNode.level === dropNode.level) {
        return type === 'prev' || type === 'next'
      } else {
        // 不同级进行处理
        return false
      }
    },
    // 先择日历标签节点
    handleNodeClick(item) {
      this.slevent = item.tag_id
      this.subSlevent = null
      this.$refs.subscribetree.setCurrentKey(null)
    },
    // 选择订阅日历
    handleSubClick(item) {
      this.subSlevent = item.from_id
      this.$refs.mytree.setCurrentKey(null)
      this.slevent = null
    },
    // 选择颜色
    async selectColor(data, index) {
      this.selectedColor = index
      const params = {
        tag_id: data.tag_id,
        tag_name: data.tag_name,
        tag_color: this.selectedColor !== null ? this.eventColor[this.selectedColor] : '',
        is_show: data.is_show,
      }
      const res = await this.editCalendarTags(params)
      if (res.status === 200) {
        this.$message.success(this.$t('message.calendar.info84'))
        this.$refs.fullcalendar.changeEventColor(data.tag_id, this.eventColor[this.selectedColor], false)
        this.showCreateTags = false
        this.getCalendarTags()
        this.getMyShareList()
      } else {
        this.$message.success(this.$t('message.calendar.info85'))
        this.showCreateTags = false
      }
    },
    async myvisibleChange(data) {
      const params = {
        tag_id: data.tag_id,
        tag_name: data.editTagName,
        tag_color: data.tag_color,
        is_show: data.is_show === '1' ? '2' : '1',
        editShow: true,
      }
      const res = await this.editCalendarTags(params)
      if (res.status === 200) {
        this.$refs.fullcalendar.hideEvents(data.tag_id, data.is_show === '1' ? 'none' : 'block', false)
        this.showCreateTags = false
        this.getCalendarTags()
        this.getMyShareList()
      } else {
        this.$message.success(this.$t('message.calendar.info85'))
        this.showCreateTags = false
      }
    },
    subvisibleChange(data) {
      const params = {
        from_id: data.from_id,
        is_show: data.is_show === '1' ? '2' : '1',
      }
      this.switchShowSubscribeTag(params)
      this.$refs.fullcalendar.hideEvents(data.from_id, data.is_show === '1' ? 'none' : 'block', true)
    },
    // 选择订阅日历颜色
    selectSubscribeColor(data, index) {
      console.log(data)
      this.selectedSubColor = index
      const params = {
        from_id: data.from_id,
        show_name: data.show_name,
        color: this.selectedSubColor !== null ? this.eventColor[this.selectedSubColor] : '',
      }
      this.editSubscribeTag(params)
      this.$refs.fullcalendar.changeEventColor(data.from_id, this.eventColor[this.selectedSubColor], true)
    },
    // 创建日历标签
    createTags() {
      if (this.tagName === '') return this.$message.error(this.$t('message.calendar.info8'))
      const reg = /[@$^:!|%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"]/g
      if (reg.test(this.tagName)) {
        return this.$message.error(this.$t('message.calendar.info86'))
      }
      const params = {
        tag_name: this.tagName,
        tag_color: '',
      }
      this.addCalendarTags(params)
      this.closeCreate()
    },
    // 重命名日历
    renameCalendar(item) {
      this.isEdit = item.tag_id
      this.editTagName = item.tag_name
    },
    // / 重命名日历
    renameSubCalendar(item) {
      this.isSubEdit = item.from_id
      this.editSubTagName = item.show_name
    },
    // 订阅日历创建日程
    async createSubscribeEvent(data) {
      // this.submenu = false
      const res = await this.getSubscribeShareTagList(data.from_id)
      if (res.result.length === 0) {
        return this.$message.error(this.$t('message.calendar.info87'))
      }
      this.createEvent()
      this.eventTags1 = res.result
      setTimeout(() => {
        this.$refs['crateEventData'].setTag(res.result[0])
        this.$refs['crateEventData'].setFromId(data.from_id)
      }, 100)
    },
    // 取消订阅
    cancelSubcribeTag(data) {
      this.$confirm(this.$t('message.calendar.info88', { mail: data.from_mail }), this.$t('message.calendar.info89'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(() => {
        this.delSubscribeTag(data.from_id)
      }).catch(() => {
      })
    },
    // 保存编辑
    async saveEdit(data) {
      if (this.editTagName === '') return this.$message.error(this.$t('message.calendar.info8'))
      const reg = /[@$^:!|%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"]/g
      if (reg.test(this.editTagName)) {
        return this.$message.error(this.$t('message.calendar.info86'))
      }
      const params = {
        tag_id: data.tag_id,
        tag_name: this.editTagName,
        tag_color: data.tag_color,
        is_show: data.is_show,
      }
      const res = await this.editCalendarTags(params)
      if (res.status === 200) {
        this.$message.success(this.$t('message.calendar.info84'))
        this.showCreateTags = false
        this.isEdit = null
        this.getCalendarTags()
        this.getMyShareList()
      } else {
        this.$message.success(this.$t('message.calendar.info85'))
        this.showCreateTags = false
      }
    },
    // 保存订阅编辑
    saveSubEdit(data) {
      if (this.editSubTagName === '') return this.$message.error(this.$t('message.calendar.info8'))
      const reg = /[@$^:!|%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"]/g
      if (reg.test(this.editSubTagName)) {
        return this.$message.error(this.$t('message.calendar.info86'))
      }
      const params = {
        from_id: data.from_id,
        show_name: this.editSubTagName,
        color: data.color,
      }
      this.editSubscribeTag(params)
      this.isSubEdit = null
    },
    closeEdit() {
      console.log('2')
      this.isEdit = null
    },
    closeSubEdit() {
      this.isSubEdit = null
      console.log('1')
    },
    // 重置数据
    resetForm() {
      this.tagForm.tag_name = ''
      this.selectedColor = null
      this.showError = false
    },
    // 新建日历
    handleClick() {
      this.showCreate = true
      setTimeout(() => {
        this.$refs['myShareList'].scrollTop = this.$refs['myShareList'].scrollHeight
      }, 0)
    },
    closeCreate() {
      this.showCreate = false
      this.tagName = ''
    },
    play() {
      this.showMyList = !this.showMyList
    },
    playSubscribe() {
      this.showSubscribeList = !this.showSubscribeList
    },
    selectedDate(date, range) {
      if (this.fullEdit !== 1) return
      this.$refs.fullcalendar.gotoDate(date, range)
    },
    getTimeStamp(date, flag) {
      this.$refs.calendar.getExactCurrentMonthDay(date, flag)
    },
    selectedEvent(id) {
      this.slevent = id
    },
    cancelSelect() {
      // this.slevent = null
    },
    // 创建日程
    createEvent() {
      if (this.fullEdit === 2) {
        this.isMetting = false
        return
      }
      this.eventTags1 = this.$myData.calendar.eventTags
      this.dialogVisible = true
      setTimeout(() => {
        this.$refs['crateEventData'].setTag(this.$myData.calendar.defaultTag)
      }, 100)
    },
    fullCreate(flag) {
      if (flag) {
        this.showDropMenu = false
        this.isMetting = false
        this.fullEdit = 2
      } else {
        this.showDropMenu = false
        this.isMetting = true
        this.fullEdit = 2
      }
      document.querySelector('.mian-container').scrollTo(0, 0)
      this.eventTags = this.$myData.calendar.eventTags
      this.selectedTag = this.$myData.calendar.defaultTag
    },
    handleCreateClose() {
      this.$refs['crateEventData'].resetData()
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogShare = false
      // this.fullEdit = true
    },
    closedShare() {
      this.shareperlist = []
      this.formLabelAlign.privilege_level = 0
    },
    getAdressData(data) {
      this.tagsto = this.deepCopy([...data.mettinglist])
    },
    openContacts(flag) {
      this.$refs.ContactsPopper.showContactPopper(flag)
    },
    selectedShare() {

    },
    async getOrgList(id) {
      const res = await this.getPublicContact(id)
      console.log(res)
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        const list = await this.getPublicContact('')
        console.log(list)
        return resolve(list)
      } else {
        const list = await this.getPublicContact(node.data.id)
        return resolve(list)
      }
    },
    pushmail() {
      this.$refs['mettingtree'].getCheckedNodes().map(item => {
        if (item.leaf && this.checkedRepeat(item)) {
          this.shareperlist.push(item)
        }
      })
      this.$refs['mettingtree'].setCheckedKeys([])
    },
    deMail(index) {
      this.shareperlist.splice(index, 1)
    },
    checkedRepeat(item) {
      const res = this.shareperlist.find(v => v.mail === item.mail)
      return !res
    },
    handleCheckChange(nodeData, nodeSelected) {
      if (this.$refs['mettingtree']?.getCheckedNodes().length) {
        this.isHave = true
      } else {
        this.isHave = false
      }
      let tree = null
      tree = this.$refs.mettingtree
      const node = tree.getNode(nodeData.id)
      this.expandCheckedNotExpandNodes(node)
    },
    expandCheckedNotExpandNodes(node) {
      let tree = null
      tree = this.$refs.mettingtree
      if (node && node.checked && !node.expanded && !node.isLeaf) {
        node.expand(function() {
          const childNodes = node.childNodes
          for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i]

            // 手动触发check-change事件,事件处理函数中回继续调用此函数,形成递归展开
            tree.$emit('check-change', childNode.data, childNode.checked, childNode.indeterminate)
          }
        })
      }
    },
    // 打开上传附件
    openUpDia() {
      this.$refs.uploadfiles.showuploadDia()
    },
    clearSearch(flag) {
      if (flag) {
        if (this.searchWord === '') {
          this.searchList = []
        }
      } else {
        this.searchList = []
      }
    },
    async searchUsers() {
      if (this.searchWord === '' || countCharacters(this.searchWord) < 3) return
      const data = await this.searchContact(this.searchWord, '')
      const cc = data.map(item => {
        item.isValid = true
        return item
      })
      this.searchList = cc
      if (this.searchList.length > 0) {
        this.showSearch = true
      }
    },
    clickout() {
      this.showSearch = false
      this.searchWord = ''
    },
    SearchListadd(data) {
      if (!this.shareperlist.find(v => v.mail === data.mail)) {
        this.shareperlist.push(data)
      } else {
        this.$message.error(this.$t('message.calendar.info90'))
      }
    },
    delEventTags(id) {
      this.$confirm(this.$t('message.calendar.info91'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(async() => {
        const res = await this.delCalendarTags(id)
        if (res.status === 200) {
          const displayTag = this.$myData.calendar.displayTag
          if (displayTag.indexOf(id) !== -1) {
            displayTag.splice(displayTag.indexOf(id), 1)
          }
          this.$message.success(this.$t('message.calendar.del1'))
          this.getCalendarTags()
          this.getMyShareList()
          this.$refs.fullcalendar.getEventList()
        } else {
          this.$message.error(this.$t('message.calendar.del2'))
        }
      }).catch(() => {

      })
    },
    selectMore(p, m) {
      this.$refs.ContactsPopper.showContactPopper(p, m)
    },
    // 编辑时候切换
    switchEdit() {
      this.editStatus = false
      this.isMetting = true
    },
    switchMetting() {
      if (this.calendarType) {
        this.editStatus = true
      }
      this.isMetting = !this.isMetting
      // 清空状态
      this.calendarType = null
    },
  },
}
</script>

<style lang="scss" scoped>
.tail-icon{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 3px;
    padding: 5px 7px;
    &:hover{
      background: #EAEAEA;
    }
    .tail-icon-item{
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      border: 1px solid #8d8c8c;
    }
}
.calendar-container {
  width: 100%;
  height: 100%;
  display: flex;
  .top-btn{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
      display: flex;
      padding: 15px 25px;
      .button{
        flex: 1;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        border-radius: 3px 0 0 3px;
      }
      .menu{
        height: 32px;
        line-height: 32px;
        color: #fff;
        background-color: #b60b11;
        border-radius: 0 3px 3px 0;
        border-left: 1px solid rgba(0,0,0,0.3);
        padding: 0 6px;
      }
    }
    .my-calendar{
        margin-top: 15px;
        padding: 0 20px;
        // max-height: 300px;
        // overflow: auto;
        .top-line{
            display: flex;
                justify-content: space-between;
                align-items: center;
        }
        .my-list{
            padding: 5px 0 5px 20px;
            li{
              cursor: pointer;
              height: 30px;
              padding: 3px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:hover{
                background-color: #f5f7fa;
              }
            }
            .selected{
              background-color: #f5f7fa;
            }
        }
        .createlist{
          padding-left: 23px;
          padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.rotate360 {
    transform: rotate(90deg);
    transition: transform 0.5s;
}
.rotate0{
    transform: rotate(0deg);
    transition: transform 0.5s;
}
.cur{
  cursor: pointer;
}
.mian-container{
  overflow: auto;
  // min-width: 1100px;
  height: calc(100vh - 54px);
  padding: 0;
}
.compose-icon{
  width: 0.9em;
}
.colorcontainer{
  width: 100px;
  margin: 0 auto;
  .eventColor{
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin: 0 5px 5px 0;
  display: inline-block;
}
}
.fulledit{
  height: 100%;
}
.to{
   min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
}
.line{
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .annex-icon{
        padding: 3px 5px 0 6px;
      }
      .annex-title{
        display: inline-block;
        height: 26px;
        line-height: 26px;
        padding-right: 6px;
        border-radius: 3px;
        cursor: pointer;
        &:hover{
          background-color: #f1f1f1;
        }
        &:active{
          background-color: #e8e8e8;
        }
      }
      .no-annex{
        margin-left: 10px;
        border-left: 1px solid #D7D7D7;
        padding-left: 15px;
        color: #afafaf;
      }
.mail-info-header1{
  padding-left: 14px;
  display: flex;
  flex: 1;
  align-items: center;
}
.mail-info-header2{
  padding-top: 8px;
}
}
.border-bottom{
    border-bottom: 1px solid #D7D7D7;
}
.mail-input{
  color: #1d1b1b;
  outline: none;
  border: none;
  width: 100%;
  // padding-left: 7px;
}
.create-footer{
  padding: 20px 0;
  position: fixed;
  bottom: 0;
}
.calendarheader{
  background-color: #f2f4f5;
  padding: 15px 20px;
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.share-header{
  background-color: #f2f4f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 20px;
  .con{
    display: flex;
    margin-top: 30px;
  }
  .menu{
    cursor: pointer;
    height: 40px;
    border-radius: 5px 5px 0 0;
    border: 1px solid #D7D7D7;
    line-height: 40px;
    text-align: center;
    /* width: 150px; */
    padding: 0 10px;
    color: #1d1b1b,;
  }
  .selected{
    background-color: #fff;
    border-bottom: none;
    font-weight: bold;
  }
}
.share-top-btn{
  padding: 10px 0;
  /* width: 100px; */
}
.contact-con{
  float: left;
  width: 200px;
  height: 300px;
  border: 1px solid #dcdfe6;
  overflow: auto;
  .title{
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #f2f4f5;
  }
}
.arrow{
  height: 300px;
  float: left;
  line-height: 300px;
  padding: 0 10px;
}
.mail{
  padding: 2px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .flex-title{
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1d1b1b;
  }
  .showclose{
    display: none;
  }
  .mail-item{
    color: #1d1b1b;
  }
  &:hover{
    background-color: #e4e4e4;
    .showclose{
    display: block;
  }
  }
}
.tagscolor-con{
  display: flex;
  justify-content: space-between;

}
.tagscolor{
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
}
/deep/ .el-tree{
  // margin-left: 20px;
  // margin-right: 20px;
  .tree-node{
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      display: inline-block;
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      color: #1d1b1b;
    }
  }
  .el-tree-node__content{
    height: 34px;
    border-radius: 5px;
    margin-bottom: 3px;
    padding-right: 8px;
  }
}
/deep/.color-form-item{
  .el-form-item__content{
    line-height: 18px;
  }
}
/deep/.createEvent-custom{
  .el-dialog__body{
    padding: 0 35px 20px 35px;
  }
  .dialog-footer{
    display: flex;
    justify-content: space-between;
  }
}
.share-detail{
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  .title{
    font-size: 16px;

  }
  .search{
    display: flex;
    justify-content: space-between;
  }
}
.addshareuser{
  cursor: pointer;
  padding: 3px 10px;
  border: 1px solid #ebeef5;
  border-top: none;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
}
.addsharebtn{
  padding-top: 10px;
}
.attachment{
      flex-wrap: wrap;
      justify-content: flex-start;
      white-space: normal;
      .attachment-item{
        min-width: 290px;
        background: #F9F9F9;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #D7D7D7;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        display: inline-block;
        .attachment-icon{
          position: relative;
          margin-right: 8px;
          .atticon{
            width: 2.6em;
            height: 2.6em;
            margin: 5px 8px;
          }
        }
        .tag{
          font-size: 12px;
          background-color: #FF0000;
          display: inline-block;
          color: #fff;
          padding: 1px 6px;
          // font-size: 12px;
          border-radius: 11px;
          line-height: 17px;
          position: absolute;
          top: -11px;
          left: 9px;
        }
        .attachment-info{
          flex: 1;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-evenly;
          padding: 7px 0;
          .bottom{
            margin-top: 5px;
          }
          .title{
            margin-right: 5px;
            display: inline-block;
            max-width: 145px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .size{
            color: #A3A3A3;
          }
          .preview{
            margin-right: 17px;
             cursor: pointer;
          }
          .delete{
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
    .detail-title{
      padding: 10px 0;
      line-height: 30px;
      border-bottom: 1px solid #ccc;
      p{
        word-break: break-all;
      }
    }
    .detail-body{
      padding: 20px 0;
    }
    .detail-icon{
      white-space: nowrap;
      i{
        margin-left: 10px;
        font-size: 18px;
      }
    }
.accept-info{
  .tag{
    width: fit-content;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.06);
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #D8D8D8;
  }
  .table{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.reselect{
  display: inline-block;
  padding: 3px 10px;
  border-left: 1px solid #dcdfe6;
  margin-left: 3px;
  .title{
    padding-left: 5px;
  }
}
/deep/.el-table__body-wrapper{
  overflow-y: auto;
}
/deep/.searchInput{
  .el-input__inner{
    border: 1px solid #dcdfe6!important;
  }
}
.searchlists{
  position: absolute;
  background-color: #fff;
  border-radius: 3px;
  opacity: 1;
  border: 1px solid #dcdfe6;
  z-index: 999;
  max-height: 300px;
  overflow: auto;
}
.dropdown__item{
  font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #313234;
    line-height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
      background-color: #F0F0F1;
    }
}
.errmsg{
  margin-top: -18px;
  margin-left: 100px;
  color:red;
  font-size: 12px;
}
.tagsinput{
  /deep/.el-input__inner{
    padding-right: 60px;
  }
}
/deep/.el-radio-button__inner{
  padding: 7px 15px;
  font-size: 14px;
}
/deep/.el-radio-button:first-child .el-radio-button__inner{
  border-radius: 2px 0 0 2px;
}
/deep/.el-radio-button:last-child .el-radio-button__inner{
  border-radius: 0 2px 2px 0;
}
/deep/.el-table .cell{
  color: #1d1b1b;
}
/deep/.el-table th.el-table__cell>.cell{
  color: #909399;
}
.cal_time{
  position: relative;
  &::before{
  content: '*';
  position: absolute;
  color: red;
  left: -7px;
}
}
/deep/.el-date-editor .el-range-input{
  color: #1d1b1b;
}
/deep/.el-input__inner{
  color:#1d1b1b;
}
.showfeedbacks{
  display: flex;
  // justify-content: space-between;
}
.tagarea{
  position: absolute;
  top: 50px;
  bottom: 290px;
  overflow: auto;
  width: 100%;
}
/deep/.el-table {
  border-color: #d6d9e0;
  .el-table__cell {
  border-color: #d6d9e0;
}
.cell{
  padding-left:15px
}
}/deep/.el-table--border .el-table__cell:first-child .cell{
  padding-left:15px
}
/deep/.el-table th.el-table__cell>.cell{
  padding-left:15px
}
.underline{
  text-decoration: underline;
}
.btn1{
  display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
}
/deep/.copylink-custom{
  .el-dialog__body{
    padding-top: 10px;
  }
  table,td{
    padding: 8px 0;
  }
}
[v-show] { display: none; }
</style>
<style>

.calendarproper{
  padding: 0!important;
  width: 126px!important;
  margin: auto;
}
.calendarclass{
  padding: 0!important;
  /* width: 100px!important; */
  min-width: 120px!important;

}

</style>
