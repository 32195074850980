<template>
  <div
    class="menu-open"
    :class="$myState.menuShowFlag ? 'menu-open-position' : 'menu-close-position'"
    @click="switch_switch"
  >
    <i
      v-if="$myState.menuShowFlag"
      class="el-icon-caret-left"
    />
    <i
      v-else
      class="el-icon-caret-right"
    />
  </div>
</template>
<script>

export default {
  components: {
  },
  filters: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  // 先加载
  created() {
  },
  methods: {
    switch_switch() {
      this.$myState.menuShowFlag = !this.$myState.menuShowFlag
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-open {
  width: 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 1px 1px 1px 1px;
  border: 1px solid #DEDCDC;
  border-left: 0;
  cursor: pointer;
  z-index: 2;
}

.menu-open-position {
  position: absolute;
  top: 49.5%;
  left: 259px;
}

.menu-close-position {
  position: absolute;
  top: 49.5%;
  left: 0px;
  background-color: #f2f4f5;
}
</style>
