<script>
export default {
  // 设置render组件加载
  props: {
    current: {
      type: Object,
      default: () => {},
    },
  },
  // watch: {
  //   current: {
  //     handler(newVal, oldVal) {
  //       console.log(newVal, 'newVal')
  //     },
  //     deep: true, // 深度监听父组件传过来对象变化
  //   },

  // },
  render(createElement) {
    return createElement(this.current.component)
  },

}
</script>
