//
// 这个模块只能由 main.js 使用
//
// 非响应式全局变量
//
const myData = {}

myData.current_user_code = ''

myData.uriDatas = {}
myData.uriModule = ''

// 系统配置
myData.sysConfig = {
  netdisk_prefix: '',
}

// 新邮件
myData.newMailList = []
myData.newMailStamp = 0

// 邮箱列表
myData.mbox_list = []
myData.mbox_true = []

// 标签列表, 监控见 this.$myState.tag_list_plus_watch
myData.tag_list = []
// 是否为标签列表点进来，而不是选中列表点进来 true是   false 不是
myData.is_tag = false

// 从文件夹列表栏,是否自动激活 showMain
myData.tabShowMainFlag = true

//
myData.routeQuery = {}

// 上一次的路由
myData.routeHistroy = {
  modules: '',
  data: '',
}

// 设置菜单树
myData.settingLastSelectedModuleId = 'blacklist-set'
myData.settingSetMenu = [
  {
    show: true,
    isOpen: false,
    value: 'account_security',
    lable: 'accountsec',
    path: 'blacklist-set',
    children: [
      {
        show: true,
        isOpen: false,
        lable: 'changepsw',
        path: 'blacklist-set',
        component: () => import('@/views/set-module/set-view/blacklist-set.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'changeque',
        path: 'modifySecurity',
        component: () => import('@/views/set-module/set-view/modifySecurity.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'checklog',
        path: 'selfServiceLogQuery',
        component: () => import('@/views/set-module/set-view/selfServiceLogQuery.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'auditlog',
        path: 'auditrecordquery',
        component: () => import('@/views/set-module/set-view/auditrecordquery.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'clientset',
        path: 'clientConfiguration',
        component: () => import('@/views/set-module/set-view/clientConfiguration.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'auth',
        path: 'authorizedMail',
        component: () => import('@/views/set-module/set-view/authorizedMail.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'tipsset',
        path: 'remindersettings',
        component: () => import('@/views/set-module/set-user/remindersettings.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'mailmove',
        path: 'mailboxMoving',
        component: () => import('@/views/set-module/set-view/mailboxMoving.vue'),
      },
    ],
  },
  {
    show: true,
    isOpen: false,
    value: 'email_settings',
    lable: 'mailset',
    path: 'displaysettings',
    children: [
      {
        show: true,
        isOpen: false,
        lable: 'displayset',
        path: 'displaysettings',
        component: () => import('@/views/set-module/set-mail/displaysettings.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'sendreve',
        path: 'informationsettings',
        component: () => import('@/views/set-module/set-mail/informationsettings.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'revrule',
        path: 'receivingrules',
        component: () => import('@/views/set-module/set-mail/receivingrules.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'quickreply',
        path: 'quickresponse',
        component: () => import('@/views/set-module/set-mail/quickresponse.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'autoreply',
        path: 'automaticreply',
        component: () => import('@/views/set-module/set-mail/automaticreply.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'autoforword',
        path: 'automaticforwarding',
        component: () => import('@/views/set-module/set-mail/automaticforwarding.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'blacklist',
        path: 'blacklist',
        component: () => import('@/views/set-module/set-mail/blacklist.vue'),
      }, {
        show: true,
        isOpen: false,
        lable: 'whitelist',
        path: 'whitelist',
        component: () => import('@/views/set-module/set-mail/whitelist.vue'),
      }, {
        show: true,
        isOpen: false,
        lable: 'signset',
        path: 'signaturesettings',
        component: () => import('@/views/set-module/set-mail/signaturesettings.vue'),
      }, {
        show: true,
        isOpen: false,
        lable: 'tagset',
        path: 'labelmanagement',
        component: () => import('@/views/set-module/set-mail/labelmanagement.vue'),
      }, {
        show: true,
        isOpen: false,
        lable: 'floderset',
        path: 'foldersettings',
        component: () => import('@/views/set-module/set-mail/foldersettings.vue'),
      },
      // {
      //   show: true,
      //   lable: '日历设置',
      //   path: 'calendarsetting',
      // },
    ],
  },
  {
    show: true,
    isOpen: false,
    value: 'set_notify',
    lable: 'notify',
    path: 'messagenotification',
    children: [
      {
        show: true,
        isOpen: false,
        lable: 'notify',
        path: 'messagenotification',
        component: () => import('@/views/set-module/set-mail/messagenotification.vue'),
      }],
  },
  {
    show: true,
    isOpen: false,
    value: 'common_setting',
    lable: 'general',
    path: 'generalsettings',
    children: [
      {
        show: true,
        isOpen: false,
        lable: 'general',
        path: 'generalsettings',
        component: () => import('@/views/set-module/set-mail/generalsettings.vue'),
      },
      {
        show: true,
        isOpen: false,
        lable: 'skinset',
        path: 'skinsettings',
        component: () => import('@/views/set-module/set-mail/skinsettings.vue'),
      },
    ],
  },
]

// 日历
myData.calendar = { }
myData.calendar.jumpDate = null
myData.calendar.defaultTag = {}
myData.calendar.eventTags = []
myData.calendar.displayTag = []
myData.calendar.displaySubTag = []

export default myData
