<template>
  <transition name="el-fade-in-linear">
    <div
      v-show="value"
      class="popup-container"
      @click.self="hidePopup"
    >
      <div
        v-ClickOutSide="clickOut"
        class="popup"
        :style="{ top: `${popupTop}px`, left: `${popupLeft}px`,width:width+'px' }"
      >
        <div class="liu-header">
          <span class="title">{{ title }}</span>
          <button
            class="btn"
            @click="hidePopup"
          ><i class="el-icon-close" /></button>
        </div>
        <div class="liu-body"> <slot /></div>
        <div class="liu-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import ClickOutSide from 'element-ui/src/utils/clickoutside'
export default {
  directives: { ClickOutSide },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '508',
    },
    title: {
      type: String,
      default: '提示',
    },
  },
  data() {
    return {
      popupTop: 0,
      popupLeft: 0,
      show: true,
    }
  },
  methods: {
    showPopup(event) {
      this.popupTop = event.clientY + window.pageYOffset - 100
      this.popupLeft = event.clientX + window.pageXOffset - 100
      if (Number(this.width) + event.clientX > window.innerWidth) {
        this.popupLeft = this.popupLeft - (Number(this.width) - (window.innerWidth - event.clientX) + 20)
      }
      setTimeout(() => {
        const height = document.querySelector('.popup').clientHeight
        if (Number(height) + event.clientY > window.innerHeight) {
          this.popupTop = this.popupTop - (height - (window.innerHeight - event.clientY) + 20)
        }
      })
    },
    hidePopup(e) {
      // e.stopPropagation()
      this.$emit('onClose')
    },
    clickOut() {
      if (!this.value) return
      this.$emit('onClose')
    },
  },
}
</script>

  <style scoped lang="scss">
  .popup-container {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .popup {
    position: absolute;
    border: 1px solid #D4D4D4;
    opacity: 1;
    box-shadow: 3px 5px 8px 1px rgba(0,0,0,0.16);
    z-index: 10001; /* 确保弹窗位于容器之上 */
    /* 其他样式根据你的需求自定义 */
    width: 300px;
    background-color: #fff;
    border-radius: 2px;
    pointer-events: initial;
  }
  .liu-header{
    padding: 20px 20px 0px;
    .title{
      line-height: 24px;
      font-size: 18px;
      color: #303133;
    }
    .btn{
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 16px;
    }
  }
  .liu-body{
    padding: 5px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .liu-footer{
    padding: 10px 20px 20px;
    box-sizing: border-box;
  }
  </style>
