<template>
  <div class="menu-con">
    <svg-icon
      icon-class="g_selectUser"
      class="g_selectUser"
    />
    <div class="info">{{ contact_title }}-{{ $t('message.contact.num',{num:userlist.length}) }}</div>
    <div
      v-if="contact_type!=='public'"
      class="menu"
    >
      <div
        v-if="gd_id!==$store.state.contact.allandungroup[0]"
        class="Opea-svg-select-noBorder arrow"
      >
        <svg-icon
          icon-class="view_move"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.mv') }}</span>
        <el-select
          v-model="moveValue"
          popper-class="Opea-svg-select-popper"
          :popper-append-to-body="true"
          style="width:135%;position: absolute;left:0;top:-5px;"
          @change="changeMoveHandle"
        >
          <el-option
            :label="$t('message.contact.newgroup')"
            value="addGroup"
          />
          <!-- <el-option
            :label="$t('message.contact.ungroup')"
            value="ungroup"
            :disabled="$route.query.group_name==='未分组'"
          /> -->
          <el-option
            :label="$t('message.contact.ungroup')"
            value="ungroup"
            :disabled="gd_id!==$store.state.contact.allandungroup[1]"
          />
          <el-option
            v-for="item in $store.state.contact.persongroup"
            :key="item.group_id"
            :label="item.group_name"
            :value="item.group_id"
            :disabled="item.group_id===id"
          />
        </el-select>
      </div>
      <div
        v-if="gd_id!==$store.state.contact.allandungroup[1]"
        class="Opea-svg-select-noBorder arrow"
      >
        <svg-icon
          icon-class="view_replyAll"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.copy') }}</span>
        <el-select
          v-model="copyValue"
          popper-class="Opea-svg-select-popper"
          :popper-append-to-body="true"
          style="width:135%;position: absolute;left:0;top:-5px;"
          @change="changeCopyHandle"
        >
          <el-option
            :label="$t('message.contact.newgroup')"
            value="addGroup"
          />
          <el-option
            v-for="item in $store.state.contact.persongroup"
            :key="item.group_id"
            :label="item.group_name"
            :value="item.group_id"
            :disabled="item.group_id===id"
          />
        </el-select>
      </div>
      <div
        class="Opea-svg-select-noBorder arrow"
        @click="deleteUsers"
      >
        <svg-icon
          icon-class="view_del"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.del') }}</span>
      </div>
      <div
        class="Opea-svg-select-noBorder arrow"
        style="margin:0 3px"
        @click="_compose"
      >
        <svg-icon
          icon-class="g_compose"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.tocompose') }}</span>
      </div>
      <div
        class="Opea-svg-select-noBorder arrow"
        @click="exportContact"
      >
        <svg-icon
          icon-class="g_export"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.export') }}</span>
      </div>
    </div>
    <div
      v-else
      class="menu"
    >
      <div
        v-if="$store.state.app.user_permissions.mailbox === 1"
        class="Opea-svg-select-noBorder arrow"
      >
        <svg-icon
          icon-class="g_person"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.addperon') }}</span>
        <el-select
          v-model="addValue"
          popper-class="Opea-svg-select-popper"
          :popper-append-to-body="true"
          style="width:135%;position: absolute;left:0;top:-5px;"
          class="customclass"
          @change="addPersonConatctHandel"
        >
          <el-option
            :label="$t('message.contact.newgroup')"
            value="addGroup"
          />
          <el-option
            :label="$t('message.contact.ungroup')"
            value="ungroup"
          />
          <el-option
            v-for="item in $store.state.contact.persongroup"
            :key="item.group_id"
            :label="item.group_name"
            :value="item.group_id"
          />
        </el-select>
      </div>
      <div
        v-if="$store.state.app.user_permissions.mailbox === 1"
        class="Opea-svg-select-noBorder arrow"
        style="margin:0 3px"
        @click="_compose"
      >
        <svg-icon
          icon-class="g_compose"
          class="icos-size-14 middle"
        />
        <span class="Opea-svg-select-noBorder-span">{{ $t('message.contact.tocompose') }}</span>
      </div>
    </div>
    <CreateGroup
      ref="addGroupRef"
      @updateGroup="getGroupId"
    />
    <form
      v-show="false"
      ref="download_req"
      method="post"
      target="_self"
      action=""
    >
      <input
        ref="task"
        name="task"
        value="download"
      >
      <input
        ref="req_data"
        name="req"
        value=""
      >
      <input
        ref="req_type"
        name="req_type"
        value="json"
      >
    </form>
  </div>
</template>

<script>
import CreateGroup from '@/views/contacts/components/CreateGroup'
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
import serialize from '@/utils/serialize'
// import { transformClass } from '@/utils/utils.js'

export default {
  components: { CreateGroup },
  mixins: [requestContact],
  props: {
    userlist: {
      type: Array,
      default: () => [],
    },
    onHandel: {
      type: Function,
      default: () => {},
    },
    contactsObjData: {
      type: Object,
      default() {
        return {}
      },
    },
    contactsSoleData: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      moveValue: '',
      copyValue: '',
      addValue: '',
      options: this.$store.state.contact.personalCache.group,
      contact_type: '',
      contact_title: '',
      id: '',
      addType: '',
      gd_id: '',
    }
  },
  mounted() {
    const { id, origin, group_name, group_id } = this.contactsObjData
    console.log(this.contactsObjData)
    this.contact_type = origin
    this.contact_title = group_name
    this.gd_id = group_id
    this.id = id
  },
  methods: {
    async changeMoveHandle(e) {
      this.addType = 'move'
      if (e === 'addGroup') {
        this.$refs.addGroupRef.showGroupdialog()
      } else {
        this.move(e)
      }
      this.moveValue = ''
    },
    async move(id) {
      let old = this.id
      if (this.gd_id === this.$store.state.contact.allandungroup[1]) {
        old = 'ungroup'
      }
      const res = await this.moveCopyConacts({
        act: 'move',
        old_group_id: old,
        group_id: id,
        ids: this.userlist.map(id => id.contact_id),
      })
      if (res.status === 200) {
        this.onHandel()
      }
    },
    getGroupId(id) {
      if (this.addType === 'move') {
        this.move(id)
      } else if (this.addType === 'copy') {
        this.copy(id)
      } else {
        this.addPersonConatct(id)
      }
    },
    changeCopyHandle(e) {
      this.addType = 'copy'
      if (e === 'addGroup') {
        this.$refs.addGroupRef.showGroupdialog()
      } else {
        this.copy(e)
      }
      this.copyValue = ''
    },
    async copy(id) {
      let old = ''
      if (this.gd_id === this.$store.state.contact.allandungroup[0]) {
        old = 'all'
      } else {
        old = this.id
      }
      const res = await this.moveCopyConacts({
        act: 'copy',
        old_group_id: old,
        group_id: id,
        ids: this.userlist.map(id => id.contact_id),
      })
      if (res.status === 200) {
        this.onHandel()
      }
    },
    deleteUsers() {
      const ids = this.userlist.map(id => id.contact_id)
      let title = ''
      if (this.$store.state.contact.allandungroup.includes(this.gd_id)) {
        title = this.$t('message.contact.info20', { num: this.userlist.length })
      } else {
        title = this.$t('message.contact.info21', { num: this.userlist.length })
      }
      this.$confirm(title, this.$t('message.contact.info3'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
      }).then(async() => {
        let gid = ''
        if (!this.$store.state.contact.allandungroup.includes(this.gd_id)) {
          gid = this.id
        }
        const res = await this.deleteContact(ids, gid)
        if (res.status === 200) {
          this.onHandel()
          this.$message.success(this.$t('message.info.m1'))
        }
      }).catch(() => {

      })
    },
    addPersonConatctHandel(e) {
      this.addType = 'addperson'
      if (e === 'addGroup') {
        this.$refs.addGroupRef.showGroupdialog()
        this.addType = 'addperson'
      } else {
        this.addPersonConatct(this.addValue)
      }
      this.addValue = ''
    },
    async addPersonConatct(id) {
      if (this.userlist.length > 1) {
        const params = {
          module: 'contact',
          require: {
            action: 'multiAdd',
            contact_id: '',
            data: {
              contact_list: this.userlist.map(contact => {
                return { mail: contact.mail, name: contact.name }
              }),
              group_list: [id],
            },
          },
        }
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          if (result.status === 200) {
            this.$message.success(this.$t('message.contact.info22', { num: this.userlist.length }))
            this.onHandel()
          }
        })
      } else {
        const params = {
          module: 'contact',
          require: {
            data: {
              contact_attrs: {
                cellphone: '',
                company: '',
                mail: this.userlist[0].mail,
                name: this.userlist[0].name,
                notes: '',
              },
              contact_id: '',
              contact_mail: this.userlist[0].mail,
              contact_name: this.userlist[0].name,
              group_list: [id],
            },
            action: 'update',
          },

        }
        const res = await this.addNewContact(params)
        if (res.status === 200) {
          this.onHandel()
        }
      }
      this.addValue = ''
    },
    // 导出通讯录
    exportContact() {
      const ids = this.userlist.map(id => id.contact_id)
      const params = {
        module: 'contact',
        ids: ids.toString(),
        disposition: 'attachment',
      }
      const url = process.env.NODE_ENV === 'production' ? `./mail.php/contact(${this.userlist.length}).csv` : `./nsmail/mail.php/contact(${this.userlist.length}).csv`
      this.$refs.download_req.action = url
      this.$refs.req_data.value = serialize(params)
      this.$refs.req_type = 'json'
      this.$refs.download_req.submit()
    },
    _compose() {
      const data = this.userlist
      data.subject = this.$t('message.mbox.newmail')
      this.$myApi.mainTab.showComposeMail({ type: 'contact', subject: this.$t('message.mbox.newmail'), composeData: data })
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
.menu-con{
   min-width: 300px;
  margin-top: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    .g_selectUser{
        width: 6em;
        height: 6em;
    }
    .menu{
        display: flex;
    }
    .info{
        padding: 29px 0 53px 0;
    }
    .arrow{
        cursor: pointer;
    }
}
.customclass{
/deep/.el-input__suffix{
  display: none;
}
}
</style>
