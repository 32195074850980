<template>
  <div class="aside-work-con">
    <div
      v-for="(item,index) in workData"
      :key="index"
      :class="item.path===$myState.homeRouter&&'work-div-click'"
      @click="linkFn(item)"
    >
      <div
        v-if="item.show"
        class="work-div"
      >
        <svg-icon
          :class="item.path===$myState.homeRouter?'svg-theme-color':'svg-original-color'"
          :icon-class="item.icon"
          :style="{'margin-bottom':item.path==='contact'?'2px':'',width:item.path==='contact'?'1.3em':''}"
        />
        <span style="text-align:center">{{ $t(`message.mainpage.${item.name}`) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload', 'msg'],
  data() {
    return {
      clickClassSvg: '',
      workData: [{
        name: 'home',
        icon: 'work_home',
        path: 'home',
        // show: this.$store.state.app.auth_info.curr_auth_mail ? this.$store.state.app.auth_authority === 2 : true,
        show: true,
      },
      {
        name: 'mail',
        icon: 'work_mail',
        path: 'mail',
        // show: this.$store.state.app.user_permissions.mailbox === 1,
        show: true,
      }, {
        name: 'addressbook',
        icon: 'work_contacts',
        path: 'contact',
        // show: this.$store.state.app.auth_info.curr_auth_mail ? this.$store.state.app.auth_authority === 2 : true,
        show: true,
      }, {
        name: 'netdisk',
        icon: 'work_network_disk',
        path: 'netdisk',
        // show: this.$store.state.app.auth_info.curr_auth_mail ? (this.$store.state.app.auth_authority === 2 && this.$store.state.app.user_permissions.netdisk === 1) : this.$store.state.app.user_permissions.netdisk === 1,
        show: true,
      },
      {
        name: 'calendar',
        icon: 'work_programme',
        path: 'calendar',
        // show: !this.$store.state.app.auth_info.curr_auth_mail && this.$store.state.app.user_permissions.open_calendar === 1,
        show: true,
      },
      ],
    }
  },
  computed: {
    isPremiss() {
      return this.$store.state.app.user_permissions
    },
    /** 监听是否切换了授权 */
    isCurrAuthWatch() {
      return this.$store.state.app.auth_info.curr_auth_mail
    },
    /** 1.不是授权，通讯录显示，企业通讯录看管理端是否开启通讯录
     * 2.是授权。授权了“管理权限”，通讯录显示(企业通讯录看管理端是否开启通讯录)
     * 3.是授权。授权读信、写信权限，不显示通讯录
     */
    is_contactsShow() {
      let flag = true
      if (this.$store.state.app.auth_info.curr_auth_mail) { // 是授权
        // 没有授权管理权限
        if (this.$store.state.app.auth_authority !== 2) flag = false
      }
      return flag
    },
    /** 1.不是授权，网盘看是否开启决定是否显示
     * 2.是授权。授权了“管理权限”，网盘看是否开启决定是否显示
     * 3.是授权。授权读信、写信权限，不显示网盘
     */
    // is_networkShow() {
    //   let flag = true
    //   if (this.$store.state.app.auth_info.curr_auth_mail) { // 是授权
    //     // 有授权管理权限
    //     if (this.$store.state.app.auth_authority === 2 && this.$store.state.app.user_permissions.netdisk === 1) flag = false
    //   }
    //   this.$store.state.app.user_permissions.netdisk === 1
    //   return flag
    // },

  },
  watch: {
    isCurrAuthWatch(newVal, oldVal) {
      if (newVal) {
        this.refresh()
      }
    },
    isPremiss(newVal, oldVal) {
      this.workData.map((el) => {
        if (el.path === 'home') el.show = this.$store.state.app.auth_info.curr_auth_mail ? this.$store.state.app.auth_authority === 2 : true
        if (el.path === 'contact') el.show = this.$store.state.app.auth_info.curr_auth_mail ? this.$store.state.app.auth_authority === 2 : true
        if (el.path === 'netdisk') el.show = this.$store.state.app.auth_info.curr_auth_mail ? (this.$store.state.app.auth_authority === 2 && this.$store.state.app.user_permissions.netdisk === 1) : this.$store.state.app.user_permissions.netdisk === 1
        if (el.path === 'calendar') el.show = !this.$store.state.app.auth_info.curr_auth_mail && this.$store.state.app.user_permissions.open_calendar === 1
        if (el.path === 'mail') el.show = this.$store.state.app.user_permissions.mailbox === 1
      })
    },
  },
  created() {
  },
  methods: {
    refresh() {
      this.reload
    },
    linkFn(item) {
      const path = item.path
      this.$myApi.topGoto(path)
    },
  },
}
</script>
<style lang="scss" scoped>
.aside-work-con {
    width: 100%;
    background: #F2F4F5;
}
.work-div{
    width: 100%;
    height: 70px;
    margin-top: 4px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    // font-weight: bold;
    color:#060606;
    cursor: pointer;
}

</style>
