
export function mboxListToTree(resResult) {
  if (!resResult || !resResult.mboxList) {
    return []
  }

  const mboxList = resResult.mboxList
  const system_mboxList = {
    INBOX: 1,
    Sent: 1,
    Drafts: 1,
    rubbishs: 1,
    trash: 1,
    archive: 1,
  }

  for (var i = 0; i < mboxList.length; i++) {
    var fo = mboxList[i]
    fo.__used_ASDF = 0
    fo.name = ''
    fo.is_system = false
    if (fo.mbox in system_mboxList) {
      fo.is_system = true
    }
  }
  function findByParent(parent_name, level) {
    var s = ''
    var rr = s.split('')
    for (var i = 0; i < mboxList.length; i++) {
      var fo = mboxList[i]
      if (fo.__used_ASDF) {
        continue
      }
      var fname = '/' + fo.mbox
      var f_vec = fname.split('/')
      if (parent_name.split('/').length !== f_vec.length) {
        continue
      }
      f_vec[f_vec.length - 1] = ''
      if (parent_name !== f_vec.join('/')) {
        continue
      }
      fo.__used_ASDF = 1
      fo.name = fo.mbox_name.split('/').pop()
      fo.children = findByParent('/' + fo.mbox + '/', level + 1)
      fo.path = '/empty-page'
      if (fo.subscribe === '1') rr.push(fo)
    }
    return rr
  }
  const rr = findByParent('/', 0)
  resResult.mboxTree = rr
  return rr
}

export function mboxTreeToFolderTreeData(mboxTree) {
  const rr = []
  mboxTree.forEach(o => {
    if (o.is_system) {
      return
    }
    if (o.subscribe === '1') rr.push(o)
  })
  return rr
}
