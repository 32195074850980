<template>
  <div class="firstBox">
    <el-dialog
      :title="title"
      width="620px"
      :visible.sync="dialogMailPassword"
      :modal-append-to-body="true"
      :append-to-body="true"
      @close="closeDia"
    >
      <el-input
        v-model="textarea"
        type="textarea"
        :rows="5"
        resize="none"
        :placeholder="$t('message.view.input_content')"
        maxlength="256"
        show-word-limit
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submitForm('add')"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="submitForm('del')">{{ $t('message.view.del') }}</el-button>
      </div></el-dialog>
  </div>
</template>
<script>

import serialize from '@/utils/serialize'
export default ({
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    bzInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      title: this.$t('message.view.mail_quote'), // 修改弱密码
      dialogMailPassword: this.isShow,
      textarea: '',
      newbzInfo: this.bzInfo,
    }
  },
  watch: {
    isShow: function(a, b) {
      if (a) {
        this.dialogMailPassword = a
      }
    },
    bzInfo: function(a, b) {
      console.log(a, 'aaaa')
      if (a) {
        this.newbzInfo = a
        this.textarea = a.comment
      }
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.textarea = this.bzInfo.comment
    })
  },
  methods: {
    submitForm(val) {
      let txt = ''
      if (val === 'add') {
        txt = this.$t('message.view.mail_quote_add_success')
      } else {
        txt = this.$t('message.view.mail_quote_del_success')
      }
      const req = {
        'module': 'mail',
        'require': {
          action: 'addMailComment',
          comment: val === 'add' ? this.textarea : '',
          uid: this.newbzInfo.uid,
          uidvalidity: this.newbzInfo.uidvalidity,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.$message.success(txt)
        // this.$store.state.tagsView.readMail.data.comment
        this.newbzInfo.comment = val === 'add' ? this.textarea : ''
        this.$parent.showMailbzDialog = false
        // 向父组件传值
        this.$emit('showRemark', val === 'add' ? this.textarea : '', this.newbzInfo)
        const readMail = this.$store.state.tagsView.readMail.data ? this.$store.state.tagsView.readMail.data : {}
        readMail.comment = val === 'add' ? this.textarea : ''

        if (JSON.stringify(readMail) !== '{}') {
          if (this.newbzInfo.uid === readMail.uid && this.newbzInfo.uidvalidity === readMail.uidvalidity) {
            // this.$store.commit('tagsView/READ_MAIL_SETTING', { // 读信请求参数
            //   path: '/whole-view', // banner-view
            //   subject: this.newbzInfo.subject,
            //   isOpen: true,
            //   data: {
            //     ...readMail,
            //   },
            // })
          }
        }
      })
    },
    closeDia() {
      this.$parent.showMailbzDialog = false
    },
  },
})
</script>
<style lang="scss" scoped>

  /deep/.el-dialog__body{
    padding: 10px 20px;
  }
</style>
