<template>
  <div class="compose-container">
    <PreviewPage v-if="isPreview" />
    <div
      v-show="!isPreview"
      class="compose-header border-bottom"
    >
      <el-collapse-transition>
        <div
          v-show="showAutoTips"
          class="autosenddiv theme-background-color"
        >{{ $t('message.compose.info11') }}<span
          class="cur"
          style="margin-left:10px"
          @click="cancelAutoSend"
        >[{{ $t('message.compose.info12') }}]</span></div>
      </el-collapse-transition>

      <el-button
        v-no-more-click
        type="primary"
        size="mini"
        class="mail-btn sendbtn"
        :disabled="is_auto_send"
        @click="sendVerifyMailData()"
      >
        <i
          class="el-icon-position"
          style="margin-right:5px"
        />{{ $t('message.compose.send') }}
      </el-button>
      <el-button
        class="mail-btn"
        size="mini"
        :title="''"
        :disabled="is_auto_send"
        @click="timingSend"
      >{{ $t('message.compose.timesend') }}</el-button>
      <el-button
        class="mail-btn"
        size="mini"
        @click="saveDrafts"
      >{{ $t('message.compose.savedraft') }}</el-button>
      <el-button
        class="mail-btn"
        size="mini"
        @click="preview"
      >{{ $t('message.compose.priview') }}</el-button>
      <el-button
        class="mail-btn"
        size="mini"
        @click="cancelCompose"
      >{{ $t('message.compose.cancel') }}</el-button>
      <p
        v-if="outer_is_secure"
        class="aqSetting"
      >
        <el-tooltip
          class="item"
          effect="light"
          placement="top"
        >
          <div
            slot="content"
            style="max-width:500px"
          >{{ aqText }}</div>
          <span><svg-icon icon-class="prompt" /> {{ $t('message.compose.info9') }}</span>
        </el-tooltip>
        <span
          style="margin: 0 15px;"
          class="text-color cur"
          @click="editSecure"
        >{{ $t('message.compose.changeset') }}</span><span
          class="text-color cur"
          @click="delSecure"
        >{{ $t('message.compose.cancelset') }}</span></p>
    </div>
    <div
      v-show="!isPreview"
      class="compose-main"
    >
      <div class="line border-bottom">
        <div class="mail-info-header">
          <span
            class="underline compose-font-colo text-hover"
            :style="{color:separately?'':'#1d1b1b'}"
            @click="openContacts('to')"
          >{{ separately?$t('message.compose.to'):$t('message.compose.single') }}</span>
          <el-tooltip
            v-if="!separately"
            class="item"
            effect="light"
            :content="sendInfo"
            placement="top-start"
          >
            <i
              class="el-icon-warning-outline sendInfo"
              style="color:#1d1b1b"
            />
          </el-tooltip>
        </div>
        <div class="mail-info-header1 address">
          <EmailAddress
            ref="tagsto"
            v-model="tags_to"
            position="1"
          />
        </div>
        <div class="mail-info-header2">
          <span
            class="text-color underline tail"
            @click="sendSeparately"
          >{{ separately?$t('message.compose.single'):$t('message.compose.unsingle') }}</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('message.compose.contact')"
            placement="top"
          >
            <span class="tail-icon">
              <span class="tail-icon-item">
                <svg-icon
                  icon-class="add"
                  class-name="compose-icon"
                  @click="openContacts('to')"
                />
              </span>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div
        v-if="separately"
        class="line border-bottom"
      >
        <div class="mail-info-header">
          <span
            class="underline compose-font-colo text-hover"
            @click="openContacts('cc')"
          >{{ $t('message.compose.cc') }}</span>
        </div>
        <div class="mail-info-header1 address">
          <EmailAddress
            ref="tagscc"
            v-model="tags_cc"
            position="2"
          />
        </div>
        <div class="mail-info-header2">
          <span
            class="text-color underline tail "
            @click="showSecret"
          >{{ showSecretMail?$t('message.compose.unaddBcc'):$t('message.compose.addBcc') }}</span>
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('message.compose.contact')"
            placement="top"
          >
            <span class="tail-icon">
              <span class="tail-icon-item">
                <svg-icon
                  icon-class="add"
                  class-name="compose-icon"
                  @click="openContacts('cc')"
                />
              </span>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div
        v-if="showSecretMail"
        class="line border-bottom"
      >
        <div class="mail-info-header">
          <span
            class="underline compose-font-colo text-hover"
            @click="openContacts('bcc')"
          >{{ $t('message.compose.bcc') }}</span>
        </div>
        <div class="mail-info-header1 address">
          <EmailAddress
            ref="tagsbcc"
            v-model="tags_bcc"
            position="3"
          />
        </div>
        <div class="mail-info-header2">
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('message.compose.contact')"
            placement="top"
          >
            <span class="tail-icon">
              <span class="tail-icon-item">
                <svg-icon
                  icon-class="add"
                  class-name="compose-icon"
                  @click="openContacts('bcc')"
                />
              </span>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div class="line border-bottom">
        <div class="mail-info-header">
          <span
            class="compose-font-colo"
            v-html="$t('message.compose.subject')"
          />
        </div>
        <div class="mail-info-header address">
          <input
            v-model="req.subject"
            type="text"
            class="mail-input"
            maxlength="256"
          >
        </div>
      </div>
      <div
        class="line"
        style="justify-content: flex-start"
      >

        <div class="mail-info-header">
          <span
            class="annex-title compose-font-colo"
            @click="openAttachment"
          >
            <svg-icon
              icon-class="att"
              class-name="compose-icon"
            />
            {{ $t('message.compose.addatts') }}</span>
          <span class="no-annex compose-font-colo">{{ fakeAttachmentList.length===0? $t('message.compose.noneatts'):$t('message.compose.numatts',{num:fakeAttachmentList.length}) }}</span>
        </div>
      </div>
      <div
        v-if="fakeAttachmentList.length!==0"
        class="line attachment"
      >
        <div
          v-for="item in fakeAttachmentList"
          :key="item.upid"
          class="attachment-item"
        >
          <div style="display: flex">
            <div class="attachment-icon">
              <svg-icon
                :icon-class="getFileType(item.name)+'icon'"
                class-name="atticon"
              />
              <div
                v-if="item.size>$store.state.app.upload_mode_limit&&item.type==='splitupload'"
                class="tag"
              >{{ $t('message.compose.big') }}</div>
            </div>
            <div class="attachment-info">
              <div style="display:flex">
                <!-- <span class="title">{{ item.name }}</span> -->

                <el-tooltip
                  class="item"
                  effect="light"
                  placement="top"
                >
                  <div
                    slot="content"
                    style="max-width:500px"
                  >{{ item.name }}</div>
                  <span class="title">{{ item.name }}</span>

                </el-tooltip>
                <span
                  class="size"
                  style="color: #8E8D8D;"
                >
                  <span>({{ item.size|mailSizefilter }})</span>
                </span>
              </div>
              <div
                style="display:flex"
                class="bottom"
              >
                <span
                  v-if="isPreView(item.name)"
                  class="delete text-color el-button--text"
                  @click="previewAtta(item)"
                >{{ $t('message.compose.priview') }}</span>

                <span
                  class="delete text-color el-button--text"
                  @click="downloadAtta(item)"
                >{{ $t('message.compose.download') }}</span>

                <span
                  class="delete text-color el-button--text"
                  @click="handleRemove(item)"
                >{{ $t('message.compose.del') }}</span>
                <span
                  v-if="['pause','error'].includes(item.status)"
                  class="delete text-color el-button--text"
                  @click="handleResumeUpload(item)"
                >{{ $t('message.compose.goon') }}</span>
                <span v-if="item.hashProgress!==100&&item.type==='splitupload'">{{ $t('message.compose.scan',{num:item.hashProgress}) }}</span>
                <el-progress
                  v-if="item.hashProgress==100&&item.percentage!==100"
                  :stroke-width="3"
                  :percentage="item.percentage"
                  style="flex:1"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      <p
        v-if="bigCount>0"
        style="color:#FF1E24;padding-bottom:5px"
      ><svg-icon
        icon-class="warning_tip"
        class-name="warning_tip"
      />{{ $t('message.compose.info13',{num:bigCount}) }}<span v-if="$store.state.app.lifespan_forever==='1'" /><span v-else>{{ $t('message.compose.info14',{num:$store.state.app.station_lifespan}) }}</span></p>

      <Tinymce
        ref="tinymceED"
        v-model="req.body"
        :on-init="editorInit"
        :data-change="dataChange"
      />

    </div>
    <div
      v-show="!isPreview"
      class="compose-footer"
    >
      <div
        v-show="moreProps"
        class="compose-footer-moreInfo"
      >
        <!-- <el-checkbox
          v-model="mailpsw"
          :true-label="1"
          :false-label="0"
        >邮件密码</el-checkbox> -->
        <!-- <el-checkbox
          v-model="expireMail"
          :disabled="req.is_timing===1"
          :title="req.is_timing?'该属性不能与定时发送同时设置':''"
          :true-label="1"
          :false-label="0"
        >过期时间</el-checkbox> -->
        <!-- <el-checkbox
          v-model="req.readed_destory"
          :true-label="1"
          :false-label="0"
        >阅读后销毁</el-checkbox> -->
        <!-- <el-checkbox
          v-model="req.undownload"
          :true-label="1"
          :false-label="0"
        >禁止下载</el-checkbox> -->
        <!-- <el-checkbox
          v-model="req.untransmit"
          :true-label="1"
          :false-label="0"
        >禁止转发</el-checkbox> -->
        <!-- <el-checkbox
          v-model="req.unprint"
          :true-label="1"
          :false-label="0"
        >禁止打印</el-checkbox> -->
        <!-- <el-checkbox
          v-model="req.unresponse"
          :true-label="1"
          :false-label="0"
        >禁止回复</el-checkbox> -->
        <el-checkbox
          v-if="showWaterMark"
          v-model="req.is_watermark"
          :true-label="1"
          :false-label="0"
        >{{ $t('message.compose.watermark') }}</el-checkbox>
      </div>
      <div class="compose-footer-info">
        <div class="signature formname">
          <span class="title">{{ $t('message.compose.from') }}：</span>
          <el-select
            ref="fromselect"
            v-model="req.from_address"
            :placeholder="$t('message.compose.info15')"
            class="custom-select"
          >
            <el-option
              v-for="(item,index) in AliasList"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="signature">
          <span class="title">{{ $t('message.compose.singn') }}：</span>
          <el-select
            ref="sinaselect"
            v-model="req.identity"
            :placeholder="$t('message.compose.info15')"
            class="custom-select"
            @change="selecIdentity"
          >
            <el-option
              v-for="item in identity"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="info-item">
          <el-checkbox
            v-model="req.is_save_sent"
            :true-label="1"
            :false-label="0"
          >{{ $t('message.compose.saveto') }}</el-checkbox>
          <el-checkbox
            v-model="req.priority"
            :true-label="1"
            :false-label="0"
          >{{ $t('message.compose.emergent') }}</el-checkbox>
          <el-checkbox
            v-model="req.receipt"
            :true-label="1"
            :false-label="0"
          >{{ $t('message.compose.receipt') }}</el-checkbox>
          <el-checkbox
            v-model="outer_is_secure"
            :true-label="1"
            :false-label="0"
            @change="secureFun"
          >{{ $t('message.compose.securemail') }}</el-checkbox>
          <el-checkbox
            v-if="showWaterMark"
            v-model="req.is_watermark"
            :true-label="1"
            :false-label="0"
          >{{ $t('message.compose.watermark') }}</el-checkbox>
        </div>
      </div>
    </div>
    <!--定时发送弹窗-->
    <el-dialog
      :title="$t('message.compose.settimesend')"
      :visible.sync="showSendTimer"
      :append-to-body="false"
      :modal-append-to-body="false"
      width="640px"
      custom-class="timingcustom"
    >
      <div class="sendTime">
        <div class="sendTimeTip">
          <i class="el-icon-warning-outline sendInfo" />
          <span class="tip">
            {{ $t('message.compose.info16') }}
          </span>
        </div>
        <div class="sendTimeOption">
          <div style="margin-bottom:15px;color:#1d1b1b"><span>{{ $t('message.compose.info17') }}:</span></div>
          <el-form
            ref="timingRef"
            :model="timingForm"
            :rules="timingRules"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label=""
                  prop="delay_date"
                >
                  <el-date-picker
                    v-model="timingForm.delay_date"
                    type="date"
                    :placeholder="$t('message.compose.info18')"
                    class="date-picker-item customselect"
                    value-format="yyyy-MM-dd"
                    style="width:97%"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label=""
                  prop="delay_time"
                >
                  <el-time-picker
                    v-model="timingForm.delay_time"
                    value-format="HH:mm"
                    format="HH:mm"
                    :placeholder="$t('message.compose.info19')"
                    style="width:100%"
                    class="customselect"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="margin-bottom:15px"><span style="color:#1d1b1b">{{ $t('message.compose.info20') }}:</span></div>
        <div class="date-picker">
          <el-select
            v-model="zone"
            :placeholder="$t('message.compose.info15')"
            style="width:100%"
          >
            <el-option
              v-for="item in timeZone"
              :key="item.value"
              :label="$t(`message.compose.${item.label}`)"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="confirmshowSendTimer"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="cancelSendTimer">{{ $t('message.btnText.cancel') }}</el-button>
      </span>
    </el-dialog>
    <!-- 邮件密码弹窗 -->
    <!-- <el-dialog
      title="设置邮件密码"
      :visible.sync="showSettingPsw"
      :append-to-body="false"
      :modal-append-to-body="false"
      width="520px"
      custom-class="expireCustom"
      @close="showSettingPswClose"
    >
      <div
        v-if="showSettingPsw"
        class="sendTime"
      >
        <div class="sendTimeTip">
          <i class="el-icon-warning-outline sendInfo" />
          <span class="tip">
            设置成功后，收件人输入以下密码，才可以查看邮件内容
          </span>
        </div>
        <div class="sendTimeOption">
          <div class="mail-password">
            <el-form
              ref="form"
              :model="req"
              label-width=""
              label-position="left"
              :rules="reqRules"
            >
              <el-form-item
                label=""
                prop="mail_psw"
                label-width="130px"
              >
                <template slot="label">
                  <span style="color:#1d1b1b">设置查看密码:</span>
                </template>
                <el-input
                  v-model.trim="req.mail_psw"
                  placeholder="密码长度6-32位，数字、字母和特殊字符"
                  :type="showeye?'text':'password'"
                  class="custominput"
                  maxlength="32"
                >

                  <svg-icon
                    slot="suffix"
                    :icon-class="showeye?'eye_show':'eye_close'"
                    class-name="eye-icon"
                    @click="showeye=!showeye"
                  />
                </el-input>
              </el-form-item>
            </el-form>
          </div>

        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="confirmSettingPsw"
        >确 定</el-button>
        <el-button @click="cancelSettingPsw">取 消</el-button>
      </span>
    </el-dialog> -->
    <!-- 过期时间弹窗 -->
    <!-- <el-dialog
      title="设置过期时间"
      :visible.sync="showExpireMail"
      :append-to-body="false"
      :modal-append-to-body="false"
      width="520px"
      custom-class="expireCustom"
      @close="showExpireMailClose"
    >
      <div
        v-if="showExpireMail"
        class="sendTime"
      >
        <div class="sendTimeTip">
          <i class="el-icon-warning-outline sendInfo" />
          <span class="tip">
            设置成功后，邮件在指定的有效期后，将无法提供查阅
          </span>
        </div>
        <div class="sendTimeOption">
          <el-form
            ref="formExpire"
            :model="req"
            label-width="80px"
            label-position="left"
            :rules="expireRules"
          >
            <el-form-item
              label="设置有效期:"
              prop="expire_time"
              label-width="130px"
            >
              <el-input
                v-model="req.expire_time"
                placeholder="请输入1-240之间的数字"
              >
                <template slot="append">小时</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="confirmExipreTime"
        >确 定</el-button>
        <el-button @click="cancelExipreTime">取 消</el-button>
      </span>
    </el-dialog> -->
    <!--    上传附件弹窗-->
    <el-dialog
      :visible.sync="showUpload"
      width="780px"
      custom-class="previewDialog"
      :append-to-body="true"
      :modal-append-to-body="true"
      top="5vh"
    >
      <div class="upload-container">
        <div class="nav">
          <div
            :class="['nav-item' ,itemIndex===1?'navitem-active':'']"
            @click="handoff(1)"
          >{{ $t('message.compose.loaclfile') }}</div>
          <div
            :class="['nav-item',itemIndex===2?'navitem-active':'']"
            @click="handoff(2)"
          >{{ $t('message.compose.myfile') }}</div>
          <div
            v-if="$store.state.app.user_permissions.netdisk === 1"
            :class="['nav-item',itemIndex===3?'navitem-active':'']"
            @click="handoff(3)"
          >{{ $t('message.compose.netfile') }}</div>
        </div>
        <div class="content">
          <div
            v-show="itemIndex === 1"
            class="my-att"
          >
            <div
              v-if="uploadFiles.length>0"
              class="attArea"
              style="height:260px;max-height: inherit;"
            >
              <div
                v-for="(item,index) in uploadFiles"
                :key="index"
                class="attArea-item"
              >
                <div class="img">
                  <svg-icon
                    :icon-class="getFileType(item.name)+'icon'"
                    class-name="atticon"
                  />
                </div>
                <div class="info">
                  <div class="filename">
                    <span
                      v-title="item.name"
                      class="name"
                    >{{ item.name }}</span>
                    <span class="size"><span>({{ item.size|mailSizefilter }})</span></span>
                  </div>
                  <div class="handle">
                    <span
                      class="text-color"
                      style="margin-right:10px;padding:5px 0"
                      @click="handleRemovepload(item)"
                    >{{ $t('message.compose.del') }}</span>
                    <!-- <span>{{ item.hashProgress==100?'扫描完成':`文件扫描中${item.hashProgress}%` }}</span> -->
                  </div>
                </div>
              </div>
            </div>
            <SplitUpload
              ref="uploadfils"
              style="flex:1"
              :on-change="fileChange"
              :on-success="fileUploadSuccess"
              :on-remove="fileRemove"
              :on-start="handleStart"
              :before-upload="handleBefore"
              drag
            />
          </div>
          <div
            v-show="itemIndex === 2"
            class="attArea"
            style="height: 377px;overflow:hidden;max-height:inherit;"
          >
            <div class="localAtts">
              <el-tabs
                v-model="activeName"
              >
                <el-tab-pane
                  :label="$t('message.compose.fileman')"
                  name="attsmanger"
                >
                  <div
                    style="height:285px;overflow:auto"
                  >
                    <div
                      v-if="!atts_manager.length"
                      style="text-align: center;line-height: 285px;"
                    >{{ $t('message.compose.nodate') }}</div>
                    <div
                      v-for="(att,index) in atts_manager"
                      :key="att.id"
                      class="attArea-item"
                      style="max-height:100%;"
                    >
                      <div>
                        <el-checkbox
                          v-model="att.checked"
                          :label="index"
                          class="css-checkbox"
                          @change="attsManagerChange(att,$event)"
                        />
                      </div>
                      <div
                        class="img"
                        style="margin-right:10px;margin-left:0"
                      >
                        <svg-icon
                          :icon-class="getFileType(att.att_name)+'icon'"
                          class-name="atticon"
                        />
                      </div>
                      <div class="filename">
                        <span
                          :class="['name',att.checked?'fw':'']"
                          style="color:#1D1B1B"
                        >{{ att.att_name }}</span>
                    &nbsp;&nbsp;
                        <span
                          class="size"
                          style="color: #8E8D8D;"
                        >
                          (<span
                            v-format-bytes="att.att_size"
                          />)
                        </span>

                      </div>
                    </div>
                  </div>
                  <el-pagination
                    :current-page="atts.require.from"
                    :page-size="atts.require.limit"
                    layout="total, prev, pager, next, jumper"
                    :total="attstotal"
                    style="margin-top:10px;"
                    @current-change="handleCurrentChange"
                  />
                </el-tab-pane>
                <el-tab-pane
                  :label="$t('message.compose.filetrans')"
                  name="attstemp"
                >
                  <div
                    style="height:320px;overflow:auto"
                  >
                    <div
                      v-if="!attsTransferList.length"
                      style="text-align: center;line-height: 320px;"
                    >{{ $t('message.compose.nodate') }}</div>
                    <div
                      v-for="(att,index) in attsTransferList"
                      :key="index"
                      class="attArea-item"
                      style="max-height:100%;"
                    >
                      <div>
                        <el-checkbox
                          v-model="att.checked"
                          :label="index"
                          class="css-checkbox"
                          @change="attsTransferChange(att,$event)"
                        />
                      </div>
                      <div
                        class="img"
                        style="margin-right:16px;margin-left:0"
                      >
                        <svg-icon
                          :icon-class="getFileType(att.nd_name)+'icon'"
                          class-name="atticon"
                        />
                      </div>
                      <div class="filename">
                        <span
                          :class="['name',att.checked?'fw':'']"
                          style="color:#1D1B1B"
                        >{{ att.nd_name }}</span>
                    &nbsp;&nbsp;
                        <span
                          class="size"
                          style="color: #8E8D8D;"
                        >
                          (<span
                            v-format-bytes="Number(att.mail_attachment_size)"
                          />)
                        </span>

                      </div>
                    </div>

                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div
            v-show="itemIndex === 3"
            class="attArea"
            style="height: 377px;overflow:hidden;max-height:inherit;"
          >
            <div style="height: 419px;">
              <el-tabs
                v-model="activeName2"
                @tab-click="tabClick"
              >
                <el-tab-pane
                  v-for="(item,index) in tablist"
                  :key="index"
                  :label="$t(`message.compose.${item.label}`)"
                  :name="item.name"
                >
                  <div v-if="activeName2==='all'">
                    <span
                      v-for="item in breadcrumbs"
                      :key="item.id"
                      style="cursor:pointer;margin-left:5px"
                      class="el-link el-link--default"
                      @click="jumpCreadCrumb(item)"
                    >{{ `>${item.isD?$t('message.compose.'+item.label):item.label}` }}</span>
                  </div>
                  <div
                    style="height:300px;overflow:auto"
                  >
                    <div
                      v-if="!item.files.length"
                      style="text-align: center;line-height: 300px;"
                    >{{ $t('message.compose.nodate') }}</div>
                    <div
                      v-for="file in item.files"
                      :key="file.nd_id"
                      class="attArea-item"
                      :style="{'max-height':'100%','padding-left':file.nd_is_dir==='1'?'15px':'',cursor:file.nd_is_dir==='1'?'pointer':''}"
                      @click="getFileBycatalog(file)"
                    >
                      <div>
                        <el-checkbox
                          v-if="file.nd_is_dir==='0'"
                          v-model="file.checked"
                          :label="index"
                          class="css-checkbox"
                          @change="attsNetdiskChange(file,$event)"
                        />
                      </div>
                      <div
                        class="img"
                        style="margin-right:16px;margin-left:0"
                      >
                        <svg-icon
                          :icon-class="file.nd_is_dir==='1'?'g_folder':getFileType(file.nd_name)+'icon'"
                          class-name="atticon"
                        />
                      </div>
                      <div class="filename">
                        <span
                          :class="['name',file.checked?'fw':'']"
                          style="color:#1D1B1B"
                        >{{ file.nd_name?file.nd_name:$t('message.compose.superatt') }}</span>
                    &nbsp;&nbsp;
                        <span
                          v-if="file.nd_is_dir==='0'"
                          class="size"
                          style="color: #8E8D8D;"
                        >
                          (<span
                            v-format-bytes="file.nd_size"
                          />)
                        </span>

                      </div>
                    </div>

                  </div>

                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelUploadFiles">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="confirmUploadFiles"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <!-- 安全属性弹窗 -->
    <el-dialog
      :title="$t('message.compose.securemail')"
      :visible.sync="showSecure"
      :append-to-body="true"
      :modal-append-to-body="false"
      width="780px"
      @close="secureFunClose"
    >
      <div
        class="secureBox"
        :class="outer_protect_type === 1 ? 'secureBorder' : ''"
        @click="protect_click(1)"
      >
        <el-radio
          v-model="outer_protect_type"
          class="radio"
          :label="1"
        />
        <div>
          <h1>{{ $t('message.compose.secureprop') }}</h1>
          <p>{{ $t('message.compose.info1') }}
            <span class="ts"><svg-icon
                               icon-class="prompt"
                               class="icos-size-14 svgIcon"
                             /> {{ $t('message.compose.info37') }}
              <el-tooltip
                class="item"
                effect="light"
                :content="previewType"
                placement="bottom"
              >
                <div
                  slot="content"
                  style="max-width:500px"
                >{{ previewType }}</div>
                <b style=" text-decoration:underline;">{{ $t('message.compose.info38') }}</b>
              </el-tooltip>
            </span></p>

          <span class="line" />
          <h2>
            <el-switch
              v-model="outer_readed_destory"
              active-value="1"
              inactive-value="0"
              class="switchBtn"
            />
            {{ $t('message.compose.destroy') }}
          </h2>
          <p>{{ $t('message.compose.info3') }}</p>
          <h2>
            <el-select
              v-model="outer_expire_time_type"
              class="delsel del_expire"
              :placeholder="$t('message.compose.info15')"
              @change="expireFun"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="$t(`message.compose.${item.label}`)"
                :value="item.value"
              />
            </el-select>
            <el-date-picker
              v-if="outer_expire_time_type === '4'"
              v-model="outer_expire_time"
              popper-class="popperClass"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions0"
              class="delsel xzdate"
              type="datetime"
              :placeholder="$t('message.compose.info21')"
            />
            {{ $t('message.compose.info22') }}
          </h2>
          <p>{{ $t('message.compose.info4') }}</p>
        </div>
      </div>

      <div
        class="secureBox"
        :class="outer_protect_type === 2 ? 'secureBorder' : ''"
        @click="protect_click(2)"
      >
        <el-radio
          v-model="outer_protect_type"
          class="radio"
          :label="2"
        />
        <div>
          <h1>{{ $t('message.compose.mailpsw') }}</h1>
          <p>{{ $t('message.compose.info5') }}</p>
          <p
            v-if="outer_protect_type===2"
            class="mail-password"
          >{{ $t('message.compose.setpsw') }}：
            <el-input
              v-model.trim="outer_mail_psw"
              autocomplete="off"
              width="400px"
              :placeholder="$t('message.compose.info6')"
              :type="showeye?'text':'password'"
              class="custominput"
              maxlength="32"
            >
              <svg-icon
                slot="suffix"
                :icon-class="showeye?'eye_show':'eye_close'"
                class-name="eye-icon"
                @click="showeye=!showeye"
              />
            </el-input>
          </p>

        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="confirmSecureFun"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="secureFunClose">{{ $t('message.btnText.cancel') }}</el-button>
      </span>
    </el-dialog>
    <ContactsPopper
      ref="ContactsPopper"
      :title="$t('message.mainpage.addressbook')"
      :to="tags_to"
      :cc="tags_cc"
      :bcc="tags_bcc"
      @get-address-data="getAdressData"
    />
    <!-- 下载附件 -->
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />
  </div>
</template>

<script>
import downAttaForm from '../components/downAtta.vue'
import PreviewPage from './preview'
import Tinymce from '@/components/Tinymce/index.vue'
import EmailAddress from '@/views/compose/components/EmailAddress'
import ContactsPopper from '@/components/ContactPopper'
import serialize from '@/utils/serialize'
import { __defaultReFoward, defaultText, shareTemplate, tansferMettingTemplate, initialValue } from '@/views/compose/components/template'
import { transformClass, GetFileType, getAfterTimer, timeStampConvert } from '@/utils/utils'
import netdisk from '@/views/compose/components/netdisk'
import SplitUpload from '@/components/SplitUpload/index.vue'
import { BigAttachmentsText } from '@/views/compose/components/template'
import timeZoneJson from './components/timezone'
import clickoutside from 'element-ui/src/utils/clickoutside'
// const IframeOnClick = {
//   resolution: 200,
//   iframes: [],
//   interval: null,
//   Iframe: function() {
//     this.element = arguments[0]
//     this.cb = arguments[1]
//     this.hasTracked = false
//   },
//   track: function(element, cb) {
//     this.iframes.push(new this.Iframe(element, cb))
//     if (!this.interval) {
//       var _this = this
//       this.interval = setInterval(function() { _this.checkClick() }, this.resolution)
//     }
//   },
//   checkClick: function() {
//     if (document.activeElement) {
//       var activeElement = document.activeElement
//       for (var i in this.iframes) {
//         if (activeElement === this.iframes[i].element) { // user is in this Iframe
//           if (this.iframes[i].hasTracked === false) {
//             this.iframes[i].cb.apply(window, [])
//             this.iframes[i].hasTracked = true
//           }
//         } else {
//           this.iframes[i].hasTracked = false
//         }
//       }
//     }
//   },
// }
export default {
  components: { downAttaForm, Tinymce, EmailAddress, ContactsPopper, SplitUpload, PreviewPage },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
  },
  directives: { clickoutside },
  mixins: [netdisk],
  props: {
    composeType: {
      type: String,
      default() {
        return ''
      },
    },
    composeData: {
      type: [Object, Array, String, Number],
      default() {
        return {}
      },
    },
  },
  data() {
    // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!|^:%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"])[a-zA-Z\d@$^:!|%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"]+$/
    const validatedelay_date = (rule, value, callback) => {
      const str = `${this.timingForm.delay_date}T${this.timingForm.delay_time}:00${this.zone === '0' ? '+08:00' : this.zone.slice(0, 3) + ':' + this.zone.slice(3)}`
      if (this.isExpired(str)) {
        callback(new Error(this.$t('message.compose.info24')))
      } else {
        callback()
      }
    }
    // const validateMailPsw = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('邮件密码不能为空'))
    //   }
    //   if (value.length < 6) {
    //     callback(new Error('邮件密码长度不能少于6位'))
    //   }
    //   if (!passwordRegex.test(value)) {
    //     callback(new Error('邮件密码不合规'))
    //   }
    //   callback()
    // }
    // const validateExpire = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('过期时间不能为空'))
    //   }
    //   if (!/^(1?[1-9]{1,2}|2[0-3][0-9]|240)$/.test(value)) {
    //     callback(new Error('请输入1到240之间的正整数'))
    //   }
    //   callback()
    // }
    return {
      previewType: this.$store.state.app.preview_atta_type.toString() ? this.$store.state.app.preview_atta_type.toString() : this.$t('message.compose.info39'), // 安全邮件预览类型
      saveTimes: 1,
      isChanged: false,
      mail_data_value_hash: '',
      timeZone: [],
      activeName: '',
      activeName2: 'all',
      tags_to: [],
      tags_cc: [],
      tags_bcc: [],
      localAtt: [],
      value1: '',
      checked: false,
      iconType: 'expand',
      moreProps: false, // 显示更多属性
      showSecretMail: false, // 显示密送人
      options: [{
        value: '1',
        label: '张三<zhangsan@kylinos.cn>',
      }],
      identity: [], // 签名
      zone: '0',
      separately: true, // 群发单显
      sendInfo: this.$t('message.compose.info23'), // 群发单显提示信息
      showSendTimer: false, // 定时发送弹窗
      showSettingPsw: false, // 邮件密码弹窗
      // showExpireMail: false, // 邮件过期弹窗
      showUpload: false, // 上传附件弹窗
      showSecure: false, // 安全属性弹窗
      itemIndex: 1,
      // reqRules: {
      //   mail_psw: [
      //     { validator: validateMailPsw, trigger: 'blur' },
      //   ],
      // },
      // expireRules: {
      //   expire_time: [
      //     { validator: validateExpire, trigger: 'blur' },
      //   ],
      // },
      outer_readed_destory: 0, // 阅后即焚开关
      outer_expire_time_type: '', // 过期时间
      outer_expire_time: '', // 自定义过期时间
      outer_mail_psw: '', // 邮件密码
      outer_protect_type: '', // 选择的是安全属性还是邮件密码
      outer_is_secure: 0, // 是否选中安全属性
      req: {
        body: '', // 正文,
        text_content: '', // 正文文本
        from_address: this.$store.state.app.user_mail_address,
        subject: '', // 主题
        to: [], // 收件人
        cc: [], // 抄送
        bcc: [], // 密送
        atts: [], // 附件
        atts_left: [],
        atts_oldto: [],
        date: new Date().toString(), // 日期
        is_save_sent: 1,
        undownload: 0,
        unprint: 0,
        unresponse: 0,
        untransmit: 0,
        protect_type: 0, // 安全属性选择 0是未设置 1安全邮件 2加密邮件
        readed_destory: 0, // 阅后即焚开关
        receipt: 0, // 回执
        priority: 0, // 紧急
        is_timing: 0, // 定时邮件
        delaysend_time: '',
        expire_time: '', // 过期时间
        expire_time_type: '', // 自定义过期时间
        mail_psw: '', // 邮件密码
        identity: '',
        is_singledisplay: 0,
        mbox_draft: 'Drafts',
        relevant_id: '',
        replay: '',
        mail_type: '',
        is_watermark: 0,
        is_secure: 0, // 安全属性
      },
      last_draft_uid: '', // 草稿id
      mailType: {
        'new': 1,
        'drafts': 1,
        're': 2,
        'reall': 3,
        'forward': 4,
        'forward_re': 5,
      }, // 邮件类型
      count: 10,
      mailpsw: false, // 邮件密码chebox
      // expireMail: 0, // 过期邮件
      timingForm: {
        delay_date: '', // 延迟发送日期
        delay_time: '', // 延迟发送时间
      }, // 定时发送时间
      timingRules: {
        delay_date: [
          { required: true, message: this.$t('message.compose.info18'), trigger: 'blur' },

        ],
        delay_time: [
          { required: true, message: this.$t('message.compose.info19'), trigger: 'blur' },
          { validator: validatedelay_date, trigger: 'blur' },
        ],
      }, // 定时发送时间校验规则
      showeye: false, // 邮件密码眼睛
      pickerOptions0: {
        disabledDate(time) {
          // return time.getTime() < this.disabledDate() - 24 * 60 * 60 * 1000
          return time.getTime() < Date.now() - 8.64e7// 禁用大于今天的日期
        },
      },
      atts_manager: [], // 附件管理附件
      attnum: 0, // 附件数量
      fakeAttachmentList: [], // 显示附件列表
      uploadFiles: [],
      bigAttachmentList: [], // 大附件列表
      AliasList: [], // 别名列表
      autoTimer: null, // 存草稿定时器
      isPreview: false, // It is  a preview
      bigCount: 0, // 大附件个数
      showAutoTips: false, // 自动发送提示
      is_auto_send: false, // 自动发送
      idPlus: 1,
      old_uid: '',
      pageSize: this.$store.state.app.userGetConfig.font_size, // 页面字体大小
      isAttaDown: false,
      downAttaReq: {},
      timeOptions: [{
        value: '0',
        label: 'day0',
      }, {
        value: '1',
        label: 'day1',
      }, {
        value: '2',
        label: 'day3',
      }, {
        value: '3',
        label: 'day7',
      }, {
        value: '4',
        label: 'day8',
      }],
      expire_time_type: '', // 自定义过期时间
      expire_time: '0', // 过期时间
      aqText: this.$t('message.compose.info25'), // 选择安全邮件提示
    }
  },
  computed: {
    showWaterMark() {
      return this.$store.state.app.water_mark.length !== 0
    },
  },
  watch: {
    // 邮件密码弹窗
    mailpsw(value) {
      if (value) {
        this.showSettingPsw = true
      } else {
        this.req.mail_psw = ''
        this.showSettingPsw = false
      }
    },
    // expireMail(value) {
    //     if (value) {
    //       // this.showExpireMail = true
    //     } else {
    //       // this.showExpireMail = false
    //       this.req.expire_time = void 0
    //     }
    // },
    'req.subject': {
      handler(value) {
        if (value.length > 0) {
          this.isChanged = true
        }
        this.$emit('changeSubject', value)
      },
      immediate: true,
    },
    'req.body': {
      handler(value) {
        if (value.length > 0) {
          this.isChanged = true
        }
      },
      immediate: true,
    },
    tags_to: {
      handler(value) {
        const arr = value.map(obj => [obj.mail, obj.name])
        this.req.to = this.uniqueArray(arr)
        if (this.req.to.length > 0) {
          this.isChanged = true
        }
      },
      immediate: true,
    },
    tags_cc: {
      handler(value) {
        const arr = value.map(obj => [obj.mail, obj.name])
        this.req.cc = this.uniqueArray(arr)
        if (this.req.cc.length > 0) {
          this.isChanged = true
        }
      },
      immediate: true,
    },
    tags_bcc: {
      handler(value) {
        const arr = value.map(obj => [obj.mail, obj.name])
        this.req.bcc = this.uniqueArray(arr)
        if (this.req.bcc.length > 0) {
          this.isChanged = true
        }
      },
      immediate: true,
    },
    activeName: {
      handler(value) {
        if (value === 'attsmanger' && !this.haveatts) {
          this.getAttchment()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.activeName = 'attsmanger'
    const composectx = this.deepCopy(this.composeData)
    if (Object.prototype.hasOwnProperty.call(composectx, 'mailData')) this.old_uid = composectx.mailData.uid
    else this.old_uid = composectx.uid
    this.timeZone = timeZoneJson
    this.getNowTime()
  },
  mounted() {
    console.log('write mail ...')
    const { sent_autosave, req_receipt, draft_autosave } = this.$store.state.app.userGetConfig
    if (draft_autosave !== '0') {
      this.autoTimer = setInterval(() => {
        if (this.req.to.length !== 0 || this.req.cc.length !== 0 || this.req.bcc.length !== 0 || this.req.atts.length !== 0 || this.req.subject !== '' || initialValue !== this.req.body) {
          this.saveDrafts()
        }
      }, draft_autosave * 1000)
    }
    if (sent_autosave === 'yes') {
      this.req.is_save_sent = 1
    } else {
      this.req.is_save_sent = 0
    }
    if (req_receipt === 'yes') {
      this.req.receipt = 1
    } else {
      this.req.receipt = 0
    }
  },
  beforeDestroy() {
    this.is_auto_send = false
    this.showAutoTips = false
    clearInterval(this.autoTimer)
  },
  methods: {
    // 翻页
    handleCurrentChange(val) {
      this.page = `${val}`
      this.atts.require.from = Number(val)
      this.getAttchment()
      // this.getShareDetail()
    },
    closeMe() {
      this.$emit('closeMe')
    },
    async beforeCloseMe() {
      this.is_auto_send = false
      this.showAutoTips = false
      clearInterval(this.autoTimer)
      this.autoTimer = null
      if (await this.draftIsChanged() === false) {
        console.log('xno changed')
        return
      }
      const res = await this.$myConfirm(this.$t('message.compose.err9'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      })
      if (res === 'CANCEL') {
        return
      }
      if (res === 'OK') {
        if (await this.saveDrafts() === true) {
          console.log('保存成功')
        } else {
          console.log('保存失败')
        }
        return
      }
      return
    },
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    // 预览附件

    uniqueArray(arr) {
      const result = []
      const obj = {}
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i].toString()
        if (!obj[str]) {
          result.push(arr[i])
          obj[str] = true
        }
      }
      return result
    },
    getNowTime() {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const day = currentDate.getDate()
      const hours = currentDate.getHours()
      const minutes = currentDate.getMinutes()
      const seconds = currentDate.getSeconds()
      const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
      const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      this.timingForm.delay_date = formattedDate
      this.timingForm.delay_time = formattedTime
    },
    subjectinput() {
      // 计算输入框内容长度（中文字符算2个长度）
      var length = 0
      for (var i = 0; i < this.req.subject.length; i++) {
        if (this.req.subject.charCodeAt(i) > 127) {
          length += 1
        } else {
          length += 1
        }
      }

      // 如果长度超过256，则截取前256个字符
      if (length > 256) {
        this.req.subject = this.req.subject.slice(0, 256 - (length - this.req.subject.length))
      }
    },
    confirmSettingPsw() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.showSettingPsw = false
        }
      })
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 取消自动发送
    cancelAutoSend() {
      this.$confirm(this.$t('message.compose.info26'), this.$t('message.btnText.info')).then(() => {
        this.is_auto_send = false
        this.showAutoTips = false
      }).catch(() => {

      })
    },
    // 验证邮件数据
    sendVerifyMailData() {
      // 失去焦点
      if (this.$refs.tagsbcc) this.$refs.tagsbcc.checkInputMail()
      if (this.$refs.tagscc) this.$refs.tagscc.checkInputMail()
      if (this.$refs.tagsto) this.$refs.tagsto.checkInputMail()
      //
      if (this.is_auto_send) return false
      const _vm = this
      if (this.req.is_timing === 1 && this.$refs.uploadfils && !this.$refs.uploadfils.isCompleted()) {
        this.req.is_timing = 0
        return this.$alert(this.$t('message.upload.info1'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.tags_to.length === 0) {
        this.req.is_timing = 0
        return this.$alert(this.$t('message.compose.err1'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.tags_to.some(item => !item.isValid)) {
        return this.$alert(this.$t('message.compose.err2'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.tags_cc.some(item => !item.isValid)) {
        return this.$alert(this.$t('message.compose.err3'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.tags_bcc.some(item => !item.isValid)) {
        return this.$alert(this.$t('message.compose.err4'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.outer_protect_type === 1 && this.outer_expire_time_type === '4' && this.isExpired(this.outer_expire_time)) {
        return this.$alert(this.$t('message.compose.err5'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      const tabViews = this.$store.state.tagsView.tabViews
      if (this.$refs.uploadfils && !this.$refs.uploadfils.isCompleted()) {
        for (let i = 0; i < tabViews.length; i++) {
          if (tabViews[i].isOpen) {
            this.$store.state.tagsView.tabViews[i].data.isUploadSuccess = 'error'
            break
          }
        }
        this.is_auto_send = true
        this.showAutoTips = true
        return false
      } else {
        for (let i = 0; i < tabViews.length; i++) {
          if (tabViews[i].isOpen) {
            this.$store.state.tagsView.tabViews[i].data.isUploadSuccess = 'success'
            break
          }
        }
        this.is_auto_send = false
        this.showAutoTips = false
      }
      if (this.req.is_timing) {
        const str = `${this.timingForm.delay_date}T${this.timingForm.delay_time}:00${this.zone === '0' ? '+08:00' : this.zone.slice(0, 3) + ':' + this.zone.slice(3)}`
        if (this.isExpired(str)) {
          this.req.is_timing = 0
          return this.$alert(this.$t('message.compose.err6'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            type: 'warning',
          })
        }
      }
      // if (this.req.atts.length > 0 && this.req.undownload === 1) {
      //   return this.$confirm('邮件中存在附件，禁止下载会限制收件人下载邮件及附件，请确认是否发送？', this.$t('message.btnText.info')).then(() => {
      //     this.sendMail()
      //   }).catch(() => {
      //     this.req.is_timing = 0
      //   })
      // }
      if (!this.bigAttachmentList.length && !this.req.atts.length && send2()) {
        return this.$confirm(this.$t('message.compose.err7'), this.$t('message.btnText.info')).then(() => {
          this.sendMail()
        }).catch(() => {
          this.req.is_timing = 0
        })
      }

      if (_vm.req.subject === '' && !this.is_auto_send) {
        if (this.req.atts.length) {
          this.req.subject = this.req.atts[0].name
          this.sendMail()
        } else {
          return _vm.$confirm(this.$t('message.compose.err8'), this.$t('message.btnText.info')).then(() => {
            this.sendMail()
          }).catch(() => {
            this.req.is_timing = 0
          })
        }
      } else {
        this.sendMail()
      }
      function send2() {
        var str = ' ' + _vm.req.subject + '\n' + _vm.$refs.tinymceED.getTextContent()
        var find = 0
        var ks; var i
        ks = _vm.$store.state.app.userGetConfig.attachment_key.replace(/\n/gi, ',').toLowerCase().split(',')
        for (i = 0; i < ks.length; i++) {
          var o = ks[i].replace(/\s|\./g, '')
          if (o === '') continue
          if (str.indexOf(o) >= 0) {
            find = 1
            break
          }
        }
        if (_vm.req.body.indexOf('发件人邮箱发来的云附件') > 0) {
          find = 0
        }
        return find
      }
    },
    isExpired(dateString) {
      var dateObj = new Date(dateString)
      var now = new Date()

      if (dateObj > now) {
        return false // 如果日期在未来，则返回 false
      }

      if (dateObj < now) {
        return true // 如果日期在过去，则返回 true
      }

      return false // 如果日期是今天，则返回 false
    },
    handleWaterMark(body) {
      const water_config = this.$store.state.app.water_mark
      const watermark_rows = water_config.line_type // 多行 1单行 2 多行
      const watermark_text_align = water_config.text_align // 1居上 2居中 3居下
      const positionSY = ['top', 'center', 'bottom']

      const watermarksvg = "url('qilinruanjian')"
      const box = document.createElement('div')
      const temp = document.createElement('div')
      const startIndex = body.indexOf('<div id="cs2c_re"')
      // 如果是单行水印
      if (watermark_rows === '1') {
        box.style.backgroundRepeat = 'no-repeat'
        box.style.backgroundPositionX = 'center'
        box.style.backgroundPositionY = positionSY[watermark_text_align - 1]
      }
      box.style.backgroundImage = watermarksvg
      box.className = 'old_body'
      box.innerHTML = startIndex === -1 ? body : body.substring(0, startIndex)
      temp.appendChild(box)
      const result = startIndex === -1 ? temp.innerHTML : (temp.innerHTML + body.substring(startIndex))
      return result
    },
    // 发送邮件
    sendMail() {
      const { write_font, write_font_size, write_font_color } = this.$store.state.app.userGetConfig
      if (this.req.subject === '' && this.req.atts.length && this.is_auto_send) {
        this.req.subject = this.req.atts[0].name
      }
      let bodycontent = ''
      this.bigAttachmentList.map(item => {
        bodycontent += item.bodys
      })
      if (this.req.is_watermark) {
        this.req.body = this.handleWaterMark(bodycontent + this.$refs.tinymceED.getContent())
      } else {
        this.req.body = `<div style="font-family:${write_font};font-size:${write_font_size}px;color:${write_font_color};" class="l_node_has_color">${bodycontent}${this.$refs.tinymceED.getContent()}`
      }

      this.req.text_content = this.$refs.tinymceED.getTextContent()
      this.req.last_draft_uid = this.last_draft_uid
      if (this.req.to.length !== this.tags_to.length && this.tags_to.length !== 0) {
      // if (this.req.to.length === 0 && this.tags_to.length !== 0) {
        const arr = this.tags_to.map(obj => [obj.mail, obj.name])
        this.req.to = this.uniqueArray(arr)
      }
      if (this.req.cc.length !== this.tags_cc.lengt && this.tags_cc.length !== 0) {
      // if (this.req.cc.length === 0 && this.tags_cc.length !== 0) {
        const arr = this.tags_cc.map(obj => [obj.mail, obj.name])
        this.req.cc = this.uniqueArray(arr)
      }
      if (this.req.bcc.length !== this.tags_bcc.length && this.tags_bcc.length !== 0) {
      // if (this.req.bcc.length === 0 && this.tags_bcc.length !== 0) {
        const arr = this.tags_bcc.map(obj => [obj.mail, obj.name])
        this.req.bcc = this.uniqueArray(arr)
      }
      if (!this.showSecretMail) {
        this.req.bcc = []
      }
      if (!this.separately) {
        this.req.cc = []
        this.req.bcc = []
      }
      // 没有勾选安全属性时清空里面的选项
      if (this.outer_is_secure === 0) {
        this.outer_protect_type = 0
        this.req.protect_type = 0
      }
      const params = {
        module: 'mail',
        require: {
          action: 'send',
          data: this.req,
        },
      }
      // 接受会议反馈
      if (this.composeType === 'calendar') {
        // const { cal_obj_id, from_id, meeting_feedback, is_meeting } = this.userStreamCache.composectx
        const { cal_obj_id, from_id, meeting_feedback, is_meeting } = this.composeData
        params.require.data = {
          ...params.require.data,
          cal_obj_id,
          from_id,
          meeting_feedback,
          is_meeting,
          desc: this.req.body,
        }
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
        if (result.status === 200) {
          this.$message.success(this.$t('message.compose.success'))
          this.updateMailFlags()
          this.closeMe()
        } else {
          this.$message.error(result.error)
        }
      })
        .catch(() => {
          this.cancelSendTimer()
        })
    },
    // 定时发送
    timingSend() {
      if (this.$refs.uploadfils && !this.$refs.uploadfils.isCompleted()) {
        return this.$alert(this.$t('message.upload.info1'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      this.showSendTimer = true
    },
    // 预览
    async preview() {
      const req = { module: 'user', require: { action: 'getWaterPng' }}
      this.userStreamCache.composectx = this.composeData
      if (this.req.is_watermark) {
        const res = await this.$axios.post(this.$apiData.mail, { req: serialize(req) })
        const body = this.handleWaterMark(this.$refs.tinymceED.getContent()).replace(/qilinruanjian/, res.result.img)
        this.userStreamCache.previewctx = this.req
        this.userStreamCache.previewatts = this.fakeAttachmentList
        this.userStreamCache.previewbody = body
        this.isPreview = !this.isPreview
      } else {
        this.userStreamCache.previewctx = this.req
        this.userStreamCache.previewatts = this.fakeAttachmentList
        this.userStreamCache.previewbody = this.$refs.tinymceED.getContent()
        setTimeout(() => {
          this.isPreview = !this.isPreview
        }, 10)
      }
    },
    // 探测写信数据是否发生变化
    async draftIsChanged() {
      if (await this.saveDrafts({ testChanged: true }) === true) {
        return true
      }
      return false
    },
    // 存草稿
    async saveDrafts(attrs /* { testChanged: false } */) {
      if (!attrs) {
        attrs = {}
      }
      if (typeof attrs !== 'object') {
        attrs = {}
      }
      const saveData = this.deepCopy(this.req)
      saveData.readed_destory = void 0
      saveData.mail_psw = void 0
      saveData.expire_time = void 0
      saveData.priority = void 0
      saveData.undownload = void 0
      saveData.unprint = void 0
      saveData.last_draft_uid = this.last_draft_uid
      saveData.receipt = void 0
      const params = {
        module: 'mail',
        require: {
          action: 'saveDraft',
          data: saveData,
        },
      }
      let bodycontent = ''
      this.bigAttachmentList.map(item => {
        bodycontent += item.bodys
      })
      saveData.body = bodycontent + saveData.body
      saveData.has_big = 1

      // build hash
      const hash_params = this.deepCopy(params)
      hash_params.require.data.last_draft_uid = ''
      const new_hash = this.$md5(serialize(hash_params))

      if (attrs.testChanged === true) {
        if (this.mail_data_value_hash === '') {
          if (this.isChanged) {
            return true
          }
          return false
        }
        if (this.mail_data_value_hash === new_hash) {
          return false
        }
        return true
      }
      if (this.mail_data_value_hash === new_hash) {
        this.$message.success(this.$t('message.compose.success1'))
        return true
      }

      const thisobj = this
      return new Promise(resolve => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) })
          .then(res => {
            if (res.result !== false && res.result !== '') {
              this.last_draft_uid = res.result.draft_uid.toString()
              this.$emit('saveDrafts', { mbox: this.req.mbox_draft, newUid: res.result.draft_uid })
              thisobj.old_uid = res.result.draft_uid.toString()
              thisobj.$message.success(this.$t('message.compose.success1'))
              thisobj.mail_data_value_hash = new_hash
              this.isChanged = false
              resolve(true)
            }
          }).catch(res => {
            resolve(false)
          })
      })
    },
    // 取消写信
    async cancelCompose() {
      if (await this.draftIsChanged() === false) {
        this.closeMe()
        return
      }
      const res = await this.$myConfirm(this.$t('message.compose.err9'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      })
      if (res === 'CANCEL') {
        this.closeMe()
        return
      }
      if (res === 'OK') {
        if (await this.saveDrafts() === true) {
          console.log('保存成功')
        } else {
          console.log('保存失败')
        }
        this.closeMe()
        return
      }
      this.closeMe()
      return
    },
    showSecret() {
      this.showSecretMail = !this.showSecretMail
    },
    sendSeparately() {
      this.separately = !this.separately
      if (!this.separately) {
        this.tags_to = [...this.tags_to, ...this.tags_cc, ...this.tags_bcc]
        this.req.is_singledisplay = 1
        this.showSecretMail = false
      } else {
        const arr = [...this.tags_cc, ...this.tags_bcc].map(item => item.mail)
        this.tags_to = this.tags_to.filter(item => !arr.includes(item.mail))
        this.req.is_singledisplay = 0
        if (this.req.bcc.length) {
          this.showSecretMail = true
        }
      }
    },
    showSettingPswClose() {
      // this.mailpsw = 0
    },
    cancelSettingPsw() {
      this.mailpsw = 0
      this.req.mail_psw = ''
      this.showSettingPsw = false
    },
    // cancelExipreTime() {
    //   this.expireMail = 0
    //   this.req.expire_time = ''
    //   // this.showExpireMail = false
    // },
    confirmExipreTime() {
      this.$refs.formExpire.validate(valid => {
        if (valid) {
          // this.showExpireMail = false
        }
      })
    },
    cancelSendTimer() {
      // this.$nextTick(() => {
      //   this.$refs['timingRef'].resetFields()
      // })
      // this.req.delaysend_time = ''
      // this.zone = '0'
      // this.delay_date = ''
      // this.delay_time = ''
      // this.req.is_timing = 0
      // this.showSendTimer = false
      // this.getNowTime()
      this.showSendTimer = false
    },
    confirmshowSendTimer() {
      this.$refs['timingRef'].validate((valid) => {
        if (valid) {
          this.req.delaysend_time = this.timingForm.delay_date + this.timingForm.delay_time + this.zone
          this.req.is_timing = 1
          if (this.zone === '0') {
            const date = new Date()
            const timeZoneOffset = date.getTimezoneOffset()
            const hours = Math.floor(Math.abs(timeZoneOffset) / 60)
            const minutes = Math.abs(timeZoneOffset) % 60
            const sign = timeZoneOffset > 0 ? '-' : '+'
            const timeZone = `${sign}${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}`
            this.req.delaysend_time = `${this.timingForm.delay_date} ${this.timingForm.delay_time} ${timeZone}`
          }
          this.showSendTimer = false
          if (this.outer_expire_time_type !== '' && this.outer_expire_time_type !== '0') {
            // 如果设置了过期时间，判断过期时间是否小于定时发送时间增加弹窗
            const str = `${this.timingForm.delay_date}T${this.timingForm.delay_time}${this.zone === '0' ? '+08:00' : this.zone.slice(0, 3) + ':' + this.zone.slice(3)}` // 设置时区后的时间段
            let s = '' // 过期时间的时间戳
            if (this.outer_expire_time_type === '1') {
              s = new Date().getTime() + 1 * 24 * 60 * 60 * 1000
            } else if (this.outer_expire_time_type === '2') {
              s = new Date().getTime() + 3 * 24 * 60 * 60 * 1000
            } else if (this.outer_expire_time_type === '3') {
              s = new Date().getTime() + 7 * 24 * 60 * 60 * 1000
            } else if (this.outer_expire_time_type === '4') {
              s = new Date(this.outer_expire_time).getTime()
            }
            if ((s - new Date(str).getTime()) <= 0) {
              this.$confirm(this.$t('message.compose.err10'), this.$t('message.btnText.info'), {
                confirmButtonText: this.$t('message.btnText.ok'),
                cancelButtonText: this.$t('message.btnText.cancel'),
                type: 'warning',
              }).then(() => {
                this.sendVerifyMailData()
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: this.$t('message.compose.info27'),
                })
              })

              return false
            }
          }
          this.sendVerifyMailData()
        }
      })
    },
    showExpireMailClose() {

    },
    openAttachment() {
      this.showUpload = true
    },
    selectLocalAtts(i) {
    },
    handoff(value) {
      this.itemIndex = value
    },
    // 选择通讯录显示
    openContacts(flag) {
      this.$refs.ContactsPopper.showContactPopper(flag)
    },
    deepCopy(obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj
      }

      const copy = Array.isArray(obj) ? [] : {}

      Object.keys(obj).forEach((key) => {
        copy[key] = this.deepCopy(obj[key])
      })

      return copy
    },
    getAdressData(data) {
      this.tags_to = this.deepCopy([...data.to])
      this.tags_cc = this.deepCopy([...data.cc])
      this.tags_bcc = this.deepCopy([...data.bcc])
      if (this.tags_bcc.length > 0) {
        this.showSecretMail = true
      }
      if (!this.separately) {
        this.showSecretMail = false
      }
    },
    // 获取附件管理中附件
    getAttchment(flag) {
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(this.atts) }).then(({ result }) => {
        if (flag) {
          const copy = []
          result.list.map(item => {
            item.checked = false
            copy.push(item)
          })
          this.atts_manager = [...this.atts_manager, ...copy]
        } else {
          result.list.map(item => {
            item.checked = false
            // this.atts_manager.push(item)
          })
          this.atts_manager = result.list
          this.attstotal = Number(result.total)
          this.haveatts = true
        }
      })
    },
    loadAttsManager() {
      if (this.atts.require.page === Math.ceil(this.attstotal / this.atts.require.limit)) return false
      this.atts.require.page++
      this.atts.require.from = this.atts.require.from + this.atts.require.limit
      this.getAttchment(1)
    },
    // 获取附件管理附件详细信息
    attachmentMultiForwardOne() {
      const requestLists = this.attsManagerFiles.map(attachment => {
        return {
          charset: attachment.att_charset,
          disposition: attachment.att_disposition,
          encoding: attachment.att_encoding,
          mbox: attachment.mail_mbox,
          name: attachment.att_name,
          psection: attachment.att_psection,
          section: attachment.att_section,
          size: attachment.att_size,
          subtype: attachment.att_subtype,
          type: attachment.att_type,
          uid: attachment.uid,
          uv: attachment.uidvalidity,
        }
      })
      const params = {
        module: 'mail',
        require: {
          action: 'attachmentMultiForwardOne',
          list: requestLists,
        },

      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          const atts = result.map((attachment, index) => {
            return {
              cid: attachment.cid,
              name: attachment.fileName,
              type: 'upload',
              size: attachment.fileSize,
              upid: attachment.upid,
              fileuptype: 'attsManager',
              origin: 'attaManage',
              originData: requestLists[index],
            }
          })
          this.attsManagerFiles.map(item => {
            item.checked = false
          })
          resolve(atts)
        }).catch((err) => {
          reject(err)
        })
      })
    },

    // 监听ManagerLists改变
    attsManagerChange(att, e) {
      if (e) {
        this.attsManagerFiles.push(att)
      } else {
        this.attsManagerFiles.splice(this.attsManagerFiles.indexOf(att), 1)
      }
    },
    attsNetdiskChange(att, e) {
      if (e) {
        this.attsNetdiskFiles.push(att)
      } else {
        this.attsNetdiskFiles.splice(this.attsNetdiskFiles.indexOf(att), 1)
      }
    },
    attsTransferChange(att, e) {
      if (e) {
        this.selecttedTransferList.push(att)
      } else {
        this.selecttedTransferList.splice(this.selecttedTransferList.indexOf(att), 1)
      }
    },
    tabClick(tab) {
      this.tablist.map((v, i) => {
        if (tab.name === v.name) {
          v.active = true
        } else {
          v.active = false
        }
      })
      this.getNetdiskFilesByCategory(tab.name)
    },
    // 获取签名
    async getIdentity() {
      const params = {
        module: 'user',
        require: {
          action: 'getIdentity',
        },
      }
      const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      const lists = []
      const arr = []
      res.list.map(item => {
        arr.push(item.identity)
        lists.push({
          label: `${item.name}`,
          value: item.identity_id,
          identity: item.identity,
        })
      })
      this.identity = lists
      const userNewMail = arr.find(item => item.used_new_mail === 1)
      const userForward = arr.find(item => item.used_reply_forward === 1)
      this.req.identity = res._default
      const type = this.composeType
      const doc = this.$refs.tinymceED.getDocument()
      var p = document.createElement('div')
      p.innerHTML = `<div><p><br></p><br><p style="color:#000;font-size:16px;">---</p><div id="cs2c_mail_sigature" style="color:#000;font-size:16px;font-family:Microsoft YaHei;"></div><p></p></div>`
      if (['reply', 'replyall', 'replyallatt', 'forward', 'asattforward', 'asattsforward'].includes(type)) {
        if (userForward) {
          this.selecIdentity(userForward.identity_id)
        } else {
          doc.body.appendChild(p)
        }
      } else {
        if (userNewMail) {
          this.selecIdentity(userNewMail.identity_id)
        } else if (type) {
          const signature = doc.getElementById('cs2c_mail_sigature')
          if (!signature) {
            doc.body.appendChild(p)
          }
        }
      }
    },
    // 获取别名列表
    getAliasList() {
      const params = {
        module: 'user',
        require: {
          action: 'getAliasList',
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        this.AliasList = result.list
      })
    },
    findIdentity(id) {
      return this.identity.find(item => {
        return item.value === id
      })
    },
    // outer_protect_type
    delSecure() {
      this.outer_is_secure = 0
      this.secureFun()
      this.outer_protect_type = 0
    },
    // 编辑安全邮件
    editSecure() {
      this.showSecure = true
    },
    // 是否点击安全属性
    secureFun() {
      if (this.outer_is_secure === 1) {
        this.showSecure = true
        this.outer_protect_type = 1 // 默认选中安全属性
      } else {
        this.outer_expire_time = '' // 自定义过期时间
        this.outer_expire_time_type = '' // 过期时间
        this.outer_readed_destory = 0 // 阅后即焚开关
        this.outer_protect_type = 0 // 选择的是安全属性还是邮件密码
        this.outer_mail_psw = '' // 邮件密码
      }
    },
    // 切换过期时间
    expireFun() {
      this.outer_protect_type = 1
      this.outer_mail_psw = '' // 邮件密码
    },
    // 取消安全属性弹窗
    secureFunClose() {
      this.req.is_secure === 0 ? this.outer_is_secure = 0 : this.outer_is_secure = 1
      this.showSecure = false
      // 点击取消后重新赋值
      this.outer_expire_time = this.req.expire_time // 自定义过期时间
      this.outer_expire_time_type = this.req.expire_time_type // 过期时间
      this.outer_readed_destory = this.req.readed_destory // 阅后即焚开关
      this.outer_protect_type = this.req.protect_type // 选择的是安全属性还是邮件密码
      this.outer_mail_psw = this.req.mail_psw // 邮件密码
      this.showeye = false // 小眼睛
    },
    // 安全邮件设置提交
    confirmSecureFun() {
      if (this.outer_protect_type === 1 && this.outer_expire_time_type === '4' && this.isExpired(this.outer_expire_time)) {
        return this.$alert(this.$t('message.compose.info28'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.outer_protect_type === 1 && this.outer_expire_time_type === '4' && this.outer_expire_time === '') {
        return this.$alert(this.$t('message.compose.info8'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.outer_expire_time_type !== '4') {
        this.outer_expire_time = ''
      }
      if (this.outer_protect_type === 2 && this.outer_mail_psw === '') {
        return this.$alert(this.$t('message.compose.info29'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.outer_protect_type === 2 && this.outer_mail_psw.length < 6) {
        return this.$alert(this.$t('message.compose.info30'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!|^:%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"])[a-zA-Z\d@$^:!|%*?&.,;`#~(){}/\[/\]<>_+=\-\\'\"]+$/
      if (this.outer_protect_type === 2 && !passwordRegex.test(this.outer_mail_psw)) {
        return this.$alert(this.$t('message.compose.info31'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      }
      if (this.outer_protect_type === 1) {
        let times = this.outer_expire_time_type
        if (this.outer_expire_time_type === '4') {
          times = this.outer_expire_time
        } else if (this.outer_expire_time_type === '' || this.outer_expire_time_type === '0') {
          times = ''
        } else if (this.outer_expire_time_type === '2') {
          times = this.$t('message.compose.day9')
        } else if (this.outer_expire_time_type === '3') {
          times = this.$t('message.compose.day10')
        } else {
          times = this.$t('message.compose.day11')
        }
        if (Number(this.outer_readed_destory) && times === '') {
          this.aqText = this.$t('message.compose.info32')
        } else if (!Number(this.outer_readed_destory) && times !== '') {
          this.aqText = this.$t('message.compose.info33', { time: times })
        } else if (Number(this.outer_readed_destory) && times !== '') {
          this.aqText = this.$t('message.compose.info34', { time: times })
        } else {
          this.aqText = this.$t('message.compose.info35')
        }
      } else {
        this.aqText = this.$t('message.compose.info25')
      }
      this.showSecure = false
      this.req.expire_time = this.outer_expire_time // 自定义过期时间
      this.req.expire_time_type = this.outer_expire_time_type // 过期时间
      this.req.readed_destory = this.outer_readed_destory // 阅后即焚开关
      // console.log(this.outer_protect_type, 'this.outer_protect_type123')
      this.req.protect_type = this.outer_protect_type// 选择的是安全属性还是邮件密码
      this.req.mail_psw = this.outer_mail_psw// 邮件密码
      this.outer_is_secure = 1
      this.req.is_secure = 1
    },
    protect_click(val) {
      this.outer_protect_type = val
      if (val === 2) {
        this.outer_readed_destory = 0
        this.outer_expire_time = ''
        this.outer_expire_time_type = ''
      } else {
        this.outer_mail_psw = ''
      }
    },
    // 设置签名
    selecIdentity(id) {
      this.req.identity = id
      const type = this.composeType
      const item = this.findIdentity(id)
      const doc = this.$refs.tinymceED.getDocument()
      const signature = doc.getElementById('cs2c_mail_sigature')
      // console.log(signature)
      if (!signature) {
        var p = document.createElement('div')
        p.innerHTML = `<div><p><br></p><br><p style="color:#000;font-size:16px;">---</p><div id="cs2c_mail_sigature" style="color:#000;font-size:16px;font-family:Microsoft YaHei;">${item ? item.identity.signature : ''}</div><p></p></div>`
        // console.log(['reply', 'replyall', 'replyallatt', 'forward', 'asattforward', 'asattsforward'].includes(type))
        if (['reply', 'replyall', 'replyallatt', 'forward', 'asattforward', 'asattsforward'].includes(type)) {
          // console.log(item, '************8')
          if (item && Number(item.identity.on_reply_forward) === 1) {
            const first = doc.body.firstChild
            doc.body.insertBefore(p, first)
          } else {
            doc.body.appendChild(p)
          }
        } else {
          doc.body.appendChild(p)
        }
      } else {
        signature.innerHTML = ''
        if (item) signature.innerHTML = item.identity.signature
      }
    },

    // 自定义上传
    upladFilesClick() {
      this.$refs.uploadInput.click()
    },
    // 确认选择附件
    async confirmUploadFiles() {
      if (this.itemIndex === 1) {
        if (!this.uploadFiles.length) {
          return this.$message.error(this.$t('message.compose.err11'))
        }
        this.showUpload = false
        this.$refs.uploadfils.submit()
        this.uploadFiles = []
      } else if (this.itemIndex === 2) {
        if (this.selecttedTransferList.length === 0 && this.attsManagerFiles.length === 0) return this.$message.error(this.$t('message.compose.err11'))
        if (this.selecttedTransferList.length > 0) {
          const attNetdiskFiles = await this.getNetdiskAttachmentInfo(1)
          this.fakeAttachmentList = [...this.fakeAttachmentList, ...attNetdiskFiles]
          this.bigAttachmentList = [...this.bigAttachmentList, ...attNetdiskFiles]
          this.showUpload = false
          this.selecttedTransferList = []
          this.cancelUploadFiles()
        }
        if (this.attsManagerFiles.length > 0) {
          const attManagersFiles = await this.attachmentMultiForwardOne()
          if (!attManagersFiles.length) {
            return this.$message.error(this.$t('message.compose.err11'))
          }
          this.fakeAttachmentList = [...this.fakeAttachmentList, ...attManagersFiles]
          this.req.atts = [...this.req.atts, ...attManagersFiles]
          this.showUpload = false
          this.attsManagerFiles = []
          this.cancelUploadFiles()
        }
        this.uploadFiles = []
      } else {
        const attNetdiskFiles = await this.getNetdiskAttachmentInfo()
        if (!attNetdiskFiles.length) {
          return this.$message.error(this.$t('message.compose.err11'))
        }
        this.fakeAttachmentList = [...this.fakeAttachmentList, ...attNetdiskFiles]
        this.bigAttachmentList = [...this.bigAttachmentList, ...attNetdiskFiles]
        this.showUpload = false
        this.attsNetdiskFiles = []
        this.cancelUploadFiles()
      }
    },
    cancelUploadFiles() {
      this.uploadFiles.map(file => {
        this.$refs.uploadfils.deleteFileList(file)
      })
      this.uploadFiles = []
      // this.$refs.uploadfils.clearFiles()
      this.attsTransferList.map(item => {
        item.checked = false
      })
      this.attsManagerFiles.map(item => {
        item.checked = false
      })
      this.attsNetdiskFiles.map(item => {
        item.checked = false
      })
      this.selecttedTransferList = []
      this.attsNetdiskFiles = []
      this.showUpload = false
    },
    // 图片的转换  将内嵌图片cid的转换成邮件可读的图片
    showImg(cid) {
      // const composectx = this.deepCopy(this.userStreamCache.composectx)
      const composectx = this.deepCopy(this.composeData)
      let url = ''
      composectx.attachments.forEach(el => {
        if (el.cid.substr(2, el.cid.length - 3) === cid) {
          // console.log(el.cid.substr(2, el.cid.length - 3))
          const req = {
            disposition: 'inline',
            encoding: el.encoding,
            mbox: composectx.mbox,
            module: 'mail_attachment',
            section: el.section,
            uid: composectx.uid,
            uv: composectx.uv,
            preview: 1,
          }
          url = '/nsmail/mail.php/fn.' + this.getFileType(el.name) + '?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
          this.req.atts.push({
            cid: '_' + cid,
            display: 'inline',
            encoding: el.encoding,
            mbox: composectx.mbox,
            name: el.name,
            type: 'att',
            section: el.section,
            uid: composectx.uid,
            uv: composectx.uv,
            uidvalidity: composectx.uv,
          })
          // console.log(this.req.atts)
        }
      })
      return url
    },
    // 编辑器初始化成功
    editorInit() {
      const type = this.composeType
      if (type !== 'new') {
        this.showComposeDataHandler(type)
        // 获取所有的imgs
      }
      this.getIdentity()
      this.getAliasList()// 获取别名列表
      this.getNetdiskFiles()// 获取网盘列表
      this.gerTransferList()// 获取中转站列表
      const vm = this
      // IframeOnClick.track(document.querySelector('.tox-edit-area__iframe'), () => {
      //   vm.$refs.sinaselect.blur()
      //   vm.$refs.fromselect.blur()
      // })
      const iframe = this.$refs.tinymceED.getDocument()
      iframe.addEventListener('click', () => {
        vm.$refs.sinaselect.blur()
        vm.$refs.fromselect.blur()
      })
    },
    dataChange() {
      const doc = this.$refs.tinymceED.getDocument()
      const imgs = doc.getElementsByTagName('img')
      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i]
        // console.log(img)
        // 图片不能太大, 变小后可以点击还原
        const url = img.src || ''
        if (url !== '' && img.src.substring(0, 4) === 'cid:') {
          const new_url = this.showImg(img.src.substring(5))
          img.src = new_url
        }
      }
    },
    handlerContact(arr) {
      const cc = arr.map(item => {
        item.isValid = true
        return item
      })
      return cc
    },
    // 处理跳转写信的数据
    showComposeDataHandler(type) {
      // const composectx = this.deepCopy(this.userStreamCache.composectx)
      this.userStreamCache.composectx = this.composeData
      const composectx = this.deepCopy(this.composeData)
      // if (type !== 'Drafts') {
      //   let sub
      //   if (['forward', 'asattforward'].includes(type)) {
      //     sub = this.$store.state.app.userGetConfig.prefix_forward + composectx.subject
      //   } else if (['reply', 'replyall'].includes(type)) {
      //     sub = this.$store.state.app.userGetConfig.prefix_re + composectx.subject
      //   } else {
      //     sub = composectx.subject
      //   }
      //   this.$store.commit('tagsView/TAB_VIEWS_REPLACE', {
      //     path: '/compose',
      //     subject: sub,
      //     isOpen: true,
      //     data: {
      //       // uid: '写邮件', // uid使用composectx中的uid
      //       mbox: '写邮件',
      //       uidvalidity: '写邮件',
      //       ...composectx,
      //     },
      //   })
      // }
      if (type === 'contact') {
        this.tags_to = this.handlerContact(composectx)
      } else if (type === 'Drafts') { // 草稿箱
        // const res = await this.GethandlerDraftData(composectx)
        // this.handlerDraft(res)
        this.GethandlerDraftData(composectx)
      } else if (type === 'editagin') {
        this.handlerDraftEditagin(composectx)
      } else if (type === 'forward') {
        this.req.subject = this.$store.state.app.userGetConfig.prefix_forward + composectx.subject
        const item = {
          subject: composectx.subject,
          date: transformClass.timeFormat(composectx.envelope_date, '年月日'),
          from: composectx.from_name,
          to: composectx.to_s.map(i => i[1]).toString(),
          body: composectx.bodys[0].substring(1, composectx.bodys[0].length).replace(/id="cs2c_mail_sigature"/gi, 'cs2c_id='),
        }
        this.req.body = __defaultReFoward(item, this.pageSize)
        const { attachments, mbox, uv, uid } = composectx
        const atts = this.handlerattachments(attachments, mbox, uid, uv)
        this.fakeAttachmentList = [...this.fakeAttachmentList, ...atts]
        this.req.atts = [...atts]
        this.req.forward = `${composectx.uv} ${composectx.uid}`
      } else if (type === 'asattforward') {
        // debugger
        // 作为附件转发跳转过来
        const mail = {
          encoding: composectx.mbox,
          mbox: composectx.mbox,
          name: composectx.subject + '.eml',
          section: 'imap-' + composectx.uv + '-' + composectx.uid,
          type: 'att',
          size: composectx.mail_size,
          uid: composectx.uid,
          uidvalidity: composectx.uidvalidity,
          uv: composectx.uidvalidity,
          origin: 'Email_attachments',
          data: composectx,
        }
        this.fakeAttachmentList.push(mail)
        this.req.atts.push(mail)
        this.req.forward = `${composectx.uv} ${composectx.uid}`
        this.req.subject = this.$store.state.app.userGetConfig.prefix_forward + composectx.subject + '.eml'
      } else if (type === 'asattsforward') {
        this.fakeAttachmentList = [...composectx.atts]
        this.req.atts = [...composectx.atts]
      } else if (type === 'reply') {
        this.translateDataHandler(composectx, 1)
      } else if (type === 'replyall') {
        this.translateDataHandler(composectx, 0)
      } else if (type === 'replyallatt') {
        const { attachments, mbox, uv, uid } = composectx
        const atts = this.handlerattachments(attachments, mbox, uid, uv)
        this.fakeAttachmentList = [...this.fakeAttachmentList, ...atts]
        this.req.atts = [...atts]
        this.translateDataHandler(composectx, 0)
      } else if (type === 'share') {
        this.req.body = shareTemplate(composectx)
      } else if (type === 'attachment') {
        this.fakeAttachmentList = [...composectx]
        this.req.atts = [...composectx]
      } else if (type === 'netdisk' || type === 'transfer') {
        const atts = composectx.map(file => {
          let time = ''
          if (file.expire_time > 253402185600) {
            time = this.$t('message.compose.neverexpire')
          } else {
            time = timeStampConvert(file.expire_time)
          }
          return {
            name: file.nd_name,
            size: file.nd_size,
            type: 'upload',
            expire: file.expire_time,
            origin: 'netdisk',
            upid: file.nd_id,
            nd_id: file.nd_id,
            fileuptype: 'big',
            nd_url: this.getdownloadlargefileURL(file.nd_url),
            bodys: BigAttachmentsText({
              url: this.getdownloadlargefileURL(file.nd_url),
              name: file.nd_name,
              expire: time,
            }),

          }
        })
        this.fakeAttachmentList = [...atts]
        this.bigAttachmentList = [...atts]
      } else if (type === 'calendar') {
        const { subject, to } = composectx
        this.req.subject = subject
        this.tags_to = [{
          name: to[1],
          mail: to[0],
          isValid: true,
        }]
        // console.log(this.tags_to)
      } else if (type === 'tansfercalendar') {
        const { title, startStr, ky_location, ky_organizer, attendee, ky_description } = composectx
        const params = {
          subject: this.$t('message.compose.fw') + title,
          date: startStr,
          location: ky_location,
          organizer: ky_organizer[0],
          attendee: attendee,
          body: ky_description,
        }
        this.req.body = tansferMettingTemplate(params, this.pageSize)
        this.req.subject = this.$t('message.compose.fw') + title
      } else if (type === 'replaycalendar') {
        const { title, startStr, attendee_arr, ky_location, ky_organizer, attendee, ky_description, isAll } = composectx
        const params = {
          subject: this.$t('message.compose.ry') + title,
          date: startStr,
          location: ky_location,
          organizer: ky_organizer[0],
          attendee: attendee,
          body: ky_description,
        }
        this.tags_to.push({
          name: ky_organizer[1],
          mail: ky_organizer[0],
          isValid: true,
        })
        if (isAll) {
          attendee_arr.map(item => {
            if (item[0] !== this.$store.state.app.user_mail_address) {
              this.tags_to.push({
                name: item[1],
                mail: item[0],
                isValid: true,
              })
              // console.log(this.tags_to)
            }
          })
        }
        this.req.body = tansferMettingTemplate(params, this.pageSize)
        this.req.subject = this.$t('message.compose.ry') + title
      } else {
        this.req.body = defaultText
      }
    },
    // 处理回复数据
    translateDataHandler(composectx, single) {
      const { reply_add_original_text } = this.$store.state.app.userGetConfig
      this.req.relevant_id = composectx.message_id
      this.req.reply = `${composectx.uidvalidity} ${composectx.uid}`
      this.req.date = new Date().toString()
      if (single) {
        this.tags_to = [{
          mail: composectx.from_name_l[0],
          name: composectx.from_name_l[1],
          path: composectx.from_name_l[2],
          isValid: true,
        }]
      } else {
        composectx.to_s = composectx.to_s.filter(item => item[0] !== this.$store.state.app.user_mail_address)
        const arr = composectx.to_s.map(item => item[0])
        if (!arr.includes(composectx.from_name_l[0])) {
          composectx.to_s.push(composectx.from_name_l)
        }
        composectx.to_s = composectx.to_s.filter(item => ![...this.$store.state.app.user_permissions.all_alias].includes(item[0]))
        // 收件人
        this.tags_to = composectx.to_s.map(item => {
          return {
            mail: item[0],
            name: item[1],
            path: item[2],
            isValid: true,
          }
        })
        // 发件人
        composectx.cc_s = composectx.cc_s.filter(item => item[0] !== this.$store.state.app.user_mail_address)
        this.tags_cc = composectx.cc_s.map(item => {
          return {
            mail: item[0],
            name: item[1],
            path: item[2],
            isValid: true,
          }
        })
      }

      if (reply_add_original_text === 'yes') {
        const item = {
          subject: composectx.subject,
          date: transformClass.timeFormat(composectx.envelope_date, '年月日'),
          from: composectx.from_name,
          to: composectx.to_s.map(i => i[1]).toString(),
          body: composectx.bodys[0].substring(1, composectx.bodys[0].length).replace(/id="cs2c_mail_sigature"/gi, 'cs2c_id='),
        }
        this.req.body = __defaultReFoward(item, this.pageSize)
      }

      this.req.subject = this.$store.state.app.userGetConfig.prefix_re + composectx.subject
    },
    // 处理 再次编辑
    handlerDraftEditagin(composectx) {
      const { attachments, mbox, uv, uid } = composectx
      this.req.subject = composectx.subject
      this.req.body = composectx.bodys[0].substring(1, composectx.bodys[0].length)
      this.tags_to = composectx.to_s.map(item => {
        return {
          mail: item[0],
          name: item[1],
          path: item[2],
          isValid: true,
        }
      })
      this.tags_cc = composectx.cc_s.map(item => {
        return {
          mail: item[0],
          name: item[1],
          path: item[2],
          isValid: true,
        }
      })
      this.tags_bcc = composectx.bcc_s.map(item => {
        return {
          mail: item[0],
          name: item[1],
          path: item[2],
          isValid: true,
        }
      })
      if (this.tags_bcc.length > 0) this.showSecretMail = true
      const atts = this.handlerattachments(attachments, mbox, uid, uv)
      this.fakeAttachmentList = [...atts]
      this.req.atts = [...atts]
      this.req.undownload = parseInt(composectx.undownload)
      this.req.unprint = parseInt(composectx.unprint)
      this.unresponse = parseInt(composectx.unresponse)
      this.req.priority = parseInt(composectx.priority)
    },
    // 处理转发 回复 附件
    handlerattachments(arr, mbox, uid, uv) {
      const vm = this
      const att = arr.map(item => {
        return {
          cid: `id_${new Date().getTime()}_${vm.idPlus++}`,
          encoding: item.encoding,
          mbox: mbox,
          name: item.name,
          section: item.section,
          type: 'att',
          uid: uid,
          uidvalidity: uv,
          uv: uv,
          size: item.size,
          origin: 'mail',
        }
      })
      return att
    },
    // 回复转发更新标记
    async updateMailFlags() {
      // const composectx = this.deepCopy(this.userStreamCache.composectx)
      const composectx = this.deepCopy(this.composeData)
      const { mbox, uv, uid } = composectx
      const type = this.composeType
      const flag = {}
      if (['reply', 'replyall', 'replyallatt'].includes(type)) {
        flag.answered = 1
      } else if (['forward', 'asattforward', 'asattsforward'].includes(type)) {
        flag.forwarded = 1
      } else {
        return 1
      }
      const params = {
        module: 'mail',
        require: {
          action: 'updateFlags',
          flags: flag,
          mboxList: [{
            mbox: mbox,
            uidList: uid,
            uidvalidity: uv,
            uv: uv,
          }],
        },
      }
      const arr = []
      if (type === 'asattsforward') {
        composectx.atts.map(item => {
          arr.push({
            mbox: item.mbox,
            uidList: item.uid,
            uidvalidity: item.uv,
            uv: item.uv,
          })
        })
        params.require.mboxList = arr
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        if (type === 'reply' || type === 'forward') this.$myApi.updateMailStatus([{ uidvalidity: uv, uid: uid, data: { [type]: '1' }}])
      } else {
        console.log(uv, uid)
      }
    },
    GethandlerDraftData(ctx) {
      const req = {
        'module': 'mail',
        'require': {
          action: 'getMail',
          filter_query: {},
          need_body: 1,
          need_info: 1,
          need_position: 1,
          need_send_info: 0,
          orderby: 'uid',
          sequence: 'DESC',
          mbox: 'Drafts',
          type: 'folder',
          uid: ctx.uid,
          uidvalidity: ctx.uidvalidity,
          uv: ctx.uidvalidity,
          mail_psw: ctx.mail_psw,
        },
      }
      // return new Promise((reslove, reject) => {
      this.$axios.post(this.$apiData.mail, { req: serialize(req) })
        .then(res => {
          // reslove(res.result.mailData)
          this.handlerDraft(res.result.mailData)
        })
      // })
    },
    // 处理草稿
    handlerDraft(mailData) {
      const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      // const { mbox, uv, uid, subject, attachments, undownload, unprint, unresponse, priority, bodys, to_s, cc_s, bcc_s } = ctx.mailData
      this.req.body = mailData.bodys[0].substring(1, mailData.bodys[0].length)
      this.$refs.tinymceED.setContent(this.req.body)
      this.last_draft_uid = mailData.uid
      this.req.subject = mailData.subject
      const att = this.handlerattachments(mailData.attachments, mailData.mbox, mailData.uid, mailData.uv)
      this.fakeAttachmentList = [...att]
      this.req.atts = [...att]
      this.req.undownload = parseInt(mailData.undownload)
      this.req.unprint = parseInt(mailData.unprint)
      this.unresponse = parseInt(mailData.unresponse)
      this.req.priority = parseInt(mailData.priority)
      this.tags_to = mailData.to_s.map(item => {
        return {
          mail: item[0],
          name: item[1],
          path: item[2],
          isValid: mailReg.test(item[0]),
        }
      })
      this.tags_cc = mailData.cc_s.map(item => {
        return {
          mail: item[0],
          name: item[1],
          path: item[2],
          isValid: mailReg.test(item[0]),
        }
      })
      this.tags_bcc = mailData.bcc_s.map(item => {
        return {
          mail: item[0],
          name: item[1],
          path: item[2],
          isValid: mailReg.test(item[0]),
        }
      })
      if (this.tags_bcc.length > 0) this.showSecretMail = true
      // if (this.$route.query.save) {
      //   setTimeout(() => {
      //     this.saveDrafts()
      //   }, 100)
      // }
      this.dataChange()
    },
    // 大附件上传到网盘
    uploadNetdisk(file) {
      const params = {
        module: 'mail',
        require: {
          action: 'uploadToNetdisk',
          file_check: file.md5,
          nd_name: file.name,
          upid: file.upid,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch(() => {
          this.showAutoTips = false
          this.is_auto_send = false
          resolve({ result: 'error' })
        })
      })
    },
    // 获取大附件下载链接
    getdownloadlargefileURL(magic_id) {
      const { netdisk_prefix } = this.$myData.sysConfig
      const { node } = this.$store.state.app
      return `${netdisk_prefix || ''}downloadlargefile.php?nsnode=s1_${node}&magic_id=${magic_id}`
    },
    // 选择文件时触发
    fileChange(files) {
      const arrs = Array.prototype.slice.call(files)
      arrs.map(item => {
        if (this.uploadFiles.indexOf(item) === -1) {
          this.uploadFiles.push(item)
        }
      })
    },
    // 文件上传成功回调
    async fileUploadSuccess(res, file, filelist) {
      const { lifespan_forever, station_lifespan } = this.$store.state.app
      // const normalsize = this.req.atts.reduce((pre, cur) => pre + Number(cur.size), 0)
      // Number(normalsize) ---所有附件的和
      // const bigsize = this.bigAttachmentList.reduce((pre, cur) => pre + cur.size, 0)

      if (Number(res.size) > Number(this.$store.state.app.upload_mode_limit)) {
        const { result } = await this.uploadNetdisk(res)
        if (result === 'error') {
          this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(file), 1)
          this.$refs.uploadfils.handleRemove(file)
          return false
        }
        this.bigAttachmentList.push({
          name: res.name,
          size: res.size,
          type: 'upload',
          expire: station_lifespan,
          upid: res.upid,
          fileuptype: 'big',
          nd_url: this.getdownloadlargefileURL(result.magic_id),
          bodys: BigAttachmentsText({
            url: this.getdownloadlargefileURL(result.magic_id),
            name: res.name,
            expire: lifespan_forever === '1' ? this.$t('message.compose.neverexpire') : getAfterTimer(new Date(this.req.date), station_lifespan),
          }),
        })
        this.gerTransferList()// 获取中转站列表
        this.bigCount++
      } else {
        const normalsize = this.req.atts.reduce((pre, cur) => pre + Number(cur.size), 0)
        // Number(normalsize) ---所有附件的和
        if ((Number(normalsize) + Number(res.size)) > Number(this.$store.state.app.mail_limit) * 1024 * 1024) {
          this.$message.error(this.$t('message.compose.err12', { num: Number(this.$store.state.app.mail_limit) }))
          this.$refs.uploadfils.handleRemove(file)
        } else {
          this.req.atts.push(res)
        }
      }
      if (this.req.subject === '') {
        this.req.subject = this.fakeAttachmentList[0].name
      }
      if (this.$refs.uploadfils.isCompleted() && this.is_auto_send) {
        this.sendMail()
      }
    },
    // 删除真实附件数据
    deleteReqFiles(raw) {
      this.req.atts.splice(this.req.atts.indexOf(raw), 1)
      this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(raw), 1)
      // if (raw.size > this.$store.state.app.upload_mode_limit) {
      //   this.deleteBigAttachment(raw.upid)
      // }
    },
    // 删除待发送大附件列表
    deleteBigAttachment(id) {
      console.log(this.fakeAttachmentList, this.bigAttachmentList)
      // const current = this.bigAttachmentList.find(({ upid }) => upid === id)
      // if (this.fakeAttachmentList.indexOf(current) !== -1) {
      //   this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(current), 1)
      // }
      // if (this.bigAttachmentList.indexOf(current) !== -1) {
      //   this.bigAttachmentList.splice(this.bigAttachmentList.indexOf(current), 1)
      // }
      // this.bigCount--
    },
    // 断点续传
    handleResumeUpload(file) {
      this.$refs.uploadfils.handleResume(file)
    },
    // 删除弹窗文件
    handleRemovepload(file) {
      this.$refs.uploadfils.deleteFileList(file)
      this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1)
    },
    // 删除写信附件区域文件(组件内部)
    handleRemove(file) {
      console.log(file)
      console.log(this.fakeAttachmentList, this.bigAttachmentList)
      return this.$confirm(this.$t('message.compose.info36'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(() => {
        if (file.type === 'splitupload') {
          // if (file.size > this.$store.state.app.upload_mode_limit) {
          //   this.deleteBigAttachment(file.uid)
          // }
          console.log(this.fakeAttachmentList, this.bigAttachmentList)
          this.fakeAttachmentList = this.fakeAttachmentList.filter(item => item.uid !== file.uid)
          if (Number(file.size) > Number(this.$store.state.app.upload_mode_limit)) {
            this.bigAttachmentList = this.bigAttachmentList.filter(item => item.upid !== file.uid)
            this.bigCount--
          }
          this.$refs.uploadfils.handleRemove(file)
        } else if (file.type === 'netdisk') {
          // this.deleteReqFiles(file)
          this.fakeAttachmentList = this.fakeAttachmentList.filter(item => item.upid !== file.upid)
          this.bigAttachmentList = this.bigAttachmentList.filter(item => item.upid !== file.upid)
        } else {
          this.deleteReqFiles(file)
        }
      }).catch(() => {

      })
    },
    // 上传开始时处理
    handleStart(filelist) {
      filelist.map(file => {
        if (this.fakeAttachmentList.indexOf(file) === -1) {
          this.fakeAttachmentList.push(file)
        }
      })
    },
    // 禁止上传附件类型
    handleBefore(files) {
      let isNull = false
      for (let index = 0; index < files.length; index++) {
        const element = files[index]
        if (element.size === 0) {
          isNull = true
          break
        }
      }
      if (isNull) {
        this.$message.error(this.$t('message.compose.err13'))
        return false
      }

      const { limit_atta_type } = this.$store.state.app
      const type = limit_atta_type.split(',')
      const isUpload = files.some(item => !type.includes(item.name.substr(item.name.lastIndexOf('.') + 1).toLowerCase()))
      if (isUpload) {
        return true
      } else {
        return true
      }
    },

    // 删除写信附件区域文件(可视附件)
    fileRemove(file, filelist) {
      // this.fakeAttachmentList.splice(this.fakeAttachmentList.indexOf(file), 1)
      const current = this.req.atts.find(({ upid }) => upid === file.uid)
      if (this.req.atts.indexOf(current) !== -1) {
        this.req.atts.splice(this.req.atts.indexOf(current), 1)
      }
    },

  },
}
</script>
<style lang="scss">

.popperClass.el-date-picker {
  width: 330px!important;
}
</style>
<style lang="scss" scoped>
.sendbtn{
  padding: 7px 12px 7px 8px!important;
}
.mail-btn{
  padding: 7px 12px;border-radius: 2px;
}
.aqSetting{
  margin-left: 15px;
  display: inline-block;
  .svg-icon{
    vertical-align: -0.3em;
  }
}
.mail-info-header{
  padding-top: 11px;
}
.mail-info-header1{
  display: flex;
  align-items: center;
}
.mail-info-header2{
  padding-top: 8px;
}
/deep/.el-breadcrumb__inner{
  cursor: pointer!important;
}
/deep/.previewDialog.el-dialog {
  .el-dialog__header {
    display: none;
  }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer{
    padding: 20px;
  }
}

.el-checkbox{
  margin-right: 20px;
}
.compose-container{
  position: relative!important;
  width: 100%;
  overflow: auto;
  height: 100%;
  cursor: default;
  // min-width: 1350px;

/deep/.is-active{
  font-weight: bolder;
}
  .compose-icon{
    width: 14px;
    height: 14px;
    // vertical-align: baseline;
  }
  .eye-icon{
    width: 1.3em;
    height: 1.3em;
    vertical-align: middle;
    cursor: pointer;
  }
  .border-bottom{
    border-bottom: 1px solid #D7D7D7;
    .underline{
      text-decoration: underline;
      cursor: pointer;
    }
    .sendInfo{
      margin-left: 4px;
    }
    .mail-input{
      outline: none;
      border: none;
      width: 100%;
      font-weight: bold;
      padding-left: 7px;
    }
    .tail{
      margin-right: 13px;
    }
    .tail-icon{
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      border-radius: 3px;
      padding: 5px 7px;
      &:hover{
        background: #EAEAEA;
      }
      .tail-icon-item{
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: 1px solid #8d8c8c;
      }
    }
  }
  .compose-header{
    padding: 10px 10px 10px 12px;
    position: relative;
    min-width: 1350px;
    .autosenddiv{
      color: #fff;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      border-radius: 3px;
      white-space: nowrap;
    }

  }
  .compose-main{
    padding: 0 20px;
    overflow-y: auto;
    min-width: 1350px;
    .line{
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      .address{
        flex: 1;
        padding-left: 28px;
      }
      .annex-icon{
        padding: 3px 5px 0 6px;
      }
      .annex-title{
        display: inline-block;
        height: 26px;
        line-height: 26px;
        padding-right: 6px;
        border-radius: 3px;
        cursor: pointer;
        &:hover{
          background-color: #f1f1f1;
        }
        &:active{
          background-color: #e8e8e8;
        }
      }
      .no-annex{
        margin-left: 10px;
        border-left: 1px solid #D7D7D7;
        padding-left: 15px;
        color: #afafaf;
      }
    }
    .attachment{
      flex-wrap: wrap;
      justify-content: flex-start;
      white-space: normal;
      .attachment-item{
        min-width: 290px;
        background: #F9F9F9;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #D7D7D7;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        display: inline-block;
        .attachment-icon{
          position: relative;
          margin-right: 8px;
          .atticon{
            width: 2.6em;
            height: 2.6em;
            margin: 5px 8px;
          }
        }
        .tag{
          font-size: 12px;
          background-color: #FF0000;
          display: inline-block;
          color: #fff;
          padding: 1px 6px;
          // font-size: 12px;
          border-radius: 11px;
          line-height: 17px;
          position: absolute;
          top: -11px;
          left: 9px;
        }
        .attachment-info{
          flex: 1;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-evenly;
          padding: 7px 0;
          .bottom{
            margin-top: 5px;
          }
          .title{
            margin-right: 5px;
            display: inline-block;
            max-width: 145px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .size{
            color: #A3A3A3;
          }
          .preview{
            margin-right: 17px;
             cursor: pointer;
          }
          .delete{
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
    .tinymce{
      height: 565px;
      margin-bottom: 50px;
    }
  }
  @media screen and(min-width: 500px) and(max-width:991px) {
     .compose-main{
      height:  calc(90vh - 150px);
    }
  }
  @media(min-width: 992px) {
    .compose-main{
      height:  calc(90vh - 150px);
    }
  }
  @media(min-width: 1200px) {
    .compose-main{
      height:  calc(90vh - 120px);
    }
  }

  .compose-footer{
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #EDEDED;
    min-width: 1350px;
    overflow-x: auto;
    .compose-footer-info{
      display: flex;
      align-items: center;
      padding: 10px 25px 15px 20px;
      /deep/.el-checkbox__inner{
        width: 16px;
        height: 16px;
        &::after{
          height: 8px;
          left:5px;
        }
      }
      /deep/.el-checkbox__label{
        color: #1D1B1B;
      }
      .signature{
        padding: 0px 13px 0px 22px;
        border-right: 1px solid #ccc;
        display: flex;
        align-items: center;
        .title{
          // padding: 2px;
          white-space: nowrap;
          color: #1D1B1B;
        }
        /deep/.el-input__suffix{
                top:0
              }
        /deep/.el-input--suffix {
          input{
            border: none;
            // height: 24px;
            width: auto;
            padding-left: 0px;
            &:hover{
              border: none!important;
            }
          }
          .el-input__icon{
            line-height: 24px;
          }
        }
        /deep/.el-icon-arrow-up:before{
          content: "\e790";
        }
      }
      .formname{
        padding-left: 0px;
      }
      .info-item{
        display: flex;
        padding-left: 20px;
        .more-info{
          display: flex;
          align-items: center;
          padding-left: 10px;
          cursor: pointer;
          user-select: none;
          white-space: nowrap;
        }
        .more-info-icon{
          padding: 0 10px;
          font-weight: bold;
        }
      }
    }
    /deep/.compose-footer-moreInfo{
      padding: 14px 25px 2px 20px;
      .el-checkbox .el-checkbox__label{
        color: #1d1b1b;
      }
      .el-checkbox__inner{
        width: 16px;
        height: 16px;
        &::after{
          height: 8px;
          left:5px;
        }
      }

    }
  }
}
 /deep/.el-checkbox__label{
        padding-left: 5px;
        color: #222;
      }
.sendTime{
  .sendTimeTip{
    display: flex;
    background: #F6F6F6;
    border-radius: 1px 1px 1px 1px;
    padding: 8px 17px 10px 10px;
    .tip{
      padding-left: 5px;
    }
  }
  .sendTimeOption{
    margin-top: 14px;
    .date-picker{
      display: flex;
      justify-content: space-between;
      padding: 8px 0 6px 0;
      .date-picker-item{
        flex: 1;
        padding-right: 10px;
      }
      /deep/.customselect{
        .el-input__suffix{
          top: 0!important;
        }
      }

    }
    .mail-password{
      .title{
        word-break: keep-all;
      }
      /deep/.custominput{
        // width: 100%;
        .el-input__inner{
          // top: 3px;
          padding-right: 32px;
        }
      }
    }
  }
}
.upload-container{
  .nav{
    display: flex;
    justify-content: space-between;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    .nav-item{
      font-weight: bold;
      color: #1d1b1b;
      flex: 1;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }
    .active{
      border-bottom: 3px solid #d0161b;
    }
  }
  .content{
    padding: 0 38px;
    .attArea{
      max-height: 251px;
      overflow: auto;
      .localAtts{
        height: 100%;
        .title{
          padding-bottom: 6px;
          margin-right: 35px;
        }
        .active{
          border-bottom: 2px solid #d0161b;
        }
      }
      .attArea-item{

        border-bottom: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        padding: 10px 0;
        .img{
          margin-left: 16px;
          .atticon{
            width: 2em;
            height: 2em;
          }
        }
        .info{
          height: 100%;
          padding-left: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .filename{
            display: inline-block;
            .size{
              color:#A5A4A4;
            }
            .name{
              display: inline-block;
              max-width: 500px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: text-top;
            }
          }
          .handle{
            display: flex;
            align-items: center;
            .text-color{
              cursor: pointer;
              color: #d0161b!important;
            }
          }
        }
      }
    }
    .my-att{
    display: flex;
    flex-direction: column;
    height: 377px;
      padding-top: 17px;
      .haveatts{
          height: 121px;
      }
      .contentInfo{
        margin: auto;
      }

    }
  }
}
/deep/.scrollbar-right{
  height: 350px;
}
/* Style the scrollbar */
.el-scrollbar__thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}

.el-scrollbar__bar {
  background-color: #f0f0f0;
  border-radius: 4px;
}
.css-checkbox{
  /deep/.el-checkbox__label{
    display: none;
  }
}
/deep/.el-tabs__nav-wrap::after{
  background-color: transparent;
}
.warning_tip{
  vertical-align: sub;
}
.el-button--default{
  background-color: #eee;
}
/deep/.custom-select{
  .el-input{
    .el-input__inner{
    padding-left: 5px!important;
    padding-right: 32px!important;
    border-radius: 2px;
  }
  }

}
/deep/.expireCustom{
  .el-dialog__body{
    padding: 10px 20px 5px;
  }
  .el-input__inner{
  padding: 0 15px 0 10px;
}
}
/deep/.timingcustom{
  .el-dialog__body{
    padding: 10px 20px;
  }
}
.fw{
  font-weight: bold;
}
.secureBox{
  display: flex;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #C4C4C4;
  padding: 18px;
  margin-bottom: 15px;
  cursor: pointer;
  /deep/ .el-radio__label{
    display:none;
  }
  .radio{
    width: 20px;
    margin-right: 5px;
  }
  div{
    flex: 1;
    font-size: 100%;
    h1{
    font-size: 100%;
      font-weight: bold;
      color: #2C2C2C;
    }
    h2{
    font-size: 100%;
      margin-top: 20px;
      color: #2C2C2C;
    }
    p{
      padding: 9px 0;
      color: #858585;
      clear: both;
    }
    .ts{
      display:block;
      color: #D0161B;
      .svgIcon{
        vertical-align : -0.3em;
      }
    }
    .switchBtn{
      float : right;
    }
    .line{
      width: 100%;
      height: 1px;
      background: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      border: 1px solid #E1E1E1;
      display: block;
      margin-bottom: 12px;
    }
    .delsel{
      float: right;
      margin: -10px 0 0 10px;
    }
    .del_expire{
      width: 100px;
    }
    .xzdate{
      width: 265px;
    }
    .mail-password{
      padding-top: 0px;
    }
    .custominput{
      margin-top: 5px;
      /deep/.el-input__suffix{
        top: 5px;
      }
    }
  }
}
.secureBorder{
  border: 2px solid #D0161B;
  border-radius: 5px;
}
</style>
