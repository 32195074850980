<template>
  <div class="calendar">
    <div
      v-if="isShow"
      class="calendar-body"
    >
      <div class="calendar-header">
        <span
          class="el-icon-d-arrow-left preMonth cal-icon text-hover"
          @click="handlePreYear"
        />
        <span
          class="el-icon-arrow-left preMonth cal-icon text-hover"
          @click="handlePreMonth"
        />
        <span class="header-time">{{ $t('message.calendar.cal1',{year:time[0],month:lang==='english'?months[time[1]-1]:time[1]}) }}</span>

        <span
          class="el-icon-arrow-right nextYear cal-icon text-hover"
          @click="handleNextMonth"
        />
        <span
          class="el-icon-d-arrow-right nextYear cal-icon text-hover"
          @click="handleNextYear"
        />
      </div>
      <div class="calendar-table">
        <table class="calendar-inner-table">
          <tr>
            <th
              v-for="(item, index) in weekDay"
              :key="index"
            >{{ item }}</th>
          </tr>
          <tr
            v-for="(item, index) in calendarList"
            :key="index"
          >
            <td
              v-for="(i, ind) in item"
              :key="ind"
              width="24"
              :class="[
                i.myClassName,
                { 'text-color': isCurrentDayStyle(i) },

              ]"
              @click="selectExactDay(i)"
            >
              <div class="content">
                <span :class="[ { 'selected theme-background-color': i.selected }]">
                  {{ i.day }}
                </span>
                <div
                  v-if="i.haveEvent"
                  class="dot theme-background-color"
                />
              </div>
              <!--  -->
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getYearMonthDay, getStartMonthDay, getMonthCount } from './utils'
export default {
  props: {
    value: {
      // type: Date||Number,
      type: Date,
      default: () => new Date(),
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    firstSun: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      time: [],
      isShow: true,
      // weekDay: ['日', '一', '二', '三', '四', '五', '六'],
      // weekDay1: ['一', '二', '三', '四', '五', '六', '日'],
      calendarList: [],
      month: 0,
      year: 0,
      flag: false,
      inputVal: [],
      lang: localStorage.getItem('lang') || 'simplified_chinese',
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
  },
  computed: {
    weekDay() {
      if (this.firstSun) {
        if (localStorage.getItem('lang') === 'english') {
          return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        } else {
          return ['日', '一', '二', '三', '四', '五', '六']
        }
      } else {
        if (localStorage.getItem('lang') === 'english') {
          return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        } else {
          return ['一', '二', '三', '四', '五', '六', '日']
        }
      }
    },

  },
  watch: {
    '$myState.calendar.jumpDate': {
      handler(value) {
        if (value) {
          this.jump()
        }
      },
    },
  },
  mounted() {
    if (this.$myState.calendar.jumpDate) {
      this.jump()
    } else {
      this.getExactCurrentMonthDay(new Date().getTime() - 24 * 60 * 60 * 1000)
    }
  },
  methods: {
    jump() {
      this.flag = true
      this.getExactCurrentMonthDay(this.$myState.calendar.jumpDate, false)
      const start = this.calendarList[0][0]
      const end = this.calendarList[5][6]
      const range = [this.formatDate1([start.year, start.month, start.day]), this.formatDate1([end.year, end.month, end.day])]
      console.log(this.$myState.calendar.jumpDate)
      setTimeout(() => {
        this.onSelect(this.$myState.calendar.jumpDate, range)
        // this.$mySate.calendar.jumpDate = null
      }, 100)
    },
    formatDate() {
      const year = this.inputVal[0]
      const month =
        this.inputVal[1] < 10 ? '0' + this.inputVal[1] : this.inputVal[1]
      const day =
        this.inputVal[2] < 10 ? '0' + this.inputVal[2] : this.inputVal[2]
      // 因为一开始没选中到时候 会出现undefined的问题所以多了一个判断
      return !year && !month && !day ? '' : `${year}-${month}-${day}`
    },
    // 上一年
    handlePreYear() {
      let year = this.time[0]
      const month = this.time[1]
      year--
      this.inputVal = [year, month, 1]
      // this.onSelect(this.formatDate())
      // 这里得去更新全局time这个变量的值
      this.$set(this.time, 0, year)
      this.calendarList = this.combineData(year, month)
      // const start = this.calendarList[0][0]
      // const end = this.calendarList[5][6]
      // const range = [this.formatDate1([start.year, start.month, start.day]), this.formatDate1([end.year, end.month, end.day])]
      // this.onSelect(this.formatDate(), range)
    },
    // 下一年
    handleNextYear() {
      let year = this.time[0]
      const month = this.time[1]
      year++
      this.inputVal = [year, month, 1]
      // this.onSelect(this.formatDate())
      this.$set(this.time, 0, year)
      this.calendarList = this.combineData(year, month)
      // const start = this.calendarList[0][0]
      // const end = this.calendarList[5][6]
      // const range = [this.formatDate1([start.year, start.month, start.day]), this.formatDate1([end.year, end.month, end.day])]
      // this.onSelect(this.formatDate(), range)
    },
    // 上个月
    handlePreMonth() {
      let year = this.time[0]
      let month = this.time[1]
      if (month > 1) {
        month--
      } else {
        year--
        month = 12
      }
      this.inputVal = [year, month, 1]
      // this.onSelect(this.formatDate())
      // 这里得去更新全局time这个变量的值
      this.$set(this.time, 0, year)
      this.$set(this.time, 1, month)
      this.calendarList = this.combineData(year, month)
      // const start = this.calendarList[0][0]
      // const end = this.calendarList[5][6]
      // const range = [this.formatDate1([start.year, start.month, start.day]), this.formatDate1([end.year, end.month, end.day])]
      // this.onSelect(this.formatDate(), range)
    },
    // 下个月
    handleNextMonth() {
      let year = this.time[0]
      let month = this.time[1]
      if (month > 11) {
        year++
        month = 1
      } else {
        month++
      }
      this.inputVal = [year, month, 1]
      // this.onSelect(this.formatDate())
      this.$set(this.time, 0, year)
      this.$set(this.time, 1, month)
      this.calendarList = this.combineData(year, month)
      // const start = this.calendarList[0][0]
      // const end = this.calendarList[5][6]
      // const range = [this.formatDate1([start.year, start.month, start.day]), this.formatDate1([end.year, end.month, end.day])]
      // this.onSelect(this.formatDate(), range)
    },
    // 获取当前面板的具体日期（上个月+本月+下个月） 6*7
    getExactCurrentMonthDay(timeStamp, r) {
      // 当天日期
      const { year, month, day } = getYearMonthDay(timeStamp)
      this.time = [year, month, day]
      // 获取这个月显示的所有日期
      this.calendarList = this.combineData(year, month)
      console.log(r)
      if (r) return
      // flag是为了还未选中时  做了个开关
      if (this.flag) {
        // 选中日期的标识
        this.calendarList.forEach((item) => {
          item.forEach((i) => {
            if (i.year === year && i.month === month && i.day === day) {
              i.selected = true
            }
          })
        })
      }
      this.flag = true
    },
    // 抽成一个函数 集中处理数据
    combineData(year, month) {
      // 上个月在本月占位的个数（即这个月1号是周几，如果是周五，那么就说明上个月在本月占位是5个）
      let lastMonthDayCount = getStartMonthDay(year, month)
      // 这里需要注意的是周一到周六对应的是1-6，而周日是0

      if (!this.firstSun) {
        lastMonthDayCount = lastMonthDayCount - 1
      }
      // 获取上个月的具体天数（即上个月最后一天的日期）
      const lastMonthDay = getMonthCount(year, month - 1)
      // 组装后的上个月日期的数组对象
      const lastExactDate = this.getlastMonthDay(
        lastMonthDay,
        lastMonthDayCount,
        'lastMonthDay',
        year,
        month,
      )
      // 本月天数
      const currentMonthDayCount = getMonthCount(year, month)
      // 组装后的本月日期的数组对象
      const currentExactDate = this.getMonthDay(
        currentMonthDayCount,
        'currentMothDay',
        year,
        month,
      )
      // 下个月占位的个数
      let nextMonthDayCount = 42 - (lastMonthDayCount + currentMonthDayCount)
      if (lastMonthDayCount === -1) {
        nextMonthDayCount = nextMonthDayCount - 1
      }
      // 组装后的下个月日期的数组对象
      const nextExactDate = this.getMonthDay(
        nextMonthDayCount,
        'nextMothDay',
        year,
        month,
      )
      // 获取这个月显示的所有日期（上个月+本月+下个月）
      const calendarData = [
        ...lastExactDate,
        ...currentExactDate,
        ...nextExactDate,
      ]
      // 变成二维数组  为了方便渲染6行7列的数据
      const twoScaleArr = this.toTwoScaleArr(calendarData, 7)
      return twoScaleArr
    },
    // 生成天数的数据
    getMonthDay(count, className, year, month) {
      const nextMonthArr = []
      for (let i = 1; i <= count; i++) {
        nextMonthArr.push({
          year: className === 'nextMothDay' && month === 12 ? year + 1 : year,
          month: className === 'currentMothDay' ? month : month === 12 ? 1 : month + 1,
          day: i,
          myClassName: className,
          selected: false,
          haveEvent: false,
        })
      }
      return nextMonthArr
    },
    getlastMonthDay(lastMonthDay, lastMonthDayCount, className, year, month) {
      const lastMonthDayArr = []
      while (lastMonthDayCount > 0) {
        lastMonthDayArr.push({
          year: year,
          month: month - 1,
          day: lastMonthDay--,
          myClassName: className,
          selected: false,
          haveEvent: false,
        })
        lastMonthDayCount--
      }
      return lastMonthDayArr.reverse()
    },
    // 一维数组转二维数组
    toTwoScaleArr(arr, num) {
      const len = Math.ceil(arr.length / num)
      const newArr = []
      for (let i = 0; i < len; i++) {
        newArr.push(arr.slice(i * num, num * (i + 1)))
      }
      return newArr
    },
    // 判断是否为当天的样式
    isCurrentDayStyle(item) {
      const { year, month, day } = getYearMonthDay(new Date().getTime())
      const { year: y, month: m, day: d } = getYearMonthDay(
        new Date(item.year, item.month - 1, item.day).getTime(),
      )
      return year === y && month === m && day === d
    },
    // 选中的样式
    selectExactDay(item) {
      const { year, month, day } = item
      this.time = [year, month, day]
      this.inputVal = this.time

      this.getExactCurrentMonthDay(
        new Date(this.time[0], this.time[1] - 1, this.time[2]).getTime(),
      )
      console.log(this.calendarList)
      const start = this.calendarList[0][0]
      const end = this.calendarList[5][6]
      const range = [this.formatDate1([start.year, start.month, start.day]), this.formatDate1([end.year, end.month, end.day])]
      this.onSelect(this.formatDate(), range)
    },
    formatDate1(val) {
      const year = val[0]
      const month =
      val[1] < 10 ? '0' + val[1] : val[1]
      const day =
      val[2] < 10 ? '0' + val[2] : val[2]
      // 因为一开始没选中到时候 会出现undefined的问题所以多了一个判断
      return !year && !month && !day ? '' : `${year}-${month}-${day}`
    },
    getTimeRange() {
      const start = this.calendarList[0][0]
      const end = this.calendarList[5][6]
      const range = [this.formatDate1([start.month === 0 ? start.year - 1 : start.year, start.month === 0 ? 12 : start.month, start.day]), this.formatDate1([end.month === 13 ? end.year + 1 : end.year, end.month === 13 ? 1 : end.month, end.day])]
      return range
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar {
  font-size: 85%;
  // height: 300px;
  text-align: center;
  margin: 0 auto;
}
.calendar-body {
  background: #fff;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 5px;
}
.calendar-header {
  // height: 30px;
  // line-height: 30px;
  display: flex;
  align-items: center;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.calendar-header :hover {
  // color: #d0161b;
}
.preYear,
.preMonth,
.nextYear,
.nextMonth {
  color: #606266;
  user-select: none;
}
.header-time {
  font-weight: bold;
  display: inline-block;
  width: 80%;
  text-align: center;
  color: #1d1b1b;
  cursor: default;
  &:hover{
    color:#606266
  }
}
.preMonth {
  margin-left: 20px;
}
.nextYear {
  margin-right: 20px;
}
.calendar{
  // position: fixed;
  // bottom: 0;
  position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
}
.calendar-table {
  width: 100%;
  border-collapse: collapse;
}
.calendar-table th {
  padding: 5px;
  width: 41px;
  height: 41px;
  box-sizing: border-box;
  color: #606266;
}
.calendar-table td {
  color: #606266;
  user-select: none;
}
.calendar-inner-table {
  width: 100%;
  tr{
    line-height: 30px;
  }
}
.calendar-inner-table td {
    width: 32px;
    height: 30px;
    // padding: 4px 0;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    position: relative;
    .content{
      position: relative;
      height: 30px;
      padding: 3px 0;
      box-sizing: border-box;
    }
    span{
      width: 24px;
      height: 24px;
      display: block;
      margin: 0 auto;
      line-height: 24px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
    }
}
.calendar-inner-table tr:nth-child(1) {
  margin-bottom: 5px;
}
.calendar-inner-table .lastMonthDay,
.calendar-inner-table .nextMothDay {
  color: #c0c4cc !important;
}
.calendar-table .currentDay {
  color: #d0161b;
}
.calendar-table .selected {
  color: #fff;
  border-radius: 50%;
  background-color: #d0161b;
  // display: block;
  // margin: 0 auto;
}
.cal-icon{
  padding: 0.2em;
  border-radius: 0.2em;
  box-sizing: border-box!important;
  font-weight: bold;
}
.cal-icon:hover{
  background-color: #f0f0f1;
}
.selected-background-color{
  background-color: #ddd;
}
.dot{
  position: absolute;
  bottom: 3px;
  left: 18px;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
</style>
