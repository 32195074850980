<template>
  <div class="demo-app">
    <div class="demo-app-main">
      <div class="fc-toolbar">
        <div class="fc-left">
          <el-button @click="today">{{ $t('message.calendar.cal2') }}</el-button>
          <el-radio-group
            v-model="datePositation"
            class="btn-group"
            @input="selectDateType"
          >
            <el-radio-button :label="$t('message.calendar.cal3')" />
            <el-radio-button :label="$t('message.calendar.cal4')" />
            <el-radio-button :label="$t('message.calendar.cal5')" />
          </el-radio-group>

        </div>
        <div class="fc-center">
          <i
            class="el-icon-arrow-left cur cal_icon"
            @click="prev"
          />
          <h2 class="data-title">
            {{ title }}
          </h2>
          <i
            class="el-icon-arrow-right cur cal_icon"
            @click="next"
          />
        </div>
        <div style="display:flex;align-items: center;">
          <!-- <el-button @click="freshRender"> 打印日程 </el-button> -->
          <el-button
            ref="next"
            class="search"
            type="button"
            @click="shareCarlendar"
          >
            {{ $t('message.calendar.cal6') }}

          </el-button>
          <div class="notice">
            <i
              :title="$t('message.calendar.cal7')"
              class="el-icon-alarm-clock naozhong-tips cur"
              @click="showCaltips"
            />
          </div>
        </div>
      </div>
      <FullCalendar
        ref="fullCalendar"
        class="demo-app-calendar"
        :options="calendarOptions"
      >
        <template #eventContent="arg">
          <div style="height: 100%;cursor: pointer;overflow: hidden;text-overflow: ellipsis;">
            {{ (arg.event.extendedProps.from_id&&arg.event.extendedProps.privilege==='0')?'':formateData(arg.event.startStr) }}&nbsp;{{ arg.event.extendedProps.from_id&&arg.event.extendedProps.privilege==='0'?`${arg.event.extendedProps.ky_transp}（${arg.event.title||$t('message.calendar.cal28')}）`:arg.event.title||$t('message.calendar.cal28') }}
          </div>
        </template>
      </FullCalendar>
    </div>
    <CalendarTips
      ref="caltips"
      @check="jumpDate"
    />
    <popup
      ref="pop"
      v-model="isPopupVisible"
      :title="cardTitle"
      @onClose="onClose"
    >
      <div class="calendar-card">
        <div
          :title="cardInfo.title.length>16?cardInfo.title:''"
          style="color:#1d1b1b;display:flex;justify-content: space-between;"
        >
          <span>
            {{ cardInfo.title||$t('message.calendar.cal28') |handelCharB }}
            <span
              v-if="![1,5].includes(cardInfo.type)&&meeting_status!=='5'&&meeting_status!=='6'"
              style="margin-left: 5px;vertical-align: middle;"
              :title="$t('message.calendar.cal29')"
              class="cur"
              @click="tansferMetting"
            ><svg-icon
              icon-class="share-cal"
              style="margin-top:5px;width: 1.2em;"
            /></span>
          </span>
          <EventSelect
            v-if="!cardInfo.from_id"
            v-model="selectedTag"
            :tags="eventTags"
            @callback="displayPop"
          /></div>
        <p><span>{{ $t('message.calendar.cal10') }}：</span><span>{{ formatTime(cardInfo.start,cardInfo.end) }}</span></p>
        <p><span>{{ $t('message.calendar.cal9') }}：</span><span :title="cardInfo.location.length>25?cardInfo.location:''">{{ cardInfo.location||'---' |handelCharA }}</span></p>
        <p><span>{{ $t('message.calendar.cal30') }}：</span><span>{{ cardInfo.tip||'---' }}</span></p>
        <p v-if="[2,3].includes(cardInfo.type)"><span>{{ $t('message.calendar.cal31') }}：</span><span>{{ cardInfo.ky_organizer[0] }}</span></p>
        <div v-if="[2,3].includes(cardInfo.type)">
          <span style="display: inline-block;vertical-align: top;">{{ $t('message.calendar.cal32') }}：</span>
          <div style="display: inline-block;width: 407px;max-height: 72px;overflow: auto;"><span>{{ cardInfo.attendee }}</span></div>
        </div>
      </div>
      <div
        v-if="cardInfo.type===2||cardInfo.type===3"
        class="split-line"
      />
      <button
        v-if="meeting_status==='6'"
        type="button"
        class="btn1"
      >{{ $t('message.calendar.cal33') }}</button>
      <div
        v-if="cardInfo.type===2&&meeting_status!=='6'&&meeting_status!=='5'&&(!cardInfo.privilege||cardInfo.privilege==='2')"
        class="accept-info"
      >
        <div
          class="showfeedbacks"
        >
          <div class="tag">{{ $t('message.calendar.cal35',{num:feedbacks_status[0]}) }}<span style="color:#19AD19">{{ $t('message.calendar.cal34') }}</span>，{{ $t('message.calendar.cal35',{num:feedbacks_status[1]}) }}<span style="color:#E7A201">{{ $t('message.calendar.cal36') }}</span>，{{ $t('message.calendar.cal35',{num:feedbacks_status[2]}) }}<span style="color:#D1060A">{{ $t('message.calendar.cal37') }}</span></div>
          <el-button
            type="text"
            @click="showfeedbacks=!showfeedbacks"
          >{{ $t('message.calendar.cal38') }}</el-button>
        </div>
        <el-table
          v-if="showfeedbacks"
          :data="cardInfo.attendee_detail_arr"
          border
          size="mini"
          style="width: 100%;margin-top: 10px;"
          :show-header="false"
          max-height="100"
        >
          <el-table-column
            prop="mail"
            :label="$t('message.calendar.cal39')"
          />
          <el-table-column
            prop="feedback"
            :label="$t('message.calendar.cal40')"
            align="center"
            width="100"
          >
            <template slot-scope="{row}">
              <span :style="{color:feedbacks_color[row.feedback]}">{{ $t(`message.calendar.${feedbacks[row.feedback]}`) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <responseMetting
        v-if="cardInfo.type===3&&(!cardInfo.privilege||cardInfo.privilege==='2')"
        :click-info="responeMettingData"
        :status="meeting_status"
        @callback="getEventList"
        @changeStatus="changeMettingStatus"
      />
      <span
        slot="footer"
        class="footer"
      >
        <span
          class="text-color cur"
          style="font-weight: bold;"
          @click="getEventCardDetail(true)"
        >{{ $t('message.calendar.cal41') }}</span>
        <span>
          <el-button
            v-if="cardInfo.type===3&&(!cardInfo.privilege||cardInfo.privilege==='2')"
            size="mini"
            @click="cancelreceiveMetting"
          >{{ $t('message.calendar.cal42') }}</el-button>
          <el-dropdown
            v-if="cardInfo.type===3&&meeting_status!=='5'&&meeting_status!=='6'&&(!cardInfo.privilege||cardInfo.privilege==='2')"
            split-button
            szie="mini"
            type="primary"
            style="margin-left: 10px;"
            @click="replayOrg('1')"
            @command="replayOrg"
          >
            {{ $t('message.calendar.cal45') }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2">{{ $t('message.calendar.cal44') }}</el-dropdown-item>
              <el-dropdown-item command="1">{{ $t('message.calendar.cal45') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="cardInfo.type===2&&meeting_status!=='5'&&meeting_status!=='6'&&(!cardInfo.privilege||cardInfo.privilege==='2')"
            size="mini"
            @click="cancelMetting"
          >{{ $t('message.calendar.cal46') }}</el-button>
          <el-button
            v-if="cardInfo.type===1"
            size="mini"
            @click="delCalendarEvent"
          >{{ $t('message.calendar.cal42') }}</el-button>
          <el-button
            v-if="[1].includes(cardInfo.type)"
            type="primary"
            size="mini"
            @click="getEventCardDetail(false)"
          >{{ $t('message.calendar.cal47') }}</el-button>
          <el-button
            v-if="cardInfo.type===2&&meeting_status!=='5'&&meeting_status!=='6'&&(!cardInfo.privilege||cardInfo.privilege==='2')"
            type="primary"
            size="mini"
            @click="getEventCardDetail(false)"
          >{{ $t('message.calendar.cal47') }}</el-button>
          <el-button
            v-if="cardInfo.type===5&&clickInfo.event.extendedProps.privilege==='2'"
            size="mini"
            @click="delCalendarEvent"
          >{{ $t('message.calendar.cal42') }}</el-button>
          <el-button
            v-if="cardInfo.type===5&&clickInfo.event.extendedProps.privilege==='2'"
            type="primary"
            size="mini"
            @click="getEventCardDetail(false)"
          >{{ $t('message.calendar.cal47') }}</el-button>
        </span>
      </span>
    </popup>
  </div>
</template>
<script>
import CalendarTips from '@/views/home-view/components/calendar-work/components/CalendarTips'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import calendar from 'js-calendar-converter'
import { formatDate } from './utils'
import zhCnLocale from '@fullcalendar/core/locales/zh-cn'
import enGbLocale from '@fullcalendar/core/locales/en-gb'
import zhTwLocale from '@fullcalendar/core/locales/zh-tw'
import Popup from './Popup.vue'
import calendarReq from '../calendar'
import responseMetting from './responseMetting.vue'
import EventSelect from './eventSelect.vue'
export default {

  components: {
    FullCalendar, // make the <FullCalendar> tag available
    Popup,
    responseMetting,
    CalendarTips,
    EventSelect,
  },
  filters: {
    handelCharA(val) {
      if (val.length > 25) {
        return val.substring(0, 25) + '...'
      } else {
        return val
      }
    },
    handelCharB(val) {
      if (val.length > 16) {
        return val.substring(0, 16) + '...'
      } else {
        return val
      }
    },
  },
  mixins: [calendarReq],
  props: {
    getTime: {
      type: Function,
      default: () => {},
    },
    getRangeTime: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTag: this.$myData.calendar.defaultTag,
      eventTags: this.$myData.calendar.eventTags,
      isPopupVisible: false,
      calendartips: false,
      datePositation: this.$t('message.calendar.cal5'),
      title: '',
      calendarOptions: {
        plugins: [
          listPlugin,
          // 加载插件，V5采用插件模块方式加入
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        views: {
          dayGridMonth: {
            displayEventTime: false, // 是否显示时间
            dayCellContent: (item) => {
              const _date = formatDate(item.date).split('-')
              const _dateF = calendar.solar2lunar(_date[0], _date[1], _date[2])
              if (localStorage.getItem('lang') === 'english') {
                return { html: `<label>${_dateF.cDay}</label>` }
              } else {
                return { html: `<span>${_dateF.Term ? _dateF.Term : _dateF.IDayCn === '初一' ? _dateF.IMonthCn : _dateF.IDayCn}</span><label>${_dateF.cDay}</label>`,
                }
              }
            },
          },
          // timeGridWeek: {
          //   displayEventTime: false, // 是否显示时间
          //   dayHeaderContent(item) {
          //     const _date = formatDate(item.date).split('-')
          //     const _dateF = calendar.solar2lunar(_date[0], _date[1], _date[2])
          //     return { html: `<p>周${_dateF.ncWeek.slice(2)}</p><p><label>${_dateF.cDay}</label><span>${_dateF.IDayCn === '初一' ? _dateF.IMonthCn : _dateF.IDayCn}</span></p>` }
          //   },
          // },
          // timeGridDay: {
          //   dayHeaderContent(item) {
          //     const _date = formatDate(item.date).split('-')
          //     const _dateF = calendar.solar2lunar(_date[0], _date[1], _date[2])
          //     return { html: `<p>周${_dateF.ncWeek.slice(2)}</p><p><label>${_dateF.cDay}</label><span>${_dateF.IDayCn === '初一' ? _dateF.IMonthCn : _dateF.IDayCn}</span></p>` }
          //   },
          // },

        },
        locale: localStorage.getItem('lang') === 'english' ? enGbLocale : localStorage.getItem('lang') === 'simplified_chinese' ? zhCnLocale : zhTwLocale,
        headerToolbar: false, // 隐藏头部的导航栏
        height: window.innerHeight - 130,
        windowResize: function(view) {
          this.el.style.height = window.innerHeight - 130 + 'px'
        },
        firstDay: 0, // 起始周几
        handleWindowResize: false, // 随浏览器窗口变化
        initialView: 'dayGridMonth',
        eventDurationEditable: false,
        nowIndicator: true, // 当前的时间线显示
        eventDisplay: 'block', // 争对全天的情况下，以块状显示 // alternatively, use the `events` setting to fetch from a feed
        editable: false, // 拖动事件
        selectable: false, // 选择多个
        droppable: true,
        timeZone: 'local',
        selectMirror: true,
        displayEventEnd: true,
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false, // 设置时间为24小时
        },
        slotLabelFormat: { // 左侧时间格式
          hour: '2-digit',
          minute: '2-digit',
          meridiem: 'lowercase',
          hour12: false, // false设置时间为24小时
        },
        eventColor: '#D0161B',
        moreLinkContent: this.$t('message.calendar.more'),
        dayMaxEvents: true,
        weekends: true, // 是否显示一周七天
        select: this.handleDateSelect,
        eventClick: this.handleEventClick, // 日程点击事件
        eventsSet: this.handleEvents, // 设置动态数据
        eventMouseEnter: this.handleEventMouseEnter, // 用户将鼠标悬停在事件上时触发
        events: [],
        // eventColor: '#378006',
        // allDayText: '全天',
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [],
      calendarApi: null,
      selectInfo: null,
      value: '',
      cardInfo: {
        title: '',
        location: '',
        notice: '',
        time: '',
        attendee: '',
        attendee_detail_arr: [],
        to: [],
        ky_organizer: '', // 组织者
      },
      feedbacks: {
        0: 'fullcal',
        1: 'acbtn2',
        2: 'cal36',
        3: 'acbtn3',
      },
      feedbacks_status: [],
      feedbacks_color: {
        0: '',
        1: '#19AD19',
        2: '#E7A201',
        3: '#D1060A',
      },
      clickInfo: null,
      cardTitle: this.$t('message.calendar.info1'),
      meeting_status: '1',
      responeMettingData: {},
      showfeedbacks: false,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
  },
  mounted() {
    console.log('mounted, full')
    this.calendarApi = this.$refs.fullCalendar.getApi()
    this.title = this.calendarApi.view?.title
    setTimeout(() => {
      this.getCurrentMonthEvents()
    }, 100)
    // 模拟动态获取数据
    // this.getDtata();
  },
  methods: {
    formateData(dateTimeStr) {
      const date = new Date(dateTimeStr)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`
    },
    formatTime(startTime, endTime) {
      if (!startTime) return ''
      const start = new Date(startTime)
      const end = new Date(endTime || startTime)
      // 判断两个日期是否在同一天
      const isSameDay = start.toISOString().split('T')[0] === end.toISOString().split('T')[0]
      // 格式化日期和时间
      const formatDate = (date, flag) => {
        const year = date.getFullYear()
        const month = ('0' + (date.getMonth() + 1)).slice(-2)
        const day = ('0' + date.getDate()).slice(-2)
        let week = ''
        if (localStorage.getItem('lang') === 'english') {
          week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
        } else {
          week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][date.getDay()]
        }
        const hours = ('0' + date.getHours()).slice(-2)
        const minutes = ('0' + date.getMinutes()).slice(-2)
        if (flag) {
          return `${hours}:${minutes}`
        }
        if (localStorage.getItem('lang') === 'english') {
          return `${date.getDate()} ${this.months[month - 1]} ${year} ${week} ${hours}:${minutes}`
        } else {
          return `${year}年${month}月${day}日 ${week} ${hours}:${minutes}`
        }
      }

      // 返回格式化后的时间字符串
      if (isSameDay) {
        return `${formatDate(start)} - ${formatDate(end, true)}`
      } else {
        return `${formatDate(start)} - ${formatDate(end)}`
      }
    },
    print() {
      console.log('11')
    },
    freshRender() {
      if (this.calendarApi) {
        setTimeout(() => {
          this.calendarApi.updateSize()
        })
      }
    },
    addEvent() {

    },
    // 隐藏日程
    hideEvents(id, flag, isSub) {
      const eventsArr = this.calendarOptions.events
      eventsArr.forEach((item, index, eventsArr) => {
        if (isSub) {
          if (item.from_id === id) {
            item.display = flag
          }
        } else {
          if (item.ky_tag_id === id) {
            item.display = flag
          }
        }
      })
    },
    // 改变标签颜色
    changeEventColor(id, color, isSub) {
      const eventsArr = this.calendarOptions.events
      eventsArr.forEach((item, index, eventsArr) => {
        if (isSub) {
          if (item.from_id === id) {
            item.color = color
          }
        } else {
          if (item.ky_tag_id === id) {
            item.color = color
          }
        }
      })
    },
    async getCurrentMonthEvents() {
      const range = [this.calendarApi.view.activeStart.toISOString().replace(/T.*$/, ''), this.calendarApi.view.activeEnd.toISOString().replace(/T.*$/, '')]
      const res = await this.getEventsList(range)
      const res1 = await this.getSubEventsList(range)
      this.calendarOptions.events = [...res, ...res1]
    },
    // 日程删除
    deleteEvent(val) {
      const eventsArr = this.calendarOptions.events
      try {
        eventsArr.forEach((item, index, eventsArr) => {
          if (item.id === val) {
            throw new Error(index)
          }
        })
      } catch (e) {
        // 删除指定日程
        eventsArr.splice(parseInt(e.message), 1)
      }
    },
    // 日程保存
    saveEvent(val) {
      const eventsArr = this.calendarOptions.events
      try {
        if (eventsArr.length === 0) {
          eventsArr.push(val)
        } else {
          eventsArr.forEach((item, index, eventsArr) => {
            // 若为修改日程
            if (item.eventID === val.eventID) {
              throw new Error(index)
            }
          })
          // 若为新增日程
          eventsArr.push(val)
        }
      } catch (e) {
        // 若为修改日程
        eventsArr.splice(e.message, 1, val)
      }
    },
    // 分享日历界面
    shareCarlendar() {
      this.$emit('on-share')
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    handleDateSelect(selectInfo) {
      this.selectInfo = selectInfo
    },
    async displayPop() {
      const cid = this.clickInfo.event.extendedProps.cal_obj_id
      const res = await this.changeMettingTag({ tag_id: this.selectedTag.tag_id, cal_obj_id: cid })
      if (res.result) {
        this.getCurrentMonthEvents()
      }
    },
    handleEventClick(clickInfo, event, view) {
      if (clickInfo.event.extendedProps.type === 1) {
        if (clickInfo.event.extendedProps.from_id && clickInfo.event.extendedProps.privilege === '0') return false
        this.cardTitle = this.$t('message.calendar.info1')
        this.meeting_status = clickInfo.event.extendedProps.meeting_status
      } else if (clickInfo.event.extendedProps.type === 2) {
        this.cardTitle = this.$t('message.calendar.info2')
        this.meeting_status = clickInfo.event.extendedProps.meeting_status
        if (new Date().getTime() > clickInfo.event.extendedProps.over_time * 1000) {
          this.meeting_status = '6'
        }
        this.cardInfo.attendee = clickInfo.event.extendedProps.attendee
        this.cardInfo.attendee_detail_arr = clickInfo.event.extendedProps.attendee_detail_arr
        let a = 0; let b = 0; let c = 0; let d = 0
        clickInfo.event.extendedProps.attendee_detail_arr.map(item => {
          this.cardInfo.to.push(item.mail)
          if (item.feedback === 1) {
            a++
          } else if (item.feedback === 2) {
            b++
          } else if (item.feedback === 3) {
            c++
          } else {
            d++
          }
        })
        this.feedbacks_status = [a, b, c, d]
      } else if (clickInfo.event.extendedProps.type === 5) {
        if (clickInfo.event.extendedProps.privilege === '0') {
          return false
        }
        this.cardTitle = this.$t('message.calendar.info1')
      } else {
        if (clickInfo.event.extendedProps.from_id && clickInfo.event.extendedProps.privilege === '0') return false
        this.cardTitle = this.$t('message.calendar.info2')
        if (new Date().getTime() > clickInfo.event.extendedProps.over_time * 1000) {
          this.meeting_status = '6'
        } else {
          this.meeting_status = clickInfo.event.extendedProps.meeting_status
        }

        this.cardInfo.attendee = clickInfo.event.extendedProps.attendee
      }
      console.log(clickInfo.event)
      console.log(this.eventTags, 'eventTags')
      this.clickInfo = clickInfo
      this.responeMettingData = { ...clickInfo.event.extendedProps, title: clickInfo.event.title }
      this.cardInfo.ky_organizer = clickInfo.event.extendedProps.ky_organizer
      this.cardInfo.title = clickInfo.event.title// 主题
      this.cardInfo.location = clickInfo.event.extendedProps.ky_location// 地点
      this.cardInfo.start = clickInfo.event.startStr// 开始时间
      this.cardInfo.end = clickInfo.event.endStr// 结束时间
      this.cardInfo.desc = clickInfo.event.extendedProps.ky_description// 描述
      this.cardInfo.type = clickInfo.event.extendedProps.type// 类型 1日程 2会议
      console.log(this.cardInfo.type)
      this.cardInfo.tip = clickInfo.event.extendedProps.ky_valarm_str
      this.cardInfo.privilege = clickInfo.event.extendedProps.privilege
      this.cardInfo.from_id = clickInfo.event.extendedProps.from_id || ''
      console.log(this.cardInfo, 'cardInfo')
      this.isPopupVisible = true
      this.eventTags = this.$myData.calendar.eventTags
      this.selectedTag = this.$myData.calendar.eventTags.find(item => item.tag_id === this.clickInfo.event.extendedProps.ky_tag_id)
      this.$refs['pop'].showPopup(clickInfo.jsEvent)
    },
    // 删除日程
    async delCalendarEvent() {
      if (this.clickInfo.event.extendedProps.type === 1 || this.clickInfo.event.extendedProps.type === 5) {
        const res = await this.deleteCaledar(this.clickInfo.event.extendedProps.cal_obj_id, this.clickInfo.event.extendedProps.from_id || '')
        if (res.status === 200) {
          // this.deleteEvent(this.clickInfo.event.extendedProps.cal_obj_id)
          this.getEventList()
          this.$message.success(this.$t('message.calendar.del1'))
          this.isPopupVisible = false
        } else {
          this.$message.success(this.$t('message.calendar.del2'))
        }
      }
    },
    //
    async outDelEvent(id, fromId) {
      const res = await this.deleteCaledar(id, fromId)
      if (res.status === 200) {
        // this.deleteEvent(id)
        this.getEventList()
        this.$message.success(this.$t('message.calendar.del1'))
      } else {
        this.$message.success(this.$t('message.calendar.del2'))
      }
    },
    onClose() {
      this.isPopupVisible = false
      this.showfeedbacks = false
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    // handleEventMouseEnter(info){
    //   // console.log(info);
    // },
    getDtata() {
      setTimeout(() => {
        this.calendarOptions.events = this.calendarApi.view?.type === 'dayGridMonth' ? this.monthEvent : this.weekEvent
      }, 200)
    },
    selectDateType(label) {
      console.log(label, this.$t('message.calendar.cal3'), this.$t('message.calendar.cal4'), this.$t('message.calendar.cal5'))
      switch (label) {
        case this.$t('message.calendar.cal3'):
          this.day()
          break
        case this.$t('message.calendar.cal4'):
          this.week()
          break
        case this.$t('message.calendar.cal5'):
          this.month()
          break
      }
    },
    prev() {
      this.calendarApi.prev()
      this.title = this.calendarApi.view?.title
      this.getTime(new Date(this.calendarApi.view.currentStart).getTime(), true)
      this.getEventList()
    },
    next() {
      this.calendarApi.next()
      this.title = this.calendarApi.view?.title
      this.getTime(new Date(this.calendarApi.view.currentStart).getTime(), true)
      this.getEventList()
    },
    today() {
      this.calendarApi.today()
      this.title = this.calendarApi.view?.title
      this.getTime(new Date(this.calendarApi.view.currentStart).getTime(), true)
      this.getEventList()
    },
    month() {
      this.calendarApi.changeView('dayGridMonth')
      // this.calendarApi.today()
      this.title = this.calendarApi.view?.title
    },
    week() {
      this.calendarApi.changeView('timeGridWeek')
      // this.calendarApi.today()
      this.title = this.calendarApi.view?.title
    },
    day() {
      this.calendarApi.changeView('timeGridDay')
      // this.calendarApi.today()
      this.title = this.calendarApi.view?.title
    },
    async gotoDate(date, range) {
      this.calendarApi.gotoDate(date)
      this.calendarApi.select(date)
      this.title = this.calendarApi.view?.title
      const res = await this.getEventsList(range)
      const res1 = await this.getSubEventsList(range)
      this.calendarOptions.events = [...res, ...res1]
    },
    async getEventCardDetail(flag) {
      // if (this.clickInfo.event.extendedProps.type === 1) {
      //   const res = await this.getEventsDetail(this.clickInfo.event.extendedProps.cal_obj_id, this.clickInfo.event.extendedProps.from_id)
      //   this.$emit('getCardInfo', res.result, flag)
      // }
      const res = await this.getEventsDetail(this.clickInfo.event.extendedProps.cal_obj_id, this.clickInfo.event.extendedProps.from_id || '')
      this.$emit('getCardInfo', res.result, flag)
    },
    // 获取事件
    async getEventList() {
      const res = await this.getEventsList(this.getRangeTime())
      const res1 = await this.getSubEventsList(this.getRangeTime())
      this.calendarOptions.events = [...res, ...res1]
    },
    changeMettingStatus(status) {
      if (status === '4') {
        this.isPopupVisible = false
        return
      }
      this.meeting_status = status
    },
    // 转发会议
    tansferMetting() {
      const params = { ...this.clickInfo.event.extendedProps,
        title: this.clickInfo.event.title,
        startStr: this.formatTime(this.clickInfo.event.startStr, this.clickInfo.event.endStr),
        endStr: this.clickInfo.event.endStr,
      }
      this.jumpCompose('tansfercalendar', params)
    },
    // 回复会议
    replayOrg(e) {
      const params = { ...this.clickInfo.event.extendedProps,
        title: this.clickInfo.event.title,
        startStr: this.formatTime(this.clickInfo.event.startStr, this.clickInfo.event.endStr),
        endStr: this.clickInfo.event.endStr,
        isAll: e === '2',
      }
      this.jumpCompose('replaycalendar', params)
    },
    jumpCompose(path, params) {
      this.$myApi.mainTab.showComposeMail({ type: path, subject: this.$t('message.mbox.newmail'), composeData: params })
    },
    // 取消会议邀请
    async cancelMetting() {
      const params = {
        cal_obj_id: this.clickInfo.event.extendedProps.cal_obj_id,
        from_id: this.clickInfo.event.extendedProps.from_id || '',
      }
      const res = await this.cancelInviteMetting(params)
      if (res.status === 200) {
        this.getEventList()
        this.isPopupVisible = false
      }
    },
    async cancelreceiveMetting() {
      this.$confirm(this.$t('message.calendar.confirm'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(async() => {
        const params = {
          cal_obj_id: this.clickInfo.event.extendedProps.cal_obj_id,
          meeting_feedback: '3',
          from_id: this.clickInfo.event.extendedProps.from_id || '',
        }
        const res = await this.directFeedbackMetting(params)
        if (res.status === 200) {
          this.getEventList()
        }
      }).catch(() => {
        this.isPopupVisible = true
      })
    }, //
    // 取消会议邀请
    async outCancelMetting(cal_obj_id, from_id) {
      const params = {
        cal_obj_id,
        from_id,
      }
      const res = await this.cancelInviteMetting(params)
      if (res.status === 200) {
        this.getEventList()
        this.$message.success(this.$t('message.calendar.confirm2'))
      }
    },
    // 日程提醒
    showCaltips() {
      this.$refs.caltips.show()
    },
    jumpDate(row) {
      const dateArray = row.start.split('/')
      const year = dateArray[0]
      const month = dateArray[1]
      const day = dateArray[2].split(' ')[0]
      const formattedDate = year + '-' + month + '-' + day
      this.calendarApi.gotoDate(formattedDate)
      this.title = this.calendarApi.view?.title
      this.getTime(new Date(formattedDate).getTime(), false)
    },
  },
}
</script>

<style lang='scss' scoped>
/deep/ .fc .fc-timegrid-col.fc-day-today {
  background-color: #F0F0F1;
}
/deep/ .fc .fc-daygrid-day.fc-day-today {
  background-color: #F0F0F1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /deep/.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #ddd;
}
}
/deep/.fc .fc-highlight{
  background-color: #eee;
  // border: 1px dashed rgba(188,232,241,.3);
}
/deep/.fc-theme-standard .fc-popover{
  background: #fff;
  border: 1px solid #ddd;
  width: 230px;
  max-height: 200px;
  overflow: auto;
}
/deep/.fc .fc-timegrid-now-indicator-line{
  border-color:red;
}
/deep/.fc-direction-ltr .fc-timegrid-now-indicator-arrow{
  border-color:red;
  border-bottom-color: transparent;
  border-top-color: transparent;
}
/deep/.fc-daygrid-event-harness{
  a{
    border-radius: 1px;
    padding: 3px 2px;
  }
}

/deep/.fc-daygrid-day-top{
  display: inherit;
  .fc-daygrid-day-number{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.demo-app{
  height: 100%;
  padding: 0 20px 0;
}
h2 {
  margin: 0;
  font-size: 16px;
}

// ul {
//   margin: 0;
//   padding: 0 0 0 1.5em;
// }

// li {
//   margin: 1.5em 0;
//   padding: 0;
// }

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  height: 100%;
  padding-top: 0;
}

.fc {
  /* the calendar root */
  margin: 0 auto;
}
.fc-toolbar {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  .btn-group{
    margin: 0 20px;
  }
}
.fc-center {
  display: flex;
  align-items: center;
  .data-title{
    margin: 0 20px;
  }
}
.search{
   margin-right: 10px;
}
.naozhong-tips{
  font-size: 19px;
}
.calendar-card{
  line-height: 27px;
}
.split-line{
  height: 1px;
  background: #DCDCDC;
  margin: 0 0 12px 0;
}
.reselect{
  display: inline-block;
  padding: 3px 10px;
  border-left: 1px solid #dcdfe6;
  margin-left: 3px;
  .title{
    padding-left: 5px;
  }
}
.footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accept-info{
  .tag{
    width: fit-content;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #FFFFFF;
    // box-shadow: 0px 0px 1px 1px rgba(0,0,0,0.06);
    // border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #D8D8D8;
  }
  .table{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.cal_icon{
  font-size: 18px;
  font-weight: bold;
}
.showfeedbacks{
  display: flex;
  justify-content: space-between;
}
.notice{
  width: 36px;
  height: 36px;text-align: center;
  line-height: 40px;
  &:hover{
    background-color: #efefef;
    border-radius: 5px;
  }
}
/deep/.fc table{
  font-size: 95%;
}
/deep/.fc .fc-timegrid-slot{
  height: 24px;
  line-height: 24px;
}
/deep/.fc-popover-body{
  .fc-more-popover-misc{
    display: none;
  }
}
.btn1{
  display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
}
</style>
