/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
const cs2cTypeof = (o) => {
    return (Object.prototype.toString.call(o) + '').split(' ')[1].replace(']', '').toLowerCase()
}
const serialize = (aaa) => {
    function je (str) {
        var a = []
        var i = 0
        var pcs = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        for (; i < str.length; i++) {
            if (pcs.indexOf(str[i]) === -1) { a[i] = '\\u' + ('0000' + str.charCodeAt(i).toString(16)).slice(-4) } else { a[i] = str[i] }
        }
        return a.join('')
    }

    function _keys (a) {
        var k; var ks = []
        for (k in a) ks.push(k)
        return ks.sort()
    }

    function _r (aaa) {
        var t = cs2cTypeof(aaa)
        var a; var r; var nu; var ks
        switch (t) {
            case 'number':
                return aaa + ''
            case 'string':
                return '"' + je(aaa) + '"'
            case 'date':
                return 'new Date(' + aaa.getTime() + ')'
            case 'object':
                var i
                r = []
                ks = _keys(aaa)
                for (i = 0; i < ks.length; i++) {
                    a = ks[i]
                    nu = _r(aaa[a])
                    if (nu == null) continue
                    r.push('"' + a + '":' + nu)
                }
                return '{' + r.join(',') + '}'
            case 'array':
                r = []
                for (a = 0; a < aaa.length; a++) {
                    nu = _r(aaa[a])
                    if (nu == null) continue
                    r.push(nu)
                }
                return '[' + r.join(',') + ']'
            case 'null':
            case 'undefined':
            case 'function':
                return null
        }
        return null
    }
    return _r(aaa)
}
export default serialize
