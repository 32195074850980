import serialize from '@/utils/serialize'
export default {
  data() {

  },
  methods: {
    async getPublicContact(id) {
      if (id === 'out') {
        const params = {
          module: 'mail',
          require: {
            action: 'get_out_contact',
          },
        }
        return new Promise((resolve, reject) => {
          this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
            const cache = {}
            const list = result.map(item => {
              const persons = item.user_list.map(item => {
                item.checked = false
                item.type = 'out'
                item.leaf = true
                item.path = item.dept
                item.isValid = true
                this.userStreamCache.setUserCache(item)
                return item
              })
              cache[item.id] = persons
              return {
                id: item.id,
                name: item.name,
                num: item.num,
                leaf: false,
                type: 'out',
              }
            })

            this.$store.commit('contact/SETTING_OUT_CACHE', cache)
            resolve(list)
          })
        })
      }
      const params = {
        module: 'mail',
        require: {
          action: 'get_pub',
          gd_id: id,
        },
      }
      if (id === 'maillist' || id === 'org') {
        params.require.gd_id = ''
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          let groups = []
          if (result.group_list) {
            groups = result.group_list.map(group => {
              group.leaf = false
              return group
            })
          }
          let persons = []
          if (result.user_list) {
            persons = result.user_list.map(person => {
              person.leaf = true
              person.isValid = true
              this.userStreamCache.setUserCache(person)
              return person
            })
          }

          const maillists = result.list_names?.map(list => {
            list.leaf = true
            list.path = '邮件列表'
            list.isValid = true
            this.userStreamCache.setUserCache(list)
            return list
          })
          if (id === 'maillist') {
            resolve([...maillists])
          } else {
            resolve([...groups, ...persons])
          }
        })
      })
    },
    getPersonContact() {
      const params = {
        module: 'contact',
        require: {
          action: 'getALL',
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          const list = result.map(item => {
            item.leaf = false
            item.user_list.map(i => {
              i.leaf = true
              i.path = this.$t('message.contact.percontact')
              i.isValid = true
              this.userStreamCache.setUserCache(i)
            })
            return item
          })
          resolve(list)
        })
      })
    },
    getCommonContact() {
      const params = {
        module: 'contact',
        require: {
          action: 'getCommonList',
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          if (result !== '') {
            const list = result.map(item => {
              item.leaf = true
              item.isValid = true
              return item
            })
            resolve(list)
          } else {
            resolve([])
          }
        })
      })
    },
    searchContact(word, type) {
      const params = {
        module: 'mail',
        require: {
          action: 'search_all',
          key_word: word,
          scope: type,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          if (result instanceof Array) {
            result.map(item => {
              this.userStreamCache.setUserCache(item)
            })
            resolve(result)
          } else {
            resolve([])
          }
        })
      })
    },
  },
}
