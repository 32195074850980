import { transformClass } from '@/utils/utils.js'
import serialize from '@/utils/serialize'
export default {
  methods: {
    listItemClick(item) {
      const mbox = transformClass.mbox(item.uidvalidity, this.$myData.mbox_list)
      let data = {}
      data = {
        mailFolderType: this.mail_type === 'tag' ? 'folder' : this.mail_type,
        need_send_info: mbox === 'Sent' ? '1' : '0', // 是否返回邮件追踪信息 (只有发件箱有)1返回 0不返回
        orderby: this.orderby, // 邮件在当前列表的排序方式
        sequence: this.sequence, // 邮件在当前列表的排序顺序desc、asc
        type: this.type, // 查询类型，文件夹模式folder，搜索search
        scopes: this.scopes, // 高级搜索范围, subject(主题),body(正文),from(发件人),to(收件人),attachment(附件名), [{"subject":"fff"},{"body":"aaa"},{"time":"2023-03-01 , 2023-04-26"},{"from":"ff"},{"to":""},{"attachment":""}]
        words: this.words, // 搜索关键字
        search_id: this.search_id, // 搜索id，用于进行二次搜索
        mbox: mbox,
        flags: item.flags,
        mail_psw: item.mail_psw,
        topping: item.topping,
        uid: item.uid,
        uidvalidity: item.uidvalidity,
        uv: item.uidvalidity,
        readed_destory: item.readed_destory,
        is_expired: item.is_expired,
        subject: item.subject,
        is_timing: item.is_timing,
        message_id: item.message_id,
      }
      if (mbox === 'Drafts') { // 草稿箱
        if (this.$store.state.app.auth_authority === 0) {
          this.$message.error(this.$t('message.mailmodule.no_auth'))
          return false
        }
        if (item.is_timing === '1') {
          return this.$confirm(this.$t('message.mailmodule.edit_later_send'), this.$t('message.btnText.info'), {
            confirmButtonText: this.$t('message.btnText.ok'),
            cancelButtonText: this.$t('message.btnText.cancel'),
            type: 'warning',
          }).then(async() => {
            const params = { 'module': 'mail', 'require': { 'action': 'deleteMailTiming', 'message_id': item.message_id }}
            const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
            if (res.status === 200) {
              this.$myApi.mainTab.showComposeMail({ mbox: mbox, uid: item.uid, type: 'Drafts', subject: item.subject, composeData: data })
            }
          })
        } else {
          this.$myApi.mainTab.showComposeMail({ mbox: mbox, uid: item.uid, type: 'Drafts', subject: item.subject, composeData: data })
        }
      } else { // 读信
        const str = item.flags.split('')
        if (str[1] === '0') {
          // str[1] = 1
          // item.flags = str.join('')
          /** 邮箱文件夹未读数量减1 */
          // 由 信件查看者 负责
          // console.log('error:文件夹数量尚未-1')
        }
        if (this.mail_type !== 'folder') {
          data.other = this.other
          data.words = this.words
          data.search_id = this.search_id
          data.filter_query = {}
          if (this.search_id) {
            for (const key in this.filter_query) {
              if (this.filter_query[key]) data.filter_query[key] = this.filter_query[key]
            }
          }
        }
        this.readMailReqData = data
        console.log(this.readMailReqData)
        if (!this.$myState.isColumns) this.$emit('listItemClick', data)
        else this.isReadMailShow = true
      }
    },
  },
}
