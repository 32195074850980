<template>
  <div
    style="width: 100%"
    class="uniqueClass"
    @click="focus"
  >
    <draggable
      ref="dragContainer"
      v-model="tags"
      :options="{animation:500}"
      group="list"
      :disabled="stopDrag"
      handle=".dragItem"
      animation="200"
      @change="dragEnd"
    >
      <span
        v-for="(item,index) in tags"
        :key="index"
        :class="[item.isValid?'dragItem':'dragItem dragItemError']"
        :title="item.isValid?'':$t('message.compose.err14')"
        @dblclick="editMailTags(index,$event)"
      >
        <span class="tags-content">
          <el-autocomplete
            v-if="item.isEdit"
            ref="taginput"
            v-model="item.mail"
            v-click-outside="cancelEdit"
            size="mini"
            popper-class="my-autocomplete"
            :fetch-suggestions="editquerySearch"
            class="mailInput"
            :trigger-on-focus="false"
            @select="handleEditSelect"
            @keyup.native.enter="cancelEditTags"
            @keyup.native.186="cancelEditTags"
            @input="removeEditSemicolons"
          >
            <template slot-scope="{ item }">
              <div
                v-if="item.id!=='more'"
                class="name"
                style="color:#333;font-weight:bold"
              >{{ item.name }}({{ item.mail }})</div>
              <div
                v-else
                class="name"
                style="color:#333;font-weight:bold"
              >{{ item.name }}</div>
              <span class="addr">{{ item.path }}</span>
            </template>
          </el-autocomplete>
          <span
            v-else
            class="text"
          >{{ handleShowName(item) }}</span>
          <span class="close">
            <i
              class="el-icon-close"
              @click="deleteTags(index)"
            />
          </span>
        </span>
      </span>
      <div
        style="display:inline-block"
      >
        <el-autocomplete
          slot="reference"
          ref="tagnewinput"
          v-model="newMail"
          size="mini"
          highlight-first-item
          :debounce="0"
          :hide-loading="true"
          popper-class="my-autocomplete"
          :popper-append-to-body="false"
          :fetch-suggestions="querySearch"
          class="mailInput"
          :trigger-on-focus="false"
          @select="handleSelect"
          @keyup.native.enter="addMailTags"
          @keydown.native.delete="delMailTags"
          @keyup.native.186="addMailTags"
          @keyup.native.59="addMailTags"
          @keyup.native.188="addMailTags"
          @keyup.native.32="nullKeyConfirm"
          @input="removeSemicolons"
          @paste.native="handlePaste"
          @blur="bluraddMailTags"
        >
          <template slot-scope="{ item }">
            <div
              v-if="item.id!=='more'"
              class="name"
              style="color:#333;font-weight:bold"
            >{{ item.name }}({{ item.mail }})</div>
            <div
              v-else
              class="name"
              style="color:#333;font-weight:bold"
            >{{ item.name }}</div>
            <span class="addr">{{ item.path }}</span>
          </template>
        </el-autocomplete>
      </div>

    </draggable>

  </div>

</template>

<script>
import draggable from 'vuedraggable'
import { countCharacters } from '@/utils/utils'
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
import serialize from '@/utils/serialize'
const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
  name: 'VueTagsInput',
  components: { draggable },
  mixins: [requestContact],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      visible: false,
      newMail: '',
      editIndex: '',
      stopDrag: false,
      displayType: this.$store.state.app.userGetConfig.show_tag_type, // JSON.parse(localStorage.getItem('userConfig')).show_tag_type === 'long',
      list: [],
      selectedIndex: -1,
      lists: [],
    }
  },
  computed: {
    tags: {
      get() {
        return this.value.slice()
      },
      set(newVal) {
        this.$emit('input', newVal)
        return newVal
      },
    },
  },
  watch: {

  },
  mounted() {
  },
  methods: {
    getAllTagsItem() {
      const container = this.$refs.dragContainer.$el
      const children = Array.from(container.children)
      return children
    },
    bluraddMailTags() {
      if (!this.visible) this.addMailTags()
    },
    addMailTags(e) {
      if (this.selectedIndex >= 0 && this.selectedIndex < this.list.length) {
        this.newMail = this.list[this.selectedIndex].mail
        this.visible = false
        this.selectedIndex = -1
      }
      const val = this.newMail.trim()
      if (val === '') return false
      // if (!mailReg.test(val)) return
      const isHas = this.tags.find(item => item.mail === this.newMail)
      if (!isHas) {
        this.tags.push({
          mail: val,
          name: this.newMail.split('@')[0],
          path: '',
          isValid: mailReg.test(val),
        })
      }

      this.visible = false
      this.list = []
      this.$emit('input', this.tags)
      this.newMail = ''
      this.$refs.tagnewinput.handleInput('')
    },
    editMailTags(index, e) {
      this.stopDrag = true
      const lists = this.getAllTagsItem()
      lists[index].classList.remove('selectedDragItem')
      this.editIndex = index
      this.$set(this.tags[index], 'isEdit', true)
    },
    cancelEditTags() {
      this.stopDrag = false
      if (mailReg.test(this.tags[this.editIndex].mail)) {
        this.tags[this.editIndex].isValid = true
        this.tags[this.editIndex].name = this.tags[this.editIndex].mail.split('@')[0]
      } else {
        this.tags[this.editIndex].isValid = false
      }
      this.tags[this.editIndex].isEdit = false
      if (this.tags[this.editIndex].mail === '') {
        this.tags.splice(this.editIndex, 1)
      }
      this.$emit('input', this.tags)
      this.$refs.tagnewinput.$refs.input.focus()
    },
    // 编辑延时触发
    cancelEdit() {
      const _vm = this
      setTimeout(() => {
        _vm.cancelEditTags()
      }, 10)
    },
    deleteTags(index) {
      this.tags.splice(index, 1)
      this.$emit('input', this.tags)
      this.$forceUpdate()
    },
    // delete删
    delMailTags(e) {
      if (e.target.value.length > 0) return
      this.tags.pop()
      this.$emit('input', this.tags)
      this.$forceUpdate()
    },
    focus(e) {
      const className = e.target.parentNode.className
      if (className === 'uniqueClass') {
        this.$refs.tagnewinput.focus()
      }
    },
    dragEnd() {
      this.$forceUpdate()
    },
    removeSemicolons() {
      if (this.newMail) {
        this.newMail = this.newMail.replace(/[;；,，]/g, '')
      }
    },
    async querySearch(queryString, cb) {
      if (countCharacters(queryString) < 3 || queryString.trim() === '') return cb([])
      const data = await this.searchContact(queryString)
      if (data.status === 200) {
        this.lists = data.result
        this.lists.map(item => {
          item.isValid = true
        })
        if (data.result.length > 15) {
          this.lists = data.result.slice(0, 15)
          this.lists.map(item => {
            item.isValid = true
          })
          this.lists.push({ id: 'more', name: '更多', mail: this.newMail, path: '' })
        }
        if (this.lists.length) {
          this.visible = true
        } else {
          this.visible = false
        }
        cb(this.lists)
      }
    },
    async editquerySearch(queryString, cb) {
      if (countCharacters(queryString) < 3) return cb([])
      const data = await this.searchContact(queryString)
      if (data.status === 200) {
        this.lists = data.result
        if (this.lists.length) {
          this.visible = true
        } else {
          this.visible = false
        }
        cb(this.lists)
      }
    },
    handleSelect(item) {
      if (item.id === 'more') {
        if (this.$parent.$refs.ContactsPopper) {
          this.$parent.$refs.ContactsPopper.showContactPopper(this.position, item.mail)
        }
        this.$emit('on-more', this.position, item.mail)
        this.newMail = ''
        this.visible = false
        return false
      }
      this.newMail = item.mail
      this.addMailTags()
    },
    handleEditSelect(item) {
      if (item.id === 'more') {
        this.$parent.$refs.ContactsPopper.showContactPopper(this.position, this.tags[this.editIndex].mail)
        return false
      }
      this.tags[this.editIndex].mail = item.mail
      this.cancelEditTags()
    },
    async search() {
      if (this.newMail.length === 0) {
        this.list = []
      }
      if (countCharacters(this.newMail) < 3) return
      const data = await this.searchContact(this.newMail)
      if (data.status === 200) {
        this.list = data.result
        if (data.result.length > 15) {
          this.list = data.result.slice(0, 15)
          this.list.push({ id: 'more', name: this.$t('message.compose.more'), mail: '', path: '' })
        }
      }
    },
    async editSearch() {
      const data = await this.searchContact(this.tags[this.editIndex].mail)
      console.log(data)
    },
    removeEditSemicolons() {
      if (this.tags[this.editIndex].mail) {
        this.tags[this.editIndex].mail = this.tags[this.editIndex].mail.replace(/[;；]/g, '')
      }
    },
    nullKeyConfirm() {
      if (!mailReg.test(this.newMail.trim())) return false
      this.addMailTags()
    },
    async handlePaste(e) {
      e.preventDefault()
      let pastedText = null
      if (e.clipboardData) {
        pastedText = e.clipboardData.getData('text/plain')
        // 在这里编写处理粘贴文本的代码
      } else if (window.clipboardData) {
        pastedText = window.clipboardData.getData('text')
        // 在这里编写处理粘贴文本的代码
      }
      const splitstr = this.newMail + pastedText
      const emailAddresses = splitstr.split(/[ ;；,，/\n/\r\n]+/)
      const validEmailAddresses = emailAddresses.filter((emailAddress) => {
        return mailReg.test(emailAddress)
      })
      if (validEmailAddresses.length === 0) return
      const params = {
        module: 'mail',
        require: {
          action: 'accurate_search',
          mail_list: Array.from(new Set(validEmailAddresses)).toString(),
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        res.result.map(item => {
          if (item.from_type !== 8) {
            this.userStreamCache.setUserCache({
              name: item.real_name,
              path: item.path,
              mail: item.mail,
              isValid: true,
            })
          }
        })
      }
      // const tags = []
      validEmailAddresses.map((emailAddress) => {
        console.log(this.tags.length)
        if (this.tags.length === 0) {
          this.tags.push({ mail: emailAddress, name: emailAddress.split('@')[0], path: '', isValid: true })
        } else {
          const isHas = this.tags.find(item => item.mail === emailAddress)
          if (!isHas) {
            this.tags.push({ mail: emailAddress, name: emailAddress.split('@')[0], path: '', isValid: true })
          }
        }
      })
      this.$forceUpdate()
      this.newMail = ''
      this.$emit('input', this.tags)
    },
    // 处理显示名称
    handleShowName(item) {
      let showname
      if (this.displayType === 'long') {
        if (this.isHaveInUsercache(item.mail)) {
          const user = this.userStreamCache.users[item.mail]
          if (user.path && user.path !== '') {
            showname = this.longName(user)
          } else {
            showname = this.shortName(user)
          }
        } else {
          showname = this.shortName(item)
        }
      } else {
        if (this.isHaveInUsercache(item.mail)) {
          const user = this.userStreamCache.users[item.mail]
          showname = this.shortName(user)
        } else {
          if (!item.isValid) {
            return `${item.mail}`
          } else {
            showname = `${item.name} <${item.mail}>`
          }
        }
      }
      return showname
    },
    shortName(user) {
      if (!user.isValid) {
        return `${user.mail}`
      }
      return `${user.name} <${user.mail}>`
    },
    longName(user) {
      return `${user.name} 【${user.path}】`
    },
    isHaveInUsercache(mail) {
      return mail in this.userStreamCache.users
    },
    hideSearchList() {
      this.visible = false
    },
    SearchListadd(item) {
      if (item.id === 'more') {
        this.$parent.$refs.ContactsPopper.showContactPopper(this.position, this.newMail)
        this.newMail = ''
        this.visible = false
        return false
      }
      this.newMail = item.mail
      this.list = []
      this.addMailTags()
      this.visible = false
      this.$emit('input', this.tags)
    },
    checkInputMail() {
      this.addMailTags()
    },
  },
}
</script>
<style lang="scss">
.popperCustomAddress{
  width: auto!important;
  max-width: 70%!important;
  padding: 0!important;
}
.optionsList{
  max-height: 600px!important;
  overflow-y: auto;
}
.my-autocomplete {
  .el-autocomplete-suggestion__wrap{
    padding: 2px;
    max-height: 70vh;
    .highlighted{
    background-color: #f0f0f1;
  }
  }
  li {
    line-height: normal;
    padding: 10px 15px;
    .highlighted{
      background-color: #f0f0f1!important;
    }
    .name {
      margin-bottom: 3px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      // font-size: 12px;
      display: block;
      color: #b4b4b4;
      margin-top: -15px;
    }

    .highlighted .addr {
      color: #ddd;
    }
    &:not(:last-child){
      border-bottom: 1px solid  #f6efef;
    }
    &:hover{
      background-color: #f0f0f1;
    }

  }

}
</style>
<style lang="scss" scoped>
.el-select-dropdown__item:not(:last-child){
  border-bottom: 1px solid #e7e7e7;
}
input{
  border: none;
  outline: none;
  background-color: #FDF5F6;
}
/deep/.mailInput{
  .el-input__inner{
    border: none!important;
    height: 20px;
    // font-size: 14px;
    background-color: transparent;
  }
}
.dragItem{
    background-color: #f8f8f8;
    border: 1px solid #cdcdcd;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    // font-size: 14px;
    min-height: 26px;
    line-height: 24px;
    margin: 5px;
    padding-left:8px;
    word-break:break-all;
    max-width: 80vw;
    .tags-content{
      display: flex;
      align-items: center;
      .close{
        padding: 0 5px;
        border-left: 1px solid #DABBBE;
      }
      .text{
        padding-right: 5px;
      }
    }
}
.dragItemError{
  color: #ff0000;
}
.selectedDragItem{
  background-color: #d85959;
  color:#fff;
  .close{
     border-left: 1px solid #fff;
  }
}
.selected {
  background-color: #ffefed;
}
.el-select-dropdown__item{
  line-height: initial;
  padding: 5px 20px;
  height: auto;
}
</style>
