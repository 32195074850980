/**
     * @description: 获取文件的后缀名
     */
class GetRecallType {
  constructor(name) {
    this.name = name
    this.RecallType = {
      'ok': 'ok',
      'sendstate': 'sendstate',
      'deepsearch': 'deepsearch',
      'succeed': 'succeed',
      'nosucceed': 'nosucceed',
      'checkdetails': 'checkdetails',
      'succeedorsend': 'succeedorsend',
      'sending': 'sending',
      'insend': 'insend',
      'abnormal': 'abnormal',
      'hidden': 'hidden',
      'show': 'show',
      'addressee': 'addressee',
      'canyourecall': 'canyourecall',
      'time': 'time',
      'madereading': 'madereading',
      'recall': 'recall',
      'nosysadmemail': 'nosysadmemail',
      'thenrecallto': 'thenrecallto',
      'thenrecall': 'thenrecall',
      'receivenoread': 'receivenoread',
      'recallfall': 'recallfall',
      'recallsucceed': 'recallsucceed',
      'recallfallwitting': 'recallfallwitting',
      'inaudit': 'inaudit',
      'nopass': 'nopass',
      'mailaudit_known': 'mailaudit_known',
      'mailaudit_read': 'mailaudit_read',
      'mailaudit_delete': 'mailaudit_delete',
      'mailaudit_reply': 'mailaudit_reply',
      'mailaudit_mark': 'mailaudit_mark',
      'mailaudit_mark_draft': 'mailaudit_mark_draft',
      'mailaudit_mark_unread': 'mailaudit_mark_unread',
      'mailaudit_mark_delete': 'mailaudit_mark_delete',
      'mailaudit_mark_reply': 'mailaudit_mark_reply',
      'mailaudit_cancel_mark': 'mailaudit_cancel_mark',
      'mailaudit_cancel_mark_draft': 'mailaudit_cancel_mark_draft',
      'mailaudit_pop_receive_del': 'mailaudit_pop_receive_del',
      'mailaudit_pop_receive': 'mailaudit_pop_receive',
      'mailaudit_recall_succeed': 'mailaudit_recall_succeed',
      'mailaudit_rule_nopass': 'mailaudit_rule_nopass',
      'mailaudit_rule_bounce': 'mailaudit_rule_bounce',
      'Auditing': 'Auditing',
      'AuditRefused': 'AuditRefused',
      'HasAudited': 'HasAudited',
      'AuditCallbacked': 'AuditCallbacked',
      'deferred': 'deferred',
      'bounced': 'bounced',
      'CallBack': 'CallBack',
      'reject': 'reject',
      'listallrecvrej': 'listallrecvrej',
      'listallsecrej': 'listallsecrej',
      'domainrej': 'domainrej',
      'listrej': 'listrej',
      'specrej': 'specrej',
      'listnull': 'listnull',
      'undefpol': 'undefpol',
      'RaisingRefused': 'RaisingRefused',
      'SecretRefused': 'SecretRefused',
      'list_persecrej': 'list_persecrej',
      'list_perrecvrej': 'list_perrecvrej',
      'sentrecv': 'sentrecv',
      'secret': 'secret',
      'sentfailed': 'sentfailed',
      'mailaudit_retr': 'mailaudit_retr',
      'mailaudit_pop_seen': 'mailaudit_pop_seen',
      'FilterRefused': 'FilterRefused',
      'mailaudit_move': 'mailaudit_move',
      'unknowuser': 'unknowuser',
      'set_topping': 'set_topping',
      'unset_topping': 'unset_topping',
      'add_tag': 'add_tag',
      'del_tag': 'del_tag',
    }
  }
  toValue() {
    return this.RecallType[this.name]
  }
}
export default GetRecallType
