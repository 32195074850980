
import { GetFileType } from '@/utils/utils.js'

import serialize from '@/utils/serialize'
import SplitUpload from '@/components/SplitUpload/index.vue'
import mailContactCardPopover from '@/components/mailmodule/mail-contactCard-popover.vue'
import lableList from '../../components/lableList.vue'
import NewContact from '@/views/contacts/components/NewContact'
import downAttaForm from '../../components/downAtta.vue'
import remarkDialog from '../../components/remark.vue'

export default {
  components: {
    SplitUpload,
    mailContactCardPopover,
    lableList,
    NewContact,
    downAttaForm,
    remarkDialog,
  },
  data() {
    return {
      showMailbzDialog: false, // 是否显示邮件备注
      bzInfo: this.$t('message.mailmodule.bzinfo'),
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      contact_id: '',
      addtitle: 'newcontact',
      idEdit: false,
    }
  },
  methods: {
    // 邮件备注弹窗
    remarkDia(item) {
      this.showMailbzDialog = true
      this.bzInfo = item
    },
    // 检测邮件备注是否做了修改同步读信页面
    changeRemark(e) {
      const remarkData = e[1]
      remarkData.comment = e[0]
      const objList = [{ uidvalidity: remarkData.uidvalidity, uid: remarkData.uid, data: { comment: e[0] }}]
      this.$myApi.updateMailStatus(objList)
    },
    onHandelAddcontact(type, data) {
      if (type === 'add') {
        this.addtitle = 'newcontact'
        this.ruleForm = {
          phones: [{
            key: Date.now(),
            value: '',
          }],
          group: '',
          name: data.name,
          mail: data.mail,
          company: '',
          remarks: '',
        }
        this.dialogVisible = true
      } else {
        this.editUser(data)
      }
    },
    async editUser(data) {
      this.addtitle = 'editcontact'
      this.contact_id = data.contactId
      const res = await this.getContactInfo(data.contactId)
      if (res.status === 200) {
        const phone = res.result.contact_attrs.cellphone.split(',')
        const arrs = {
          phones: phone.map((item, i) => {
            return { key: i, value: item }
          }),
          group: [...res.result.group_id_arr],
          name: res.result.contact_name,
          mail: res.result.contact_mail,
          company: res.result.contact_attrs.company,
          remarks: res.result.contact_attrs.notes,
        }
        this.idEdit = true
        this.ruleForm = arrs
        this.dialogVisible = true
      }
    },
    // 更新联系人信息
    getContactInfo(id) {
      const params = {
        module: 'contact',
        require: {
          action: 'getInfo',
          contact_id: id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          if (result.status === 200) {
            resolve(result)
          }
        })
      })
    },
    addUserClosed() {
      this.dialogVisible = false
    },
    addUserSubmit() {
      this.dialogVisible = false
    },
    // 上传开始时处理
    handleStart(filelist) {
    },
    // 选择文件时触发
    fileChange(files) {
    },
    // 文件上传成功回调
    async fileUploadSuccess(res, file, filelist) {
      if (file.status === 'success') {
        this.uploadFile(file)
      }
    },
    // 上传文件后请求接口
    uploadFile(row) {
      const req = {
        'module': 'mail',
        require: {
          'action': 'ImportUpload',
          fid: row.uid,
          filename: row.name,
          mbox: this.menuItem.mbox,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        const data = res.result.res
        const str = this.$t('message.mailmodule.check_import', { num1: data.all, num2: data.count })
        this.$confirm(str, this.$t('message.btnText.info'), {
          showCancelButton: false,
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        }).then(() => {
        })
        // // 上传成功
        // this.$message({
        //   type: 'success',
        //   message: '上传成功',
        // })
      })
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 上传前检测文件类型
    fileTypeFun(filelist) {
      const list = Object.values(filelist)
      let type = true
      list.map(item => {
        if (this.getFileType(item.name) !== 'zip') {
          type = false
          this.$message.error(this.$t('message.mailmodule.support_type'))
        }
      })
      return type
    },
    // 标签
    // 子组件标签list变化
    tab_changeList(e) {
      console.log('单个')
      const tagLableList = e[1]
      // tagLableList.tag = e[0]
      const objList = [{ uidvalidity: tagLableList.uidvalidity, uid: tagLableList.uid, data: { tags: e[0], isCover: true }}]
      this.$myApi.updateMailStatus(objList)
      if (this.$refs.tabListBox && this.$refs.tabListBox.length > 0) {
        this.$refs.tabListBox.map(item => {
          item.hide()
        })
      }
      this.lableListShow = false
    },
    /** 批量操作添加标签
         * 1.判断操作列表里面是否有打开的读信，有的话同步读信标签
         * 2.判断列表是选中全部邮件或者当前页时，重新加载列表数据
         * 3.非全部邮件或者当前页时，手动追加列表选中邮件标签
         */
    tabListAction(e) {
      const tagLableList = e[0]
      const tag_id = []
      tagLableList.forEach(item => {
        tag_id.push(item.tag_id)
      })
      let params = {}
      let objList = []
      if (this.allSelect === 'all') {
        params = {
          'module': 'mbox',
          'require': {
            'action': 'updateAllMail',
            'type': 'tag',
            'tags': tag_id.toString(),
            'uidvalidity': this.menuItem.uidvalidity, // 邮箱编号(搜索时指定编号，则只返回此邮箱的数据，不指定则返回全部邮箱数据)
            'uv': this.menuItem.uidvalidity, // 邮箱编号，缩写
          },
        }
        objList = [{ uidvalidity: this.menuItem.uidvalidity, uid: '-1', data: { tags: e[0], isCover: false }}]
      } else {
        const uu_list = []
        const mail_list = this.mail_list
        mail_list.forEach(item => {
          if (item.checked) {
            uu_list.push([item.uidvalidity, item.uid])
            objList.push({ uidvalidity: item.uidvalidity, uid: item.uid, data: { tags: e[0], isCover: false }})
          }
        })
        params = {
          'module': 'mail',
          'require': {
            action: 'addMailTagGeneral',
            tag_id: tag_id,
            uu_list: uu_list,
          },
        }
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then((res) => {
          // 分栏从第一页开始重新请求
          // this.page = 1
          // this.refreshRequest()
          this.$myApi.updateMailStatus(objList)
        })
      if (this.$refs.tabListBox && this.$refs.tabListBox.length > 0) {
        this.$refs.tabListBox.map(item => {
          item.hide()
        })
      }
      const array = document.getElementsByClassName('popper__arrow')
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        element.style.display = 'none'
      }
      this.lableListShow = false
    },
  },
}
