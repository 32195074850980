<template>
  <!-- 通栏页面 -->
  <div
    class="banner-mail-container disable-select"
  >
    <!-- 操作 -->
    <div class="banner-mail-header">
      <div class="banner-mail-item-operate flax-align-items-center">
        <div class="banner-mail-item-checkbox">
          <el-checkbox
            v-model="allCheak"
            @change="allCheakChange"
          />
          <el-select
            v-model="allSelect"
            :popper-append-to-body="false"
            class="checked-select"
            @click.native="allSelectClear"
          >
            <el-option
              v-for="item in allchecked_options"
              :key="item.value"
              :label="$t(`message.colmail.${item.label}`)"
              :value="item.value"
              @click.native="allSelectChange"
            />
          </el-select>
        </div>
        <!-- 删除 -->
        <div
          v-if="$store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
          class="Opea-svg-select-noBorder"
        >
          <svg-icon
            class="icos-size-14"
            icon-class="view_del"
          />
          <span class="Opea-svg-select-noBorder-span">{{ $t('message.maillist.delete') }}</span>
          <el-select
            v-model="delOpt"
            popper-class="Opea-svg-select-popper"
            :popper-append-to-body="false"
            style="width:135%;position: absolute;left:0;top:-em;"
            @click.native="delOpt = ''"
          >
            <el-option
              v-for="item in delete_options"
              :key="item.value"
              :label="$t(`message.maillist.${item.label}`)"
              :value="item.value"
              @click.native="deleteClick"
            />
          </el-select>
        </div>
        <!-- 移动 -->
        <div
          v-if="isMove && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
          class="Opea-svg-select-noBorder"
        >
          <svg-icon
            class="icos-size-14"
            icon-class="view_move"
          />
          <span class="Opea-svg-select-noBorder-span">{{ $t('message.maillist.move') }}</span>
          <el-select
            v-model="to_move_mailbox"
            popper-class="Opea-svg-select-popper-move"
            :popper-append-to-body="false"
            style="width:135%;position: absolute;left:0;top:-em;"
            class="product-style"
            @click.native="to_move_mailbox = ''"
          >
            <el-option
              v-for="item in move_mailbox"
              :key="item.mbox"
              :label="!item.mbox_parent_name ? item.mbox_name : item.mbox_real_name"
              :value="item.mbox"
              :title="!item.mbox_parent_name ? item.mbox_name : item.mbox_real_name"
              @click.native="to_move_mailboxChange"
            >
              <span>{{ !item.mbox_parent_name ? item.mbox_name : item.mbox_real_name }}</span>
            </el-option>
          </el-select>
        </div>
        <!-- 标记 -->
        <div
          v-if="isBadge && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
          class="Opea-svg-select-noBorder"
        >
          <svg-icon
            class="icos-size-14"
            icon-class="view_makk"
          />
          <span class="Opea-svg-select-noBorder-span">{{ $t('message.maillist.mark') }}</span>
          <el-select
            v-model="flags"
            popper-class="Opea-svg-select-popper-mark"
            :popper-append-to-body="false"
            style="width:135%;position: absolute;left:0;top:-em;"
            @click.native="flags = ''"
          >
            <el-option
              v-for="item in marker_options"
              :key="item.value"
              :label="$t(`message.maillist.${item.label}`)"
              :value="item.value"
              @click.native="markerClick"
            />
            <el-option
              :label="$t('message.maillist.untag')"
              value="not_tag"
              @click.native="notTagAllClick"
            />
          </el-select>
        </div>
        <!-- 转发 -->
        <div
          v-if="isMailRelay && allSelect !== 'all' && $store.state.app.auth_authority !== 0"
          class="Opea-svg-select-noBorder"
        >
          <svg-icon
            class="icos-size-14"
            icon-class="view_forward"
          />
          <span class="Opea-svg-select-noBorder-span">{{ $t('message.maillist.forword') }}</span>
          <el-select
            v-model="value"
            popper-class="Opea-svg-select-popper"
            :popper-append-to-body="false"
            style="width:135%;position: absolute;left:0;top:-em;"
            @click.native="value = ''"
          >
            <el-option
              :disabled="mail_check_num > 1"
              :label="$t('message.maillist.forword')"
              value="asattforward"
              @click.native="forwardAction"
            />
            <el-option
              :label="$t('message.maillist.forwordasatt')"
              value="asattsforward"
              @click.native="forwardAsAttachment"
            />
          </el-select>
        </div>
        <!-- 新邮件无筛选功能 -->
        <div
          v-if="mail_type != 'newMail'"
          class="Opea-svg-select-noBorder"
        >
          <svg-icon
            class="icos-size-14"
            icon-class="mall_sieving"
          />
          <span class="Opea-svg-select-noBorder-span">{{ $t('message.maillist.filter') }}</span>
          <el-cascader
            v-model="cherry_pick"
            :options="cherry_pick_options"
            :props="{ expandTrigger: 'hover' }"
            popper-class="pc-sel-area-cascader"
            style="width:135%;position: absolute;left:0;top:-em;"
            @change="cherryPickChange"
          >
            <template slot-scope="{ node }">
              <span>{{ $t(`message.maillist.${node.label}`) }}</span>
            </template>
          </el-cascader>
        </div>
        <!-- 更多 -->
        <div
          v-if="isMore && allSelect !== 'all' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
          class="Opea-svg-select-noBorder"
        >
          <svg-icon
            class="icos-size-14"
            icon-class="view_more"
          />
          <span class="Opea-svg-select-noBorder-span">{{ $t('message.maillist.more') }}</span>
          <el-select
            v-model="value"
            popper-class="Opea-svg-select-popper"
            :popper-append-to-body="false"
            style="width:135%;position: absolute;left:0;top:-em;"
            @click.native="value = ''"
          >
            <el-option
              :label="$t('message.maillist.download')"
              value="下载邮件"
              @click.native="downMail"
            />
            <el-option
              v-if="isUploadMail"
              value="上传邮件"
            >
              <SplitUpload
                ref="uploadfils"
                :on-start="handleStart"
                :on-change="fileChange"
                :on-success="fileUploadSuccess"
                :before-upload="fileTypeFun"
                auto-upload
              >
                <span
                  slot="trigger"
                  class=" cur"
                >
                  {{ $t('message.maillist.upload') }}
                </span>
              </SplitUpload>
            </el-option>
          </el-select>
        </div>
        <!-- <div style="color: #ffffff;"><div ref="moveDiv">已选中{{ $store.state.tagsView.mail_check_num?$store.state.tagsView.mail_check_num:'1' }}封邮件</div></div> -->
      </div>
      <div style="position: relative;left:300px;top:-10px;">
        <el-dropdown
          ref="tabListTag"
          trigger="click"
          class="tabBox"
        >
          <span class="el-dropdown-link" />
          <el-dropdown-menu slot="dropdown">
            <!-- 标签列表 -->
            <lableList
              v-show="lableListShow"
              ref="lableList"
              :tab-list="[]"
              :mail-info="{}"
              @showTabList="tabListAction(arguments)"
            />
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 搜索结果,二次搜索 -->
    <div
      v-if="isTwoSearch"
      class="banner-search-column-tab"
    >
      <div>
        <span style="font-size: 114%;">{{ $t('message.colmail.search_res') }}</span>
        <span v-if="mail_type === 'search'">
          （{{ $t('message.colmail.find_ok') }}“
          <span class="text-color">{{ words }}</span>
          ”{{ $t('message.colmail.total_num_mail',{num:mail_total}) }}）
        </span>
        <span v-else-if="mail_type === 'highsearch'">
          （{{ $t('message.colmail.hight_search',{total:mail_total}) }}）
        </span>
      </div>
      <div>
        <el-select
          v-model="filter_query.mbox"
          clearable
          :placeholder="$t('message.colmail.an_mail')"
          class="search-column-tab-selset"
          style="width: 100px;"
          @clear="searchSelectClear('mbox')"
        >
          <el-option
            v-for="(val, key) in mboxSelect"
            :key="key"
            :label="key | mboxNameFilter"
            :value="key"
            @click.native="searchSelect"
          >
            <span>{{ key | mboxNameFilter }}（{{ val }}）</span>
          </el-option>
        </el-select>
        <el-select
          v-model="filter_query.from"
          clearable
          :placeholder="$t('message.colmail.an_from')"
          class="search-column-tab-selset"
          style="width: 120px;"
          @clear="searchSelectClear('from')"
        >
          <el-option
            v-for="(val, key) in filter_result.from"
            :key="key"
            :label="key"
            :value="key"
            @click.native="searchSelect"
          >
            <span>{{ key }}（{{ val }}）</span>
          </el-option>
        </el-select>
        <el-select
          v-model="filter_query.to"
          clearable
          :placeholder="$t('message.colmail.an_to')"
          class="search-column-tab-selset"
          style="width: 120px;"
          @clear="searchSelectClear('to')"
        >
          <el-option
            v-for="(val, key) in filter_result.to"
            :key="key"
            :label="key"
            :value="key"
            @click.native="searchSelect"
          >
            <span>{{ key }}（{{ val }}）</span>
          </el-option>
        </el-select>
        <el-select
          v-model="filter_query.date"
          clearable
          :placeholder="$t('message.colmail.an_time')"
          class="search-column-tab-selset"
          style="width: 100px;"
          @clear="searchSelectClear('date')"
        >
          <el-option
            v-for="(val, key) in filter_result.date"
            :key="key"
            :label="dateObj[key]"
            :value="key"
            @click.native="searchSelect"
          >
            <span>{{ dateObj[key] }}（{{ val }}）</span>
          </el-option>
        </el-select>
        <el-select
          v-model="filter_query.attachment"
          clearable
          :placeholder="$t('message.colmail.an_has_att')"
          class="search-column-tab-selset"
          style="width: 140px;"
          @clear="searchSelectClear('attachment')"
        >
          <el-option
            :label="$t('message.colmail.has_att')+'（' + filter_result.attachment + '）'"
            value="1"
            @click.native="searchSelect"
          />
          <el-option
            :label="$t('message.colmail.no_att')+'（' + filter_result.not_attachment + '）'"
            value="0"
            @click.native="searchSelect"
          />
        </el-select>
      </div>
    </div>
    <!-- 列表 -->
    <div
      class="banner-mail-list"
      style="position: relative;"
    >
      <div
        v-if="is_reqMail&&mail_list.length < 1"
        class="not-maillist-container"
      >
        {{ $t('message.colmail.foder_no_mail') }}
      </div>
      <div
        v-else
        id="banner_mail"
        ref="banner_mail"
        :class="isTwoSearch?'banner-mail-overflow-search':'banner-mail-overflow'"
        style="position: relative;"
        @scroll="scrollEvent($event)"
      >
        <!-- <div
          v-for="(item, index) in $store.state.tagsView.mail_list"
          :id="'banner_mail_item' + (index + 1)"
          :key="index"
          class="banner-mail-tab theme-hover-background"
          :class="Number(item.topping)>0?'topping-background':''|| item.checked?'theme-click-background':''"
          style="font-weight: normal;"
          @click="mailLinkFun(item,index)"
          @mousedown="mousedownFn(item)"
        > -->
        <div
          v-for="(item, index) in mail_list"
          :id="'banner_mail_item' + (index + 1)"
          :key="index"
          class="banner-mail-tab theme-hover-background"
          :class="Number(item.topping)>0?'topping-background':''|| item.checked?'theme-click-background':''"
          style="font-weight: normal;padding-left: 13px;"
          @click="listItemClick(item)"
          @mousedown="mousedownFn(item)"
        >
          <div class="banner-mail-tab-container">
            <div class="banner-mail-tab-item">
              <div class="banner-mail-tab-item-left">
                <div
                  style="width: 50px;
                        height: 45px;
                        position: absolute;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;"
                  @click.stop
                >
                  <div
                    style="width:14px;"
                    @click.stop
                  >
                    <el-checkbox
                      v-model="item.checked"
                      @change="cheakChange(item)"
                    />
                  </div>
                </div>

                <div class="img-item">
                  <!-- 定时邮件 -->
                  <svg-icon
                    v-if="item.is_timing=='1'"
                    class="icos-size-14"
                    icon-class="delaysend_date"
                  />
                  <!-- 转发或回复、转发，优先级高于未读 -->
                  <svg-icon
                    v-else
                    class="icos-size-14"
                    :icon-class="item|forwardReply"
                  />
                </div>
                <div
                  class="img-item"
                  style="padding-top:4px"
                >
                  <!-- 可疑标识/陌生标识,可疑优先 -->
                  <svg-icon
                    class="icos-size-14"
                    :icon-class="item|suspiciousFilters"
                  />
                </div>
                <!-- 未读、置顶加粗text-color-bold -->
                <!-- 发件人/收件人 -->
                <div
                  style="cursor: pointer;"
                  class="item-mailing-address one-line-ellipsis"
                  :class="Number(item.topping)>0 || item.flags.charAt(1)==='0'?'text-color-bold':''"
                >
                  <!-- 发件箱、草稿箱显示收件人，其它文件夹显示发件人  -->
                  <span v-if="isToFilter(item.uidvalidity)">
                    <span
                      v-for="(t, ti) in item.to_s"
                      :key="ti"
                    >
                      <mailContactCardPopover
                        :contact-name="t[1]"
                        :contact-mail="t[0]"
                        :contact-group="t[2]"
                        :text-content="user_mail.indexOf(t[0])!=-1?$t('message.view.me'):t[1]"
                      />
                      <span v-if="item.to_s.length>1">；</span>
                    </span>
                  </span>
                  <span v-else>
                    <mailContactCardPopover
                      :contact-name="item.from_name"
                      :contact-mail="item.from_address"
                      :contact-group="item.from_name_l[2]"
                      :text-content="user_mail.indexOf(item.from_address)!=-1?$t('message.view.me'):item.from_name"
                    />
                  </span>
                </div>
                <div class="img-item">
                  <!-- 阅后即焚 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    :content="$t('message.colmail.read_destory')"
                    placement="top-start"
                  >
                    <svg-icon
                      v-if="item.readed_destory == '1'|| item.readed_destory == 1"
                      class="icos-size-14"
                      icon-class="mail_burn"
                    />
                  </el-tooltip>
                  <!-- 过期 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    placement="top-start"
                  >
                    <div slot="content">
                      <span>{{ $t('message.colmail.mail_expired',{date:dateNYRFilter(item.expire_time)}) }}</span>
                    </div>
                    <svg-icon
                      v-if="item.readed_destory.toString() != '1'&&Number(item.expire_time)>0"
                      class="icos-size-14"
                      icon-class="mall_over_time"
                    />
                  </el-tooltip>

                  <!-- 安全邮件 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    :content=" $t('message.colmail.secrity_mail')"
                    placement="top-start"
                  >
                    <svg-icon
                      v-if="item.readed_destory != '1'&& Number(item.expire_time) === 0 && item.protect_type === 1"
                      class="icos-size-14"
                      icon-class="secure"
                    />
                  </el-tooltip>

                  <!-- 密码邮件 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    :content=" $t('message.colmail.psw_mail')"
                    placement="top-start"
                  >
                    <svg-icon
                      v-if="item.readed_destory != '1'&& Number(item.expire_time) === 0 && item.protect_type == 2"
                      class="icos-size-14"
                      icon-class="encipher"
                    />
                  </el-tooltip>
                </div>
                <div class="img-item">
                  <!-- 紧急 -->
                  <svg-icon
                    v-if="item.priority === '1'"
                    class="icos-size-14"
                    icon-class="mall_urgent"
                  />
                </div>
              </div>
              <div class="item-mail-cont one-line-ellipsis">
                <span
                  class="img-item"
                  style="display: inline-block;margin-left: 3px;"
                  @click.stop="startSet(item)"
                >
                  <!-- 星标(选中) -->
                  <svg-icon
                    v-if="item.flags.charAt(3) === '1' && $store.state.app.auth_authority!==0 && $store.state.app.auth_authority!==1"
                    class="icos-size-14"
                    icon-class="start"
                  />
                  <!-- 星标(未选中) -->
                  <svg-icon
                    v-if="item.flags.charAt(3) === '0' && $store.state.app.auth_authority!==0 && $store.state.app.auth_authority!==1"
                    class="icos-size-14"
                    icon-class="unstart"
                  />
                </span>
                <!-- 主题,未读加粗text-color-bold -->
                <span
                  class="item-mail-subject one-line-ellipsis"
                  :class="Number(item.topping)>0 || item.flags.charAt(1)==='0'?'text-color-bold':''"
                  style="cursor: pointer;vertical-align: 0.3em;"
                >
                  <span v-if="isMboxShow">[{{ item.uidvalidity | mboxNameFilter }}]</span>
                  {{ item | subjectFilter }}
                </span>
                <!-- 会话数量 -->
                <!-- <div
                    v-if="item.csum"
                    class="conversation-num theme-background-color"
                  >
                    {{ item.csum }}
                  </div> -->
                <!-- 正文 -->
                <span
                  v-show="$store.state.app.userGetConfig.show_mail_diges==='yes'"
                  class="text-color-a3"
                  style="cursor: pointer;margin-bottom: 1px;"
                >
                  <span
                    v-if="item.mail_psw"
                    style="vertical-align: 0.3em;"
                  >{{ $t('message.colmail.add_psw_mail') }}</span>
                  <span v-else>
                    <span
                      v-if="item.digest.charAt(0)=='h'"
                      style="vertical-align: 0.3em;"
                      v-html="item.digest.slice(1)"
                    />
                    <span
                      v-else
                      style="vertical-align: 0.3em;"
                    >{{ item.digest.slice(1) }}</span>
                  </span>
                </span>
              </div>
              <div class="banner-mail-tab-item-right flax-align-items-center">
                <div
                  class="img-item remark "
                  :class="item.comment !== '' ? 'okRemark' : ''"
                >
                  <!-- 备注 -->

                  <el-tooltip
                    v-if="item.comment !== '' && $store.state.app.auth_authority!==0 && $store.state.app.auth_authority!==1"
                    class="item"
                    effect="light"
                    placement="right-start"
                  >
                    <div
                      slot="content"
                      style="max-width:500px"
                    >{{ item.comment }}</div>
                    <span><svg-icon
                      class="icos-size-14 svgIcon svg-color"
                      icon-class="mail_bz"
                      @click.stop="remarkDia(item)"
                    /></span>
                  </el-tooltip>
                  <svg-icon
                    v-if="item.comment === '' && $store.state.app.auth_authority!==0 && $store.state.app.auth_authority!==1"
                    class="icos-size-14 svgIcon svg-color"
                    icon-class="mail_bz"
                    @click.stop="remarkDia(item)"
                  />
                </div>
                <div class="img-item">
                  <!-- 置顶-->
                  <svg-icon
                    v-if="Number(item.topping)>0"
                    class="icos-size-14"
                    icon-class="mall_topping"
                  />
                </div>
                <div class="img-item">
                  <!-- 附件-->
                  <svg-icon
                    v-if="item.flags.charAt(7)==='1'"
                    class="icos-size-14"
                    icon-class="att"
                  />
                </div>
                <!-- 邮件大小 -->
                <div class="appendix-width color-bbb">
                  {{ item.mail_size | mailSizefilter }}
                </div>
                <!-- 邮件时间 -->
                <div class="color-bbb">{{ item.envelope_date | dateFilter }}</div>
              </div>
            </div>
            <div>
              <!-- 定时邮件提醒 -->
              <div
                v-if="item.is_timing=='1'"
                style="padding-left:54px;display: flex;"
              >
                <span
                  class="undertint-theme-background-color text-color"
                  style="padding-left: 5px;padding-right: 5px;border-radius: 2px;"
                >{{ $t('message.colmail.will_send',{date:dateNYRFilter(item.delaysend_date)}) }}</span>
              </div>
              <!-- 邮件标签 -->
              <div
                v-if="item.tag.length > 0 && $store.state.app.auth_authority!==0 && $store.state.app.auth_authority!==1"
                style="padding-left:262px;display: flex;"
              >
                <div
                  v-if="item.tag.length > 0 && $store.state.app.auth_authority!==0 && $store.state.app.auth_authority!==1"
                  class="banner-mail-label flax-align-items-center"
                >
                  <div
                    v-for="(i,index) in item.tag"
                    :key="index"
                    class="banner-mail-label-item"
                  >
                    <div
                      @click.stop
                    >
                      <el-dropdown
                        ref="tabListBox"
                        trigger="click"
                        class="tabBox"
                        @visible-change="handVisibleChange($event, index)"
                      >
                        <el-tooltip
                          class="item"
                          effect="light"
                          :content="i.tag_name"
                          placement="top-start"
                        >
                          <span
                            class="el-dropdown-link"
                            style="display: flex;"
                          >
                            <svg-icon
                              ref="tab"
                              class="tab"
                              style="width: 14px;height: 14px;"
                              :icon-class="i.tag_color.slice(1,7)"
                            />

                            <span
                              style="display: inline-block;max-width: 108px;margin-left: 2px;"
                              class="one-line-ellipsis"
                            >
                              {{ i.tag_name }}
                            </span>
                          </span>
                        </el-tooltip>
                        <el-dropdown-menu slot="dropdown">
                          <!-- 标签列表 -->
                          <lableList
                            ref="lableList"
                            :tab-list="item.tag"
                            :mail-info="item"
                            @showTabList="tab_changeList(arguments)"
                          />
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </draggable> -->
      </div>
      <!-- 回到顶部 -->
      <svg-icon
        v-show="isScrollTopShow"
        icon-class="scroll_top"
        class="scrollTop-position-class"
        @click.stop="$refs.banner_mail.scrollTop = 0"
      />
    </div>
    <!-- 翻页 -->
    <div
      class="pagination-container"
      style="padding-left: 13px;"
    >
      <el-pagination
        v-if="bPageShow"
        :current-page="page"
        :page-size="limit"
        layout="total, prev, pager, next, jumper"
        :total="mail_total"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 邮件备注弹窗 -->
    <remarkDialog
      v-if="showMailbzDialog"
      :is-show="showMailbzDialog"
      :bz-info="bzInfo"
      @showRemark="changeRemark(arguments)"
    />
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="498px"
      append-to-body
      :show-close="false"
      class="cutomDialog"
    >
      <NewContact
        v-if="dialogVisible"
        :on-closed="addUserClosed"
        :on-submit="addUserSubmit"
        :form="ruleForm"
        :cid="contact_id"
        :is-edit="idEdit"
        :title="addtitle"
        is-card
      />
    </el-dialog>
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />
    <div
      v-if="$myState.isMousedownBox"
      :style="{ top: showPositionTop + 'px', left: showPositionLeft + 'px' }"
      class="dragable"
    >{{ $t('message.colmail.selectmails',{num:checkedMail.length>0?checkedMail.length-1:1}) }}
    </div>
  </div>
</template>
<script>

import getList from './components/getList.js'
import mailListEmitOuter from './components/mailListEmitOuter.js'
import componentsAction from './components/componentsAction.js'
import actionList from './components/action-list '

export default {
  mixins: [getList, actionList, mailListEmitOuter, componentsAction],
  data() {
    return {
      checkedMail: [],
      count: 0,
      showPositionTop: -1000,
      showPositionLeft: '',
      dialogVisible: false,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    dragStartFn(e) {
      // console.log(e, 'start')
    },
    dragEndFn(e) {
      // console.log(e, 'end')
    },
  },
}
</script>
<style>
.cutomDialog .el-dialog__body{
    padding: 0px 20px;
}
</style>
<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
@import './components/style.scss';
.dragable{
  position: fixed;
  background: #fff;
  border: 1px solid #333;
  z-index: 1000;
}
.banner-mail-tab-container{
  width: 100%;
  padding: 10px 18px;
}
.banner-mail-tab-item{
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  .banner-mail-tab-item-left {
        // width: calc(100% - 276px);
    // flex:1;
    width: 236px;
    display: flex;
    align-items: center;
  }
    .banner-mail-tab-item-right {
        width: 305px;
    }
    .item-mailing-address {
      width: 182px;
      padding-left: 8px;
    }
  .item-mail-cont {
    // width: calc(100% - 302px);
    flex:1;
    // display: flex;
    // align-items: center;
  }
    .item-mail-subject{
      max-width: 90%;
      // display: inline-block;
    }
    .appendix-width {
        width: 100px;
        padding-left: 18px;
        margin-right: 15px;
    }
}
.banner-mail-label {
  // padding-left: 236px;
  .banner-mail-label-item {
    padding: 4px 4px 2px 4px;
    border: 1px solid #c4c2c2;
    background: #f9f9f9;
    border-radius: 2px;
    margin-right: 5px;
  }
}
.svg-color{
    fill:#BCBCBC!important;
}
.banner-mail-list .banner-mail-tab .okRemark .svg-color{
    fill:#6994D4!important;
}
.banner-mail-list .banner-mail-tab .remark .svgIcon{
    display: none;  // 设置父元素hover时子元素的样式 【实现要点！！！！！】
}
.banner-mail-list .banner-mail-tab:hover .remark .svgIcon,
.banner-mail-list .banner-mail-tab .okRemark .svgIcon{
    display: block!important;  // 设置父元素hover时子元素的样式 【实现要点！！！！！】
}
.el-select-dropdown__item{
  color: #1d1b1b;
}
</style>
