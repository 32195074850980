<template>
  <div
    v-if="selfActived"
    class="menu-container"
  >
    <div
      id="mail-module-menu"
      class="mail-module-menu"
    >
      <!-- 邮件模块左侧菜单 -->
      <div
        class="menu-module-aside disable-select"
      >
        <div class="shortcut-operate">
          <div
            class="write-mail-button theme-background-color-Customisation"
            :style="{ background: ($store.state.app.auth_authority === 0 ? '#9b9a9a' : ''), borderColor: ($store.state.app.auth_authority === 0 ? '#9b9a9a' : '') }"
            @click="writeMail"
          >
            {{ $t('message.mbox.newmail') }}
          </div>
          <div
            class="operate-receive"
            @click="receiveAction"
          >
            <el-tooltip
              class="item"
              effect="light"
              :content="$t('message.mbox.receiveemail')"
              placement="bottom"
            >
              <span style="text-align: center;width: 30px;height: 30px;margin-top: 7px;"> <svg-icon
                class="svg-original-color"
                style="width:50%;"
                icon-class="mail_menu_receive"
              /></span>

            </el-tooltip>
          </div>
        </div>
        <div class="menu-list-container">
          <div
            class="menu-list"
            style="position: relative;"
          >
            <div
              v-for="(item, index) in menu_regular"
              :key="index"
              class="menu-list-item theme-hover-background"
              :class="mbox_selected_id === item.uidvalidity ? 'theme-click-background' : ''"
              @click="menuLinkFun(item)"
              @mouseup="menuMouseupFn(item)"
            >
              <span class="gdwid">
                <svg-icon
                  :class="mbox_selected_id === item.uidvalidity ? 'svg-theme-color' : 'svg-original-color'"
                  class="menu-icon-14"
                  :icon-class="'mail_menu_' + item.mbox"
                />
              </span>
              <div
                class="menu-list-item-text"
                :class="mbox_selected_id === item.uidvalidity ? 'text-color' : ''"
              >
                <span class="gdwid namembox">{{ item.mbox_name }}</span>
                <span
                  v-if="Number(item.unseen) > 0"
                  class="text-color"
                >{{ item.unseen }}</span>
                <div
                  v-if="item.mbox == 'fileWebDisk'"
                  style="width:1.5em;height:1.5em;line-height:1.5em;"
                >
                  <span style="display:initial">
                    <el-popover
                      placement="right"
                      width="230"
                      trigger="hover"
                      @show="getStationInfo"
                    >
                      <div class="disk_tips">
                        <h1>{{ $t('message.list.file_transfer_opc') }}</h1>
                        <p>{{ $t('message.list.file_num') }}：{{ getStation.file_num }}</p>
                        <p>{{ $t('message.list.used') }}：{{ getStation.used_station_space | mailSizefilter }}/{{ getStation.station_space |
                          mailSizefilter }}</p>
                        <p>{{ $t('message.list.save_time') }}：{{ getStation.station_lifespan_forever == 1 ? $t('message.list.forever_time') : $t('message.list.num_day',{num:getStation.station_lifespan}) }}
                        </p>
                      </div>
                      <svg-icon
                        slot="reference"
                        icon-class="important"
                        class="middle cur"
                      />
                    </el-popover>
                  </span>
                </div>
              </div>
            </div>
            <div class="menu-list-folder">
              <el-tree
                ref="folderTree"
                :props="folder_props"
                :data="folderTreeData"
                node-key="uidvalidity"
                class="filter-tree"
                highlight-current
                :indent="10"
              >
                <div
                  slot-scope="{ node, data }"
                  class="folder-tree-node one-line-ellipsis"
                  style="width:196px;padding-right: 10px;display: flex;"
                  :class="data.uidvalidity === mbox_selected_id ? 'text-color' : ''"
                  :style="data.uidvalidity === mbox_selected_id ? { 'fontWeight': 600 } : ''"
                  @click="() => handleMboxNodeClick(node, data)"
                  @mouseup="menuMouseupFn(data, 1)"
                >
                  <span
                    v-if="data.uidvalidity === 'top'"
                    style="display: flex;align-items: center;"
                  >
                    <svg-icon
                      class="menu-icon-14"
                      icon-class="folder"
                    />
                  </span>
                  <el-tooltip
                    class="item"
                    effect="light"
                    placement="top"
                    :open-delay="500"
                  >
                    <div
                      slot="content"
                      style="max-width:500px"
                    >{{ data.mbox_name }}</div>
                    <div
                      class="one-line-ellipsis"
                      :class="data.uidvalidity === 'top' ? 'folder-tree-text' : ''"
                      style="flex:1;display: flex;
                      justify-content:space-between;
                      align-items: center;"
                    >
                      <span class="one-line-ellipsis">{{ data.uidvalidity === 'top'?$t('message.list.my_folder'): data.mbox_name }}</span>
                      <span style="display: flex;align-items: center;">
                        <svg-icon
                          v-if="data.uidvalidity === 'top' && ($store.state.app.auth_authority === 9)"
                          icon-class="set"
                          @click.stop="menuFolderSet"
                        />
                        <span v-else-if="Number(data.unseen) > 0">({{ data.unseen }})</span>
                      </span>
                    </div>
                  </el-tooltip>
                </div>
              </el-tree>
            </div>
            <div class="menu-list-folder">
              <el-tree
                ref="labelTree"
                :data="label_menu"
                :props="label_props"
                node-key="tag_id"
                class="filter-tree"
                highlight-current
              >
                <div
                  slot-scope="{ node, data }"
                  class="folder-tree-node "
                  style="width:196px;padding-right: 10px;display: flex;"
                  :class="data.tag_id === mbox_selected_id ? 'text-color' : ''"
                  :style="data.tag_id === mbox_selected_id ? { 'fontWeight': 600 } : ''"
                  @click="() => handleTagNodeClick(node, data)"
                >
                  <span
                    :class="data.tag_id === '标签邮件' ? 'folder-tree-text' : ''"
                    style="display: flex;align-items: center;"
                  >
                    <svg-icon
                      v-if="data.tag_id !== '标签邮件'"
                      class="bag menu-icon-14"
                      :icon-class="data.tag_color.slice(1, 7)"
                      style="margin-left: -9px;"
                    />
                    <svg-icon
                      v-else
                      class="menu-icon-14"
                      icon-class="tag_menu"
                      style="margin-left: -9px;margin-right: 8px;"
                    />
                  </span>
                  <div
                    v-if="data.tag_id === '标签邮件'"
                    style="flex:1;display: flex;
                    justify-content:space-between;
                    align-items: center;
                    "
                  >
                    <span style="display: flex;align-items: center;">
                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top"
                        :open-delay="500"
                      >

                        <div
                          slot="content"
                          style="max-width:500px"
                        >{{ $t('message.list.tag_mail') }}</div>
                        <div
                          style="display: block;align-items: center;width: 100%;"
                          class="one-line-ellipsis"
                        >
                          {{ $t('message.list.tag_mail') }}
                        </div>
                      </el-tooltip></span>
                    <span style="display: flex;align-items: center;">
                      <svg-icon
                        v-if="$store.state.app.auth_authority === 9"
                        icon-class="set"
                        @click.stop="menuLabelSet"
                      />
                    </span>
                  </div>
                  <div
                    v-else
                    style="flex:1;display: flex;
                    justify-content:space-between;
                    align-items: center;
                    padding-left: 9px;
                    width: 155px;
                    overflow: hidden;
                    "
                  >
                    <!-- <div
                      style="display: block;align-items: center;width: 100%;"
                      class="one-line-ellipsis"
                    >
                      {{ data.tag_name }}
                    </div> -->
                    <el-tooltip
                      class="item"
                      effect="light"
                      placement="top"
                      :open-delay="500"
                    >

                      <div
                        slot="content"
                        style="max-width:500px"
                      >{{ data.tag_name }}</div>
                      <div
                        style="display: block;align-items: center;width: 100%;"
                        class="one-line-ellipsis"
                      >
                        {{ data.tag_name }}
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </el-tree>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mboxListToTree, mboxTreeToFolderTreeData } from '@/utils/mbox'
import { transformClass } from '@/utils/utils.js'
import actionList from '@/views/mail-module/components/action-list '
// import bannerMail from '@/views/mail-module/banner-mail'
export default {
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
  },
  mixins: [actionList],
  data() {
    return {
      allItemsTmp: {},
      selfActived: false,
      firstStageOver: false,
      firstStageStarted: false,
      firstHighlight: ['', ''],
      getMenuAndMboxList_magic: 0, // 防止过度刷新
      folder_props: {
        label: 'mbox_name',
        children: 'children',
        isLeaf: 'leaf',
      },
      // 我的文件夹, element-ui 的 tree 绑定数据
      folderTreeData: [
        {
          menuType: 'mbox',
          uidvalidity: 'top',
          mbox_name: this.$t('message.mbox.myfolders'),
          children: [],
        },
      ],
      label_menu: [{
        menuType: 'tag',
        uidvalidity: 'tag',
        mbox: 'tag',
        tag_name: '标签邮件',
        tag_id: '标签邮件',
        tag_color: '',
        children: [],
      }],
      label_props: {
        children: 'children',
        label: 'tag_name',
      },
      menu_regular: [],
      mbox_selected_type: '',
      mbox_selected_id: '',
      odiv: '',
      disX: '',
      disY: '',
      getStation: {}, // 文件中转站信息
    }
  },
  computed: {
    /** 监听是否打开阅读销毁文件 */
    isDestroyWatch() {
      return this.$store.state.mail.isDestroy
    },
  },

  watch: {
    isDestroyWatch(newVal, oldVal) {
      /** 监听是否点击了阅读后销毁 */
      if (newVal) {
        this.$store.commit('mail/ISDESTROY', newVal)
      }
    },
  },
  created() {
    const thisobj = this

    this.$myApi.activeModuleMailMenu = () => {
      if (thisobj.selfActived) {
        return
      }
      thisobj.selfActived = true
      thisobj.firstStage()
    }

    this.$myApi.mainMenu.refreshMboxList = async(attrs /* { force: boolean } */) => {
      return new Promise((resolve) => {
        if (!attrs) {
          attrs = {}
        }
        thisobj.getMenuAndMboxList(attrs).then(result => {
          resolve(result)
        })
      })
    }
    this.$myApi.mainMenu.refreshTagList = async() => {
      return new Promise((resolve) => {
        thisobj.getTagList().then(result => {
          resolve(result)
        })
      })
    }
    this.$myApi.mainMenu.refreshAuditCount = async() => {
      return new Promise((resolve) => {
        thisobj.get_audit_total().then(result => {
          resolve(result)
        })
      })
    }
    this.$myApi.mainMenu.highlightItem = (type, id) => {
      if (this.firstStageOver) {
        return thisobj.highlightItem(type, id)
      } else {
        this.firstHighlight = [type, id]
      }
    }
  },
  methods: {
    // 高亮选中条目
    highlightItem(type, id) {
      this.$refs.folderTree.setCurrentKey(null)
      this.$refs.labelTree.setCurrentKey(null)
      this.mbox_selected_type = type
      this.mbox_selected_id = ''
      if (type === 'mbox') {
        this.mbox_selected_id = id
        this.$refs.folderTree.setCurrentKey(id)
      } else if (type === 'tag') {
        this.mbox_selected_id = id
        this.$refs.labelTree.setCurrentKey(id)
      } else if (type === 'other') {
        this.mbox_selected_id = id
      }
    },
    // 刷新全部
    async firstStage() {
      this.firstStageStarted = true
      await this.getMenuAndMboxList() // 刷新菜单和文件夹
      await this.getTagList()
      await this.get_audit_total()
      this.firstStageOver = true
      if (this.firstHighlight[0] !== '') {
        this.highlightItem(this.firstHighlight[0], this.firstHighlight[1])
      }
    },
    handleMboxNodeClick(node, data) {
      if (data.uidvalidity === 'top') {
        const last = this.$refs.folderTree.getCurrentKey()
        setTimeout(() => {
          this.$refs.folderTree.setCurrentKey(last)
        }, 1)
        return false
      }
      this.getNodesParent(node)
      const item = {
        ...data,
        path: '/empty-page',
        type: 'folder',
        menuType: 'mbox',
      }
      this.menuLinkFun(item)
    },
    getNodesParent(node) {
      if (node.parent.parent !== null) {
        this.getNodesParent(node.parent)
      }
    },
    handleTagNodeClick(node, data) {
      if (data.tag_id === '标签邮件') {
        let last = this.$refs.labelTree.getCurrentKey()
        if (last === '标签邮件') {
          last = null
        }
        setTimeout(() => {
          this.$refs.labelTree.setCurrentKey(last)
        }, 1)
        return false
      }
      this.menuLinkFun(data)
    },
    /** 跳转文件夹设置 */
    menuFolderSet() {
      this.$myApi.settingGoto('foldersettings')
    },
    writeMail() {
      if (this.$store.state.app.auth_authority === 0) {
        this.$message.error(this.$t('message.list.auth_no_compose'))
        return false
      }

      this.$myApi.mainTab.showComposeMail({ type: '', subject: this.$t('message.list.write_mail'), composeData: {}})
    },
    /** 刷新菜单和文件夹 */
    async getMenuAndMboxList(attrs) {
      if (this.getMenuAndMboxList_magic) {
        return
      }
      this.getMenuAndMboxList_magic = 1
      await this.getMenuAndMboxList_trueDo(attrs)
      this.getMenuAndMboxList_magic = 0
    },
    async getMenuAndMboxList_trueDo(attrs) {
      const params = {
        'module': 'mbox',
        'require': {
          'action': 'getList',
          'level_type': 'all',
          'force': (attrs && attrs.force) ? '1' : '0', // 是否强制同步邮件数1是; 0否
        },
      }
      // const res = await this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
      const res = await this.$ajaxRequest(this.$apiData.mail, params)
      if (res.status !== 200) {
        return
      }
      const mboxList = res.result.mboxList
      const list = []
      mboxList.forEach((item, index) => {
        if (item.subscribe === '1') { // 隐藏状态的不赋值
          if (item.mbox_name === '回收站') item.mbox_name = '已删除'
          list.push(item)
        }
      })
      // stamp值为空时，赋值给新邮件通知使用，新邮件每分钟更新时再重新赋值
      if (!this.$myData.newMailStamp) this.$myData.newMailStamp = res.result.stamp
      const mboxTree = mboxListToTree(res.result)
      this.folderTreeData[0].children = mboxTreeToFolderTreeData(mboxTree)
      this.$myData.mbox_list = list
      this.$myData.mbox_tree = mboxTree
      this.$myState.mbox_list_plus_watch++

      // 如果还没激活界面,则返回
      if (!this.firstStageStarted) {
        return
      }

      const menu_regular = []
      /** *
         * 列表显示顺序
         * 收件箱INBOX,发件箱Sent,草稿箱Drafts,已删除(回收站)trash;-----接口返回详细数据
         * 星标邮件start,未读邮件unseen;---这两个邮箱为搜索结果类,是固定写死的邮箱
         * 归档邮件archive,垃圾邮件rubbishs；-----接口返回详细数据
         *  */
      const sort_arr = ['INBOX', 'Sent', 'Drafts', 'trash', 'start', 'unseen', 'archive', 'rubbishs']
      mboxList.forEach(item => {
        const i = sort_arr.indexOf(item.mbox)
        if (i > -1) {
          menu_regular[i] = {
            ...item,
            label: sort_arr[i],
            path: '/empty-page',
            'type': 'folder',
            'menuType': 'mbox',
          }
        }
      })
      menu_regular[4] = {
        mbox: 'start',
        label: 'start',
        mbox_name: this.$t('message.list.start'),
        path: '/empty-page',
        'type': 'folder',
        'menuType': 'mbox',
        'uidvalidity': 'start',
        'uv': 'start',
      }
      menu_regular[5] = {
        mbox: 'unseen',
        label: 'unseen',
        mbox_name: this.$t('message.list.unseen'),
        path: '/empty-page',
        'type': 'folder',
        'menuType': 'mbox',
        'uidvalidity': 'unseen',
        'uv': 'unseen',
      }
      if (this.$store.state.app.auth_authority === 9 || this.$store.state.app.auth_authority === 2) {
        menu_regular.push({
          mbox: 'tracking',
          label: 'tracking',
          mbox_name: this.$t('message.mbox.emailtracking'),
          path: '/tracking',
          type: 'folder',
          menuType: 'other',
          uidvalidity: 'tracking',
        }, {
          mbox: 'audit',
          label: 'audit',
          mbox_name: this.$t('message.mbox.reviewnotifications'),
          path: '/audit',
          to_audit_total: 'to_audit_total',
          type: 'folder',
          menuType: 'other',
          uidvalidity: 'audit',
        }, {
          mbox: 'attachment',
          label: 'attachment',
          mbox_name: this.$t('message.mbox.attachmentmanagement'),
          path: '/attachment',
          icon: 'mail_menu_start',
          type: 'folder',
          menuType: 'other',
          uidvalidity: 'attachment',
        }, {
          mbox: 'fileWebDisk',
          label: 'fileWebDisk',
          mbox_name: this.$t('message.mbox.ftStation'),
          path: '/fileWebDisk',
          type: 'folder',
          menuType: 'other',
          uidvalidity: 'fileWebDisk',
        })
      }
      this.menu_regular = menu_regular
      if (!this.firstStageOver) {
        if (this.$myData.tabShowMainFlag) {
          this.menuLinkFun(menu_regular[0])
        }
      }
      return
    },
    /** 跳转标签设置 */
    menuLabelSet() {
      this.$myApi.settingGoto('labelmanagement')
    },
    // 鼠标松开
    menuMouseupFn(item, type) {
      const mboxArr = ['INBOX', 'Sent', 'trash', 'archive', 'rubbishs']
      // 如果是我的文件夹自定义的， 追加类型mbox
      if (type === 1) mboxArr.push(item.mbox)
      if (this.$myState.isMousedownBox) {
        if (mboxArr.indexOf(item.mbox) !== -1) {
          this.$myApi.mailMove_TMP_1102(item.mbox)
        } else {
          this.$message.error(this.$t('message.list.not_move_folder'))
        }
      }
    },
    menuLinkFun(item) {
      if (item.mbox === 'tag' && item.tag_id === '标签邮件') {
        return false
      }

      let key = ''
      if (item.menuType === 'mbox') {
        key = 'mbox/' + item.uidvalidity
      } else if (item.menuType === 'tag') {
        key = 'tag/' + item.tag_id
      } else {
        key = 'other/' + item.mbox
      }
      this.allItemsTmp[key] = item

      if (item.menuType === 'mbox' || item.menuType === 'tag') {
        this.$myApi.mainTab.showMain(item)
        return true
      }
      if (item.menuType === 'other') {
        if (item.mbox === 'tracking') {
          this.$myApi.mainTab.showTracking()
          return true
        }
        if (item.mbox === 'audit') {
          this.$myApi.mainTab.showAudit()
          this.$myApi.mainMenu.refreshAuditCount()
          return true
        }
        if (item.mbox === 'attachment') {
          this.$myApi.mainTab.showAttachment()
          return true
        }
        if (item.mbox === 'fileWebDisk') {
          this.$myApi.mainTab.showFileWebDisk()
          return true
        }
        return false
      }
      return false
    },
    /** 收信 */
    async receiveAction() {
      this.$myData.newMailStamp = 0 // 清空时间，获取最新时间
      await this.getMenuAndMboxList() // 刷新菜单和文件夹
      await this.getTagList()
      await this.get_audit_total()
      const key = this.mbox_selected_type + '/' + this.mbox_selected_id
      if (this.allItemsTmp[key]) {
        this.menuLinkFun(this.allItemsTmp[key])
      }
    },
    // 获取文件中转站的信息
    getStationInfo() {
      const params = {
        'module': 'netdisk',
        'require': {
          'action': 'getStationInfo',
        },
      }
      this.$ajaxRequest(this.$apiData.netdisk, params).then(res => {
        if (res.status === 200) {
          this.getStation = res.result
        }
      })
    },
    // 获取全部标签列表并显示
    async getTagList() {
      const req = {
        'module': 'tag',
        'require': {
          action: 'getList',
        },
      }
      const res = await this.$ajaxRequest(this.$apiData.mail, req)
      if (res.status !== 200) {
        return res
      }
      const tag_label = []
      res.result.forEach(item => {
        tag_label.push({
          menuType: 'tag',
          mbox: 'tag',
          mbox_name: this.$t('message.list.tag_mail'),
          path: '/empty-page',
          type: 'tag', // folder
          uidvalidity: 'tag',
          uv: 'tag',
          ...item,
        })
      })
      this.label_menu[0].children = tag_label
      this.$myData.tag_list = tag_label
      this.$myState.tag_list_plus_watch++
      return { status: 200 }
    },
    async get_audit_total() {
      let params = {}
      params = {
        'module': 'mail',
        'require': {
          'action': 'getBaseInfoList',
          'from': 0, // 起始位置
          'limit': 1, // 读取个数
          'type': 'folder',
          'uidvalidity': 1,
          'uv': 1,
          'removeJunk': 0, // 不过滤回收站及垃圾箱
        }}
      const res = await this.$ajaxRequest(this.$apiData.mail, params)
      if (res.status === 200) {
        this.menu_regular.forEach(item => {
          if (item.uidvalidity === 'audit') {
            item.unseen = res.result.to_audit_total
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-container {
  position: absolute;
  top: 0px;
  height: 100%;
  left: 0px;
}
.mail-module-menu {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;

    /deep/.el-tree-node__content {
        height: 34px;
        line-height: 34px;
        border-radius: 5px;
    }

    /deep/.el-tree-node__expand-icon {
        font-size: 16px;
    }

    .menu-module-aside {
        width: 260px;
        height: 100%;
        padding: 0;
        margin: 0;
        border-right: 1px solid #dcdcdc;
    }
    .menu-module-main {
        flex: 1;
        height: 100%;
        display: flex;
        overflow: auto;
    }

    .shortcut-operate {
      position: absolute;
      top:0px;
      height: 58px;
      left: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .operate-receive {
         width: 30px;
         height: 30px;
         margin-left: 15px;
         border: 1px solid #DADADA;
         border-radius: 15px;
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;
      }

      .operate-receive:hover {
          background-color: #f0f1f1;
      }

      .operate-receive:active {
        background-color: #e8e8e8;
      }
    }

    .menu-list-container {
      position: absolute;
      top:59px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      flex: 1;
      .menu-list {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          height: calc(100vh - 141px);
          overflow: auto;

          .gdwid {
              display: flex;
              align-items: center;
              // width: 18px;
              // .svg-icon {
              //     vertical-align: -0.3em;
              // }

          }
          .namembox{
            display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              max-width: 145px;
              overflow: hidden;
              text-overflow: ellipsis;
          }
        }

        .menu-list-item {
            height: 34px;
            line-height: 34px;
            border-radius: 5px;
            padding-left: 15px;
            padding-right: 15px;
            // margin-bottom: 3px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .menu-list-item-text {
                margin-left: 8px;
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .menu-list-item-text-setting,
            .menu-list-item-text-network {
                color: #1D1B1B;
                display: ruby !important;
            }
        }

        .menu-list-folder {
            border-top: 1px solid #DEDCDC;
            margin-top: 8px;
            padding-top: 8px;
        }

        .menu-list-folder:last-child {
            margin-bottom: 20px;
        }
    }

    .folder-tree-node {
        display: flex;
        align-items: center;
    }

    .folder-tree-text {
        // display: inline-block;
        // width: 150px;
        margin-left: 8px;
    }
}

.bag {
    width: 14px;
    height: 14px;
    border-radius: 2px 2px 2px 2px;
    // display: block;
    // float: left;
    // margin: 3px 9px 0 0;
    // vertical-align: middle;
}

.disk_tips {
    h1 {
        font-weight: bold;
        font-size: 100%;
        padding: 3px 0;
    }

    p {
        font-size: 100%;
        padding: 3px 0;
    }
}

.write-mail-button {
  width: 170px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}
:deep(.el-tree-node > .el-tree-node__children) {
  overflow: visible;
}
</style>
