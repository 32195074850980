<template>
  <!-- 邮件模块 -->
  <div
    class="mail-module-container"
  >
    <!-- 页签 -->
    <Tab />
  </div>
</template>
<script>
import Tab from './Tab.vue'
export default {
  components: {
    Tab,
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.mail-module-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #1D1B1B;
}
</style>

