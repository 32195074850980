<template>
  <el-popover
    placement="bottom"
    popper-class="mail-contactCard"
    :disabled="disabledType"
    :open-delay="1000"
    trigger="hover"
    @show="showProper"
  >
    <div style="display:flex;align-items: center;">
      <div class="width36">
        <div class="user-abbr user-abbr-theme"><span class="user-abbr-span">{{ contactMail.slice(0, 1) }}</span></div>
      </div>
      <div style="flex:1">
        <div
          class="one-line-ellipsis"
          style="width: 100%;"
        >
          {{ name }}
        </div>
        <div style="width: 100%;display: flex;align-items: center;">
          <div
            style="flex:1;"
            class="one-line-ellipsis"
          >{{ contactMail }}</div>
          <div
            style="width: 50px;cursor: pointer;"
            class="text-color"
            @click="copyAddress"
          >{{ $t('message.contact.copy') }}</div>
        </div>
      </div>
    </div>
    <div
      class="text-color"
      style="margin: 10px 48px;cursor: pointer;"
      @click="contactSearch"
    >{{ $t('message.contact.ckcrossmail') }}</div>
    <div
      style="display: flex;align-items: center;justify-content: space-evenly;border-top: 1px solid #EFEEEE;padding-top: 5px;"
    >
      <div
        class="el-select-dropdown__item"
        style="text-align: center;cursor: pointer;"
        @click="ComposeAction"
      >{{ $t('message.list.write_mail') }}
      </div>
      <div
        v-if="isNew"
        class="el-select-dropdown__item"
        style="text-align: center;cursor: pointer;"
        @click="add"
      >{{ $t('message.view.add_contact') }}</div>
      <div
        v-else
        class="el-select-dropdown__item"
        style="text-align: center;cursor: pointer;"
        @click="edit"
      >{{ $t('message.contact.editcontact') }}</div>
      <div
        v-if="!isBlack"
        class="el-select-dropdown__item"
        style="text-align: center;cursor: pointer;"
        @click="addBlackList"
      >{{ $t('message.view.refuse_mail') }}</div>
    </div>
    <span slot="reference">{{ textContent }}</span>
  </el-popover>
</template>
<script>
import { copy } from '@/utils/utils.js'
import serialize from '@/utils/serialize'
// import { transformClass } from '@/utils/utils.js'
const mailgw = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
  /**
   * @props contactName 姓名
   *        contactMail 邮件地址
   */
  props: {
    // 姓名
    contactName: {
      type: String,
      required: true,
    },
    // 邮件地址
    contactMail: {
      type: String,
      required: true,
    },
    // 机构
    contactGroup: {
      type: String,
      required: true,
    },
    // 页面显示内容
    textContent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disabledType: !true,
      isNew: true,
      isBlack: false,
      ids: '', // 联系人名片编辑id
      name: '',
    }
  },

  computed: {
    /** 监听是否切换了授权 */
    isCurrAuthWatch() {
      return this.$store.state.app.auth_info.curr_auth_type
    },
  },
  watch: {
    isCurrAuthWatch(newVal, oldVal) {
      if (newVal === 0 || newVal === 1) {
        this.disabledType = true
      } else {
        this.disabledType = false
      }
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.state.app.auth_info.curr_auth_type === 0 || this.$store.state.app.auth_info.curr_auth_type === 1) {
        this.disabledType = true
      } else {
        this.disabledType = false
      }
    })
  },
  methods: {
    /** 往来邮件 */
    contactSearch() {
      this.$myApi.mainTab.showMailContact({
        contact_mail: this.contactMail,
        contact_name: this.contactName,
        type: 'contact',
        mbox: 'contact',
        uid: 'contact',
      })
    },
    copyAddress() {
      copy.copyText(this.contactMail)
      this.$message({
        message: this.$t('message.contact.info18'),
        type: 'success',
      })
    },
    /** 写邮件 */
    ComposeAction() {
      const data = [{
        subject: this.$t('message.list.write_mail'),
        mail: this.contactMail,
        name: this.contactName,
        path: this.contactGroup,
        isValid: true,
      }]
      this.$myApi.mainTab.showComposeMail({ type: 'contact', subject: this.$t('message.list.write_mail'), composeData: data })
    },
    showProper() {
      this.checkMailIsInPersonalBook()
    },
    async checkMailIsInPersonalBook() {
      if (!mailgw.test(this.contactMail)) return false
      const params = {
        module: 'contact',
        require: {
          action: 'getPublicContactTips',
          mail: this.contactMail,
          type: 2,
        },
      }
      const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.contact_id !== '') {
        this.ids = res.contact_id
        this.isNew = false
        this.name = res.contact_name
      } else {
        this.isNew = true
        this.name = this.contactName
      }
      if (res.in_black) {
        this.isBlack = true
      } else {
        this.isBlack = false
      }
    },
    edit() {
      this.$parent.onHandelAddcontact('edit', { contactId: this.ids })
    },
    add() {
      this.$parent.onHandelAddcontact('add', { name: this.contactName, mail: this.contactMail })
    },
    addBlackList() {
      this.$confirm(this.$t('message.view.refuse_mail_not_rec'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(() => {
        const req = { 'module': 'filter', 'require': { act: 'add', action: 'bw', bw: 'blackMail', idm: this.contactMail }}
        this.$axios.post(this.$apiData.mail, { req: JSON.stringify(req) }).then(res => {
          if (res.status === 200) {
            this.$message({
              type: 'success',
              message: this.$t('message.view.add_ok'),
            })
          } else {
            this.$message.error(res.status)
          }
        })
      }).catch(() => {
      })
    },
  },
}
</script>
<style lang="scss" scoped>
/** 联系人名片样式 */
.mail-contactCard {
    .width36 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 100%;
    }

    .user-abbr {
        width: 32px;
        height: 32px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        .user-abbr-span {
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}
</style>
