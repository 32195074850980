import store from '@/store'
// import router from '@/router'
import axios from '@/api/axios-requery'
import apiData from '@/api/api-store'
import serialize from '@/utils/serialize'
import { loginInit } from '@/utils/InitializePage'

/** 是否是首次登录 */
export const isFirstLogin = (mail, role, type) => {
  const req = {
    login_type: type,
    username: mail, // 邮箱地址
  }
  axios.post(apiData.checklogin, { req: serialize(req) }).then(res => {
    // 1首次登录 0 不是首次
    store.commit('app/IS_FIRST_LOGIN', res.result.need_edit) // 是否首次登录

    loginInit(role)
  })
}
/** 获取密码策略 */
// export const get_pass_policy = () => {
//   const data = {
//     'module': 'mail',
//     'require': {
//       'action': 'get_pass_policy',
//     },
//   }
//   axios.post(apiData.get_pass_policy, { req: serialize(data) }).then(res => {
//     store.commit('app/PASSWORD_RULE', res.result)
//   })
// }
