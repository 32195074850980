<template>
  <div class="firstBox">
    <el-dialog
      v-loading="loading"
      :element-loading-text="$t('message.view.loading')"
      :title="$t('message.mailmodule.first_change')"
      :width="$i18n.locale === 'english' ? '700px' : '600px'"
      :visible.sync="$parent.isFirstPassowrd"
      :show-close="false"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        style="padding:0 50px"
      >
        <el-form-item
          :label="$t('message.mailmodule.init_psw')"
          :label-width="formLabelWidth"
          prop="jpassword"
        >

          <div
            class="showPass showPassHeight"
          >

            <!-- <p
              v-if="!eyes1"
            >
              <input
                v-model="pwdCover1"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown"
                @input="setPassword"
              >
              <span @click="showPwd(true,'eyes1')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.jpassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @input="setPwdCover"
              >
              <span @click="showPwd(false,'eyes1')"><svg-icon icon-class="showPassword" /></span>
            </p> -->

            <p>
              <input
                v-model="form.jpassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                :type="inputType1"
              >
              <span
                v-if="!eyes1"
                @click="showPwd(true,'eyes1')"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false,'eyes1')"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
          </div>

        </el-form-item>
        <el-form-item
          :label="$t('message.mailmodule.newpsw')"
          :label-width="formLabelWidth"
          prop="npassword"
        >
          <div
            class="showPass showPassHeight"
          >
            <div
              v-show="tips2"
              class="tips"
            >
              <div v-html="error_html" />
            </div>
            <!-- <p
              v-if="!eyes2"
            >
              <input
                v-model="pwdCover2"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown_new"
                @input="setPassword_new"
                @focus="tips2 = true"
                @blur="tips2 = false"
              >
              <span @click="showPwd(true,'eyes2')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.npassword"
                class="password noelInput"
                maxlength="32"
                autocomplete="off"
                @focus="tips2 = true"
                @blur="tips2 = false"
                @input="setPwdCover_new"
              >
              <span @click="showPwd(false,'eyes2')"><svg-icon icon-class="showPassword" /></span>
            </p> -->

            <p>
              <input
                v-model="form.npassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                :type="inputType2"
                @focus="tips2 = true"
                @blur="tips2 = false"
              >
              <span
                v-if="!eyes2"
                @click="showPwd(true,'eyes2')"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false,'eyes2')"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('message.mailmodule.confirm_psw')"
          :label-width="formLabelWidth"
          prop="okNewPassword"
        >
          <div
            class="showPass showPassHeight"
          >
            <div
              v-show="tips3"
              class="tips"
            >
              <div v-html="error_html" />
            </div>
            <!-- <p
              v-if="!eyes3"
            >
              <input
                v-model="pwdCover3"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown_ok"
                @input="setPassword_ok"
                @focus="tips3 = true"
                @blur="tips3 = false"
              >
              <span @click="showPwd(true,'eyes3')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.okNewPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @input="setPwdCover_ok"
                @focus="tips3 = true"
                @blur="tips3 = false"
              >
              <span @click="showPwd(false,'eyes3')"><svg-icon icon-class="showPassword" /></span>
            </p> -->

            <p>
              <input
                v-model="form.okNewPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                :type="inputType3"
                @focus="tips3 = true"
                @blur="tips3 = false"
              >
              <span
                v-if="!eyes3"
                @click="showPwd(true,'eyes3')"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false,'eyes3')"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('message.login.selectq')"
          :label-width="formLabelWidth"
          prop="question1_id"
        >
          <el-select
            v-if="!isQuestion1"
            v-model="form.question1_id"
            style="width:360px"
            :placeholder="$t('message.login.placeholder7')"
            @change="questionClick(1)"
          >
            <el-option
              v-for="item in questionList"
              :key="item.val"
              :label="$t(`message.login.${item.label}`)"
              :value="item.val"
            />
          </el-select>
          <p
            v-else
            class="wtbox"
          >
            <input
              v-model="textarea_question1"
              class="noelInput noLimit"
              :placeholder="$t('message.login.inputcontent')"
            >
            <span class="wt_nums">{{ textarea_question1.length }}/128</span>
            <svg-icon
              class="wt_back"
              icon-class="goBackPassword"
              @click="back(1)"
            />

          </p>
        </el-form-item>
        <el-form-item
          :label="$t('message.mailmodule.set_answer')"
          :label-width="formLabelWidth"
          prop="answer1"
        >
          <input
            v-model="form.answer1"
            class="noelInput"
            autocomplete="off"
          >
        </el-form-item>

        <el-form-item
          :label="$t('message.login.selectq')"
          :label-width="formLabelWidth"
        >

          <el-select
            v-if="!isQuestion2"
            v-model="form.question2_id"
            style="width:360px"
            :placeholder="$t('message.login.placeholder7')"
            @change="questionClick(2)"
          >

            <el-option
              v-for="item in questionList"
              :key="item.val"
              :label="$t(`message.login.${item.label}`)"
              :value="item.val"
            />
          </el-select>

          <p
            v-else
            class="wtbox"
          >
            <input
              v-model="textarea_question2"
              class="noelInput noLimit"
              :placeholder="$t('message.login.inputcontent')"
            >
            <span class="wt_nums">{{ textarea_question2.length }}/128</span>
            <svg-icon
              class="wt_back"
              icon-class="goBackPassword"
              @click="back(2)"
            />

          </p>
        </el-form-item>
        <el-form-item
          :label="$t('message.mailmodule.set_answer')"
          :label-width="formLabelWidth"
          prop="answer2"
        >
          <input
            v-model="form.answer2"
            class="noelInput"
            autocomplete="off"
          >
        </el-form-item>
      </el-form>
      <p
        v-show="errMsg"
        class="errmsg"
      />
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          :disabled="disabled"
          @click="submitForm('form')"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="resetForm('form')">{{ $t('message.mailmodule.reset') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { PasswordSm4 } from '@/utils/changePassword'
// import { PassWord, PwdCover, PasswordSm4 } from '@/utils/changePassword'
import serialize from '@/utils/serialize'
import { transformClass } from '@/utils/utils.js'
export default ({
  data() {
    const checkPwd_ok = (rule, value, callback) => {
      if (value.trim().length === 0) {
        callback(new Error(this.$t('message.login.err1')))
      } else if (value !== this.form.npassword) {
        callback(new Error(this.$t('message.login.err3')))
      } else {
        callback()
      }
    }
    const question = (rule, value, callback) => {
      if (value === 0 || value === '') {
        callback(new Error(this.$t('message.login.placeholder7')))
      } else if (value === 7 && !this.textarea_question1.length) {
        callback(new Error(this.$t('message.mailmodule.input_q')))
      } else {
        callback()
      }
    }
    const answerOK = (rule, value, callback) => {
      if (this.form.question2_id !== '' && this.form.question2_id !== 7 && this.form.answer2.length === 0) {
        callback(new Error(this.$t('message.login.placeholder8')))
      } else if (this.form.question2_id === 7 && this.textarea_question2.length > 0 && this.form.answer2.length === 0) {
        callback(new Error(this.$t('message.login.placeholder8')))
      } else {
        callback()
      }
    }
    return {
      // dialogFirstPassword: false,
      eyes1: false, // 是否查看密码
      eyes2: false, // 是否查看密码
      eyes3: false, // 是否查看密码
      // cursorStart1: 0,
      // cursorEnd1: 0,
      // cursorStart2: 0,
      // cursorEnd2: 0,
      // cursorStart3: 0,
      // cursorEnd3: 0,
      // pwdCover1: '',
      // pwdCover2: '',
      // pwdCover3: '',
      inputType1: 'password',
      inputType2: 'password',
      inputType3: 'password',
      tips2: false,
      tips3: false,
      form: {
        jpassword: '',
        npassword: '',
        okNewPassword: '',
        question1_id: '',
        answer1: '',
        question2_id: '',
        answer2: '',
      },
      textarea_question1: '',
      textarea_question2: '',
      formLabelWidth: this.$i18n.locale === 'english' ? '200px' : '100px',
      isQuestion1: false,
      isQuestion2: false,
      rules: {
        jpassword: [
          { required: true, message: this.$t('message.setting.err1'), trigger: 'blur' },
        ],
        npassword: [
          { required: true, message: this.$t('message.setting.err2'), trigger: 'blur' },
        ],
        okNewPassword: [
          { validator: checkPwd_ok, required: true, trigger: 'blur' },
        ],
        question1_id: [
          { validator: question, required: true, trigger: 'change' },
        ],
        answer1: [
          { required: true, message: this.$t('message.login.placeholder8'), trigger: 'blur' },
        ],
        answer2: [
          { validator: answerOK, required: false, trigger: 'blur' },
        ],

      },
      questionList: [{
        val: 1,
        label: 'q1',
        q: '您的出生地是?',
      }, {
        val: 2,
        label: 'q2',
        q: '您的父亲姓名是?',
      }, {
        val: 3,
        label: 'q3',
        q: '您的母亲姓名是?',
      }, {
        val: 4,
        label: 'q4',
        q: '您的配偶姓名是?',
      }, {
        val: 5,
        label: 'q5',
        q: '您的生日是?',
      }, {
        val: 6,
        label: 'q6',
        q: '您的工作城市是?',
      }, {
        val: 7,
        label: 'q7',
        q: '《-自定义问题-》',
      }],
      errMsg: '',
      error_html: '', // 密码提示
      loading: false,
      disabled: false,
    }
  },
  created() {
  },
  mounted() {
    // this.$nextTick(() => {
    this.get_pass_policy()
    // })
  },
  methods: {
    handleClose() {
      return false
    },
    // 获取用户规则
    get_pass_policy() {
      const data = {
        'module': 'mail',
        'require': {
          'action': 'get_pass_policy',
        },
      }
      this.$axios.post(this.$apiData.get_pass_policy, { req: serialize(data) }).then(res => {
        this.error_html = transformClass.password_error_html(res.result.min_pass_lenth, res.result.max_pass_lenth, res.result.pass_filt, res.result.old_pass_same === 1 ? '不能' : '能', res.result.user_pass_same === 1 ? '不能' : '能', localStorage.getItem('lang'))
      })
    },
    // 显示密码
    showPwd(status, eyes) {
      if (eyes === 'eyes1') {
        status ? this.eyes1 = true : this.eyes1 = false
        // this.pwdCover1 = this.pwdCover1.slice(0, this.form.jpassword.length)
        this.inputType1 = status ? 'text' : 'password'
      }
      if (eyes === 'eyes2') {
        status ? this.eyes2 = true : this.eyes2 = false
        // this.pwdCover2 = this.pwdCover2.slice(0, this.form.npassword.length)
        this.inputType2 = status ? 'text' : 'password'
      }
      if (eyes === 'eyes3') {
        status ? this.eyes3 = true : this.eyes3 = false
        // this.pwdCover3 = this.pwdCover3.slice(0, this.form.okNewPassword.length)
        this.inputType3 = status ? 'text' : 'password'
      }
    },
    // // 初始密码
    // handlerKeydown(event) {
    //   this.cursorStart1 = event.target.selectionStart
    //   this.cursorEnd1 = event.target.selectionEnd
    // },
    // // 初始密码 用户登录密码框提换值
    // setPassword(event) {
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   // this.capsTooltip = false
    //   this.pwdCover1.length === 0 ? this.form.jpassword = '' : ''
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const b = new PassWord(event, this.pwdCover1, this.form.jpassword, this.cursorStart1, this.cursorEnd1)
    //     this.pwdCover1 = b.toValue()[0]
    //     this.form.jpassword = b.toValue()[1]
    //   }
    // },
    // // 初始密码 用户登录密码框提换值
    // setPwdCover(event) {
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover1, this.form.jpassword)
    //     this.pwdCover1 = a.toValue()
    //     this.errMsg = ''
    //   }
    // },

    // // 新密码
    // handlerKeydown_new(event) {
    //   this.cursorStart2 = event.target.selectionStart
    //   this.cursorEnd2 = event.target.selectionEnd
    // },
    // // 新密码 用户登录密码框提换值
    // setPassword_new(event) {
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   // this.capsTooltip = false
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover2 = this.pwdCover2.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const b = new PassWord(event, this.pwdCover2, this.form.npassword, this.cursorStart2, this.cursorEnd2)
    //     this.pwdCover2 = b.toValue()[0]
    //     this.form.npassword = b.toValue()[1]
    //     this.errMsg = ''
    //   }
    // },
    // // 新密码 用户登录密码框提换值
    // setPwdCover_new(event) {
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover2 = this.pwdCover2.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover2, this.form.npassword)
    //     this.pwdCover2 = a.toValue()
    //     this.errMsg = ''
    //   }
    // },

    // // 确认密码
    // handlerKeydown_ok(event) {
    //   this.cursorStart3 = event.target.selectionStart
    //   this.cursorEnd3 = event.target.selectionEnd
    // },
    // // 确认密码 用户登录密码框提换值
    // setPassword_ok(event) {
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   // this.capsTooltip = false
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover3 = this.pwdCover3.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const b = new PassWord(event, this.pwdCover3, this.form.okNewPassword, this.cursorStart3, this.cursorEnd3)
    //     this.pwdCover3 = b.toValue()[0]
    //     this.form.okNewPassword = b.toValue()[1]
    //     this.errMsg = ''
    //   }
    // },
    // // 确认密码 用户登录密码框提换值
    // setPwdCover_ok(event) {
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover3 = this.pwdCover3.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover3, this.form.okNewPassword)
    //     this.pwdCover3 = a.toValue()
    //     this.errMsg = ''
    //   }
    // },
    // 密码问题选择
    questionClick(type) {
      type === 1 && this.form.question1_id === 7 ? this.isQuestion1 = true : ''
      type === 2 && this.form.question2_id === 7 ? this.isQuestion2 = true : ''
    },
    back(type) {
      if (type === 1) {
        this.isQuestion1 = false
        this.form.question1_id = ''
      } else {
        this.isQuestion2 = false
        this.form.question2_id = ''
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.disabled = true
          setTimeout(() => {
            this.loading = false
            this.disabled = false
          }, 3000)
          const username = this.$store.state.app.realName
          const req = JSON.parse(JSON.stringify(this.form))
          req.jpassword = new PasswordSm4(username, this.form.jpassword).toValue()
          req.npassword = new PasswordSm4(username, this.form.npassword).toValue()
          req.okNewPassword = new PasswordSm4(username, this.form.okNewPassword).toValue()
          req.username = username
          this.questionList.forEach(el => {
            if (el.val === req.question1_id) {
              req.question1 = req.question1_id === 7 ? this.textarea_question1 : el.q
            }
            if (el.val === req.question2_id) {
              req.question2 = req.question2_id === 7 ? this.textarea_question2 : el.q
            }
          })
          this.$axios.post(this.$apiData.setLogin, { req: serialize(req) }).then(res => {
            // this.dialogFirstPassword = false
            this.$parent.isFirstPassowrd = false
            this.loading = false
            this.disabled = false
            const userInfo = {
              user_mail: username,
              user_pwd: new PasswordSm4(username, this.form.npassword).toValue(),
              user_type: this.$store.state.app.userInfo.user_type, // 登录方式
            }
            // 更改成---不是首次登录
            this.$store.commit('app/IS_FIRST_LOGIN', 0)
            this.$store.commit('app/USERINFO', userInfo)
            this.$message.success(this.$t('message.mailmodule.change_ok'))
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      // this.pwdCover1 = ''
      // this.pwdCover2 = ''
      // this.pwdCover3 = ''
      this.form.question2_id = ''
      this.form.answer2 = ''
      this.eyes1 = false // 是否查看密码
      this.eyes2 = false // 是否查看密码
      this.eyes3 = false // 是否查看密码
      this.inputType1 = 'password'
      this.inputType2 = 'password'
      this.inputType3 = 'password'
      this.$refs[formName].resetFields()
    },
  },
})
</script>
<style lang="scss" scoped>
@import '@/styles/password.scss';
.showPass {
  position: relative;
  margin: 0;
  .password {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
  span {
    position: absolute;
    right: 5px;
    bottom: 0;
    cursor: pointer;
    .svg-icon {
      vertical-align: middle;
    }
  }
}
.wtbox /deep/.el-input--small .el-input__inner {
  padding-right: 95px;
}
.wtbox {
  position: relative;
}
.wt_nums,
.wt_back {
  position: absolute;
  right: 2px;
  bottom: 7px;
}
.wt_back {
  cursor: pointer;
}
.wt_nums {
  right: 35px;
  bottom: 0;
}
</style>
