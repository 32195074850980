<template>
  <div class="preview-contaciner">

    <div class="main-preview">
      <div class="info">
        <div class="subject item">
          {{ userStreamCache.previewctx.subject }}
        </div>
        <div class="from item">
          <span>{{ $store.state.app.userInfo.user_mail }}</span>
          &nbsp;&nbsp;&nbsp;
          <span>{{ showDate() }}</span>
        </div>
        <div
          v-if="userStreamCache.previewctx.to.length"
          class="to item word-break"
        >
          <span>{{ $t('message.compose.sendto') }}：</span>
          <span
            v-for="(item,index) in userStreamCache.previewctx.to"
            :key="index"
          >{{ item[1] }};</span>
        </div>
        <div
          v-if="userStreamCache.previewctx.atts.length!==0"
          class="attachment item "
        >
          <span>{{ $t('message.compose.atts') }}：</span>
          <span>{{ $t('message.compose.numatts',{num:userStreamCache.previewctx.atts.length}) }}</span>
          <span class="previewAtts text-color">{{ $t('message.compose.viewatts') }}</span>
        </div>
      </div>
      <div
        class="viewhtml"
        v-html="userStreamCache.previewbody"
      />
      <div
        v-show="userStreamCache.previewctx.atts.length"
        id="view_atta"
        class="view_atta"
      >
        <div class="atta_tit">{{ $t('message.compose.atts') }}(<span>{{ $t(`message.compose.atts`,{num:userStreamCache.previewctx.atts.length}) }}</span>)
          <!-- <span
            v-if="ctx.atts.length>1"
            class="text-color cur"
          >(打包下载)</span> -->
        </div>
        <div class="atta_html">
          <div
            v-for="item in userStreamCache.previewatts"
            :key="item.upid"
            class="attachment-item"
          >
            <div style="display: flex">
              <div class="attachment-icon">
                <svg-icon
                  :icon-class="getFileType(item.name)+'icon'"
                  class-name="atticon"
                />
              </div>
              <div class="attachment-info">
                <div style="display:flex">
                  <span class="title">{{ item.name }}</span>
                  <span
                    class="size"
                    style="color: #8E8D8D;"
                  >
                    <span>({{ item.size|mailSizefilter }})</span>
                  </span>
                </div>
                <div style="display:flex">
                  <span
                    v-if="isPreView(item.name)"
                    class="delete text-color el-button--text"
                    @click="previewAtta(item)"
                  >{{ $t('message.compose.priview') }}</span>

                  <span
                    class="delete text-color el-button--text"
                    @click="downloadAtta(item)"
                  >{{ $t('message.compose.download') }}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="btn1">
      <el-button
        type="primary"
        @click="closePreview"
      >{{ $t('message.compose.closepeiview') }}</el-button>
    </div>
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />
  </div>
</template>

<script>
import downAttaForm from '../../components/downAtta.vue'
import { GetFileType, transformClass } from '@/utils/utils.js'
import netdisk from '@/views/compose/components/netdisk'
export default {
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
  },
  components: { downAttaForm },
  mixins: [netdisk],
  data() {
    return {
      attachments: [{
        name: '12131313.jpg',
        size: '235664',
      }],
      isAttaDown: false,
      // ctx: this.$store.state.compose.previewctx,
    }
  },
  mounted() {

  },
  methods: {
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    translateTime(time, type) {
      return transformClass.timeFormat(time, type)
    },
    closePreview() {
      this.$parent.preview()
    },
    showDate() {
      const today = new Date()
      const date = today.toLocaleDateString('zh-CN').replace(/\//g, '-')
      const time = today.toLocaleTimeString('zh-CN')
      const dateTime = `${date} ${time}`
      return dateTime
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-contaciner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     .main-preview{
        height: calc(100vh - 173px);
        overflow-y: auto;
    }
    .btn{
        padding: 9px 22px;
    }
    .btn1{
        padding: 16px 22px;
        background-color: #F1F3F4;
    }
    .info{
        background-color: #F7F8F9;
        padding: 12px 22px;
        .subject{
            color: #1D1B1B;
            font-weight: bold;
        }
        .from{
            color: #A0A0A0;
        }
        .attachment{
            .previewAtts{
                padding-left: 10px;
                cursor: pointer;
            }
        }
        .item{
            padding: 5px;
        }
    }
    .viewhtml{
        word-wrap: break-word;
        padding: 43px 47px;
        line-height: 2em;
    }
    .view_atta {
      padding: 20px 22px;
    }
    .atta_tit {
      font-weight: bold;
    }
    .atta_html {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      .mv-att-item {
        width: 326px;
        // height: 58px;
        background: #ffffff;
        border-radius: 3px 3px 3px 3px;
        border: 1px solid #b8b8b8;
        box-sizing: border-box;
        display: flex;
        margin: 0 10px 21px 0;
      }
      .attaLeft {
        width: 36px;
        height: 36px;
        margin: auto 10px;
        .attaClass {
          width: 100%;
          height: 100%;
        }
      }
      .attaRight {
        flex: 1;
        padding: 12px 10px 12px 0;
        font-size: 107%;
        .attaR_top span {
          float: left;
          color: #b8b8b8;
        }
        .attaR_top .atta_name {
          max-width: 177px;
          /**将多余字符用省略号显示 */
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          color: #1d1b1b;
          margin-right: 5px;
        }
        .attaR_top .atta_size {
          max-width: 70px;
          /**将多余字符用省略号显示 */
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .attaR_bot {
        margin-top: 5px;
        span {
          cursor: pointer;
          margin-right: 16px;
        }
        span:last-child {
          margin-right: 0;
        }
      }
      .mv-att-name:hover,
      .mv-att-down:hover {
        cursor: pointer;
        color: #ff7500;
      }

      .mv-download:hover {
        cursor: pointer;
      }
    }

}
.attachment-item{
        min-width: 290px;
        background: #F9F9F9;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #D7D7D7;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        display: inline-block;
        .attachment-icon{
          position: relative;
          margin-right: 8px;
          .atticon{
            width: 2.6em;
            height: 2.6em;
            margin: 5px 8px;
          }
        }
        .tag{
          background-color: #FF0000;
          display: inline-block;
          color: #fff;
          padding: 1px 4px;
          // font-size: 12px;
          border-radius: 11px;
          line-height: 17px;
          position: absolute;
          top: -11px;
          left: 9px;
        }
        .attachment-info{
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding-right: 3px;
          .title{
            margin-right: 5px;
            display: inline-block;
            max-width: 145px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .size{
            color: #A3A3A3;
          }
          .preview{
            margin-right: 17px;
             cursor: pointer;
          }
          .delete{
            cursor: pointer;
            margin-right: 2px;
          }
        }
      }
      .word-break{
        line-height: 2em;
        word-break: break-all;
      }
</style>
