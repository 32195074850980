// 定义内容
export default {
  login: {
    usermenu: '用户手册',
    admmenu: '管理员手册',
    userlogin: '用户登录',
    admlogin: '管理员登录',
    placeholder1: '请输入邮箱账号',
    placeholder2: '请输入密码',
    placeholder3: '请输入手机号',
    placeholder4: '请输入验证码',
    placeholder5: '请输入PIN码',
    placeholder6: '请输入邮箱账号（如：user@example.com）',
    placeholder7: '请选择问题',
    placeholder8: '请输入答案',
    remmberusername: '记住用户名',
    remmberaccount: '记住账号',
    ssllogin: 'SSL登录',
    forgetpsw: '忘记密码',
    btnText: '登录',
    step1: '输入邮箱账号',
    step2: '验证身份',
    step3: '设置新密码',
    step4: '重置密码成功',
    mailaccount: '邮箱账号',
    resetbtx: '重置',
    confirminfo1: '为确认是你本人操作，请完成身份验证：',
    confirminfo2: '通过密保问题验证：',
    newpsw: '新密码',
    selectq: '选择问题',
    inputa: '输入答案',
    confirmbtx: '确定',
    setsuccess: '设置成功',
    singinagain: '账号密码重置成功，请重新登录。',
    back: '返回登录页',
    q1: '您的出生地是？',
    q2: '您的父亲姓名是？',
    q3: '您的母亲姓名是？',
    q4: '您的配偶姓名是？',
    q5: '您的生日是？',
    q6: '您的工作城市是？',
    q7: '《自定义问题》',
    confirmpsw: '确认密码',
    inputmail: '请输入邮箱地址',
    inputrightmail: '请输入正确的邮箱地址',
    inputcontent: '请输入内容',
    err1: '请输入确认密码',
    err2: '密码长度设置不符合要求',
    err3: '两次密码不一致',
  },
  btnText: {
    save: '保 存',
    cancel: '取 消',
    ok: '确 定',
    info: '提示',
    close: '关 闭',
    swipecode: '向右滑动获取验证码',
  },
  info: {
    m1: '操作成功',
  },
  mainpage: {
    home: '首页',
    mail: '邮件',
    addressbook: '通讯录',
    calendar: '日历',
    netdisk: '网盘',
    setting: '设置',
    lock: '锁屏',
    help: '帮助',
    changepassword: '修改密码',
    logginout: '退出登录',
    authorized: '切换授权邮箱',
    authorizedmy: '返回我的邮箱',
    placeholder: '请输入关键字',
    advanced: '高级搜索',
    quote: '备注',
    to: '至',
    sdate: '开始日期',
    edate: '结束日期',
    togglemannger: '切换企业管理员平台',
  },
  compose: {
    adv1: '主&nbsp;&nbsp;&nbsp;题',
    adv2: '正&nbsp;&nbsp;&nbsp;文',
    adv3: '日&nbsp;&nbsp;&nbsp;期',
    adv4: '备&nbsp;&nbsp;&nbsp;注',
    subject: '主&nbsp;&nbsp;&nbsp;题',
    sub: '主题',
    body: '正文',
    from: '发件人',
    to: '收件人',
    cc: '抄送人',
    bcc: '密送人',
    attsname: '附件名字',
    date: '日期',
    send: '发送',
    timesend: '定时发送',
    settimesend: '设置定时发送',
    savedraft: '存草稿',
    priview: '预览',
    closepeiview: '关闭预览',
    cancel: '取消',
    single: '群发单显',
    unsingle: '取消群发单显',
    contact: '通讯录',
    addBcc: '添加密送',
    unaddBcc: '取消密送',
    addatts: '添加附件',
    noneatts: '暂无附件',
    download: '下载',
    del: '删除',
    goon: '继续',
    scan: '文件扫描中{num}%',
    numatts: '{num}个附件',
    singn: '签名',
    saveto: '保存到"发件箱"',
    emergent: '紧急',
    receipt: '请求回执',
    securemail: '安全邮件',
    secureprop: '安全属性',
    watermark: '企业水印',
    destroy: '阅后即焚',
    setpsw: '设置邮件密码',
    cancelset: '取消设置',
    changeset: '修改设置',
    big: '超大',
    loaclfile: '本地文件',
    myfile: '我的文件',
    netfile: '网盘文件',
    fileman: '附件管理',
    nodate: '暂无数据',
    filetrans: '文件中转站',
    superatt: '超大附件',
    mailpsw: '邮件密码',
    sendto: '发送给',
    atts: '附件',
    viewatts: '查看附件',
    nums: '{num}个',
    more: '更多',
    info1: '收件人将禁止对此邮件内容进行转发、回复、复制、打印、下载等操作 ',
    info2: ' 仅支持对以下类型txt、pdf、 png、 jpe、jpeg、jpg、gif的附件预览，其他类型的附件存在无法查看的情况，请谨慎使用安全属性邮件发送带附件的邮件。',
    info3: '开启阅后即焚后，邮件被阅读一次后，邮件原文将被销毁',
    info4: '设置邮件阅读的有效期，超过时间限制后，此邮件将无法查看',
    info5: '选择邮件密码方式发送邮件，会将整封邮件进行加密处理，需要使用密码才能查阅',
    info6: '密码长度6-32位，数字、字母和特殊字符',
    info7: '邮件密码不能为空',
    info8: '请设置自定义时间',
    info9: ' 您已设置为安全邮件模式',
    info10: '您已设置为安全邮件模式，邮件将被禁止转发、回复、复制、打印、下载等操作，并于{date}过期',
    info11: '正在上传附件，上传完成后将自动发送',
    info12: '取消自动发送',
    info13: '{num}个文件过大，附件将作为云附件上传，',
    info14: '{num}天内有效，若被删除或过期，收件人将无法下载！',
    info15: '请选择',
    info16: '设置成功后，点击写信页的“发送”按钮，邮件将会暂存在草稿箱，并在设定的时间发送',
    info17: '发送时间',
    info18: '选择日期',
    info19: '选择时间',
    info20: '发送时区',
    info21: '选择日期时间',
    info22: '过期时间',
    info23: '"群发单显"采用一对一单独发送，每个收件人只能看到自己的邮箱地址',
    info24: '选择的日期时间已过期',
    info25: '您已设置为安全邮件模式，邮件将以邮件加密的方式发送，收件人需输入密码查阅',
    info26: '附件正在上传，确定中断和放弃对此信件的操作？',
    info27: '已取消定时发送',
    info28: '您设置的过期邮件时间已经过期',
    info29: '邮件密码不能为空',
    info30: '邮件密码长度不能少于6位',
    info31: '邮件密码不合规',
    info32: '您已设置为安全邮件模式，邮件将被禁止转发、回复、复制、打印、下载等操作，并设置了阅后即焚属性',
    info33: '您已设置为安全邮件模式，邮件将被禁止转发、回复、复制、打印、下载等操作，并于{time}过期',
    info34: '您已设置为安全邮件模式，邮件开启了阅后即焚和禁止操作属性，并于{time}过期',
    info35: '您已设置为安全邮件模式，邮件将被禁止转发、回复、复制、打印、下载等操作',
    info36: '此操作将永久删除该文件, 是否继续?',
    info37: '注意：安全邮件中存在部分附件类型可能无法预览。',
    info38: '点此查看 支持预览 的附件类型。',
    info39: '当前暂无附件类型支持预览！',
    day0: '无限制',
    day1: '1天',
    day3: '3天',
    day7: '7天',
    day8: '自定义',
    day9: '3天后',
    day10: '7天后',
    day11: '1天后',
    err1: '请至少要填写一个收件人地址',
    err2: '收件人含有错误的邮件地址',
    err3: '抄送人含有错误的邮件地址',
    err4: '密送人含有错误的邮件地址',
    err5: '发送失败，邮件过期时间早于当前发送时间',
    err6: '您设置的定时发送邮件时间已经过期',
    err7: '未上传附件，请确认是否继续发送？',
    err8: '主题为空，请确认是否继续发送？',
    err9: '正在写信中，关闭前是否保存草稿？',
    err10: '定时发送时间晚于邮件过期时间，邮件将无法正常阅读，确认继续发送吗？',
    err11: '未选择文件',
    err12: '上传失败，附件总大小超过了邮件{num}M限制',
    err13: '禁止上传空文件',
    err14: '无效的邮件地址',
    success: '发送成功',
    success1: '保存草稿成功',
    neverexpire: '永久有效',
    fw: '转发：',
    ry: '回复：',
    label0: '自动',
    label1: 'UTC-12:00 (贝克岛)',
    label2: 'UTC-11:00 (中途岛)',
    label3: 'UTC-10:00 (檀香山)',
    label4: 'UTC-09:30 (马克萨斯群岛)',
    label5: 'UTC-09:00 (安克雷奇)',
    label6: 'UTC-08:00 (洛杉矶)',
    label7: 'UTC-07:00 (丹佛)',
    label8: 'UTC-06:00 (芝加哥)',
    label9: 'UTC-05:00 (纽约)',
    label10: 'UTC-04:00 (圣地亚哥)',
    label11: 'UTC-03:00 (布宜诺斯艾利斯)',
    label12: 'UTC+01:00 (巴黎)',
    label13: 'UTC+03:00 (莫斯科)',
    label14: 'UTC+04:00 (迪拜)',
    label15: 'UTC+05:30 (新德里)',
    label16: 'UTC+07:00 (曼谷)',
    label17: 'UTC+08:00 (北京)',
    label18: 'UTC+09:00 (东京)',
    label19: 'UTC+10:00 (悉尼)',
    label20: 'UTC+12:00 (奥克兰)',
    attinfo: '拖放/粘贴来自网络的图片（crtl+v）/从计算机添加',
    all: '全部文件',
    img: '图片',
    music: '音频',
    video: '视频',
    doc: '文档',
    zip: '压缩包',
    other: '其他',
    mydriev: '我的网盘',
    format: '禁止上传{type}的格式文件',
  },
  homepage: {
    lingchen: '淩晨好！',
    zaoshang: '早上好！',
    shangwu: '上午好！',
    zhongwu: '中午好！',
    xiawu: '下午好！',
    wanshang: '晚上好！',
    unread: '未读邮件',
    stared: '星标邮件',
    notification: '待审核记录',
    track: '邮件追踪',
    maillimit: '邮件上限',
    mailcapacity: '邮件容量',
    tansfercapacity: '中转站容量',
    drivecapacity: '网盘容量',
    limit: '上限',
    used: '已用',
    total: '容量',
    recentlogin: '最近登录',
    recentloginstatus: '最近登录情况',
    lastchangepsw: '上次修改密码',
    lastchangedpsw: '上次修改密码',
    mysetting: '我的设置',
    autoreplaysetting: '自动回复设置',
    clientauthcode: '客户端授权码设置',
    autoforword: '自动转发设置',
    forword: '转发至',
    envelope: '封',
    on: '开启',
    off: '关闭',
    noEmailPermission: '无邮箱权限，无法查看',
  },
  mbox: {
    newmail: '写邮件',
    receiveemail: '收信',
    inbox: '收件箱',
    sentitems: '发件箱',
    drafts: '草稿箱',
    deleteditems: '已删除',
    starred: '星标邮件',
    unread: '未读邮件',
    archive: '归档邮件',
    junkemail: '垃圾邮件',
    emailtracking: '邮件追踪',
    reviewnotifications: '审核通知',
    attachmentmanagement: '附件管理',
    ftStation: '文件中转站',
    myfolders: '我的文件夹',
    labelmail: '标签邮件',
  },
  maillist: {
    delete: '删除',
    deleteper: '彻底删除',
    move: '移动',
    mark: '标记',
    unread: '未读',
    read: '已读',
    stared: '星标',
    unstar: '取消星标',
    top: '置顶',
    untop: '取消置顶',
    tag: '选标签',
    untag: '取消全部标签',
    forword: '转发',
    forwordasatt: '作为附件转发',
    filter: '筛选',
    sort: '排序',
    bytimen2o: '按时间从新到旧',
    bytimeo2n: '按时间从旧到新',
    btfromdesc: '按发件人降序',
    btfromasc: '按发件人升序',
    bysubdesc: '按主题降序',
    bysubasc: '按主题升序',
    bysizedesc: '按邮件大小降序',
    bysizedasc: '按邮件大小升序',
    urgent: '紧急',
    hasatt: '含附件',
    hasnotes: '含备注',
    all: '全部',
    more: '更多',
    download: '下载邮件',
    upload: '上传邮件',
  },
  netdisk: {
    diskshare: '网盘共享',
    diskfshare: '网盘分享',
    dir: '所在目录',
    noname: '无文件名称',
    nofname: '无文件夹名称',
    dayslayer: '天后',
    expired: '已失效',
    folder: '文件夹',
    file: '文件',
    all: '全部文件',
    image: '图片',
    audio: '音频',
    vodeo: '视频',
    doc: '文档',
    zip: '压缩包',
    other: '其他',
    upload: '上传文件',
    createfolder: '新建文件夹',
    delete: '删除',
    move: '移动到',
    sendmail: '发送邮件',
    share: '分享',
    download: '下载',
    rename: '重命名',
    mydrive: '我的网盘',
    filename: '文件名',
    modifiedtime: '修改时间',
    type: '类型',
    size: '大小',
    myshare: '我的分享',
    used: '已用网盘容量',
    total: '共',
    Unshare: '取消分享',
    copylink: '复制链接',
    sharename: '分享文件名',
    sharetime: '分享时间',
    expiretime: '失效时间',
    unexpire: '有效期',
    sharefile: '分享文件',
    crearelink: '创建链接',
    checktimes: '查看次数',
    downloadtimes: '下载次数',
    confirmselfiles: '确认删除所选文件吗?',
    movetof: '移动到文件夹',
    copysend: '复制去发邮件',
    copylinkcode: '复制链接及提取码',
    mv: '移动',
    result: '搜索结果',
    namenotnull: '名称不能为空',
    canceldel: '已取消删除',
    delsuccess: '删除成功',
    cresuccess: '新建文件夹成功',
    mvsuccess: '移动成功',
    resuccess: '重命名成功',
    link: '链接',
    code: '提取码',
    info1: '复制链接成功',
    info2: '取消分享后，所选链接将无法访问及下载，确认取消分享吗？',
    info3: '已取消操作',
    info4: '取消分享成功',
    info5: '分享的文件已取消或已过期',
    day1: '1天',
    day2: '7天',
    day3: '30天',
    day4: '永久有效',
    err1: '请选择文件',
    err2: '目标目录不能为空',
    err3: '不支持 / : * ? < > |',
    err4: '请选择文件或文件夹',
    err5: '请选择下载文件',
    err6: '仅支持选择文件',
    err7: '所选文件大小超过200M，请分批或单个下载',
    filenum: '等{num}个文件',
    drivesharelink: '网盘共享链接',
    sharepsw: '请输入共享密码',
  },
  contact: {
    group: '所属组',
    enpcontact: '企业通讯录',
    orgcontact: '组织通讯录',
    percontact: '个人通讯录',
    commoncontact: '常用联系人',
    maillist: '邮件列表',
    maillistname: '邮件列表名称',
    allcontact: '全部联系人',
    ungroup: '未分组',
    newcontact: '新建联系人',
    editcontact: '编辑联系人',
    newgroup: '新建联系组',
    newfenzu: '新建分组',
    editgroup: '编辑联系组',
    delgroup: '删除联系组',
    importcontact: '导入联系人',
    outcontact: '外部联系人',
    info1: '此分组暂无联系人',
    info2: '暂无联系人',
    info3: '确认删除',
    info4: '删除分组后，组内联系人将被解散至全部联系人',
    info5: '同步删除通讯录中属于此分组的联系人',
    info6: '删除联系组成功',
    info7: '请输入组名称',
    info8: `显示名称不支持英文分号、单引号、双引号、<、>、/、\\%&......￥特殊字符`,
    info9: '组名不合规',
    info10: '创建成功',
    info11: '请输入正确的联系方式',
    info12: '显示名称不能为空',
    info13: '邮箱地址不能为空',
    info14: '请输入正确的邮箱地址',
    info15: '显示名称不合法',
    info16: '编辑成功',
    info17: '请输入分组名称',
    info18: '复制成功',
    info19: '是否删除联系人{person} ？',
    info20: '已选中{num}个联系人，请确认是否删除？',
    info21: '已选中{num}个联系人，是否在分组内删除？',
    info22: '已添加{num}人到个人通讯录',
    more: '更多',
    retract: '收起',
    select: '选择',
    unselect: '取消选择',
    allselect: '全选',
    num: '{num}个联系人',
    addperon: '添加到个人通讯录',
    tocompose: '去写信',
    depart: '所属部门',
    mailaddress: '邮箱地址',
    crossmail: '往来邮件',
    compose: '写信',
    edit: '编辑',
    del: '删除',
    mv: '移动',
    copy: '复制',
    export: '导出',
    copymail: '复制邮箱地址',
    ckcrossmail: '查看往来邮件',
    selectgroup: '选择分组',
    displayname: '显示名称',
    phone: '联系方式',
    addphone: '添加联系方式',
    quote: '备注',
    unit: '单位',
    noresult: '暂无搜索结果',
    placeholder1: '输入分组名称',
    placeholder2: '请输入联系组名称',
    member: '成员',
    view: '查看',
    unview: '不允许查看',
    memberlist: '邮件成员列表',
    dynamiclist: '动态成员',
    designlist: '指定成员',
    externallist: '外部成员',
    departmail: '部门邮箱',
    importgroup: '导入到组',
    selectimportfile: '选择要导入的文件',
    repeatmail: '如遇到地址重复的记录',
    selectfile: '选择文件',
    tips: '请按照模板格式导入，只支持.csv格式文件。',
    downtips: ' 下载CSV格式文档',
    unimport: '不导入',
    repeat: '覆盖',
    reflect: '请做字段映射',
    selcctreflect: '请选择映射',
    importsuccess: '导入完成',
    gocheck: '去查看',
    konw: '我知道了',
    imported: '成功导入{num}条,',
    conflicts: '地址冲突{num}条,',
    error1: '格式错误{num}条。',
    error2: '下载格式错误名单',
    error3: '请选择上传文件！',
    error4: '请选择联系组',
    error5: '邮件格式错误列表',
    error6: '只支持.csv格式的文档',
    importinfo1: '导入成功',
    importinfo2: '地址冲突',
    importinfo3: '格式错误',
    clear: '清除全部',
    addto: '添加到',
    placeholder: '输入姓名/邮件地址搜索联系人',
    participant: '参会人',
  },
  setting: {
    opnetdisk: '操作网盘',
    optranfer: '操作中转站',
    tipsset: '提醒设置',
    mailmove: '邮箱搬家',
    accountsec: '账号安全',
    changepsw: '修改密码',
    changeque: '修改密保',
    checklog: '自助日志查询',
    auditlog: '审核记录查询',
    clientset: '客户端配置',
    auth: '授权管理',
    mailset: '邮箱设置',
    displayset: '显示设置',
    sendreve: '发信和收信设置',
    revrule: '收信规则',
    quickreply: '快速回复',
    autoreply: '自动回复',
    autoforword: '自动转发',
    blacklist: '黑名单',
    whitelist: '白名单',
    signset: '签名设置',
    tagset: '标签管理',
    floderset: '文件夹设置',
    notify: '消息通知',
    general: '通用设置',
    skinset: '皮肤设置',
    emaiPassword: '邮箱密码',
    oldpsw: '旧密码',
    new: '新密码',
    confirmpsw: '确认密码',
    info1: '修改密码成功',
    info2: '修改密保前请输入邮箱登录密码验证您的身份',
    info3: '修改密保成功',
    err1: '请输入初始密码',
    err2: '请输入新密码',
    err3: 'ip地址不合规',
    operator: '操作者',
    operatorip: '操作IP',
    operatortime: '操作时间',
    operatortype: '操作类型',
    operatorcontent: '操作内容',
    clicksearch: '点此搜索',
    viewlog: '日志记录查询',
    p1: '请选择操作类型',
    operate1: '登录',
    operate2: '退出',
    operate3: '发送邮件',
    operate4: '阅读邮件',
    operate5: '操作邮件',
    operate6: '操作通讯录',
    operate7: '操作附件',
    operate8: '设置属性',
    operate9: '风险操作',
    operate10: '切换授权邮箱',
    operate11: '召回邮件',
    operate12: '审核邮件',
    operate13: '删除邮件',
    fzset: '字体大小设置',
    timeformat: '时间显示格式',
    h12: '12小时制',
    h24: '24小时制',
    langset: '语言设置',
    zh: '简体中文',
    zhf: '繁体中文',
    en: 'English',
    saveinfo: '保存成功',
    skinstyle: '皮肤风格',
    sk01: '默认',
    sk02: '皮肤02',
    sk03: '皮肤03',
    sk04: '皮肤04',
    sk05: '皮肤05',
    sk06: '皮肤06',
    sk07: '皮肤07',
    sk08: '皮肤08',
    sk09: '皮肤09',
    sk10: '皮肤10',
    sk11: '皮肤11',
    sk12: '皮肤12',
    sk13: '皮肤13',
    sk14: '皮肤14',
    sk15: '皮肤15',
    sk16: '皮肤16',
    newnotice: '新邮件到达通知',
    range: '通知范围',
    mbox: '收件箱',
    allmail: '所有邮件',
    notifytype: '通知方式',
    webpropmpt: 'web弹窗',
    audittime: '审核日期',
    from: '发件人',
    subject: '主题',
    auditrecords: '审核记录',
    auditdetail: '审核详情',
    viewdetail: '查看详情',
    audit: '待审核',
    approved: '审核通过',
    reject: '审核拒绝',
    contain: '包含',
    thename: '发件人',
    thesubject: '主题',
    record: '的记录',
    clientcode: '客户端授权码设置',
    usercode: '使用客户端授权码登录',
    clientinfo1: '开启客户端授权登录码后，邮箱密码将无法在登录客户端时使用',
    clientinfo2: '*客户端授权码用于登录第三方邮件客户端的专用密码，适用于登录以下服务:POP3/SMTP/IMAP服务。',
    clientinfo3: '新增授权码',
    clientinfo4: '开启客户端授权码登录后，仅能使用客户端授权码登录客户端，将无法使用邮箱密码登录，确认开启吗?',
    clientinfo5: '关闭客户端授权码登录后，所有客户端授权码将失效，同时恢复邮箱密码登录客户端权限，确认关闭吗?',
    clientinfo6: '* 管理员已开启客户端授权码登录，必须设置客户端授权码才能使用客户端收发邮件。',
    clientinfo7: '(最多可添加5个授权码)',
    clientinfo8: '设置客户端授权码',
    clientinfo9: '客户端授权码仅显示一次，请手工复制或者点击下方按钮复制授权码',
    clientinfo10: '使用设备名称',
    clientinfo11: '客户端授权码',
    clientinfo12: '确定并复制授权码',
    clientinfo13: '开启客户端授权码登录后，仅能使用客户端授权码登录客户端，将无法使用邮箱密码登录，确认开启吗？',
    clientinfo14: '关闭客户端授权码登录后，所有客户端授权码将失效，同时恢复邮箱密码登录客户端权限，确认关闭吗？',
    clientname: '设备名称',
    clicenttime: '启用时间',
    clientoperate: '操作',
    clientdel: '删除',
    error1: '设备名称不能为空',
    error2: '添加成功',
    error3: '最多可添加5个授权码',
    error4: '最多可添加5条授权管理',
    error5: '请选择时间段',
    selectauthobj: '选择授权对象',
    addobj: '添加',
    selectauth: '权限选择',
    placeholder1: '请选择权限',
    placeholder2: '读信权限:(仅可阅读邮件)',
    placeholder3: '写信权限:(在阅读权限基础上，增加写信、回复和转发权限)',
    placeholder4: '管理权限:(可对设置之外所有功能模块进行操作)',
    placeholder5: '授权有效期',
    radio1: '长期有效',
    radio2: '30天',
    radio3: '1天',
    radio4: '自定义日期',
    radio5: '7天',
    authobj: '授权对象',
    author: '授权权限',
    authtime: '生效时间',
    authexpire: '失效时间',
    edit: '编辑',
    au: '授权',
    tipsset1: '异地登录提醒',
    tipsset2: '开启后，账号出现异地登录时，系统将发送邮件提醒',
    tipsset3: '修改密码提醒',
    tipsset4: '开启后，账号密码被修改时，系统将发送邮件提醒',
    tipsset5: '自动转发提醒',
    tipsset6: '开启后，账号设置自动转发时，系统统将发送邮件提醒',
    tipsset7: '客户端授权码设置提醒',
    tipsset8: '开启后，账号设置客户端授权时，系统统将发送邮件提醒',
    mailmove1: '管理端设置使用',
    mailmove2: '进行邮箱迁移',
    mailmove3: '请输入原邮箱',
    mailmove4: '原系统邮箱地址',
    mailmove5: '原系统邮箱密码',
    mailmove6: '请确保输入信息的准确性，否则可能影响迁移结果。',
    mailmove7: '提交原邮箱账号密码后，迁移进度由系统管理员处理，如有紧急迁移需求，请直接联系管理人员。',
    mailmove8: 'IMAP协议',
    mailmove9: 'IMAP授权码',
    mailmove10: 'POP3协议',
    mailmove11: 'POP3授权码',
    mailmove12: 'Exchange协议',
    mailmove13: 'Exchange授权码',
    // 显示设置
    disset: '启动页',
    disset1: '启动打开首页',
    disset2: '启动打开邮箱',
    disset3: '邮件视图',
    disset4: '列表模式',
    disset5: '分栏模式',
    disset6: '邮件摘要',
    disset7: '显示邮件摘要',
    disset8: '邮件显示',
    disset9: '收发件人显示格式',
    disset10: '设置邮箱列表、读信和写信页面收发件人的显示方式',
    disset11: '显示内嵌图片',
    disset12: '每页邮件条数',
    disset13: '恢复上次打开的标签页',
    disset14: '意外退出后，再次登录时支持自动回复标签页',
    disset15: '显示附件图片',
    disset16: '姓名+所属部门',
    disset17: '姓名+邮件地址',
    disset18: '总是',
    disset19: '来自通讯录的邮件',
    disset20: '从不',
    // 文件夹设置
    floderset1: '新建文件夹',
    floderset2: '文件夹名称',
    floderset3: '邮件数量',
    floderset4: '显示',
    floderset5: '操作',
    floderset6: '清空邮件',
    floderset7: '重命名',
    floderset8: '删除',
    floderset9: '请确认是否删除此文件夹？请输入安全码：',
    floderset10: '文件夹有{num}封邮件，请确认是否清空？请输入安全码：',
    floderset11: '上级文件夹',
    floderset12: '我的文件夹',
    floderset13: '文件夹中仍存在邮件，删除前请清空文件夹',
    floderset14: '安全码输入错误',
    floderset15: '删除文件夹成功',
    floderset16: '邮件夹清空成功',
    floderset17: '邮件夹重命名成功',
    floderset18: '请输入文件夹名称',
    floderset19: '新建文件夹成功',
    // 标签管理
    tagset1: '新建标签',
    tagset2: '请输入内容',
    tagset3: '标签颜色',
    tagset4: '确定删除标签：{name}?',
    tagset5: '请输入标签名称',
    tagset6: '请选择标签颜色',
    tagset7: '暂无标签',
    tagset8: '标签名称',
    tagset9: '未读数/邮件总数',
    tagset10: '编辑',
    tagset11: '删除',
    tagset12: '编辑标签',
    tagset13: '删除成功',
    tagset14: '操作成功!',
    // 收信规则
    revrule1: '新建收信规则',
    revrule2: '规则名称',
    revrule3: '状态',
    revrule4: '编辑',
    revrule5: '删除',
    revrule6: '暂无收信规则',
    revrule7: '状态',
    revrule8: '收信规则名称',
    revrule9: '条件类型',
    revrule10: '触发条件',
    revrule11: '执行动作',
    revrule12: '彻底删除 ',
    revrule13: '（彻底删除邮件无法恢复，请谨慎选择）',
    revrule14: '满足所有条件',
    revrule15: '满足任一条件',
    revrule16: '请输入规则名称',
    revrule17: '移动到',
    revrule18: '执行此规则后，不再执行后续过滤规则',
    revrule19: '收件人',
    revrule20: '发件人',
    revrule21: '主题',
    revrule22: '包括',
    revrule23: '不包括',
    revrule24: '请输入触发条件',
    revrule25: '请选择',
    revrule26: '请确认是否删除此规则',
    revrule27: '输入',
    revrule28: '地址不合规',
    revrule29: '请选择执行动作',
    revrule30: '请选择移动到',
    // 快速回复
    quickreply1: '输入回复内容',
    quickreply2: '添加到快捷回复列表',
    quickreply3: '规则名称',
    quickreply4: '编辑',
    quickreply5: '删除',
    quickreply6: '不支持添加空数据',
    // 发信和收信设置.
    mailset1: '发信设置',
    mailset2: '保存到发件箱',
    mailset3: '自动保存到草稿箱',
    mailset4: '从不',
    mailset5: '1分钟',
    mailset6: '3分钟',
    mailset7: '5分钟',
    mailset8: '10分钟',
    mailset9: '回复主题前缀',
    mailset10: '回复：',
    mailset11: 'Re:',
    mailset12: '转发主题前缀',
    mailset13: '转发：',
    mailset14: 'Fw:',
    mailset15: '回复时加入原文',
    mailset16: '自动添加收件人到通讯录',
    mailset17: '附件提醒关键字',
    mailset18: '默认请求回执',
    mailset19: '写信字体设置',
    mailset20: '收信设置',
    mailset21: '收信阅读回执',
    mailset22: '手动选择',
    mailset23: '全部忽略',
    mailset24: '自动回复',
    mailset25: '归档设置周期',
    mailset26: '7天',
    mailset27: '15天',
    mailset28: '30天',
    mailset29: '不归档',
    mailset30: '多个关键字之间用英文逗号分割',
    mailset31: 'Webmail发信将被保存到发件箱 ',
    mailset32: '默认归档“收件箱”内邮件',
    // 自动回复
    autoreply1: '启用自动回复',
    autoreply2: '回复内容',
    autoreply3: '在以下时间内启用自动回复',
    autoreply4: '选择开始日期时间',
    autoreply5: '选择结束日期时间',
    autoreply8: '仅对企业内联系人进行自动回复',
    autoreply9: '请正确设置启用自动回复的开始时间和结束时间',
    autoreply10: '结束时间早于开始时间',
    autoreply11: '开始时间不能大于结束时间',
    autoreply12: '自动回复内容不能为空',
    // 自动转发
    autofwd1: '启用自动转发',
    autofwd2: '在以下时间内启用自动转发',
    autofwd3: '自动转发地址',
    autofwd4: '地址{num}',
    autofwd5: '自动转发后在此邮箱删除来信',
    autofwd6: '请正确设置启用自动转发的开始时间和结束时间',
    autofwd7: '开始时间不能大于结束时间',
    autofwd8: '请至少输入一个邮件地址',
    // 黑名单
    blacklist1: '输入邮箱地址',
    blacklist2: '添加',
    blacklist3: '输入邮箱地址或域名后缀，添加后将拒收该地址来信。如：mail@example.com,example.com',
    blacklist4: '黑名单列表',
    blacklist5: '删除',
    blacklist6: '请确认是否将此邮件地址移除，不再拦截此地址的邮件？',
    blacklist7: '邮箱不能为空',
    blacklist8: '邮箱格式错误',
    blacklist9: '输入关键字搜索',
    // 白名单
    whitelist1: '输入邮箱地址',
    whitelist2: '添加',
    whitelist3: '输入邮箱地址或域名后缀，添加后则安全信任该地址来信。如：mail@example.com,example.com',
    whitelist4: '白名单列表',
    whitelist5: '删除',
    whitelist6: '删除后不再放行此地址邮件，请确认是否删除？',
    whitelist7: '邮箱不能为空',
    whitelist8: '邮箱格式错误',
    whitelist9: '输入关键字搜索',
    // 签名设置
    signature1: '新建签名',
    signature2: '签名名称',
    signature3: '应用于新邮件',
    signature4: '应用于转发/回复',
    signature5: '签名位置',
    signature6: '操作',
    signature7: '预览',
    signature8: '编辑',
    signature9: '删除',
    signature10: '暂无签名',
    signature11: '请输入签名名称',
    signature12: '设为默认用于新邮件',
    signature13: '设置默认用于回复/转发',
    signature14: '签名位置',
    signature15: '在回复转发引用内容上方',
    signature16: '在回复转发引用内容下方',
    signature17: '签名预览',
    signature18: '关闭预览',
    signature19: '请确认是否删除此签名？',
    signature20: '请输入签名名称',
    signature21: '新建成功',
    signature22: '更新成功',
    signature23: '此签名处于使用中，请确认是否删除？',
    signature24: '编辑签名',
    signature25: '定时发送',
    signature26: '存草稿',
    signature27: '预览',
    signature28: '取消',
    signature29: '邮件主题',
    signature30: '收件人：xxx',
    signature31: '邮件正文部分示例样式',
    signature32: '引文部分',
    signature33: '发送',
    signature34: '引用内容上方',
    signature35: '引用内容下方',
    signature36: '新邮件',
  },
  calendar: {
    view_link: '查看日历链接',
    view_indo: '日历链接用于挂载到第三方客户端上查看共享日历',
    cal_link: '日历链接',
    share_link: '分享链接',
    change_auth: '修改权限',
    cal1: '{year}年{month}月',
    cal2: '今天',
    cal3: '日',
    cal4: '周',
    cal5: '月',
    cal6: '日程分享',
    cal7: '日程提醒',
    cal8: '主题',
    cal9: '地点',
    cal10: '时间',
    cal11: '开始时间',
    cal12: '暂无提醒',
    cal13: '忽略全部',
    cal14: '稍后提醒',
    cal15: '查看日历',
    cal16: '5分钟前',
    cal17: '10分钟前',
    cal18: '15分钟前',
    cal19: '1小时前',
    cal20: '2小时前',
    cal21: '6小时前',
    cal22: '12小时前',
    cal23: '1天前',
    cal24: '30分钟前',
    cal25: '18小时前',
    cal26: '2天前',
    cal27: '操作成功',
    cal28: '(无主题)',
    cal29: '转发会议',
    cal30: '提醒',
    cal31: '组织者',
    cal32: '参会者',
    cal33: '会议已过期',
    cal34: '接受',
    cal35: '{num}人',
    cal36: '暂定',
    cal37: '拒绝',
    cal38: '查看参会者响应',
    cal39: '日期',
    cal40: '姓名',
    cal41: '查看详情',
    cal42: '删除',
    cal43: '回复组织者',
    cal44: '回复全部',
    cal45: '回复组织者',
    cal46: '取消会议',
    cal47: '编辑',
    cal48: '无',
    cal49: '一天前',
    acbtn1: '接受',
    acbtn2: '已接受',
    acbtn3: '已拒绝',
    acbtn4: '会议已取消',
    acbtn5: '重新选择',
    acbtn6: '已',
    acbtn7: '现在发送响应',
    acbtn8: '编辑后发送响应',
    acbtn9: '不发送响应',
    more: '更多',
    fullcal: '未响应',
    info1: '日程信息',
    info2: '会议邀请',
    del1: '删除成功',
    del2: '删除失败',
    confirm: '确定要删除此日程吗?',
    confirm2: '会议取消成功',
    info3: '新建活动',
    info4: '新建日程',
    info5: '新建会议',
    info6: '我的日历',
    info7: '暂无日程',
    info8: '请输入标签名称',
    info9: '重命名',
    info10: '订阅日历',
    info11: '暂无订阅',
    info12: '分享来源',
    info13: '创建日程',
    info14: '删除订阅',
    info15: '发送会议',
    info16: '保存日程',
    info17: '切换日程',
    info18: '切换会议',
    info19: '更改会议',
    info20: '更改日程',
    info21: '参会人',
    info22: '通讯录',
    info23: '主&nbsp;&nbsp;&nbsp;题',
    info24: '地&nbsp;&nbsp;&nbsp;点',
    info25: '时&nbsp;&nbsp;&nbsp;间',
    info26: '开始日期',
    info27: '结束日期',
    info28: '至',
    info29: '全天',
    info30: '忙&nbsp;&nbsp;&nbsp;闲',
    info31: '空闲',
    info32: '忙碌',
    info33: '暂定',
    info34: '外出',
    info35: '在其他地方工作',
    info36: '提&nbsp;&nbsp;&nbsp;醒',
    info37: '请选择',
    info38: '关闭活动周期',
    info39: '设置活动周期',
    info40: '添加附件',
    info41: '暂无附件',
    info42: '{num}个附件',
    info43: '超大',
    info44: '下载',
    info45: '继续',
    info46: '我收到的分享',
    info47: '我发起的分享',
    info48: '返回日历',
    info49: '请输入分享成员姓名或邮箱',
    info50: '分享成员',
    info51: '分享权限',
    info52: '操作',
    info53: '删除',
    info54: '添加分享成员',
    info55: '删除全部',
    info56: '返回',
    info57: '日历名称',
    info58: '分享统计',
    info59: '已分享{num}人',
    info60: '详情',
    info61: '全部忽略',
    info62: '分享来源',
    info63: '分享范围',
    info64: '权限等级',
    info65: '忽略',
    info66: '完整编辑',
    info67: '选中成员',
    info68: '输入日历名称',
    info69: '文件扫描中{num}%',
    info70: '分享详情',
    info71: '查看忙闲',
    info72: '查看日程详情',
    info73: '可编辑',
    info74: '标签颜色不能为空',
    info75: '请输入日历名称',
    info76: '会议变更',
    info77: '日程时间不能为空',
    info78: '更改会议成功',
    info79: '确定要取消此会议吗',
    info80: '您未填写主题，是否继续保存?',
    info81: '时间不能为空',
    info82: '您未填写主题，是否继续发送？',
    info83: '请选择分享成员',
    info84: '编辑成功',
    info85: '编辑失败',
    info86: `不支持, % ' " \\ / ；| &.* 等特殊字符`,
    info87: '没有权限创建日程',
    info88: '请确认是否删除${mail}的订阅日历？删除后无法继续查看此日历。',
    info89: '确认删除',
    info90: '该成员已添加',
    info91: '删除日历时将同步清空日历下的日程，是否继续？',
    info92: '日程标题',
    info93: '快速编辑仅支持100字以内文本输入',
    info94: '新建会议成功',
    info95: '新建日程成功',
    info96: '变更日程成功',
    cyle1: '重复模式',
    cyle2: '每天',
    cyle3: '每周',
    cyle4: '每月',
    cyle5: '每年',
    cyle6: '每',
    cyle7: '天',
    cyle8: '每个工作日',
    cyle9: '周的',
    cyle10: '月的',
    cyle11: '第',
    cyle12: '个',
    cyle13: '年的',
    cyle14: '月',
    cyle15: '日',
    cyle16: '结束日期',
    cyle17: '永不过期',
    cyle18: '星期一',
    cyle19: '星期二',
    cyle20: '星期三',
    cyle21: '星期四',
    cyle22: '星期五',
    cyle23: '星期六',
    cyle24: '星期日',
    cyle25: '请填写完整活动周期',
    cyle26: '请填写活动周期结束日期',
    cyle27: '无主题',
  },
  upload: {
    info1: '还有附件没上传完毕.请等待上传完毕或取消,然后再试.',
    info2: '请至少要填写一个参会人',
    info3: '参会人含有错误的邮件地址',
  },
  list: {
    mail_quote: '邮件备注',
    input_content: '请输入内容',
    mail_quote_add_success: '邮件备注添加成功',
    mail_quote_del_success: '您已成功删除邮件备注',
    INBOX: '收件箱',
    Sent: '发件箱',
    Drafts: '草稿箱',
    trash: '已删除',
    start: '星标邮件',
    unseen: '未读邮件',
    archive: '归档邮件',
    rubbishs: '垃圾邮件',
    tracking: '邮件追踪',
    audit: '审核通知',
    attachment: '附件管理',
    fileWebDisk: '文件中转站',
    file_transfer_opc: '文件中转站空间',
    file_num: '文件数',
    used: '使用量',
    save_time: '存放时间',
    my_folder: '我的文件夹',
    tag_mail: '标签邮件',
    auth_no_compose: '权限不符，无法写信',
    not_move_folder: '不能移动到此文件夹',
    close_tag: '关闭全部标签',
    no_subject: '无主题',
    income_out_mail: '往来邮件',
    write_mail: '写邮件',
    composing_close_save_draft: '正在写信中，关闭前是否保存草稿？',
    forever_time: '永久有效',
    num_day: '{num} 天',
  },
  view: {
    not_support_type: '此文件类型不支持预览',
    loading: '拼命加载中',
    no_subject: '无主题',
    time: '时间',
    replace_send: '由{mail}代发',
    expand: '展开',
    close: '收起',
    send_to: '发送给',
    num_person: '等{num}人',
    to: '收件人',
    cc: '抄送人',
    bcc: '密送人',
    att: '附件',
    view_att: '查看附件',
    have_att: '{num}个',
    etc: '等',
    mail_expired: '此邮件已过期',
    mail_will_expired: '此邮件将于{date}过期',
    wait_audit: '等待{mail}审核',
    wait: '等待',
    audit: '审核',
    income_track_view: '（点击进入邮件追踪页面查看）',
    click_view_status: '（点击按钮可查看邮件状态)',
    click_view_and_recall: '（点击按钮可查看邮件状态并召回邮件）',
    withdraw: '撤回',
    risk_info: '风险提示',
    warn_info: '警惕！此邮件为外来邮件，可能存在风险，请谨慎对待!',
    mail_psw_info: '这是一封加密邮件，收件人需要解密后才能查看邮件内容，邮件密码：{psw}',
    secrity: '这是一封安全邮件，收件人将禁止对此邮件内容进行转发、回复、复制 、打印、下载等操作',
    and: '并于',
    expired: '过期',
    and_view_once: '并且仅能查阅一次',
    total: '共',
    pack_download: '打包下载',
    no_att_name: '无附件名称',
    priview: '预览',
    download: '下载',
    save_drive: '转存网盘',
    back_audit: '返回到审核通知',
    input_content: '请输入内容',
    more_replay: '更多快捷回复内容',
    metting_num: '参会人数',
    invite_metting: '邀请您参加会议',
    have_people: '{num}人',
    location: '地 点',
    metting_expire: '会议已过期',
    cancel_metting: '取消会议',
    al_cancel_metting: '会议已取消',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sep: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    confirm_cancel_metting: '请确认是否取消会议?',
    back: '返回',
    edit_again: '再次编辑',
    reply: '回复',
    reply_all: '回复全部',
    reply_all_att: '回复全部带附件',
    fw: '转发',
    as_att_fw: '作为附件转发',
    mark: '标记',
    unread: '未读',
    read: '已读',
    flagged: '星标',
    cancel_flagged: '取消星标',
    top: '置顶',
    un_top: '取消置顶',
    select_tag: '选标签',
    cacel_all_tag: '取消全部标签',
    move: '移动',
    del: '删除',
    real_del: '彻底删除',
    more: '更多',
    copy: '复 制',
    down_mail: '下载邮件',
    print: '打印邮件',
    look: '查看信头',
    new_tab_view: '新标签页打开',
    cancel_tag_success: '取消全部标签成功',
    real_del_not_find: '确认要彻底删除吗？彻底删除后邮件将无法找回',
    cancel_del: '已取消删除',
    set_destory_not_view: '本封邮件设置了阅读销毁，无法查看信头',
    copy_success: '复制成功',
    audit_oponion: '审核意见区',
    no_audit_info: '暂无审核信息',
    pass: '通过',
    reject: '拒绝',
    baomi: '保密办',
    level_audit: '{num}级审核人',
    go_audit: '去审核',
    test_info: '测试提示！',
    p1: '亲爱的小伙伴，很抱歉，你已中招！',
    p2: '这是一封安全测试邮件，当你看到此页面时，说明你的安全意识相当薄弱，属于高危人群。当你收到任何来源不明，包含链接或附件的邮件时，注意高度警惕，不要轻易打开。如果无法判断，请联系信息中心或安全部门。',
    p3: '请观看【邮件安全学习】视频',
    test_title: '这是一封邮件安全意识测试邮件！',
    guanbi: '关闭',
    receipt_tip: '回执提醒',
    is_send_receipt: '发件人已要求发送该电子邮件的已读回执，是否发送“已读”回执？',
    mail_view_receipt: '邮件阅读回执',
    subject: '主题',
    not_support: '您的浏览器不支持 video 标签。',
    attack_info: '很遗憾，被钓鱼邮件攻击啦╮(╯▽╰)╭  快来学习一下吧！学习完成后可以关闭窗口，恢复邮箱使用。',
    mail_already_set_psw: '此邮件设置了过期时间，现已过期，无法阅读',
    warning: '警告',
    konw: '我知道了',
    psw_error: '密码错误!',
    code_already_send: '验证码通知信已发送，请注意查收',
    subject_mail_view_rec: '主题：邮件阅读回执：',
    nail_view_rec_under: '邮件阅读回执：您的以下邮件',
    at_time_read: '该收件人已经在{date}阅读。',
    quick_replay: '快捷回复给',
    quick_replay_all: '快捷回复全部',
    quick_rq: '快捷回复',
    mark_mail_ok: '标记为{flag}邮件成功',
    me: '我',
    replay_ok: '回复已成功',
    risk_mail: '此邮件来自可疑的发信地址，访问附件可能造成操作风险，是否仍要继续？',
    strange_mail: '此邮件来自陌生邮件地址，访问附件可能造成操作风险，是否仍要继续？',
    risk_mail_save_drive: '此邮件来自可疑的发信地址，保存网盘可能造成操作风险，是否仍要继续？',
    strange_mail_save_drive: '此邮件来自陌生邮件地址，保存网盘可能造成操作风险，是否仍要继续？',
    mail_auditing_withdraw: '邮件等待审核中，请确认是否要撤回邮件',
    withdraw_ok_move_draft: '撤回成功，邮件移至草稿箱',
    risk_mail_tolink: '此邮件来自可疑的发信地址，访问链接可能造成操作风险，是否仍要继续？',
    strange_mail_tolink: '此邮件来自陌生邮件地址，访问链接可能造成操作风险，是否仍要继续？',
    add_new_contact: '新建联系人',
    edit_contact: '编辑联系人',
    destory_mail_once_read: '此封邮件是阅后即焚邮件，仅能阅读一次，请仔细阅读',
    secrity_mail: '安全邮件',
    reading_mail_rec_code: '您正阅读安全邮件，{val}将会接收到验证码邮件',
    send_msg_code: '发送验证码通知信',
    psw: '密码',
    input_psw: '请输入邮件密码',
    ok: '好的',
    copy_that: '收到',
    thanks: '谢谢',
    mail_quote: '邮件备注',
    mail_quote_add_success: '邮件备注添加成功',
    mail_quote_del_success: '您已成功删除邮件备注',
    save_to: '保存到',
    sace_drive_ok: '成功保存至网盘',
    go_look: '前往查看',
    my_drive: '我的网盘',
    select_save_att: '请选择需要保存的附件',
    add_contact: '添加到通讯录',
    refuse_mail: '拒收此人',
    refuse_mail_not_rec: '拒收后无法收取该地址发送的邮件，请确认！',
    add_ok: '添加成功!',
    add_tag: '新建标签',
    confirm: '确定',
    input_tag: '请输入标签',
    tag_color: '标签颜色',
    input_tag_name: '请输入标签名称',
    input_tag_color: '请选择标签颜色',
    max_five_tag: '最多添加5个标签',
    less_one_tag: '请最少选择一个标签',
    mail_reccall: '邮件召回',
    recieve_status: '接收状态',
    recacll: '召回',
    recall_info: '注: 仅可召回48小时内发出的邮件',
    recall_one_key: '一键召回',
  },
  mailstatus: {
    'ok': '成功',
    'sendstate': '发送状态',
    'deepsearch': '邮件深度追踪',
    'succeed': '发送成功',
    'nosucceed': '发送不成功',
    'checkdetails': '查看详情',
    'succeedorsend': '发送不成功或正在发送',
    'sending': '请到邮件追踪查询邮件状态',
    'insend': '正在发送',
    'abnormal': '出现异常，请重试',
    'hidden': '隐藏',
    'show': '显示',
    'addressee': '收件人',
    'canyourecall': '能否召回',
    'time': '时间',
    'madereading': '已阅读',
    'recall': '召回',
    'nosysadmemail': '非本系统邮箱，无法召回',
    'thenrecallto': '已经被召回',
    'thenrecall': '已召回',
    'receivenoread': '已收到未阅读',
    'recallfall': '召回失败',
    'recallsucceed': '召回成功',
    'recallfallwitting': '召回失败，对方已知晓',
    'inaudit': '正在审核',
    'nopass': '审核未通过',
    'mailaudit_known': '已知晓',
    'mailaudit_read': '已阅读',
    'mailaudit_delete': '删除',
    'mailaudit_reply': '回复',
    'mailaudit_mark': '加标记',
    'mailaudit_mark_draft': '标记为草稿',
    'mailaudit_mark_unread': '标记为未读',
    'mailaudit_mark_delete': '标记为未删除',
    'mailaudit_mark_reply': '标记为未回复',
    'mailaudit_cancel_mark': '取消加标记',
    'mailaudit_cancel_mark_draft': '取消标记为草稿',
    'mailaudit_pop_receive_del': 'POP收取并删除',
    'mailaudit_pop_receive': 'POP收取',
    'mailaudit_recall_succeed': '召回成功',
    'mailaudit_rule_nopass': '发送不成功（系统规则不符）',
    'mailaudit_rule_bounce': '规则退信',
    'Auditing': '等待审核',
    'AuditRefused': '规则拒绝投递（审核规则拒绝投递）',
    'HasAudited': '审核中',
    'AuditCallbacked': '已撤回',
    'deferred': '临时递送错误，会再次尝试',
    'bounced': '退信',
    'CallBack': '已撤回',
    'reject': '规则拒收',
    'listallrecvrej': '收发策略退信',
    'listallsecrej': '密级策略退信',
    'domainrej': '发件人不属于本列表域退信',
    'listrej': '列表退信',
    'specrej': '发件人不允许给列表发信退信',
    'listnull': '列表为空退信',
    'undefpol': '列表未定义任何策略退信',
    'RaisingRefused': '临时提权规则拒绝投递',
    'SecretRefused': '密级规则拒绝投递',
    'list_persecrej': '成员密级不符退信',
    'list_perrecvrej': '成员收发不符退信',
    'sentrecv': '个人未通过收发策略退信',
    'secret': '个人未通过密级策略退信',
    'sentfailed': '回送过程失败退信',
    'mailaudit_retr': '被查看',
    'mailaudit_pop_seen': 'POP查看',
    'FilterRefused': '过滤规则拒绝投递',
    'mailaudit_move': '被移动',
    'unknowuser': '邮件地址不存在',
    'set_topping': '置顶',
    'unset_topping': '取消置顶',
    'add_tag': '加标签',
    'del_tag': '取消标签',
  },
  other: {
    view_member: '查看列表成员',
    add_depart_mail: '添加部门邮箱',
    psw_mail: '加密邮件',
    open_audit_code: '您正在打开审核通知邮件，请输入验证码',
    go_mail_view_code: '请前往邮件查看验证码',
    input_code: '请填写验证码',
  },
  track: {
    mail_track: '邮件追踪',
    input_mail_sub: '请输入邮件主题',
    time: '时间',
    to: '收件人',
    sub: '主题',
    mail_status: '邮件状态',
    action: '操作',
    withdraw: '撤回',
    display: '显示',
    withdraw_ok: '撤回成功存至草稿箱',
  },
  audit: {
    audit_tips: '审核通知',
    input_mail_sub: '请输入邮件主题',
    time: '时间',
    to: '发件人',
    sub: '主题',
    action: '操作',
    auditing: '审核',
  },
  att: {
    input_att_name: '请输入附件名称',
    all: '全部文件',
    img: '图片',
    audio: '多媒体',
    doc: '文档',
    zip: '压缩包',
    other: '其他',
    send: '发送',
    down: '下载',
    save_drive: '保存到网盘',
    name: '文件名',
    no_name: '无文件名称',
    time: '时间',
    from: '发件人',
    sub: '主题',
    size: '大小',
    view: '预览',
    form_mail: '来自邮件',
    select_file: '请选择文件',
    select_folder: '选择文件夹',
  },
  colmail: {
    all: '全部邮件',
    currentPage: '当前页',
    uncheck: '不选',
    invert: '反选',
    unread: '未读',
    emergent: '紧急',
    search_res: '搜索结果',
    hight_search: '高级搜索：查找到邮件{total}封',
    an_mail: '按邮箱',
    an_from: '按发件人',
    an_to: '按收件人',
    an_time: '按时间',
    an_has_att: '按有无附件',
    has_att: '有附件',
    no_att: '无附件',
    foder_no_mail: '此文件夹中没有邮件',
    me: '我',
    read_destory: '阅后即焚邮件',
    mail_expired: '此邮件将于{date}过期',
    secrity_mail: '安全属性邮件',
    psw_mail: '密码邮件',
    add_psw_mail: '加密邮件***',
    will_send: '将于{date}发送',
    no_mail: '暂无选择邮件',
    select_mails: '已选择{num}封邮件',
    transfer: '转发',
    astt_transfer: '作为附件转发',
    mark: '标记',
    move: '移动',
    del: '删除',
    more: '更多',
    down_mail: '下载邮件',
    upload_mail: '上传邮件',
    selectmails: '选中{num}封邮件',
    find_ok: '查找到',
    total_num_mail: '的邮件{num}封',
    one_week: '一周内',
    o_mon: '一月内',
    t_mon: '三月内',
    since: '更早',
  },
  transfer: {
    input_att_name: '请输入附件名称',
    upload: '上传文件',
    del: '删除',
    del_ok: '删除成功',
    send: '发送',
    increace_time: '续期',
    increace_time_ok: '续期成功',
    save_drive: '保存到网盘',
    file_name: '文件名',
    no_file_name: '无文件名称',
    create_time: '创建时间',
    expired: '过期时间',
    forever: '永久有效',
    size: '大小',
    download_times: '下载次数',
    preview: '预览',
    down: '下载',
    rename: '重命名',
    rename_ok: '重命名成功',
    select_files: '请选择文件',
    not_support: '不支持 \ / : * ? < > |',
    delete_not_down: '删除后收到大附件的人将无法下载,确定删除所选文件吗？',
    unread: '未读',
    read: '已读',
    star: '星标',
    unstar: '取消星标',
    top: '置顶',
    untop: '取消置顶',
    select_tag: '选标签',
    mark_type_ok: '标记为{type}邮件成功',
    select_mail: '请选择邮件',
    select_untag_mail: '请选择取消全部标签的邮件',
    star_ok: '星标设置成功',
    star_not_ok: '星标取消成功',
    move_to_ok: '移动到{val}成功',
    del_not_recover: '确认彻底删除邮件吗？删除后，邮件将不能恢复',
    clean_del: '彻底删除成功',
    cancel_del: '已取消删除',
    not_transfer: '邮件设置了安全属性，不允许转发',
    expired_not_fw: '过期邮件不允许转发',
    fw: '转发:',
    max_fw_20: '最多允许同时转发20封邮件',
    not_att_transfer: '邮件设置了安全属性，不允许作为附件转发',
    some_ok: '部分邮件下载成功，禁止下载类型的邮件不允许下载',
    undownload: '禁止下载类型的邮件不允许下载',
    unfw: '禁止转发类型的邮件不允许下载',
    select_download_mail: '请选择要下载的邮件',
    max_down_times: '一次最多允许下载20封邮件',
  },
  mailmodule: {
    no_auth: '权限不符，无法编辑草稿',
    edit_later_send: '确定要编辑定时邮件吗? 确定后，则会取消定时邮件的发送',
    bzinfo: '备注信息',
    newcontact: '新建联系人',
    support_type: '上传邮件只支持zip类型',
    editcontact: '编辑联系人',
    check_import: '检测到可以导入的邮件{num1}封，成功导入{num2}封，其他重复或格式错误邮件已忽略。',
    max_select_contact: '最多选择{num}个地址',
    depart_list: '部门邮箱列表',
    already_add: '该联系人已添加',
    uploading: '{num}个文件上传中',
    all_cancel: '全部取消',
    upoad_fail: '上传失败',
    pause: '暂停',
    goon: '继续',
    options: '备选项',
    confirm_cancel_task: '确定取消全部上传任务吗？',
    oversize: '文件大小超过2G上限',
    not_upload_type: '上传类型受限',
    zone_small: '空间不足',
    transfer_small: '文件中转站空间不足',
    netdisk_small: '网盘空间不足',
    search_type: '请输入搜索条件',
    new_mail: '你有{num}封新邮件',
    go_view: '去查看',
    save_fail: '保存失败',
    depart: '部门',
    toggle: '切换',
    first_change: '首次修改密码',
    init_psw: '初始密码',
    newpsw: '新密码',
    confirm_psw: '确认密码',
    set_answer: '设置答案',
    reset: '重 置',
    input_q: '请输入问题',
    change_ok: '修改成功',
    cloud_att: '从发件人邮箱发来的云附件',
    overtime_logout: '超时了，已退出登录',
  },
}

