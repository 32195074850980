<template>
  <el-dialog
    :title="$t('message.contact.memberlist')"
    :visible.sync="memberdialog"
    append-to-body
    custom-class="custom-member"
    width="700px"
    @open="getList"
  >
    <div class="content">
      <div class="title">{{ $t('message.contact.dynamiclist') }}</div>
      <div class="df">
        <span
          v-for="(item,index) in members"
          :key="index"
          class="addr"
        >{{ item.name }}</span>
      </div>
      <div class="title">{{ $t('message.contact.designlist') }}</div>
      <div class="df">
        <span
          v-for="(item,index) in user_list"
          :key="index"
          class="addr"
        >{{ `${item.mail}【${item.path}】` }}</span>
      </div>
      <div class="title">{{ $t('message.contact.externallist') }}</div>
      <div class="df">
        <span
          v-for="(item,index) in out_users"
          :key="index"
          class="addr"
        >{{ item }}</span>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="memberdialog = false">{{ $t('message.btnText.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="memberdialog = false"
      >{{ $t('message.btnText.ok') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
export default {
  mixins: [requestContact],
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      memberdialog: false,
      members: [],
      user_list: [],
      out_users: [],
    }
  },
  methods: {
    async getList() {
      const res = await this.getMemberList(this.id)
      this.members = res.result.members
      this.user_list = res.result.user_list
      this.out_users = res.result.out_users
      this.memberdialog = true
    },
    showDia() {
      this.memberdialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.custom-member{
  .el-dialog__body{
    padding: 0px 20px;
    .content{
     height: 500px;
     overflow-y: auto;
      .title{
        color:#B0B0B0;
        padding:5px 0px;
      }
      .df{
        width: 100%;
        .addr{
          color: #1D1B1B;
          text-align: center;
          display: inline-block;
          padding: 0 8px;
          border: 1px solid #DEDEDE;
          margin: 5px 10px 5px 0;
          height: 26px;
          line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 12px;
        }
      }
    }
  }

}
</style>
