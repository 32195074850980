import { render, staticRenderFns } from "./lableList.vue?vue&type=template&id=64f4371c&scoped=true"
import script from "./lableList.vue?vue&type=script&lang=js"
export * from "./lableList.vue?vue&type=script&lang=js"
import style0 from "./lableList.vue?vue&type=style&index=0&id=64f4371c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f4371c",
  null
  
)

export default component.exports