<template>
  <!-- 读信页面 -->
  <div
    id="view_warp"
    ref="view_warp"
    v-loading="loading"
    :element-loading-text="$t('message.view.loading')"
    class="view_warp"
  >
    <viewAction
      :mail-info="mailInfo"
      :body-html="bodyHtml"
      @downMail="downMail"
      @goBack="goBack"
    />
    <div
      v-if="!audit_expired"
      id="mailboxAll"
      ref="mailboxAll"
      class="mailboxAll"
    >
      <div
        v-if="!loading"
        class="view_top"
      >
        <p
          class="trun_page"
        >

          <span
            v-if="!firstMail"
            class="cur"
            @click="prevMail('prev')"
          ><svg-icon
            class="left svg-theme-color"
            icon-class="left"
          />{{ bodyHtml.from }}</span>
          <span
            v-if="!lastMail"
            class="cur"
            @click="prevMail('next')"
          ><svg-icon
            class="right"
            icon-class="right"
          /></span>

        </p>
        <p class="subject">
          <span
            class="txt"
          >{{ bodyHtml.subject ? bodyHtml.subject : $t('message.view.no_subject') }}</span>
          <svg-icon
            v-show="flags==='0'&& auth_authority !== 0 && auth_authority !== 1"
            icon-class="unstart"
            class="start"
            @click="flagsClick('1')"
          />
          <svg-icon
            v-show="flags==='1' && auth_authority !== 0 && auth_authority !== 1"
            icon-class="start"
            class="start"
            @click="flagsClick('0')"
          />
          <el-dropdown
            v-if="auth_authority !== 0 && auth_authority !== 1"
            ref="tabListBox"
            trigger="click"
            class="tabBox"
            placement="buttom"
            @visible-change="handVisibleChange"
          >
            <span class="el-dropdown-link">
              <em
                v-for="(i,index) in tabList"
                :key="index"
                style="margin-right:3px"
              >

                <el-tooltip
                  class="item cur tooltip"
                  effect="light"
                  :content="i.tag_name"
                  placement="top-start"
                >
                  <span>
                    <svg-icon
                      ref="tab"
                      class="tab"
                      :title="i.tag_name"
                      :icon-class="i.tag_color.slice(1,7)"
                    />
                  </span>
                </el-tooltip>
              </em>

            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- 标签列表 -->
              <lableList
                ref="lableList"
                :tab-list="tabList"
                :mail-info="mailInfo"
                @showTabList="tab_changeList(arguments)"
              />
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 备注 -->

          <el-tooltip
            v-if="auth_authority !== 0 && auth_authority !== 1 && bodyHtml.comment !== ''"
            class="item"
            effect="light"
            placement="right-start"
          >
            <div
              slot="content"
              style="max-width:500px"
            >{{ bodyHtml.comment }}</div>
            <span class="cur"><svg-icon
              class="icos-size-14 svgIcon svg-color"
              icon-class="mail_bz"
              @click.stop="remarkDia(bodyHtml)"
            /></span>
          </el-tooltip>
          <span class="clean" />
        </p>
        <p class="time">{{ bodyHtml.from_name_l ? showName(bodyHtml.from_name_l) :'' }} {{ dateFilter(bodyHtml.envelope_date) }}
          <svg-icon
            v-show="bodyHtml.is_strange ==='1' && bodyHtml.is_suspicious ==='0' "
            :icon-class="langicon()"
            class="middle kym"
          />
          <span
            v-show="bodyHtml.is_suspicious ==='1'"
            class="formtips"
          ><svg-icon
            icon-class="ky"
            class="middle kym"
          />({{ $t('message.view.replace_send',{mail:bodyHtml.x_sender}) }})</span>
        </p>
        <div class="peoList">
          <span
            v-if="isUnfold"
            class="zs"
            @click="Unfold(true)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.expand') }}</span>
          <span
            v-else
            class="zs"
            @click="Unfold(false)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.close') }}</span>
          <p
            v-if="isUnfold"
            class="to_s one-line-ellipsis"
          >
            <em>{{ $t('message.view.send_to') }}: </em>
            <span
              v-for="(i,index) in bodyHtml.to_s"
              :key="index"
              class="cur"
            >
              <mailContactCardPopovrer
                :contact-name="i[1]"
                :contact-mail="i[0]"
                :contact-group="i[2]"
                :text-content="showName(i)"
              />
              <span v-show="to_s && to_s.length-1 !== index "> , </span>
            </span><span v-show="to_s.length > 1"> &nbsp;{{ $t('message.view.num_person',{num: to_s.length}) }}</span>
          </p>
          <div
            v-show="!isUnfold"
            class="to_s"
          >
            <em>{{ $t('message.view.to') }}: </em>
            <!-- <p class="tosList">
            <span
              v-for="(i,index) in to_s"
              :key="index"
            >{{ i[1]+'<'+i[0]+'>' }}<span v-show="to_s && to_s.length-1 !== index "> , </span></span>
          </p> -->
            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in to_s"
                :key="index"
              >
                <mailContactCardPopovrer
                  :contact-name="i[1]"
                  :contact-mail="i[0]"
                  :contact-group="i[2]"
                  :text-content="showName(i)"
                />
                <span v-show="to_s && to_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <div
            v-show="!isUnfold && cc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.cc') }}: </em>
            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in cc_s"
                :key="index"
              >
                <mailContactCardPopovrer
                  :contact-name="i[1]"
                  :contact-mail="i[0]"
                  :contact-group="i[2]"
                  :text-content="showName(i)"
                />
                <span v-show="cc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <!-- 需要判断是否在发件箱 -->

          <div
            v-show="!isUnfold && bcc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.bcc') }}: </em>
            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in bcc_s"
                :key="index"
              >
                <mailContactCardPopovrer
                  :contact-name="i[1]"
                  :contact-mail="i[0]"
                  :contact-group="i[2]"
                  :text-content="showName(i)"
                />
                <span v-show="bcc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
        </div>
        <p v-if="attachments && attachments.length">

          <em>{{ $t('message.view.att') }}: </em>
          <span>
            <svg-icon
              class="attaClass middle"
              :icon-class="getFileType(attachments[0].name)+'icon'"
              class-name="atticon"
            /> {{ $t('message.view.att') }}{{ $t('message.view.have_att',{num:attachments.length}) }}({{ attachments[0].name }}<em v-show="attachments.length >1">{{ $t('message.view.etc') }}</em>)</span>

          <span
            class="lookAtta text-color cur"
            @click="scrollAtta"
          > {{ $t('message.view.view_att') }}</span>
        </p>
        <p
          v-if=" bodyHtml.expire_time > 0"
          class="expireTime"
        >
          <svg-icon
            class="middle icos-size-14"
            icon-class="prompt"
          />
          <span v-if="mail_expired === 'yes'">{{ $t('message.view.mail_expired') }}</span>
          <span v-else>{{ $t('message.view.mail_will_expired',{date:dateFilter(bodyHtml.expire_time)}) }}</span>
        </p>

      </div>
      <!-- 邮件召回状态--发件箱 -->
      <div
        v-if=" mailInfo.mbox == 'Sent' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1 && !loading"
        class="view_track"
      >
        <!-- <div
          v-if="sentMailStatus === '等待审核' || sentMailStatus === '审核中'"
          class="type tdtype"
          :title="'等待' + sentMailStatusList+'审核'"
        ><em>等待</em><span class="audittype one-line-ellipsis">{{ sentMailStatusList }}</span><em>审核</em></div> -->
        <div
          v-if="sentMailStatus === $t('message.mailstatus.Auditing') || sentMailStatus === $t('message.mailstatus.HasAudited')"
          class="type tdtype"
        >
          <el-tooltip
            class="item"
            effect="light"
            placement="top"
          >
            <div
              slot="content"
              style="max-width:500px"
            >{{ $t('message.view.wait_audit',{mail:sentMailStatusList}) }}</div>
            <p><em>{{ $t('message.view.wait') }}</em><span class="audittype one-line-ellipsis">{{ sentMailStatusList }}</span><em>{{ $t('message.view.audit') }}</em></p>
          </el-tooltip>
        </div>
        <div v-else>
          <div
            v-if="sentMailStatus === $t('message.mailstatus.sending')"
            class="type cur"
            @click="Recall_mail_tacking"
          >{{ sentMailStatus }}
            <span
              class="tdtype"
            >{{ $t('message.view.income_track_view') }}</span>
          </div>
          <div
            v-else
            class="type cur"
            @click="Recall_mail"
          >{{ sentMailStatus }}
            <span
              class="tdtype"
            >{{ isOrganizer?$t('message.view.click_view_status'):$t('message.view.click_view_and_recall') }}</span></div>
          <em v-if="sentMailStatus === $t('message.mailstatus.Auditing') || sentMailStatus === $t('message.mailstatus.HasAudited')">|</em>
          <span
            v-if="sentMailStatus == $t('message.mailstatus.Auditing')"
            class="tk cur"
            @click="withdraw_mail"
          >{{ $t('message.view.withdraw') }}</span>
        </div>
      </div>

      <!-- 外来邮件 -->
      <p
        v-if="bodyHtml.is_strange ==='1' || bodyHtml.is_suspicious ==='1'"
        class="view_mo"
      >
        <!-- <span class="know">我知道了</span> -->
        <svg-icon
          class="middle icos-size-14"
          icon-class="prompt"
        /> <span>{{ $t('message.view.risk_info') }}</span>{{ $t('message.view.warn_info') }}
      </p>
      <!-- 发件箱 加密邮件 -->
      <p
        v-if="bodyHtml.x_append === '1' && bodyHtml.protect_type === 2 && !loading"
        class="view_mo"
      >
        <svg-icon
          class="middle icos-size-14"
          icon-class="encipherIcon"
        /> {{ $t('message.view.mail_psw_info',{psw:bodyHtml.mail_psw}) }}
      </p>
      <!-- 发件箱 安全属性邮件 -->
      <!-- protect_type 0是未设置 1安全邮件 2加密邮件 x_append  1是自己发的-->
      <p
        v-if="bodyHtml.x_append === '1' && bodyHtml.protect_type === 1 && !loading"
        class="view_mo"
      >
        <svg-icon
          class="middle icos-size-14"
          icon-class="encipherIcon"
        /> {{ $t('message.view.secrity') }}<span v-if=" bodyHtml.is_expired == 1">{{ $t('message.view.and') }}<span class="text-color">{{ dateFilter(bodyHtml.expire_time) }}</span>{{ $t('message.view.expired') }}</span><span v-if="bodyHtml.readed_destory === '1'">{{ $t('message.view.and_view_once') }}</span>
      </p>
      <!-- 会议信息卡 -->
      <calendarCard
        v-if="isMettingMail"
        :card-info="MettingCardData"
      />
      <!-- 邮件内容 -->
      <div
        class="view_center"
      >
        <div
          ref="mailbody"
          class="view_html"
        >

          <iframe
            ref="iframe"
            src=""
            frameborder="0"
            width="100%"
            height="500"
            scrolling="auto"
          />
        </div>
        <!-- <div
          ref="mailbody"
          class="view_html"
          v-html="bodys"
        /> -->
        <img
          v-for="(i,index) in attaImg"
          :key="index"
          class="imgpadding"
          :src="i"
        >
        <!-- 附件区 -->
        <div
          v-show="attachments && attachments.length"
          ref="view_atta_box"
          class="view_atta"
        >
          <div class="atta_tit">{{ $t('message.view.att') }}(<span>{{ $t('message.view.have_att',{num:attachments.length}) }}, {{ $t('message.view.total') }}{{ bodyHtml.att_size | mailSizefilter }}</span>)
            <span
              v-if="bodyHtml.undownload !== '1'"
              class="text-color cur"
              @click="downAtta('download','allDown')"
            >({{ $t('message.view.pack_download') }})</span>
          </div>
          <div class="atta_html">
            <div
              v-for="(i,index) in attachments"
              :key="index"
              class="mv-att-item"
            >
              <div
                cs2c_id="icon"
                class="big-icon icon-att4-file attaLeft"
              >
                <svg-icon
                  class="attaClass"
                  :icon-class="getFileType(i.name)+'icon'"
                  class-name="atticon"
                />
              </div>
              <div class="attaRight">
                <div class="attaR_top">
                  <el-tooltip
                    class="item"
                    effect="light"
                    placement="top-start"
                  >
                    <div
                      slot="content"
                      style="max-width:500px"
                    >{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</div>
                    <span
                      class="atta_name"
                    >{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</span>
                  </el-tooltip>&nbsp;<span>(</span><span class="atta_size">{{ i.size | mailSizefilter }}</span><span>)</span>
                </div>
                <div
                  class="attaR_bot"
                >
                  <!-- 读信预览 -->
                  <span
                    v-if="isPreView(i.name)"
                    class="preview text-color"
                    @click="downAtta('preview',i)"
                  >{{ $t('message.view.priview') }}</span>
                  <!-- 读信下载 -->
                  <span
                    v-if="bodyHtml.undownload != '1'"
                    class="download text-color"
                    @click="downAtta('download',i)"
                  >{{ $t('message.view.download') }}</span>
                  <span
                    v-if="bodyHtml.undownload !== '1' && $store.state.app.user_permissions.netdisk == 1 && $store.state.app.auth_authority !== 1 && $store.state.app.auth_authority !== 0"
                    class="text-color"
                    @click="handleIcludClick(i)"
                  >{{ $t('message.view.save_drive') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 过期邮件 -->
    <div
      v-else
      class="mailboxAll"
    >
      <svg-icon
        class="audit_expired"
        icon-class="audit_expired"
      />
      <p class="audit_expired_html">{{ audit_expired_html }}</p>

    </div>
    <!-- 快捷回复 -->
    <div
      v-show="bodyHtml.unresponse !== '1' && $store.state.app.auth_authority !== 0"
      ref="view_bot_back"
      class="view_bot_back"
    >
      <div class="view_bot">
        <el-input
          v-show="isReplyTxt"
          id="replyTxt"
          v-model="replyTxt"
          type="textarea"
          resize="none"
          :rows="5"
          aria-multiline="true"
          class="txt_bot"
          :placeholder="$t('message.view.input_content')"
        />
        <div
          class="all"
          @click="handViewBot"
        >
          <svg-icon
            class="reall_icon"
            :class="!isReplyAll ? 'reall_icon_hover' : ''"
            icon-class="view_reply"
            @click="handReplyAll(false)"
          />
          <span class="line" />
          <svg-icon
            class="reall_icon"
            :class="isReplyAll ? 'reall_icon_hover' : ''"
            icon-class="view_replyAll"
            @click="handReplyAll(true)"
          />
          <span class="mrtxt">{{ textarea }}</span>
          <el-select
            v-model="replyValue"
            class="txt_sel"
            :placeholder="$t('message.view.more_replay')"
            @visible-change="GetFastreplyMsg"
            @change="txtSel"
          >
            <el-option
              v-for="item in replyList"
              :key="item.id"
              :label="item.reply_msg"
              :value="item.reply_msg"
            />
          </el-select>
          <p class="txt_gd">
            <span
              v-for="(i,index) in quickList"
              :key="index"
              @click="replyTxt = i"
            >{{ i }}</span>
          </p>

          <el-button
            type="primary"
            @click="toCompose"
          >{{ btnTxet }}</el-button>
        </div>

      </div>
    </div>
    <!-- 邮件召回 -->
    <mailRecall
      :is-meeting="isOrganizer"
      :mail-info="recallMailInfo"
      @showRecall="changeList"
    />

    <!-- 钓鱼邮件 -->
    <phishingEmail
      :is-show="isPhishingEmail"
      @Phishing_mail="Phishing_mail"
    />
    <!-- 钓鱼视频 -->
    <videoDialog
      ref="child"
      :videosrc="videosrc"
    />
    <!-- 回执提醒 -->
    <receiptMail
      :is-show="dialogReceipt"
      :mail-info="mailInfo"
      :body="bodyHtml"
    />
    <!-- 消息提示 -->
    <el-dialog
      :title="$t('message.btnText.info')"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="30%"
    >
      <span>{{ dialogMsg }}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="isKmd"
          @click="dialogVisible = false"
        >{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="closeDialog"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <!-- 邮件密码 -->
    <mailPassword
      :mail-info="$store.state.tagsView.readMail.data"
      :is-show="showMailPasswordDialog"
      @showPasswordFun="mailPasswordFun"
    />
    <!-- 下载附件 -->
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />

    <!-- 邮件备注弹窗 -->
    <remarkDialog
      v-if="showMailbzDialog"
      :is-show="showMailbzDialog"
      :bz-info="bzInfo"
      @showRemark="changeRemark(arguments)"
    />
    <!-- 转存网盘 -->
    <saveDisk
      :file-list="fileListDisk"
      :is-show="showDialogDisk"
      :is-type="{
        file: 'viewMail',
        isAll: false,
      }"
    />
    <el-dialog
      title=""
      :visible.sync="dialogVisibleContact"
      width="498px"
      append-to-body
      :show-close="false"
      class="cutomDialog"
    >
      <NewContact
        v-if="dialogVisibleContact"
        :on-closed="addUserClosed"
        :on-submit="addUserSubmit"
        :form="ruleForm"
        :cid="contact_id"
        :is-edit="idEdit"
        :title="addtitle"
        is-card
      />
    </el-dialog>
  </div>
</template>
<script>
import view from './components/view.js'
import viewAction from './components/action.vue'
import phishingEmail from './components/phishingEmail.vue'
import mailPassword from '../components/mailPassword.vue'
import receiptMail from './components/receiptMail.vue'
import videoDialog from './components/videoShow.vue'
import mailRecall from '../components/mailRecall.vue'
import lableList from '../components/lableList.vue'
import downAttaForm from '../components/downAtta.vue'
import mailContactCardPopovrer from '@/components/mailmodule/mail-contactCard-popover.vue'
import saveDisk from '../components/saveDisk.vue'
import NewContact from '@/views/contacts/components/NewContact'
import remarkDialog from '../components/remark.vue'
import calendarCard from './components/calendarCard.vue'
export default {
  components: {
    viewAction,
    phishingEmail,
    videoDialog,
    mailRecall,
    receiptMail,
    lableList,
    downAttaForm,
    mailContactCardPopovrer,
    mailPassword,
    saveDisk,
    NewContact,
    remarkDialog,
    calendarCard,
  },
  mixins: [view],
  methods: {
    goBack() {
      this.$emit('goBackList')
    },
    langicon() {
      const lang = localStorage.getItem('lang')
      if (lang === 'english') {
        return 'mo_en'
      } else {
        return 'ms'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./common.scss";
</style>
