<template>
  <!-- 安全邮件、第三方api页面 -->
  <div
    ref="view_warp"
    v-loading="loading"
    element-loading-text=""
    class="view_warp"
    :style="urlPath === '/convert-view' ? 'user-select:none' : ''"
  >
    <div
      v-if="!audit_expired"
      id="mailboxAll"
      class="mailboxAll mailboxAll1"
    >
      <div
        v-if="!loading"
        class="view_top"
      >
        <p class="subject">
          <span
            class="txt"
          >{{ bodyHtml.subject ? bodyHtml.subject : $t('message.view.no_subject') }}</span>

          <span class="clean" />
        </p>
        <p class="time">{{ bodyHtml.from_name_l ? showName(bodyHtml.from_name_l) :'' }} {{ dateFilter(bodyHtml.envelope_date) }}
          <!-- 过期时间 -->
          <svg-icon
            v-if="bodyHtml.expire !=='' && urlPath === '/convert-view'"
            class="icos-size-14 middle"
            icon-class="mall_over_time"
            style="margin-left: 15px"
          /><span
            v-if="bodyHtml.expire !=='' && urlPath === '/convert-view'"
            style="color:#ECB22C;margin-right: 50px;"
          >{{ $t('message.view.mail_will_expired',{date:bodyHtml.expire}) }}</span>
          <!-- 过期时间 -->
          <svg-icon
            v-if="Number(bodyHtml.readed_destory) && urlPath === '/convert-view'"
            class="icos-size-14 middle"
            icon-class="mail_burn"
            style="margin-left: 15px"
          /><span
            v-if="Number(bodyHtml.readed_destory) && urlPath === '/convert-view'"
            style="color:#ECB22C"
          >{{ $t('message.view.destory_mail_once_read') }}</span>
          <!-- <svg-icon
            v-show="bodyHtml.is_strange ==='1' && bodyHtml.is_suspicious ==='0' "
            icon-class="ms"
            class="middle kym"
          />
          <span
            v-show="bodyHtml.is_suspicious ==='1'"
            class="formtips"
          ><svg-icon
            icon-class="ky"
            class="middle kym"
          />(由{{ bodyHtml.x_sender }}代发)</span> -->
        </p>
        <div class="peoList">
          <span
            v-if="isUnfold"
            class="zs"
            @click="Unfold(true)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.expand') }}</span>
          <span
            v-else
            class="zs"
            @click="Unfold(false)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.close') }}</span>
          <p
            v-if="isUnfold"
            class="to_s one-line-ellipsis"
          >
            <em>{{ $t('message.view.send_to') }}: </em>
            <span
              v-for="(i,index) in bodyHtml.to_s"
              :key="index"
              class="cur"
            >
              {{ showName(i) }}
              <span v-show="to_s && to_s.length-1 !== index "> , </span>
            </span><span v-show="to_s.length > 1"> &nbsp;{{ $t('message.view.num_person',{num:to_s.length}) }}</span>
          </p>
          <div
            v-show="!isUnfold"
            class="to_s"
          >
            <em>{{ $t('message.view.to') }}: </em>
            <!-- <p class="tosList">
            <span
              v-for="(i,index) in to_s"
              :key="index"
            >{{ i[1]+'<'+i[0]+'>' }}<span v-show="to_s && to_s.length-1 !== index "> , </span></span>
          </p> -->
            <p class="tosList">
              <span
                v-for="(i,index) in to_s"
                :key="index"
              >

                {{ showName(i) }}
                <span v-show="to_s && to_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <div
            v-show="!isUnfold && cc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.cc') }}: </em>
            <p class="tosList">
              <span
                v-for="(i,index) in cc_s"
                :key="index"
              >

                {{ showName(i) }}
                <span v-show="cc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <!-- 需要判断是否在发件箱 -->

          <div
            v-show="!isUnfold && bcc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.bcc') }}: </em>
            <p class="tosList">
              <span
                v-for="(i,index) in bcc_s"
                :key="index"
              >

                {{ showName(i) }}
                <span v-show="bcc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
        </div>
        <p v-if="attachments && attachments.length">

          <em>{{ $t('message.view.att') }}: </em>
          <span>
            <svg-icon
              class="attaClass middle"
              :icon-class="getFileType(attachments[0].name)+'icon'"
              class-name="atticon"
            /> {{ $t('message.view.att') }}{{ $t('message.view.have_att',{num:attachments.length}) }}({{ attachments[0].name }}<em v-show="attachments.length >1">{{ $t('message.view.etc') }}}</em>)</span>
          <span
            class="lookAtta text-color cur"
            @click="scrollAtta"
          > {{ $t('message.view.view_att') }}</span>
        </p>
        <p
          v-if=" bodyHtml.is_expired == 1"
          class="expireTime"
        >
          <svg-icon
            class="middle icos-size-14"
            icon-class="prompt"
          /> {{ $t('message.view.mail_will_expired',{date:dateFilter(bodyHtml.expire_time) }) }}
        </p>

      </div>
      <!-- 外来邮件 -->
      <p
        v-if="(bodyHtml.is_strange ==='1' || bodyHtml.is_suspicious ==='1')"
        class="view_mo"
      >
        <!-- <span class="know">我知道了</span> -->
        <svg-icon
          class="middle icos-size-14"
          icon-class="prompt"
        /> <span>{{ $t('message.view.risk_info') }}</span>{{ $t('message.view.warn_info') }}
      </p>
      <!-- 邮件内容 -->
      <div class="view_center view_center1">
        <div
          ref="mailbody"
          class="view_html"
        >

          <iframe
            ref="iframe"
            src=""
            frameborder="0"
            width="100%"
            height="500"
            scrolling="auto"
          />
        </div>
        <!-- <div
          ref="mailbody"
          class="view_html"
          v-html="bodys"
        /> -->
        <img
          v-for="(i,index) in attaImg"
          :key="index"
          class="imgpadding"
          :src="i"
        >
        <!-- 附件区 -->
        <div
          v-show="attachments && attachments.length"
          id="view_atta"
          ref="view_atta_box"
          class="view_atta"
        >
          <div class="atta_tit">{{ $t('message.view.att') }}(<span>{{ $t('message.view.have_att',{num:attachments.length}) }}, {{ $t('message.view.total') }}{{ bodyHtml.att_size | mailSizefilter }}</span>)
            <span
              v-if=" bodyHtml.undownload !== '1'&& urlPath !== '/convert-view'"
              class="text-color cur"
              @click="downAtta('download','allDown')"
            >({{ $t('message.view.pack_download') }})</span>
          </div>
          <div class="atta_html">
            <div
              v-for="(i,index) in attachments"
              :key="index"
              class="mv-att-item"
            >
              <div
                cs2c_id="icon"
                class="big-icon icon-att4-file attaLeft"
              >
                <svg-icon
                  class="attaClass"
                  :icon-class="getFileType(i.name)+'icon'"
                  class-name="atticon"
                />
              </div>
              <div class="attaRight">
                <div class="attaR_top">
                  <span class="atta_name">{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</span>&nbsp;<span>(</span><span class="atta_size">{{ i.size | mailSizefilter }}</span><span>)</span>
                </div>
                <div
                  class="attaR_bot"
                >
                  <!-- 读信预览 -->
                  <span
                    v-if="isPreView(i.name)&& urlPath != '/convert-view'"
                    class="preview text-color"
                    @click="downAtta('preview',i)"
                  >{{ $t('message.view.priview') }}</span>
                  <!-- 审核预览 -->
                  <a
                    v-if="isPreView(i.name)&& urlPath === '/convert-view'"
                    class="preview text-color"
                    target="_blank"
                    :href="auditDownAtta('preview',i)"
                  >{{ $t('message.view.priview') }} </a>
                  <!-- 读信下载 -->
                  <span
                    v-if=" bodyHtml.undownload != '1' && urlPath != '/convert-view'"
                    class="download text-color"
                    @click="downAtta('download',i)"
                  >{{ $t('message.view.download') }}</span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 过期邮件 -->
    <div
      v-else
      class="mailboxAll"
    >
      <svg-icon
        class="audit_expired"
        icon-class="audit_expired"
      />
      <p class="audit_expired_html">{{ audit_expired_html }}</p>
    </div>
    <!-- 钓鱼邮件 -->
    <!-- <phishingEmail
      :is-show="isPhishingEmail"
      @Phishing_mail="Phishing_mail"
    /> -->
    <!-- 钓鱼视频 -->
    <!-- <videoDialog
      ref="child"
      :videosrc="videosrc"
    /> -->
    <!-- 回执提醒 -->
    <!-- <receiptMail
      :is-show="dialogReceipt"
      :mail-info="mailInfo"
      :body="bodyHtml"
    /> -->
    <!-- 消息提示 -->
    <el-dialog
      :title="$t('message.btnText.info')"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="30%"
    >
      <span>{{ dialogMsg }}</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="isKmd"
          @click="dialogVisible = false"
        >{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="closeDialog"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <!-- 邮件密码 -->
    <mailPassword
      :mail-info="mailInfo"
      :is-show="showMailPasswordDialog"
      @showPasswordFun="mailPasswordFun"
    />
    <!-- 下载附件 -->
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />
    <el-dialog
      title=""
      :visible.sync="dialogVisibleContact"
      width="498px"
      append-to-body
      :show-close="false"
      class="cutomDialog"
    >
      <NewContact
        v-if="dialogVisibleContact"
        :on-closed="addUserClosed"
        :on-submit="addUserSubmit"
        :form="ruleForm"
        :cid="contact_id"
        :is-edit="idEdit"
        :title="addtitle"
        is-card
      />
    </el-dialog>

    <!-- 安全邮件发送验证码 -->
    <el-dialog
      :title="$t('message.view.secrity_mail')"
      :visible.sync="sendYzmVisible"
      :modal-append-to-body="false"
      width="660px"
    >
      <p style="margin-top: 20px">{{ $t('message.view.reading_mail_rec_code',{val:urlTo}) }}</p>
      <p
        v-if="urlReaded_destroy"
        class="red"
      > <svg-icon
        class="middle icos-size-14"
        icon-class="prompt"
      /> {{ $t('message.view.destory_mail_once_read') }}</p>
      <p class="yzmInpuy">
        <el-input
          v-model="yzmInpuy"
          maxlength="6"
          class="input"
          oninput="value=value.replace(/^\+|[^\d]/g,'')"
        /><span
          class="cur"
          @click="sendCodeFun"
        >{{ $t('message.view.send_msg_code') }} </span>
      </p>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="medium"
          @click="showMsg_bodys_document()"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import view from './components/apiView.js'
// import viewAction from './components/action.vue'
// import phishingEmail from './components/phishingEmail.vue'
import mailPassword from '../components/mailPassword.vue'
// import receiptMail from './components/receiptMail.vue'
// import videoDialog from './components/videoShow.vue'
// import auditMail from './components/auditMail.vue'
// import printMail from './components/printMail.vue'
import downAttaForm from '../components/downAtta.vue'
// import saveDisk from '../components/saveDisk.vue'
import NewContact from '@/views/contacts/components/NewContact'
export default {
  components: {
    // viewAction,
    // phishingEmail,
    // videoDialog,
    // receiptMail,
    // auditMail,
    // printMail,
    downAttaForm,
    mailPassword,
    // saveDisk,
    NewContact,
  },
  mixins: [view],
  mounted() {
    const skin_colour = this.$store.state.app.userGetConfig.skin_colour
    console.log(skin_colour, 'header------------color')
    const skin1 = document.getElementById('linkSkin1')
    const skin2 = document.getElementById('linkSkin2')
    skin1.href = `/nsmail/theme/${skin_colour}/theme.css`
    skin2.href = `/nsmail/theme/${skin_colour}/style/index.css`
  },
}
</script>

<style lang="scss" scoped>
@import "./common.scss";
</style>
