<template>
  <div class="setting-time">
    <div class="top">
      <div>
        <span>{{ $t('message.calendar.cyle1') }}：</span>
        <el-select
          v-model="dateForm.Model"
          size="mini"
          :placeholder="$t('message.calendar.info37')"
          style="width: 100px;"
        >
          <el-option
            v-for="item in cycleModel"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <div
          v-if="dateForm.Model===1"
          style="padding: 10px 0; 0;border-bottom:1px solid #dcdfe6;"
        >
          <el-radio
            v-model="dateForm.day.kind"
            :label="1"
          >
            {{ $t('message.calendar.cyle6') }}<el-input
              v-model="dateForm.day.daynum"
              size="mini"
              style="width: 50px;"
              class="intPadding_0"
              oninput="value=value.replace(/[^\d]/g,'')"
            />{{ $t('message.calendar.cyle7') }}
          </el-radio>
          <el-radio
            v-model="dateForm.day.kind"
            :label="2"
          >{{ $t('message.calendar.cyle8') }}</el-radio>
        </div>
        <div
          v-if="dateForm.Model===2"
          class="genal"
          style="align-items: baseline;"
        >
          <!-- <div style="display: flex;align-items: baseline;"> -->
          <span style="white-space:nowrap">{{ $t('message.calendar.cyle6') }}</span><el-input
            v-model="dateForm.week.weeknum"
            oninput="value=value.replace(/[^\d]/g,'')"
            class="intPadding_0"
            size="mini"
            style="width: 50px;"
          /><span style="white-space: nowrap;margin-right:3px;">{{ $t('message.calendar.cyle9') }}&nbsp;</span>
          <!-- </div> -->

          <el-checkbox-group v-model="dateForm.week.weekList">
            <el-checkbox
              v-for="(item,index ) in weeks"
              :key="index"
              :label="item.label"
            />
          </el-checkbox-group>
        </div>
        <div
          v-if="dateForm.Model===3"
          class="genal"
        >
          <div style="display: flex;align-items: baseline;">
            <span>{{ $t('message.calendar.cyle6') }}</span><el-input
              v-model="dateForm.month.monthnum"
              oninput="value=value.replace(/[^\d]/g,'')"
              size="mini"
              class="intPadding_0"
              style="width: 50px;"
            /><span style="white-space: nowrap;margin-right:3px;">{{ $t('message.calendar.cyle10') }}</span>
          </div>
          <el-radio
            v-model="dateForm.month.kind"
            :label="1"
          >
            {{ $t('message.calendar.cyle11') }}
            <!-- <el-input
              v-model="dateForm.month.daymun"
              oninput="value=value.replace(/[^\d]/g,'')"
              pattern="\d{1,31}"
              size="mini"
              style="width: 50px;"
            /> -->
            <el-select
              v-model="dateForm.month.daymun"
              size="mini"
              :placeholder="$t('message.calendar.info37')"
              style="width:100px;"
            >
              <el-option
                v-for="i in 31"
                :key="i"
                :label="i"
                :value="String(i)"
              />
            </el-select>

            {{ $t('message.calendar.cyle7') }}
          </el-radio>
          <el-radio
            v-model="dateForm.month.kind"
            :label="2"
          >{{ $t('message.calendar.cyle11') }}<el-input
            v-model="dateForm.month.weeknum"
            oninput="value=value.replace(/[^\d]/g,'')"
            size="mini"
            style="width: 50px;"
          />{{ $t('message.calendar.cyle12') }}
            <el-select
              v-model="dateForm.month.week"
              size="mini"
              :placeholder="$t('message.calendar.info37')"
              style="width:120px"
            >
              <el-option
                v-for="item in weeks"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-radio>
        </div>
        <div
          v-if="dateForm.Model===4"
          class="genal"
        >
          <div>
            <span>{{ $t('message.calendar.cyle6') }}</span><el-input
              v-model="dateForm.year.yearnum"
              oninput="value=value.replace(/[^\d]/g,'')"
              size="mini"
              class="intPadding_0"
              style="width: 50px;"
            /><span style="white-space: nowrap;">{{ $t('message.calendar.cyle13') }}&nbsp;</span>
            <el-select
              v-model="dateForm.year.monthnum"
              size="mini"
              :placeholder="$t('message.calendar.info37')"
              style="width: 100px;"
            >
              <el-option
                v-for="item in 12"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>&nbsp;{{ $t('message.calendar.cyle14') }}&nbsp;
            <el-select
              v-model="dateForm.year.daynum"
              size="mini"
              :placeholder="$t('message.calendar.info37')"
              style="width: 100px;"
            >
              <el-option
                v-for="item in 31"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>&nbsp;{{ $t('message.calendar.cyle15') }}
          </div>
        </div>
        <div class="date-padding">
          <span>{{ $t('message.calendar.cyle16') }}：</span>
          <el-date-picker
            v-model="dateForm.endDate"
            size="mini"
            type="date"
            value-format="yyyyMMdd"
            :placeholder="$t('message.calendar.cyle16')"
            :disabled="dateForm.neverexpire"
            style="margin-right: 5px;"
          />
          <el-checkbox v-model="dateForm.neverexpire">{{ $t('message.calendar.cyle17') }}</el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      oldData: {}, // 保存初始化数据
      dateForm: {
        Model: 1, // 重复模式
        day: {
          kind: 1,
          daynum: '',
        },
        week: {
          weeknum: '',
          weekList: [],
        },
        month: {
          monthnum: '',
          kind: 1,
          daymun: '',
          weeknum: '',
          week: '',
        },
        year: {
          yearnum: '',
          monthnum: '',
          daynum: '',
        },
        endDate: '',
        neverexpire: false,
      },

      pickerOptions0: {
        disabledDate(time) {
          // return time.getTime() < this.disabledDate() - 24 * 60 * 60 * 1000
          return time.getTime() < Date.now() - 8.64e7
        },
      },
      cycleModel: [
        {
          value: 1,
          label: this.$t('message.calendar.cyle2'),
        }, {
          value: 2,
          label: this.$t('message.calendar.cyle3'),
        }, {
          value: 3,
          label: this.$t('message.calendar.cyle4'),
        }, {
          value: 4,
          label: this.$t('message.calendar.cyle5'),
        }],
      weeks: [{
        value: 'MO',
        label: this.$t('message.calendar.cyle18'),
      }, {
        value: 'TU',
        label: this.$t('message.calendar.cyle19'),
      }, {
        value: 'WE',
        label: this.$t('message.calendar.cyle20'),
      }, {
        value: 'TH',
        label: this.$t('message.calendar.cyle21'),
      }, {
        value: 'FR',
        label: this.$t('message.calendar.cyle22'),
      }, {
        value: 'SA',
        label: this.$t('message.calendar.cyle23'),
      }, {
        value: 'SU',
        label: this.$t('message.calendar.cyle24'),
      }],
      errmsg: this.$t('message.calendar.cyle25'),
      errmsg1: this.$t('message.calendar.cyle26'),
    }
  },
  watch: {
    dataMsg: {
      handler(newVal) {
        console.log(newVal)
        this.dateForm = newVal
      },
      deep: true,
    },
  },
  mounted() {
    this.oldData = JSON.parse(JSON.stringify(this.$data))
  },
  methods: {
    // 获取rule规则
    getDate() {
      const result = {
        status: true,
        data: '',
      }
      let overTimer = ''
      console.log(this.dateForm.endDate, 'dateForm.endDate')
      if (!this.dateForm.neverexpire && this.dateForm.endDate) {
        overTimer = ';UNTIL=' + this.dateForm.endDate
      }
      if (this.dateForm.Model === 1) {
        if (this.dateForm.day.kind === 1) {
          if (this.dateForm.day.daynum === '') {
            return {
              status: false,
              msg: this.errmsg,
            }
          }
          if ((this.dateForm.endDate === '' || !this.dateForm.endDate) && !this.dateForm.neverexpire) {
            return {
              status: false,
              msg: this.errmsg1,
            }
          }
          result.data = `FREQ=DAILY;INTERVAL=${this.dateForm.day.daynum}${overTimer}`
          return result
        } else {
          if ((this.dateForm.endDate === '' || !this.dateForm.endDate) && !this.dateForm.neverexpire) {
            return {
              status: false,
              msg: this.errmsg1,
            }
          }
          result.data = `FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR${overTimer}`
          return result
        }
      } else if (this.dateForm.Model === 2) {
        if (this.dateForm.week.weeknum === '') {
          return {
            status: false,
            msg: this.errmsg,
          }
        }
        const weeks = []
        this.dateForm.week.weekList.map(item => {
          weeks.push(this.weeks.find(i => i.label === item).value)
        })
        if ((this.dateForm.endDate === '' || !this.dateForm.endDate) && !this.dateForm.neverexpire) {
          return {
            status: false,
            msg: this.errmsg1,
          }
        }
        result.data = `FREQ=WEEKLY;INTERVAL=${this.dateForm.week.weeknum};BYDAY=${weeks.toString()}${overTimer}`
        return result
      } else if (this.dateForm.Model === 3) {
        if (this.dateForm.month.monthnum === '') {
          return {
            status: false,
            msg: this.errmsg,
          }
        }
        if (this.dateForm.month.kind === 1) {
          if (this.dateForm.month.daymun === '') {
            return {
              status: false,
              msg: this.errmsg,
            }
          }
          if ((this.dateForm.endDate === '' || !this.dateForm.endDate) && !this.dateForm.neverexpire) {
            return {
              status: false,
              msg: this.errmsg1,
            }
          }
          result.data = `FREQ=MONTHLY;INTERVAL=${this.dateForm.month.monthnum};BYMONTHDAY=${this.dateForm.month.daymun}${overTimer}`
          return result
        } else {
          if (this.dateForm.month.weeknum === '' || this.dateForm.month.week === '') {
            return {
              status: false,
              msg: this.errmsg,
            }
          }
          if ((this.dateForm.endDate === '' || !this.dateForm.endDate) && !this.dateForm.neverexpire) {
            return {
              status: false,
              msg: this.errmsg1,
            }
          }
          result.data = `FREQ=MONTHLY;INTERVAL=${this.dateForm.month.monthnum};BYDAY=${this.dateForm.month.weeknum}${this.dateForm.month.week}${overTimer}`
          return result
        }
      } else {
        if (this.dateForm.year.monthnum === '' || this.dateForm.year.daynum === '') {
          return {
            status: false,
            msg: this.errmsg,
          }
        }
        if ((this.dateForm.endDate === '' || !this.dateForm.endDate) && !this.dateForm.neverexpire) {
          return {
            status: false,
            msg: this.errmsg1,
          }
        }
        result.data = `FREQ=YEARLY;INTERVAL=${this.dateForm.year.yearnum};BYMONTH=${this.dateForm.year.monthnum};BYMONTHDAY=${this.dateForm.year.daynum}${overTimer}`
        return result
      }
    },
    // 获取form数据
    getOrinData() {
      return this.dateForm
    },
    // 设置数据
    setOriginValue(form) {
      this.dateForm = form
    },
    // 重置数据
    resetData() {
      Object.assign(this.$data, this.oldData)
    },
  },
}
</script>

<style lang="scss" scoped>
.setting-time{
  color: #1d1b1b;
  background-color: #f9f9f9;
  padding: 10px 20px;
  /deep/.el-input__inner{
    border: 1px solid #dcdfe6
  }

}
/deep/.el-radio__label{
  color: #1d1b1b;
}
/deep/.el-checkbox__label{
  color: #1d1b1b;
}
.date-padding{
  padding: 10px 0;
}
.genal{
  padding: 10px 0;
  border-bottom:1px solid #dcdfe6;
  display:flex;
  align-items: center;

}
.intPadding_0{
  margin:0 3px;
  /deep/.el-input__inner{
    padding: 0;
  }
}
</style>
