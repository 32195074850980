<template>
  <div class="event-container">
    <div class="event-form-item">
      <div>{{ $t('message.calendar.cal8') }}:</div>
      <div class="center">
        <el-input
          v-model="carlendarReq.subject"
          maxlength="256"
          :placeholder="$t('message.calendar.info92')"
          :class="{subject:carlendarReq.subject.length>0}"
        />
      </div>
      <div class="event-select">
        <el-popover
          v-model="visible"
          placement="bottom"
          trigger="click"
          popper-class="tags-custom"
        >
          <ul class="el-scrollbar__view el-select-dropdown__list">
            <li
              v-for="item in tags"
              :key="item.tag_id"
              class="el-select-dropdown__item"
              style="max-width: 150px;"
              @click="selectEventTag(item)"
            >
              <span
                class="tagscolor"
                :style="{backgroundColor:item.tag_color,marginRight:'3px'}"
              />
              <span
                :title="item.tag_name"
                class="title"
              >{{ item.tag_name }}</span>
            </li>
          </ul>
          <div
            slot="reference"
            class="tags"
          >
            <span
              class="tagscolor"
              :style="{backgroundColor:selectTags.tag_color,marginRight:'3px'}"
            />
            <span
              class="title"
            >{{ selectTags.tag_name }}</span>
            <i
              :class="!visible?'el-icon-arrow-down':'el-icon-arrow-up'"
              style="float: right;"
            />
          </div>
        </el-popover>

      </div>
    </div>
    <div class="event-form-item">
      <div>{{ $t('message.calendar.cal9') }}:</div>
      <div class="center">
        <el-input
          v-model="carlendarReq.location"
          :placeholder="$t('message.calendar.cal9')"
        />
      </div>
    </div>
    <div class="event-form-item">
      <div class="cal_time">{{ $t('message.calendar.cal10') }}:</div>
      <div class="center">
        <el-date-picker
          v-model="date"
          :default-time="defaultTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm"
          type="datetimerange"
          :range-separator="$t('message.calendar.info28')"
          :start-placeholder="$t('message.calendar.info26')"
          :end-placeholder="$t('message.calendar.info27')"
        />
        <el-checkbox
          v-model="all_day_type"
          true-label="1"
          false-label="0"
          @change="changeFullDay"
        >{{ $t('message.calendar.info29') }}</el-checkbox>
      </div>
    </div>
    <div class="event-form-item">
      <div>{{ $t('message.calendar.cal30') }}:</div>
      <div class="center">
        <el-select
          v-model="tips"
          :placeholder="$t('message.calendar.cal37')"
          style="width: 150px;"
          @change="setType"
        >
          <el-option
            v-for="item in tipsModel"
            :key="item.value"
            :label="$t(`message.calendar.${item.label}`)"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="event-select">
        <el-button
          type="text"
          style="text-decoration: underline;"
          @click="timeCycle=!timeCycle"
        >{{ timeCycle?$t('message.calendar.info38'):$t('message.calendar.info39') }}</el-button>
      </div>
    </div>
    <activityCycle
      v-if="timeCycle"
      ref="Cycle"
    />
    <div class="event-form-item">
      <div>{{ $t('message.contact.quote') }}:</div>
      <div class="center">
        <el-input
          v-model="carlendarReq.desc"
          maxlength="100"
          :placeholder="$t('message.calendar.info93')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import activityCycle from './activityCycle.vue'
import calendar from '../calendar'
export default {
  components: { activityCycle },
  mixins: [calendar],
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      subject: '', // 主题
      date: '', // 日期
      tips: '15M', // 提醒
      Model: 1,
      radio: '1',
      value1: '',
      checked: false,
      timeCycle: false, // 活动周期
      selectTags: {},
      visible: false,
      checkList: [],
      carlendarReq: {
        subject: '', // 主题
        location: '', // 地点
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        valarm_time: '15', // 提醒时间
        valarm_type: 'M', // 提示类型
        tag_id: '', // 标签id
        rule: '', // 日程循环
        add_type: 1, // 创建方式
        desc: '', // 描述
        transp: '',
        from_id: '',
        all_day_type: '0',
      }, // 创建日程参数
      all_day_type: '',
      pickerOptions0: {
        disabledDate(time) {
          // return time.getTime() < this.disabledDate() - 24 * 60 * 60 * 1000
          return time.getTime() < Date.now() - 8.64e7
        },
      },
    }
  },
  computed: {
    defaultTime() {
      return ['2023-09-11 09:00:00', '2023-09-11 09:30:00']
    },
  },
  watch: {
    date(val) {
      console.log(val)
    },
  },
  methods: {
    selectEventTag(item) {
      this.visible = false
      this.selectTags = item
    },
    setType(value) {
      this.carlendarReq.valarm_time = value.substring(0, value.length - 1)
      this.carlendarReq.valarm_type = value.charAt(value.length - 1)
    },
    getReqData() {
      if (!this.date) {
        return {
          status: false,
          msg: this.$t('message.calendar.info77'),
        }
      }
      this.carlendarReq.start_time = this.date[0]
      this.carlendarReq.end_time = this.date[1]
      this.carlendarReq.tag_id = this.selectTags.tag_id
      this.carlendarReq.all_day_type = this.all_day_type
      if (this.timeCycle) {
        console.log('dsds')
        const res = this.$refs['Cycle'].getDate()
        console.log(res)
        if (res.status) {
          this.carlendarReq.rule = res.data
        } else {
          return {
            status: false,
            msg: res.msg,
          }
        }
      }
      return {
        status: true,
        result: this.carlendarReq,
      }
    },
    getOrinData() {
      return {
        subject: this.carlendarReq.subject,
        location: this.carlendarReq.location,
        date: this.date,
        tips: this.tips,
        tag: this.selectTags,
        desc: this.carlendarReq.desc,
        openCycle: this.timeCycle,
        ruleOrigin: this.timeCycle ? this.$refs['Cycle'].getOrinData() : '',
        all_day_type: this.all_day_type,
        from_id: this.carlendarReq.from_id,
      }
    },
    // 全天状态修改
    changeFullDay(flag) {
      if (this.date === '') {
        if (flag === '1') {
          // 创建一个新的日期对象
          var currentDate = new Date()

          // 从日期对象获取年、月、日、时、分、秒
          var year = currentDate.getFullYear()
          var month = ('0' + (currentDate.getMonth() + 1)).slice(-2) // Months are 0 based, hence add 1
          var day = ('0' + currentDate.getDate()).slice(-2)
          // 组合成一个字符串,并添加必要的分隔符
          this.date = [year + '-' + month + '-' + day + ' ' + '00:00:00', year + '-' + month + '-' + day + ' ' + '23:59:59']
        }
      } else {
        this.date = [this.date[0].substring(0, 10) + ' ' + '00:00:00', this.date[1].substring(0, 10) + ' ' + '23:59:59']
      }
    },
    resetData() {
      Object.assign(this.$data, this.$options.data())
    },
    setTag(tag) {
      this.selectTags = tag
    },
    setFromId(id) {
      this.carlendarReq.from_id = id
    },
  },
}
</script>

<style lang="scss" scoped>
.cal_time{
  position: relative;
  &::before{
  content: '*';
  position: absolute;
  color: red;
  left: -7px;
}
}
/deep/.el-checkbox-group{
  margin-top: 3px;
  .el-checkbox{
    margin-right: 14px;
    margin-bottom: 8px;
  }
}
/deep/.el-input__inner{
  border: none;
}
.event-form-item{
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D2D2D2;
  .center{
    flex: 1;
    .eve-input{
      width: 100%;
      outline: none;
      border: none;
    }
  }
}
.setting-time{
  background-color: #f9f9f9;
  padding: 10px 20px;
}
.date-padding{
  padding: 5px 0;
}
.tags{
  width: 100px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D2D2D2;
  .tagscolor{
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;
}
.title{
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 56px;
      white-space: nowrap;
    }
}

</style>
<style lang="scss">
.tags-custom{
  padding: 0!important;
  .tagscolor{
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
}
}
.subject{
  .el-input__inner{
    font-weight: bold;
  }
}
</style>
