import { GetFileType, transformClass, getQueryVariable, iframeEventPop } from '@/utils/utils.js'
import GetRecallType from '@/utils/recallType'
import serialize from '@/utils/serialize'
// import xss from '@/utils/xss'
import DOMPurify from 'dompurify' // 过滤xss攻击的
// import { getObjArr } from '@/utils/localstorage'
export default {
  name: 'Login',
  props: {
    readMailReqData: {
      type: Object,
      default() {
        return {}
      },
    },
    readMailUidMbox: {
      type: String,
      default() {
        return 'a'
      },
    },
    tabId: {
      type: String,
      default() {
        return 'a'
      },
    },
    fromType: {
      type: String,
      default() {
        return 'a'
      },
    },
  },
  components: {

  },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
  },
  data() {
    return {
      dialogVisible: false, // 消息提示
      dialogMsg: '', // 消息信息
      bodyHtml: {}, // 邮件信息
      attachments: [], // 附件
      flags: 0, // 星标
      form: '',
      to_s: [],
      cc_s: [],
      bcc_s: [],
      bodys: {},
      isHtml: true,
      replyValue: '',
      quickList: [this.$t('message.view.ok'), this.$t('message.view.copy_that'), this.$t('message.view.thanks')],
      textarea: this.$t('message.view.quick_rq'),
      isUnfold: true,
      rows: 20,
      btnTxet: this.$t('message.view.reply'),
      sentMailStatus: '', // 发件箱的发送状态
      sentMailStatusList: '', // 发件箱审核人的列表
      isReplyAll: false,
      replyTxt: '', // 快捷回复的内容
      isReplyTxt: false,
      recallMailInfo: {
        recallType: false, // 是否需要邮件召回
      },
      isPhishingEmail: false, // 是否打开钓鱼邮件
      videosrc: '', // 钓鱼视频链接
      dialogReceipt: false, // 是否需要回执
      isAuditMail: false, // 是否是邮件审核'
      auditMailInfo: {}, // 邮件审核的信息
      firstMail: true, // 是第一封邮件
      lastMail: true, // 是最后一封邮件
      // mailInfo: this.$store.state.tagsView.readMail.data, // 邮件的基本信息
      mailInfo: this.readMailReqData, // 邮件的基本信息
      // userConfig: '',
      replyList: [], // 快速回复列表
      tabList: [],
      downAttaReq: {}, // 下载附件参数
      isAttaDown: false,
      token: '', // 邮件审核的token
      nsnode: '', // 邮件审核节点
      click_from: '', // 邮件审核来源
      isShowDownBtn: false, // 审核是否完成
      isKmd: 'view', // kyms可疑、陌生、钓鱼邮件  view 邮件召回中的撤回邮件  down下载
      urlOrgin: '', // 点击页面的a链接
      auth_authority: 9, // 授权邮箱权限
      showMailPasswordDialog: false, // 是否显示密码邮件框
      mail_psw: '', // 邮件密码
      attaImg: [], // 要展示的附件附片
      fileListDisk: [], // 保存网盘选中的文件
      showDialogDisk: false, // 是否打开保存网盘
      isRouter: false,
      downType: '', // ky\ms下载类型--临时存储
      downEl: '', // ky\ms下载内容--临时存储
      webDisk: {}, // ky\ms转存网盘--临时存储
      dialogVisibleContact: false,
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      contact_id: '',
      addtitle: this.$t('message.view.add_new_contact'),
      idEdit: false,
      reply_receipt: this.$store.state.app.userGetConfig.reply_receipt, // 设置中回执选项
      // flowSrc: '/#/view-mail',
      showMemberInfo: false,
      loading: true,
      msgContent: {},
      audit_expired: false, // 邮件审核过期、撤回页面
      audit_expired_html: '', // 邮件审核过期、撤回页面 内容
      showMailbzDialog: false, // 邮件备注弹窗
      bzInfo: '', // 邮件备注信息
      mail_expired: '', // 当前邮件是否过期
      isMettingMail: false,
      isOrganizer: false,
      MettingCardData: null,
      sendAuditVisible: false, // 审核通知验证码通知
      auditTxt: this.$t('message.other.open_audit_code'),
      yzmAuditInpuy: '',
    }
  },
  computed: {
  },
  watch: {
    readMailUidMbox(newVal, oldVal) {
      this.mailInfo = JSON.parse(JSON.stringify(this.readMailReqData))

      // console.log(newVal, this.$store.state.tagsView.changMailFlags, this.mailInfo.uidvalidity, this.mailInfo.uid, '新的读信传值')
      this.showMsg_bodys_document()
      if (!this.isAuditMail) {
        this.textarea = this.$t('message.view.quick_replay') + this.form
      }
    },
  },
  beforeCreate() {
  },
  created() {
    this.$nextTick(() => {
      this.mail_psw = this.mailInfo.mail_psw
    })
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$myData.uriDatas, this.$myData.uriModule, 'this.$myData.uriDatas')
      var _this = this
      this.isAuditMail = Boolean(this.$myData.uriModule === '/audit') || Boolean(this.$myData.uriModule === '/banner-view/')
      _this.showMsg_bodys_document()
      _this.auth_authority = this.$store.state.app.auth_authority // 授权邮箱权限

      if (!_this.isAuditMail) {
        _this.textarea = this.$t('message.view.quick_replay') + _this.form
      }
    })
  },
  beforeDestroy() {
    // this.$store.commit('mail/ISDESTROY', false)
  },
  methods: {
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },

    // this.$myApi.updateMailStatus(objList)
    // [{ uidvalidity, uid, data }] --- objList的格式
    // 同步邮件内的操作
    updateMailStatusFromOuter(objList) {
      // [{ uidvalidity, uid, data }] --- objList的格式
      objList.forEach(obj => {
        // if (obj.data.seenFlag !== undefined) {
        //   // 已读未读--读信不需要处理
        // }
        if (obj.data.starFlag !== undefined) {
          // 星标
          if (obj.uidvalidity === this.mailInfo.uidvalidity && (obj.uid === this.mailInfo.uid || obj.uid === '-1')) {
            const str = obj.data.starFlag
            this.flags = str
          }
        }
        if (obj.data.tags !== undefined) {
          // 标签
          if (obj.uidvalidity === this.mailInfo.uidvalidity && (obj.uid === this.mailInfo.uid || obj.uid === '-1')) {
            // const str = obj.data.tags
            // this.tabList = str
            if (JSON.stringify(obj.data.tags) === '[]') {
              this.tabList = []
            } else {
              const tagArr = []
              if (JSON.stringify(this.tabList) === '[]' || obj.data.isCover) {
                this.tabList = obj.data.tags
              } else {
                this.tabList.forEach(i => {
                  tagArr.push(i.tag_id)
                })
                obj.data.tags.forEach(i => {
                  if (tagArr.indexOf(i.tag_id) < 0) this.tabList.push(i)
                })
              }
            }
          }
        }

        if (obj.data.comment !== undefined) {
          // 备注
          if (obj.uidvalidity === this.mailInfo.uidvalidity && (obj.uid === this.mailInfo.uid || obj.uid === '-1')) {
            const str = obj.data.comment
            this.bodyHtml.comment = str
          }
        }

        // if (objList[0].data.topping !== undefined) {
        //   // 置顶--读信不需要处理
        // }
      })
    },
    // 新标签跳转新页面
    // goBlack() {
    //   // this.$router.push({ path: '/print-view', query: this.newMailInfo })

    //   const routeData = this.$router.resolve({
    //     name: 'commercialPreview',
    //     path: '/print-view',
    //     query: { cylType: 'cylType' },
    //     params: { id: '9999' },
    //   })
    //   window.open(routeData.href, '_blank')
    // },
    // 邮件备注弹窗
    remarkDia(item) {
      this.showMailbzDialog = true
      this.bzInfo = item
    },
    changeRemark(e) {
      // 读信数据同步列表
      const remarkData = e[1]
      remarkData.comment = e[0]
      // 自己请求接口
      const objList = [{
        uidvalidity: this.mailInfo.uidvalidity,
        uid: this.mailInfo.uid,
        data: { comment: e[0] },
      }]
      this.$myApi.updateMailStatus(objList)
    },
    // 点击星标
    flagsClick(val) {
      // // 数据请求
      const mboxList = [{
        mbox: this.mailInfo.mbox,
        uidList: this.mailInfo.uid,
        uidvalidity: this.mailInfo.uidvalidity,
        uv: this.mailInfo.uidvalidity,
      }]
      const mailFlags = {
        flagsName: 'flagged', // 操作类型
        flagsValue: val, // 值
        outer: 'starFlag', // 自己定义的值--页签同步用
        mboxList: mboxList,
        isShowMessage: true, // 是否显示操作成功提示
      }
      this.flagSetAction(mailFlags)
    },

    /** 标记操作 */
    // flagSetAction({ commit }, data) {
    flagSetAction(data) {
      console.log(data)
      console.log('kkkkkkk走了吗')
      const params = {
        'module': 'mail',
        'require': {
          action: 'updateFlags',
          flags: { [data.flagsName]: data.flagsValue },
          mboxList: data.mboxList,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then(res => {
          console.log(res, 'res')
          const marker_options = {
            'seen:0': this.$t('message.view.unread'),
            'seen:1': this.$t('message.view.read'),
            'flagged:1': this.$t('message.view.flagged'),
            'flagged:0': this.$t('message.view.cancel_flagged'),
            'topping:1': this.$t('message.view.top'),
            'topping:0': this.$t('message.view.un_top'),
            'tag': this.$t('message.view.select_tag'),
          }
          const key = data.flagsName + ':' + data.flagsValue
          console.log(key, 'key')
          if (data.type === 'list' || data.isShowMessage) {
            this.$message({
              type: 'success',
              message: this.$t('message.view.mark_mail_ok', { flag: marker_options[key] }),
            })
          }
          if (data.outer === 'starFlag') this.flags = data.flagsValue

          // 自己请求接口
          const objList = [{
            uidvalidity: this.mailInfo.uidvalidity,
            uid: this.mailInfo.uid,
            data: { [data.outer]: data.flagsValue },
          }]
          this.$myApi.updateMailStatus(objList)
          // 标记为已读、未读更新文件夹的未读数量
          if (data.flagsName === 'seen') this.$myApi.mainMenu.refreshMboxList()
        })
    },
    // 邮箱名显示
    showName(i) {
      const type = this.$store.state.app.userGetConfig.show_tag_type
      const userInfo = [this.$store.state.app.user_mail_address, ...this.$store.state.app.user_permissions.all_alias]
      let name = ''
      if (this.isUnfold) {
        name = userInfo.indexOf(i[0]) !== -1 ? this.$t('message.view.me') : i[1]
      } else {
        if (type === 'long' && i[2] !== '') {
          name = userInfo.indexOf(i[0]) !== -1 ? this.$t('message.view.me') : i[1] + '[' + i[2] + ']'
        } else {
          name = userInfo.indexOf(i[0]) !== -1 ? this.$t('message.view.me') : i[1] + '<' + i[0] + '>'
        }
      }
      return name
    },
    getRecallType(name) {
      const type = new GetRecallType(name).toValue()
      return type
    },
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 是否能够预览
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    // 回传父组件当前邮件详情信息
    changeMaiModulelData() {
      const obj = {
        subject: this.bodyHtml.subject,
        uid: this.bodyHtml.uid,
        uidvalidity: this.bodyHtml.uidvalidity,
      }
      this.$emit('changeMailData', obj)
    },
    // 获取读信页面详情
    getMail(istab) {
      const need_send_info = this.mailInfo.need_send_info ? this.mailInfo.need_send_info : 1
      const orderby = this.mailInfo.orderby ? this.mailInfo.orderby : 'uid'
      const sequence = this.mailInfo.sequence ? this.mailInfo.sequence : 'DESC'
      const type = this.mailInfo.type ? this.mailInfo.type : 'folder'
      const uid = this.mailInfo.uid
      const uidvalidity = this.mailInfo.uidvalidity
      const uv = this.mailInfo.uv
      const scopes = this.mailInfo.scopes
      const other = this.mailInfo.other
      const words = this.mailInfo.words
      const search_id = this.mailInfo.search_id
      const filter_query = this.mailInfo.filter_query
      const topping = this.mailInfo.topping
      const mbox = this.mailInfo.mbox
      const req = {
        'module': 'mail',
        'require': {
          action: 'getMail',
          filter_query: filter_query,
          need_body: 1,
          need_info: 1,
          need_position: 1,
          need_send_info: need_send_info,
          orderby: orderby,
          sequence: sequence,
          type: type,
          uid: uid,
          uidvalidity: uidvalidity,
          uv: uv,
          mail_psw: this.mail_psw,
          scopes: scopes,
          other: other,
          words: words,
          search_id: search_id,
          topping: topping,
          mbox: mbox,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.loading = false
        // 判断是否是加密邮件
        if (res.result.mail_psw && res.result.mail_psw === 'yes') {
          this.showMailPasswordDialog = true
          return
        }
        // 更新未读书
        if (Number(res.result.mailData.seen_flag) === 0) {
          this.$myApi.mainMenu.refreshMboxList()
          this.$myApi.updateMailStatus([{ uidvalidity: uidvalidity, uid: uid, data: { seenFlag: 1 }}])
        }
        if (this.fromType === 'attachment') {
          // 如果是从附件管理进来，则需要给新标签重新赋值
          const readMail_data = {
            need_send_info: 0, // 是否返回邮件追踪信息 (只有发件箱有)1返回 0不返回
            orderby: res.result.mailData.orderby, // 邮件在当前列表的排序方式
            sequence: res.result.mailData.sequence, // 邮件在当前列表的排序顺序desc、asc
            type: res.result.mailData.type, // 查询类型，文件夹模式folder，搜索search
            scopes: res.result.mailData.scopes, // 高级搜索范围, subject(主题),body(正文),from(发件人),to(收件人),attachment(附件名), [{"subject":"fff"},{"body":"aaa"},{"time":"2023-03-01 , 2023-04-26"},{"from":"ff"},{"to":""},{"attachment":""}]
            words: res.result.mailData.words, // 搜索关键字
            search_id: res.result.mailData.search_id, // 搜索id，用于进行二次搜索
            mbox: res.result.mailData.mbox,
            flags: res.result.mailData.flags,
            mail_psw: res.result.mailData.mail_psw,
            topping: res.result.mailData.topping,
            readed_destory: res.result.mailData.readed_destory,
            is_expired: res.result.mailData.is_expired,
            tag: res.result.mailData.tag,
          }
          const readMail = this.readMailReqData
          this.mailInfo = {
            ...readMail,
            ...readMail_data,
          }
        }
        // 如果是过期邮件 还需要加邮件审核撤回的
        this.mail_expired = ''
        res.result.from === 0 ? this.firstMail = true : this.firstMail = false
        res.result.from + 1 === Number(res.result.count) ? this.lastMail = true : this.lastMail = false
        this.bodyHtml = res.result.mailData
        // 判断邮件是否过期

        // 如果是过期邮件，自动跳转上一封或者下一封
        // if (res.result.is_expired) {
        //   // console.log('过期邮件')
        //   // this.closeDialog()
        //   // return false
        // }
        if (res.result.mailData.bodys && res.result.mailData.bodys.length) {
          res.result.mailData.bodys[0].substring(0, 1) === 'h' ? this.isHtml = true : this.isHtml = false
        }
        this.showMemberInfo = true
        console.log(res.result, 'res.result')
        if (this.isHtml) {
          // 返回的是html格式
          // this.bodys = res.result.mailData.bodys.length ? DOMPurify.sanitize(res.result.mailData.bodys[0].substring(1)) : ''
          this.bodys = res.result.mailData.bodys.length ? DOMPurify.sanitize(res.result.mailData.bodys[0].substring(1)) : ''
        } else {
          // 返回的是text格式
          this.bodys = res.result.mailData.bodys.length ? res.result.mailData.bodys[0].substring(1).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '\n<br/>').replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') : ''
        }
        // this.$store.commit('mail/MAILBODY', DOMPurify.sanitize(this.bodys))

        // 显示
        this.showBody()
        // 拦截a链接
        // this.listenLink()
        if (res.result.mailData.readed_destory === '1') {
          this.mailInfo.readed_destory = true
          // 标记是否是销毁邮件
          // this.$store.commit('mail/ISDESTROY', true)
        }

        // 处理会议邀请信---->开始
        if (res.result.mailData.cal_data) {
          this.isMettingMail = true
          this.MettingCardData = res.result.mailData.cal_data
          this.isOrganizer = res.result.mailData.cal_data.is_organizer === 1
        } else {
          this.isMettingMail = false
          this.isOrganizer = false
          this.MettingCardData = null
        }
        // ------>end

        this.mail_psw = ''// 清空此次密码
        this.to_s = res.result.mailData.to_s
        this.cc_s = res.result.mailData.cc_s
        this.bcc_s = res.result.mailData.bcc_s
        this.attachments = res.result.mailData.attachments
        this.form = res.result.mailData.from_name
        this.textarea = this.$t('message.view.quick_replay') + res.result.mailData.from_name
        this.flags = res.result.mailData.flags.charAt(3)
        this.tabList = res.result.mailData.tag
        // debugger
        this.dialogReceipt = res.result.mailData.receipt.length !== 0 && res.result.mailData.flags.charAt(5) !== '1' && res.result.mailData.mbox !== 'Sent'
        console.log(this.dialogReceipt, 'this.dialogReceipt')
        console.log(res.result.mailData, 'mailData')
        if (res.result.mailData.mbox === 'Sent' && this.$store.state.app.auth_authority !== 0 && this.$store.state.app.auth_authority !== 1) {
          // this.sentMailStatus = this.getRecallType(res.result.send_info.label)
          this.sentMailStatus = this.$t(`message.mailstatus.${res.result.send_info.label}`)
          if (res.result.send_info.label === 'Auditing' || res.result.send_info.label === 'HasAudited') {
            let list = ''
            res.result.send_info.audit_list.map((item, index) => {
              if (res.result.send_info.audit_list.length - 1 === index) {
                list = list + item.audit_user_name + '<' + item.audit_user_mail + '>'
              } else {
                list = list + item.audit_user_name + '<' + item.audit_user_mail + '>;'
              }
            })
            this.sentMailStatusList = list
          }
        }
        this.bodyHtml.mbox = transformClass.mbox(this.bodyHtml.uidvalidity, this.$myData.mbox_list)
        const str = this.bodyHtml.flags.split('')
        if (str[1] === '0') {
          str[1] = 1
          this.bodyHtml.flags = str.join('')
          /** 邮箱文件夹未读数量减1 */
          const mbox_unseen = Number(this.$store.state.tagsView.menu_unseen[this.bodyHtml.mbox])
          this.$store.state.tagsView.menu_unseen[this.bodyHtml.mbox] = mbox_unseen - 1
          this.$store.state.tagsView.mail_list.forEach(item => {
            if (item.uid === this.bodyHtml.uid && item.uidvalidity === this.bodyHtml.uidvalidity) {
              item.flags = str.join('')
            }
          })
        }
        // 是否显示内嵌图片
        const domparser = new DOMParser()
        var doc = domparser.parseFromString(this.bodys, 'text/html')
        const arr = doc.body.getElementsByTagName('img')
        this.show_always_img(arr)
        // 是否显示附件图片
        if (this.$store.state.app.userGetConfig.show_att_image === 'yes') {
          this.show_attat_img()
        }
        // 回传父组件
        this.changeMaiModulelData()
      })
    },
    // 显示内嵌图片
    show_always_img(arr) {
      if (arr.length !== 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.width = 'auto'
        }
      }
      let haveimg
      if (arr.length === 0) {
        haveimg = false
      } else {
        haveimg = true
      }
      if (haveimg) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.display = 'block'
          arr[i].style.width = 'auto'
        }
        if (this.isHtml) {
          const imgDiv = window.document.createElement('div')
          // 内嵌图片示例
          // this.bodys = JSON.parse(JSON.stringify('<html><body>\r\n<div background="cid:_Foxmail.1@e6a26adb-7777-e5dc-74b5-5cff85f32aa8">\r\nsss\r\n<hr />\r\n<img src="cid:_Foxmail.1@e6a26adb-7777-e5dc-74b5-5cff85f32aa8" /><div background="cid:_Foxmail.1@66666666666"></div>\r\n</div>\r\n\r\n</body>\r\n</html>'))
          imgDiv.innerHTML = this.bodys
          const divbox = imgDiv.getElementsByTagName('*')
          if (this.$store.state.app.userGetConfig.show_image === 'always' || this.$store.state.app.userGetConfig.show_image === 'contact') {
            for (let i = 0; i < divbox.length; i++) {
            // 获取所有的background
              const back = divbox[i].getAttribute('background') || ''
              if (back !== '' && back.substring(0, 4) === 'cid:') {
                const new_back = this.showImg(back.substring(5))
                divbox[i].setAttribute('background', new_back)
              }
            }
            // 获取所有的imgs
            const imgs = imgDiv.getElementsByTagName('img')
            for (let i = 0; i < imgs.length; i++) {
              const img = imgs[i]
              // 图片不能太大, 变小后可以点击还原
              img.style.maxWidth = '95%'
              const url = img.src || ''
              if (url !== '' && img.src.substring(0, 4) === 'cid:') {
                const new_url = this.showImg(img.src.substring(5))
                img.src = new_url
              }

              // 还需要判断发件人是否在通讯录里
              if (this.$store.state.app.userGetConfig.show_image === 'contact' && !this.bodyHtml.show_in_images) {
                if (url !== '') {
                  img.src = ''
                  img.style.display = 'none'
                }
              }

              img.onload = () => {
                this.showBody()
              }
            }
          } else if (this.$store.state.app.userGetConfig.show_image === 'never') {
            // 获取所有的imgs
            const imgs = imgDiv.getElementsByTagName('img')
            for (let i = 0; i < imgs.length; i++) {
              const img = imgs[i]
              const url = img.src || ''
              if (url !== '') {
                img.src = ''
                img.style.display = 'none'
              }
              img.onload = () => {
                this.showBody()
              }
            }
          }
          this.bodys = JSON.parse(JSON.stringify(imgDiv.innerHTML))
          this.showBody()
        }
      } else {
        for (let i = 0; i < arr.length; i++) {
          arr[i].src = ''
          arr[i].style.display = 'none'
        }
      }
    },
    // 显示附件图片
    show_attat_img(mo, tab) {
      this.attaImg = []
      this.attachments.forEach(el => {
        if (this.getFileType(el.name) === 'img') {
          const req = {
            disposition: 'inline',
            encoding: el.encoding,
            mbox: this.mailInfo.mbox,
            module: 'mail_attachment',
            section: el.section,
            uid: this.mailInfo.uid,
            uv: this.mailInfo.uv,
            preview: 1,
          }
          const url = '/nsmail/mail.php/fn.' + this.getFileType(el.name) + '?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
          this.attaImg.push(url)
        }
      })
    },
    // 图片的转换  将内嵌图片cid的转换成邮件可读的图片
    showImg(cid) {
      let url = ''
      this.attachments.forEach(el => {
        if (el.cid.substr(2, el.cid.length - 3) === cid) {
          const req = {
            disposition: 'inline',
            encoding: el.encoding,
            mbox: this.mailInfo.mbox,
            module: 'mail_attachment',
            section: el.section,
            uid: this.mailInfo.uid,
            uv: this.mailInfo.uv,
            preview: 1,
          }
          url = '/nsmail/mail.php/fn.' + this.getFileType(el.name) + '?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
        }
      })
      return url
    },
    // 获取审核邮件详情
    getAuditMail() {
      const req = { 'module': 'audit_mail', 'require': { action: 'view_mail_audit', token: this.token }}
      this.$axios.post(this.$apiData.mailBrevity + '?nsnode=' + this.nsnode + '&click_from=' + this.click_from + '&task=audit_mail', { req: serialize(req) }).then(res => {
        // 显示
        if (res.status === 10000 || res.status === 9999) {
          this.sendAuditVisible = true
          this.audit_expired = true
          this.audit_expired_html = '请先登录'
          return false
        }
        if (res.status === 50001) {
          // 邮件审核已过期或者撤回
          this.loading = false
          this.audit_expired = true
          this.audit_expired_html = res.msg
          return false
        }
        this.loading = false

        this.audit_expired = false
        this.auditMailInfo = res.result
        this.bodyHtml = res.result.polling_info
        localStorage.setItem('lang', res.result.language)
        this.$i18n.locale = res.result.language
        this.bodys = res.result.polling_info.bodys
        this.to_s = res.result.polling_info.to_s
        this.cc_s = res.result.polling_info.cc_s
        this.bcc_s = res.result.polling_info.bcc_s
        this.attachments = res.result.polling_info.mail_atts
        this.form = res.result.polling_info.from_name_l[1]
        // 保存预览类型
        this.$store.commit('app/preview_atta_type_SET', res.result.preview_atta_type)
        this.showBody()
        if (Number(this.auditMailInfo.my_audit_approve.level_status) < 3 && Number(this.auditMailInfo.my_audit_approve.user_status) < 3 && Number(this.auditMailInfo.polling_info.mail_status) < 3) {
          this.isShowDownBtn = true
        }
        // 是否显示内嵌图片
        const imgDiv = window.document.createElement('div')
        imgDiv.innerHTML = this.bodys
        const arr = imgDiv.getElementsByTagName('img')
        for (let i = 0; i < arr.length; i++) {
          arr[i].style.display = 'block'
          arr[i].style.width = 'auto'
          arr[i].onload = () => {
            this.showBody()
          }
        }
        this.sendAuditVisible = false
      })
    },
    // iframe 显示读信
    showMsg_bodys_documentInfo() {
      // 向iframe传值
      const viewIframe = this.$refs['iframe']
      let doc = '<div></div>'
      try {
        doc = viewIframe.contentWindow.document
      } catch {
        console.log('111error', viewIframe)
      }
      let h = '<!DOCTYPE html><HTML>'
      h += '<HEAD>'
      h += '<TITLE>应该到加载normalize.css, 以保证显示效果一致</TITLE>'
      h += '<META CHARSET="utf-8"><META HTTP-EQUIV="X-UA-Compatible">'
      h += '</HEAD>'
      h += '<BODY style="'
      h += 'overflow: hidden;'
      h += 'width: 100%;'
      h += 'margin: 0px;'
      h += 'padding: 0px;'
      h += 'display: inline-block;'
      h += 'word-break: break-all;'
      // h += 'white-space: pre-wrap;';
      h += 'font-family: FZLTHJW;'
      h += 'background-color:white;'
      h += '">'
      h += '<style>'
      h += 'table{max-width:100%}'
      h += '</style>'
      h += '<DIV ID="viewer"></DIV>'
      h += '</BODY>'
      h += '</HTML>'
      doc.open()
      doc.write(h)
      doc.close()
      this.msgContent.doc = doc

      // this.$refs.mailbody.append(this.bodys)
      iframeEventPop(doc) // 解除iframe的事件
      const _this = this
      // 获取审核邮件详情
      if (_this.isAuditMail) {
        _this.token = this.$myData.uriDatas.token
        _this.nsnode = this.$myData.uriDatas.nsnode
        const source = this.$myData.uriDatas.client_from
        console.log(source, 'source')
        _this.click_from = source === 'null' || source == null ? '' : source
        _this.getAuditMail()
      } else {
        // 获取正常读信
        _this.getMail()
      }
    },
    showMsg_bodys_document() {
      this.audit_expired = false
      this.$nextTick(() => {
        this.showMsg_bodys_documentInfo()
      })
    },
    showBody() {
      // 给iframe里面送值
      const viewer = this.msgContent.doc.getElementById('viewer') || this.msgContent.doc.createElement('div')
      viewer.innerHTML = this.bodys
      // setTimeout(() => {
      this.showMsg_viewer_height()
      // }, 1000)
    },
    // iframe的宽高 每次加载完图片都需要重新调用
    showMsg_viewer_height() {
      const viewIframe = this.$refs['iframe']
      if (viewIframe) {
        const doc = viewIframe.contentWindow.document || '<div></div>'
        // console.log(doc.body.scrollHeight, 'doc.body.scrollHeight')

        setTimeout(() => {
          viewIframe.height = doc.body.scrollHeight < 500 ? 500 : doc.body.scrollHeight
        }, 1000)
        // 拦截a链接
        this.listenLink()
      }
    },
    // 是否展开详情
    Unfold(type) {
      type ? this.isUnfold = false : this.isUnfold = true
    },
    // 子组件密码邮件变化
    mailPasswordFun(e) {
      if (!e[0]) {
        this.$message.error(this.$t('message.view.psw_error'))
      } else {
        this.mail_psw = e[1]
        this.getMail()
        this.showMailPasswordDialog = false
      }
    },
    // 点击快速回复区域
    handViewBot() {
      this.isReplyTxt = true
      this.$nextTick(() => {
        document.querySelector(`#replyTxt`).focus()
      })
    },
    // 切换回复、回复全部
    handReplyAll(type) {
      if (!type) {
        this.isReplyAll = false
        this.textarea = this.$t('message.view.quick_replay') + this.form
        this.btnTxet = this.$t('message.view.reply')
      } else {
        this.isReplyAll = true
        this.textarea = this.$t('message.view.quick_replay_all')
        this.btnTxet = this.$t('message.view.reply_all')
      }
    },
    // 获取快速回复列表
    GetFastreplyMsg() {
      const req = { 'module': 'mail', 'require': { action: 'GetFastreplyMsg' }}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.replyList = res.result
      })
    },
    // 快速回复
    toCompose() {
      const userInfo = [this.$store.state.app.user_mail_address, ...this.$store.state.app.user_permissions.all_alias, this.bodyHtml.from_address]
      const to = this.to_s.map(item => {
        if (userInfo.indexOf(item[0]) === -1) {
          return item
        }
      })
      const cc = this.cc_s.map(item => {
        if (userInfo.indexOf(item[0]) === -1) {
          return item
        }
      })
      const req = {
        body: this.replyTxt, // 正文,
        text_content: this.replyTxt, // 正文文本
        subject: this.$store.state.app.userGetConfig.prefix_re + this.bodyHtml.subject, // 主题
        to: this.isReplyAll ? [...to, ...[this.bodyHtml.from_name_l]] : [this.bodyHtml.from_name_l], // 收件人
        cc: this.isReplyAll ? [...cc] : '', // 抄送
        bcc: '', // 密送
        atts: [], // 附件
        atts_left: [],
        atts_oldto: [],
        date: '', // 日期
        is_save_sent: this.$store.state.app.userGetConfig.sent_autosave === 'yes' ? 1 : 0,
        undownload: 0,
        unprint: 0,
        unresponse: 0,
        untransmit: 0,
        readed_destory: 0, // 阅读后销毁
        receipt: 0, // 回执
        priority: 0, // 紧急
        is_timing: 0, // 定时邮件
        delaysend_time: '',
        expire_time: '', // 过期时间
        mail_psw: '', // 邮件密码
        identity: '',
        mbox_draft: 'Drafts',
        relevant_id: '',
        replay: '',
        is_singledisplay: 0,
        last_draft_uid: '',
        mail_type: '',
      }
      const params = {
        module: 'mail',
        require: {
          action: 'send',
          data: req,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
        if (result.result) {
          this.$message.success(this.$t('message.view.replay_ok'))
          this.replyTxt = ''
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    // 审核附件下载、预览、打包下载
    auditDownAtta(type, el) {
      let path = ''
      if (el === 'allDown') {
        path = '/nsmail/mail.php?nsnode=' + this.nsnode + '&task=audit_mail&req={' + '"module":"audit_mail","require":{"action":"pack_download","message_id":"' + this.bodyHtml.message_id + '","x_ns_mid": "' + this.bodyHtml.mid + '","secret_terminal":"0","from_type":"web"}}'
      } else {
        const url = type === 'preview' ? '/nsmail/mail.php/' + el.file_name + '?' : '/nsmail/mail.php?'
        path = url + 'nsnode=' + this.nsnode + '&task=audit_mail&req={' + '"module":"audit_mail","require":{"action":"download_attachment","message_id":"' + this.bodyHtml.message_id + '","x_ns_mid": "' + this.bodyHtml.mid + '","secret_terminal":"0","index":" ' + el.index + '","type":"' + type + '","from_type":"web"}}'
      }
      return path
    },
    // 下载邮件
    downMail() {
      const req = {
        disposition: 'attachment',
        mail_seclevel: this.bodyHtml.mail_seclevel,
        mbox: this.mailInfo.mbox,
        message_id: this.mailInfo.message_id,
        module: 'mail_attachment',
        section: 'self',
        // subject: encodeURI(this.bodyHtml.subject.replace(/[\/ ?\t\r\n:\|\\*\"\'<>]/g, ' ')) + '.eml',
        subject: this.bodyHtml.subject + '.eml',
        uid: this.mailInfo.uid,
        uv: this.mailInfo.uv,
      }
      this.$refs.downMailForm.formLabelAlign.req_data = serialize(req)
      this.$refs.downMailForm.down(this.bodyHtml.subject + '.eml')
    },
    // 邮件页面打包下载
    downAtta(type, el) {
      if (this.bodyHtml.is_suspicious === '1') {
        this.dialogVisible = true
        this.isKmd = 'down'
        this.dialogMsg = this.$t('message.view.risk_mail')
        this.downType = type
        this.downEl = el
        return false
      }
      if (this.bodyHtml.is_strange === '1') {
        this.dialogVisible = true
        this.isKmd = 'down'
        this.dialogMsg = this.$t('message.view.risk_mail')
        this.downType = type
        this.downEl = el
        return false
      }
      this.downAction(type, el)
    },

    // 邮件页面打包下载
    async downAction(type, el) {
      const disposition = el === 'allDown' ? 'attachment' : el.disposition
      let name = this.attachments[0].name
      const index = name.lastIndexOf('.')
      name = name.substring(0, index)

      if (el === 'allDown') {
        // 打包下载
        this.downAttaReq = {
          list: this.attachments,
          module: 'mail_attachment',
          att_type: 'multi',
          disposition: 'attachment',
          from_type: 'mail',
          attachment_name: name + '.zip',
          uid: this.mailInfo.uid,
          uv: this.mailInfo.uv,
          mbox: this.mailInfo.mbox,
          subject: this.bodyHtml.subject,
        }
      } else {
        // 单个下载
        this.downAttaReq = {
          module: 'mail_attachment',
          atta_seclevel: el.atta_seclevel,
          attachment_name: el.name,
          encoding: el.encoding,
          mbox: this.mailInfo.mbox,
          message_id: this.bodyHtml.message_id,
          secret_terminal: 0,
          section: el.section,
          uid: this.mailInfo.uid,
          uv: this.mailInfo.uv,
        }
        if (type === 'preview') {
          this.downAttaReq.preview = 1
          this.downAttaReq.disposition = 'inline'
          this.downAttaReq.suffix = el.name.substring(el.name.lastIndexOf('.') + 1).toLowerCase()
          const type = ['txt', 'pdf', 'jpe', 'jpeg', 'jpg', 'gif', 'png']
          if (!type.includes(this.downAttaReq.suffix)) {
            const t = {
              module: 'mail',
              require: {
                action: 'attachmentPreview',
                ...this.downAttaReq,
              },
            }
            const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(t) })
            if (Array.isArray(res)) {
              return this.$alert(this.$t('message.view.not_support_type'), this.$t('message.btnText.info'), {
                confirmButtonText: this.$t('message.btnText.ok'),
                type: 'warning',
              })
            } else {
              window.open(res.preview_url)
            }
            return false
          }
        } else {
          this.downAttaReq.disposition = disposition
        }
      }
      this.isAttaDown = true
    },
    // 转存网盘
    handleIcludClick(val) {
      if (this.bodyHtml.is_suspicious === '1') {
        this.dialogVisible = true
        this.isKmd = 'webDisk'
        this.dialogMsg = this.$t('message.view.risk_mail_save_drive')
        this.webDisk = val
        return false
      }
      if (this.bodyHtml.is_strange === '1') {
        this.dialogVisible = true
        this.isKmd = 'webDisk'
        this.dialogMsg = this.$t('message.view.strange_mail_save_drive')
        this.webDisk = val
        return false
      }
      this.webDiskFun(val)
    },
    // 转存网盘窗口
    webDiskFun(val) {
      const row = {
        mail_mbox: this.mailInfo.mbox,
        uidvalidity: this.mailInfo.uidvalidity,
        uid: this.mailInfo.uid,
        att_section: val.section,
        att_encoding: val.encoding,
        att_name: val.name,

      }
      this.showDialogDisk = true
      this.fileListDisk = []
      this.fileListDisk.push(row)
    },
    // 切换更多回复内容时
    txtSel() {
      this.handViewBot()
      this.replyTxt = this.replyValue
    },
    // 上一封、下一封邮件
    prevMail(action, obj) {
      const opt = {
        type: this.mailInfo.type, // 文件类型
        orderby: this.mailInfo.orderby, // 排序种类
        ordervalue: this.bodyHtml[this.mailInfo.orderby], // 排序关键值
        envelope_date: this.bodyHtml.envelope_date, // 信件时间
        envelope_date_sort: this.bodyHtml.envelope_date_sort, // 信件排序值
        need_position: '1',
        need_send_info: '0',
        sequence: this.mailInfo.sequence, // 排序方式
        topping: this.bodyHtml.topping, // 置顶值
        uidvalidity: this.bodyHtml.uidvalidity,
        uid: this.bodyHtml.uid,
        uv: this.mailInfo.uv,
        which: action,
        search_id: this.mailInfo.search_id,
        filter_query: this.mailInfo.filter_query,
        scopes: this.mailInfo.scopes,
        words: this.mailInfo.words,
        other: this.mailInfo.other,
      }
      const req = { 'module': 'mail', 'require': { action: 'getMailLN', ...opt }}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        if (res.result.ln[0] === -1 || res.result.ln[1] === -1) {
          // 移动后如果是最后一封信 关闭标签页
          this.$emit('closeMe')
          this.$myApi.mainTab.goBackMain(this.readMailReqData.mailFolderType)
          return false
        }
        this.mailInfo.uidvalidity = res.result.ln[0]
        this.mailInfo.uv = res.result.ln[0]
        this.mailInfo.uid = res.result.ln[1]
        this.getMail('tab_replace') // 上一封、下一封替换标签
      })
      // }
    },
    // 点击邮件召回
    Recall_mail() {
      this.recallMailInfo = {
        recallType: true,
        message_id: this.bodyHtml.message_id,
        mid: this.bodyHtml.mid,
        time: this.bodyHtml.envelope_date,
        subject: this.bodyHtml.subject,
        mail_seclevel: this.bodyHtml.mail_seclevel,
        over_recall_time: this.bodyHtml.over_recall_time,
      }
    },
    // 跳转到邮件追踪
    Recall_mail_tacking() {
      this.$myApi.mainTab.showTracking()
    },

    // 跳转到邮件审核
    go_mail_audit() {
      this.$myApi.mainTab.showAudit()
    },
    changeList(e) {
      this.mailInfo.recallType = false
    },
    // 点击撤回
    withdraw_mail() {
      this.isKmd = 'view'
      this.dialogVisible = true
      this.dialogMsg = this.$t('message.view.mail_auditing_withdraw')
    },
    // 点击关闭撤回弹窗-自动跳转到下一封
    closeDialog() {
      if (this.isKmd === 'kyms') {
        this.dialogVisible = false
        setTimeout(() => {
          this.attackFishing('url')
        }, 500)
      } else if (this.isKmd === 'down') {
        this.downAction(this.downType, this.downEl)
      } else if (this.isKmd === 'webDisk') {
        this.webDiskFun(this.webDisk)
      } else {
        let action = 'next'
        this.lastMail ? action = 'prev' : action = 'next'
        this.prevMail(action)
      }
      if (this.isKmd === 'view') {
        // 撤回审核
        const req = {
          'module': 'mail', 'require': {
            action: 'callback_audit',
            message_id: this.bodyHtml.message_id,
            x_ns_mid: this.bodyHtml.mid,
          },
        }
        this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        // if (res.status === 'ok') {
          this.$message.ok(this.$t('message.view.withdraw_ok_move_draft'))
        // }
        })
      }
      this.dialogVisible = false
    },
    // 是否点击a链接
    attackFishing(type) {
      if (this.bodyHtml.phishing_id !== '' && !this.isAuditMail) {
        const req = {
          'module': 'phishingMail', 'require': {
            action: 'attackUsers',
            phishing_id: this.bodyHtml.phishing_id,
            type: type,
          },
        }
        this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
          this.isPhishingEmail = true
        })
      } else {
        window.open(this.urlOrgin)
      }
    },
    // 通知信点击审核跳转
    auditLinkUrl(url) {
      /** 跳转读信 */
      const req = {
        token: getQueryVariable.url('token', url),
        nsnode: getQueryVariable.url('nsnode', url),
        isAudit: getQueryVariable.url('isAudit', url),
        client_from: getQueryVariable.url('client_from', url),
      }
      // const uid = getQueryVariable.url('token', url)
      // // 新标签页打开，加入标签栏
      // this.$store.commit('tagsView/READ_MAIL_SETTING', { // 读信请求参数
      //   path: '/whole-view', // banner-view
      //   subject: '邮件审核',
      //   isOpen: true,
      //   data: {
      //     ...req,
      //   },
      // })
      // this.$router.push({ path: '/whole-view', query: { uid: uid, mbox: 'audit' }})
      // this.$myApi.mainTab.showWholeMail('audit', req)
      window.open(window.location.origin + '/#/audit?' + JSON.stringify(req))
    },
    // 拦截 点击a标签的拦截
    async listenLink() {
      // var body = this.msgContent.doc.getElementById('viewer')
      var body = this.msgContent.doc.getElementById('viewer').getElementsByTagName('A')
      for (let index = 0; index < body.length; index++) {
        body[index].addEventListener('click', async(e) => {
          e.preventDefault()
          // window.event.returnValue = false// IE
          // console.log('哈哈哈哈', body[index].href)
          // if (e.preventDefault) {
          // e.preventDefault()
          this.urlOrgin = body[index].href
          if (getQueryVariable.url('isAudit', this.urlOrgin)) {
            // 如果是审核通知信地址
            this.auditLinkUrl(this.urlOrgin)
          } else {
            if (this.bodyHtml.is_suspicious === '1') {
              this.dialogVisible = true
              this.isKmd = 'kyms'
              this.dialogMsg = this.$t('message.view.risk_mail_tolink')
            } else if (this.bodyHtml.is_strange === '1') {
              this.dialogVisible = true
              this.isKmd = 'kyms'
              this.dialogMsg = this.$t('message.view.strange_mail_tolink')
            } else {
              this.attackFishing('url')
            }
          }
          // } else {
          //   window.event.returnValue = false// IE
          //   // 注意：这个地方是无法用return false代替的
          // }
        })
      }

      // body.addEventListener('click', async(e) => {
      //   console.log('a链接点解了没有', e.target.tagName, e.target, e.target.parentElement, e)
      //   if (e.target.tagName === 'A') {
      //     console.log('a链接点解了没有11111')
      //     if (e.preventDefault) {
      //       e.preventDefault()
      //       this.urlOrgin = e.target.href
      //       console.log('a链接')
      //       if (this.isPrintMail) {
      //         return
      //       }
      //       if (getQueryVariable.url('isAudit', this.urlOrgin)) {
      //       // 如果是审核通知信地址
      //         this.auditLinkUrl(this.urlOrgin)
      //       } else {
      //         if (this.bodyHtml.is_suspicious === '1') {
      //           this.dialogVisible = true
      //           this.isKmd = 'kyms'
      //           this.dialogMsg = '此邮件来自可疑的发信地址，访问链接可能造成操作风险，是否仍要继续？'
      //         } else if (this.bodyHtml.is_strange === '1') {
      //           this.dialogVisible = true
      //           this.isKmd = 'kyms'
      //           this.dialogMsg = '此邮件来自陌生邮件地址，访问链接可能造成操作风险，是否仍要继续？'
      //         } else {
      //           this.attackFishing('url')
      //         }
      //       }
      //     } else {
      //       window.event.returnValue = false// IE
      //       // 注意：这个地方是无法用return false代替的
      //     }
      //   }
      // })
    },
    // 点击钓鱼邮件
    Phishing_mail(type) {
      this.isPhishingEmail = false
      if (type === 'video') {
        // this.bofang()
        this.$store.commit('app/ISPHISHINGEMAIL', true)
      }
    },
    // 是否出现钓鱼视频
    // bofang() {
    //   this.checkLearnFishing()
    // },
    // checkLearnFishing() {
    //   const req = {
    //     'module': 'phishingMail',
    //     'require': {
    //       action: 'getToLearningList',
    //     },
    //   }
    //   this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
    //     const result = res.result
    //     if (result.length === 0) {
    //       return false
    //     }
    //     var videoInfo = result[0]
    //     this.$refs.child.checkVideoFun()
    //     this.videosrc = videoInfo.learning_url
    //   })
    // },
    // 子组件标签list变化--标签
    tab_changeList(e) {
      this.tabList = e[0]
      // 自己请求接口
      const objList = [{
        uidvalidity: this.mailInfo.uidvalidity,
        uid: this.mailInfo.uid,
        data: { tags: e[0], isCover: true },
      }]
      this.$myApi.updateMailStatus(objList)
      console.log(e[0], 'eee')

      this.$refs.tabListBox.hide()
    },
    handVisibleChange(e) {
      if (e) {
        const a = JSON.parse(JSON.stringify(this.tabList))
        this.tabList = []
        this.tabList = a
      }
    },
    timeDispose(dates) {
      if (dates < 10) {
        return '0' + dates
      } else {
        return dates
      }
    },
    // 时间转换
    gettime(item) {
      var getTimes = new Date(parseInt(item) * 1000)
      var monthGet = getTimes.getMonth() + 1
      var time = getTimes.getFullYear() + '/' + this.timeDispose(monthGet) + '/' + this.timeDispose(getTimes.getDate()) + ' ' + this.timeDispose(getTimes.getHours()) + ':' + this.timeDispose(getTimes.getMinutes())
      return time
    },
    // 联系人名片的新建和编辑
    onHandelAddcontact(type, data) {
      if (type === 'add') {
        this.addtitle = this.$t('message.view.add_new_contact')
        this.ruleForm = {
          phones: [{
            key: Date.now(),
            value: '',
          }],
          group: '',
          name: data.name,
          mail: data.mail,
          company: '',
          remarks: '',
        }
        this.dialogVisibleContact = true
      } else {
        this.editUser(data)
      }
    },
    async editUser(data) {
      this.addtitle = this.$t('message.view.edit_contact')
      this.contact_id = data.contactId
      const res = await this.getContactInfo(data.contactId)
      if (res.status === 200) {
        const phone = res.result.contact_attrs.cellphone.split(',')
        const arrs = {
          phones: phone.map((item, i) => {
            return { key: i, value: item }
          }),
          group: [...res.result.group_id_arr],
          name: res.result.contact_name,
          mail: res.result.contact_mail,
          company: res.result.contact_attrs.company,
          remarks: res.result.contact_attrs.notes,
        }
        this.idEdit = true
        this.ruleForm = arrs
        this.dialogVisibleContact = true
      }
    },
    // 更新联系人信息
    getContactInfo(id) {
      const params = {
        module: 'contact',
        require: {
          action: 'getInfo',
          contact_id: id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          if (result.status === 200) {
            resolve(result)
          }
        })
      })
    },
    addUserClosed() {
      this.dialogVisibleContact = false
    },
    addUserSubmit() {
      this.dialogVisibleContact = false
    },
    handleClose() {
      return false
    },
    // 发送审核验证码通知信
    sendAuditCodeFun() {
      const req = {
        'module': 'audit_mail',
        'require': {
          action: 'client_send_code',
          token: this.token,
        },
      }
      this.$axios.post(this.$apiData.audit_mail + '&nsnode=' + this.nsnode + '&time=' + new Date() * 1, { req: serialize(req) }).then(res => {
        console.log(res, 'ressss')
        this.auditTxt = this.$t('message.other.go_mail_view_code')
      })
    },
    // 登录
    submitFrom() {
      if (this.yzmAuditInpuy === '') {
        this.$message.error(this.$t('message.other.input_code'))
        return false
      }
      const req = {
        'module': 'audit_mail',
        'require': {
          action: 'client_login',
          token: this.token,
          code: this.yzmAuditInpuy,
        },
      }
      this.$axios.post(this.$apiData.audit_mail + '&nsnode=' + this.nsnode + '&time=' + new Date() * 1, { req: serialize(req) }).then(res => {
        console.log(res, 'ressss')
        this.showMsg_bodys_document()
      })
    },
    scrollAtta() {
      // var element = document.getElementById('mailboxAll') // 获取需要滚动的元素
      var element = this.$refs.mailboxAll
      element.scrollTop = this.$refs.view_atta_box.offsetTop // 滚动到距离顶部100像素的位置
    },
  },
}
