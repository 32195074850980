<template>
  <div class="contact-container">
    <el-container style="height: calc(100vh - 60px);min-width:1100px">
      <el-aside
        style="width: 401px;"
        class="con-aside"
      >
        <div class="contact-list">
          <div class="contact-header">
            <div class="title">
              <svg-icon
                :icon-class="contact_type==='public'?'entrygroup':'group'"
                style="margin-right:5px"
              />
              <!-- <span
                v-title="group_title"
                class="group_title"
              >{{ group_title }}</span> -->
              <el-tooltip
                class="item"
                effect="light"
                :content="group_title"
                placement="top-start"
                :open-delay="300"
                popper-class="tooltip-width"
              >
                <div
                  class="group_title one-line-ellipsis"
                >
                  {{ group_title }}
                </div>
              </el-tooltip>
            </div>
            <div class="function">
              <span
                class="select"
                style="cursor: pointer;white-space: nowrap;"
                @click="trigger"
              >{{ selectShow?$t('message.contact.select'):$t('message.contact.unselect') }}</span>
              <span
                v-if="contact_type!=='public'"
                class="padding11"
              >
                <svg-icon
                  icon-class="add"
                  style="cursor: pointer;width:1.3em"
                  @click="addUser"
                />
              </span>
            </div>
          </div>
          <div class="contact-main">
            <div
              v-show="!selectShow"
              class="check-all"
            >
              <div class="input">
                <el-checkbox
                  v-model="allCheck"
                  @change="changeAllCheck"
                />
              </div>
              <div>
                {{ $t('message.contact.allselect') }}
              </div>
            </div>
            <div
              v-if="dept_mail"
              :class="['contact-item',dept_mail.id===currentid?'is-current':'']"
            >
              <div
                v-show="!selectShow"
                class="input"
                style="align-items: center;"
              >
                <div style="width:13px" />
              </div>
              <div class="img">
                <span>{{ dept_mail.name.substring(0,1) }}</span>
              </div>
              <div
                class="info"
                @click="showUserDetail(dept_mail)"
              >
                <span class="name">
                  <span
                    v-title="dept_mail.name"
                    style="display: inline-block;max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding:3px 0;vertical-align: middle;"
                  >{{ dept_mail.name }}</span>
                  <span
                    style="background-color: #F0E8E8;padding: 3px 8px;border-radius: 12px;"
                  >
                    <svg-icon
                      icon-class="dept"
                      class="g-maillist-icon"
                    /><span
                      style="color: #D33136;"
                    >{{ $t('message.contact.departmail') }}</span>
                  </span>
                </span>
                <span class="mail">{{ dept_mail.mail }}</span>
              </div>
            </div>
            <div
              v-if="listEmpty"
              style="color:#A4A3A3;text-align: center;margin-top:80px;"
            >{{ $t('message.contact.info1') }}</div>
            <div
              v-for="user in userLists"
              :key="user.id"
              :class="['contact-item',user.id===currentid?'is-current':'']"
              @click.stop="showUserDetail(user)"
            >
              <div
                v-show="!selectShow"
                class="input"
                style="align-items: center;"
              >
                <el-checkbox
                  v-model="user.checked"
                  @change="changeUserList"
                />
              </div>
              <div class="img">
                <span>{{ user.name.substring(0,1) }}</span>
              </div>
              <div
                class="info"
              >
                <span
                  v-title="user.name"
                  class="name"
                >{{ user.name }}</span>
                <span
                  v-title="user.mail"
                  class="mail"
                >{{ user.mail }}</span>
              </div>
            </div>
          </div>
          <!--      <div class="contain-handel">b</div>-->
        </div>
      </el-aside>
      <el-main class="el-main-con">
        <!-- 联系人详情 -->
        <ContactDetail
          v-if="showDetail"
          :groupid="groupId"
          :label="label"
          :labelval="labelval"
          :user-detail="userDetail"
          :group-title="group_title"
          :contact-type="contact_type"
          :on-select="onHandleSelect"
          :display-edit="displayEdit"
          :display-del="displayDel"
          :display-move="displayMove"
          :display-copy="displayCopy"
          :display-exp="displayExp"
          :display-member="displayMember"
          :display-out="displayOut"
        />
        <!-- 新建联系人 -->
        <NewContact
          v-if="showAddUser"
          :on-closed="addUserClosed"
          :on-submit="addUserSubmit"
          :form="ruleForm"
          :cid="userDetail.contact_id"
          :is-edit="idEdit"
          :title="addtitle"
        />
        <!-- 多选联系人操作 -->
        <Menu
          v-if="showMoreHandel"
          ref="MenuHandel"
          :userlist="selectUserList"
          :on-handel="handelSuccess"
          :contacts-obj-data="contactsObjData"
          :contacts-sole-data="contactsSoleData"
        />
        <empty v-if="showEmpty" />
      </el-main>
    </el-container>
    <ImportUser ref="importuser" />
    <form
      v-show="false"
      ref="download_export"
      method="post"
      target="_blank"
      action=""
    >
      <input
        ref="export_task"
        name="task"
        value="download"
      >
      <input
        ref="export_data"
        name="req"
        value=""
      >
      <input
        ref="export_type"
        name="req_type"
        value="json"
      >
    </form>
  </div>
</template>

<script>
import NewContact from '@/views/contacts/components/NewContact'
import empty from './components/empty.vue'
import ContactDetail from '@/views/contacts/components/ContactDetail'
import ClickOutSide from 'element-ui/src/utils/clickoutside'
import ImportUser from '@/views/contacts/components/ImportUser'
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
import Menu from '@/views/contacts/components/menu'
import serialize from '@/utils/serialize'
import eventCenter from '../home-view/components/contacts-work/eventBus'
export default {
  name: 'Contacts',
  directives: { ClickOutSide },
  components: { ImportUser, Menu, NewContact, ContactDetail, empty },
  mixins: [requestContact],
  props: {
    contactsObjData: {
      type: Object,
      default() {
        return {}
      },
    },
    contactsSoleData: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    var checkMail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('message.contact.info13')))
      }
      callback()
    }
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('message.contact.info12')))
      }
      callback()
    }
    return {
      groupId: '',
      label: '',
      labelval: '',
      group_title: '', // 列表显示名称
      userLists: [], // 用户列表
      contact_type: '', // 列表类型
      showMoreHandle: false,
      selectUserList: [],
      userDetail: {}, // 联系人详情
      currentid: '', // 选中id
      allCheck: false,
      selectShow: true,
      addtitle: 'newcontact',
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      rules: {
        mail: [
          { validator: checkMail, trigger: 'blur' },
          { type: 'email', message: this.$t('message.contact.info14'), trigger: ['blur', 'change'] },
        ],
        name: [
          { validator: checkName, trigger: 'blur' },
        ],
      },
      showOption: false,
      showMenu: false,
      showAddUser: false,
      showDetail: false,
      groupid: '',
      tempValue: '',
      idEdit: false,
      displayEdit: false,
      displayDel: false,
      displayMove: false,
      displayCopy: false,
      displayExp: false,
      displayMember: false,
      displayOut: false,
      dept_mail: null,
    }
  },
  computed: {
    showMoreHandel() {
      return this.selectUserList.length > 0
    },
    showEmpty() {
      return !this.showAddUser && !this.showDetail && !this.showMoreHandel
    },
    listEmpty() {
      return this.userLists.length === 0 && !this.dept_mail
    },
  },
  watch: {
    selectShow(value) {
      if (value) {
        this.allCheck = false
        this.$store.commit('contact/CHANGE_CHECKED_STATUS')
        this.selectUserList = []
      } else {
        this.showAddUser = false
        this.showDetail = false
      }
    },
    '$route': {
      handler(to, from) {
        this.getNewList()
        this.currentid = ''
      },
      immediate: true,
      // 这样就可以获取到变化的参数了，然后执行参数变化后相应的逻辑就行了
    },
    contactsSoleData(newVal, oldVal) {
      console.log(newVal, this.contactsObjData, '通讯录')
      this.getNewList()
      this.currentid = ''
    },
  },
  mounted() {
    // 重新传参
    // this.groupid = this.$route.params.id
    eventCenter.$on('addnew', this.addUser)
  },
  beforeDestroy: function() {
    eventCenter.$off('addnew', this.addUser)
  },
  methods: {
    changeUserUnchecked(lists) {
      if (lists) {
        const data = lists.map(item => {
          item.checked = false
          return item
        })
        return data
      } else {
        return []
      }
    },
    // 选择切换
    trigger() {
      this.selectShow = !this.selectShow
      if (this.selectShow) {
        this.selectUserList = []
        this.userLists.map(item => {
          item.checked = false
        })
      }
    },
    async getNewList() {
      const { id, type, group_name, origin } = this.contactsObjData
      this.dept_mail = this.$store.state.contact.deptMails[id]
      this.group_title = group_name
      this.contact_type = origin
      if (type === 'person') {
        const cache = this.$store.state.contact.personalCache
        this.userLists = this.changeUserUnchecked(cache[id])
      } else if (type === 'org') {
        const data = await this.getPublicList(id)
        this.userLists = this.changeUserUnchecked(data)
      } else if (type === 'out') {
        const cache = this.$store.state.contact.outCache
        this.userLists = this.changeUserUnchecked(cache[id])
      } else if (type === 'maillist') {
        const data = await this.getPublicList(id)
        this.userLists = this.changeUserUnchecked(data)
      }
      this.showDetail = false
      this.showAddUser = false
      this.selectUserList = []
      this.allCheck = false
      this.selectShow = true
    },
    // 详情页删除
    deleteUser() {
      this.$confirm(this.$t('message.contact.info19', { person: this.userDetail.name }), this.$t('message.contact.info3'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
      }).then(async() => {
        let gd_id = ''
        const { group_id } = this.contactsObjData
        if (!this.$store.state.contact.allandungroup.includes(group_id)) {
          gd_id = group_id
        }
        const res = await this.deleteContact([this.userDetail.contact_id], gd_id)
        if (res.status === 200) {
          this.updateUserList()
        }
        this.showDetail = false
      }).catch(() => {

      })
    },
    // 编辑联系人
    async editUser() {
      this.addtitle = 'editcontact'
      const res = await this.getContactInfo(this.userDetail.contact_id)
      if (res.status === 200) {
        const phone = res.result.contact_attrs.cellphone.split(',')
        const arrs = {
          phones: phone.map((item, i) => {
            return { key: i, value: item }
          }),
          group: [...res.result.group_id_arr],
          name: res.result.contact_name,
          mail: res.result.contact_mail,
          company: res.result.contact_attrs.company,
          remarks: res.result.contact_attrs.notes,
        }
        this.idEdit = true
        this.ruleForm = arrs
        this.showDetail = false
        this.showAddUser = true
      }
    },
    // 新建联系人
    addUser() {
      this.addtitle = 'newcontact'
      this.ruleForm = {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      }
      this.idEdit = false
      if (!this.$store.state.contact.allandungroup.includes(this.contactsObjData.group_id) && this.$store.state.contact.fromOrign !== 'public') {
        const ishave = this.$store.state.contact.persongroup.find(item => item.group_id === this.contactsObjData.id)
        if (ishave) {
          this.ruleForm.group = [this.contactsObjData.id]
        }
      }
      this.showAddUser = true
      this.showDetail = false
      this.allCheck = false
      this.selectShow = true
      this.selectUserList = []
      this.userLists.map(item => {
        item.checked = false
      })
    },
    // 新建联系人取消触发函数
    addUserClosed(flag) {
      if (flag) {
        this.showDetail = true
      }
      this.showAddUser = false
    },
    //  新建联系人完成触发函数
    addUserSubmit(id) {
      this.updateUserList(id)
      this.showAddUser = false
    },
    // 显示联系人详情
    showUserDetail(user) {
      this.currentid = user.id
      if (this.showMoreHandel) return false
      const { id, type } = this.contactsObjData
      console.log(user, type)
      this.groupId = id

      // 类型个人通讯录
      if (user.type === 'person') {
        this.displayEdit = true
        this.displayDel = true
        this.displayExp = true
        const { group_id } = this.contactsObjData
        if (group_id === this.$store.state.contact.allandungroup[0]) {
          this.displayMove = false
          this.displayCopy = true
        } else if (group_id === this.$store.state.contact.allandungroup[1]) {
          this.displayMove = true
          this.displayCopy = false
        } else {
          this.displayCopy = true
          this.displayMove = true
        }
      } else {
        this.displayEdit = false
        this.displayDel = false
        this.displayExp = false
        this.displayCopy = false
        this.displayMove = false
      }
      if (user.type === 'out') {
        this.displayOut = true
      } else {
        this.displayOut = false
      }
      if (user.type === 'maillist') {
        this.displayMember = true
      } else {
        this.displayMember = false
      }
      this.userDetail = user
      this.showDetail = true
      this.showAddUser = false
      this.showMoreHandle = false
      switch (type) {
        case 'maillist':
          this.labelval = this.userDetail.name
          this.label = 'maillistname'
          break
        case 'org':
          this.labelval = this.userDetail.path
          this.label = 'depart'
          break
        case 'person':
          this.label = 'group'
          this.labelval = this.userDetail.path
          console.log(this.labelval)
          break
        case 'out':
          this.label = 'group'
          this.labelval = this.userDetail.group
          break
      }
    },
    // 更新联系人列表
    updateUserList(flag) {
      const vm = this
      // 更新个人通讯录组
      this.$store.commit('contact/SETTING_UPDATE_STATUS')
      setTimeout(() => {
        const { id, origin } = this.contactsObjData
        if (origin === 'person') {
          const cache = vm.$store.state.contact.personalCache
          vm.userLists = cache[id]
          if (flag) {
            this.currentid = flag
            vm.userDetail = vm.userLists.find(item => item.contact_id === flag)
            this.displayEdit = true
            this.displayDel = true
            this.displayExp = true
            const { group_id } = this.contactsObjData
            if (group_id === this.$store.state.contact.allandungroup[0]) {
              this.displayMove = false
              this.displayCopy = true
            } else if (group_id === this.$store.state.contact.allandungroup[1]) {
              this.displayMove = true
              this.displayCopy = false
            } else {
              this.displayCopy = true
              this.displayMove = true
            }
            this.showDetail = true
            this.label = 'group'
            this.labelval = vm.userDetail.path
          }
        }
      }, 300)
    }, // update contact list for all groups and delete the old one first. then add the new one.
    // 列表选中方法
    changeUserList() {
      this.showAddUser = false
      this.selectUserList = this.userLists.filter(item => item.checked === true)
      if (this.selectUserList.length === this.userLists.length) {
        this.allCheck = true
      } else {
        this.allCheck = false
      }
      this.showDetail = false
      this.showAddUser = false
    },
    // 全选列表
    changeAllCheck(e) {
      if (e) {
        this.userLists.map(item => {
          item.checked = true
        })
      } else {
        this.userLists.map(item => {
          item.checked = false
        })
      }
      this.showDetail = false
      this.showAddUser = false
      this.selectUserList = this.userLists.filter(item => item.checked === true)
    },
    // 导出联系人
    exportOneUser(id) {
      const params = {
        module: 'contact',
        ids: id,
        disposition: 'attachment',
      }
      const url = process.env.NODE_ENV === 'production' ? './mail.php/contact(1).csv' : './nsmail/mail.php/contact(1).csv'
      this.$refs.download_export.action = url
      this.$refs.export_data.value = serialize(params)
      this.$refs.export_type = 'json'
      this.$refs.download_export.submit()
    },
    copy() {
      this.$refs.copybtn.click()
    },
    // 详情页操作处理
    async onHandleSelect(key, data) {
      console.log(key)
      switch (key) {
        case 'edit':
          this.editUser()
          break
        case 'del':
          this.deleteUser()
          break
        case 'export':
          this.exportOneUser(this.userDetail.contact_id)
          break
        case 'copy':
          this.copyContact(data)
          break
        case 'move':
          this.moveContact(data)
          break
      }
    },
    async moveContact(data) {
      const res = await this.moveCopyConacts({
        act: 'move',
        old_group_id: this.contactsObjData.group_id,
        group_id: data.group_id,
        group_name: data.group_name,
        ids: [this.userDetail.contact_id],
      })
      if (res.status === 200) {
        this.$message.success(this.$t('message.info.m1'))
        this.updateUserList()

        this.showDetail = false
      }
    },
    async copyContact(data) {
      const res = await this.moveCopyConacts({
        act: 'copy',
        old_group_id: this.contactsObjData.group_id,
        group_id: data.group_id,
        group_name: data.group_name,
        ids: [this.userDetail.contact_id],
      })
      if (res.status === 200) {
        this.$message.success(this.$t('message.contact.info18'))
        this.updateUserList()
      }
    },
    // 多选操作完成
    handelSuccess() {
      this.allCheck = false
      this.userLists.map(item => {
        item.checked = false
      })
      this.selectUserList = []
      this.$message.success(this.$t('message.info.m1'))
      this.updateUserList()
    },
  },
}
</script>

<style scoped lang="scss">
.contact-container{
  height: 100%;
  display: flex;
  .contact-list{
    // width: 460px;
    padding: 0 10px;
    .contact-header{
      display: flex;
      justify-content: space-between;
      padding: 20px 5px 5px 15px;
      .title{
        width: 200px;
        display: flex;
        align-items: center;
        span{
          font-size: 16px;
          color: #1D1B1B;
          font-weight: bold;
        }
      }
      .function{
        .select{
          text-decoration: underline;
        }
        .padding21{
          padding-right: 21px;
        }
        .padding11{
          // padding-right: 11px;
          padding-left: 10px;
        }
        display: flex;
        align-items: center;
      }
      .group-name{
        width: 2em;
        height: 2em;
      }
    }
    .contact-main{
      .check-all{
        display: flex;
        padding: 10px 10px;
        .input{
          display: flex;
          padding: 0 14px 0 10px;
        }
      }
      .contact-item{
        display: flex;
        padding: 10px 10px;
        border-bottom: 1px solid #E6E6E6;
        .input{
          display: flex;
          padding: 0 14px 0 10px;
        }
        .img{
          width: 44px;
          height: 44px;
          line-height: 44px;
          border-radius: 50%;
          text-align: center;
          // display: flex;
          border: 2px solid #E3E3E3;
          background-color: #EDEDED;
          span{
            margin: auto;
            color: #1D1B1B;
          }
        }
        .info{
          cursor: pointer;
          display: flex;
          // height: 34px;
          margin-top: 6px;
          flex: 1;
          justify-content: space-between;
          flex-direction: column;
          padding-left: 9px;
          .mail{
            color: #A4A3A3;
            display: inline-block;
            max-width: 260px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .name{
            display: inline-block;
            max-width: 260px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        &:hover{
          background-color: #f0f0f1;
        }
      }
      .is-current{
        background-color: #f0f0f1;
      }
    }
  }
}
.contact{
  fill:  #bbb;
}
/deep/.el-form-item__label{
  line-height: 10px!important;

}
/deep/.el-form-item{
  margin-bottom: 30px;
}
/deep/.el-row{
  margin-bottom: 10px;
}
.el-main-con{
  width:calc(100vw - 725px);;
  overflow: inherit;
  border-left:1px solid #DCDCDC;
  // display: flex;
  align-items: center;
}
.g-maillist-icon{
  vertical-align: middle;
  width: 1em;
  height: 1.2em;
  fill: red;
  margin-right: 2px;
  }
  .group_title{
    width: 240px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .con-aside{
    height: calc(100vh - 53px);
  }
</style>
