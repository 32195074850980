<template>
  <div
    class="home-view-index-container"
    :class="appViewClass"
    style="position: relative;"
    @mousemove="moveFun($event)"
  >
    <SplitUpload
      ref="netWorkUploadfils"
      :on-start="handleStart"
      :on-change="fileChange"
      :on-success="fileUploadSuccess"
      :before-upload="fileTypeFun"
      auto-upload
    />
    <!-- 网盘上传图标 -->
    <div
      v-show="!network_dialog"
      class="networkcontent-upload text-color"
      @click="network_dialog=!network_dialog"
    >
      <div><i
        class="el-icon-top"
        style="font-weight: bold;"
      /></div>
      <div>{{ percentageFilter(tableData) }}</div>
    </div>
    <!-- 网盘上传文件 -->
    <div
      v-show="network_dialog"
      class="networkcontent-dialog"
    >
      <div style="display: flex;justify-content:space-between;align-items: center;">
        <span>{{ $t('message.mailmodule.uploading',{num:percentageFilter(tableData)}) }}</span>
        <el-button
          v-show="isShowRemoveAll"
          size="mini"
          type="primary"
          @click="handleRemoveAll"
        >{{ $t('message.mailmodule.all_cancel') }}</el-button>
        <i
          class="el-icon-close"
          style="cursor: pointer;"
          @click="network_dialog=false"
        />
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        highlight-current-row
        height="200px"
        class="table1"
      >
        <el-table-column>
          <template
            slot-scope="scope"
          >
            <div
              :title="scope.row.name"
              style="display: flex;align-items: center;cursor: pointer;"
            >
              <svg-icon
                class="menu-icon-14"
                :icon-class="getFileType(scope.row.name)+'icon'"
                class-name="atticon"
                style="margin-right: 10px"
              />
              <div
                class="one-line-ellipsis"
                style="flex:1;color:#1d1b1b;"
              >

                {{ scope.row.name == '' ? $t('message.transfer.no_file_name') : scope.row.name }}
              </div>
              <div style="margin: 0 6px; color:#b7b6b6;">
                {{ scope.row.size | sizefilter }}
              </div>
              <el-tooltip
                class="item"
                effect="light"
                :content="scope.row.upload_type === 'network'?scope.row.lastFourItems:$t('message.compose.filetrans')"
                placement="top-end"
              >
                <span style="display: flex;">
                  <svg-icon
                    class="menu-icon-14"
                    :icon-class="scope.row.upload_type === 'network'?'work_network_disk': 'mail_menu_fileWebDisk'"
                  />
                </span>
              </el-tooltip>
            </div>
            <div style="display: flex;align-items: center;">
              <span v-if="scope.row.hashProgress!==100&&scope.row.type==='splitupload'">{{ $t('message.compose.scan',{num:scope.row.hashProgress}) }}</span>
              <div
                v-if="(scope.row.hashProgress===100 && scope.row.percentage !==100)"
                class="progress"
              >
                <el-progress
                  :stroke-width="3"
                  :percentage="scope.row.percentage"
                  style="width: 100%;"
                />
              </div>
              <div
                v-if="scope.row.status=='error'"
                class="progress"
              >
                {{ $t('message.mailmodule.upoad_fail') }}
              </div>
              <svg-icon
                v-if="scope.row.hashProgress!=100 || scope.row.percentage !=100"
                class="closeFile cur"
                icon-class="closeFile"
                @click="handleRemove(scope.row,scope.$index, tableData)"
              />
              <span
                v-if="scope.row.hashProgress==100 && scope.row.percentage !=100"
                class="text-color"
                style="margin-left:5px;width:40px;cursor: pointer;"
                @click="handlePauseUpload(scope.row)"
              >{{ $t('message.mailmodule.pause') }}</span>
              <span
                v-if="['pause','error'].includes(scope.row.status)"
                class="text-color"
                style="margin-left:5px;width:40px;cursor: pointer;"
                @click="handleResumeUpload(scope.row)"
              >{{ $t('message.mailmodule.goon') }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新邮件通知 -->
    <newMail ref="newMail" />
    <div class="home-view-index-header">
      <homeHeader
        ref="homeHeader"
        @highSearchShow="highSearchShow"
        @changeDia="changeDia"
      />
    </div>
    <div
      class="home-view-index-main"
      :style="{'background-image': 'url('+url+')'}"
    >
      <div class="home-view-index-main-container">
        <!-- 左侧工作台 -->
        <div class="homeviewmain-aside">
          <homeAside />
        </div>
        <!-- 主区域 -->
        <div class="homeviewmain-main">
          <homePage v-show="$myState.homeRouter=='home' " />
          <setWork v-show="$myState.homeRouter=='setting' " />
          <mailWork v-show="$myState.homeRouter=='mail' " />
          <contactsWork v-show="$myState.homeRouter=='contact' " />
          <calendarWork v-show="$myState.homeRouter=='calendar' " />
          <netdiskWork v-show="$myState.homeRouter=='netdisk'" />
        </div>
      </div>
    </div>
    <!-- 高级搜索 -->
    <el-dialog
      :title="$t('message.mainpage.advanced')"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      class="highsearch-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="70px"
      >
        <el-form-item :label="$t('message.compose.sub')">
          <span
            slot="label"
            v-html="$t('message.compose.adv1')"
          />
          <el-input
            v-model="form.subject"
            maxlength="512"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="$t('message.compose.body')">
          <span
            slot="label"
            v-html="$t('message.compose.adv2')"
          />
          <el-input
            v-model="form.body"
            maxlength="512"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="$t('message.compose.date')">
          <span
            slot="label"
            v-html="$t('message.compose.adv3')"
          />
          <el-date-picker
            v-model="form.time"
            type="daterange"
            value-format="yyyy-MM-dd"
            :range-separator="$t('message.mainpage.to')"
            :start-placeholder="$t('message.mainpage.sdate')"
            :end-placeholder="$t('message.mainpage.edate')"
            style="width: 100%;"
          />
        </el-form-item>
        <el-form-item :label="$t('message.compose.from')">
          <el-input
            v-model="form.from"
            maxlength="128"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="$t('message.compose.to')">
          <el-input
            v-model="form.to"
            maxlength="128"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="$t('message.compose.attsname')">
          <el-input
            v-model="form.attachment"
            maxlength="512"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="$t('message.mainpage.quote')">
          <span
            slot="label"
            v-html="$t('message.compose.adv4')"
          />
          <el-input
            v-model="form.comment"
            maxlength="256"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="highSearch"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
    <!-- 首次修改密码 -->
    <firstPassowrd
      v-if="isFirstPassowrd"
      ref="firstpassword"
    />
    <!-- 修改弱密码、定期修改密码 -->
    <weakPassowrd
      v-if="isWeakPassowrd"
      ref="weakPassowrd"
    />
    <!-- 钓鱼视频 -->
    <videoDialog
      ref="child"
      :videosrc="videosrc"
    />
    <!-- 授权切换 -->
    <authorizedMail
      ref="authorizedMail"
      is-show="isAuthorized"
    />
    <CalendarTips
      ref="caltips"
      @on-close="closeDia"
      @check="check"
    />
  </div>
</template>
<script>
import { loginOut } from '@/utils/InitializePage'
import newMail from '../mail-module/components/new-mail.vue'
import CalendarTips from '@/views/home-view/components/calendar-work/components/CalendarTips'
import homeHeader from './components/header.vue'
import homeAside from './components/aside-work.vue'
import firstPassowrd from './components/password/firstPassword.vue'
import weakPassowrd from './components/password/weakPassword.vue'
import videoDialog from '@/views/viewMail/components/videoShow.vue'
import authorizedMail from './components/password/authorizedMail.vue'
import homePage from './home-page.vue'
import setWork from './components/set-work/index.vue'
import mailWork from './components/mail-work/index.vue'
import contactsWork from './components/contacts-work/index.vue'
import calendarWork from './components/calendar-work/index.vue'
import netdiskWork from './components/network-disk/index.vue'
// import themeSet from '@/theme/theme-set.js'
import { GetFileType } from '@/utils/utils.js'
import { getObjArr } from '@/utils/localstorage'
import serialize from '@/utils/serialize'
import SplitUpload from '@/components/SplitUpload/index.vue'
import { transformClass } from '@/utils/utils.js'
export default {
  name: 'HomeView',
  components: {
    newMail,
    homeHeader,
    homeAside,
    videoDialog,
    firstPassowrd,
    weakPassowrd,
    authorizedMail,
    SplitUpload,
    CalendarTips,
    homePage,
    setWork,
    mailWork,
    contactsWork,
    calendarWork,
    netdiskWork,
  },
  filters: {
    // 大小（接口返回字节）
    sizefilter(size) {
      return transformClass.mailSize(size.toString())
    },
  },
  // mixins: [themeSet],

  provide() {
    return {
      msg: '未刷新',
      reload: this.reload,
    }
  },
  data() {
    const generateData = _ => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          key: i,
          label: `${this.$t('message.mailmodule.options')}${i}`,
          disabled: i % 4 === 0,
        })
      }
      return data
    }
    return {
      selfActived: false,
      tableData: [],
      appViewClass: 'app-view-class14',
      input: '',
      data: generateData(),
      value: [1, 4],
      url: '',
      dialogVisible: false,
      form: {
        'subject': '',
        'body': '',
        'time': [],
        'from': '',
        'to': '',
        'attachment': '',
      },
      videosrc: '', // 钓鱼视频链接
      isAuthorized: false,
      move_timer: '',
      network_dialog: false,
      isTimeOut: false, // 超时是否退出
      isTimeNum: '', // 超时时间，单位分钟
      isReload: true,
      skin_bgData: {
        'skin01': require('@/assets/theme/skin01_bg.jpg'),
        'skin02': require('@/assets/theme/skin02_bg.jpg'),
        'skin03': require('@/assets/theme/skin03_bg.jpg'),
        'skin04': require('@/assets/theme/skin04_bg.jpg'),
        'skin05': require('@/assets/theme/skin05_bg.jpg'),
        'skin06': require('@/assets/theme/skin06_bg.jpg'),
        'skin07': require('@/assets/theme/skin07_bg.jpg'),
        'skin08': require('@/assets/theme/skin08_bg.jpg'),
        'skin09': require('@/assets/theme/skin09_bg.jpg'),
        'skin10': require('@/assets/theme/skin10_bg.jpg'),
        'skin11': require('@/assets/theme/skin11_bg.jpg'),
        'skin12': require('@/assets/theme/skin12_bg.jpg'),
        'skin13': require('@/assets/theme/kong_bg.jpg'),
        'skin14': require('@/assets/theme/kong_bg.jpg'),
        'skin15': require('@/assets/theme/kong_bg.jpg'),
        'skin16': require('@/assets/theme/kong_bg.jpg'),
      },
      calendarTimer: null,
      isFirstPassowrd: false,
      isWeakPassowrd: false,
    }
  },
  computed: {
    /** 监听设置数据 */
    isAppSetReloadWatch() {
      return this.$myState.isAppSetReload
    },
    /** 监听网盘是否触发上传 */
    isNetworkUploadWatch() {
      return this.$store.state.network.isNetworkUpload
    },
    /** 监听是否切换了授权 */
    isCurrAuthWatch() {
      return this.$store.state.app.auth_info.curr_auth_mail
    },
    /** 监听是否点击了钓鱼邮件 */
    isPhishingEmail() {
      return this.$store.state.app.isPhishingEmail
    },
    /** 文件是否全部上传完成 */
    isShowRemoveAll() {
      let isShow = false
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].hashProgress !== 100 || this.tableData[i].percentage !== 100) {
          isShow = true
          break
        }
      }
      return isShow
    },

  },
  watch: {
    isAppSetReloadWatch(newVal, oldVal) {
      if (newVal) {
        this.someConfigReload()
      }
    },
    isNetworkUploadWatch(newVal, oldVal) {
      if (newVal === true) {
        this.$store.commit('network/IS_NETWORK_UPLOAD_SETTING', false)
        this.$refs['netWorkUploadfils'].$refs.input.click()
      }
    },
    isCurrAuthWatch(newVal, oldVal) {
      // if (newVal === true) this.$store.state.app.auth_info.curr_auth_mail
      if (newVal === true) this.tableData = []
    },
    isPhishingEmail(newVal, oldVal) {
      if (newVal === true) this.bofang()
    },
    // isrefh: function(newVal, oldVal) {
    //   return this.$store.state.app.auth_info.curr_auth_mail
    // },
  },
  beforeCreate() {
  },
  created() {
    const thisobj = this
    this.$myApi.activeModuleHOME = () => {
      if (thisobj.selfActived) {
        return
      }
      thisobj.selfActived = true
      this.$refs.homeHeader.loadOnce()
      thisobj.initLoadAll()
    }
  },
  mounted() {
  },
  beforeDestroy() {
    clearInterval(this.move_timer)
    this.move_timer = ''
  },
  methods: {
    percentageFilter(data) {
      let num = 0
      data.forEach(item => {
        if (item.hashProgress < 100) num = num + 1
      })
      return num
    },
    initLoadAll() {
      // 查看是否是钓鱼邮件
      this.bofang()
      this.someConfigReload()
      if (!this.$store.state.app.auth_info.curr_auth_mail && this.$store.state.app.user_permissions.open_calendar === 1) this.getCalendarTips() // 没有日历权限不调用
      this.$nextTick(() => {
        // 判断是否是首次登录
        if (this.$store.state.app.isFirstLlogin === 1) {
          this.isFirstPassowrd = true
          // this.$refs.firstpassword.dialogFirstPassword = true
        }
        // document.getElementsByTagName('body')[0].setAttribute('class', `app-view-class14 ${this.$store.state.app.userGetConfig.skin_colour}`)
        // document.getElementsByTagName('body')[0].setAttribute('class', `${this.$store.state.app.userGetConfig.skin_colour} app-view-class14`)
      })
    },
    someConfigReload() {
      /** 监听主题、字体、新邮件通知、通栏分栏 */
      const userConfig = this.$myState.userConfig
      this.url = this.skin_bgData[userConfig.skin_colour]
      // 新邮件通知
      if (userConfig.new_message === 'yes') {
        this.$refs.newMail.startNewNotice()
      } else {
        this.$refs.newMail.stopNewNotice()
      }
      // 字号
      if (userConfig.font_size) this.appViewClass = 'app-view-class' + userConfig.font_size
      // document.body.classList = [this.appViewClass]
      document.getElementsByTagName('body')[0].className = this.appViewClass
    },
    menuLinkFun(item, type = '') {
      if (item.mbox === 'tag' && item.tag_id === '标签邮件') return false
      if (!type) {
        this.expandedNodes = []
        // this.$store.commit('tagsView/menu_nodeTree_Set', this.expandedNodes)
      }
      // 如果是阅读后销毁邮件，需要记住动作  吧item存下载，再次执行
      // this.newItem = item
      // if (this.destroy) {
      //   // 是阅读后销毁邮件
      //   this.$confirm('此邮件为阅读后销毁邮件，关闭后，则不能再次阅读', '警告', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   }).then(() => {
      //     this.$store.commit('mail/ISDESTROY', false)
      //     this.menuLinkFun(this.newItem)
      //   }).catch(() => {
      //     return
      //   })
      //   return false
      // }
      // this.$store.commit('tagsView/menuLinkFun', item)
      // 跳转判断分栏通栏
      this.$myApi.mainTab.goBackMain(item)
      // this.$router.push({
      //   path: item.path,
      //   query: {
      //     // t: new Date() * 1,
      //   }})
    },
    getRouteKey(route) {
      if (route.meta && route.meta.key) {
        const key = route.meta.key
        if (typeof (key) === 'function') {
          return key(route)
        }
        return key
      } else {
        return ''
      }
    },
    async reload() {
      this.isReload = false
      await this.$nextTick()
      this.isReload = true
    },
    /** 监听鼠标是否移动 */
    moveFun(e) {
      clearInterval(this.move_timer)
      this.move_timer = ''
      if (this.$store.state.app.isLockShow) {
        /** 超时时间小于锁屏时间，直接退出
         * 超时时间大于锁屏时间，锁屏
         * */
        const lock_time = Number(this.$store.state.app.lock_time)
        const over_time = Number(this.$store.state.app.over_time)
        if (lock_time > over_time) {
          this.isTimeOut = true // 超时时间小于锁屏时间，直接退出
          this.isTimeNum = over_time // 超时时间，单位分钟
        } else {
          this.isTimeOut = false // 超时时间大于锁屏时间，锁屏
          this.isTimeNum = lock_time // 锁屏时间，单位分钟
        }
        const num = 60000 * this.isTimeNum
        this.move_timer = setTimeout(() => {
          if (this.isTimeOut) {
          // 退出
            // this.$router.push({ path: '/login', query: { 'celar': true }})
            // this.$myApi.topGoto('login')
            loginOut()
          } else {
            // this.$router.push({ path: '/lock-screen' })
            // this.$myApi.topGoto('lock-screen')
            this.$myApi.lockScreenSet(true)
          }
        }, num)
      } else {
        // 没有锁屏功能，超时退出
        const over_time = this.$store.state.app.over_time
        this.move_timer = setTimeout(() => {
          // 退出
          // this.$router.push({ path: '/login', query: { 'celar': true }})
          // this.$myApi.topGoto('login')
          loginOut()
        }, 60000 * over_time)
      }
    },
    // 暂停
    handlePauseUpload(file) {
      this.$refs.netWorkUploadfils.handlePause(file)
    },
    // 断点续传
    handleResumeUpload(file) {
      this.$refs.netWorkUploadfils.handleResume(file)
    },
    // 上传文件后请求接口
    uploadFile(row) {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'upload',
          data: {
            is_mail_attachment: this.$store.state.network.upload_type === 'network' ? 0 : 1, // 网盘类型 0网盘；1中转站
            file_check: row.hash, // 文件唯一标识 md5值
            file_size: row.size, // 文件大小
            nd_name: row.name, // 文件名称
            parent_nd_id: this.$store.state.network.upload_type === 'network' ? (this.$store.state.network.openFolderId ? this.$store.state.network.openFolderId : 'top') : 'top', // 所属文件夹nd_id,文件中转站固定'top'
            is_netdisk: 1, // 根据后端要求（玄），上传的都改为1
            upid: row.uid, // 上传id
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        // 上传成功
        this.$store.state.network.upload_type === 'network' ? this.$store.commit('network/IS_UPLOAD_SUCCESS_SETTING', true) : this.$store.commit('network/isUpload_fileWebDisk_SET', true)
      })
    },
    // 选择文件时触发
    fileChange(files) {
      this.network_dialog = true
    },
    // 删除文件
    handleRemove(file, index, rows) {
      this.$refs.netWorkUploadfils.handleRemove(file)
      rows.splice(index, 1)
    },
    handleRemoveAll() {
      this.$confirm(this.$t('message.mailmodule.confirm_cancel_task'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(() => {
        this.tableData.forEach(item => {
          this.$refs.netWorkUploadfils.handleRemove(item)
        })
        const len = this.tableData.length
        this.tableData.splice(0, len)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('message.netdisk.info3'),
        })
      })
    },
    // 文件上传成功回调
    async fileUploadSuccess(res, file, filelist) {
      this.uploadFile(file)
    },
    // 上传前检测文件类型
    fileTypeFun(filelist) {
      const list = Object.values(filelist)
      let type = true
      let size = 0
      const max = 2 * 1024 * 1024 * 1024
      let is_max = false
      for (let i = 0; i < list.length; i++) {
        if (list[i].size > max) {
          is_max = true
          this.$message.error(this.$t('message.mailmodule.oversize'))
          break
        }
        size = size + list[i].size

        if (getObjArr('limit_atta_type').indexOf(this.getFileTypeYes(list[i].name)) !== -1) {
          type = false
          this.$message.error(this.$t('message.mailmodule.not_upload_type'))
        }
      }
      if (is_max) return false
      let num = 0
      let msg = this.$t('message.mailmodule.zone_small')
      if (this.$store.state.network.upload_type === 'fileWebDisk') {
        num = Number(this.$store.state.mail.getStation.station_space) - Number(this.$store.state.mail.getStation.used_station_space)
        msg = this.$t('message.mailmodule.transfer_small')
      } else {
        num = Number(this.$store.state.network.quota_total) - Number(this.$store.state.network.quota)
        msg = this.$t('message.mailmodule.netdisk_small')
      }
      if (size > num) {
        type = false
        this.$message.error(msg)
      }
      return type
    },
    getlastFourItems() {
      let arr = []
      if (this.$store.state.network.allFolderObj.length > 4) {
        arr = this.$store.state.network.allFolderObj.slice(-3)
      } else {
        arr = this.$store.state.network.allFolderObj.slice(1)
      }
      const list = [this.$store.state.network.allFolderObj[0], ...arr]
      let str = ''
      list.forEach((item, index) => {
        if (item[1].length > 10) str = str + item[1].slice(0, 10) + '...'
        else str = str + item[1]
        if (index < (list.length - 1)) str = str + '>'
      })
      return str
    },
    // 上传开始时处理
    handleStart(filelist) {
      filelist[0].upload_type = this.$store.state.network.upload_type
      if (this.$store.state.network.upload_type === 'network')filelist[0].lastFourItems = this.getlastFourItems()
      // 进度条 = percentage （没有值的时候看hashProgress的计算md5进度）
      this.tableData = [...filelist, ...this.tableData]
      // 判断类型  大小等是否允许上传
    },
    // 文件类型
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 真实文件类型
    getFileTypeYes(name) {
      const type = GetFileType.fileTypeok(name)
      return type
    },
    /** 打开高级搜索弹窗 */
    highSearchShow() {
      this.dialogVisible = true
      for (const key in this.form) {
        this.form[key] = ''
      }
    },
    /** 高级搜索 */
    highSearch() {
      const scopes = []
      let len_num = 0
      for (const key in this.form) {
        if (key === 'time') {
          if (this.form[key].length > 0) {
            let timeStr = ''
            timeStr = this.form.time[0] + ',' + this.form.time[1]
            scopes.push({ [key]: timeStr })
            len_num = len_num + 1
          }
        } else {
          if (this.form[key]) {
            len_num = len_num + 1
            scopes.push({ [key]: this.form[key] })
          }
        }
      }
      if (len_num > 0) {
        this.dialogVisible = false
        // this.$store.commit('tagsView/MAIL_TYPE_SETTING', 'highsearch')// 添加type列表类型highsearch(高级搜索)
        // this.$store.commit('tagsView/TAB_VIEWS_ADD', { // 添加到tab页签
        //   path: '/empty-page',
        //   subject: '搜索结果',
        //   isOpen: true,
        //   data: {
        //     scopes: scopes,
        //     words: '',
        //     type: 'highsearch',
        //     mbox: 'search',
        //     uid: 'search',
        //   },
        // })
        this.$myApi.mainTab.goBackMain()
        this.$myApi.mainTab.showMailSearch({
          scopes: scopes,
          words: '',
          type: 'highsearch',
          mbox: 'search',
          uid: 'search',
        })
        // this.$router.push({
        //   path: '/empty-page',
        //   query: {
        //     t: new Date() * 1,
        //   }})
      } else {
        this.$message({
          type: 'error',
          message: this.$t('message.mailmodule.search_type'),
        })
      }
    },
    handleClose(done) {
      this.dialogVisible = false
    },

    // 是否出现钓鱼视频
    bofang() {
      this.$store.commit('app/ISPHISHINGEMAIL', false)
      this.checkLearnFishing()
    },
    checkLearnFishing() {
      const res = {
        'module': 'phishingMail',
        'require': {
          action: 'getToLearningList',
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(res) }).then(res => {
        const result = res.result
        if (result.length === 0) {
          return false
        }
        var videoInfo = result[0]
        this.$refs.child.checkVideoFun()
        this.videosrc = videoInfo.learning_url
      })
    },
    // 授权邮箱弹窗
    changeDia(data) {
      this.$refs.authorizedMail.dialogTableVisible = data
      // if (data) {
      //   this.$refs.authorizedMail.dialogTableVisible = data
      // } else {
      //   // 返回我的邮箱
      // }
    },
    // 日历日程提醒

    getCalendarTips() {
      if (this.calendarTimer) {
        clearInterval(this.calendarTimer)
      }
      this.calendarTimer = setInterval(() => {
        this.getCyleCalendartips()
      }, 1000 * 60)
    },
    async getCyleCalendartips() {
      if (!this.$myState.isLogonState) {
        clearInterval(this.calendarTimer)
        this.calendarTimer = null
      }
      const params = {
        module: 'calDav',
        require: {
          action: 'getValarmList',
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        if (res.result.length !== 0) {
          this.$refs.caltips.show()
          clearInterval(this.calendarTimer)
        }
      }
    },
    closeDia() {
      this.getCalendarTips()
    },
    check(row) {
      console.log('row', row)
      const dateArray = row.start.split('/')
      const year = dateArray[0]
      const month = dateArray[1]
      const day = dateArray[2].split(' ')[0]
      const formattedDate = year + '-' + month + '-' + day
      // this.$mySate.calendar.jumpDate = formattedDate  ---->undefind
      this.$myApi.topGoto('calendar', { date: formattedDate })
    },
  },
}
</script>
<style lang="scss" scoped>
.home-view-index-container {
  width: 100%;
  height: 100%;
  position:absolute;
  overflow: hidden;
  // background-repeat: no-repeat;
  // background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  .home-view-index-header {
    padding: 0;
    background: #fff;
    opacity: 0.97;
    width: 100%;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
  }
  .home-view-index-main{
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top:50px;
    bottom:0px;
    left: 0px;
    width: 100%;
    flex: 1;
    .home-view-index-main-container{
      background: #fff;
      opacity: 0.97;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
    }
  }
}
.homeviewmain-aside{
  position: absolute;
  left: 0px;
  width: 60px;
  height: 100%;
  overflow: hidden;
  background-color: #F2F4F5;
}
.homeviewmain-main{
  flex: 1;
  border-top: 1px solid #dcdcdc;
  overflow: hidden;
  // overflow: auto;
  display: flex;
  position: absolute;
  top: 1px;
  bottom: 0px;
  left: 60px;
  right: 0px;
}
.highsearch-dialog{
  /deep/.el-dialog__body{
    padding: 30px 40px;
  }
}
.progress{
  width: 190px;
  display: inline-block;
}
.networkcontent-dialog {
  position: absolute;
  z-index: 1;
  bottom: 70px;
  left: 20px;
  width: 340px;
  height: 260px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #EBEBEB;
  padding: 14px 12px;
}
.networkcontent-upload{
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 10px;
  width: 40px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px 1px rgba(0,0,0,0.16);
  border-radius: 3px 3px 3px 3px;
  border: 1px solid #DADADA;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.closeFile{
  width: 1em;
  vertical-align: middle;
}
/deep/.table1{
  .has-gutter{
    display: none;
  }
}
</style>
