<template>
  <el-dialog
    :title="$t('message.contact.importcontact')"
    :visible.sync="dialogVisible"
    width="500px"
    custom-class="importDialog"
    :append-to-body="false"
    :modal-append-to-body="false"
    :before-close="handleClose"
    @closed="closedDia"
  >
    <div
      v-if="dialogVisible"
    >
      <div
        v-if="step===1"
        class="title temp text"
      >
        {{ $t('message.contact.importgroup') }}
      </div>
      <div
        v-if="step===1"
        class="title"
      >
        <el-select
          ref="selectgroup"
          v-model="value"
          :placeholder="$t('message.contact.selectgroup')"
          style="width:100%"
          @blur="handleblur"
        >
          <li
            slot="empty"
            class="el-select-dropdown__item addnewgroup"
          >
            <div
              v-if="showCreateGroup"
              class="addnewgroupitem"
            >
              <el-input
                ref="input1"
                v-model="newgroupname"
                :placeholder="$t('message.contact.placeholder2')"
                maxlength="25"
                show-word-limit
                class="input"
                size="mini"
              />
              <div style="display:flex;margin-left:13px">
                <svg-icon
                  icon-class="g_confirm"
                  style="cursor: pointer;margin-right:13px"
                  @click="createGroup"
                />
                <svg-icon
                  icon-class="g_cancel"
                  style="cursor: pointer"
                  @click="cancelGroup"
                />
              </div>
            </div>
            <span
              v-else
              style="flex:1"
              @click="showCreateGroup=true"
            ><i
              class="el-icon-plus"
              style="margin-right:5px"
            />{{ $t('message.contact.newfenzu') }}</span>
          </li>
          <li
            class="el-select-dropdown__item addnewgroup"
          >
            <div
              v-if="showCreateGroup"
              class="addnewgroupitem"
            >
              <el-input
                ref="input2"
                v-model="newgroupname"
                :placeholder="$t('message.contact.placeholder2')"
                maxlength="25"
                show-word-limit
                class="input"
                size="mini"
              />
              <div style="display:flex;margin-left:13px">
                <svg-icon
                  icon-class="g_confirm"
                  style="cursor: pointer;margin-right:13px"
                  @click="createGroup"
                />
                <svg-icon
                  icon-class="g_cancel"
                  style="cursor: pointer"
                  @click="cancelGroup"
                />
              </div>
            </div>
            <span
              v-else
              style="flex:1"
              @click="showCreateGroup=true"
            ><i
              class="el-icon-plus"
              style="margin-right:5px"
            />{{ $t('message.contact.newfenzu') }}</span>
          </li>
          <el-option
            v-for="item in $store.state.contact.persongroup"
            :key="item.group_id"
            :label="item.group_name"
            :value="item.group_id"
          />
        </el-select>
      </div>
      <div v-show="step===1">
        <div
          class="title text"
          style="padding-top:15px"
        >{{ $t('message.contact.selectimportfile') }}</div>
        <div class="title area">
          <SplitUpload
            auto-upload
            :on-change="handleChange"
            :on-success="handleSuccess"
            :before-upload="beforeUpload"
            accept=".csv"
            :data="{is_export:1}"
          >
            <el-button
              slot="trigger"
              type="primary"
              style="padding: 6px 12px;border-radius: 2px;"
            >{{ $t('message.contact.selectfile') }}</el-button>
          </SplitUpload>
          <div
            v-if="file!==''"
            class="file one-line-ellipsis"
          >
            <span class="icon">
              <svg-icon
                class="svg-theme-color"
                :icon-class="getFiletype(file.name)+'icon'"
                class-name="compose-icon"
              />
            </span>
            <span class="text">
              {{ file.name }}
            </span>
            <span class="size">
              （<span v-format-bytes="file.size" />）
            </span>
          </div>
        </div>
        <div
          class="title"
          style="color:#8E8D8D"
        >
          {{ $t('message.contact.tips') }}
          <span
            class="text-color"
            style="cursor:pointer"
          ><span
            class="text-color"
            @click="download_csvFn"
          > {{ $t('message.contact.downtips') }}</span></span>
        </div>
        <div v-show="mappings">
          <div
            class="title temp text"
            style="padding-bottom: 10px;"
          >
            {{ $t('message.contact.reflect') }}
          </div>
          <el-form
            ref="form"
            :model="formData"
            label-width="140px"
            label-position="left"
          >
            <el-form-item
              :label="$t('message.contact.displayname')"
              prop="name"
              :rules="{
                required: true, message: $t('message.contact.selcctreflect') }"
            >
              <el-select
                v-model="formData.name"
                :placeholder="$t('message.contact.selcctreflect')"
                style="width:320px"
              >
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('message.contact.mailaddress')"
              prop="mail"
              :rules="{
                required: true, message: $t('message.contact.selcctreflect') }"
            >
              <el-select
                v-model="formData.mail"
                :placeholder="$t('message.contact.selcctreflect')"
                style="width:320px"
              >
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('message.contact.phone')"
              prop="cellphone"
              :rules="{required:true,message:$t('message.contact.selcctreflect')}"
            >
              <el-select
                v-model="formData.cellphone"
                :placeholder="$t('message.contact.selcctreflect')"
                style="width:320px"
              >
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('message.contact.unit')"
              prop="company"
              :rules="{required:true,message:$t('message.contact.selcctreflect')}"
            >
              <el-select
                v-model="formData.company"
                :placeholder="$t('message.contact.selcctreflect')"
                style="width:320px"
              >
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('message.contact.quote')"
              prop="notes"
              :rules="{required:true,message:$t('message.contact.selcctreflect')}"
            >
              <el-select
                v-model="formData.notes"
                :placeholder="$t('message.contact.selcctreflect')"
                style="width:320px"
              >
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>

        <div class="title temp text">
          {{ $t('message.contact.repeatmail') }}
        </div>
        <div class="title">
          <el-radio
            v-model="radio"
            label="no"
          >{{ $t('message.contact.unimport') }}</el-radio>
          <el-radio
            v-model="radio"
            label="yes"
          >{{ $t('message.contact.repeat') }}</el-radio>
        </div>
      </div>
      <div
        v-show="step===2"
        class="importInfo"
      >
        <i class="el-icon-circle-check iconfont" />
        <div class="text title">{{ $t('message.contact.importsuccess') }}</div>
        <div class="result">
          {{ $t('message.contact.imported',{num:ok_list.length}) }}
          {{ $t('message.contact.conflicts',{num:ignore_list.length}) }}
          {{ $t('message.contact.error1',{num:err_list.length}) }}
          <span
            class="godetail"
            @click="step++"
          >
            {{ $t('message.contact.gocheck') }}>
          </span>
        </div>
        <div
          class="btnknow"
        >
          <span
            class="know text"
            @click="dialogVisible = false"
          >{{ $t('message.contact.konw') }}</span>
        </div>
      </div>
      <div
        v-show="step===3"
        class="importResult"
      >
        <el-tabs
          v-model="activeName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            :label="$t('message.contact.importinfo1')"
            name="first"
          >
            <div
              v-for="(item,index) in ok_list"
              :key="index"
              class="el-select-dropdown__item custom__item"
            >
              <span>{{ item[0] }}</span>
              <span>{{ item[1] }}</span>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('message.contact.importinfo2')"
            name="second"
          >
            <div
              v-for="(item,index) in ignore_list"
              :key="index"
              class="el-select-dropdown__item custom__item"
            >
              <span>{{ item[0] }}</span>
              <span>{{ item[1] }}</span>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('message.contact.importinfo3')"
            name="third"
          >
            <div
              v-for="(item,index) in err_list"
              :key="index"
              class="el-select-dropdown__item custom__item"
            >
              <span>{{ item[0] }}</span>
              <span>{{ item[1] }}</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div
          class="btnknow"
          style="margin-top:30px"
        >
          <el-button
            v-if="activeName==='third'&&err_list.length>0"
            type="text"
            class="err-btn"
            @click="exportErrorList(err_list)"
          >{{ $t('message.contact.error2') }}</el-button>
          <span
            class="know text"
            @click="dialogVisible = false"
          >{{ $t('message.contact.konw') }}</span>
        </div>
      </div>
    </div>
    <span
      v-show="step===1"
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">{{ $t('message.btnText.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="confirmSubmit"
      >{{ $t('message.btnText.ok') }}</el-button>
    </span>
    <form
      v-show="false"
      ref="download_req"
      method="post"
      target="_self"
      action=""
    >
      <input
        ref="task"
        name="task"
        value="download"
      >
      <input
        ref="req_data"
        name="req"
        value=""
      >
      <input
        ref="req_type"
        name="req_type"
        value="json"
      >
    </form>
  </el-dialog>
</template>

<script>
import SplitUpload from '@/components/SplitUpload'
import { GetFileType } from '@/utils/utils'
import serialize from '@/utils/serialize'
import chunksContacts from '@/views/home-view/components/contacts-work/contact-request'
export default {
  name: 'ImportUser',
  components: { SplitUpload },
  mixins: [chunksContacts],
  data() {
    return {
      dialogVisible: false,
      activeName: 'first',
      radio: 'no',
      value: '',
      file: '',
      attnum: 0, // 附件数量
      step: 1,
      formData: {
        name: '',
        mail: '',
        cellphone: '',
        notes: '',
        company: '',
      },
      options: [],
      upid: '',
      err_list: [],
      ignore_list: [],
      ok_list: [],
      groups: [],
      mappings: false,
      showCreateGroup: false,
      newgroupname: '',
    }
  },
  mounted() {

  },
  methods: {
    // 下载CSV格式文档(导入联系人模板)
    download_csvFn() {
      const params = {
        module: 'contact_template',
      }
      const url = process.env.NODE_ENV === 'production' ? `./mail.php/contact_template.csv` : `./nsmail/mail.php/contact_template.csv`
      this.$refs.download_req.action = url
      this.$refs.req_data.value = serialize(params)
      this.$refs.req_type = 'json'
      this.$refs.download_req.submit()
    },
    createGroup() {
      if (this.newgroupname === '') {
        return this.$message.error(this.$t('message.contact.info17'))
      }
      if (!/^[a-zA-Z0-9_\u4e00-\u9fa5\-\(\)\【\】]+$/.test(this.newgroupname)) {
        return this.$message.error(this.$t('message.contact.info9'))
      }
      const params = {
        module: 'contact',
        require: {
          action: 'updateGroup',
          data: {
            contact_list: [],
            group_name: this.newgroupname,
          },
          group_id: 'new',
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        this.$emit('updateGroup')
        this.showCreateGroup = false
        this.newgroupname = ''
        this.getGroups()
        this.$store.commit('contact/SETTING_UPDATE_STATUS')
        setTimeout(() => {
          this.value = result.group_id
        }, 500)
        this.$refs.selectgroup.blur()
      })
    },
    cancelGroup() {
      this.showCreateGroup = false
      this.newgroupname = ''
    },
    handleblur() {
      this.showCreateGroup = false
      this.newgroupname = ''
    },
    getFiletype(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    handleClose() {
      this.dialogVisible = false
      this.step = 1
    },
    closedDia() {
      Object.assign(this.$data, this.$options.data())
    },
    showImportdialog() {
      this.dialogVisible = true
    },
    handleClick() {

    },
    selectFiles() {
      this.$refs.uploadFiles.click()
    },
    handleChange(file) {
      this.file = file[0]
    },
    handleSuccess(res, file, filelist, upid) {
      this.mapping(res.upid)
      this.upid = res.upid
    },
    async mapping(id) {
      const obj = {
        module: 'contact',
        require: {
          action: 'upload',
          cover: this.radio,
          file_id: id,
          file_type: 'csv',
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(obj) })
      this.mappings = true
      this.options = res.result.titles
    },
    confirmSubmit() {
      if (this.file === '') {
        return this.$message.error(this.$t('message.contact.error3')) // if there is no file, alert the user with the error message.  If
      }
      if (this.value === '') {
        return this.$message.error(this.$t('message.contact.error4'))
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.importUser()
        }
      })
    },
    async importUser() {
      const obj = {
        module: 'contact',
        require: {
          action: 'upload',
          cover: this.radio,
          file_id: this.upid,
          file_type: 'csv',
          group_id: this.value, // if you want to import a group, then add it here.  It will be ignored for now.  It
          key_map: {
            name: this.formData.name,
            mail: this.formData.mail,
            cellphone: this.formData.cellphone,
            company: this.formData.company,
            notes: this.formData.notes,
          },
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(obj) })
      this.err_list = res.result.err_list
      this.ok_list = res.result.ok_list
      this.ignore_list = res.result.ignore_list
      this.$store.commit('contact/SETTING_UPDATE_STATUS')
      this.getPersonalContact()
      this.step++
    },
    // 导出数据
    exportErrorList(list) {
      const tt = list.map(item => {
        return {
          name: item[1],
          mail: item[0],
        }
      })
      import('@/vendor/Export2Excel').then((excel) => {
        this.exportLoading = false
        const tHeader = [this.$t('message.contact.displayname'), this.$t('message.contact.mailaddress')]
        const filterVal = ['name', 'mail']
        const data = tt.map((v) => filterVal.map((j) => v[j]))
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.$t('message.contact.error5'),
          autoWidth: true,
          bookType: 'csv',
        })
      })
    },
    beforeUpload(file) {
      const suffix = file[0].name.substr(file[0].name.lastIndexOf('.') + 1).toLowerCase()
      if (suffix === 'csv') {
        return true
      } else {
        this.$message.error(this.$t('message.contact.error6'))// if the file type is not csv, alert the user with the error message.  If it is csv, then continue.  It will be ignored for now.  It will be ignored for now.  It will
        return false
      }
    },
  },

}
</script>

<style lang="scss" scoped>
/deep/.importDialog.el-dialog {
    .el-dialog__header{
        padding-bottom: 5px;
    }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body{
    padding: 0 20px;
    cursor: default;
  }
}
/deep/.el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    z-index: 1;
}
.importDialog{
    .iconfont{
        font-size: 80px;
        color: #6BC839;
    }
    .compose-icon{
        vertical-align: middle;
    }
    .text{
        color: #1D1B1B;
    }
    .title{
        padding: 5px 0;
        .file{
            display: inline-block;
            height: 30px;
            line-height: 30px;
            background: #FAFAFC;
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid #D4D4D4;
            margin-left: 21px;
            .icon{
                padding-left: 8px;
            }
            .size{
               color: #8E8D8D;
            }
        }
    }
    .area{
      display: flex;
      align-items: center;
    }
    .temp{
        padding-top: 15px;
    }
    .btn{
        display: inline-block;
        width: 82px;
        height: 30px;
        background: #FFFAFA;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #D0161B;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
    }
    .importInfo{
        padding-top: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-weight: bold;
            padding: 18px 0;
        }
        .result{
            color:#8E8D8D;
            .godetail{
                color:#6BC839;
                cursor: pointer;
            }

        }
        .btnknow{
            padding: 46px 0 37px 0;
            cursor: pointer;
            .know{
            display: inline-block;
            width: 130px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background: #E0E0E0;
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            font-weight: bold;
        }
        }

    }
    .importResult{
        .btnknow{
            text-align: center;
            cursor: pointer;
            .know{
            display: inline-block;
            width: 130px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background: #E0E0E0;
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            font-weight: bold;
        }
        }
    }
}
/deep/.el-tabs__content{
  height: 400px;
  overflow-y: auto;
}
.custom__item{
    display: flex;
    justify-content: space-between;
}
.err-btn{
  position: absolute;
  left: 30px;
}
.addnewgroup{
  border-bottom: 1px solid #D4D4D4;
  padding: 0px 20px;
  .addnewgroupitem{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input{
      // width: 200px;
    }
  }
}
</style>
