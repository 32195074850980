import store from '@/store'
/**
     * @description: 获取文件的后缀名
     */
const GetFileType = {
  fileType: (fileName) => {
    const type = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()
    let FileType = ''
    if (['pdf', 'ofd'].includes(type)) {
      FileType = 'pdf'
    } else if (['ppt', 'pptx', 'dps', 'dpt', 'uop'].includes(type)) {
      FileType = 'ppt'
    } else if (['csv', 'xls', 'xlsx', 'et', 'ett', 'uos'].includes(type)) {
      FileType = 'xls'
    } else if (['zip', 'rar', 'tar', 'gz', 'tgz', '7z', 'arj', 'z', 'bz2', 'jar'].includes(type)) {
      FileType = 'zip'
    } else if (type === 'txt') {
      FileType = 'txt'
    } else if (['doc', 'docx', 'wps', 'wpt', 'uot'].includes(type)) {
      FileType = 'doc'
    } else if (['png', 'jpe', 'jpeg', 'jpg', 'gif', 'bmp', 'dib', 'jfif', 'ico', 'tiff', 'tif', 'svg', 'svgz'].includes(type)) {
      FileType = 'img'
    } else if (['rm', 'ram', 'mpg', 'mpeg', 'mpeg4', 'mp4', 'avi', 'rmvb', 'swf', 'flv', 'mov', 'asf', 'wmv'].includes(type)) {
      FileType = 'video'
    } else if (['wav', 'mid', 'midi', 'cda', 'mp3', 'wma', 'aiff', 'aif', 'ra', 'vqf', 'ape', 'aac', 'asp', 'm4a'].includes(type)) {
      FileType = 'mius'
    } else if (type === 'uof') {
      FileType = 'uof'
    } else {
      FileType = 'file'
    }
    return FileType
  },
  fileTypeok: (fileName) => {
    const type = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()
    return type
  },
  isPreView: (fileName) => {
    const name = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()
    // const type = ['txt', 'pdf', 'png', 'jpe', 'jpeg', 'jpg', 'gif']
    // const type = window._kglobal_myState.preview_atta_type
    const type = store.state.app.preview_atta_type
    let FileType = false
    if (type.includes(name)) {
      FileType = true
    } else {
      FileType = false
    }
    return FileType
  },
}
const countCharacters = (str) => {
  var totalCount = 0
  for (var i = 0; i < str.length; i++) {
    var charCode = str.charCodeAt(i)
    if (charCode > 127) {
      totalCount += 2
    } else {
      totalCount++
    }
  }
  return totalCount
}
const transformClass = {
  // 邮件大小（接口返回字节）
  mailSize: (size) => {
    if (!size) { return 0 }
    const num = 1024 // byte
    if (size < 10) { return 0.01 + 'k' }
    if (size < Math.pow(num, 2)) { return (size / num).toFixed(2) + 'K' } // kb
    if (size < Math.pow(num, 3)) { return (size / Math.pow(num, 2)).toFixed(2) + 'M' } // M
    if (size < Math.pow(num, 4)) { return (size / Math.pow(num, 3)).toFixed(2) + 'G' } // G
    return (size / Math.pow(num, 4)).toFixed(2) + 'T' // T
  },
  /** 24小时制改为12小时 */
  // timeAmPm(s) {
  //   let ampm = s.split(':').reduce((a, c) => a ? (s === a + c) ? a + c + 'am' : a + c + 'pm' : c % 12 + ':', '')
  //   const arr = ampm.split(':')
  //   if (arr[0].length === 1) ampm = '0' + arr[0] + ':' + arr[1]
  //   return ampm
  // },
  timeAmPm(time) {
    var hours = parseInt(time.substr(0, 2))
    var minutes = parseInt(time.substr(3, 2))
    var period = hours >= 12 ? 'pm' : 'am'

    if (hours === 0) {
      hours = 12
    } else if (hours > 12) {
      hours = hours - 12
    }

    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    if (period === 'am' && hours === 12) {
      hours = '00'
    }
    var convertedTime = hours + ':' + minutes + ' ' + period
    return convertedTime
  },
  /** 时间转换 unix时间
   * type: 'yyyy/MM/dd','年月日' */
  timeFormat(time, type) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const timeDate = new Date(time * 1000)
    const latestTime = new Date()
    const timeDateYear = timeDate.getFullYear()
    const timeDateMonth = timeDate.getMonth() + 1
    const timeDateDay = timeDate.getDate()
    let timeDateHours = ''
    if (store.state.app.userGetConfig.time_format === '12') {
      timeDateHours = timeDate.getHours() < 10 ? timeDate.getHours() : timeDate.getHours()
    } else {
      timeDateHours = timeDate.getHours() < 10 ? ('0' + timeDate.getHours()) : timeDate.getHours()
    }
    const timeDateMinutes = timeDate.getMinutes() < 10 ? ('0' + timeDate.getMinutes()) : timeDate.getMinutes()
    const latestTimeYear = latestTime.getFullYear()
    const latestTimeMonth = latestTime.getMonth() + 1
    const latestTimeDay = latestTime.getDate()
    const timeDateMonthStr = timeDateMonth < 10 ? ('0' + timeDateMonth) : timeDateMonth // 月
    const md = timeDateMonthStr + '月' + timeDateDay + '日' // MM月dd日
    let hm = timeDateHours + ':' + timeDateMinutes

    if (store.state.app.userGetConfig.time_format === '12')hm = transformClass.timeAmPm(hm)
    // 不需要判断年份相同
    if (type === 'yyyy年MM月dd日 hm') {
      let str = timeDateYear + '年' + md + hm
      if (localStorage.getItem('lang') === 'english') {
        str = window.vm.$i18n.t(`message.view.${months[timeDate.getMonth()]}`) + ' ' + timeDateDay + ', ' + ' ' + timeDateYear + ' ' + hm
      } else {
        str = timeDateYear + '年' + md + hm
      }
      return str
    } else {
      // 年份相同
      if (timeDateYear === latestTimeYear) {
      // 同月同日，显示hh:mm
        if (timeDateMonth === latestTimeMonth && timeDateDay === latestTimeDay) {
          return hm
        } else {
        // 同年不同月日
          let ss = ''
          if (localStorage.getItem('lang') === 'english') {
            ss = window.vm.$i18n.t(`message.view.${months[timeDate.getMonth()]}`) + ' ' + timeDateDay + ' '
          } else {
            ss = md
          }
          const str = type === 'yyyy/MM/dd' ? ss : (ss + hm)
          return str
        }
      } else { // 年份不同,显示yyyy/MM/dd或者yyyy年MM月dd日 hh:mm
        if (type === 'yyyy/MM/dd') {
          const str = timeDateYear + '/' + timeDateMonthStr + '/' + timeDateDay
          return str
        } else {
          // const str = timeDateYear + '年' + md + hm
          let str = timeDateYear + '年' + md + hm
          if (localStorage.getItem('lang') === 'english') {
            str = window.vm.$i18n.t(`message.view.${months[timeDate.getMonth()]}`) + ' ' + timeDateDay + ', ' + ' ' + timeDateYear + ' ' + hm
          } else {
            str = timeDateYear + '年' + md + hm
          }
          return str
        }
      }
    }
  },
  /** 时间转换 unix时间
   * '年月日'
   *  */
  timeNYRFormat(time) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const timeDate = new Date(time * 1000)
    const timeDateYear = timeDate.getFullYear()
    const timeDateMonth = timeDate.getMonth() + 1
    const timeDateDay = timeDate.getDate()
    // const timeDateHours = timeDate.getHours() < 10 ? ('0' + timeDate.getHours()) : timeDate.getHours()
    let timeDateHours = ''
    if (store.state.app.userGetConfig.time_format === '12') {
      timeDateHours = timeDate.getHours() < 10 ? timeDate.getHours() : timeDate.getHours()
    } else {
      timeDateHours = timeDate.getHours() < 10 ? ('0' + timeDate.getHours()) : timeDate.getHours()
    }
    const timeDateMinutes = timeDate.getMinutes() < 10 ? ('0' + timeDate.getMinutes()) : timeDate.getMinutes()
    const timeDateMonthStr = timeDateMonth < 10 ? ('0' + timeDateMonth) : timeDateMonth // 月
    const md = timeDateMonthStr + '月' + timeDateDay + '日' // MM月dd日
    let hm = timeDateHours + ':' + timeDateMinutes
    if (store.state.app.userGetConfig.time_format === '12')hm = transformClass.timeAmPm(hm)
    let str = timeDateYear + '年' + md + hm
    if (localStorage.getItem('lang') === 'english') {
      str = window.vm.$i18n.t(`message.view.${months[timeDate.getMonth()]}`) + ' ' + timeDateDay + ', ' + ' ' + timeDateYear + ' ' + hm
    } else {
      str = timeDateYear + '年' + md + hm
    }
    console.log(str)
    return str
  },
  /** 根据uidvalidity转换邮箱中文名 */
  mbox_name(uidvalidity, mailbox) {
    let str = ''
    mailbox.forEach(item => {
      if (item.uidvalidity === uidvalidity) str = item.mbox_name
    })
    return str
  },
  /** 根据mbox转换邮箱中文名 */
  mboxFilter_name(mbox, mailbox) {
    let str = ''
    mailbox.forEach(item => {
      if (item.mbox === mbox) str = item.mbox_name
    })
    return str
  },
  /** 根据uidvalidity转换邮箱值 */
  mbox(uidvalidity, mailbox) {
    let str = ''
    mailbox.forEach(item => {
      if (item.uidvalidity === uidvalidity) str = item.mbox
    })
    return str
  },
  /** 根据mbox转换邮箱uidvalidity值 */
  uidvalidity(mbox, mailbox) {
    let str = ''
    mailbox.forEach(item => {
      if (item.mbox === mbox) str = item.uidvalidity
    })
    return str
  },
  /** 转换密码报错提示 */
  password_error(min_num = 8, max_num = 32, total_num = '两', old_pass_flag = '不能', user_flag = '不能', lang) {
    let str = []
    if (lang === 'english') {
      str = [
        'The password length should be between' + min_num + '~' + max_num + 'characters',
        'The password must contain any ' + total_num + ' combinations of uppercase letters, lowercase letters, numbers, and special symbols.',
        `The password ${old_pass_flag === '能' ? 'can' : 'cannot'} be the same as the original password`,
        `Password ${user_flag === '能' ? 'can' : 'cannot'} contain username`,
      ]
    } else if (lang === 'traditional_chinese') {
      str = [
        '密碼長度在' + min_num + '~' + max_num + '位',
        '密碼必須包含大寫字母、小寫字母、數位、特殊符號任意' + total_num + '種組合',
        '密碼' + old_pass_flag + '與原密碼相同',
        '密碼' + user_flag + '包含用戶名',
      ]
    } else {
      str = [
        '密码长度在' + min_num + '~' + max_num + '位',
        '密码必须至少包含大写字母、小写字母、数字、特殊符号任意' + total_num + '种组合',
        '密码' + old_pass_flag + '与原密码相同',
        '密码' + user_flag + '包含用户名',
      ]
    }
    return str
  },
  password_error_html(min_num = 8, max_num = 32, total_num = '两', old_pass_flag = '不能', user_flag = '不能', type) {
    const arr = transformClass.password_error(min_num, max_num, total_num, old_pass_flag, user_flag, type)
    let error_html = ''
    arr.forEach((str, index) => {
      if (old_pass_flag === '能' && index === 2) {
        return
      }
      if (user_flag === '能' && index === 3) {
        return
      }
      error_html = error_html + '<p><span class="middle pw_import"></span><em>' + str + '</em></p>'
    })
    return error_html
  },
  /**
* 深拷贝
* @param {Object} obj 要拷贝的对象
*/
  deepClone(obj = {}) {
    if (typeof obj !== 'object' || obj == null) {
      // obj 是null,或者不是对象和数组，直接返回
      return obj
    }
    // 初始化返回结果
    let result
    if (obj instanceof Array) {
      result = []
    } else {
      result = {}
    }
    for (const key in obj) {
      // 保证 key 不是原型的属性
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = transformClass.deepClone(obj[key])
      }
    }
    return result
  },
  getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0; var v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  },
}
const copy = {
  // 复制
  copyText(lookHtml) {
    // handleClipboard(this.randompassword, event)
    // navigator clipboard 需要https等安全上下文
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard 向剪贴板写文本
      return navigator.clipboard.writeText(lookHtml)
    } else {
      // 创建text area
      const textArea = document.createElement('textarea')
      textArea.value = lookHtml
      // 使text area不在viewport，同时设置不可见
      textArea.style.position = 'absolute'
      textArea.style.opacity = 0
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      return new Promise((res, rej) => {
        // 执行复制命令并移除文本框
        document.execCommand('copy') ? res() : rej()
        textArea.remove()
      })
    }
  },
}
const getQueryVariable = {
  // 获取字符串地址中的参数
  url(variable, url) {
    var str = url.split('?')
    var query = str[1]
    var vars = ''
    if (query) {
      vars = query.split('&')
    }
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (pair[0] === variable) {
        return pair[1]
      }
    }
    return (false)
  },
}
const isNULL = (o) => {
  return (o === undefined || o == null)
}
const isBLANK = (o) => {
  return (isNULL(o) || o === '')
}
const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  const copy = Array.isArray(obj) ? [] : {}

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key])
  })

  return copy
}
const getAfterTimer = (futureDate, daysToAdd) => {
  futureDate.setDate(futureDate.getDate() + Number(daysToAdd))
  const year = futureDate.getFullYear()
  const month = (futureDate.getMonth() + 1).toString().padStart(2, '0')
  const day = futureDate.getDate().toString().padStart(2, '0')
  const hours = futureDate.getHours().toString().padStart(2, '0')
  const minutes = futureDate.getMinutes().toString().padStart(2, '0')
  const seconds = futureDate.getSeconds().toString().padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
const timeStampConvert = (timestamp) => {
  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
const iframeEventPop = (doc) => {
  doc.addEventListener('click', () => {
    ['mousedown', 'mouseup'].forEach((t) => {
      const evt = document.createEvent('MouseEvents')
      try {
        evt.initMouseEvent(t, true, false, window, 0, 0, 0, 0, 0)
        document.body.dispatchEvent(evt)
      } catch (e) {
        // console.log(e)
      }
    })
    // const div = document.getElementById('view_center')
    // div.click()
  })
  doc.onkeydown = () => {
    const keyCode = window.event.keyCode
    // ctrl, shift, 还未处理
    try {
      var evtObj = new KeyboardEvent('keydown', { keyCode: keyCode, repeat: false, isComposing: false })
      document.dispatchEvent(evtObj)
    } catch (e) {
      // console.log(e)
    }
  }
}
function isMSbrowser() {
  const userAgent = window.navigator.userAgent
  return userAgent.indexOf('Edge') !== -1 || userAgent.indexOf('Trident') !== -1
}

function format(data) {
  return String(data).replace(/"/g, '""').replace(/(^[\s\S]*$)/, '"$1"')
}

function saveCSV(title, head, data) {
  const wordSeparator = ','
  const lineSeparator = '\n'

  const reTitle = title + '.csv'
  const headBOM = '\ufeff'
  const headStr = head ? head.map(item => format(item)).join(wordSeparator) + lineSeparator : ''
  const dataStr = data ? data.map(row => row.map(item => format(item)).join(wordSeparator)).join(lineSeparator) : ''

  return isMSbrowser()
    ? new Promise(resolve => { // Edge、IE11
      const blob = new Blob([headBOM + headStr + dataStr], { type: 'text/plain;charset=utf-8' })
      window.navigator.msSaveBlob(blob, reTitle)
      resolve()
    })
    : new Promise(resolve => { // Chrome、Firefox
      const a = document.createElement('a')
      a.href = 'data:text/csv;charset=utf-8,' + headBOM + encodeURIComponent(headStr + dataStr)
      a.download = reTitle
      a.click()
      resolve()
    })
}
const debounce = function(fn, delay) {
  let timer = null
  return function() {
    const content = this
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(content, args)
    }, delay)
  }
}

export {
  GetFileType,
  transformClass,
  copy,
  isNULL,
  isBLANK,
  deepCopy,
  getQueryVariable,
  countCharacters,
  getAfterTimer,
  timeStampConvert,
  iframeEventPop,
  saveCSV,
  debounce,

}
