<template>

  <div class="mail_recall noBorder ">
    <el-dialog
      :title="$t('message.view.mail_reccall')"
      :visible.sync="dialogTableVisible"
      :modal-append-to-body="false"
      :append-to-body="true"
      width="700px"
      @close="closeDia"
    >
      <el-table
        :data="gridData"
        max-height="450px"
      >
        <el-table-column
          property="mail"
          :label="$t('message.view.to')"
        >

          <template
            slot-scope="scope"
          >
            <p
              class="one-line-ellipsis"
            >
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
              >
                <div
                  slot="content"
                  style="max-width:500px"
                >{{ scope.row.mail }}</div>
                <span>{{ scope.row.mail }}</span>
              </el-tooltip>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          property=""
          :label="$t('message.view.recieve_status')"
        >
          <template
            slot-scope="scope"
          >
            <p
              class="one-line-ellipsis"
            >
              <el-tooltip
                class="item"
                effect="light"
                placement="top"
              >
                <div
                  slot="content"
                  style="max-width:500px"
                >{{ $t(`message.mailstatus.${scope.row.status}`) }}</div>
                <span>{{ $t(`message.mailstatus.${scope.row.status}`) }}</span>
              </el-tooltip>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="info.type == 'tracking'"
          property=""
          :label="$t('message.view.time')"
          width="200"
        >
          <span>{{ info.time }}</span>
        </el-table-column>
        <el-table-column
          v-else
          property=""
          :label="$t('message.view.time')"
          width="200"
        >
          <span>{{ info.time | dateFilter }}</span>
        </el-table-column>
        <el-table-column
          v-if="!isMeeting"
          property=""
          :label="$t('message.view.recacll')"
          width="100"
        >

          <template slot-scope="scope">
            <!-- 显示的召回状态 -->
            <p v-if="status_array.indexOf(scope.row.status) == -1" />
            <!-- 是否可以召回 -->
            <p v-else>
              <!-- force_recall是否是强制召回over_recall_time值为1=超过24小时，0=没有超过24小时 -->
              <!-- {{ force_recall }}{{ info.over_recall_time }}{{ scope.row.status }} -->
              <span
                v-if="!force_recall && !info.over_recall_time && scope.row.status=='receivenoread'"
                class="cur color"
                @click="handRecall(scope.row)"
              >{{ scope.row.type }} {{ $t('message.view.recacll') }}</span>
              <span
                v-if="force_recall && status_array.indexOf(scope.row.status) !== -1"
                class="cur color"
                @click="handRecall(scope.row)"
              >{{ scope.row.type }} {{ $t('message.view.recacll') }}</span>
            </p>

          </template>
        </el-table-column>
      </el-table>
      <span

        slot="footer"
        class="dialog-footer"
      >
        <!-- 区分强制召回和普通召回，普通召回就显示这个提示，只能在48小时内召回，强制召回就不受时间限制 -->
        <span
          v-if="!isMeeting"
          v-show="!force_recall"
          class="txt_left"
        >{{ $t('message.view.recall_info') }}</span>
        <el-button
          v-if="!isMeeting"
          v-show="isAllreacll"
          type="primary"
          @click="handAllRecall"
        >{{ $t('message.view.recall_one_key') }}</el-button>
        <el-button @click="closeDia">{{ $t('message.btnText.close') }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>

import serialize from '@/utils/serialize'
import GetRecallType from '@/utils/recallType'
import { transformClass } from '@/utils/utils.js'
export default {
  filters: {
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
  },
  props: {
    mailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    isMeeting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      info: this.mailInfo,
      time: false,
      gridData: [], // 列表
      force_recall: this.$store.state.app.settingInfo.force_recall, // 是否是强制召回
      isAllreacll: false,
      status_array: ['receivenoread', 'mailaudit_move', 'madereading', 'mailaudit_read', 'mailaudit_mark', 'mailaudit_reply'], // 召回类型
    }
  },
  watch: {
    mailInfo: function(a, b) {
      if (a.recallType) {
        this.dialogTableVisible = a.recallType
        this.info = a
        this.getList()
      }
    },
  },
  created() {},
  mounted() {
  },
  methods: {
    // 邮件状态
    getRecallType(name) {
      const type = new GetRecallType(name).toValue()
      return type
    },
    closeDia() {
      this.dialogTableVisible = false
      // 向父组件传值
      this.$emit('showRecall', false)
    },
    // 显示
    getList() {
      const req = { 'module': 'mail', 'require': {
        action: 'getSendInfo',
        message_id: this.info.message_id,
        mid: this.info.mid,
      },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.gridData = res.result.status_list
        if (this.gridData.length <= 1) {
          this.isAllreacll = false
          return false
        }
        const status = []
        this.gridData.map((el) => {
          status.push(el.status)
        })
        if (!this.force_recall && !this.info.over_recall_time && status.indexOf('receivenoread') > -1) {
          // 普通召回：显示一键召回
          this.isAllreacll = true
        }
        if (this.force_recall) {
          // 强制召回：显示一键召回
          const type = this.status_array
          type.filter(i => { return status.includes(i) }).length > 0 ? this.isAllreacll = true : this.isAllreacll = false
        }
      })
    },
    // 单个召回
    handRecall(row) {
      const req = { 'module': 'mail', 'require': {
        action: 'recall',
        message_id: this.info.message_id,
        mid: this.info.mid,
        subject: this.info.subject,
        tomail: row.mail,
        mail_seclevel: this.mail_seclevel,
      },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        row.status = res.result.label
      })
    },
    // 一键召回
    handAllRecall(row) {
      var tomail = []
      this.gridData.forEach(el => {
        if (this.force_recall) { // 是否是强制召回
          this.status_array.indexOf(el.status) !== -1 ? tomail.push(el.mail) : ''
        } else {
          el.status === 'receivenoread' && !this.info.over_recall_time ? tomail.push(el.mail) : ''
        }
      })
      const req = { 'module': 'mail', 'require': {
        action: 'allRecall',
        message_id: this.info.message_id,
        mid: this.info.mid,
        subject: this.info.subject,
        tomail: tomail,
        mail_seclevel: this.info.mail_seclevel,
      },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.getList()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/tbNoBorder.scss';
  .txt_left{
    float: left;
  }
  .color{
    color: #d0161b;
    font-weight: 500;
  }
</style>
