<template>
  <div class="down">
    <el-form
      ref="formLabelAlign"
      :model="formLabelAlign"
      method="post"
      target="_blank"
      :action="Url"
    >
      <el-form-item>
        <el-input
          v-model="formLabelAlign.task"
          name="task"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formLabelAlign.cal_obj_id"
          name="cal_obj_id"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formLabelAlign.upid"
          name="upid"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formLabelAlign.from_id"
          name="from_id"
        />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formLabelAlign: {
        task: 'getMeetingAtt',
        cal_obj_id: '',
        upid: '',
        from_id: '',
      },
      Url: '',
    }
  },
  watch: {
    isShow: function(a, b) {
      if (a) {
        this.formLabelAlign.cal_obj_id = this.$parent.downAttaReq.cal_obj_id
        this.formLabelAlign.upid = this.$parent.downAttaReq.upid
        this.formLabelAlign.from_id = this.$parent.downAttaReq.from_id
        this.down(this.$parent.downAttaReq.attachment_name)
      }
    },
  },
  methods: {
    down(filename) {
      var url = '/nsmail/mail.php/'
      url += encodeURI(filename.replace(/[\/ ?\t\r\n:\|\\*\"\#\'<>]/g, ' '))
      this.Url = url
      this.$nextTick(() => {
        this.$refs.formLabelAlign.$el.submit()
        this.$parent.isAttaDown = false
      })
    },

  },
}

</script>
  <style lang="scss" scoped>
  .down{
    display: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
  }
  </style>

