<template>
  <div class="attachment_box noBorder trunBottom">
    <div class="action">
      <p class="action_right">
        <el-input
          v-model="search"
          :placeholder="$t('message.att.input_att_name')"
          :title="$t('message.att.input_att_name')"
          clearable
          class="input-with-select"
          @clear="searchFun"
          @keydown.enter.native="searchFun"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchFun"
          />
        </el-input>
      </p>
      <div class="action_left">
        <div class="select">
          <div class="Opea-svg-select-noBorder">
            <span
              class="Opea-svg-select-noBorder-span"
              style="margin-right: 5px;"
            >{{ $t(`message.att.${typeOptions[typeValue].label}`) }}</span>
            <el-select
              v-model="typeValue"
              popper-class="Opea-svg-select-popper"
              :popper-append-to-body="false"
              style="width:120%;position: absolute;left:0;top:0;"
              @change="selectType"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="$t(`message.att.${item.label}`)"
                :value="item.value"
              />
            </el-select></div></div>

        <em />
        <span
          class="cur"
          @click="toCompose(multipleSelection,'all')"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon "
          icon-class="compose"
        /> {{ $t('message.att.send') }}</span>
        <em />
        <span
          class="cur"
          @click="downAtta('allDown','')"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon"
          icon-class="download"
        /> {{ $t('message.att.down') }}</span>
        <em />
        <span
          v-if="$store.state.app.user_permissions.netdisk == 1"
          class="cur"
          @click="handleIcludClick('all')"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon"
          icon-class="webdisk"
        /> {{ $t('message.att.save_drive') }}</span>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      highlight-current-row
      :height="attachmentTabHeight"
      class="table"
      @selection-change="handleSelectionChange"
      @sort-change="sortTabl"
    >
      <el-table-column
        type="selection"
        align="center"
        width="55"
      />
      <el-table-column
        :label="$t('message.att.name')"
        prop=""
        min-width="200px"
      >
        <template
          slot-scope="scope"
        >
          <p
            class="one-line-ellipsis"
          >
            <svg-icon
              class="attaClass middle attachmentIcon"
              :icon-class="getFileType(scope.row.att_name)+'icon'"
              class-name="atticon"
            />
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
              :open-delay="300"
            >
              <div
                slot="content"
                style="max-width:500px"
              >{{ scope.row.att_name == '' ? $t('message.att.no_name') : scope.row.att_name }}</div>
              <span> {{ scope.row.att_name == '' ? $t('message.att.no_name') : scope.row.att_name }}</span>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="att_date"
        :label="$t('message.att.time')"
        sortable="custom"
        width="230"
      >
        <template slot-scope="scope">
          <p class="colorBD">{{ scope.row.att_date | dateFilter }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="from_name"
        :label="$t('message.att.from')"
        width="200"
      >

        <template
          slot-scope="scope"
        >
          <p
            class="one-line-ellipsis colorBD"
          >
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
              :open-delay="300"
            >
              <div
                slot="content"
                style="max-width:500px"
              >{{ scope.row.from_name }}</div>
              <span>{{ scope.row.from_name }}</span>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        :label="$t('message.att.sub')"
      >
        <template
          slot-scope="scope"
        >
          <p
            class="one-line-ellipsis colorBD"
          >
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
              :open-delay="300"
            >
              <div
                slot="content"
                style="max-width:500px"
              >{{ scope.row.subject }}</div>
              <span>{{ scope.row.subject && scope.row.subject != '' ? scope.row.subject : $t('message.list.no_subject') }}</span>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="att_size"
        :label="$t('message.att.size')"
        sortable="custom"
        width="140"
      >
        <template slot-scope="scope">
          <p class="colorBD">{{ scope.row.att_size | mailSizefilter }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label=" "
        width="250"
      >
        <template slot-scope="scope">
          <el-tooltip
            v-if="isPreView(scope.row.att_name)"
            class="item cur tooltip"
            effect="light"
            :open-delay="300"
            :content="$t('message.att.view')"
            placement="top-start"
          >
            <span>
              <svg-icon
                class="svgIcon"
                icon-class="preview"
                @click="downAtta('preview',scope.row)"
              />
            </span>
          </el-tooltip>
          <el-tooltip
            class="item cur tooltip"
            effect="light"
            :open-delay="300"
            :content="$t('message.att.send')"
            placement="top-start"
          >
            <span>
              <svg-icon
                class="svgIcon"
                icon-class="compose"
                @click="toCompose(scope.row,'one')"
              />
            </span>
          </el-tooltip>
          <el-tooltip
            class="item cur tooltip"
            effect="light"
            :open-delay="300"
            :content="$t('message.att.down')"
            placement="top-start"
          >

            <span>
              <svg-icon
                class="svgIcon"
                icon-class="download"
                @click="downAtta('download',scope.row)"
              />
            </span>
          </el-tooltip>
          <el-tooltip
            v-if="$store.state.app.user_permissions.netdisk == 1"
            class="item cur tooltip"
            effect="light"
            :open-delay="300"
            :content="$t('message.att.save_drive')"
            placement="top-start"
          >

            <span>
              <svg-icon
                class="svgIcon"
                icon-class="webdisk"
                @click="handleIcludClick(scope.row)"
              />
            </span>
          </el-tooltip>
          <el-tooltip
            class="item cur tooltip"
            effect="light"
            :open-delay="300"
            :content="$t('message.att.form_mail')"
            placement="top-start"
          >

            <span>
              <svg-icon
                class="svgIcon"
                icon-class="from"
                @click="handleFormClick(scope.row)"
              />
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 下载附件 -->
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />
    <!-- 翻页 -->
    <p
      v-if="total>0"
      class="trunPage"
    >
      <el-pagination
        :current-page="page"
        :page-size="page_size"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </p>

    <!-- 转存网盘 -->
    <saveDisk
      :file-list="fileListDisk"
      :is-show="showDialogDisk"
      :is-type="isType"
    />
  </div>
</template>
<script>
import { GetFileType, transformClass } from '@/utils/utils.js'
import serialize from '@/utils/serialize'
import downAttaForm from '../components/downAtta.vue'
import saveDisk from '../components/saveDisk.vue'
export default {
  name: 'Attachment',
  components: {
    downAttaForm,
    saveDisk,
  },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
  },

  props: {
    idPlus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      isAttaDown: false,
      tableData: [],
      multipleSelection: [],
      typeOptions: [{
        value: '0',
        label: 'all',
      }, {
        value: '1',
        label: 'zip',
      }, {
        value: '2',
        label: 'doc',
      }, {
        value: '3',
        label: 'img',
      }, {
        value: '4',
        label: 'audio',
      }, {
        value: '5',
        label: 'other',
      }],
      typeValue: '0',
      page_size: Number(this.$store.state.app.userGetConfig.page_size), // 每页多少条
      // page_size: 20, // 每页多少条
      page: 1, // 第几页
      total: 0, // 总共多少条
      orderby: 'date', // 默认排序
      sequence: 'DESC',
      fileListDisk: [], // 保存网盘选中的文件
      showDialogDisk: false, // 是否打开保存网盘
      isType: {
        file: 'attachment',
        isAll: false,
      },
      formatVal: '',
      attachmentTabHeight: window.innerHeight - 200,
    }
  },
  watch: {
    idPlus(newVal, oldVal) {
      if (newVal) {
        this.page = 1
        this.getList()
      }
    },
  },
  created() {
    console.log(this.objData, 'objData')
  },
  mounted() {
    this.getList()
    window.onresize = () => {
      return (() => {
        this.attachmentTabHeight = window.innerHeight - 200
      })()
    }
  },
  methods: {
    searchFun() {
      this.page = 1
      this.getList()
    },
    // 跳转到写信
    toCompose(data, type) {
      let attaData = []
      if (type === 'one') {
        attaData.push(data)
      } else {
        attaData = [...data]
      }
      if (!attaData.length) {
        this.$message.error(this.$t('message.att.select_file'))
        return false
      }
      const requestLists = attaData.map(attachment => {
        return {
          charset: attachment.att_charset,
          disposition: attachment.att_disposition,
          encoding: attachment.att_encoding,
          mbox: attachment.mail_mbox,
          name: attachment.att_name,
          psection: attachment.att_psection,
          section: attachment.att_section,
          size: attachment.att_size,
          subtype: attachment.att_subtype,
          type: attachment.att_type,
          uid: attachment.uid,
          uv: attachment.uidvalidity,
          message_id: attachment.message_id,
        }
      })
      const params = {
        module: 'mail',
        require: {
          action: 'attachmentMultiForwardOne',
          list: requestLists,
        },

      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        const atts = result.map((attachment, index) => {
          return {
            subject: this.$t('message.mbox.newmail'),
            cid: attachment.cid,
            name: attachment.fileName,
            type: 'upload',
            size: attachment.fileSize,
            upid: attachment.upid,
            fileuptype: 'attsManager',
            origin: 'attaManage',
            originData: requestLists[index],
          }
        })
        // this.attsManagerFiles.map(item => {
        //   item.checked = false
        // })
        this.$myApi.mainTab.showComposeMail({ type: 'attachment', subject: this.$t('message.mbox.newmail'), composeData: atts })
      })
    },
    // 文件类型
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    // 列表选择值
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 保存网盘
    handleIcludClick(val) {
      this.fileListDisk = []
      if (val === 'all' && this.multipleSelection.length === 0) {
        this.$message.error(this.$t('message.att.select_file'))
        return false
      }
      if (val === 'all') {
        this.fileListDisk = this.multipleSelection
        this.isType.isAll = true
      } else {
        this.fileListDisk.push(val)
        this.isType.isAll = false
      }
      this.showDialogDisk = true
    },
    // 来自邮件
    handleFormClick(val) {
      const data = {
        uid: val.uid,
        uidvalidity: val.uidvalidity,
        uv: val.uidvalidity,
        mbox: val.mail_mbox,
      }
      this.$myApi.mainTab.showWholeMail('attachment', data)
    },
    // 是否能够预览
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    // 邮件页面下载
    downAtta(type, el) {
      const disposition = type === 'allDown' ? 'attachment' : el.att_disposition
      if (type === 'allDown') {
        if (!this.multipleSelection.length) {
          this.$message.error(this.$t('message.att.select_file'))
          return false
        }
        this.isAttaDown = true
        let name = this.multipleSelection[0].att_name
        const index = name.lastIndexOf('.')
        name = name.substring(0, index)
        const list = []
        this.multipleSelection.map(item => {
          list.push({
            'atta_seclevel': item.atta_seclevel,
            'charset': item.att_charset,
            'cid': item.att_cid,
            'disposition': item.att_disposition,
            'encoding': item.att_encoding,
            'name': item.att_name,
            'psection': item.att_psection,
            'section': item.att_section,
            'size': item.att_size,
            'subtype': item.att_subtype,
            'type': item.att_type,
            'uid': item.uid,
            'uidvalidity': item.uidvalidity,
            'mbox': item.mail_mbox,
            'message_id': item.message_id,
            'secret_terminal': 0,
          })
        })
        // 打包下载
        this.downAttaReq = {
          list: list,
          module: 'mail_attachment',
          att_type: 'multi',
          disposition: 'attachment',
          from_type: 'attachment',
          attachment_name: name + '.zip',
        }
      } else {
        // 单个下载
        this.isAttaDown = true
        this.downAttaReq = {
          module: 'mail_attachment',
          from_type: 'attachment',
          atta_seclevel: el.atta_seclevel,
          attachment_name: el.att_name,
          disposition: disposition,
          encoding: el.att_encoding,
          mbox: el.mail_mbox,
          message_id: el.message_id,
          secret_terminal: 0,
          section: el.att_section,
          uid: el.uid,
          uv: el.uidvalidity,
        }
        if (type === 'preview') {
          this.downAttaReq.preview = 1
          this.downAttaReq.disposition = 'inline'
          this.downAttaReq.suffix = el.att_suffix.toLowerCase()
        } else {
          this.downAttaReq.disposition = disposition
        }
      }
    },
    // 获取列表
    getList() {
      this.formatVal = ''
      this.typeValue = '0'

      const req = {
        'module': 'mail',
        'require': {
          action: 'attachmentList',
          limit: this.page_size,
          from: this.page,
          type: 'folder',
          words: this.search,
          orderby: this.orderby,
          sequence: this.sequence,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.tableData = res.result.list
        this.total = res.result.total
      })
    },
    // 切换格式
    selectType() {
      this.page = 1
      this.orderby = 'date' // 默认排序
      this.sequence = 'DESC'
      if (this.typeValue === '1') {
        this.formatVal = 1
      } else if (this.typeValue === '2') {
        this.formatVal = 2
      } else if (this.typeValue === '3') {
        this.formatVal = 3
      } else if (this.typeValue === '4') {
        this.formatVal = 4
      } else if (this.typeValue === '5') {
        this.formatVal = 5
      } else {
        this.formatVal = ''
      }
      this.changeAtta()
    },
    changeAtta() {
      const req = { 'module': 'mail', 'require': { action: 'attachmentList', format: this.formatVal, from: this.page, limit: this.page_size, page: this.page, type: 'folder', orderby: this.orderby, sequence: this.sequence }}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.tableData = res.result.list
        this.total = res.result.total
      })
    },
    // 翻页
    handleCurrentChange(val) {
      this.page = Number(`${val}`)
      if (this.typeValue === '' || this.typeValue === '0') {
        this.getList()
      } else {
        this.changeAtta()
      }
    },
    // 排序事件
    sortTabl(column) {
      this.page = 1
      this.orderby = column.prop === 'att_size' ? 'size' : 'date'
      this.sequence = column.order === 'ascending' ? 'ASC' : 'DESC'

      if (this.typeValue === '' || this.typeValue === '0') {
        this.getList()
      } else {
        this.changeAtta()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
@import '@/styles/tbNoBorder.scss';
.attachment_box {
  width: 100%;
  height: 100%;
  padding: 20px;
  // overflow: auto;
  .action {
    .action_right{
      float: right;
      .el-input-group{
        z-index:1;
      }
    }
    .action_left{
      height: 32px;
      display:flex;
    }
    .select{
      float:left;
      min-width: 82px;
      // padding: 0 10px;
      font-weight: bold;
    }
    .Opea-svg-select-noBorder{
      margin:0 20px 0 0;
    }
    em {
      width: 1px;
      height: 20px;
      background: #c3c3c3;
      float: left;
      margin: 6px 14px 0;
    }
    span {
      float: left;
      line-height: 32px;
    }
    .action_icon{
      vertical-align: middle;
    }
  }
  .el-table__row{
      &:hover .svgIcon{
          display: block;  // 设置父元素hover时子元素的样式 【实现要点！！！！！】
      }
    }

  .tooltip{

    margin: 0 10px;
    float: left;
    width: 1.2em;
    height: 1.2em;
  }
  .svgIcon{
    display: none;
    width: 1.2em;
    height: 1.2em;
  }
  .attachmentIcon{
    width: 1.2em;
    height: 1.2em;
  }

  .table{
    margin-top: 8px;
  }
}
</style>
