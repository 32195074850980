<template>
  <div
    id="app"
  >
    <lockScreen v-if="$store.state.app.isLock" />
    <prePath v-if="topRoute=='/pre-path'" />
    <login v-else-if="topRoute=='login'" />
    <clientLogin v-else-if="topRoute=='/clientLogin'" />
    <auditMailView v-else-if="topRoute=='/audit' || topRoute=='/banner-view/'" />
    <forgetPassword v-else-if="topRoute=='forgetPassword'" />
    <netdiskShare v-else-if="topRoute=='/netdisk_share'" />
    <convertView v-else-if="topRoute=='/convert-view'" />
    <apiView v-else-if="topRoute=='/api-view/'" />
    <print v-else-if="topRoute=='/print'" />
    <!--
      如果 home 组件也用 v-if, 会失败, 目前情况 :
        1, home下所有 mail, contact, calendar等都还没有初始化
        2, 如果需要调用 home的各种方法会失败
      如果必须用 v-if， 那么应该 :
        1, 要维护一个变量如 this.$myState.homeInitData
        2, home组件初始化的时候根据 this.$myState.homeInitData 执行相关的初始化命令
      如果用 v-show 的方式, 需要注意:
        1, 还有一些组件没考虑到, 会激活 ajax 请求, 这个必须修改
        2, 可能一些组件的 watch 会激活 ajax 请求, 这个不可控
    -->
    <home v-show="topRoute=='home'" />
    <!-- <router-view v-else-if="isRresh" /> -->
  </div>
</template>
<script>
import { routeInit } from './utils/route'

import prePath from './views/pre-path.vue'
import login from './views/login/index.vue'
import clientLogin from './views/login/clientLogin.vue'
import auditMailView from './views/viewMail/auditMailView.vue'
import forgetPassword from './views/login/forgetPassword.vue'
import lockScreen from './views/lock-screen/index.vue'
import netdiskShare from './views/home-view/components/network-disk/netdisk_share.vue'

import convertView from './views/viewMail/apiView.vue'
import apiView from './views/viewMail/apiView.vue'
import print from './views/viewMail/print.vue'
import home from './views/home-view/index.vue'

export default {
  name: 'App',
  components: {
    prePath,
    login,
    clientLogin,
    auditMailView,
    forgetPassword,
    lockScreen,
    netdiskShare,
    convertView,
    apiView,
    print,
    home,
  },
  provide() {
    return {
      refreshFn: this.refresh,
    }
  },
  data() {
    return {
      topRoute: '',
      isRresh: true,
    }
  },
  created() {
    const thisobj = this
    this.$myApi.topGoto = (module, data, options) => {
      return thisobj.topGoto(module, data, options)
    }
    this.$myApi.clearUserData = () => {
      return thisobj.clearUserData()
    }
    this.$myApi.lockScreenSet = (data) => {
      return thisobj.lockScreenSet(data)
    }
    routeInit()
  },
  mounted() {
    this.firstLoad()
    // window.onkeydown = window.onkeyup = window.onkeypress = function(event) {
    // // 判断是否按下F12，F12键码为123
    //   if (event.keyCode === 123) {
    //     event.preventDefault() // 阻止默认事件行为
    //     event.returnValue = false
    //   }
    // }
    // window.oncontextmenu = function(event) {
    //   event.preventDefault() // 阻止默认事件行为
    //   return false
    // }
  },
  methods: {
    lockScreenSet(data) {
      this.$store.commit('app/IS_LOCK_SETTING', data)
    },
    firstLoad() {
      this.$myApi.appFirstGogo()
    },
    topGoto(module, data, options) {
      console.log('topGoto', module, data)
      if (typeof options !== 'object') {
        options = { }
      }
      if (options.isHistory === undefined) {
        options.isHistory = false
      }

      switch (module) {
        case 'login':
          console.log(module, options.firstGogo, '+++++')
          // if (options.firstGogo !== true) {
          //   return this.$myApi.topGotoByRedirect(module, data)
          // }
      }

      if (module === 'login' && !options.isHistory) {
        this.$myApi.routeSet('login')
        this.$store.commit('app/IS_LOCK_SETTING', false)
      }
      if (module === 'forgetPassword' && !options.isHistory) {
        this.$myApi.routeSet('forgetPassword')
      }
      // if (module === 'lock-screen' && !options.isHistory) {
      //   this.$myApi.routeSet('lock-screen')
      // }
      if (module === 'netdisk_share' && !options.isHistory) {
        this.$myApi.routeSet('netdisk_share')
      }
      if (module === 'pre-path' && !options.isHistory) {
        this.$myApi.routeSet('pre-path')
      }

      let submodule = ''
      if (!module || module === '') {
        module = 'mail'
      }
      switch (module) {
        case 'home':
        case 'mail':
        case 'contact':
        case 'calendar':
        case 'netdisk':
        case 'setting':
          this.$myState.homeRouter = module
          submodule = module
          module = 'home'
          break
      }
      this.topRoute = module
      const headerSearchType = this.$myState.headerSearchType
      this.$myState.headerSearchType = ['mail', 'contact', 'netdisk'].indexOf(submodule) > -1 ? submodule : '' // header搜索框下拉值默认选择
      if (headerSearchType !== this.$myState.headerSearchType) this.$myState.headerSearchWords = '' // 切换不同模块搜索框值清空
      if (module === 'home') {
        this.$myApi.activeModuleHOME()
        if (submodule === 'home') {
          this.$myApi.activeModuleHome(data, options)
        } else if (submodule === 'mail') {
          this.$myApi.activeModuleMail(data, options)
        } else if (submodule === 'calendar') {
          this.$myApi.activeModuleCalendar(data, options)
        } else if (submodule === 'contact') {
          this.$myApi.activeModuleContact(data, options)
        } else if (submodule === 'netdisk') {
          this.$myApi.activeModuleNetdisk(data, options)
        } else if (submodule === 'setting') {
          this.$myApi.activeModuleSetting(data, options)
        }
      }
    },
    clearUserData() {
      this.$myApi.clearUserDataModuleHOME()
      this.$myApi.clearUserDataModuleHome()
      this.$myApi.clearUserDataModuleMail()
      this.$myApi.clearUserDataModuleMailMenu()
      this.$myApi.clearUserDataModuleNetdisk()
      this.$myApi.clearUserDataModuleContact()
      this.$myApi.clearUserDataModuleCalendar()
      this.$myApi.clearUserDataModuleSetting()
    },
    refresh() {
      this.isRresh = false
      this.$nextTick(() => {
        console.log('zhixing')
        this.isRresh = true
      })
    },
  },
}
</script>
<style lang="scss">
#app{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media print   {
  #app{
   height:auto;
   overflow:visible;
   vertical-align:top;
   display:inline-block;
  }
}
body { padding: 0 !important; }
</style>

