const __defaultReFoward = (mail, fontSize, lang) => {
  return `
  <p><br></p>
  <p><br></p>
    <div id='cs2c_re' style='margin-left:0.5em;padding-left:0.5em;border-left:1px solid #cacaca;-webkit-user-modify: read-only;-moz-user-modify: read-only;-ms-user-modify: read-only;-o-user-modify: read-only;'>
    <br /><br /><br />
    <div style='background-color:#f8f8f8;padding:8px 10px;font-family: Microsoft YaHei; font-size: ${fontSize}px; color: #000;'>
    <B>${window.vm.$i18n.t('message.compose.adv1')}：</B><span id='cs2c_subject'>${mail.subject}</span>
    <br><B>${window.vm.$i18n.t('message.compose.adv3')}：</B><span id='cs2c_date'>${mail.date}</span>
    <br><B>${window.vm.$i18n.t('message.compose.from')}：</B><span id='cs2c_from'>${mail.from}</span>
    <br><B>${window.vm.$i18n.t('message.compose.to')}：</B><span id='cs2c_to' style='word-break:break-all'>${mail.to}</span>
    </div>
    <br />
    <div id='cs2c_content'>
    ${mail.body}
    </div>
    </div>
   
`
}
const BigAttachmentsText = (file, lang) => {
  return `
  <div style='clear:both;margin-top:10px;margin-bottom:15px;background:#e2e5e2;padding:4px;font-family:verdana,Arial,Helvetica,sans-serif'>
  <div style='font-size:14px;padding:4px 8px 8px 8px;line-height:16px;font-family: Microsoft YaHei; font-size: 16px; color: #000;'>
      <b>${window.vm.$i18n.t('message.mailmodule.cloud_att')}</b>
  </div>
  <div style='background:#fff;padding:4px;font-family: Microsoft YaHei; font-size: 16px; color: #000;'>
      <a target='_blank' href='${file.url}' style='pointer-events:initial;'>${file.name}</a> (${window.vm.$i18n.t('message.transfer.expired')}: <span>${file.expire}</span>)
  </div>
</div>

  `
}
const shareTemplate = (arr, lang) => {
  let t = ''
  arr.map(item => {
    t += `${window.vm.$i18n.t('message.netdisk.link')}：<a target='_blank' href='${item.share_link}'>${item.share_link}</a> ${window.vm.$i18n.t('message.netdisk.code')}: <span>${item.share_password}</span><br/>`
  })
  return `
  <div style='clear:both;margin-top:10px;margin-bottom:15px;background:#e2e5e2;padding:4px;font-family:verdana,Arial,Helvetica,sans-serif'>
  <div style='font-size:14px;padding:4px 8px 8px 8px;line-height:16px'>
      <b>${window.vm.$i18n.t('message.netdisk.diskfshare')}</b>
  </div>
  <div style='background:#fff;padding:4px'>
    ${t}
  </div>
  </div>
  `
}
const defaultText = `<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p style="color:#000;font-size:16px;">----</p>
<div id='cs2c_mail_sigature' style="color:#000;font-size:16px;font-family:Microsoft YaHei;"></div>

`
const initialValue = `<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="color: #000; font-size: 16px;">----</p>
<div id="cs2c_mail_sigature" style="color: #000; font-size: 16px; font-family: Microsoft YaHei;"></div>`
// 编辑器预设文本
const tansferMettingTemplate = (data, fontSize, lang) => {
  return `
  <p><br></p>
  <p><br></p>
    <div id='cs2c_re' style='margin-left:0.5em;padding-left:0.5em;border-left:1px solid #cacaca;-webkit-user-modify: read-only;-moz-user-modify: read-only;-ms-user-modify: read-only;-o-user-modify: read-only;'>
    <div style='background-color:#f8f8f8;padding:8px 10px;font-family: Microsoft YaHei; font-size: ${fontSize}px; color: #000;'>
    <B>${window.vm.$i18n.t('message.compose.adv1')}：</B><span id='cs2c_subject'>${data.subject}</span>
    <br><B>${window.vm.$i18n.t('message.calendar.cal10')}：</B><span id='cs2c_date'>${data.date}</span>
    <br><B>${window.vm.$i18n.t('message.calendar.cal9')}：</B><span id='cs2c_date'>${data.location}</span>
    <br><B>${window.vm.$i18n.t('message.calendar.cal31')}：</B><span id='cs2c_from'>${data.organizer}</span>
    <br><B>${window.vm.$i18n.t('message.calendar.info21')}</span>：</B><span id='cs2c_to' style='word-break:break-all'>${data.attendee}</span>
    </div>
    <br />
    <div id='cs2c_content'>
    ${data.body}
    </div>
    </div>
`
}
export { __defaultReFoward, defaultText, BigAttachmentsText, shareTemplate, tansferMettingTemplate, initialValue }
