<template>
  <!-- 打印页面 -->
  <div
    ref="view_warp"
    v-loading="loading"
    :element-loading-text="$t('message.view.loading')"
    class="view_warp"
  >

    <div
      v-if="!audit_expired"
      class="mailboxAll"
    >
      <div
        v-if="!loading"
        class="view_top"
      >
        <p class="subject">
          <span
            class="txt"
          >{{ bodyHtml.subject ? bodyHtml.subject : $t('message.view.no_subject') }}</span>

          <span class="clean" />
        </p>
        <p class="time">{{ bodyHtml.from_name_l ? showName(bodyHtml.from_name_l) :'' }} {{ dateFilter(bodyHtml.envelope_date) }}
          <svg-icon
            v-show="bodyHtml.is_strange ==='1' && bodyHtml.is_suspicious ==='0' "
            :icon-class="langicon()"
            class="middle kym"
          />
          <span
            v-show="bodyHtml.is_suspicious ==='1'"
            class="formtips"
          ><svg-icon
            icon-class="ky"
            class="middle kym"
          />({{ $t('message.view.replace_send',{mail:bodyHtml.x_sender}) }})</span>
        </p>
        <div class="peoList">
          <span
            v-if="isUnfold"
            class="zs"
            @click="Unfold(true)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.expand') }}</span>
          <span
            v-else
            class="zs"
            @click="Unfold(false)"
          ><svg-icon icon-class="view_down" /> {{ $t('message.view.close') }}</span>
          <p
            v-if="isUnfold"
            class="to_s one-line-ellipsis"
          >
            <em>{{ $t('message.view.send_to') }}: </em>
            <span
              v-for="(i,index) in bodyHtml.to_s"
              :key="index"
              class="cur"
            >
              {{ showName(i) }}
              <span v-show="to_s && to_s.length-1 !== index "> , </span>
            </span><span v-show="to_s.length > 1"> &nbsp;{{ $t('message.view.num_person',{num: to_s.length}) }}</span>
          </p>
          <div
            v-show="!isUnfold"
            class="to_s"
          >
            <em>{{ $t('message.view.to') }}: </em>

            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in to_s"
                :key="index"
              >
                {{ showName(i) }}
                <span v-show="to_s && to_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
          <div
            v-show="!isUnfold && cc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.cc') }}: </em>

            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in cc_s"
                :key="index"
              >

                {{ showName(i) }}
                <span v-show="cc_s.length-1 !== index "> , </span>
              </span>
            </p>
          </div>
          <!-- 需要判断是否在发件箱 -->

          <div
            v-show="!isUnfold && bcc_s.length"
            class="to_s"
          >
            <em>{{ $t('message.view.bcc') }}: </em>

            <p
              class="tosList"
            >
              <span
                v-for="(i,index) in bcc_s"
                :key="index"
              >

                {{ showName(i) }}
                <span v-show="bcc_s.length-1 !== index "> , </span>
              </span>
            </p>

          </div>
        </div>
        <p v-if="attachments && attachments.length">

          <em>{{ $t('message.view.att') }}: </em>
          <span>
            <svg-icon
              class="attaClass middle"
              :icon-class="getFileType(attachments[0].name)+'icon'"
              class-name="atticon"
            /> {{ $t('message.view.att') }}{{ $t('message.view.have_att',{num:attachments.length}) }}({{ attachments[0].name }}<em v-show="attachments.length >1">{{ $t('message.view.etc') }}</em>)</span>
          <a
            class="lookAtta text-color"
            href="#view_atta"
          > {{ $t('message.view.view_att') }}</a>
        </p>
        <p
          v-if="bodyHtml.expire_time > 0"
          class="expireTime"
        >
          <svg-icon
            class="middle icos-size-14"
            icon-class="prompt"
          />
          <span v-if="mail_expired === 'yes'">{{ $t('message.view.mail_expired') }}</span>
          <span v-else>{{ $t('message.view.mail_will_expired',{date:dateFilter(bodyHtml.expire_time)}) }}</span>
        </p>

      </div>

      <!-- 会议信息卡 -->
      <calendarCard
        v-if="isMettingMail"
        :card-info="MettingCardData"
      />
      <!-- 邮件内容 -->
      <div
        class="view_center"
      >
        <!-- <div
          ref="mailbody"
          class="view_html"
        >

          <iframe
            ref="iframe"
            src=""
            frameborder="0"
            width="100%"
            height="500"
            scrolling="auto"
          />
        </div> -->
        <div
          ref="mailbody"
          class="view_html"
          style="min-height: 500px"
          v-html="bodys"
        />
        <img
          v-for="(i,index) in attaImg"
          :key="index"
          class="imgpadding"
          :src="i"
        >
        <!-- 附件区 -->
        <div
          v-show="attachments && attachments.length"
          id="view_atta"
          class="view_atta"
        >
          <div class="atta_tit">{{ $t('message.view.att') }}(<span>{{ $t('message.view.have_att',{num:attachments.length}) }}, {{ $t('message.view.total') }}{{ bodyHtml.att_size | mailSizefilter }}</span>)

          </div>
          <div class="atta_html">
            <div
              v-for="(i,index) in attachments"
              :key="index"
              class="mv-att-item"
            >
              <div
                cs2c_id="icon"
                class="big-icon icon-att4-file attaLeft"
              >
                <svg-icon
                  class="attaClass"
                  :icon-class="getFileType(i.name)+'icon'"
                  class-name="atticon"
                />
              </div>
              <div class="attaRight">
                <div class="attaR_top">
                  <el-tooltip
                    class="item"
                    effect="light"
                    placement="top-start"
                  >
                    <div
                      slot="content"
                      style="max-width:500px"
                    >{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</div>
                    <span
                      class="atta_name"
                    >{{ i.name=='' ? $t('message.view.no_att_name') : i.name }}</span>
                  </el-tooltip>&nbsp;<span>(</span><span class="atta_size">{{ i.size | mailSizefilter }}</span><span>)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 过期邮件 -->
    <div
      v-else
      class="mailboxAll"
    >
      <svg-icon
        class="audit_expired"
        icon-class="audit_expired"
      />
      <p class="audit_expired_html">{{ audit_expired_html }}</p>
      <p
        class="audit_expired_txt"
        @click="go_mail_audit"
      ><span class=" cur text-color">《 {{ $t('message.view.back_audit') }}</span></p>
    </div>

    <!-- 打印邮件 -->
    <printMail :is-show="isPrintMail" />

  </div>
</template>
<script>
import view from './components/print.js'
import printMail from './components/printMail.vue'
// import mailContactCardPopovrer from '@/components/mailmodule/mail-contactCard-popover.vue'
import calendarCard from './components/calendarCard.vue'
export default {
  components: {
    printMail,
    // mailContactCardPopovrer,
    calendarCard,
  },
  mixins: [view],
  methods: {
    langicon() {
      const lang = localStorage.getItem('lang')
      if (lang === 'english') {
        return 'mo_en'
      } else {
        return 'ms'
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@import "./common.scss";
.view_warp{
  word-wrap: break-word;
}
</style>
