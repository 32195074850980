<template>
  <div class="event-select">
    <el-popover
      v-model="visible"
      placement="bottom"
      trigger="click"
      popper-class="tags-custom"
      content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
    >
      <ul class="el-scrollbar__view el-select-dropdown__list">
        <li
          v-for="item in tags"
          :key="item.tag_id"
          class="el-select-dropdown__item"
          style="max-width: 150px;"
          @click="selectEventTag(item)"
        >
          <span
            class="tagscolor"
            :style="{backgroundColor:item.tag_color,marginRight:'3px'}"
          />
          <span
            :title="item.tag_name"
            class="title"
          >{{ item.tag_name }}</span>
        </li>
      </ul>
      <div
        slot="reference"
        class="tags"
      >
        <span
          class="tagscolor"
          :style="{backgroundColor:value.tag_color,marginRight:'3px'}"
        />
        <span
          class="title"
        >{{ value.tag_name }}</span>
        <i
          :class="!visible?'el-icon-arrow-down':'el-icon-arrow-up'"
          style="float: right;"
        />
      </div>
    </el-popover>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          tag_color: '',
          tag_name: '',
        }
      },
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    selectEventTag(item) {
      this.visible = false
      this.$emit('input', item)
      this.$emit('callback')
    },
  },
}
</script>
<style lang="scss" scoped>
.tags{
  width: 100px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D2D2D2;
  .tagscolor{
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;
}
.title{
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 56px;
      white-space: nowrap;
    }
}

</style>
<style lang="scss">
.tags-custom{
  padding: 0!important;
  .tagscolor{
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
}
}
</style>
