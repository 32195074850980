//
// 这个模块只能由 main.js 使用
//
// 响应式全局变量
//
const myState = {}

myState.isLogonState = false

myState.menuShowFlag = true
myState.homeRouter = 'mail'
myState.isColumns = true // true分栏,false通栏

// 邮箱列表监控
myState.mbox_list_plus_watch = 0

// 标签列表监控
myState.tag_list_plus_watch = 0

// 需要刷新页面或属性, 同步系统设置，主题、字体、分栏等
myState.isAppSetReload = 0

// 拖拽移动邮件标记
myState.isMousedownBox = false

myState.userConfig = {
  page_inbox: 'no', // 登陆后是否打开收件箱 yes是（打开收件箱）；no否打开首页
  mail_view_type: 'tabulation', // 邮件视图 tabulation列表模式 subfield分栏模式
  list_view_type: '', // 展示模式 standard标准模式 conversation会话模式
  show_mail_diges: 'yes', // 是否显示邮件摘要 yes是 no否
  page_size: 20, // 每页显示条数
  show_att_image: 'yes', // 显示附件图片
  show_tag_type: 'long', // 收发件人显示格式 short短标签（姓名＋邮件地址） long长标签（姓名+所属组织）
  show_image: 'always', // 显示内嵌图片 always总是 never从不 contact来自通讯录的信件
  prompt: 'tab_never', // 恢复上次打开的标签页 tab_automatic自动恢复， tab_never从不恢复
  sent_autosave: 'yes', // 发信时自动保存到发件箱 yes是 no否
  draft_autosave: '60', // 自动保存到草稿箱时间（秒）
  prefix_re: '回复：', // 回复主题前缀
  prefix_forward: '转发：', // 转发主题前缀
  prefix_receipt: '', // 回执主题前缀
  reply_add_original_text: 'yes', // 回复时加入原文 yes是 no否
  auto_addcontact: 'no', // 自动添加收件人到通讯录 yes是 no否
  attachment_key: '', // 附件提醒关键字 多个之间用英文逗号分割
  req_receipt: 'no', // 默认请求回执 yes开启；no关闭
  word_size: '', // 字号 small小（默认）standard标准 big大 superBig超大
  write_font_size: '14', // 写信字体大小
  write_font: 'Microsoft YaHei', // 写信字体,默认微软雅黑
  write_font_color: '#C00000', // 色号
  reply_receipt: 'hand', // 阅读回执 ignore全部忽略，hand手动选择；auto自动回执
  archive_scope: '', // 归档范围
  archive_cycle: '0', // 归档周期（天）
  skin_colour: '', // 皮肤风格，GREY_RED简约风格(灰红色)
  font_size: 14, // 字体大小
  time_format: '24', // 时间格式 12小时制 24小时制
  show_language: 'simplified_chinese',
  timezone: '', // 时区
  new_message: 'yes', // 新消息到达通知 yes开 no关
  notice_scope: 'all', // 通知范围 inbox收件箱 all所有邮件
  notice_method: 'popup', // 通知方式 popup弹窗
  show_contact_type: '', // yes no
  lang: '', // 语言 zh_CN
  more_window: '', // 多窗口显示 no不多窗口 yes多窗口
  pubcontact: '', // 默认展开组功能
  open_trustadress: '', // 系统可信地址 yes是 no否
} // 用户配置

myState.user_permissions = { }

myState.headerSearchType = '' // header搜索类型(邮件、通讯录、网盘)
myState.headerSearchWords = '' // header搜索值

// 日历
myState.calendar = { }
myState.calendar.jumpDate = null

export default myState
