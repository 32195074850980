<template>
  <div class="contact-detail">
    <div class="contact-content">
      <div class="img">
        <span>{{ userDetail.name.substring(0,1) }}</span>
      </div>
      <el-tooltip
        effect="light"
        :content="userDetail.name"
        placement="bottom"
      >
        <div
          class="name"
        >
          {{ userDetail.name }}
        </div>
      </el-tooltip>

      <div
        class="handle"
      >

        <el-tooltip
          effect="light"
          :content="$t('message.contact.compose')"
          placement="bottom"
        >
          <span
            v-if="$store.state.app.user_permissions.mailbox === 1"
            class="btn"
            @click="toCompose"
          >
            <svg-icon
              icon-class="g_mail"
              class-name="contact"
            />
          </span>
        </el-tooltip>

        <el-tooltip
          effect="light"
          :content="$t('message.contact.edit')"
          placement="bottom"
        >
          <span
            v-if="displayEdit"
            class="btn"
            @click="handleClick('edit')"
          >
            <svg-icon
              icon-class="g_edit"
              class-name="contact"
            />
          </span>
        </el-tooltip>

        <el-tooltip
          effect="light"
          :content="$t('message.contact.del')"
          placement="bottom"
        >
          <span
            v-if="displayDel"
            class="btn"
            @click="handleClick('del')"
          >
            <svg-icon

              icon-class="g_del"
              class-name="contact"
            />
          </span>
        </el-tooltip>

        <el-tooltip
          effect="light"
          :content="$t('message.contact.mv')"
          placement="bottom"
        >
          <span
            v-if="displayMove"
            class="btn"
            @click="showMoveDrop=true"
          >
            <el-popover
              v-model="showMoveDrop"
              placement="bottom"
              width="100px"
              popper-class="moveitem"
            >
              <div
                class="el-select-dropdown__item"
                style="border-bottom:1px solid #E6E6E6"
                @click="addGroup('move')"
              >{{ $t('message.contact.newgroup') }}</div>
              <div
                v-for="item in $store.state.contact.persongroup"
                :key="item.group_id"
                :class="['el-select-dropdown__item',item.group_id===groupid?'is-disabled':'']"
                style="curso"
                @click="chekSelf('move',item)"
              >{{ item.group_name }}</div>
              <svg-icon
                v-if="contactType!=='public'"
                slot="reference"
                icon-class="g_movegroup"
                class-name="contact"
                style="width:1.5em;height:1.5em;"
              />
            </el-popover>

          </span>
        </el-tooltip>

        <el-tooltip
          effect="light"
          :content="$t('message.contact.copy')"
          placement="bottom"
        >
          <span
            v-if="displayCopy"
            class="btn"
            @click="showCopyDrop=true"
          >
            <el-popover
              v-model="showCopyDrop"
              placement="bottom"
              width="100px"
              popper-class="moveitem"
            >
              <div
                class="el-select-dropdown__item "
                style="border-bottom:1px solid #E6E6E6"
                @click="addGroup('copy')"
              >{{ $t('message.contact.newgroup') }}</div>
              <div
                v-for="item in $store.state.contact.persongroup"
                :key="item.group_id"
                :class="['el-select-dropdown__item',item.group_id===groupid?'is-disabled':'']"
                @click="chekSelf('copy',item)"
              >{{ item.group_name }}</div>
              <svg-icon
                v-if="contactType!=='public'"
                slot="reference"
                icon-class="g_copygroup"
                class-name="contact"
                style="width:1.5em;height:1.5em;"
              />
            </el-popover>
          </span>
        </el-tooltip>

        <el-tooltip
          effect="light"
          :content="$t('message.contact.export')"
          placement="bottom"
        >
          <span
            v-if="displayExp"
            class="btn"
            @click="handleClick('export')"
          >
            <svg-icon
              icon-class="g_export1"
              class-name="contact"
            />
          </span>
        </el-tooltip>
      </div>
      <div class="split" />
      <div
        class="detail"
        style="position: relative;"
      >
        <div class="group detail-info">
          <span class="title">{{ $t(`message.contact.${label}`) }}</span>
          <!-- <span class="title-value">{{ labelval }}</span> -->
          <el-tooltip
            class="item"
            effect="light"
            :content="labelval"
            placement="top-start"
            :open-delay="300"
            popper-class="tooltip-width"
          >
            <div
              class="title-value one-line-ellipsis"
            >
              {{ labelval }}
            </div>
          </el-tooltip>
        </div>

        <div
          class="box"
          style="position: relative;"
        >
          <el-tooltip
            effect="light"
            :content="$t('message.contact.copymail')"
            placement="bottom"
          >
            <span
              class="copy"
            >
              <svg-icon
                icon-class="g_copy"
                class-name=""
                @click="copymail(userDetail.mail)"
              />
            </span>
          </el-tooltip>
          <div
            class="mail detail-info"
          >
            <span class="title">{{ $t('message.contact.mailaddress') }}</span>

            <el-tooltip
              effect="light"
              :content="userDetail.mail"
              placement="bottom"
              :open-delay="300"
              popper-class="tooltip-width"
            >
              <span
                class="title-value"
              >{{ userDetail.mail }}
              </span>
            </el-tooltip>

          </div>
        </div>
        <div
          v-if="$store.state.app.user_permissions.mailbox === 1"
          class="Incoming detail-info"
        >
          <span class="title">{{ $t('message.contact.crossmail') }}</span>
          <span
            class="text-color"
            style="cursor: pointer"
            @click="backtomail"
          >{{ $t('message.contact.ckcrossmail') }}</span>
        </div>
        <div
          v-if="displayMember"
          class="member detail-info"
        >
          <span class="title">{{ $t('message.contact.member') }}</span>
          <span
            v-if="Number(userDetail.is_public_users)===1"
            class="text-color"
            style="cursor: pointer"
            @click="getList"
          >{{ $t('message.contact.view') }}</span>
          <span v-else>{{ $t('message.contact.unview') }}</span>
        </div>
        <div
          v-if="displayOut"
          class="member detail-info"
        >
          <span class="title">{{ $t('message.contact.phone') }}</span>
          <!-- <span
            style="cursor: pointer"
            class="phone"
          >{{ userDetail.tel.toString() }}</span> -->
          <el-tooltip
            class="item"
            effect="light"
            :content="userDetail.tel.toString()"
            placement="top-start"
            :open-delay="300"
            popper-class="tooltip-width"
          >
            <div
              class="phone one-line-ellipsis"
            >
              {{ userDetail.tel.toString() }}
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="displayOut"
          class="member detail-info"
        >
          <span class="title">{{ $t('message.contact.unit') }}</span>
          <!-- <span
            style="cursor: pointer"
          >{{ userDetail.dept }}</span> -->
          <el-tooltip
            class="item"
            effect="light"
            :content="userDetail.dept"
            placement="top-start"
            :open-delay="300"
            popper-class="tooltip-width"
          >
            <div
              class="title-value one-line-ellipsis"
              style="cursor: pointer;max-width: 280px;"
            >
              {{ userDetail.dept }}
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <CreateGroup
      ref="addgroup"
      @updateGroup="getGroupId"
    />
    <MaillistCard
      :id="list_id"
      ref="maillistcard"
    />
  </div>
</template>

<script>
import { copy } from '@/utils/utils.js'
import CreateGroup from '@/views/contacts/components/CreateGroup'
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
import MaillistCard from './maillist-card.vue'
export default {
  components: { CreateGroup, MaillistCard },
  mixins: [requestContact],
  props: {
    userDetail: {
      type: Object,
      default: () => {},
    },
    groupid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelval: {
      type: String,
      default: '',
    },
    contactType: {
      type: String,
      default: '',
    },
    groupTitle: {
      type: String,
      default: '',
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    displayEdit: {
      type: Boolean,
      default: false,
    },
    displayDel: {
      type: Boolean,
      default: false,
    },
    displayMove: {
      type: Boolean,
      default: false,
    },
    displayCopy: {
      type: Boolean,
      default: false,
    },
    displayExp: {
      type: Boolean,
      default: false,
    },
    displayMember: {
      type: Boolean,
      default: false,
    },
    displayOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCopyDrop: false,
      showMoveDrop: false,
      memberdialog: false,
      members: [],
      user_list: [],
      out_users: [],
      list_id: '',
      titleValue: '',
      title: '',
      id: '',
      addType: '',
    }
  },
  created() {
    // const { id } = this.$route.query
    // this.id = id
    // switch (this.$route.query.type) {
    //   case 'maillist':
    //     this.titleValue = this.userDetail.name
    //     this.title = 'maillistname'
    //     break
    //   case 'org':
    //     this.titleValue = this.userDetail.path
    //     this.title = 'depart'
    //     break
    //   case 'person':
    //     this.title = 'group'
    //     this.titleValue = this.userDetail.path
    //     break
    //   case 'out':
    //     this.title = 'group'
    //     this.titleValue = this.userDetail.group
    //     break
    // }
  },
  methods: {
    toCompose() {
      const data = this.userDetail
      this.$myApi.mainTab.showComposeMail({ type: 'contact', composeData: [data] })
    },
    addGroup(type) {
      this.addType = type
      this.showCopyDrop = false
      this.showMoveDrop = false
      this.$refs['addgroup'].showGroupdialog()
    },
    // 复制
    copymail(mail) {
      this.$message.success(this.$t('message.contact.info18'))
      copy.copyText(mail)
    },
    // 获取成员列表
    async getList() {
      this.list_id = this.userDetail.id || this.userDetail.list_id
      this.$refs.maillistcard.showDia()
    },
    handleClick(type, data) {
      this.onSelect(type, data)
    },
    getGroupId(id) {
      this.handleClick(this.addType, { group_id: id })
    },
    chekSelf(type, item) {
      console.log(item.group_id, this.groupid)
      if (item.group_id === this.groupid) return false
      this.handleClick(type, item)
      this.showCopyDrop = false
      this.showMoveDrop = false
    },
    backtomail() {
      // 往来邮件
      this.$myApi.mainTab.showMailContact({
        contact_mail: this.userDetail.mail,
        contact_name: this.userDetail.name,
        type: 'contact',
        mbox: 'contact',
        uid: 'contact',
      })
    },
  },

}
</script>
<style>
.moveitem{
  padding: 10px!important;
  max-height: 1000px;
  overflow-y: auto;
}
</style>
<style lang="scss" scoped>

.contact-detail{
  margin-top: 100px;
    display: flex;
    flex: 1;
    .contact-content{
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid #E3E3E3;
        background-color: #D0161B;
        // display: flex;
        text-align: center;
        line-height: 100px;
        span{
          font-size: 32px;
          margin: auto;
          color: #fff;
        }
      }
      .name{
        font-size: 24px;
        color: #1D1B1B;
        text-align: center;
        padding: 18px 0;
        max-width: 320px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .handle{
        cursor: pointer;
        .btn{
          display: inline-block;
          padding: 9px 20px;
          border-radius: 50px;
          background-color: #F6F6F6;
          border: 1px solid #E3E3E3;
          margin: 0 5px;
          &:hover{
            background-color: #F3F1ED;
          }
        }
      }
      .split{
        width: 498px;
        height: 1px;
        background: #DEDCDC;
        margin-top: 46px;
        margin-bottom: 60px;
      }
      .detail{
        width: 100%;
        .copy{
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 44px;
          }
        .detail-info{
          padding: 16px 70px;
          display: flex;
          justify-content: space-between;
          .text-color{
            color: #d0161b;
          }
          .title{
            margin-right: 10px;
            color: #8E8D8D;
          }
          .title-value{
            max-width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            color: #1D1B1B;
          }

        }
      }
    }
    .phone{
      overflow: hidden;
    max-width: 190px;
    cursor: pointer;
    text-overflow: ellipsis;
    }
  }
</style>
