import { transformClass } from '@/utils/utils.js'
import serialize from '@/utils/serialize'
let that
export default {
  beforeCreate() {
    that = this
  },
  props: {
    parentObj: {
      type: Object,
      default() {
        return {}
      },
    },
    menuId: {
      type: String,
      default: '',
    },
  },
  filters: {
    forwardReply(item) {
      // 转发relay,回复forward,转发并回复forward-reply,未读unread
      let icon = ''
      if (item.flags.charAt(4) === '1' && item.flags.charAt(6) === '1') icon = 'forward-reply'
      else if (item.flags.charAt(4) === '1') icon = 'relay'
      else if (item.flags.charAt(6) === '1') icon = 'forward'
      else if (item.flags.charAt(1) === '0') icon = 'unread'
      return icon
    },
    suspiciousFilters(item) {
      // 可疑suspicious，陌生mo，可疑优先
      let icon = ''
      if (item.is_suspicious === '1') icon = 'suspicious'
      else if (item.is_strange === '1') localStorage.getItem('lang') === 'english' ? icon = 'mo_en' : icon = 'mo'
      else icon = ''
      return icon
    },
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
    dateFilter(time) {
      return transformClass.timeFormat(time, 'yyyy/MM/dd')
    },
    /** 邮箱名称 */
    mboxNameFilter(val) {
      return transformClass.mbox_name(val, that.$myData.mbox_list)
    },
    subjectFilter(item) {
      const nosub = {
        'english': 'No subject',
        'traditional_chinese': '無主題',
        'simplified_chinese': '无主题',
      }
      const psw = {
        'english': '',
        'traditional_chinese': '',
        'simplified_chinese': '邮件加密****',
      }
      if (!item.subject) return nosub[localStorage.getItem('lang')]
      else if (item.mail_psw) return psw[localStorage.getItem('lang')]
      else return item.subject
    },
  },
  computed: {
    user_mail() {
      const data = [this.$store.state.app.user_mail_address, ...this.$store.state.app.user_permissions.all_alias]
      return data
    },
    /** 标记,收件箱、发件箱、已删除、星标邮件、归档邮件、我的文件夹、标签邮件 */
    marker_options() {
      const data = [{
        value: 'seen:0',
        label: 'unread',
      }, {
        value: 'seen:1',
        label: 'read',
      }, {
        value: 'flagged:1',
        label: 'stared',
      }, {
        value: 'flagged:0',
        label: 'unstar',
      }, {
        value: 'topping:1',
        label: 'top',
      }, {
        value: 'topping:0',
        label: 'untop',
      }, {
        value: 'tag',
        label: 'tag',
      },
      ]
      return data
    },
    /** 全选复选框下拉框 */
    allchecked_options() {
      let data = [
        {
          label: 'all',
          value: 'all',
        }, {
          label: 'currentPage',
          value: 'currentPage',
        }, {
          label: 'uncheck',
          value: 'uncheck',
        }, {
          label: 'invert',
          value: 'invert',
        }, {
          label: 'unread',
          value: 'unread',
        }, {
          label: 'emergent',
          value: 'emergent',
        }]
      const mbox = this.menuItem.mbox
      if (this.mail_type !== 'folder' || mbox === 'start' || mbox === 'tag' || mbox === 'unseen') {
        data.splice(0, 1) // 搜索结果、星标邮件、标签邮件没有“所有邮件”操作
      }
      if (this.menuItem.mbox === 'Drafts') {
        data = [
          {
            label: 'all',
            value: 'all',
          }, {
            label: 'currentPage',
            value: 'currentPage',
          }, {
            label: 'uncheck',
            value: 'uncheck',
          }, {
            label: 'invert',
            value: 'invert',
          }]
      }
      return data
    },
    /** 筛选--(草稿箱特殊处理) */
    cherry_pick_options() {
      const mbox = this.menuItem.mbox
      let data = []
      if (this.mail_type !== 'folder' || mbox === 'start' || mbox === 'tag' || mbox === 'unseen') {
        data = [{
          label: 'sort', // asc(正序), desc(倒序)
          value: '',
          children: [{
            label: 'bytimen2o',
            value: '{"orderby":"envelope_date_sort","sequence":"desc"}',
          }, {
            label: 'bytimeo2n',
            value: '{"orderby":"envelope_date_sort","sequence":"asc"}',
          }, {
            label: 'btfromdesc',
            value: '{"orderby":"from_sort","sequence":"desc"}',
          }, {
            label: 'btfromasc',
            value: '{"orderby":"from_sort","sequence":"asc"}',
          }, {
            label: 'bysubdesc',
            value: '{"orderby":"subject_sort","sequence":"desc"}',
          }, {
            label: 'bysubasc',
            value: '{"orderby":"subject_sort","sequence":"asc"}',
          }, {
            label: 'bysizedesc',
            value: '{"orderby":"mail_size_sort","sequence":"desc"}',
          }, {
            label: 'bysizedasc',
            value: '{"orderby":"mail_size_sort","sequence":"asc"}',
          }],
        }]
      } else if (this.menuItem.mbox === 'Drafts') {
        data = [{
          label: 'sort',
          value: '',
          children: [{
            label: 'bytimen2o',
            value: '{"orderby":"envelope_date_sort","sequence":"desc"}',
          }, {
            label: 'bytimeo2n',
            value: '{"orderby":"envelope_date_sort","sequence":"asc"}',
          }, {
            label: 'bysubdesc',
            value: '{"orderby":"subject_sort","sequence":"desc"}',
          }, {
            label: 'bysubasc',
            value: '{"orderby":"subject_sort","sequence":"asc"}',
          }, {
            label: 'bysizedesc',
            value: '{"orderby":"mail_size_sort","sequence":"desc"}',
          }, {
            label: 'bysizedasc',
            value: '{"orderby":"mail_size_sort","sequence":"asc"}',
          }],
        }, {
          label: 'urgent',
          value: 'priority',
        }, {
          label: 'hasatt',
          value: 'att',
        }, {
          label: 'hasnotes',
          value: 'comment',
        }, {
          label: 'all',
          value: 'all',
        }]
      } else {
        data = [
          {
            label: 'sort', // asc(正序), desc(倒序)
            value: '',
            children: [{
              label: 'bytimen2o',
              value: '{"orderby":"envelope_date_sort","sequence":"desc"}',
            }, {
              label: 'bytimeo2n',
              value: '{"orderby":"envelope_date_sort","sequence":"asc"}',
            }, {
              label: 'btfromdesc',
              value: '{"orderby":"from_sort","sequence":"desc"}',
            }, {
              label: 'btfromasc',
              value: '{"orderby":"from_sort","sequence":"asc"}',
            }, {
              label: 'bysubdesc',
              value: '{"orderby":"subject_sort","sequence":"desc"}',
            }, {
              label: 'bysubasc',
              value: '{"orderby":"subject_sort","sequence":"asc"}',
            }, {
              label: 'bysizedesc',
              value: '{"orderby":"mail_size_sort","sequence":"desc"}',
            }, {
              label: 'bysizedasc',
              value: '{"orderby":"mail_size_sort","sequence":"asc"}',
            }],
          }, {
            label: 'unread',
            value: 'unseen',
          }, {
            label: 'urgent',
            value: 'priority',
          }, {
            label: 'hasatt',
            value: 'att',
          }, {
            label: 'hasnotes',
            value: 'comment',
          },
          {
            label: 'all',
            value: 'all',
          },
        ]
      }
      return data
    },
    /** 删除 */
    delete_options() {
      let data = [{
        value: 'move',
        label: 'delete',
      }, {
        value: 'delete',
        label: 'deleteper',
      }]
      if (this.menuItem.mbox === 'rubbishs' || this.menuItem.mbox === 'trash') {
        data = [{
          value: 'delete',
          label: 'deleteper',
        }]
      }
      return data
    },
    /** 列表是否显示邮箱名称 */
    isMboxShow() {
      const mbox = this.menuItem.mbox
      // 简单搜素、高级搜索、往来邮件、新邮件、星标邮件、未读邮件、标签邮件，邮件列表显示邮箱名称
      const type = ['search', 'highsearch', 'contact', 'newMail']
      const mboxArr = ['start', 'tag', 'unseen']
      if (type.indexOf(this.mail_type) > -1 || mboxArr.indexOf(mbox) > -1) return true
      else return false
    },
    /** 是否显示二次搜索 */
    isTwoSearch() {
      // 简单搜素、高级搜索
      if (this.mail_type === 'search' || this.mail_type === 'highsearch') return true
      else return false
    },
    /** 是否继续自动加载 */
    noMore() {
      const len = this.mail_list.length
      return len < this.mail_total
    },
    /** 作为附件转发
     * 多封邮件以多个eml附件的形式进行转发。超过20封，提示“一次最多允许转发20封邮件”
     */
    isMailRelay() {
      // 草稿箱无'作为附件转发'功能
      if (this.menuItem.mbox === 'Drafts') return false
      else return true
    },
    /** 移动 */
    isMove() {
      // 草稿箱无'移动'功能
      if (this.menuItem.mbox === 'Drafts') return false
      else return true
    },
    /** 标记 */
    isBadge() {
      // 草稿箱无'标记'功能
      if (this.menuItem.mbox === 'Drafts') return false
      else return true
    },
    /** 更多 */
    isMore() {
      // 草稿箱无'更多'功能
      if (this.menuItem.mbox === 'Drafts') return false
      else return true
    },
    /** 是否有上传邮件功能 */
    isUploadMail() {
      const mbox = this.menuItem.mbox
      if (this.mail_type === 'folder' && (mbox !== 'start' && mbox !== 'tag' && mbox !== 'unseen')) return true
      else return false
    },
    /** 监听是否重新加载列表数据 */
    isMailListReloadWatch() {
      return this.$store.state.app.userGetConfig.page_size
    },

    /** 监听邮箱是否更改 */
    mbox_list_watch() {
      return this.$myState.mbox_list_plus_watch
    },
  },
  data() {
    return {
      menuItem: {},
      mail_type: '',
      bPageShow: true,
      is_reqMail: false, // 是否接口请求
      page: 1, // 分页页码
      mail_total: 0, // 邮箱总条数
      limit: Number(this.$store.state.app.userGetConfig.page_size), // 条数
      from_limit: 0, // 起始位置
      isAttaDown: false,
      flags: '', // 标记
      delOpt: '', // 删除
      allCheak: false, // 全选状态
      allSelect: 'currentPage', // 全选类型
      mail_check_num: 0, // 邮箱列表数据--选中数量
      to_move_mailbox: '', // 移动到邮箱
      uploadFiles: [],
      lableListShow: false,
      mail_list: [], // 邮箱列表数据
      type: 'folder', // 列表类型, folder(文件夹内邮件列表), search(搜索), highsearch(高级搜索), contac(往来邮件), conversation(会话邮件)
      orderby: 'uid', // 排序种类，默认是uid，可选 uid(收信顺序), from_sort(发件人),subject_sort(主题),mail_size_sort(邮件大小),envelope_date_sort(信封时间)
      sequence: 'desc', // 排序方式, 可选 asc(正序), desc(倒序)
      uidvalidity: 0, // number, 邮件夹Id, (搜索时指定编号，则只返回此邮箱的数据，不指定则返回全部邮箱数据)
      scopes: '', // 高级搜索范围, subject(主题),body(正文),from(发件人),to(收件人),attachment(附件名), 形如 [{"subject":"fff"},{"body":"aaa"},{"time":"2023-03-01 , 2023-04-26"},{"from":"ff"},{"to":""},{"attachment":""}],other(其他)
      other: '', // scopes为other的情况下的搜索类型，可选，unseen（未读），flag（星标），priority（紧急），tag（标签），mail_seclevel（密级）,att(含附件)
      words: '', // 搜索关键字
      search_id: '', // 搜索id，用于进行二次搜索
      contact_mail: '',
      contact_name: '',
      cherry_pick: '', // 筛选
      filter_query: {
        from: '', // 二次搜索的条件,发件人
        to: '', // 二次搜索的条件,收件人
        mbox: '', // 二次搜索的条件,邮件夹Id
        date: '', // 二次搜索的条件, 时间 可选 1d, 2d, 3d, 4d, 解释见 返回的filter_result
        attachment: '', // 二次搜索的条件, 有没有附件 可选 0, 1
      },
      filter_result: {}, // 按发件人
      mboxSelect: [], // 按邮箱查找
      dateObj: {
        '1d': this.$t('message.colmail.one_week'),
        '2d': this.$t('message.colmail.o_mon'),
        '3d': this.$t('message.colmail.t_mon'),
        '4d': this.$t('message.colmail.since'),
      },
      isAchiveBottom: false, // 滚动条是否到底部标志
      isScrollTopShow: false,
      readMailReqData: {}, // 读信请求数据
      isReadMailShow: false, // 是否显示读信
      value: '',
      move_mailbox: [], // 可移动文件夹
    }
  },
  watch: {
    menuId: {
      handler(value) {
        this.menuItem = this.parentObj.data.menuItem
        this.mail_type = this.menuItem.type
        this.page = 1
        this.reset_mbox_list()
        this.clearMailList()
        this.resParamsSet()
        this.intRequestSet()
      },
      immediate: true,
    },
    mbox_list_watch(value) {
      this.reset_mbox_list()
    },
    isMailListReloadWatch() {
      this.is_reqMail = false
      this.page = 1
      this.limit = Number(this.$store.state.app.userGetConfig.page_size)
      this.clearMailList()
      this.resParamsSet()
      this.intRequestSet()
    },
  },
  created() {
  },
  methods: {
    dateNYRFilter(time) {
      return transformClass.timeNYRFormat(time)
    },
    // 邮箱列表发生变化后更新本地数据
    reset_mbox_list() {
      const move_mailbox = this.$myData.mbox_list
      this.move_mailbox = []
      const list = []
      move_mailbox.forEach(item => {
        if (item.mbox !== 'Drafts' && item.mbox !== this.menuItem.mbox) list.push(item)
      })
      this.move_mailbox = list
    },
    /**
     * resParamsSet 不同页面请求参数赋值
     * @param {string} mail_type 列表类型，值：folder(菜单显示文件夹), search(搜索), highsearch(高级搜索), contac(往来邮件),newMail(新邮件),conversation(会话邮件)
     * Array
    */
    resParamsSet() {
      const mbox = this.menuItem.mbox
      if (this.mail_type === 'folder') {
        if (mbox === 'start') {
          // 星标邮件
          this.scopes = 'other'
          this.type = 'search'
          this.other = 'flag'
        } else if (mbox === 'unseen') {
          // 未读邮件
          this.scopes = 'other'
          this.type = 'search'
          this.other = 'unseen'
        } else {
          this.scopes = ''
          this.type = 'folder'
          this.other = ''
        }
      } else if (this.mail_type === 'tag') {
        // 标签邮件
        this.scopes = 'other'
        this.type = 'search'
        this.other = 'tag'
      } else {
        // 取搜索条件
        // 简单搜索、高级搜索，有二级搜索条件
        if (this.mail_type === 'highsearch' || this.mail_type === 'search') {
          this.type = this.menuItem.type
          this.scopes = this.menuItem.scopes
          this.words = this.menuItem.words
          // const twoSearchSelectData = this.$store.state.tagsView.twoSearchSelectData
          // const filter_query = this.$store.state.tagsView.filter_query
          // if (JSON.stringify(twoSearchSelectData) !== '{}') {
          //   this.mboxSelect = twoSearchSelectData.mboxSelect
          //   this.filter_result = twoSearchSelectData.filter_result
          //   this.search_id = twoSearchSelectData.search_id
          // }
          // if (JSON.stringify(filter_query) === '{}') {
          //   for (const key in this.filter_query) {
          //     this.filter_query[key] = ''
          //   }
          // } else {
          //   for (const key in this.filter_query) {
          //     this.filter_query[key] = filter_query[key]
          //   }
          // }
        } else if (this.mail_type === 'contact') {
          this.type = this.menuItem.type
          this.contact_mail = this.menuItem.contact_mail
          this.contact_name = this.menuItem.contact_name
        }
        // }
      }
      // }
    },
    /**
     * intRequestSet 不同页面请求方法
     * @param {string} is_twoSeach 是否是二级搜索
     * @param {string} mail_type 列表类型
     * folder(菜单显示文件夹)：单一文件夹调用getBaseInfoList，混合文件夹调用searchBaseInfoList
     * search(搜索), highsearch(高级搜索), 调用searchBaseInfoList
     * contac(往来邮件)contactBaseInfoList,
     * newMail(新邮件)newMailGetList
    */
    intRequestSet(is_twoSeach) {
      this.is_reqMail = false
      this.bPageShow = false
      const mbox = this.menuItem.mbox
      if (this.mail_type === 'folder' || this.mail_type === 'tag') {
        // 星标邮件、标签邮件、未读邮件
        if (mbox === 'start' || mbox === 'tag' || mbox === 'unseen') {
          this.searchBaseInfoList()
        } else {
          this.folderMailListGet()
        }
      } else if (this.mail_type === 'highsearch' || this.mail_type === 'search') {
        this.searchBaseInfoList(is_twoSeach)
      } else if (this.mail_type === 'contact') {
        this.contactBaseInfoList()
      } else if (this.mail_type === 'newMail') {
        this.newMailGetList()
      }
    },
    /**  单邮箱列表uid数据
     *  @param {Number} page 页码
     *  @param {Number} limit 条数
     *  接口请求格式为从第几条开始，请求多少条
     *  @param {Number} removeJunk 是否过滤回收站(已删除)、垃圾箱, 1过滤,0不过滤
     */
    folderMailListGet() {
      const limit = Number(this.$store.state.app.userGetConfig.page_size)
      const num = (this.page - 1) * limit
      let params = {}
      params = {
        'module': 'mail',
        'require': {
          'action': 'getBaseInfoList',
          'from': num, // 起始位置
          'limit': limit, // 读取个数
          'type': this.type,
          'orderby': this.orderby, // 排序方式，默认是uid，可选subject_sort,from_sort,date_sort
          'sequence': this.sequence, // 排序方式desc或asc
          'scopes': this.scopes, // 高级搜索范围, subject(主题),body(正文),from(发件人),to(收件人),attachment(附件名), [{"subject":"fff"},{"body":"aaa"},{"time":"2023-03-01 , 2023-04-26"},{"from":"ff"},{"to":""},{"attachment":""}]
          'other': this.other,
          'uidvalidity': this.menuItem.uidvalidity, // 邮箱编号(搜索时指定编号，则只返回此邮箱的数据，不指定则返回全部邮箱数据)
          'uv': this.menuItem.uidvalidity, // 邮箱编号，缩写
          'removeJunk': 0, // 不过滤回收站及垃圾箱
        }}
      this.$axios.post(this.$apiData.mail, { req: serialize(params) })
        .then((res) => {
          if (res.result.mailList.length < 1) {
            if (this.page > 1) {
              this.page = this.page - 1
              return this.folderMailListGet()
            } else {
              this.is_reqMail = true
              return false
            }
          }
          this.bPageShow = true
          this.from_limit = num // 起始位置,列表数据赋值时用以区分赋值方式
          this.words = '' // 单邮箱无搜索关键字
          // this.$store.commit('tagsView/reqMailDataSet', { // 存储请求参数
          //   'scopes': this.scopes,
          //   'type': this.type,
          //   'other': this.other,
          //   'words': this.words,
          //   'orderby': this.orderby,
          //   'sequence': this.sequence,
          // })
          this.mail_total = Number(res.result.count)
          // this.$store.commit('tagsView/MAIL_TOTAL_SETTING', Number(res.result.count))
          const list = res.result.mailList
          const uidList = []
          list.forEach(item => {
            uidList.push(item.uid)
          })
          this.getInfoList(uidList.toString())
          // this.$store.commit('tagsView/MENU_UNSEEN_SET', { ...this.$store.state.tagsView.menu_unseen, audit: res.result.to_audit_total }) // 各邮箱未读数量
        })
    },
    /** getInfoList 单邮箱列表详细数据
     * @param {Boolean} allCheak 全选复选框
     * @param {Boolean} isColumns true分栏 false通栏
     * @param {string} allSelect 全选复选-下拉框值：分栏--all、currentPage,新加载数据自动勾选复选框；通栏--all,新加载数据自动勾选复选框
    */
    getInfoList(uidList) {
      const params = {
        'module': 'mail',
        'require': {
          'action': 'getInfoList',
          'mboxList': [{ // 按邮箱提交，格式数组，每个数组为一个邮箱内的指定邮件
            'mbox': this.menuItem.mbox, // 邮箱名（文件系统名称）
            'uidList': uidList, // 需要操作的邮件的uid，逗号分隔
            'uidvalidity': this.menuItem.uidvalidity,
            'uv': this.menuItem.uidvalidity,
          }],
        }}
      this.$axios.post(this.$apiData.mail, { req: serialize(params) })
        .then((res) => {
          const list = res.result.mboxList[0].mailList
          const isColumns = this.$myState.isColumns
          let flag = false
          if (this.allCheak) {
            if (isColumns && (this.allSelect === 'all' || this.allSelect === 'currentPage')) flag = true
            if (!isColumns && this.allSelect === 'all') flag = true
          }
          if (flag) {
            list.forEach(item => {
              item.checked = true
            })
            if (this.allSelect !== 'all') {
              this.mail_check_num = this.mail_check_num + list.length
            }
          } else {
            this.allCheak = false
            list.forEach(item => {
              item.checked = false
            })
          }
          // 缓存收件人 抄送人 密送人地址
          list.map(item => {
            if (item.bcc_s && item.bcc_s.length > 0) {
              item.bcc_s.map(i => {
                this.userStreamCache.setUserCache({ name: i[1], mail: i[0], path: i[2], isValid: true })
              })
            }
            if (item.cc_s && item.cc_s.length > 0) {
              item.cc_s.map(i => {
                this.userStreamCache.setUserCache({ name: i[1], mail: i[0], path: i[2], isValid: true })
              })
            }
            if (item.from_name_l) {
              this.userStreamCache.setUserCache({ name: item.from_name_l[1], mail: item.from_name_l[0], path: item.from_name_l[2], isValid: true })
            }
            if (item.to_s && item.to_s.length > 0) {
              item.to_s.map(i => {
                this.userStreamCache.setUserCache({ name: i[1], mail: i[0], path: i[2], isValid: true })
              })
            }
          })

          // this.mail_list = list
          if (isColumns) {
            if (this.from_limit === 0) this.mail_list = list
            else if (this.from_limit >= this.mail_list.length) this.mail_list = this.mail_list.concat(list)
          } else {
            this.mail_list = list
          }
          this.is_reqMail = true
        })
    },
    /** searchBaseInfoList 搜索类--所有邮箱列表uid数据
     * @param {string} is_twoSeach 是否是二级搜索
     *  @param {Object} filter_query 二次搜索下拉框选中值
    */
    searchBaseInfoList(is_twoSeach) {
      const limit = Number(this.$store.state.app.userGetConfig.page_size)
      // const page = Number(this.page)
      const num = (this.page - 1) * limit
      let params = {}
      let removeJunk = 0
      if (this.menuItem.mbox === 'unseen') removeJunk = 1 // 未读邮件，过滤掉已删除、垃圾、
      params = {
        'module': 'mail',
        'require': {
          'action': 'getBaseInfoList',
          'from': num, // 起始位置
          'limit': limit, // 读取个数
          'type': this.type,
          'orderby': this.orderby, // 排序方式，默认是uid，可选subject_sort,from_sort,date_sort
          'sequence': this.sequence, // 排序方式desc或asc
          'scopes': this.scopes, // 高级搜索范围, subject(主题),body(正文),from(发件人),to(收件人),attachment(附件名)
          'other': this.other,
          'words': this.words, // 搜索关键字
          'removeJunk': removeJunk,
          // 'search_id': this.search_id, // 搜索id，用于进行二次搜索
        }}
      if (this.mail_type === 'highsearch' || this.mail_type === 'search') {
        let is_flag = false // 二次搜索后移动或者删除,重新发起请求，二次搜索下拉框有值的需要按twoSeach来处理
        for (const key in this.filter_query) {
          if (this.filter_query[key]) is_flag = true
        }
        // 二次搜索请求赋值
        if (is_twoSeach === 'twoSeach' || is_flag) {
          params.require.search_id = this.search_id // 搜索id，用于进行二次搜索
          params.require.filter_query = {}
          for (const key in this.filter_query) {
            if (this.filter_query[key]) params.require.filter_query[key] = this.filter_query[key]
          }
        }
        // this.$store.commit('tagsView/filterQuerySet', this.filter_query)
      }
      // 标签邮件
      if (this.menuItem.mbox === 'tag') {
        params.require.tag = this.menuItem.tag_id
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) })
        .then((res) => {
          if (JSON.stringify(res.result.mailList !== '[]')) this.uidListGetPublic(res.result, is_twoSeach)
        })
    },
    /** 统一处理混邮箱mboxList请求参数 */
    uidListGetPublic(data, is_twoSeach) {
      const limit = Number(this.$store.state.app.userGetConfig.page_size)
      const num = (this.page - 1) * limit
      this.bPageShow = true
      this.from_limit = num // 起始位置,列表数据赋值时用以区分赋值方式
      // this.$store.commit('tagsView/reqMailDataSet', { // 存储请求参数
      //   'scopes': this.scopes,
      //   'type': this.type,
      //   'other': this.other,
      //   'words': this.words,
      //   'orderby': this.orderby,
      //   'sequence': this.sequence,
      // })
      if (is_twoSeach !== 'twoSeach') {
        this.search_id = data.search_id
      }
      this.mboxSelect = data.filter_result.mbox // 按邮箱查找
      const filter_result = data.filter_result // 按发件人等
      filter_result.not_attachment = Number(data.count) - Number(filter_result.attachment) // 按附件，无附件没有返回值，需计算
      this.filter_result = filter_result
      this.mail_total = Number(data.count)
      if (data.mailList.length < 1) {
        if (this.page > 1) {
          this.page = this.page - 1
          return this.searchBaseInfoList()
        } else {
          this.is_reqMail = true
          return false
        }
      }
      const uidList = data.mailList // 列表uid顺序
      const list = data.mailList // 列表uid顺序
      const mboxList = []
      for (const key in this.mboxSelect) { // mboxSelect 邮件所在的各邮箱,接口只返回了{邮箱的uidvalidity值:邮件数量}
        mboxList.push({
          'mbox': transformClass.mbox(key, this.$myData.mbox_list), // 搜索类混合邮箱无返回mbox,需转换赋值
          'uidvalidity': key,
          'uidList': '',
        })
      }
      // 格式示例 "mboxList":[{"mbox":"INBOX","uidvalidity":"1691146649","uidList":"133,631,"},{"mbox":"Drafts","uidvalidity":"1691146651","uidList":"35,15,"}]}}
      mboxList.forEach(mbox => {
        list.forEach(mail => {
          if (mail.uidvalidity === mbox.uidvalidity) {
            mbox.uidList = mbox.uidList + mail.uid + ','
          }
        })
      })
      this.searchInfoList(mboxList, uidList)
    },
    searchInfoList(mboxList, uidList) {
      const params = {
        'module': 'mail',
        'require': {
          'action': 'getInfoList',
          'mboxList': mboxList,
        }}
      this.$axios.post(this.$apiData.mail, { req: serialize(params) })
        .then((res) => {
          const resMboxList = res.result.mboxList
          let arr = []
          resMboxList.forEach(item => {
            item.mailList.forEach(i => {
              arr = arr.concat(i)
            })
          })
          const list = []
          for (let i = 0; i < uidList.length; i++) {
            for (let j = 0; j < arr.length; j++) {
              if (uidList[i].uidvalidity === arr[j].uidvalidity && uidList[i].uid === arr[j].uid) {
                list.push(arr[j])
                break
              }
            }
          }
          const isColumns = this.$myState.isColumns
          let flag = false
          if (this.allCheak) {
            if (isColumns && (this.allSelect === 'all' || this.allSelect === 'currentPage')) flag = true
            if (!isColumns && this.allSelect === 'all') flag = true
          }
          if (flag) {
            list.forEach(item => {
              item.checked = true
            })
            this.mail_check_num = this.mail_check_num + list.length
          } else {
            this.allCheak = false
            list.forEach(item => {
              item.checked = false
            })
          }
          if (isColumns) {
            if (this.from_limit === 0) this.mail_list = list
            else if (this.from_limit >= this.mail_list.length) this.mail_list = this.mail_list.concat(list)
          } else {
            this.mail_list = list
          }
          this.is_reqMail = true
        })
    },
    /** 往来邮件 */
    contactBaseInfoList() {
      const limit = Number(this.$store.state.app.userGetConfig.page_size)
      let params = {}
      params = {
        'module': 'mail',
        'require': {
          'action': 'getBaseInfoList',
          'from': (this.page - 1) * limit, // 起始位置
          'limit': limit, // 读取个数
          'contact_mail': this.contact_mail,
          'contact_name': this.contact_name,
          'type': this.type,
          'orderby': this.orderby,
          'sequence': this.sequence,
        }}
      this.$axios.post(this.$apiData.mail, { req: serialize(params) })
        .then((res) => {
          this.uidListGetPublic(res.result)
        })
    },
    /** 获取新邮件 */
    newMailGetList() {
      const list = this.$myData.newMailList
      this.$myData.newMailList = []
      this.mail_total = list.length
      const mboxList = {}
      list.forEach(item => {
        if (item.uidvalidity in mboxList) {
          const uidList = mboxList[item.uidvalidity].uidList
          mboxList[item.uidvalidity].uidList = uidList + item.uid + ','
        } else {
          mboxList[item.uidvalidity] = {
            mbox: transformClass.mbox(item.uidvalidity, this.$myData.mbox_list),
            uidvalidity: item.uidvalidity,
            uidList: item.uid + ',',
          }
        }
      })
      const reqList = []
      for (const key in mboxList) {
        reqList.push(mboxList[key])
      }
      this.bPageShow = true
      this.searchInfoList(reqList, list)
    },
    /** 二次搜索 */
    searchSelect() {
      this.page = 1
      // this.clearMailList()
      this.mail_list = []
      this.mail_check_num = 0
      this.mail_total = 0
      this.intRequestSet('twoSeach')
    },
    /** 清空搜索某个下拉框值 */
    searchSelectClear(key) {
      this.filter_query[key] = ''
      this.page = 1
      // this.clearMailList()
      this.mail_list = []
      this.mail_check_num = 0
      this.mail_total = 0
      let isValue = ''
      for (const key in this.filter_query) {
        if (this.filter_query[key]) { // 下拉框还有值的就是二次搜索
          isValue = 'twoSeach'
        }
      }
      this.page = 1
      this.intRequestSet(isValue)
    },
    // 通栏翻页
    handleCurrentChange(val) {
      this.$refs.banner_mail.scrollTop = 0
      this.page = Number(val)
      let isValue = ''
      for (const key in this.filter_query) {
        if (this.filter_query[key]) {
          isValue = 'twoSeach'
        }
      }
      this.intRequestSet(isValue)
    },
    /** 发件箱、草稿箱显示收件人，其它文件夹显示发件人
     * from_name 发件人
     * to_s收件人
     */
    isToFilter(val) {
      const mboxValue = transformClass.mbox(val, this.$myData.mbox_list)
      if (mboxValue === 'Sent' || mboxValue === 'Drafts') return true
      else return false
    },
    /** 筛选 */
    cherryPickChange(value) {
      const mbox = this.menuItem.mbox
      // 选择查询全部，强制默认排序重新
      if (value[0] === 'all') value[1] = '{"orderby":"envelope_date_sort","sequence":"desc"}'
      if (this.mail_type === 'folder' || this.mail_type === 'tag') {
        if (value.length > 1) { // 排序
          if (mbox === 'start' || mbox === 'tag' || mbox === 'unseen') {
            // 星标邮件、标签邮件、未读邮件
            this.scopes = 'other'
            this.type = 'search'
            if (mbox === 'start') this.other = 'flag'
            else this.other = mbox
            const { orderby, sequence } = JSON.parse(value[1])
            this.orderby = orderby
            this.sequence = sequence
          } else {
            this.scopes = ''
            this.other = ''
            this.type = 'folder'
            const { orderby, sequence } = JSON.parse(value[1])
            this.orderby = orderby
            this.sequence = sequence
          }
        } else {
          this.scopes = 'other'
          this.type = 'search'
          this.other = value[0]
        }
      } else {
        // 搜索类的筛选只有排序
        if (value.length > 1) { // 排序
          const { orderby, sequence } = JSON.parse(value[1])
          this.orderby = orderby
          this.sequence = sequence
        }
      }
      // 从第一页开始重新请求
      this.page = 1
      this.mail_list = []
      this.mail_check_num = 0
      this.mail_total = 0
      // this.clearMailList()
      // this.$store.commit('tagsView/CLEAR_MAIL_LIST') // 清空邮件列表数据
      this.intRequestSet()
    },
    /** 滚动条事件  */
    scrollEvent(e) {
      // 触发滚动条显示回到顶部
      this.isScrollTopShow = true
      // // 存储滚动条位置
      // this.$store.commit('tagsView/SCROLL_TOP_ID_SET', e.target.scrollTop)
      const isColumns = this.$myState.isColumns
      // 分栏自动加载
      if (isColumns) {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 60 && !this.isAchiveBottom && this.noMore) {
        // 触底时进行操作
          this.isAchiveBottom = true
          // 延时触发数据加载
          setTimeout(() => {
            this.page = this.page + 1
            this.intRequestSet()
            this.isAchiveBottom = false
          }, 500)
        }
      }
    },
    // 清空邮件列表数据
    clearMailList() {
      this.isScrollTopShow = false
      this.mail_list = []
      this.mail_check_num = 0
      this.mail_total = 0
      this.allCheak = false
      this.allSelect = 'currentPage'
      this.is_reqMail = false
      this.cherry_pick = ''
      // const isColumns = this.$myState.isColumns
      // const parent = isColumns ? document.getElementById('three_mail') : document.getElementById('banner_mail')
      // parent.scrollTop = 0
      // this.list_scrollTop = 0
      this.scopes = ''
      this.type = 'folder'
      this.other = ''
      this.words = ''
      this.orderby = 'uid'
      this.sequence = 'desc'
      this.mboxSelect = []
      this.filter_result = {}
      this.search_id = ''
      for (const key in this.filter_query) {
        this.filter_query[key] = ''
      }
      this.isReadMailShow = false
    },
    // 重新发起请求
    refreshRequest() {
      this.clearMailList()
      this.resParamsSet()
      this.intRequestSet()
    },
  },
}
