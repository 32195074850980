<template>
  <el-dialog
    :visible.sync="showContact"
    :append-to-body="true"
    :modal-append-to-body="true"
    width="829px"
    custom-class="previewConatctDialog"
    top="5vh"
    @closed="handleClose"
    @open="handleOpen"
    @opened="handleOpened"
  >
    <div class="container">
      <div class="top">
        <div class="title">
          <div style="white-space: nowrap;">{{ title }}</div>
          <el-popover
            v-model="visible"
            placement="bottom-start"
            popper-class="popperCustomSearch"
            visible-arrow
          >
            <div style="">
              <div
                v-for="(item,index) in searchList"
                ref="optionsList"
                :key="index"
                :class="['el-select-dropdown__item']"
                class=""
                @click="SearchListadd(item)"
              >
                <div
                  v-title="`${ item.name}(${ item.mail})`"
                  style="color: rgb(51, 51, 51);font-weight: bold;margin-bottom:2px;overflow: hidden;text-overflow: ellipsis;"
                >{{ item.name }}({{ item.mail }})</div>
                <div style="color:#b4b4b4;">{{ item.path }}</div>
              </div>
            </div>
            <el-input
              slot="reference"
              v-model="search"
              v-debounce="searchUsers"
              :placeholder="$t('message.contact.placeholder')"
              size="mini"
              class="input"
              clearable
              @clear="clearSearch"
              @input="clearSearch(1)"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
              />
            </el-input>
          </el-popover>

        </div>
        <div class="search">
          <i
            class="el-icon-close closeicon"
            @click="closeContactPoper"
          />
        </div>
      </div>
      <div class="main">
        <div
          v-if="isempower"
          class="left"
        >
          <div
            class="contacts-tree power"
            @click="powerState=!powerState"
          >
            <div
              class="contacts-tree-title power-title"
            >
              <span>
                <svg-icon
                  icon-class="g_group"
                  class="g-common-icon"
                />
                <span style="margin-left: 6px;">{{ $t('message.contact.enpcontact') }}</span>
              </span>
            </div>
            <svg-icon
              class="customicon"
              :icon-class="powerState==1?'up-arrow':'down-arrow'"
              style="cursor: pointer"
            />
          </div>
          <el-collapse-transition>
            <!-- <div
              v-show="powerState"
              style="width: 100%;overflow-x: auto;"
            > -->
            <div
              v-show="powerState"
            >
              <el-tree
                ref="powerTree"
                :props="defaultProps"
                :load="loadNode"
                lazy
                show-checkbox
                node-key="id"
                @check-change="handleCheckChange"
                @node-expand="setWidth"
              >
                <span
                  slot-scope="{ node }"
                  class="tree-node"
                >
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
          </el-collapse-transition>

        </div>
        <div
          v-else
          class="left"
        >

          <!-- Your content here -->
          <div
            class="usual"
            :style="{width:scrollerWeight}"
            @click="usual=!usual"
          >
            <span>
              <svg-icon
                icon-class="g_common"
                class="g-common-icon"
              />
              <span
                style="margin-left: 6px;"
                class="cur"
              >{{ $t('message.contact.commoncontact') }}</span>
            </span>
            <svg-icon
              class="customicon"
              :icon-class="usual==1?'up-arrow':'down-arrow'"
              style="cursor: pointer"
            />
          </div>
          <el-collapse-transition>
            <!-- <div
              v-show="usual"
              style="width: 100%;overflow-x: auto;"
            > -->
            <div
              v-show="usual"
            >
              <el-tree
                ref="commonTree"
                :data="commonData"
                :props="defaultProps"
                show-checkbox
                node-key="id"
                :style="{width:commonData.length===0?'100%':''}"
                @check-change="handleCheckChange"
                @node-expand="setWidth"
              >
                <span
                  slot-scope="{ node }"
                  class="tree-node"
                >
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
            </div>

          </el-collapse-transition>

          <div
            v-show="Number($store.state.app.yes_no_public)===1"
            class="contacts-tree"
            style="    border-top: 1px solid rgb(222, 220, 220);"
            @click="enterState=!enterState"
          >
            <div
              class="contacts-tree-title"
              :style="{width:scrollerWeight}"
            >
              <span>
                <svg-icon
                  icon-class="g_group"
                  class="g-common-icon"
                />
                <span style="margin-left: 6px;">{{ $t('message.contact.enpcontact') }}</span>
              </span>

            </div>
            <svg-icon
              class="customicon"
              :icon-class="enterState==1?'up-arrow':'down-arrow'"
              style="cursor: pointer"
            />
          </div>
          <el-collapse-transition>
            <!-- <div
              v-show="enterState"
              style="width: 100%;overflow-x: auto;"
            > -->
            <div
              v-show="enterState"
            >
              <el-tree
                v-show="Number($store.state.app.yes_no_public)===1"
                ref="enterprise"
                :props="defaultProps"
                :load="loadNode"
                lazy
                show-checkbox
                node-key="id"
                @check-change="handleCheckChange"
                @node-expand="setWidth"
              >
                <span
                  slot-scope="{ node,data }"
                  class="tree-node"
                >
                  <span>{{ node.label }}</span>
                  <span
                    v-if="data.is_public_users&&data.is_public_users==='1'&&!ismetting"
                    class="labelicon"
                    @click="getShowMaillist(data.id)"
                  >
                    <el-tooltip
                      class="box-item"
                      effect="light"
                      :content="$t('message.other.view_member')"
                      placement="bottom"
                    >
                      <span>
                        <svg-icon
                          icon-class="maillist"
                          class="g-maillist-icon"
                        />
                      </span>
                    </el-tooltip>
                  </span>
                  <span
                    v-if="data.list_name&&data.list_name!==''&&!ismetting"
                    class="labelicon"
                    @click="addDeptList(data)"
                  >
                    <el-tooltip
                      class="box-item"
                      effect="light"
                      :content="$t('message.other.add_depart_mail')"
                      placement="bottom"
                    >
                      <span>
                        <svg-icon
                          icon-class="dept"
                          class="g-maillist-icon"
                        />
                      </span>
                    </el-tooltip>
                  </span>
                </span>
              </el-tree>
            </div>

          </el-collapse-transition>
          <!-- 个人通讯录 -->
          <div
            class="contacts-tree"
            style="border-top: 1px solid #DEDCDC;"
            :style="{width:scrollerWeight}"
            @click="personState=!personState"
          >
            <div
              class="contacts-tree-title"
            >
              <span>
                <svg-icon
                  icon-class="g_person"
                  class="g-common-icon"
                />
                <span style="margin-left: 6px;">{{ $t('message.contact.percontact') }}</span>
              </span>
            </div>
            <svg-icon
              class="customicon"
              :icon-class="personState==1?'up-arrow':'down-arrow'"
              style="cursor: pointer"
            />
          </div>
          <el-collapse-transition>
            <!-- <div
              v-show="personState"
              style="width: 100%;overflow-x: auto;"
            > -->
            <div
              v-show="personState"
            >
              <el-tree
                ref="personTree"
                :data="treeData"
                :props="defaultProps2"
                show-checkbox
                :style="{width:treeData.length===0?'100%':''}"
                node-key="id"
                @check-change="handleCheckChange"
                @node-expand="setWidth"
              >
                <span
                  slot-scope="{ node }"
                  class="tree-node"
                >
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
          </el-collapse-transition>
          <!-- 企业通讯录 -->

        </div>
        <div class="icon">
          <i
            :title="`${$t('message.contact.addto')}${typeMailname[active]}`"
            class="el-icon-d-arrow-right"
            :class="[isHave?'text-color':'']"
            @click="pushmail"
          />
        </div>
        <div
          v-if="isempower"
          class="right"
        >
          <div class="to title">
            <span>{{ $t('message.setting.authobj') }}({{ power.length }})</span>
            <span>
              <el-button
                type="text"
                class="clearBtn"
                @click="clear('power')"
              >{{ $t('message.contact.clear') }}</el-button>
            </span>
          </div>
          <div
            style="flex:1"
            class="content"
          >
            <div
              v-for="(user,index) in power"
              :key="index"
              class="mail"
            >
              <div
                v-title="user.name+user.mail"
                class="flex-title"
              >
                <span class="name">{{ user.name }}</span>&nbsp;&nbsp;<span class="mail-item">({{ user.mail }})</span>
              </div>
              <i
                class="el-icon-close showclose"
                @click="delMail(index,'power')"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="ismetting"
          class="right"
        >
          <div class="to title">
            <span>{{ $t('message.contact.participant') }}({{ metting.length }})</span>
            <span>
              <el-button
                type="text"
                class="clearBtn"
                @click="clear('metting')"
              >{{ $t('message.contact.clear') }}</el-button>
            </span>
          </div>
          <div
            style="flex:1"
            class="content"
          >
            <div
              v-for="(user,index) in metting"
              :key="index"
              class="mail"
            >
              <div
                v-title="user.name+user.mail"
                class="flex-title"
              >
                <span class="name">{{ user.name }}</span>&nbsp;&nbsp;<span class="mail-item">({{ user.mail }})</span>
              </div>
              <i
                class="el-icon-close showclose"
                @click="delMail(index,'metting')"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="right"
        >
          <div
            class="to title"
            style="margin-bottom: 1px;"
            @click="expandgroup('to')"
          >
            <span>{{ $t('message.compose.to') }}({{ tolist.length }})</span>
            <span>
              <el-button
                type="text"
                class="clearBtn"
                @click.stop="clear('to')"
              >{{ $t('message.contact.clear') }}</el-button>
              <svg-icon
                class="customicon"
                :icon-class="active==1?'up-arrow':'down-arrow'"
                style="cursor: pointer"
              />
            </span>
          </div>
          <div
            v-show="active==1"
            style="flex:1"
            class="content powerlist"
          >
            <div
              v-for="(user,index) in tolist"
              :key="index"
              class="mail"
            >
              <div
                v-title="user.name+user.mail"
                class="flex-title"
              >
                <span class="name">{{ user.name }}</span>&nbsp;&nbsp;<span class="mail-item">({{ user.mail }})</span>
              </div>
              <i
                class="el-icon-close showclose"
                @click="delMail(index,'to')"
              />
            </div>
          </div>
          <div
            class="cc title"
            style="margin-bottom: 1px;"
            @click="expandgroup('cc')"
          >
            <span>{{ $t('message.compose.cc') }}({{ cclist.length }})</span>
            <span>
              <el-button
                type="text"
                class="clearBtn"
                @click.stop="clear('cc')"
              >{{ $t('message.contact.clear') }}</el-button>
              <svg-icon
                class="customicon"
                :icon-class="active==2?'up-arrow':'down-arrow'"
                style="cursor: pointer"
              />
            </span>
          </div>
          <div
            v-show="active==2"
            style="flex:1"
            class="content"
          >
            <div
              v-for="(user,index) in cclist"
              :key="index"
              class="mail"
            >
              <div
                v-title="user.name+user.mail"
                class="flex-title"
              >
                <span class="name">{{ user.name }}</span>&nbsp;&nbsp;<span class="mail-item">({{ user.mail }})</span>
              </div>
              <i
                class="el-icon-close showclose"
                @click="delMail(index,'cc')"
              />
            </div>

          </div>
          <div
            class="bcc title"
            @click="expandgroup('bcc')"
          >
            <span>{{ $t('message.compose.bcc') }}({{ bcclist.length }})</span>
            <span>
              <el-button
                type="text"
                class="clearBtn"
                @click.stop="clear('bcc')"
              >{{ $t('message.contact.clear') }}</el-button>
              <svg-icon
                :icon-class="active==3?'up-arrow':'down-arrow'"
                style="cursor: pointer"
                class="customicon"
              />
            </span>
          </div>
          <div
            v-show="active==3"
            style="flex:1"
            class="content"
          >

            <div
              v-for="(user,index) in bcclist"
              :key="index"
              class="mail"
            >
              <div
                v-title="user.name+user.mail"
                class="flex-title"
              >
                <span class="name">{{ user.name }}</span>&nbsp;&nbsp;<span class="mail-item">({{ user.mail }})</span>
              </div>
              <i
                class="el-icon-close showclose"
                @click="delMail(index,'bcc')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <MaillistCard
      :id="list_id"
      ref="maillistcard1"
    />
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="showContact = false">{{ $t('message.btnText.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="getContactAddress"
      >{{ $t('message.btnText.ok') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { countCharacters } from '@/utils/utils'
import requestContact from '@/components/ContactPopper/request'
import MaillistCard from '@/views/contacts/components/maillist-card.vue'
export default {
  components: { MaillistCard },
  mixins: [requestContact],
  props: {
    title: {
      type: String,
      default: '通讯录',
    },
    to: {
      type: Array,
      default: () => [],
    },
    cc: {
      type: Array,
      default: () => [],
    },
    bcc: {
      type: Array,
      default: () => [],
    },
    isempower: {
      type: Boolean,
      default: false,
    },
    ismetting: {
      type: Boolean,
      default: false,
    },
    powerlist: {
      type: Array,
      default: () => [],
    },
    mettinglist: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: 1,
      typeMail: {
        1: 'to',
        2: 'cc',
        3: 'bcc',
      },
      typeMailname: {
        1: this.$t('message.compose.to'),
        2: this.$t('message.compose.cc'),
        3: this.$t('message.compose.bcc'),
        4: this.$t('message.setting.authobj'),
      },
      showContact: false,
      search: '',
      defaultProps: {
        label: 'name',
        isLeaf: 'leaf',
      },

      userLists: [],
      address: {
        to: [],
        cc: [],
        bcc: [],
      },
      defaultProps2: {
        label: 'name',
        isLeaf: 'leaf',
        children: 'user_list',
      },
      treeData: [],
      commonData: [],
      visible: false,
      searchList: [],
      defaultExpandedNodes: [],
      tolist: [],
      cclist: [],
      bcclist: [],
      power: [],
      metting: [],
      list_id: '',
      scrollerWeight: '100%',
      usual: true,
      powerState: true,
      enterState: true,
      personState: true,
      isHave: false,
    }
  },
  watch: {
    searchList(val) {
      if (val.length > 0) {
        this.visible = true
      } else {
        this.visible = false
      }
    },
  },
  mounted() {
    // 授权管理页面引用此组件时没有缓存,使用mounted
    this.getPersonList()
    this.getCommon()
  },
  created() {
  },
  methods: {
    clearSearch(flag) {
      if (flag) {
        if (this.search === '') {
          this.searchList = []
        }
      } else {
        this.searchList = []
      }
    },
    async getPersonList() {
      const data = await this.getPersonContact()
      this.treeData = data
    },
    async getCommon() {
      const data = await this.getCommonContact()
      this.commonData = data
    },

    async showContactPopper(flag, word) {
      this.expandgroup(flag)
      this.showContact = true
      if (word) {
        this.search = word
        const data = await this.searchContact(this.search, this.isempower ? 1 : '')
        const cc = data.map(item => {
          item.isValid = true
          return item
        })
        this.searchList = cc
      }
    },
    getContactAddress() {
      let data = null
      data = {
        to: [...this.tolist],
        cc: [...this.cclist],
        bcc: [...this.bcclist],
      }
      if (this.isempower) {
        data = {
          powerlist: [...this.power],
        }
      } else if (this.ismetting) {
        data = {
          mettinglist: [...this.metting],
        }
      } else {
        const count = this.tolist.length + this.cclist.length + this.bcclist.length
        if (count > Number(this.$store.state.app.mail_to_max)) {
          return this.$message.error(this.$t('message.mailmodule.max_select_contact', { num: this.$store.state.app.mail_to_max }))
        }
      }

      this.$emit('get-address-data', data)
      this.closeContactPoper()
    },
    closeContactPoper() {
      this.showContact = false
    },
    pushmail() {
      if (this.isempower) {
        this.active = 4
        const lists = this.$refs['powerTree'].getCheckedNodes().filter(item => item.leaf)
        lists.map(item => {
          if (this.checkedRepeat(item)) {
            this.power.push(item)
          }
        })

        return false
      }
      const commonlist = this.$refs['commonTree'].getCheckedNodes().filter(item => item.leaf)
      const publiclist = this.$refs['enterprise'].getCheckedNodes().filter(item => item.leaf)
      const personlist = this.$refs['personTree'].getCheckedNodes().filter(item => item.leaf)
      const mails = [...commonlist, ...publiclist, ...personlist]
      if (this.ismetting) {
        mails.map(item => {
          if (this.checkedRepeatmetting(item)) {
            this.metting.push(item)
          }
        })

        return false
      }
      mails.map(item => {
        if (this.active === 1 && this.checkedRepeat(item)) {
          this.tolist.push(item)
        } else if (this.active === 2 && this.checkedRepeat(item)) {
          this.cclist.push(item)
        } else if (this.active === 3 && this.checkedRepeat(item)) {
          this.bcclist.push(item)
        }
      })
      if (this.isempower) {
        this.$refs['powerTree'].setCheckedKeys([])
        return false
      }
      this.$refs['commonTree'].setCheckedKeys([])
      this.$refs['enterprise'].setCheckedKeys([])
      this.$refs['personTree'].setCheckedKeys([])
    },
    checkedRepeat(item) {
      if (this.active === 1) {
        const res = this.tolist.find(v => v.mail === item.mail)
        return !res
      } else if (this.active === 2) {
        const res = this.cclist.find(v => v.mail === item.mail)
        return !res
      } else if (this.active === 3) {
        const res = this.bcclist.find(v => v.mail === item.mail)
        return !res
      } else if (this.active === 4) {
        const res = this.power.find(v => v.mail === item.mail)
        return !res
      }
    },
    checkedRepeatmetting(item) {
      const res = this.metting.find(v => v.mail === item.mail)
      return !res
    },
    addDeptList(data) {
      const item = {
        name: data.name,
        mail: data.list_name,
        path: this.$t('message.mailmodule.depart_list'),
        isValid: true,
      }
      this.userStreamCache.setUserCache(item)
      if (this.active === 1 && this.checkedRepeat(item)) {
        this.tolist.push(item)
      } else if (this.active === 2 && this.checkedRepeat(item)) {
        this.cclist.push(item)
      } else if (this.active === 3 && this.checkedRepeat(item)) {
        this.bcclist.push(item)
      }
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        let list = null
        if (this.isempower) {
          list = [{
            name: this.$t('message.contact.orgcontact'),
            id: 'org',
            leaf: false,
          }]
        } else if (this.ismetting) {
          list = [{
            name: this.$t('message.contact.orgcontact'),
            id: 'org',
            leaf: false,
          }, {
            name: this.$t('message.contact.outcontact'),
            id: 'out',
            leaf: false,
          }]
        } else {
          list = [{
            name: this.$t('message.contact.orgcontact'),
            id: 'org',
            leaf: false,
          }, {
            name: this.$t('message.contact.maillist'),
            id: 'maillist',
            leaf: false,
          }, {
            name: this.$t('message.contact.outcontact'),
            id: 'out',
            leaf: false,
          }]
        }

        return resolve(list)
      } else {
        if (node.data.type === 'out') {
          const cache = this.$store.state.contact.outCache
          return resolve(cache[node.data.id])
        }
        const list = await this.getPublicContact(node.data.id)
        return resolve(list)
      }
    },
    async loadNodePerson(node, resolve) {
      if (node.level === 0) {
        const list = [{
          name: this.$t('message.contact.orgcontact'),
          id: '',
          leaf: false,
        }, {
          name: this.$t('message.contact.maillist'),
          id: 'maillist',
          leaf: false,
        }]
        return resolve(list)
      } else {
        const list = await this.getPublicContact(node.data.id, 0)
        return resolve(list)
      }
    },
    handleNodeClick(data) {
      this.userLists = this.userStream[data.group_id]
    },
    expandgroup(flag) {
      switch (flag) {
        case 'to':
          this.active = 1
          break
        case 'cc':
          this.active = 2
          break
        case 'bcc':
          this.active = 3
          break
      }
    },
    async searchUsers() {
      if (this.search === '' || countCharacters(this.search) < 3) return
      const data = await this.searchContact(this.search, this.isempower ? 1 : '')
      const cc = data.map(item => {
        item.isValid = true
        return item
      })
      this.searchList = cc
    },
    handleCheckChange(nodeData, nodeSelected) {
      if (this.$refs['commonTree']?.getCheckedNodes().length ||
        this.$refs['enterprise']?.getCheckedNodes().length ||
        this.$refs['personTree']?.getCheckedNodes().length || this.$refs['powerTree']?.getCheckedNodes().length) {
        this.isHave = true
      } else {
        this.isHave = false
      }
      let tree = null
      tree = this.$refs.enterprise
      if (this.isempower) {
        tree = this.$refs.powerTree
      }
      const node = tree.getNode(nodeData.id)
      this.expandCheckedNotExpandNodes(node)
    },
    expandCheckedNotExpandNodes(node) {
      let tree = null
      tree = this.$refs.enterprise
      if (this.isempower) {
        tree = this.$refs.powerTree
      }
      if (node && node.checked && !node.expanded && !node.isLeaf) {
        node.expand(function() {
          const childNodes = node.childNodes
          for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i]

            // 手动触发check-change事件，事件处理函数中回继续调用此函数，形成递归展开
            tree.$emit('check-change', childNode.data, childNode.checked, childNode.indeterminate)
          }
        })
      }
    },
    // 清除地址
    clear(key) {
      switch (key) {
        case 'to':
          this.tolist.splice(0, this.tolist.length)
          break
        case 'cc':
          this.cclist.splice(0, this.cclist.length)
          break
        case 'bcc':
          this.bcclist.splice(0, this.bcclist.length)
          break
        case 'power':
          this.power.splice(0, this.power.length)
          break
        case 'metting':
          this.metting.splice(0, this.metting.length)
          break
      }
    },
    delMail(index, tag) {
      switch (tag) {
        case 'to':
          this.tolist.splice(index, 1)
          break
        case 'cc':
          this.cclist.splice(index, 1)
          break
        case 'bcc':
          this.bcclist.splice(index, 1)
          break
        case 'power':
          this.power.splice(index, 1)
          break
        case 'metting':
          this.metting.splice(index, 1)
          break
      }
    },
    handleClose() {
      if (this.isempower) {
        this.$refs['powerTree'].setCheckedKeys([])
        return false
      }
      this.$refs['commonTree'].setCheckedKeys([])
      this.$refs['enterprise'].setCheckedKeys([])
      this.$refs['personTree'].setCheckedKeys([])
      this.visible = false
      this.searchList = []
      this.search = ''
    },
    handleOpen() {
      this.tolist = [...this.to]
      this.cclist = [...this.cc]
      this.bcclist = [...this.bcc]
      this.power = [...this.powerlist]
      this.metting = [...this.mettinglist]
    },
    handleOpened() {
      if (this.isempower) this.active = 4
      // this.scrollerWeight = document.getElementsByClassName('left')[0].scrollWidth + 'px'
    },
    setWidth() {
      /* setTimeout(() => {
        this.scrollerWeight = document.getElementsByClassName('left')[0].scrollWidth + 'px'
      }, 1000) */
    },
    // 搜索添加
    SearchListadd(data) {
      if (this.isempower) {
        if (!this.power.find(item => item.mail === data.mail)) {
          this.power.push(data)
        } else {
          this.$message.error(this.$t('message.mailmodule.already_add'))
        }
      } else if (this.ismetting) {
        if (!this.metting.find(item => item.mail === data.mail)) {
          this.metting.push(data)
        } else {
          this.$message.error(this.$t('message.mailmodule.already_add'))
        }
      } else {
        if (this.active === 1) {
          if (!this.tolist.find(v => v.mail === data.mail)) {
            this.tolist.push(data)
          } else {
            this.$message.error(this.$t('message.mailmodule.already_add'))
          }
        } else if (this.active === 2) {
          if (!this.cclist.find(v => v.mail === data.mail)) {
            this.cclist.push(data)
          } else {
            this.$message.error(this.$t('message.mailmodule.already_add'))
          }
        } else {
          if (!this.bcclist.find(v => v.mail === data.mail)) {
            this.bcclist.push(data)
          } else {
            this.$message.error(this.$t('message.mailmodule.already_add'))
          }
        }
      }
    },
    getShowMaillist(id) {
      this.list_id = id
      this.$refs.maillistcard1.showDia()
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select-dropdown__item{
  line-height: initial;
  padding: 10px 15px;
  height: auto;
  border-bottom:1px solid #DEDCDC;
  &:hover{
    background-color: #f0f0f1;
  }
}
/deep/.previewConatctDialog.el-dialog {
  .el-dialog__header {
    display: none;
  }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer{
    padding-top: 20px;
  }
}
.contacts-tree {
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
    .contacts-tree-title {
      padding: 5px 0 5px 9px;
      font-weight: bold;
      color: #1D1B1B;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img{
        vertical-align: bottom;
      }
      .addiocn{
        padding-right: 11px;
      }
    }
    .power-title{
      padding-left: 17px;
    }

}
.power{
  margin-bottom: 0px;
}
 /deep/ .el-tree{
        // margin-left: 20px;
        // margin-right: 20px;
        padding: 0px;
        color: #1d1b1b;
        width: fit-content;
        min-width: -webkit-fill-available;
        .el-tree-node > .el-tree-node__children{
          overflow: inherit;
        }
    }
.container{
  // padding: 0 23px 0 32px;
  .top{
    padding: 0 23px 0 32px;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    .title{
       display: flex;
      justify-content: space-between;
      align-items: center;
      color: #2C2C2C;
      font-weight: bold;
      .input{
        width: 331px;
        // padding-right:21px ;
        margin-left: 10px;
      }
    }
    .search{

      .closeicon{
        cursor: pointer;
        font-size: 17px;
      }
    }
  }
    .g-common-icon{
        width: 1.2em;
        height: 1.2em;
      }
      .g-maillist-icon{
        width: 1em;
        height: 1em;
      }
  .main{
    display: flex;
    flex-direction: row;
    flex-flow: row;
    padding: 16px 23px 0 32px;
    .left{
    flex: 1;
    height: 390px;
    width: 360px;
    border: 1px solid #DDDDDD;
    overflow-y: auto;
    .usual{
      font-weight: bold;
      padding-left: 10px;
      padding-right: 8px;
      color: #1D1B1B;
      background-color: #f4f4f4;
      height: 28px;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
     }
    }
    .center{
      border: 1px solid #DDDDDD;
      border-left: none;
      .mail{
        padding: 5px 12px;
        .mail-item{
          color: #959595;
        }
        &:hover{
          background-color: #e4e4e4;
        }
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      .title{
        height: 26px;
        line-height: 26px;
        padding: 0 8px 0 10px;
        background-color: #f4f4f4;
        color: #2C2C2C;
        display: flex;
        justify-content: space-between;
        align-items: center;

      }
      .content{
        max-height: 305px;
        overflow: auto;
        margin-top: 3px;
        .mail{
          padding: 5px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .flex-title{
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #1d1b1b;
          }
          .showclose{
            display: none;
          }
          .mail-item{
            color: #1d1b1b;
          }
          &:hover{
            background-color: #e4e4e4;
            .showclose{
            display: block;
          }
          }
        }
      }
      .powerlist{
        height: 473px;
      }
    }
    .icon{
      padding: 0 15px;
      display: flex;
      align-items: center;
      font-size: 24px;
    }
    .right{
      flex: 1;
      border: 1px solid #DDDDDD;
    }
  }
}
.customicon{
  width: 0.7em !important;
  height: 0.7em !important;
  margin-left:5px;
  margin-bottom: 2px;
 }
/deep/.scrollbar-wrapper {
  margin-right: 0 !important;
  height: 465px;
  overflow-y: auto;
}
/deep/.scrollbar-center {
  height: 465px;
  width: 383px;
  overflow-y: auto;
}
/deep/.scrollbar-right{
  height: 381px;
}
/* Style the scrollbar */
.el-scrollbar__thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}

.el-scrollbar__bar {
  background-color: #f0f0f0;
  border-radius: 4px;
}
.el-icon-d-arrow-right{
  cursor: pointer;
  color: #ccc;
}
.el-icon-close{
  cursor: pointer;
}
.tree-node{
  flex:1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.labelicon{
  margin-right: 10px;
}
/deep/.el-input__suffix{
  top: 0 !important;
}
.showcommon{
  display: none;
  padding-right: 10px;
}
.el-tree-node__content:hover{
  .showdelcommon{
    display: block;
  }
}
</style>
<style lang="scss">
.popperCustomSearch{
  max-width: 70%;
  max-height: 70%;
  overflow-y: auto;
  padding: 0!important;
}
.el-icon-d-arrow-right:hover{
  background-color: #f0f0f1;

}
.el-icon-d-arrow-right{
  padding: 0.2em;
  border-radius: 0.2em;
  box-sizing: border-box!important;
  font-weight: bold;
}

</style>

