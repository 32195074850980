<template>
  <div class="forget_box">
    <div class="head">
      <p> <img
        :src="logo"
        alt="logo"
      ></p>

    </div>
    <h1>{{ $t('message.login.forgetpsw') }}</h1>
    <div class="step_box">
      <ul>
        <li
          v-for="i in stepList"
          :key="i.act"
          class="toplist"
        >
          <span
            class="act"
            :class="i.act <= active ? 'act_hov': ''"
            v-html="i.act"
          />
          <span
            class="txt"
            :class="i.act <= active ? 'txt_hov': ''"
            v-html="$t(`message.login.${i.txt}`)"
          />
          <span class="bod" />
        </li>
      </ul>
      <!-- 第一步骤 -->
      <div
        v-if="active == 1"
        class="step1 step"
      >
        <el-form
          ref="step1ValidateForm"
          :model="step1ValidateForm"
          :label-width="lang==='english'?'150px':'100px'"
          class="dynamic"
        >
          <el-form-item
            prop="email"
            :label="$t('message.login.mailaccount')"
            :rules="[
              { required: true, message: $t('message.login.inputmail'), trigger: 'blur' },
              { type: 'email', message: $t('message.login.inputrightmail'), trigger: ['blur', 'change'] }
            ]"
          >
            <input
              v-model="step1ValidateForm.email"
              class="noelInput"
              :placeholder="$t('message.login.placeholder6')"
              onkeypress="if(event.keyCode == 13) return false;"
            >

            <!-- 隐藏的输入框作用于防止ie11回车刷新事件 -->
            <input
              id="hiddenText"
              type="text"
              style="display:none"
            >
          </el-form-item>
        </el-form>
        <div
          class="codeImg"
        >
          <VerificationCode
            v-if="isCodeImg"
            ref="child"
            @finishVerify="finishVerify"
          />
        </div>
        <p class="btn">
          <span
            class="nexBtn btnDanger"
            @click="calen()"
          >{{ $t('message.login.resetbtx') }}</span>
          <button
            class="nexBtn btnPrimary"
            @click="submitForm1()"
          >{{ $t('message.login.confirmbtx') }}</button></p>
      </div>
      <!-- 第二步骤 -->
      <div
        v-if="active == 2"
        class="step2 step"
      >
        <h2>{{ $t('message.login.confirminfo1') }}</h2>
        <h2>{{ $t('message.login.confirminfo2') }}</h2>
        <el-form
          ref="step2ValidateForm"
          :model="step2ValidateForm"
          :rules="rules"
          :label-width="lang==='english'?'150px':'100px'"
          class="dynamic"
        >
          <el-form-item
            prop="question_id"
            :label="$t('message.login.selectq')"
          >
            <el-select
              v-if="!isQuestion"
              v-model="step2ValidateForm.question_id"
              :placeholder="$t('message.login.placeholder7')"
              :style="{width:lang==='english'?'270px':'320px'}"
              @change="questionClick"
            >
              <el-option
                v-for="item in questionList"
                :key="item.val"
                :label="$t(`message.login.${item.label}`)"
                :value="item.val"
              />
            </el-select>
            <p
              v-else
              class="wtbox"
            >
              <input
                v-model="textarea_question"
                class="noelInput noLimit"
                :placeholder="$t('message.login.inputcontent')"
                maxlength="128"
              >
              <span class="wt_nums">{{ textarea_question.length }}/128</span>
              <svg-icon
                class="wt_back"
                icon-class="goBackPassword"
                @click="back(1)"
              />

            </p>
          </el-form-item>
          <el-form-item
            :label="$t('message.login.inputa')"
            prop="answer"
            class="qtion"
          >
            <input
              v-model="step2ValidateForm.answer"
              class="noelInput noelInputBorder"
              maxlength="128"
              :placeholder="$t('message.login.placeholder8')"
            >
            <span>{{ step2ValidateForm.answer.length }}/128</span>

          </el-form-item>
        </el-form>
        <p class="btn">
          <span
            class="nexBtn btnDanger"
            @click="calen()"
          >{{ $t('message.login.resetbtx') }}</span>
          <span
            class="nexBtn btnPrimary"
            @click="submitForm2('step2ValidateForm')"
          >{{ $t('message.login.confirmbtx') }}</span>
        </p>
        <p class="clear" />
      </div>

      <!-- 第三步骤 -->
      <div
        v-if="active == 3"
        class="step2 step"
      >
        <h2>{{ $t('message.login.step3') }}：</h2>
        <el-form
          ref="step3ValidateForm"
          :model="step3ValidateForm"
          :rules="rules"
          :label-width="lang==='english'?'160px':'100px'"
          :class="{'dynamic':lang!=='english'}"
        >
          <el-form-item
            :label="$t('message.login.newpsw')"
            class="pswError"
            prop="password"
          >
            <!-- <el-input
              v-model="step3ValidateForm.password"
              placeholder="请输入密码"
            /> -->
            <div
              class="showPass showPassHeight"
            >
              <div
                v-show="tips1"
                class="tips"
              >
                <div v-html="error_html" />
              </div>
              <!-- <p v-if="!eyes">
                <input
                  id="password"
                  v-model="pwdCover"
                  class="noelInput"
                  placeholder="请输入密码"
                  autocomplete="off"
                  type="text"
                  maxlength="32"
                  @focus="tips1 = true"
                  @blur="tips1 = false"
                  @keydown="handlerKeydown"
                  @input="setPassword"
                >
                <span @click="showPwd(true)"><svg-icon icon-class="hidePassword" /></span></p>
              <p v-else>
                <input
                  id="password"
                  v-model="step3ValidateForm.password"
                  class="noelInput"
                  placeholder="请输入密码"
                  autocomplete="off"
                  type="text"
                  maxlength="32"
                  @focus="tips1 = true"
                  @blur="tips1 = false"
                  @input="setPwdCover"
                >
                <span @click="showPwd(false)"><svg-icon icon-class="showPassword" /></span>
              </p> -->

              <p>
                <input
                  id="password"
                  v-model="step3ValidateForm.password"
                  class="noelInput"
                  :placeholder="$t('message.login.placeholder2')"
                  autocomplete="off"
                  maxlength="32"
                  :type="inputType"
                  @focus="tips1 = true"
                  @blur="tips1 = false"
                >
                <span
                  v-if="!eyes"
                  @click="showPwd(true)"
                ><svg-icon
                  class="login-svg"
                  icon-class="hidePassword"
                /></span>
                <span
                  v-else
                  @click="showPwd(false)"
                ><svg-icon
                  class="login-svg"
                  icon-class="showPassword"
                /></span>
              </p>
            </div>
          </el-form-item>
          <el-form-item
            class="pswError"
            :label="$t('message.login.confirmpsw')"
            prop="password_ok"
          >
            <div class="showPass showPassHeight">

              <div
                v-show="tips2"
                class="tips"
              >
                <div v-html="error_html" />
              </div>
              <!-- <p
                v-if="!eyes_ok"
              >
                <input
                  id="password"
                  v-model="pwdCover_ok"
                  class="noelInput"
                  placeholder="请确认密码"
                  autocomplete="off"
                  type="text"
                  maxlength="32"
                  @focus="tips2 = true"
                  @blur="tips2 = false"
                  @keydown="handlerKeydown_ok"
                  @input="setPassword_ok"
                >
                <span @click="showPwd_ok(true)"><svg-icon icon-class="hidePassword" /></span></p>
              <p v-else>
                <input
                  id="password"
                  v-model="step3ValidateForm.password_ok"
                  class="noelInput"
                  placeholder="请确认密码"
                  autocomplete="off"
                  type="text"
                  maxlength="32"
                  @focus="tips2 = true"
                  @blur="tips2 = false"
                  @input="setPwdCover_ok"
                >
                <span @click="showPwd_ok(false)"><svg-icon icon-class="showPassword" /></span>
              </p> -->

              <p>
                <input
                  id="password"
                  v-model="step3ValidateForm.password_ok"
                  class="noelInput"
                  :placeholder="$t('message.login.placeholder2')"
                  autocomplete="off"
                  maxlength="32"
                  :type="inputType_ok"
                  @focus="tips2 = true"
                  @blur="tips2 = false"
                >
                <span
                  v-if="!eyes_ok"
                  @click="showPwd_ok(true)"
                ><svg-icon
                  class="login-svg"
                  icon-class="hidePassword"
                /></span>
                <span
                  v-else
                  @click="showPwd_ok(false)"
                ><svg-icon
                  class="login-svg"
                  icon-class="showPassword"
                /></span>
              </p>
            </div>
          </el-form-item>
        </el-form>
        <p class="btn">
          <span
            class="nexBtn btnDanger"
            @click="calen()"
          >{{ $t('message.login.resetbtx') }}</span>
          <span
            class="nexBtn btnPrimary"
            @click="submitForm3('step3ValidateForm')"
          >{{ $t('message.login.confirmbtx') }}</span>
        </p>
        <p class="clear" />
      </div>
      <!-- 第四步骤 -->
      <div
        v-if="active == 4"
        class="step4 step"
      >
        <i class="el-icon-circle-check succ_ok" />
        <h2>{{ $t('message.login.setsuccess') }}</h2>
        <p class="success">{{ $t('message.login.singinagain') }}</p>
        <p class="btn">
          <span
            class="nexBtn btnPrimary"
            @click="toLogin"
          >{{ $t('message.login.back') }}</span>
        </p>

      </div>
    </div>
  </div>
</template>
<script>
import VerificationCode from '@/views/components/code.vue'
import { PasswordSm4 } from '@/utils/changePassword'
// import { PassWord, PwdCover, PasswordSm4 } from '@/utils/changePassword'
import serialize from '@/utils/serialize'
import { transformClass } from '@/utils/utils.js'
export default {
  components: {
    VerificationCode,
  },
  data() {
    return {
      usermail: '', // 每一步都需要 邮箱名
      active: 1, // 进度条
      stepList: [{
        act: 1,
        txt: 'step1',
      }, {
        act: 2,
        txt: 'step2',
      }, {
        act: 3,
        txt: 'step3',
      }, {
        act: 4,
        txt: 'step4',
      }],
      lang: localStorage.getItem('lang'),
      step1ValidateForm: {
        email: '',
      },
      step2ValidateForm: {
        question_id: '',
        answer: '',
      },
      step3ValidateForm: {
        password: '',
        password_ok: '',
      },
      pwdCover: '', // 虚拟密码
      inputType: 'password',
      eyes: false, // 是否查看密码
      inputType_ok: 'password',
      pwdCover_ok: '', // 虚拟密码
      eyes_ok: false, // 是否查看密码
      isQuestion: false,
      isCodeImg: false, // 是否显示验证码
      textarea_question: '',
      tips1: false,
      tips2: false,
      inputCode: '', // 验证码
      token: '',
      questionList: [{
        val: 1,
        label: 'q1',
        q: '您的出生地是?',
      }, {
        val: 2,
        label: 'q2',
        q: '您的父亲姓名是?',
      }, {
        val: 3,
        label: 'q3',
        q: '您的母亲姓名是?',
      }, {
        val: 4,
        label: 'q4',
        q: '您的配偶姓名是?',
      }, {
        val: 5,
        label: 'q5',
        q: '您的生日是?',
      }, {
        val: 6,
        label: 'q6',
        q: '您的工作城市是?',
      }, {
        val: 7,
        label: 'q7',
        q: '《-自定义问题-》',
      }],
      error_html: '',
      logo: this.$store.state.app.loginSetInfo.login_logo,
    }
  },
  computed: {
    rules() {
      const checkPwd = (rule, value, callback) => {
        console.log(value)
        if (value.trim().length === 0) {
          callback(new Error(this.$t('message.login.err1')))
        } else if (value.trim().length < 8 || value.trim().length > 32) {
          callback(new Error(this.$t('message.login.err2')))
        } else {
          callback()
        }
      }

      const checkPwd_ok = (rule, value, callback) => {
        if (value.trim().length === 0) {
          callback(new Error(this.$t('message.login.err1')))
        } else if (value.trim().length < 8 || value.trim().length > 32) {
          callback(new Error(this.$t('message.login.err2')))
        } else if (value !== this.step3ValidateForm.password) {
          callback(new Error(this.$t('message.login.err3')))
        } else {
          callback()
        }
      }
      const question_rule = (rule, value, callback) => {
        if (value === '' || (value === 7 && this.textarea_question.trim() === '')) {
          callback(new Error(this.$t('message.login.placeholder7')))
        } else {
          callback()
        }
      }
      return {
        password: [{ validator: checkPwd, required: true, trigger: 'blur' }],
        password_ok: [{ validator: checkPwd_ok, required: true, trigger: 'blur' }],
        question_id: [{ validator: question_rule, required: true, trigger: 'change' }],
        answer: [{ required: true, message: this.$t('message.login.placeholder8'), trigger: 'blur' }],
      }
    },
  },
  created() {
    this.$i18n.locale = localStorage.getItem('lang') || 'simplified_chinese'
  },
  mounted() {
  },
  methods: {
    // 验证码刷新
    finishVerify(code) {
      this.inputCode = code[0]
      this.token = code[1]
      this.login()
    },
    submitForm1() {
      this.$refs['step1ValidateForm'].validate((valid) => {
        if (valid) {
          // 出现验证码
          this.isCodeImg = true
          this.$nextTick(() => {
            this.$refs.child.img()
          })
        } else {
          return false
        }
      })
    },
    login() {
      this.$refs['step1ValidateForm'].validate((valid) => {
        if (valid) {
          const data = {
            require: {
              action: 'ckpassword_one',
              mail: this.step1ValidateForm.email,
              code: new PasswordSm4(this.step1ValidateForm.email, this.inputCode).toValue(),
              token: this.token,
              show_language: localStorage.getItem('lang'),
            },

          }
          // '忘记密码第一步'
          this.$axios.post(this.$apiData.ckpassword_new, { req: serialize(data) }).then(res => {
            if (res.status === 200) {
              this.active++
              this.isCodeImg = false
              this.token = res.result.token
              this.usermail = this.step1ValidateForm.email
            } else if (res.status === 20020) {
              this.$message.error(res.msg)
              this.$refs.child.img()
            } else {
              this.isCodeImg = false
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    calen() {
      this.step1ValidateForm = {
        email: '',
      }
      this.step2ValidateForm = {
        question_id: '',
        answer: '',
      }
      this.step3ValidateForm = {
        password: '',
        password_ok: '',
      }
      this.pwdCover = ''
      this.pwdCover_ok = ''
      this.eyes_ok = false
      this.eyes = false // 是否查看密码
      this.inputType = 'password'
      this.inputType_ok = 'password'
    },
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.questionList.forEach(el => {
            if (el.val === this.step2ValidateForm.question_id) {
              this.step2ValidateForm.question = this.step2ValidateForm.question_id === 7 ? this.textarea_question : el.q
            }
          })
          const data = {
            require: {
              action: 'ckpassword_two',
              mail: this.usermail,
              question: this.step2ValidateForm.question,
              answer: this.step2ValidateForm.answer,
              question_id: this.step2ValidateForm.question_id,
              show_language: localStorage.getItem('lang'),
              token: this.token,

            },

          }
          // '忘记密码第二步'
          this.$axios.post(this.$apiData.ckpassword_new, { req: serialize(data) }).then(res => {
            if (res.status === 200) {
              this.active++
              this.token = res.result.token
              const pass_policy = res.result.pass_policy
              this.error_html = transformClass.password_error_html(pass_policy.min_pass_lenth, pass_policy.max_pass_lenth, pass_policy.pass_filt, pass_policy.old_pass_same === 1 ? '不能' : '能', pass_policy.user_pass_same === 1 ? '不能' : '能', localStorage.getItem('lang'))
            } else if (res.status === 20019) {
              this.active = 1
              this.$message.error(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },
    submitForm3(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            require: {
              action: 'ckpassword_three',
              mail: this.usermail,
              password: new PasswordSm4(this.usermail, this.step3ValidateForm.password).toValue(),
              newPassword: new PasswordSm4(this.usermail, this.step3ValidateForm.password_ok).toValue(),
              show_language: localStorage.getItem('lang'),
              token: this.token,
            },
          }
          // '忘记密码第三步'
          this.$axios.post(this.$apiData.ckpassword_new, { req: serialize(data) }).then(res => {
            if (res.status === 200) {
              this.active++
            } else if (res.status === 20019) {
              this.active = 1
              this.$message.error(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    // handlerKeydown(event) {
    //   this.cursorStart = event.target.selectionStart
    //   this.cursorEnd = event.target.selectionEnd
    // },
    // // 用户登录密码框提换值
    // setPassword(event) {
    //   this.pwdCover.length === 0 ? this.step3ValidateForm.password = '' : ''
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     this.err_msg = true
    //     this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover = this.pwdCover.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     // this.pwdCover = val.replace(/\S/g, '●')
    //     const b = new PassWord(event, this.pwdCover, this.step3ValidateForm.password, this.cursorStart, this.cursorEnd)
    //     this.pwdCover = b.toValue()[0]
    //     this.step3ValidateForm.password = b.toValue()[1]
    //   }
    // },
    // // 用户登录密码框提换值
    // setPwdCover(event) {
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.step3ValidateForm.password = this.step3ValidateForm.password.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover, this.step3ValidateForm.password)
    //     this.pwdCover = a.toValue()
    //   }
    // },
    // // 显示密码
    showPwd(status) {
      status ? this.eyes = true : this.eyes = false
      // this.pwdCover = this.pwdCover.slice(0, this.step3ValidateForm.password.length)
      this.inputType = status ? 'text' : 'password'
    },

    // handlerKeydown_ok(event) {
    //   this.cursorStart = event.target.selectionStart
    //   this.cursorEnd = event.target.selectionEnd
    // },
    // 用户登录密码框提换值--确认密码
    // setPassword_ok(event) {
    //   this.pwdCover_ok.length === 0 ? this.step3ValidateForm.password_ok = '' : ''
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     this.err_msg = true
    //     this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover_ok = this.pwdCover_ok.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     // this.pwdCover_ok = val.replace(/\S/g, '●')
    //     const b = new PassWord(event, this.pwdCover_ok, this.step3ValidateForm.password_ok, this.cursorStart, this.cursorEnd)
    //     this.pwdCover_ok = b.toValue()[0]
    //     this.step3ValidateForm.password_ok = b.toValue()[1]
    //   }
    // },
    // 用户登录密码框提换值--确认密码
    // setPwdCover_ok(event) {
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.step3ValidateForm.password_ok = this.step3ValidateForm.password_ok.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover_ok, this.step3ValidateForm.password_ok)
    //     this.pwdCover_ok = a.toValue()
    //   }
    // },
    // 显示密码--确认密码
    showPwd_ok(status) {
      status ? this.eyes_ok = true : this.eyes_ok = false
      // this.pwdCover_ok = this.pwdCover_ok.slice(0, this.step3ValidateForm.password_ok.length)

      this.inputType_ok = status ? 'text' : 'password'
    },
    // 跳转至登录页面
    toLogin() {
      // this.$router.push('/')
      console.log('goto', '222')
      this.$myApi.topGoto('login')
    },
    back(type) {
      this.isQuestion = false
      this.step2ValidateForm.question_id = ''
    },
    // 密码问题选择
    questionClick(type) {
      this.step2ValidateForm.question_id === 7 ? this.isQuestion = true : ''
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/password.scss';

input {
  background-color:transparent;
  // caret-color: $cursor;

  &:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {

    // background: url(../../assets/icons/login/l_password.png) 20px 11px
    //     no-repeat #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color:transparent;
    transition: background-color 50000s ease-in-out 0s; //背景色透明 生效时长 过渡效果 启用时延迟的时间
  }
}
/deep/.el-button--small{
  width: 160px;
  height: 46px;
  font-size: 14px;
}
.clear{
  clear: both;
}
.forget_box{
  width: 100vw;
  height: 100vh;
  background: #F4F6F8;
  overflow-y:auto;
  .head{
    height: 84px;
    width: 100%;
    background: #fff;
    p{
      width: 1200px;
      margin: 0 auto;
      img{
        height: 80px;
      }
    }
  }
  .nexBtn{
    padding:14px 60px;
    font-size: 14px;
    cursor: pointer;
  }
  .btnPrimary{
    background: #D0161B;
    border:1px solid #D0161B;
    color: #fff;
    margin-left: 10px;

  }
  .btnPrimary:hover{
    background: #da6065;
  }
  .btnDanger{
    background: #fde9e9;
    border:1px solid #f6a6a6;
    color: #e92020;
  }
  .btnDanger:hover{
    background: #D0161B;
    border:1px solid #D0161B;
    color: #fff;
  }
  h1{
    font-size: 26px;
    font-weight: 500;
    color: #1D1B1B;
    width: 100%;
    text-align: center;
    padding: 58px 0 43px;
  }
  .step_box{
    width: 1000px;
    height: 600px;
    background: #fff;
    margin: 0 auto 50px;
  }
  .dynamic{
    width:420px;
  }
  .noLimit{
    padding-right: 91px;
  }
  .noelInputBorder{
    padding-right: 66px;
  }
  ul{
    width:760px;
    margin: 0 auto;
    padding: 60px 0 0;
    height: 40px;
    li{
      float: left;
      display: flex;
      align-items: center;
      span{
        float: left;
      }
      .act{
        width: 44px;
        height: 44px;
        background: #FFF;
        border: 1px solid #B0B0B0;
        font-size: 16px;
        color: #1D1B1B;
        display: block;
        border-radius: 44px;
        text-align: center;
        line-height: 44px;
      }
      .act.act_hov{
        background: #D0161B;
        border: 1px solid #D0161B;
        color: #fff;
      }
      .txt{
        font-size: 14px;
        color: #B0B0B0;
        display: block;
        // line-height: 44px;
        width: 85px;
        margin: 0 4px 0 6px;
        font-weight: 500;
      }
      .txt.txt_hov{
        color: #1D1B1B;
      }
      .bod{
        width: 60px;
        display: block;
        height: 1px;
        opacity: 1;
        border-top: 1px dashed #B0B0B0;
        margin-right: 4px;
        // margin-top: 21px;
      }

    }
    li:nth-child(4) .bod{
      width: 0;
      display: none;
    }
  }
  .step{
    width: 500px;
    margin: 90px auto 0;
    .btn{
      text-align: center;
      margin-top: 60px;
    }
    h2{
      font-size: 14px;
      font-weight: bold;
      color: #1D1B1B;
      margin-bottom:30px;
    }
    .qtion{
      position: relative;
      /deep/.el-input__inner{
        padding-right: 86px;
      }
      span{
        position: absolute;
        right: 10px;
      }
    }
  }
  /deep/ .step3 .el-form-item--small.el-form-item{
    margin-bottom: 34px;
  }
  .step4{
    text-align: center;
    margin-top: 80px;
    h2{
      margin:30px auto 20px;
    }
    .succ_ok{
        font-size: 52px;
        color: #33C472;
      }
    .success{
      font-size: 14px;
      text-align: center;
      span{
        color: #D0161B;
      }
    }
    .btn{
      margin-top: 60px;
      .el-button--small{
        width: 336px;
        font-size: 14px;
      }
    }
  }

  .codeImg{
    width: 342px;
    height: 260px;
    margin: 32px auto 0;
  }

  .wtbox /deep/.el-input--small .el-input__inner {
    padding-right: 95px;
  }
  .wtbox {
    position: relative;
  }
  .wt_nums,
  .wt_back {
    position: absolute;
    right: 2px;
    bottom: 7px;
  }
  .wt_back {
    cursor: pointer;
  }
  .wt_nums {
    right: 35px;
    bottom: 0;
  }
}
 /deep/.pswError.el-form-item--small .el-form-item__error{
        width: 408px;
    }
</style>
