<template>
  <div
    ref="login_warp"
    class="login_warp"
  >
    <div
      class="herder"
    >
      <div class="hdbox">
        <p>
          <a
            class="help"
            :href="toLink('help_link1')"
            :title="help_txt_title1"
            target="_blank"
          >{{ help_txt1 }}</a>
          <a
            class="help"
            :title="help_txt_title2"
            :href="toLink('help_link2')"
            target="_blank"
          >{{ help_txt2 }}</a>
          <!-- 分支后注释多语言入口 -->
          <a class="lang">
            <el-popover
              v-model="showlang"
              placement="bottom"
              popper-class="langclss"
              trigger="click"
            >
              <div
                v-for="item in langs"
                :key="item.id"
                class="el-select-dropdown__item"
                style="text-align:center;padding:0 15px;font-size:12px"
                @click="chanlang(item)"
              >{{ item.label }}</div>
              <span slot="reference">{{ langref[setlang] }}<i class="el-icon-arrow-down" /></span>
            </el-popover>

          </a>
        </p>
        <img
          :src="imgsrc"
          alt="logo"
        >
      </div>
    </div>
    <div class="login_main">
      <div
        v-show="boxPosition"
        class="login_div"
        :class="div_position()"
      >
        <p
          ref="logTop"
          class="log_top"
        >
          <span @click="tabState('user')">{{ $t('message.login.userlogin') }}</span>
          <span
            v-show="isAdminShow"
            @click="tabState('admin')"
          >{{ $t('message.login.admlogin') }}</span>
        </p>
        <div v-show="!isCodeImg">
          <!-- 二维码 -->
          <div
            v-show="showLog == 'ewm'"
            id="ewm"
          ><img
            src="@/assets/icons/login/l_ewm.png"
            alt=""
          ></div>
          <!-- 手机号 -->
          <div
            v-show="showLog == 'phone'"
            id="phone"
          >
            <!-- <input
              id="username"
              v-model="phone"
              :placeholder="phone_txt"
              type="text"
              autocomplete="off"
            > -->
            <p class="userlogo">
              <!-- <img
                class="rx"
                src="../../assets/icons/login/l_user.png"
              > -->
              <svg-icon
                class="rx"
                icon-class="user_login"
              />
              <input
                id="username"
                v-model="phone"
                :placeholder="$t('message.login.placeholder3')"
                type="text"
                autocomplete="off"
              >
            </p>
            <p class="showPass">
              <!-- <img
                class="xyj"
                src="../../assets/icons/login/l_password.png"
              > -->
              <svg-icon
                class="xyj"
                icon-class="login_password"
              />
              <input
                id="password"
                v-model="phone_code"
                :placeholder="$t('message.login.placeholder4')"
                type="text"
              >
            </p>
            <p
              ref="checkBack"
              class="ischeck"
            >
              <span><input
                v-show="isSsl"
                v-model="isCheckPhoneSsl"
                true-label="true"
                false-label="false"
                type="checkbox"
              >{{ $t('message.login.ssllogin') }}</span><span><input
                v-show="isUser"
                v-model="isCheckPhoneUser"
                type="checkbox"
              >{{ $t('message.login.remmberaccount') }}</span>
            </p>
            <p
              ref="logBtn1"
              class="log-btn"
              @click="phoneLogin"
            >{{ $t('message.login.btnText') }}</p>
          </div>
          <!-- ukey -->
          <div
            v-show="showLog == 'ukey'"
            id="ukey"
          >
            <!-- <input
              id="username"
              v-model="pin"
              placeholder="请输入PIN码"
              type="text"
              autocomplete="off"
            > -->
            <p class="userlogo">
              <!-- <img
                class="rx"
                src="../../assets/icons/login/l_user.png"
              > -->
              <svg-icon
                class="rx"
                icon-class="user_login"
              />
              <input
                id="username"
                v-model="pin"
                :placeholder="$t('message.login.placeholder5')"
                type="text"
                autocomplete="off"
              >
            </p>
            <p class="ischeck">
              <span><input
                v-show="isSsl"
                v-model="isCheckUkeySsl"
                true-label="true"
                false-label="false"
                type="checkbox"
              >{{ $t('message.login.ssllogin') }}</span>
              <span class="clear" /></p>
            <p
              ref="logBtn2"
              class="log-btn"
              @click="ukeyLogin"
            >{{ $t('message.login.btnText') }}</p></div>
          <!-- 用户登录 -->
          <div
            v-show="showLog == 'password'"
            class="default_login"
          >
            <p class="userlogo">
              <!-- <img
                class="rx"
                src="../../assets/icons/login/l_user.png"
              > -->
              <svg-icon
                class="rx"
                icon-class="user_login"
              />
              <input
                id="username"
                v-model="loginForm.username"
                :placeholder="$t('message.login.placeholder1')"
                type="text"
                autocomplete="off"
              >
            </p>
            <!-- <p
              v-if="!eyes"
              class="showPass"
            >
              <input
                id="password"
                v-model="pwdCover"
                :placeholder="pass_txt"
                autocomplete="off"
                type="text"
                maxlength="32"
                @keydown="handlerKeydown"
                @input="setPassword"
                @keyup.enter="login"
              >
              <span @click="showPwd(true)"><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
            </p>
            <p
              v-else
              class="showPass"
            >
              <input
                id="password"
                v-model="loginForm.password"
                :placeholder="pass_txt"
                autocomplete="off"
                type="text"
                maxlength="32"
                @keyup.enter="login"
              >
              <span @click="showPwd(false)"><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p> -->

            <p class="showPass">
              <!-- <img
                class="xyj"
                src="../../assets/icons/login/l_password.png"
              > -->
              <svg-icon
                class="xyj"
                icon-class="login_password"
              />
              <input
                id="password"
                v-model="loginForm.password"
                :placeholder="$t('message.login.placeholder2')"
                autocomplete="off"
                :type="inputType"
                maxlength="32"
                @keyup.enter="login"
              >
              <span
                v-if="!eyes"
                @click="showPwd(true)"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false)"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
            <p class="ischeck">
              <span
                v-show="isUser"
              >
                <el-checkbox
                  v-model="isCheckUser"
                  text-color="red"
                >{{ $t('message.login.remmberusername') }}</el-checkbox>
              <!-- <input
                  v-model="isCheckUser"
                  type="checkbox"
                >记住用户名 -->
              </span>
              <span
                v-show="isSsl"
              >

                <el-checkbox
                  v-model="isCheckSsl"
                  true-label="true"
                  false-label="false"
                  :disabled="isDisable"
                  @change="sslCli"
                >{{ $t('message.login.ssllogin') }}</el-checkbox>
              <!-- <input
                  v-model="isCheckSsl"
                  type="checkbox"
                >ssl登录 -->
              </span>
            </p>
            <p
              ref="logBtn3"
              class="log-btn"
              @click="login"
            >{{ $t(`message.login.${btntxt}`) }}</p>
          </div>
        </div>
        <!-- 验证码 -->
        <div
          v-if="isCodeImg"
          class="codeImg"
        >
          <VerificationCode
            ref="child"
            @finishVerify="finishVerify"
          />
        </div>
        <p
          class="err_msg"
          v-html="err_msg_txt"
        />
        <span
          v-show="forget&&showLog == 'password'"
          class="forgotpsw cur"
          @click="toForget"
        >{{ $t('message.login.forgetpsw') }}?</span>
        <p
          v-show="moudle && !isCodeImg "
          class="threeLog"
          :style="{left:forget&&showLog == 'password'?'':'50%'}"
        >
          <img
            v-for="i in threeLog"
            v-show="showLog !== i.id"
            :key="i.id"
            :src="i.src"
            :alt="i.id"
            @click="threeClick(i.id)"
          >
        </p>
      </div>
    </div>
    <div class="footer"><p>{{ copyright }}</p></div>
  </div>
</template>
<script>
import l_pws from '@/assets/icons/login/l_pws.png'
import l_phone from '@/assets/icons/login/l_phone.png'
import l_ewm from '@/assets/icons/login/l_ewm.png'
import l_ukey from '@/assets/icons/login/l_ukey.png'

import { addCookie, getCookie, delCookie } from '@/utils/cookie'
import { PasswordSm4 } from '@/utils/changePassword'
// import { PassWord, PwdCover, PasswordSm4 } from '@/utils/changePassword'
// import { sessionVuexClear } from '@/utils/InitializePage'
import { isFirstLogin } from './login'
import { isBLANK } from '@/utils/utils'
import VerificationCode from '@/views/components/code.vue'
// import serialize from '@/utils/serialize'
// import themeSet from '@/theme/theme-set.js'
export default {
  name: 'Login',
  components: {
    VerificationCode,
  },
  // mixins: [themeSet],
  data() {
    return {
      moudle: true, // 控制第三方登录是否显示
      userType: 1, // 1 用户登录 2管理员登录
      btntxt: 'btnText',
      activeName: 'first',
      eyes: false, // 是否查看密码
      forget: true,
      user_txt: this.$t('message.login.placeholder1'),
      pass_txt: this.$t('message.login.placeholder2'),
      phone_txt: this.$t('message.login.placeholder3'),
      phone_code_txt: this.$t('message.login.placeholder4'),
      loginForm: {
        username: '',
        password: '',
      },
      pwdCover: '', // 密码框替换的值
      phone: '',
      phone_code: '',
      pin: '',
      isCheckPhoneUser: false, // 手机登录是否勾选记住用户名
      isCheckPhoneSsl: false, // 手机登录是否勾选ssl登陆
      isCheckUser: false, // 用户登录是否勾选记住用户名
      isCheckSsl: 'false', // 用户登录是否勾选ssl登陆
      isCheckUkeySsl: false, // ukey是否勾选ssl登陆
      err_msg: false, // 错误信息是否展示
      err_msg_txt: '', // 错误信息
      cursorStart: 0, // input 弹起位置
      cursorEnd: 0, // input 弹起位置
      // threeLog: [{
      //   id: 'password',
      //   src: l_pws,
      // }, {
      //   id: 'phone',
      //   src: l_phone,
      // }, {
      //   id: 'ewm',
      //   src: l_ewm,
      // }, {
      //   id: 'ukey',
      //   src: l_ukey,
      // }],
      threeLog: [],
      inputCode: '', // 验证码
      token: '', // 登录验证码时需要
      isCodeImg: false, // 是否显示验证码
      isAdminShow: true, // 管理员登录是否存在
      imgsrc: '', // 需要后端返回logo图片
      div_pos: 'right', // 需要后端返回登录框的位置
      back_color: '', // 需要后端返回主题颜色
      txt_color: '', // 需要后端返回文字颜色
      isForget: true, // 是否显示忘记密码
      isUser: true, // 是否显示记住用户名
      isSsl: true, // 是否显示ssl登陆
      isDisable: false, // 是否强制ssl登录
      showLog: 'password', // 当前显示的是什么登录
      help_txt1: '', // 帮助文字1
      help_txt_title1: '', // 帮助文字1
      help_link1: '', // 帮助链接1
      help_txt_title2: '', // 帮助文字1
      help_txt2: '', // 帮助文字2
      help_link2: '', // 帮助链接2
      copyright: '', // 版权信息
      pwdCoverss: '',
      passwordcc: '',
      isShowPassword: false,
      intervalFlag: '', // 定时器
      inputType: 'password',
      showlang: false,
      langs: [
        {
          id: 1,
          label: '简体中文',
          val: 'simplified_chinese',
        },
        {
          id: 2,
          label: '繁体中文',
          val: 'traditional_chinese',
        },
        {
          id: 3,
          label: 'English',
          val: 'english',
        },
      ],
      langref: {
        'simplified_chinese': '简体中文',
        'traditional_chinese': '繁体中文',
        'english': 'English',
      },
      setlang: 'simplified_chinese',
      boxPosition: false,
    }
  },
  created() {
    // 本登录界面以后都由 ?redirect 转来
    // this.$store.commit('app/IS_LOCK_SETTING', false) // 解除锁屏状态
    // if (this.$route.query.isAudit === 'true') {
    //   this.$store.commit('mail/AUDIT_ROUTE_QUERY', {
    //     isAudit: this.$route.query.isAudit,
    //     nsnode: this.$route.query.nsnode,
    //     token: this.$route.query.token,
    //   })
    // } else {
    //   sessionVuexClear() // 清空vuex会话缓存
    // } ------路由已废弃
    // sessionVuexClear() // ------路由已废弃 应该使用上面的判断
  },
  mounted() {
    this.$nextTick(() => {
      this.get_login_setting()
      // 密码sm4加密
      // const a = new PasswordSm4('user', 'password').toValue()
      // window.isphone('bbb') // 外用js
      if (window.location.protocol === 'https:') {
        this.isCheckSsl = 'true'
      }
      if (getCookie('isCheckSsl') === 'true') {
        location.href = 'https' + window.location.href.slice(4)
      }
      // 兼容ie
      this.$refs.logTop.children[0].style.borderBottom = '2px solid #EAEAEA'
      this.$refs.logTop.children[1].style.borderBottom = 'none'
    })
  },
  methods: {
    chanlang(item) {
      this.$i18n.locale = item.val
      localStorage.setItem('lang', item.val)
      this.setlang = item.val
      this.showlang = false
    },
    // 输入框输入事件
    // setPasswordc(val) {
    //   if (this.isShowPassword) {
    //     this.passwordcc = val
    //   }
    //   const p = new AllPassword(val, this.passwordcc, this.pwdCoverss).toValue()
    //   this.passwordcc = p[1]
    //   this.pwdCoverss = p[0]
    // },
    // 点击右侧小眼睛控制显示隐藏
    // hidePasswordc() {
    //   if (!this.isShowPassword) {
    //     this.isShowPassword = true
    //     this.pwdCoverss = this.passwordcc
    //   } else {
    //     this.isShowPassword = false
    //     this.pwdCoverss = this.pwdCoverss.replace(/\S/g, '●')
    //   }
    // },
    // 登录的逻辑
    userLogin() {
      if (this.account === 'admin' && this.password === 'admin') {
        this.loading = true
        setTimeout(() => {
          // this.$router.push({ path: '/main' })
          this.$myApi.topGoto('home')
        }, 600)
        this.$message.success('登录成功')
      }
    },
    // 是否点击了ssl
    sslCli(val) {
      if (val === 'true' && window.location.protocol === 'http:') {
        location.href = 'https' + window.location.href.slice(4)
        addCookie('isCheckSsl', 'true')
      }
      if (val === 'false' && window.location.protocol === 'https:') {
        location.href = 'http' + window.location.href.slice(5)
        // addCookie('isCheckSsl', 'false')
        delCookie('isCheckSsl', null, -1)
      }
    },
    // tab切换
    tabState(state) {
      this.loginForm.password = ''
      this.eyes = false
      this.pwdCover = ''
      // user 账户登录 admin 管理员登录
      this.userType = state === 'user' ? 1 : 2
      // this.user_txt = state === 'user' ? '请输入邮箱账号' : '请输入PIN码'
      if (this.isForget) {
        state === 'user' ? this.forget = true : this.forget = false
      } else {
        this.forget = false
      }
      if (this.userType === 1) {
        if (this.showLog === 'password') {
          this.isCheckUser = false
          this.loginForm.username = ''
          if (getCookie('isCheckUserName')) {
            this.loginForm.username = getCookie('userName')
            this.isCheckUser = true
          }
        }
        // 兼容ie
        this.$refs.logTop.children[0].style.borderBottom = '2px solid #EAEAEA'
        this.$refs.logTop.children[0].style.borderBottom = '2px solid' + this.back_color
        this.$refs.logTop.children[1].style.borderBottom = 'none'
        this.moudle = true
        this.btntxt = 'btnText'
      } else {
        this.isCheckUser = false
        this.loginForm.username = ''
        // 兼容ie
        this.$refs.logTop.children[1].style.borderBottom = '2px solid #EAEAEA'
        this.$refs.logTop.children[1].style.borderBottom = '2px solid' + this.back_color
        this.$refs.logTop.children[0].style.borderBottom = 'none'
        this.showLog = this.threeLog[0].id
        let flag = false
        if (this.userType === 2) flag = true
        else if (this.userType === 1 && this.showLog === 'password') flag = true
        else flag = false
        this.$store.commit('app/IS_LOCK_SHOW_SETTING', flag)
        if (getCookie('isCheckAdminName')) {
          this.loginForm.username = getCookie('adminName')
          this.isCheckUser = true
        }
        if (this.$myData.uriDatas.adminType) {
          this.loginForm.username = this.$myData.uriDatas.adminName.toLowerCase()
          this.isCheckUser = false
        }
        this.moudle = false
        this.btntxt = 'admlogin'
      }
    },
    // 验证码刷新
    finishVerify(code) {
      console.log(code, 'code')
      this.inputCode = code[0]
      this.token = code[1]
      this.$refs.logBtn1.style.background = this.back_color // 按钮背景色
      this.$refs.logBtn1.style.color = this.txt_color // 按钮背景色
      this.login()
    },
    /** 判断是否非本人登录，清空浏览器缓存及cookie */
    StorageClear() {
      const lang = localStorage.getItem('lang')
      let userAllmail_alias = localStorage.getItem('userAllmail_alias')
      if (userAllmail_alias != null) {
        if (!userAllmail_alias && userAllmail_alias !== '[]') userAllmail_alias = JSON.parse(userAllmail_alias)
        if (userAllmail_alias.indexOf(this.loginForm.username.toLowerCase()) < 0) {
          localStorage.clear()
          localStorage.setItem('lang', lang)
        }
      }
    },
    // 用户登录
    login() {
      // this.isCheckUser 是否记住用户名
      // this.isCheckSsl 是否ssl登录
      this.StorageClear()
      const req = {
        module: 'login',
        reqire: {
          mail: this.loginForm.username.trim().toLowerCase(),
          password: new PasswordSm4(this.loginForm.username.toLowerCase(), this.loginForm.password).toValue(),
          code: new PasswordSm4(this.loginForm.username.toLowerCase(), this.inputCode).toValue(),
          user_type: this.userType,
          ssl: this.isCheckSsl ? 1 : 0,
          token: this.token,
          show_language: localStorage.getItem('lang'),
        },
      }
      this.$axios.post(this.$apiData.login, { req: btoa(JSON.stringify(req)), req_type: 'json', req_crypt: '1' })
        .then(res => {
          this.err_msg_txt = ''
          if (res.status === 200) {
            let flag = false
            if (this.userType === 2) flag = true
            else if (this.userType === 1 && this.showLog === 'password') flag = true
            else flag = false
            this.$store.commit('app/IS_LOCK_SHOW_SETTING', flag) // 是否显示锁屏
            if (res.result.need_code) {
              // 出现验证码
              this.isCodeImg = true
              this.$nextTick(() => {
                this.$refs.child.img()
              })
              return false
            }
            // get_pass_policy()
            if (this.userType !== 2) {
              this.isCheckUser ? addCookie('userName', this.loginForm.username.trim().toLowerCase(), 24 * 30) : delCookie('userName', null, -1)
              this.isCheckUser ? addCookie('isCheckUserName', true, 24 * 30) : delCookie('isCheckUserName', null, -1)
            } else {
              this.isCheckUser ? addCookie('adminName', this.loginForm.username.trim().toLowerCase(), 24 * 30) : delCookie('adminName', null, -1)
              this.isCheckUser ? addCookie('isCheckAdminName', true, 24 * 30) : delCookie('isCheckAdminName', null, -1)
              delCookie('ci_session', null, -1) // 清除管理端的cookie
              location.href = res.result.url// 管理员登录
            }
            this.isCheckSsl === 'true' ? addCookie('isCheckSsl', 'true') : delCookie('isCheckSsl', null, -1)
            const userInfo = {
              user_mail: this.loginForm.username.toLowerCase(),
              user_pwd: new PasswordSm4(this.loginForm.username.toLowerCase(), this.loginForm.password).toValue(),
              user_type: this.userType === 2 ? 'admin' : this.showLog, // 登录方式
            }
            this.$store.commit('app/USERINFO', userInfo)
            // this.isFirstLogin()
            // const role = this.$route.query.isAudit ? 'viewAudit' : ''-----路由已废弃
            const role = '' // -----路由已废弃 应该使用上面的判断
            const type = this.userType === 2 ? 'admin' : this.showLog // ukey表示ukey登录 默认是password

            isFirstLogin(this.loginForm.username.toLowerCase(), role, type)
            this.err_msg_txt = ''
            // 检测是否是同一个账户
            // check_cookie_is_changed()

            if (window._ky_login_checker) {
              clearInterval(window._ky_login_checker)
            }
            this.$myData.current_user_code = getCookie('user_code')
            console.log('goto', '000', this.$myData.current_user_code)
            if (isBLANK(this.$myData.current_user_code)) {
              this.$myData.current_user_code = ''
            }
            window._ky_login_checker = setInterval(() => {
              let new_c = getCookie('user_code')
              if (isBLANK(new_c)) {
                new_c = ''
              }
              if (this.$myData.current_user_code !== new_c) {
                clearInterval(window._ky_login_checker)
                window._ky_login_checker = null
                this.$myApi.topGoto('login')
              }
            }, 1000)
          } else if (res.status === 2020) {
            // 后台需要
            this.login()
          } else if (res.status === 20020) {
            // 出现验证码
            console.log('lplp')
            this.isCodeImg = true
            this.$nextTick(() => {
              this.$refs.child.img()
            })
            this.err_msg_txt = res.msg
          } else {
            this.isCodeImg = false
            this.err_msg_txt = res.msg
          }
        })
    },
    // 检测是否是首次登录
    // isFirstLogin() {
    //   const req = {
    //     login_type: this.userType === 2 ? 'admin' : this.showLog, // ukey表示ukey登录 默认是password
    //     username: this.loginForm.username, // 邮箱地址
    //   }
    //   this.$axios.post(this.$apiData.checklogin, { req: serialize(req) }).then(res => {
    //     // 1首次登录 0 不是首次

    //     this.$store.commit('app/IS_FIRST_LOGIN', res.result.need_edit) // 是否首次登录
    //     if (this.$route.query.isAudit) {
    //       loginInit('viewAudit')
    //     } else {
    //       loginInit()
    //     }
    //   })
    // },

    // handlerKeydown(event) {
    //   const evt = event || window.event
    //   this.cursorStart = evt.target.selectionStart
    //   this.cursorEnd = evt.target.selectionEnd
    // },
    // 用户登录密码框提换值
    // setPassword(event) {
    //   const evt = event || window.event
    //   this.pwdCover.length === 0 ? this.loginForm.password = '' : ''
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(evt.target.value)) {
    //     this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover = this.pwdCover.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     // this.pwdCover = val.replace(/\S/g, '●')
    //     const b = new PassWord(evt, this.pwdCover, this.loginForm.password, this.cursorStart, this.cursorEnd)
    //     this.pwdCover = b.toValue()[0]
    //     this.loginForm.password = b.toValue()[1]
    //   }
    // },
    // // 用户登录密码框提换值
    // setPwdCover(event) {
    //   const evt = event || window.event
    //   // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(evt.target.value)) {
    //     this.err_msg_txt = '禁止输入中文'
    //     this.loginForm.password = this.loginForm.password.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover, this.loginForm.password)
    //     this.pwdCover = a.toValue()
    //   }
    // },
    // 显示密码
    showPwd(status) {
      status ? this.eyes = true : this.eyes = false
      this.inputType = status ? 'text' : 'password'
      // this.pwdCover = this.pwdCover.slice(0, this.loginForm.password.length)
    },
    // 手机号登录
    phoneLogin() {
      const status = window.isphone(this.phone, this.phone_code, this.isCheckPhoneUser, this.isCheckPhoneSsl)
      console.log(status)
    },
    // ukey登录
    ukeyLogin() {
      const status = window.isukey(this.pin, this.isCheckUkeySsl)
      console.log(status)
    },
    // 定制--登录位置
    div_position() {
      const a = this.div_pos
      const b = {
        'left': 'login_div_left',
        'center': 'login_div_center',
        'right': '',
      }
      return b[a]
    },
    // 定制--背景颜色
    backColor() {
      this.$myData.uriDatas.adminType === 'true' ? this.tabState('admin') : this.tabState('user') // 默认用户登录
      // this.back_color // 后端返回的颜色样式
      if ((this.showLog === 'password' || this.showLog === 'phone' || this.showLog === 'ukey') || !this.moudle) {
        this.$nextTick(() => {
          this.$refs.logBtn1.style.background = this.back_color // 按钮背景色
          this.$refs.logBtn1.style.color = this.txt_color // 按钮背景色
          this.$refs.logBtn2.style.background = this.back_color // 按钮背景色
          this.$refs.logBtn2.style.color = this.txt_color // 按钮背景色
          this.$refs.logBtn3.style.background = this.back_color // 按钮背景色
          this.$refs.logBtn3.style.color = this.txt_color // 按钮背景色
        })
      }
    },
    threeClick(id) {
      this.showLog = id
      this.err_msg_txt = ''
      this.backColor()
      // 显示第三方登录 //手机是phone 二维码是 code ukey是ukey
      if (id === 'phone') {
        window.isphone()
      }
    },
    // 跳转到忘记密码
    toForget() {
      // this.$router.push('/forgetPassword')
      // this.$myApi.topGoto('forgetPassword')
      const href = decodeURI(location.href)
      const hrefArr = href.split('#', 2)
      const openUrl = hrefArr[0] + '#forgetPassword'
      window.open(openUrl)
    },
    toLink(link) {
      const url = link === 'help_link1' ? this.help_link1 : this.help_link2
      // location.href = url
      return url
    },
    // 获取登录设置
    get_login_setting() {
      this.$axios.get(this.$apiData.get_login_setting).then(res => {
        if (res.status === 200) {
          const data = res.result
          /** 存储系统背景图、logo、版权信息，锁屏、header等使用 */
          // 缓存自定义登录页信息，系统背景图、logo、版权信息，锁屏、header等使用 */
          this.$store.commit('app/LOGIN_SETINFO_SETTING', {
            login_background_url: data.login_background,
            login_logo: data.login_logo,
            copyright: data.copyright,
          })
          // if (localStorage.getItem('lang')) {
          //   this.setlang = localStorage.getItem('lang')
          //   this.$i18n.locale = this.setlang
          // } else {
          localStorage.setItem('lang', data.show_language)
          this.setlang = data.show_language
          this.$i18n.locale = this.setlang
          // }

          this.help_txt1 = data.text_1.length > 8 ? data.text_1.slice(0, 8) + '...' : data.text_1
          this.help_txt_title1 = data.text_1
          this.help_link1 = data.text_1_link
          this.help_txt2 = data.text_2.length > 8 ? data.text_2.slice(0, 8) + '...' : data.text_2
          this.help_txt_title2 = data.text_2
          this.help_link2 = data.text_2_link
          this.div_pos = data.login_position
          this.boxPosition = true
          this.back_color = data.login_btn_color
          // console.log(data.login_btn_color, 'data.login_btn_color')
          this.txt_color = data.login_btn_text_color
          this.isForget = !!data.find_password
          this.isUser = !!data.remember_password
          if (data.ssl_show) {
            this.isSsl = data.ssl_show
            this.isCheckSsl = getCookie('isCheckSsl') === 'true' ? 'true' : 'false'
          } else {
            this.isSsl = !!data.ssl_show
          }
          // this.isSsl = !!data.ssl_show
          this.imgsrc = data.login_logo
          this.copyright = data.copyright
          this.isAdminShow = !!data.admin_login_enter
          if (data.force_https) {
            this.isCheckSsl = 'true'
            this.isDisable = true
            location.href = 'https' + window.location.href.slice(4)
          }
          if (this.$refs.login_warp && this.$refs.login_warp.style) {
            this.$refs.login_warp.style.background = "url('" + data.login_background + "') no-repeat top center"
            this.$refs.login_warp.style.backgroundSize = '100% 100%'
          }
          document.title = data.web_title
          // data.protection_type// 登录方式 1.用户名+口令 2.ukey 3.生理特征、二维码  4.手机号登录
          const arr = [1, 2, 3, 4]
          const loginList = [{
            id: 'password',
            src: l_pws,
          }, {
            id: 'ukey',
            src: l_ukey,
          }, {
            id: 'ewm',
            src: l_ewm,
          }, {
            id: 'phone',
            src: l_phone,
          }]
          const login_type = data.protection_type
          arr.map((el, index) => {
            if (login_type.indexOf(el) !== -1) {
              this.threeLog.push(loginList[el - 1])
            }
          })
          this.showLog = this.threeLog[0].id
          this.backColor()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
input[type="password"] {
  letter-spacing: 0;
  font-size: 20px;
}
input[type="password"]::-webkit-input-placeholder {
  letter-spacing: 0;
  font-size: 14px;
}
input[type="password"]:-moz-placeholder {
  letter-spacing: 0;
  font-size: 14px;
}
input[type="password"]::-moz-placeholder {
  letter-spacing: 0;
  font-size: 14px;
}
input[type="password"]:-ms-input-placeholder {
  letter-spacing: 0;
  font-size: 14px;
}

input {
  background-color:transparent;
  // caret-color: $cursor;

  &:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {

    // background: url(../../assets/icons/login/l_password.png) 20px 11px
    //     no-repeat #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color:transparent;
    transition: background-color 50000s ease-in-out 0s; //背景色透明 生效时长 过渡效果 启用时延迟的时间
  }
}
.login_warp {
  // background: url('../../assets/icons/login/bj1.jpg') no-repeat top center;
  // background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  font-size: 14px;
  overflow-y: auto;
  input::-ms-clear{display:none;}/*删除文本框中的叉号*/
  input::-ms-reveal{display:none;} /*删除密码框中的小眼睛*/

  .login-svg{
    width: 1.3em;
    height: 1.3em;
    margin-right: 6px;
  }
  .clear{
    clear: both;
  }
  .login_main {
    width: 1200px;
    margin: 0 auto;
  }
  .herder {
    width: 100%;
    height: 84px;
    line-height: 84px;
    background: #fff;
    .hdbox{
      width: 1200px;
      margin: 0 auto;
      a,a:hover{
        color: #1D1B1B;
        font-weight: bold;
      }
    }
    img{
      height: 80px;
      vertical-align: middle;
    }

    p{
      float:right;
      padding: 0 20px 0 0;
      .help{
        cursor: pointer;
        font-size:14px;
      }
      .help:first-child{
        margin-right: 60px;
      }
      .help:not(:last-child){
         margin-right: 60px;
      }
    }
     .lang{
      position: relative;
      border:1px solid #d7d7d7;
      border-radius: 1px;
      padding: 5px 10px;
      font-weight: normal;
      .list{
        position: absolute;
        bottom: 0;
      }
    }
  }
  .login_div {
    width: 440px;
    // height: 440px;
    padding: 0 0 40px 0;
    background: #fff;
    border-radius: 7px 7px 7px 7px;
    margin: 10vh 0 150px 757px;
    clear: both;
    position: relative;
    box-shadow: 0px 1px 4px 1px #dedddd;
    input{ outline: none; }
    .log_top {
      width: 100%;
      height: 64px;
      line-height: 64px;
      border-bottom: 1px solid #d7d7d7;
      display: flex;
      span {
        flex: 1;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        font-size:14px;
      }
      span.state {
        border-bottom: 3px solid #d0161b;
      }
    }
    #username,
    #password {
      width: 336px;
      height: 42px;
      background: #fff;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #d7d7d7;
      padding: 0 30px 0 48px;
      font-size:14px;
    }
    .showPass{
      position: relative;
      width: 336px;
      height: 42px;
      margin: 0 52px 16px;
      input#password{
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
      }
      span{
        position: absolute;
        right: 5px;
        bottom:10px;
        cursor: pointer;
      }
    }
    #ewm img{
      width: 221px;
      height: 216px;
      margin: 21px auto 0;
      border: 1px solid red;
      display: block;
    }
    .userlogo{

      margin: 33px 52px 24px;
      position: relative;
    }
    .rx{
      position:absolute;
      top: 10px;
      left: 14px;
    }
    #username {
      // background: url(../../assets/icons/login/l_user.png) 14px 10px no-repeat
      //   #fff;
      background: #fff;
    }
    #password {
      margin: 0 52px 16px;
      // background: url(../../assets/icons/login/l_password.png) 20px 11px
      //   no-repeat #fff;
      background:  #fff;
    }
    .xyj{
      position:absolute;
      top: 11px;
      left: 14px;
      z-index: 1;
    }
    .err_msg{
      padding: 10px 52px 0;
      margin: 0;
      //height: 30px;
      color: #d0161b;
    }
    .ischeck {
      margin-bottom: 30px;
      padding: 0 52px;
      font-size: 14px;
      height: 1px;
      cursor: pointer;
      input {
        vertical-align: middle;
        margin-right: 6px;
      }
      span:nth-child(2) {
        margin-right: 24px;
      }
      span:first-child {
        float: right;
      }
    }
    .log-btn {
      margin: 34px auto 0;
      width: 336px;
      height: 46px;
      line-height: 46px;
      background: #d0161b;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
    }
    .threeLog{
      position: absolute;
      bottom: 15px;
      left: 32%;
      transform: translate(-50%,15px);
      img{
        margin:0 12px;
        cursor: pointer;
      }
    }
    .forgotpsw{
      position: absolute;
      bottom: 22px;
      right: 12%;
      font-size: 14px;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner{
      border-color: #409EFF;
      background-color: #409EFF;
    }
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
      color: #409EFF;
    }
  }
  .login_div_left{
    margin: 166px 0 100px 0;
  }
  .login_div_center{
    margin: 166px auto 100px;
  }
  .footer {
    width: 100%;
    background: #fff;
    font-size: 14px;
    position: fixed;
    bottom: 0;
    box-shadow: 0px 1px 4px 1px #dedddd;
      padding: 20px 0;
    p{
      width: 70%;
      line-height: 22px;
      margin: 0 auto;
      text-align:center;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 超出几行省略 */
      overflow: hidden;

    }
  }
  .codeImg{
    width: 336px;
    height: 260px;
    margin: 32px auto 0;
  }
  //修改选择框的颜色
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #d0161b!important;
      background-color: #d0161b!important;
    }
    /deep/.el-checkbox__input.is-focus .el-checkbox__inner{
      border-color:  #d0161b!important;
    }
    //修改选中后文本的颜色
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label{
    color:#d0161b!important;
}

}

</style>
<style>
.langclss{
  padding: 0!important;
  min-width: 130px!important;
}
</style>
