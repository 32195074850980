<template>
  <el-dialog
    :title="$t('message.view.receipt_tip')"
    :visible.sync="dialogReceipt"
    width="520px"
    :before-close="handleReceiptClose"
    append-to-body
    :modal-append-to-body="false"
  >
    <span>{{ $t('message.view.is_send_receipt') }}</span>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        @click="submit('')"
      >{{ $t('message.btnText.ok') }}</el-button>
      <el-button @click="submit('none')">{{ $t('message.btnText.cancel') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>

import serialize from '@/utils/serialize'
import { transformClass } from '@/utils/utils.js'
export default {
  filters: {

  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    mailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    body: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dialogReceipt: this.isShow && this.$parent.reply_receipt === 'hand',
      newMailInfo: this.mailInfo,
      bodyHtml: this.body,
    }
  },
  watch: {
    isShow: function(a, b) {
      this.dialogReceipt = a && this.$parent.reply_receipt === 'hand'
    },
    body: function(a, b) {
      this.bodyHtml = a

      if (this.bodyHtml.receipt && this.bodyHtml.receipt.length !== 0 && this.bodyHtml.flags.charAt(5) !== '1' && this.bodyHtml.mbox !== 'Sent' && this.$parent.reply_receipt === 'auto') {
        this.submit()
      }
    },
  },
  created() {
  },
  methods: {
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
    // 取消按钮--下次打开读信不在弹出
    // 关闭x按钮 -- 下次出来弹出
    // 确认弹出--下次不在弹出
    submit(type) {
      // this.bodyHtml.to_s
      const to_s = this.bodyHtml.to_s.map(item => {
        return item[1] + ' &lt;' + item[0] + '&gt;'
      })
      const req = {
        'module': 'mail',
        'require': {
          action: 'sendReceipt',
          data: {
            mbox: transformClass.mbox(this.mailInfo.uidvalidity, this.$myData.mbox_list),
            uv: this.newMailInfo.uv,
            uid: this.newMailInfo.uid,
            send_receipt: type, // 如果为 none 则不发送回执信件
            body: `${this.$t('message.view.subject_mail_view_rec')}` + this.bodyHtml.subject + `<p>${this.$t('message.view.nail_view_rec_under')}<p><p>${this.$t('message.view.to')}: <span>` + to_s.toString() + `</span><br />${this.$t('message.view.subject')}: <span cs2c_id="subject">` + this.bodyHtml.subject + `</span><br />${this.$t('message.view.time')}：<span>` + transformClass.timeFormat(this.bodyHtml.envelope_date, 'yyyy年MM月dd日 hm') + `</span><br /></p ><p>` + ` <br /></p >`,
            subject: this.$t('message.view.mail_view_receipt') + ':' + this.bodyHtml.subject, // 回执信的主题
            date: new Date().toString(), // 回执信的日期, toString()
            relevant_id: this.bodyHtml.message_id, // 原信件的 message_id
            to: this.bodyHtml.receipt, // 回执信的收件人, 原信件返回的 receipt 直接赋值
          },
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.$parent.dialogReceipt = false
      })
    },
    // 点击关闭回执
    handleReceiptClose(done) {
      this.$parent.dialogReceipt = false
    },
    time() {
      const dateTime = new Date()
      function format() {
        const year = dateTime.getFullYear()
        const month = dateTime.getMonth() + 1 > 9 ? dateTime.getMonth() + 1 : '0' + (dateTime.getMonth() + 1)
        const day = dateTime.getDate() > 9 ? dateTime.getDate() : '0' + dateTime.getDate()

        const hour = dateTime.getHours() > 9 ? dateTime.getHours() : '0' + dateTime.getHours()
        const minute = dateTime.getMinutes() > 9 ? dateTime.getMinutes() : '0' + dateTime.getMinutes()
        const second = dateTime.getSeconds() > 9 ? dateTime.getSeconds() : '0' + dateTime.getSeconds()
        if (localStorage.getItem('lang') === 'english') {
          return `${year}-${month}-${day}- ${hour}:${minute}:${second}`
        } else {
          return `${year}年${month}月${day}日 ${hour}:${minute}:${second}`
        }
      }

      const current = format()
      return current
    },
  },
}
</script>
