<template>
  <div
    ref="labelBox"
  >
    <div
      class="labelBox"
    >
      <el-checkbox-group
        v-if="tag_list.length"
        v-model="checkedTab"
        class="group gn_border"
        @change="groupChange"
      >
        <el-checkbox
          v-for="(i,index) in tag_list"
          :key="index"
          :label="i.tag_name"
        >

          <svg-icon
            class="bag"
            :icon-class="i.tag_color.slice(1,7)"
          /> <span class="lis one-line-ellipsis">{{ i.tag_name }}</span></el-checkbox>
      </el-checkbox-group>
      <p
        class="new_tabs new_tab gn_border"
        @click="showDid"
      >{{ $t('message.view.add_tag') }}</p>
      <p
        class="new_tabs text-color web"
        @click="changeTab"
      >{{ $t('message.view.confirm') }}</p>
      <!-- <p
        v-show="isDel"
        class="new_tabs"
      >
        <span
          class="all_del"
          @click="delAll_open"
        >移除全部标签</span> <em>|</em>
        <span
          class="all_ok"
          @click="changeTab"
        >确定</span>
      </p> -->
    </div>

    <el-dialog
      :modal-append-to-body="false"
      :title=" $t('message.view.add_tag')"
      :visible.sync="newAdd"
      width="430px"
      :before-close="
        hideDid"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="0"
        class="demo-ruleForm"
      >
        <el-form-item
          label=""
          prop="newTxt"
        >
          <!-- <el-input v-model="ruleForm.name" /> -->
          <p class="inpf">
            <el-input
              v-model="ruleForm.newTxt"
              :placeholder="$t('message.view.input_tag')"
              maxlength="25"
            />
            <span>{{ ruleForm.newTxt.length }}/25</span>
          </p>
        </el-form-item>
      </el-form>

      <p>{{ $t('message.view.tag_color') }}
        <span class="tp_pos mb middle">

          <em class="tp_bg" />
          <svg-icon
            class="tp"
            :icon-class="mback"
          />
        </span>
      </p>
      <span
        v-show="mback == ''"
        class=" msgError"
      >{{ mbackMsg }}</span>
      <p class="bgspan">
        <span
          v-for="i in colors"
          :key="i"
          class="tp_pos"
          @click="selBcak(i)"
        >
          <em class="tp_bg" />
          <svg-icon
            class="tp"
            :icon-class="i"
          />

        </span>
      </p>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="addTab('ruleForm')"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="hideDid">{{ $t('message.btnText.cancel') }}</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="提示"
      :visible.sync="dialoDelAll"
      :modal-append-to-body="false"
      width="30%"
      :before-close="handleClose"
    >
      <span>请确认是否移除全部标签</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="delAll"
        >确 认</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import serialize from '@/utils/serialize'
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [{
        tag_color: '#D04545',
        tag_name: '会议记录',
        tag_id: 1,
      }],
    },
    mailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      colors: ['D17D64', 'EEBCAD', 'F3E475', 'DDF1B6', 'DDF4EA', 'BCE2F5', 'DFEAFA', '7EABE8', 'B4A1CB', 'FFE3BB', 'B0E1E7', 'E5E5E5', 'C4C4C4', 'C00000', 'FF0000', 'FFFF00', '92D050', '00B050', '00B0F0', '0070C0', '002060', '7030A0', 'FF9800', '8CBBEA', '808080', '000000'],
      tag_list: this.$myData.tag_list,
      checkedCities: this.tabList,
      newMailInfo: this.mailInfo,
      checkedTab: [],
      dialoDelAll: false,
      isDel: false, // 是否显示移除
      newAdd: false, // 是否显示新建标签弹窗
      mback: '', // 新建选中的颜色
      mbackMsg: '', // 报错消息
      ruleForm: {
        newTxt: '', // 新建标签姓名
      },
      rules: {
        newTxt: [
          { required: true, message: this.$t('message.view.input_tag_name'), trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    /** 监听标签是否更改 */
    menu_labelWatch() {
      return this.$myState.tag_list_plus_watch
    },
  },
  watch: {
    tabList: function(a, b) {
      this.checkedCities = a
      this.checkedTab = []
      if (this.checkedCities.length === 0) {
        this.checkedTab = []
      } else {
        this.checkedCities.map((i, d) => {
          this.checkedTab.push(i.tag_name)
        })
      }
    },
    /** 监听标签数组变化，更新标签菜单
     * menu_label
     */
    menu_labelWatch(newVal, oldVal) {
      this.tag_list = this.$myData.tag_list
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(function() {
      // this.getList()
      this.checkedCities.map((i, d) => {
        this.checkedTab.push(i.tag_name)
      })
    })
  },
  methods: {
    // getList() {
    //   const req = {
    //     'module': 'tag',
    //     'require': {
    //       action: 'getList',
    //     },
    //   }
    //   this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
    //     this.labelList = res.result
    //   })
    // },
    // 移除全部-确认框打开
    // delAll_open() {
    //   this.dialoDelAll = true
    // },
    // 移除全部
    // delAll() {
    //   this.checkedTab = []
    //   this.dialoDelAll = false
    //   this.isDel = false
    //   // 向父组件传值
    //   this.$emit('showTabList', this.checkedTab)
    //   this.$parent.$parent.$parent.$refs.tabListBox.hide()

    //   const req = {
    //     'module': 'mail',
    //     'require': {
    //       action: 'addMailTag',
    //       tag_id: [],
    //       uid: [this.newMailInfo.uid],
    //       uidvalidity: this.newMailInfo.uidvalidity,
    //       uv: this.newMailInfo.uv,
    //     },
    //   }
    //   this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
    //     if (res.result.status) {
    //       this.$message.success(res.result.result)
    //     }
    //   })
    // },
    // 关闭弹窗
    handleClose() {
      this.dialoDelAll = false
    },
    // 标签变化的时候
    groupChange() {
      this.isDel = true
    },

    // 新建标签-确认框
    showDid() {
      this.ruleForm.newTxt = ''
      this.mback = ''
      this.newAdd = true
    },
    // 关闭新建标签弹窗
    hideDid() {
      this.$nextTick(e => {
        this.$refs['ruleForm'].resetFields()
      })
      this.newAdd = false
      this.mbackMsg = ''
    },
    addTab(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.mback === '') {
            this.mbackMsg = this.$t('message.view.input_tag_color')
            return false
          }
          const req = {
            'module': 'tag',
            'require': {
              action: 'update',
              data: {
                tag_color: '#' + this.mback,
                tag_name: this.ruleForm.newTxt,
                uid: this.newMailInfo.uid,
                uidvalidity: this.newMailInfo.uidvalidity,
                uv: this.newMailInfo.uv,
              },
            },
            tag_id: 'new',
          }
          this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
            this.newAdd = false
            this.tag_list.push({
              tag_color: '#' + this.mback,
              tag_name: this.ruleForm.newTxt,
              tag_id: res.result.tag_id,
            })
            this.$myApi.mainMenu.refreshTagList()

            this.mbackMsg = ''
          })
          // this.ruleForm.newTxt 标签名称
          // this.mback 标签颜色
        } else {
          return false
        }
      })
    },
    // 新建选中的颜色
    selBcak(i) {
      this.mback = i
    },
    // 切换标签
    changeTab() {
      console.log(this.$myData.is_tag)
      const list = []
      const tag_id = []
      if (this.tag_list.length) {
        this.tag_list.map(i => {
          const Index = this.checkedTab.findIndex((item) => {
            return item === i.tag_name
          })
          Index !== -1 ? list.push(i) : ''
          Index !== -1 ? tag_id.push(i.tag_id) : ''
        })
      }
      if (tag_id.length > 5) {
        this.$message.error(this.$t('message.view.max_five_tag'))
        return false
      }
      if (tag_id.length === 0 && (!this.$myData.is_tag)) {
        this.$message.error(this.$t('message.view.less_one_tag'))
        return false
      }
      const req = {
        'module': 'mail',
        'require': {
          action: 'addMailTag',
          tag_id: tag_id,
          uid: [this.newMailInfo.uid],
          uidvalidity: this.newMailInfo.uidvalidity,
          uv: this.newMailInfo.uv,
        },
      }
      if (this.newMailInfo.uid !== undefined) {
        this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
          this.$myData.is_tag = false
          if (res.result.status) {
            this.$message.success(res.result.result)
          }
        })
      }
      // 向父组件传值
      this.$emit('showTabList', list, this.mailInfo)
    },
  },
}
</script>
<style lang="scss" scoped>

  /deep/ .el-dialog__body{
    padding: 10px 20px!important;
    .el-input--small .el-input__inner{
      padding-right:56px;
    }
  }
.labelBox {
  width: 220px;
  /deep/.el-checkbox {
    display: block;
    padding-top: 12px;
  }
  /deep/.el-checkbox__inner::after{
  border:1px solid #707070;
  border-left:0;
  border-top:0;
  }

  /deep/ .el-checkbox__input,/deep/ .el-checkbox__label{
      vertical-align: bottom;
    }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #fff;
    border-color:#707070;
  }
  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner,
  /deep/ .el-checkbox__inner{
    border-color:#707070;
  }
  .group {
    padding: 0 15px 15px;
    max-height: 200px;
    overflow: auto;
    .lis{
      max-width: 167px;
      display: block;
      font-size: 100%;
    }
  }
  .bag {
    width: 14px;
    height: 14px;
    border-radius: 2px 2px 2px 2px;
    display: block;
    float: left;
    margin: 3px 9px 0 0;
    // vertical-align: middle;
  }
  .new_tab {
    color: #1d1b1b;
    cursor: pointer;
  }
  .gn_border{

    border-bottom: 1px solid #e2e2e2;
  }
  .new_tabs {
    padding: 11px 0 14px 14px;
    font-size: 14px;
    cursor: pointer;
    .all_ok {
      text-align: center;
      width: 70px;
      display: inline-block;
      cursor: pointer;
    }
    .all_del {
      cursor: pointer;
    }
    em {
      font-style: normal;
      margin: 0 10px;
    }
  }
  .web{
    font-weight:bold;
  }
}
.bgspan {
  display: flex;
  flex-wrap: wrap;
  clear: both;
  span {
    width: 20px;
    height: 14px;
    margin: 10px 10px 10px 0;
    cursor: pointer;
  }
}

.tp_pos{

  position: relative;
    .tp_bg{
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0;
      width: 20px;
      height: 14px;
      z-index:1;
      display: block;
    }
    .svg-icon{
      width: 20px;
      height: 14px;
      position: absolute;
      top: 0;
      left: 0;
    }
}

.mb {
  .svg-icon,.tp_bg{
    width: 43px;
    height: 16px;
    top: -8px;

  }
  display: inline-block;
}
.inpf {
  position: relative;
  span {
    position: absolute;
    right: 0;
    top: 0;
    right: 10px;
  }
}
.msgError{
  color:#f56c6c;
  margin-top: 2px;
  float: left;
}
</style>
