import serialize from '@/utils/serialize'
import { BigAttachmentsText } from '@/views/compose/components/template'
import { timeStampConvert } from '@/utils/utils'
export default {
  data() {
    return {
      node: this.$store.state.app.userGetConfig.node,
      // 附件管理
      atts: {
        module: 'mail',
        require: {
          action: 'attachmentList',
          from: 1,
          limit: 10,
          type: 'folder',
          uidvalidity: 1679501436,
          uv: 1679501436,
        },
      },
      attstotal: 0, // 附件管理总数量
      haveatts: false, // 有无附件管理附件
      attsManagerFiles: [], // 附件管理中附件
      attsNetdiskFiles: [], // 网盘附件
      attsTransferList: [], // 中转站附件
      selecttedTransferList: [],
      breadcrumbs: [{ label: 'mydriev', id: 'top', isD: true }], // 面包屑导航
      tablist: [{
        label: 'all',
        name: 'all',
        active: true,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
        },
        files: [],
      }, {
        label: 'img',
        name: 'img',
        active: false,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
          nd_type: '1',
        },
        files: [],
      }, {
        label: 'music',
        name: 'music',
        active: false,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
          nd_type: '2',
        },
        files: [],
      }, {
        label: 'video',
        name: 'video',
        active: false,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
          nd_type: '3',
        },
        files: [],
      }, {
        label: 'doc',
        name: 'doc',
        active: false,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
          nd_type: '4',
        },
        files: [],
      }, {
        label: 'zip',
        name: 'zip',
        active: false,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
          nd_type: '5',
        },
        files: [],
      }, {
        label: 'other',
        name: 'other',
        active: false,
        req: {
          is_mail_attachment: 0,
          parent_nd_id: 'top',
          limit: 9999,
          orderby: 'nd_type',
          page: 1,
          sequence: 'DESC',
          simpleMode: 0,
          word: '',
          nd_type: '0',
        },
        files: [],
      }],
      num: 0,
    }
  },
  methods: {
    // 获取大附件下载链接
    getdownloadlargefileURLdisk(magic_id) {
      const { netdisk_prefix } = this.$myData.sysConfig
      const { node } = this.$store.state.app
      return `${netdisk_prefix || ''}downloadlargefile.php?nsnode=s1_${node}&magic_id=${magic_id}`
    },
    // 获取网盘
    getNetdiskFiles() {
      const params = {
        module: 'netdisk',
        require: {
          action: 'getList',
          data: this.tablist[0].req,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        const dir = []
        result.list.map(file => {
          file.checked = false
          dir.push(file)
        })
        this.tablist[0].files = dir
        if (result.parents.length > 0) {
          const ids = result.parents.map(item => {
            return {
              label: item[1] ? item[1] : this.$t('message.compose.superatt'),
              id: item[0],
            }
          })
          ids.unshift({ label: this.$t('message.netdisk.mydrive'), id: 'top' })
          this.breadcrumbs = ids
        } else {
          this.breadcrumbs = [{ label: this.$t('message.netdisk.mydrive'), id: 'top' }]
        }
        this.tablist[0].ishave = true
      })
    },
    gerTransferList() {
      const params = {
        module: 'netdisk',
        require: {
          action: 'getList',
          data: {
            is_mail_attachment: 1,
            parent_nd_id: 'top',
            limit: 9999,
            orderby: 'nd_type',
            page: 1,
            sequence: 'DESC',
            simpleMode: 0,
            word: '',
          },
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        this.attsTransferList = result.list.map(item => {
          return {
            ...item,
            checked: false,
          }
        })
        console.log(this.attsTransferList)
      })
    },
    // 获取文件夹内容
    getFileBycatalog(item) {
      if (item.nd_is_dir === '1') {
        this.tablist[0].req.parent_nd_id = item.nd_id
        this.getNetdiskFiles()
      }
    },
    jumpCreadCrumb(item) {
      this.tablist[0].req.parent_nd_id = item.id
      this.getNetdiskFiles()
    },
    // 获取各类型附件
    getNetdiskFilesByCategory(id) {
      const category = this.tablist.filter(item => item.name === id)
      if (category[0].ishave) return false
      if (id === 'all') {
        this.getNetdiskFiles()
        return
      }
      const params = {
        module: 'netdisk',
        require: {
          action: 'getListByCategory',
          data: category[0].req,
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        result.list.map(item => {
          item.checked = false
          category[0].files.push(item)
        })
        category[0].ishave = true
      })
    },
    // 获取网盘文件链接信息
    getNetdiskAttachmentInfo(flag) {
      const ndinfo = {}
      const ids = []
      if (flag) {
        if (this.selecttedTransferList.length === 0) return []
        this.selecttedTransferList.map(item => {
          ndinfo[item.nd_id] = item
          ids.push(item.nd_id)
        })
      } else {
        if (this.attsNetdiskFiles.length === 0) return []
        this.attsNetdiskFiles.map(item => {
          ndinfo[item.nd_id] = item
          ids.push(item.nd_id)
        })
      }

      const params = {
        module: 'netdisk',
        require: {
          action: 'setSuperatt',
          data: {
            nd_id_list: ids.toString(),
          },
        },
      }
      return new Promise((reslove, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          const copy = []
          for (const key in result.list) {
            if (Object.hasOwnProperty.call(result.list, key)) {
              const element = result.list[key]
              const file = {
                url: this.getdownloadlargefileURLdisk(element[0]),
                name: ndinfo[key].nd_name,
                expire: timeStampConvert(element[1]),
              }
              copy.push({
                name: ndinfo[key].nd_name,
                size: flag ? this.selecttedTransferList.find(item => item.nd_id === key).mail_attachment_size : ndinfo[key].nd_size,
                type: 'netdisk',
                upid: ndinfo[key].nd_id + this.num++,
                nd_id: ndinfo[key].nd_id,
                fileuptype: 'big',
                expire: element[1],
                nd_url: this.getdownloadlargefileURLdisk(element[0]),
                bodys: BigAttachmentsText(file),
                origin: 'netdisk',
              })
            }
          }
          if (flag) {
            this.attsTransferList.map(item => {
              item.checked = false
            })
          } else {
            this.attsNetdiskFiles.map(item => {
              item.checked = false
            })
          }
          reslove(copy)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 大附件上传到网盘
    uploadNetdisk(file) {
      const params = {
        module: 'mail',
        require: {
          action: 'uploadToNetdisk',
          file_check: file.md5,
          nd_name: file.name,
          upid: file.upid,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
    async isHasthird(data) {
      const t = {
        module: 'mail',
        require: {
          action: 'attachmentPreview',
          ...data,
        },
      }
      const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(t) })
      if (Array.isArray(res)) {
        return this.$alert(this.$t('message.view.not_support_type'), this.$t('message.btnText.info'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          type: 'warning',
        })
      } else {
        window.open(res.preview_url)
      }
    },
    // 预览附件
    async previewAtta(item) {
      console.log(item.origin, 'origin-------------')
      const ext = item.name.split('.').pop()
      const type = ['txt', 'pdf', 'jpe', 'jpeg', 'jpg', 'gif', 'png']

      if (item.origin === 'netdisk') {
        this.downAttaReq = {
          nd_id: item.nd_id,
          is_mail_attachment: 0,
          preview: 1,
          disposition: 'inline',
          suffix: item.name.substring(item.name.lastIndexOf('.') + 1).toLowerCase(),
        }
        const req = {
          'module': 'netdisk',
          ...this.downAttaReq,
        }
        if (type.includes(ext)) {
          const url = `/nsmail/mail.php/fn.dat?task=download&req=
              ${encodeURIComponent(JSON.stringify(req))}&req_type=json`
          window.open(url)
        } else {
          this.isHasthird(this.downAttaReq)
        }
      } else if (item.origin === 'mail') {
        const { uid, uv, message_id, mbox } = this.userStreamCache.composectx
        // const { uid, uv, message_id, mbox } = this.composeData
        this.downAttaReq = {
          module: 'mail_attachment',
          attachment_name: item.name,
          encoding: item.encoding,
          mbox: mbox,
          message_id: message_id,
          secret_terminal: 0,
          section: item.section,
          uid: uid,
          uv: uv,
        }
        this.downAttaReq.preview = 1
        this.downAttaReq.disposition = 'inline'
        this.downAttaReq.suffix = item.name.substring(item.name.lastIndexOf('.') + 1).toLowerCase()

        if (type.includes(ext)) {
          this.isAttaDown = true
        } else {
          this.isHasthird(this.downAttaReq)
        }
      } else if (item.origin === 'attaManage') {
        this.downAttaReq = {
          module: 'mail_attachment',
          attachment_name: item.originData.name,
          disposition: item.originData.disposition,
          encoding: item.originData.encoding,
          mbox: item.originData.mbox,
          message_id: item.originData.message_id,
          secret_terminal: 0,
          section: item.originData.section,
          uid: item.originData.uid,
          uv: item.originData.uidvalidity,
        }
        this.downAttaReq.preview = 1
        this.downAttaReq.disposition = 'inline'
        this.downAttaReq.suffix = item.name.substring(item.name.lastIndexOf('.') + 1).toLowerCase()
        if (type.includes(ext)) {
          this.isAttaDown = true
        } else {
          this.isHasthird(this.downAttaReq)
        }
      } else if (item.origin === 'loc_upload') {
        this.downAttaReq = {
          disposition: 'inline',
          module: 'compose_attachment',
          hash_key: this.$md5(item.uid),
          attachment_name: item.name,
          suffix: item.name.substring(item.name.lastIndexOf('.') + 1).toLowerCase(),
          preview: 1,
        }
        const req = {
          ...this.downAttaReq,
        }
        const url = `/nsmail/mail.php/fn.dat?task=download&req=
              ${encodeURIComponent(JSON.stringify(req))}&req_type=json`
        if (type.includes(ext)) {
          window.open(url)
        } else {
          this.isHasthird(this.downAttaReq)
        }
      }
    },
    // 下载附件
    downloadAtta(item) {
      console.log(item, 'item')
      if (item.origin === 'netdisk') {
        this.downAttaReq = {
          nd_id: item.nd_id,
          is_mail_attachment: 0,
        }
        const req = {
          'module': 'netdisk',
          ...this.downAttaReq,
        }
        const url = '/nsmail/mail.php/fn.dat?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
        window.open(url)
      } else if (item.origin === 'mail') {
        const { uid, uv, message_id, mbox } = this.userStreamCache.composectx
        // const { uid, uv, message_id, mbox } = this.composeData
        this.downAttaReq = {
          module: 'mail_attachment',
          attachment_name: item.name,
          encoding: item.encoding,
          mbox: mbox,
          message_id: message_id,
          secret_terminal: 0,
          section: item.section,
          uid: uid,
          uv: uv,
        }
        this.isAttaDown = true
      } else if (item.origin === 'attaManage') {
        this.downAttaReq = {
          module: 'mail_attachment',
          attachment_name: item.originData.name,
          disposition: item.originData.disposition,
          encoding: item.originData.encoding,
          mbox: item.originData.mbox,
          message_id: item.originData.message_id,
          secret_terminal: 0,
          section: item.originData.section,
          uid: item.originData.uid,
          uv: item.originData.uidvalidity,
        }
        this.isAttaDown = true
      } else if (item.origin === 'Email_attachments') {
        // 作为附件转发下载
        this.downAttaReq = {
          disposition: 'attachment',
          attachment_name: item.name,
          mail_seclevel: item.data.mail_seclevel,
          mbox: item.data.mbox,
          message_id: item.data.message_id,
          module: 'mail_attachment',
          section: 'self',
          // subject: encodeURI(this.bodyHtml.subject.replace(/[\/ ?\t\r\n:\|\\*\"\'<>]/g, ' ')) + '.eml',
          subject: item.data.subject + '.eml',
          uid: item.data.uid,
          uv: item.data.uv,
        }
        this.isAttaDown = true
      } else if (item.origin === 'loc_upload') {
        this.downAttaReq = {
          disposition: 'inline',
          module: 'compose_attachment',
          hash_key: this.$md5(item.uid),
          attachment_name: item.name,
          suffix: item.name.substring(item.name.lastIndexOf('.') + 1).toLowerCase(),
        }
        const req = {
          ...this.downAttaReq,
        }
        const url = `/nsmail/mail.php/${encodeURIComponent(item.name)}?task=download&req=
            ${encodeURIComponent(JSON.stringify(req))}&req_type=json`
        window.open(url)
      }
    },
  },
}
