// 定义内容
export default {
  login: {
    usermenu: '用戶手冊',
    admmenu: '管理員手冊',
    userlogin: '用戶登錄',
    admlogin: '管理員登錄',
    placeholder1: '請輸入郵箱帳號',
    placeholder2: '請輸入密碼',
    placeholder3: '請輸入手機號',
    placeholder4: '請輸入驗證碼',
    placeholder5: '請輸入PIN碼',
    placeholder6: '請輸入郵箱帳號（如：user@example.com）',
    placeholder7: '請選擇問題',
    placeholder8: '請輸入答案',
    remmberusername: '記住用戶名',
    remmberaccount: '記住帳號',
    ssllogin: 'SSL登錄',
    forgetpsw: '忘記密碼',
    btnText: '登錄',
    step1: '輸入郵箱帳號',
    step2: '驗證身份',
    step3: '設定新密碼',
    step4: '重置密碼成功',
    mailaccount: '郵箱帳號',
    resetbtx: '重置',
    confirminfo1: '為確認是你本人操作,請完成身份驗證：',
    confirminfo2: '透過密保問題驗證：',
    newpsw: '新密碼',
    selectq: '選擇問題',
    inputa: '輸入答案',
    confirmbtx: '確定',
    setsuccess: '設定成功',
    singinagain: '帳號密碼重設成功,請重新登入。',
    back: '返回登入頁',
    q1: '您的出生地是？',
    q2: '您的父親姓名是？',
    q3: '您的母親姓名是？',
    q4: '您的配偶姓名是？',
    q5: '您的生日是？',
    q6: '您的工作城市是？',
    q7: '《自訂問題》',
    confirmpsw: '確認密碼',
    inputmail: '請輸入郵箱地址',
    inputrightmail: '請輸入正確的郵箱地址',
    inputcontent: '請輸入內容',
    err1: '請輸入確認密碼',
    err2: '密碼長度設定不符合要求',
    err3: '兩次密碼不一致',
  },
  btnText: {
    save: '儲 存',
    cancel: '取 消',
    ok: '確 定',
    info: '提示',
    close: '關 閉',
    swipecode: '向右滑動獲取驗證碼',
  },
  info: {
    m1: '操作成功',
  },
  mainpage: {
    home: '首頁',
    mail: '郵件',
    addressbook: '通訊錄',
    calendar: '日曆',
    netdisk: '網盤',
    setting: '設置',
    lock: '鎖屏',
    help: '幫助',
    changepassword: '修改密碼',
    logginout: '安全退出',
    authorized: '切換授權郵箱',
    authorizedmy: '返回我的郵箱',
    placeholder: '請輸入關鍵字',
    advanced: '高級搜索',
    quote: '備注',
    to: '至',
    sdate: '開始日期',
    edate: '結束日期',
    togglemannger: '切換企業管理平台',
  },
  compose: {
    adv1: '主&nbsp;&nbsp;&nbsp;題',
    adv2: '正&nbsp;&nbsp;&nbsp;文',
    adv3: '日&nbsp;&nbsp;&nbsp;期',
    adv4: '備&nbsp;&nbsp;&nbsp;注',
    subject: '主&nbsp;&nbsp;&nbsp;題',
    sub: '主題',
    body: '正文',
    from: '寄件人',
    to: '收件人',
    cc: '抄送人',
    bcc: '密送人',
    attsname: '附件名字',
    date: '日期',
    send: '傳送',
    timesend: '定時傳送',
    settimesend: '設定定時傳送',
    savedraft: '存草稿',
    priview: '預覽',
    closepeiview: '關閉預覽',
    cancel: '取消',
    single: '群發單顯',
    unsingle: '取消群發單顯',
    contact: '通訊錄',
    addBcc: '添加密送',
    unaddBcc: '取消密送',
    addatts: '添加附件',
    noneatts: '暫無附件',
    download: '下載',
    del: '刪除',
    goon: '繼續',
    scan: '檔掃描中{num}%',
    numatts: '{num}个附件',
    singn: '簽名',
    saveto: '儲存到“寄件匣”',
    emergent: '緊急',
    receipt: '請求回執',
    securemail: '安全郵件',
    secureprop: '安全屬性',
    watermark: '企業浮水印',
    destroy: '閱讀後銷毀',
    setpsw: '設定郵件密碼',
    cancelset: '取消設置',
    changeset: '修改設置',
    big: '超大',
    loaclfile: '本地文件',
    myfile: '我的文件',
    netfile: '网盘文件',
    fileman: '附件管理',
    nodate: '暫無數據',
    filetrans: '文件中轉站',
    superatt: '超大附件',
    mailpsw: '郵件密碼',
    sendto: '發送給',
    atts: '附件',
    viewatts: '查看附件',
    nums: '{num}个',
    more: '更多',
    info1: '收件人將禁止對此郵件內容進行轉發、回復、復製、打印、下載等操作',
    info2: '僅支持對以下類型txt、pdf、 png、 jpe、jpeg、jpg、gif的附件預覽,其他類型的附件存在無法查看的情況,請謹慎使用安全屬性郵件發送帶附件的郵件。',
    info3: '開啟閱後即焚後,郵件被閱讀一次後,郵件原文將被銷毀',
    info4: '設置郵件閱讀的有效期,超過時間限製後,此郵件將無法查看',
    info5: '選擇郵件密碼方式發送郵件,會將整封郵件進行加密處理,需要使用密碼才能查閱',
    info6: '密碼長度6-32位,數字、字母和特殊字符',
    info7: '郵件密碼不能為空',
    info8: '請設置自定義時間',
    info9: ' 您已設置為安全郵件模式',
    info10: '您已設置為安全郵件模式,郵件將被禁止轉發、回復、復製、打印、下載等操作,並於{date}過期',
    info11: '正在上傳附件,上傳完成後將自動發送',
    info12: '取消自動發送',
    info13: '{num}個文件過大,附件將作為雲附件上傳,',
    info14: '{num}天內有效,若被刪除或過期,收件人將無法下載！',
    info15: '請選擇',
    info16: '設置成功後,點擊寫信頁的“發送”按鈕,郵件將會暫存在草稿箱,並在設定的時間發送',
    info17: '傳送時間',
    info18: '選擇日期',
    info19: '選擇時間',
    info20: '傳送時區',
    info21: '選擇日期時間',
    info22: '過期時間',
    info23: '"群發單顯"采用一對一單獨發送,每個收件人只能看到自己的郵箱地址',
    info24: '選擇的日期時間已過期',
    info25: '您已设置为安全邮件模式,邮件将以邮件加密的方式发送,收件人需输入密码查阅',
    info26: '附件正在上傳,確定中斷和放棄對此信件的操作？',
    info27: '已取消定時發送',
    info28: '您設置的過期郵件時間已經過期',
    info29: '郵件密碼不能为空',
    info30: '郵件密碼長度不能少於6位',
    info31: '郵件密碼不合規',
    info32: '您已設置為安全郵件模式,郵件將被禁止轉發、回復、復製、打印、下載等操作,並設置了閱後即焚屬性',
    info33: '您已設置為安全郵件模式,郵件將被禁止轉發、回復、復製、打印、下載等操作,並於{time}過期',
    info34: '您已設置為安全郵件模式,郵件開啟了閱後即焚和禁止操作屬性,並於{time}過期',
    info35: '您已設置為安全郵件模式,郵件將被禁止轉發、回復、復製、打印、下載等操作',
    info36: '此操作將永久刪除該文件, 是否繼續?',
    info37: '注意：安全郵件中存在部分附件類型可能無法預覽。',
    info38: '點此查看支持預覽嘅附件類型。',
    info39: '當前暫無附件類型支持預覽！',
    day0: '無限製',
    day1: '1天',
    day3: '3天',
    day7: '7天',
    day8: '自定義',
    day9: '3天后',
    day10: '7天后',
    day11: '1天后',
    err1: '請至少要填寫一個收件人位址',
    err2: '收件人含有錯誤的郵件地址',
    err3: '抄送人含有錯誤的郵件地址',
    err4: '密送人含有錯誤的郵件地址',
    err5: '發送失敗,郵件過期時間早於當前發送時間',
    err6: '您設置的定時發送郵件時間已經過期',
    err7: '未上傳附件,請確認是否繼續傳送？',
    err8: '主題為空,請確認是否繼續發送？',
    err9: '正在寫信中,關閉前是否保存草稿？',
    err10: '定時發送時間晚於郵件過期時間,郵件將無法正常閱讀,確認繼續發送嗎？',
    err11: '未選擇文件',
    err12: '上傳失敗,附件總大小超過了郵件{num}M限製',
    err13: '禁止上傳空文件',
    err14: '無效的郵件地址',
    success: '傳送成功',
    success1: '儲存草稿成功',
    neverexpire: '永久有效',
    fw: '轉寄：',
    ry: '回復：',
    label0: '自動',
    label1: 'UTC-12:00 (貝克島)',
    label2: 'UTC-11:00 (中途島)',
    label3: 'UTC-10:00 (檀香山)',
    label4: 'UTC-09:30 (馬克薩斯群島)',
    label5: 'UTC-09:00 (安克雷奇)',
    label6: 'UTC-08:00 (洛杉磯)',
    label7: 'UTC-07:00 (丹佛)',
    label8: 'UTC-06:00 (芝加哥)',
    label9: 'UTC-05:00 (紐約)',
    label10: 'UTC-04:00 (聖地亞哥)',
    label11: 'UTC-03:00 (布宜諾斯艾利斯)',
    label12: 'UTC+01:00 (巴黎)',
    label13: 'UTC+03:00 (莫斯科)',
    label14: 'UTC+04:00 (迪拜)',
    label15: 'UTC+05:30 (新德裏)',
    label16: 'UTC+07:00 (曼谷)',
    label17: 'UTC+08:00 (北京)',
    label18: 'UTC+09:00 (東京)',
    label19: 'UTC+10:00 (悉尼)',
    label20: 'UTC+12:00 (奧克蘭)',
    attinfo: '拖拽/粘貼來自網絡的圖片（ctrl+v）/從計算機添加',
    all: '全部檔案',
    img: '圖片',
    music: '音訊',
    video: '視頻',
    doc: '檔案',
    zip: '壓縮包',
    other: '其他',
    mydriev: '我的網盤',
    format: '禁止上傳{type}的格式檔案',
  },
  homepage: {
    lingchen: '淩晨好！',
    zaoshang: '早上好！',
    shangwu: '上午好！',
    zhongwu: '中午好！',
    xiawu: '下午好！',
    wanshang: '晚上好！',
    unread: '未讀郵件',
    stared: '星標郵件',
    notification: '待審核記錄',
    track: '郵件追蹤',
    maillimit: '郵件上限',
    mailcapacity: '郵件容量',
    tansfercapacity: '中轉站容量',
    drivecapacity: '網盤容量',
    limit: '上限',
    used: '已用',
    total: '容量',
    recentlogin: '最近登錄',
    recentloginstatus: '最近登錄情況',
    lastchangepsw: '上次修改密碼',
    lastchangedpsw: '上次修改密碼',
    mysetting: '我的設置',
    autoreplaysetting: '自動回復設置',
    clientauthcode: '用戶端授權碼設置',
    autoforword: '自動轉發設置',
    forword: '轉發至',
    envelope: '封',
    on: '開啟',
    off: '關閉',
    noEmailPermission: '無郵箱許可權，無法查看',
  },
  mbox: {
    newmail: '寫郵件',
    receiveemail: '收信',
    inbox: '收件箱',
    sentitems: '寄件匣',
    drafts: '草稿箱',
    deleteditems: '已刪除',
    starred: '星標郵件',
    unread: '未讀郵件',
    archive: '歸檔郵件',
    junkemail: '垃圾郵件',
    emailtracking: '郵件追蹤',
    reviewnotifications: '審核通知',
    attachmentmanagement: '檔案管理',
    ftStation: '檔中轉站',
    myfolders: '我的資料夾',
    labelmail: '標籤郵件',
  },
  maillist: {
    delete: '刪除',
    deleteper: '徹底刪除',
    move: '移動',
    mark: '標記',
    unread: '未讀',
    read: '已讀',
    stared: '星標',
    unstar: '取消星標',
    top: '置頂',
    untop: '取消置頂',
    tag: '選標籤',
    untag: '取消全部標籤',
    forword: '轉發',
    forwordasatt: '作為附件轉發',
    filter: '篩選',
    sort: '排序',
    bytimen2o: '按時間從新到舊',
    bytimeo2n: '按時間從舊到新',
    btfromdesc: '按寄件人降序',
    btfromasc: '按寄件人升序',
    bysubdesc: '按主題降序',
    bysubasc: '按主題升序',
    bysizedesc: '按郵件大小降序',
    bysizedasc: '按郵件大小升序',
    urgent: '緊急',
    hasatt: '含附件',
    hasnotes: '含備注',
    all: '全部',
    more: '更多',
    download: '下載郵件',
    upload: '上傳郵件',
  },
  netdisk: {
    diskshare: '網盤共亯',
    diskfshare: '網盤分亯',
    dir: '所在目錄',
    noname: '無檔案名稱',
    nofname: '無資料夾名稱',
    dayslayer: '天后',
    expired: '已失效',
    folder: '資料夾',
    file: '文件',
    all: '全部檔',
    image: '圖片',
    audio: '音訊',
    vodeo: '視頻',
    doc: '文檔',
    zip: '壓縮包',
    other: '其他',
    upload: '上傳檔',
    createfolder: '新建資料夾',
    delete: '刪除',
    move: '移動到',
    sendmail: '發送郵件',
    share: '分享',
    download: '下載',
    rename: '重命名',
    mydrive: '我的網盤',
    filename: '檔案名',
    modifiedtime: '修改時間',
    type: '類型',
    size: '大小',
    myshare: '我的分享',
    used: '已用網盤容量',
    total: '共',
    Unshare: '取消分享',
    copylink: '複製連結',
    sharename: '分享檔案名',
    sharetime: '分享時間',
    expiretime: '失效時間',
    unexpire: '有效期',
    sharefile: '分享檔案',
    crearelink: '創建連結',
    checktimes: '查看次數',
    downloadtimes: '下載次數',
    confirmselfiles: '確認刪除所選檔嗎?',
    movetof: '移動到資料夾',
    copysend: '複製去發郵件',
    copylinkcode: '複製連結及提取碼',
    mv: '移動',
    result: '搜索結果',
    namenotnull: '名稱不能為空',
    canceldel: '已取消刪除',
    delsuccess: '刪除成功',
    cresuccess: '新建資料夾成功',
    mvsuccess: '移動成功',
    resuccess: '重命名成功',
    link: '鏈接',
    code: '提取碼',
    info1: '連結複製連結成功',
    info2: '取消分享後,所選連結將無法訪問及下載,確認取消分享嗎？',
    info3: '已取消操作',
    info4: '取消分享成功',
    info5: '分享的檔案已取消或已過期',
    day1: '1天',
    day2: '7天',
    day3: '30天',
    day4: '永久有效',
    err1: '請選擇檔',
    err2: '目標目錄不能為空',
    err3: '不支持 / : * ? < > |',
    err4: '請選擇檔案或資料夾',
    err5: '請選擇下載檔案',
    err6: '僅支持選擇檔案',
    err7: '網盤分享所選文件大小超過200M,請分批或單個下載',
    filenum: '等{num}個檔案',
    drivesharelink: '網盤共亯連結',
    sharepsw: '請輸入共亯密碼',
  },
  contact: {
    group: '所屬組',
    enpcontact: '企業通訊錄',
    orgcontact: '組織通訊錄',
    percontact: '個人通訊錄',
    commoncontact: '常用連絡人',
    maillist: '郵寄清單',
    maillistname: '郵寄清單名稱',
    allcontact: '全部連絡人',
    ungroup: '未分組',
    newcontact: '新建連絡人',
    editcontact: '編輯連絡人',
    newgroup: '新建聯繫組',
    newfenzu: '新建分組',
    editgroup: '編輯聯系組',
    delgroup: '刪除聯系組',
    importcontact: '導入連絡人',
    outcontact: '外部連絡人',
    info1: '此分組暫無連絡人',
    info2: '暫無連絡人',
    info3: '確認刪除',
    info4: '刪除分組後,組內連絡人將被解散至全部連絡人',
    info5: '同步刪除通訊錄中屬於此分組的連絡人',
    info6: '刪除聯系組成功',
    info7: '請輸入組名稱',
    info8: '顯示名稱不支持英文分號、單引號、雙引號、<、>、/、\\%&…… ￥特殊字符！',
    info9: '組名不合規',
    info10: '創建成功',
    info11: '請輸入正確的聯繫方式',
    info12: '顯示名稱不能為空',
    info13: '郵箱地址不能為空',
    info14: '請輸入正確的郵箱地址',
    info15: '顯示名稱不合法',
    info16: '編輯成功',
    info17: '請輸入分組名稱',
    info18: '複製成功',
    info19: '是否刪除連絡人{person}？',
    info20: '已選中{num}個連絡人,請確認是否刪除？',
    info21: '已選中{num}個連絡人,是否在分組內刪除？',
    info22: '已添加{num}人到個人通訊錄',
    more: '更多',
    retract: '收起',
    select: '選擇',
    unselect: '取消選擇',
    allselect: '全選',
    num: '{num}個連絡人',
    addperon: '添加到個人通訊錄',
    tocompose: '去寫信',
    depart: '所屬部門',
    mailaddress: '郵箱地址',
    crossmail: '往來郵件',
    compose: '寫信',
    edit: '編輯',
    del: '刪除',
    mv: '移動',
    copy: '複製',
    export: '匯出',
    copymail: '複製郵箱地址',
    ckcrossmail: '查看往來郵件',
    selectgroup: '選擇分組',
    displayname: '顯示名稱',
    phone: '聯繫方式',
    addphone: '添加聯繫方式',
    quote: '備註',
    unit: '單位',
    noresult: '暫無搜索結果',
    placeholder1: '輸入分組名稱',
    placeholder2: '請輸入聯系組名稱',
    member: '成員',
    view: '查看',
    unview: '不允許查看',
    memberlist: '郵件成員列表',
    dynamiclist: '動態成員',
    designlist: '指定成員',
    externallist: '外部成員',
    departmail: '部門郵箱',
    importgroup: '導入到組',
    selectimportfile: '選擇要導入的檔案',
    repeatmail: '如遇到地址重複的記錄',
    selectfile: '選擇檔案',
    tips: '請按照範本格式導入,只支持.csv格式檔案。',
    downtips: '下載CSV格式檔案',
    unimport: '不導入',
    repeat: '覆蓋',
    reflect: '請做欄位映射',
    selcctreflect: '請選擇映射',
    importsuccess: '導入完成',
    gocheck: '去查看',
    konw: '我知道了',
    imported: '成功導入{num}條,',
    conflicts: '地址衝突{num}條,',
    error1: '格式錯誤{num}條。',
    error2: '下載格式錯誤名單',
    error3: '請選擇上傳文件！',
    error4: '請選擇聯系組',
    error5: '郵件格式錯誤清單',
    error6: '只支持.csv格式的檔案',
    importinfo1: '導入成功',
    importinfo2: '地址衝突',
    importinfo3: '格式錯誤',
    clear: '清除全部',
    addto: '添加到',
    placeholder: '輸入姓名/郵寄地址蒐索連絡人',
    participant: '參會人',
  },
  setting: {
    opnetdisk: '操作網盤',
    optranfer: '操作中轉站',
    tipsset: '提醒設定',
    mailmove: '郵箱搬家',
    accountsec: '帳號安全',
    changepsw: '修改密碼',
    changeque: '修改密保',
    checklog: '自助日誌査詢',
    auditlog: '稽核記錄査詢',
    clientset: '用戶端配寘',
    auth: '授權管理',
    mailset: '郵箱設置',
    displayset: '顯示設定',
    sendreve: '寄信和收信設定',
    revrule: '收信規則',
    quickreply: '快速回復',
    autoreply: '自動回復',
    autoforword: '自動轉寄',
    blacklist: '黑名單',
    whitelist: '白名單',
    signset: '簽名設定',
    tagset: '標籤管理',
    floderset: '資料夾設定',
    notify: '消息通知',
    general: '通用設定',
    skinset: '皮膚設定',
    emaiPassword: '邮箱密碼',
    oldpsw: '舊密碼',
    new: '新密碼',
    confirmpsw: '確認密碼',
    info1: '修改密碼成功',
    info2: '修改密保前請輸入郵箱登錄密碼驗證您的身份',
    info3: '修改密保成功',
    err1: '請輸入初始密碼',
    err2: '請輸入新密碼',
    err3: 'ip地址不合規',
    operator: '操作者',
    operatorip: '操作IP',
    operatortime: '操作時間',
    operatortype: '操作類型',
    operatorcontent: '操作內容',
    clicksearch: '點此蒐索',
    viewlog: '日誌記錄査詢',
    p1: '請選擇操作類型',
    operate1: '登入',
    operate2: '退出',
    operate3: '傳送郵件',
    operate4: '閱讀郵件',
    operate5: '操作郵件',
    operate6: '操作通訊錄',
    operate7: '操作附件',
    operate8: '設定屬性',
    operate9: '風險操作',
    operate10: '切換授權郵箱',
    operate11: '召回郵件',
    operate12: '稽核郵件',
    operate13: '刪除郵件',
    fzset: '字體大小設定',
    timeformat: '時間顯示格式',
    h12: '12小時制',
    h24: '24小時制',
    langset: '語言設定',
    zh: '簡體中文',
    zhf: '繁體中文',
    en: 'English',
    saveinfo: '儲存成功',
    skinstyle: '皮膚風格',
    sk01: '默認',
    sk02: '皮膚02',
    sk03: '皮膚03',
    sk04: '皮膚04',
    sk05: '皮膚05',
    sk06: '皮膚06',
    sk07: '皮膚07',
    sk08: '皮膚08',
    sk09: '皮膚09',
    sk10: '皮膚10',
    sk11: '皮膚11',
    sk12: '皮膚12',
    sk13: '皮膚13',
    sk14: '皮膚14',
    sk15: '皮膚15',
    sk16: '皮膚16',
    newnotice: '新郵件到達通知',
    range: '通知範圍',
    mbox: '收件箱',
    allmail: '所有郵件',
    notifytype: '通知管道',
    webpropmpt: 'web彈窗',
    audittime: '審核日期',
    from: '寄件人',
    subject: '主題',
    auditrecords: '稽核記錄',
    auditdetail: '稽核詳情',
    viewdetail: '查看詳情',
    audit: '待稽核',
    approved: '稽核通過',
    reject: '稽核拒絕',
    contain: '包含',
    thename: '寄件人',
    thesubject: '主題',
    record: '的記錄',
    clientcode: '用戶端授權碼設定',
    usercode: '使用用戶端授權碼登錄',
    clientinfo1: '開啟用戶端授權登錄碼後,信箱密碼將無法在登錄用戶端時使用',
    clientinfo2: '*用戶端授權碼用於登錄協力廠商郵件用戶端的專用密碼,適用於登錄以下服務:POP3/SMTP/IMAP服務。',
    clientinfo3: '新增授權碼',
    clientinfo4: '開啟用戶端授權碼登錄後,僅能使用用戶端授權碼登錄用戶端,將無法使用信箱密碼登錄,確認開啟嗎?',
    clientinfo5: '關閉用戶端授權碼登錄後,所有用戶端授權碼將失效,同時恢復信箱密碼登錄用戶端許可權,確認關閉嗎?',
    clientinfo6: '*管理員已開啟用戶端授權碼登入,必須設定用戶端授權碼才能使用用戶端收發郵件。',
    clientinfo7: '(最多可添加5個授權碼)',
    clientinfo8: '設定用戶端授權碼',
    clientinfo9: '用戶端授權碼僅顯示一次,請手工複製或者點擊下方按鈕複製授權碼',
    clientinfo10: '使用設備名稱',
    clientinfo11: '用戶端授權碼',
    clientinfo12: '確定並複製授權碼',
    clientinfo13: '開啟用戶端授權碼登入後,僅能使用用戶端授權碼登入用戶端,將無法使用郵箱密碼登入,確認開啟嗎？',
    clientinfo14: '關閉用戶端授權碼登入後,所有用戶端授權碼將失效,同時恢復郵箱密碼登入用戶端許可權,確認關閉嗎？',
    clientname: '設備名稱',
    clicenttime: '啟用時間',
    clientoperate: '操作',
    clientdel: '刪除',
    error1: '設備名稱不能為空',
    error2: '添加成功',
    error3: '最多可添加5個授權碼',
    error4: '最多可添加5條授權管理',
    error5: '請選擇時間段',
    selectauthobj: '選擇授權對象',
    addobj: '添加',
    selectauth: '許可權選擇',
    placeholder1: '請選擇許可權',
    placeholder2: '讀信許可權：（僅可閱讀郵件）',
    placeholder3: '寫信許可權：（在閱讀許可權基礎上,新增寫信、回復和轉發許可權）',
    placeholder4: '管理許可權：（可對設定之外所有功能模組進行操作）',
    placeholder5: '授權有效期',
    radio1: '長期有效',
    radio2: '30天',
    radio3: '1天',
    radio4: '自定義日期',
    radio5: '7天',
    authobj: '授權對象',
    author: '授權許可權',
    authtime: '生效時間',
    authexpire: '失效時間',
    edit: '編輯',
    au: '授權',
    tipsset1: '异地登入提醒',
    tipsset2: '開啟後,帳號出現异地登入時,系統將發送郵件提醒',
    tipsset3: '修改密碼提醒',
    tipsset4: '開啟後,帳號密碼被修改時,系統將發送郵件提醒',
    tipsset5: '自動轉發提醒',
    tipsset6: '開啟後,帳號設定自動轉發時,系統統將發送郵件提醒',
    tipsset7: '用戶端授權碼設定提醒',
    tipsset8: '開啟後,帳號設定用戶端授權時,系統統將發送郵件提醒',
    mailmove1: '管理端設定使用',
    mailmove2: '進行郵箱遷移',
    mailmove3: '請輸入原郵箱',
    mailmove4: '原系統郵箱地址',
    mailmove5: '原系統郵箱密碼',
    mailmove6: '請確保輸入資訊的準確性,否則可能影響遷移結果。',
    mailmove7: '提交原郵箱帳號密碼後,遷移進度由系統管理員處理,如有緊急遷移需求,請直接聯系管理人員。',
    mailmove8: 'IMAP協定',
    mailmove9: 'IMAP授權碼',
    mailmove10: 'POP3協定',
    mailmove11: 'POP3授權碼',
    mailmove12: 'Exchange協定',
    mailmove13: 'Exchange授權碼',
    // 显示设置
    disset: '啟動頁',
    disset1: '啟動打開首頁',
    disset2: '啟動打開收件箱',
    disset3: '郵件視圖',
    disset4: '清單模式',
    disset5: '分欄模式',
    disset6: '郵件摘要',
    disset7: '顯示郵件摘要',
    disset8: '郵件顯示',
    disset9: '收寄件人顯示格式',
    disset10: '設定郵箱清單、讀信和寫信頁面收寄件人的顯示方式',
    disset11: '顯示內嵌圖片',
    disset12: '每頁郵件條數',
    disset13: '恢復上次打開的標籤頁',
    disset14: '意外退出後,再次登入時支持自動回復標籤頁',
    disset15: '顯示附件圖片',
    disset16: '姓名+所屬部門',
    disset17: '姓名+郵寄地址',
    disset18: '總是',
    disset19: '來自通訊錄的郵件',
    disset20: '從不',
    // 文件夹设置
    floderset1: '建立資料夾',
    floderset2: '資料夾名稱',
    floderset3: '郵件數量',
    floderset4: '顯示',
    floderset5: '操作',
    floderset6: '清空郵件',
    floderset7: '重命名',
    floderset8: '刪除',
    floderset9: '請確認是否刪除此資料夾？請輸入安全碼：',
    floderset10: '資料夾有{num}封郵件,請確認是否清空?請輸入安全碼:',
    floderset11: '上級資料夾',
    floderset12: '我的資料夾',
    floderset13: '資料夾中仍存在郵件,刪除前請清空資料夾',
    floderset14: '安全碼輸入錯誤',
    floderset15: '刪除資料夾成功',
    floderset16: '郵件夾清空成功',
    floderset17: '郵件夾重命名成功',
    floderset18: '請輸入資料夾名稱',
    floderset19: '新建資料夾成功',
    // 标签管理
    tagset1: '新建標籤',
    tagset2: '請輸入內容',
    tagset3: '標籤顏色',
    tagset4: '確定刪除標籤：{name}?',
    tagset5: '請輸入標籤名稱',
    tagset6: '請選擇標籤顏色',
    tagset7: '暫無標籤',
    tagset8: '標籤名稱',
    tagset9: '未讀數/郵件總數',
    tagset10: '編輯',
    tagset11: '刪除',
    tagset12: '編輯標籤',
    tagset13: '刪除成功',
    tagset14: '操作成功!',
    // 收信规则
    revrule1: '新建收信規則',
    revrule2: '規則名稱',
    revrule3: '狀態',
    revrule4: '編輯',
    revrule5: '刪除',
    revrule6: '暫無收信規則',
    revrule7: '状态',
    revrule8: '收信規則名稱',
    revrule9: '條件類型',
    revrule10: '觸發條件',
    revrule11: '執行動作',
    revrule12: '徹底刪除 ',
    revrule13: '（徹底刪除郵件無法恢復,請謹慎選擇）',
    revrule14: '滿足所有條件',
    revrule15: '滿足任一條件',
    revrule16: '請輸入規則名稱',
    revrule17: '移動到',
    revrule18: '執行此規則後不再執行後續過濾規則',
    revrule19: '收件人',
    revrule20: '寄件人',
    revrule21: '主题',
    revrule22: '包括',
    revrule23: '不包括',
    revrule24: '請輸入觸發條件',
    revrule25: '請選擇',
    revrule26: '請確認是否刪除此規則？',
    revrule27: '输入',
    revrule28: '地址不合規',
    revrule29: '請選擇執行動作',
    revrule30: '請選擇移動到',
    // 快速回复
    quickreply1: '輸入回復內容',
    quickreply2: '添加到快捷回復清單',
    quickreply3: '規則名稱',
    quickreply4: '编辑',
    quickreply5: '刪除',
    quickreply6: '不支持添加空數據',
    // 发信和收信设置.
    mailset1: '寄信設定',
    mailset2: '儲存到寄件匣',
    mailset3: '自動儲存到草稿箱',
    mailset4: '從不',
    mailset5: '1分鐘',
    mailset6: '3分鐘',
    mailset7: '5分鐘',
    mailset8: '10分鐘',
    mailset9: '回復主題首碼',
    mailset10: '回復：',
    mailset11: 'Re:',
    mailset12: '轉寄主題首碼',
    mailset13: '轉寄：',
    mailset14: 'Fw:',
    mailset15: '回復時加入原文',
    mailset16: '自動添加收件人到通訊錄',
    mailset17: '附件提醒關鍵字',
    mailset18: '默認請求回執',
    mailset19: '寫信字體設定',
    mailset20: '收信設定',
    mailset21: '收信閱讀回執',
    mailset22: '手動選擇',
    mailset23: '全部忽略',
    mailset24: '自動回復',
    mailset25: '歸檔設定週期',
    mailset26: '7天',
    mailset27: '15天',
    mailset28: '30天',
    mailset29: '不歸檔',
    mailset30: '多個關鍵字之間用英文逗號分割',
    mailset31: 'Webmail寄信將被儲存到寄件匣 ',
    mailset32: '預設歸檔“收件匣”內郵件',
    // 自动回复
    autoreply1: '啟用自動回復',
    autoreply2: '回復內容',
    autoreply3: '在以下時間內啟用自動回復',
    autoreply4: '選擇開始日期時間',
    autoreply5: '選擇結束日期時間',
    autoreply8: '僅對企業內連絡人進行自動回復',
    autoreply9: '請正確設定啟用自動回復的開始時間和結束時間',
    autoreply10: '結束時間早於開始時間',
    autoreply11: '開始時間不能大於結束時間',
    autoreply12: '自動回復內容不能為空',
    // 自动转发
    autofwd1: '啟用自動轉發',
    autofwd2: '在以下時間內啟用自動轉發',
    autofwd3: '自動轉發地址',
    autofwd4: '地址{num}',
    autofwd5: '自動轉發後在此郵箱刪除來信',
    autofwd6: '請正確設定啟用自動轉發的開始時間和結束時間',
    autofwd7: '開始時間不能大於結束時間',
    autofwd8: '請至少輸入一個郵寄地址',
    // 黑名单
    blacklist1: '輸入郵箱地址',
    blacklist2: '添加',
    blacklist3: '輸入郵箱地址或功能變數名稱尾碼,添加後將拒收該地址來信。 如： mail@example.com ,example.com',
    blacklist4: '黑名單清單',
    blacklist5: '刪除',
    blacklist6: '請確認是否將此郵寄地址移除,不再攔截此地址的郵件？',
    blacklist7: '郵箱不能為空',
    blacklist8: '郵箱格式錯誤',
    blacklist9: '輸入關鍵字蒐索',
    // 白名单
    whitelist1: '輸入郵箱地址',
    whitelist2: '添加',
    whitelist3: '輸入郵箱地址或功能變數名稱尾碼,添加後則安全信任該地址來信。 如： mail@example.com ,example.com',
    whitelist4: '白名單清單',
    whitelist5: '刪除',
    whitelist6: '刪除後不再放行此地址郵件,請確認是否刪除？',
    whitelist7: '郵箱不能為空',
    whitelist8: '郵箱格式錯誤',
    whitelist9: '輸入關鍵字蒐索',
    // 签名设置
    signature1: '新建簽名',
    signature2: '簽名名稱',
    signature3: '應用於新郵件',
    signature4: '應用於轉寄/回復',
    signature5: '簽名位置',
    signature6: '操作',
    signature7: '預覽',
    signature8: '編輯',
    signature9: '刪除',
    signature10: '暫無簽名',
    signature11: '請輸入簽名名稱',
    signature12: '設為默認用於新郵件',
    signature13: '設定默認用於回復/轉寄',
    signature14: '簽名位置',
    signature15: '在回復轉寄引用內容上方',
    signature16: '在回復轉寄引用內容下方',
    signature17: '簽名預覽',
    signature18: '關閉預覽',
    signature19: '請確認是否刪除此簽名？',
    signature20: '請輸入簽名名稱',
    signature21: '新建成功',
    signature22: '更新成功',
    signature23: '此簽名處於使用中,請確認是否刪除？',
    signature24: '編輯簽名',
    signature25: '定時發送',
    signature26: '存草稿',
    signature27: '預覽',
    signature28: '取消',
    signature29: '郵件主題',
    signature30: '收件人：xxx',
    signature31: '郵件正文部分示例樣式',
    signature32: '引文部分',
    signature33: '發送',
    signature34: '引用內容上方',
    signature35: '引用內容下方',
    signature36: '新郵件',
  },
  calendar: {
    view_link: '查看日曆連結',
    view_indo: '日曆鏈接用於挂載到第三方客戶端上查看共享日曆',
    cal_link: '日曆鏈',
    share_link: '分享鏈接',
    change_auth: '修改權限',
    cal1: '{year}年{month}月',
    cal2: '今天',
    cal3: '日',
    cal4: '週',
    cal5: '月',
    cal6: '日程分享',
    cal7: '日程提醒',
    cal8: '主題',
    cal9: '地點',
    cal10: '時間',
    cal11: '開始時間',
    cal12: '暫無提醒',
    cal13: '忽略全部',
    cal14: '稍後提醒',
    cal15: '查看日曆',
    cal16: '5分鐘前',
    cal17: '10分鐘前',
    cal18: '15分鐘前',
    cal19: '1小時前',
    cal20: '2小時前',
    cal21: '6小時前',
    cal22: '12小時前',
    cal23: '1天前',
    cal24: '30分鐘前',
    cal25: '18小時前',
    cal26: '2天前',
    cal27: '操作成功',
    cal28: '（無主題）',
    cal29: '轉發會議',
    cal30: '提醒',
    cal31: '組織者',
    cal32: '參會者',
    cal33: '會議已過期',
    cal34: '接受',
    cal35: '{num}人',
    cal36: '暫定',
    cal37: '拒絕',
    cal38: '查看參會者響應',
    cal39: '日期',
    cal40: '姓名',
    cal41: '查看詳情',
    cal42: '刪除',
    cal43: '回復組織者',
    cal44: '回復全部',
    cal45: '回復組織者',
    cal46: '取消會議',
    cal47: '編輯',
    cal48: '無',
    cal49: '1天前',
    acbtn1: '接受',
    acbtn2: '已接受',
    acbtn3: '已拒絕',
    acbtn4: '會議已取消',
    acbtn5: '重新選擇',
    acbtn6: '已',
    acbtn7: '現在發送響應',
    acbtn8: '編輯後發送響應',
    acbtn9: '不發送響應',
    more: '更多',
    fullcal: '未響應',
    info1: '日程資訊',
    info2: '會議邀請',
    del1: '刪除成功',
    del2: '刪除失敗',
    confirm: '確定要刪除此日程嗎？',
    confirm2: '會議取消成功',
    info3: '新建活動',
    info4: '新建日程',
    info5: '新建會議',
    info6: '我的日歷',
    info7: '暫無日程',
    info8: '請輸入標籤名稱',
    info9: '重命名',
    info10: '訂閱日歷',
    info11: '暫無訂閱',
    info12: '分享來源',
    info13: '創建日程',
    info14: '刪除訂閱',
    info15: '發送會議',
    info16: '保存日程',
    info17: '切換日程',
    info18: '切換會議',
    info19: '更改會議',
    info20: '更改日程',
    info21: '參會人',
    info22: '通訊錄',
    info23: '主&nbsp;&nbsp;&nbsp; 題',
    info24: '地&nbsp;&nbsp;&nbsp; 點',
    info25: '時&nbsp;&nbsp;&nbsp; 間',
    info26: '開始日期',
    info27: '結束日期',
    info28: '至',
    info29: '全天',
    info30: '忙&nbsp;&nbsp;&nbsp; 閑',
    info31: '空閒',
    info32: '忙碌',
    info33: '暫定',
    info34: '外出',
    info35: '在其他地方工作',
    info36: '提&nbsp;&nbsp;&nbsp; 醒',
    info37: '請選擇',
    info38: '關閉活動週期',
    info39: '設定活動週期',
    info40: '添加附件',
    info41: '暫無附件',
    info42: '{num}個附件',
    info43: '超大',
    info44: '下載',
    info45: '繼續',
    info46: '我收到的分享',
    info47: '我發起的分享',
    info48: '返回日歷',
    info49: '請輸入分享成員姓名或郵箱',
    info50: '分享成員',
    info51: '分享許可權',
    info52: '操作',
    info53: '刪除',
    info54: '添加分享成員',
    info55: '刪除全部',
    info56: '返回',
    info57: '日歷名稱',
    info58: '分享統計',
    info59: '已分享{num}人',
    info60: '詳情',
    info61: '全部忽略',
    info62: '分享來源',
    info63: '分享範圍',
    info64: '權限等級',
    info65: '忽略',
    info66: '完整編輯',
    info67: '選中成員',
    info68: '輸入日歷名稱',
    info69: '檔掃描中{num}%',
    info70: '分享詳情',
    info71: '查看忙閑',
    info72: '查看日程詳情',
    info73: '可編輯',
    info74: '標籤顏色不能為空',
    info75: '請輸入日歷名稱',
    info76: '會議變更',
    info77: '日程時間不能為空',
    info78: '更改會議成功',
    info79: '確定要取消此會議嗎?',
    info80: '您未填寫主題,是否繼續保存？',
    info81: '時間不能為空',
    info82: '您未填寫主題,是否繼續發送？',
    info83: '請選擇分享成員',
    info84: '編輯成功',
    info85: '編輯失敗',
    info86: `不支持,% '“\\ /;| &.*等特殊字符`,
    info87: '沒有許可權創建日程',
    info88: '請確認是否刪除${mail}的訂閱日歷？ 刪除後無法繼續查看此日歷。',
    info89: '確認刪除',
    info90: '該成員已添加',
    info91: '刪除日歷時將同步清空日歷下的日程,是否繼續？',
    info92: '日程標題',
    info93: '快速編輯僅支持100字以內文字輸入',
    info94: '新建會議成功',
    info95: '新建日程成功',
    info96: '變更日程成功',
    cyle1: '重複模式',
    cyle2: '每天',
    cyle3: '每週',
    cyle4: '每月',
    cyle5: '每年',
    cyle6: '每',
    cyle7: '天',
    cyle8: '每個工作日',
    cyle9: '週的',
    cyle10: '月的',
    cyle11: '第',
    cyle12: '個',
    cyle13: '年的',
    cyle14: '月',
    cyle15: '日',
    cyle16: '結束日期',
    cyle17: '永不過期',
    cyle18: '星期一',
    cyle19: '星期二',
    cyle20: '星期三',
    cyle21: '星期四',
    cyle22: '星期五',
    cyle23: '星期六',
    cyle24: '星期日',
    cyle25: '請填寫完整活動週期',
    cyle26: '請填寫活動週期結束日期',
    cyle27: '無主題',
  },
  upload: {
    info1: '還有附件沒上傳完畢.請等待上傳完畢或取消,然後再試.',
    info2: '請至少要填寫一個參會人',
    info3: '參會人含有錯誤的郵寄地址',
  },
  list: {
    mail_quote: '郵件備註',
    input_content: '請輸入內容',
    mail_quote_add_success: '郵件備註新增成功',
    mail_quote_del_success: '您已成功刪除郵件備註',
    INBOX: '收件匣',
    Sent: '寄件匣',
    Drafts: '草稿箱',
    trash: '已刪除',
    start: '星標郵件',
    unseen: '未讀郵件',
    archive: '歸檔郵件',
    rubbishs: '垃圾郵件',
    tracking: '郵件追蹤',
    audit: '審核通知',
    attachment: '檔案管理',
    fileWebDisk: '檔案中轉站',
    file_transfer_opc: '文件中轉站空間',
    file_num: '文件數',
    used: '使用量',
    save_time: '存放時間',
    my_folder: '我的資料夾',
    tag_mail: '標籤郵件',
    auth_no_compose: '權限不符，無法寫信',
    not_move_folder: '不能移動到此資料夾',
    close_tag: '關閉全部標籤',
    no_subject: '無主題',
    income_out_mail: '往來郵件',
    write_mail: '寫郵件',
    composing_close_save_draft: '正在寫信中，關閉前是否儲存草稿？',
    forever_time: '永久有效',
    num_day: '{num} 天',
  },
  view: {
    not_support_type: '此檔案類型不支持預覽',
    loading: '拼命載入中',
    no_subject: '無主題',
    time: '時間',
    replace_send: '由{mail}代寄',
    expand: '展開',
    close: '收起',
    send_to: '傳送給',
    num_person: '等{num}人',
    to: '收件人',
    cc: '抄送人',
    bcc: '密送人',
    att: '附件',
    view_att: '查看附件',
    have_att: '{num}個',
    etc: '等',
    mail_expired: '此郵件已過期',
    mail_will_expired: '此郵件將於{date}過期',
    wait_audit: '等待{mail}審核',
    wait: '等待',
    audit: '審核',
    income_track_view: '（點選進入郵件追蹤頁面查看）',
    click_view_status: '（點擊按鈕可查看郵件狀態)',
    click_view_and_recall: '（點擊按鈕可查看郵件狀態並召回郵件）',
    withdraw: '撤回',
    risk_info: '風險提示',
    warn_info: '警戒！此郵件為外來郵件，可能有風險，請謹慎對待!',
    mail_psw_info: '這是一封加密郵件，收件者需要解密後才能查看郵件內容，郵件密碼：{psw}',
    secrity: '這是一封安全郵件，收件者將禁止對此郵件內容進行轉發、回覆、複製、列印、下載等操作',
    and: '並於',
    expired: '過期',
    and_view_once: '並且只能查閱一次',
    total: '共',
    pack_download: '打包下載',
    no_att_name: '無附件名稱',
    priview: '預覽',
    download: '下載',
    save_drive: '轉存網盤',
    back_audit: '返回審核通知',
    input_content: '請輸入內容',
    more_replay: '更多快速回覆內容',
    metting_num: '參加人數',
    invite_metting: '邀請您參加會議',
    have_people: '{num}人',
    location: '地 點',
    metting_expire: '會議已過期',
    cancel_metting: '取消會議',
    al_cancel_metting: '會議已取消',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sep: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    confirm_cancel_metting: '请确认是否取消会议?',
    back: '返回',
    edit_again: '再編輯',
    reply: '回覆',
    reply_all: '回覆全部',
    reply_all_att: '回覆全部附附件',
    fw: '轉寄',
    as_att_fw: '作為附件轉寄',
    mark: '標記',
    unread: '未讀',
    read: '已讀',
    flagged: '星標',
    cancel_flagged: '取消星標',
    top: '置頂',
    un_top: '取消置頂',
    select_tag: '選標籤',
    cacel_all_tag: '取消全部標籤',
    move: '移動',
    del: '刪除',
    real_del: '徹底刪除',
    more: '更多',
    copy: '複 製',
    down_mail: '下載郵件',
    print: '列印郵件',
    look: '查看信頭',
    new_tab_view: '新標籤頁打開',
    cancel_tag_success: '取消全部標籤成功',
    real_del_not_find: '確認要徹底刪除嗎？徹底刪除後郵件將無法找回',
    cancel_del: '已取消刪除',
    set_destory_not_view: '本封郵件設定了閱讀銷毀，無法查看信頭',
    copy_success: '複製成功',
    audit_oponion: '審核意見區',
    no_audit_info: '暫無審核資訊',
    pass: '通過',
    reject: '拒絕',
    baomi: '保密辦',
    level_audit: '{num}級審核人',
    go_audit: '去審核',
    test_info: '測試提示！',
    p1: '親愛的小夥伴，很抱歉，你已中招！',
    p2: '這是一封安全測試郵件，當你看到此頁面時，表示你的安全意識相當薄弱，屬於高危險群。當你收到任何來源不明，包含連結或附件的郵件時，注意高度警惕，不要輕易打開。如果無法判斷，請聯絡資訊中心或安全部門。',
    p3: '請觀看【郵件安全學習】影片',
    test_title: '這是一封郵件安全意識測試郵件！',
    guanbi: '關閉',
    receipt_tip: '回執提醒',
    is_send_receipt: '寄件者已要求傳送該電子郵件的已讀回執，是否傳送「已讀」回執？',
    mail_view_receipt: '郵件閱讀回執',
    subject: '主題',
    not_support: '您的瀏覽器不支援video 標籤。',
    attack_info: '很遺憾，被釣魚郵件攻擊啦╮(╯▽╰)╭來學習一下吧！學習完成後可以關閉窗口，恢復郵箱使用。',
    mail_already_set_psw: '此郵件設定了過期時間，現已過期，無法閱讀',
    warning: '警告',
    konw: '我知道了',
    psw_error: '密碼錯誤!',
    code_already_send: '驗證碼通知信已傳送，請注意查收',
    subject_mail_view_rec: '主題：郵件閱讀回執：',
    nail_view_rec_under: '郵件閱讀回執：您的以下郵件',
    at_time_read: '該收件者已經在{date}閱讀。',
    quick_replay: '快速回覆給',
    quick_replay_all: '快速回覆全部',
    quick_rq: '快捷回覆',
    mark_mail_ok: '標記為{flag}郵件成功',
    me: '我',
    replay_ok: '回覆已成功',
    risk_mail: '此郵件來自可疑的寄信地址，存取附件可能造成操作風險，是否仍要繼續？',
    strange_mail: '此郵件來自陌生郵件地址，存取附件可能造成操作風險，是否仍要繼續？',
    risk_mail_save_drive: '此郵件來自可疑的寄信地址，保存網盤可能造成操作風險，是否仍要繼續？',
    strange_mail_save_drive: '此郵件來自陌生郵件地址，保存網盤可能造成操作風險，是否仍要繼續？',
    mail_auditing_withdraw: '郵件等待審核中，請確認是否要撤回郵件',
    withdraw_ok_move_draft: '撤回成功，郵件移至草稿箱',
    risk_mail_tolink: '此郵件來自可疑的寄信地址，存取連結可能造成操作風險，是否仍要繼續？',
    strange_mail_tolink: '此郵件來自陌生郵件地址，存取連結可能造成操作風險，是否仍要繼續？',
    add_new_contact: '新建聯絡人',
    edit_contact: '編輯聯絡人',
    destory_mail_once_read: '此封郵件是閱後即焚郵件，僅能閱讀一次，請仔細閱讀',
    secrity_mail: '安全郵件',
    reading_mail_rec_code: '您正在閱讀安全郵件，{val}將會接收到驗證碼郵件',
    send_msg_code: '傳送驗證碼通知信',
    psw: '密碼',
    input_psw: '請輸入郵件密碼',
    ok: '好的',
    copy_that: '收到',
    thanks: '謝謝',
    mail_quote: '郵件備註',
    mail_quote_add_success: '郵件備註新增成功',
    mail_quote_del_success: '您已成功刪除郵件備註',
    save_to: '儲存到',
    sace_drive_ok: '成功儲存至網盤',
    go_look: '前往查看',
    my_drive: '我的網盤',
    select_save_att: '請選擇需要儲存的附件',
    add_contact: '加入到通訊錄',
    refuse_mail: '拒收此人',
    refuse_mail_not_rec: '拒收後無法收取該位址發送的郵件，請確認！',
    add_ok: '新增成功!',
    add_tag: '新建標籤',
    confirm: '確定',
    input_tag: '請輸入標籤',
    tag_color: '標籤顏色',
    input_tag_name: '請輸入標籤名稱',
    input_tag_color: '請選擇標籤顏色',
    max_five_tag: '最多加5個標籤',
    less_one_tag: '請最少選擇一個標籤',
    mail_reccall: '郵件召回',
    recieve_status: '接收狀態',
    recacll: '召回',
    recall_info: '註: 僅可召回48小時內寄出的郵件',
    recall_one_key: '一鍵召回',
  },
  mailstatus: {
    'ok': '成功',
    'sendstate': '傳送狀態',
    'deepsearch': '郵件深度追蹤',
    'succeed': '傳送成功',
    'nosucceed': '傳送不成功',
    'checkdetails': '查看詳情',
    'succeedorsend': '傳送不成功或正在發送',
    'sending': '請到郵件追蹤查詢郵件狀態',
    'insend': '正在發送',
    'abnormal': '出現異常，請重試',
    'hidden': '隱藏',
    'show': '顯示',
    'addressee': '收件人',
    'canyourecall': '能否召回',
    'time': '時間',
    'madereading': '已閱讀',
    'recall': '召回',
    'nosysadmemail': '非本系統信箱，無法召回',
    'thenrecallto': '已經被召回',
    'thenrecall': '已召回',
    'receivenoread': '已收到未閱讀',
    'recallfall': '召回失敗',
    'recallsucceed': '召回成功',
    'recallfallwitting': '召回失敗，對方已知曉',
    'inaudit': '正在審核',
    'nopass': '審核未通過',
    'mailaudit_known': '已知曉',
    'mailaudit_read': '已閱讀',
    'mailaudit_delete': '刪除',
    'mailaudit_reply': '回覆',
    'mailaudit_mark': '加標記',
    'mailaudit_mark_draft': '標記為草稿',
    'mailaudit_mark_unread': '標記為未讀',
    'mailaudit_mark_delete': '標記為未刪除',
    'mailaudit_mark_reply': '標記為未回覆',
    'mailaudit_cancel_mark': '取消加標記',
    'mailaudit_cancel_mark_draft': '取消標記為草稿',
    'mailaudit_pop_receive_del': 'POP收取並刪除',
    'mailaudit_pop_receive': 'POP收取',
    'mailaudit_recall_succeed': '召回成功',
    'mailaudit_rule_nopass': '傳送不成功（系統規則不符）',
    'mailaudit_rule_bounce': '規則退信',
    'Auditing': '等待審核',
    'AuditRefused': '規則拒絕投遞（審核規則拒絕投遞）',
    'HasAudited': '審核中',
    'AuditCallbacked': '已撤回',
    'deferred': '暫時遞送錯誤，會再嘗試',
    'bounced': '退信',
    'CallBack': '已撤回',
    'reject': '規則拒收',
    'listallrecvrej': '收寄策略退信',
    'listallsecrej': '密級策略退信',
    'domainrej': '寄件人不屬於本列表域退信',
    'listrej': '列表退信',
    'specrej': '寄件人不允許給清單寄信退信',
    'listnull': '列表為空退信',
    'undefpol': '清單未定義任何策略退信',
    'RaisingRefused': '臨時提權規則拒絕投遞',
    'SecretRefused': '密級規則拒絕投遞',
    'list_persecrej': '成員密級不符退信',
    'list_perrecvrej': '成員收寄不符退信',
    'sentrecv': '個人未通過收寄策略退信',
    'secret': '個人未通過密級策略退信',
    'sentfailed': '回送過程失敗退信',
    'mailaudit_retr': '被查看',
    'mailaudit_pop_seen': 'POP查看',
    'FilterRefused': '過濾規則拒絕投遞',
    'mailaudit_move': '被移動',
    'unknowuser': '郵件地址不存在',
    'set_topping': '置頂',
    'unset_topping': '取消置頂',
    'add_tag': '加標籤',
    'del_tag': '取消標籤',
  },
  other: {
    view_member: '查看清單成員',
    add_depart_mail: '新增部門信箱',
    psw_mail: '加密郵件',
    open_audit_code: '您正在開啟審核通知郵件，請輸入驗證碼',
    go_mail_view_code: '請前往郵件查看驗證碼',
    input_code: '請填寫驗證碼',
  },
  track: {
    mail_track: '郵件追蹤',
    input_mail_sub: '請輸入郵件主題',
    time: '時間',
    to: '收件人',
    sub: '主題',
    mail_status: '郵件狀態',
    action: '操作',
    withdraw: '撤回',
    display: '顯示',
    withdraw_ok: '撤回成功儲存至草稿箱',
  },
  audit: {
    audit_tips: '審核通知',
    input_mail_sub: '請輸入郵件主題',
    time: '時間',
    to: '寄件人',
    sub: '主題',
    action: '操作',
    auditing: '審核',
  },
  att: {
    input_att_name: '請輸入附件名稱',
    all: '全部檔',
    img: '圖片',
    audio: '多媒體',
    doc: '文檔',
    zip: '壓縮包',
    other: '其他',
    send: '傳送',
    down: '下載',
    save_drive: '儲存到網盤',
    name: '檔案名',
    no_name: '無檔案名稱',
    time: '時間',
    from: '寄件人',
    sub: '主題',
    size: '大小',
    view: '預覽',
    form_mail: '來自郵件',
    select_file: '請選擇檔案',
    select_folder: '選擇資料夾',
  },
  colmail: {
    all: '全部郵件',
    currentPage: '當前頁',
    uncheck: '不選',
    invert: '反選',
    unread: '未讀',
    emergent: '緊急',
    search_res: '搜尋結果',
    hight_search: '進階搜尋：查找到郵件{total}封',
    an_mail: '按信箱',
    an_from: '按寄件人',
    an_to: '按收件者',
    an_time: '按時間',
    an_has_att: '按有無附件',
    has_att: '有附件',
    no_att: '無附件',
    foder_no_mail: '此資料夾中沒有郵件',
    me: '我',
    read_destory: '閱後即焚郵件',
    mail_expired: '此郵件將於{date}過期',
    secrity_mail: '安全屬性郵件',
    psw_mail: '密碼郵件',
    add_psw_mail: '加密郵件***',
    will_send: '將於{date}發送',
    no_mail: '暫無選擇郵件',
    select_mails: '已選擇{num}封郵件',
    transfer: '轉寄',
    astt_transfer: '作為附件轉寄',
    mark: '標記',
    move: '移動',
    del: '刪除',
    more: '更多',
    down_mail: '下載郵件',
    upload_mail: '上傳郵件',
    selectmails: '選取{num}封郵件',
    find_ok: '查找到',
    total_num_mail: '的郵件{num}封',
    one_week: '一週內',
    o_mon: '一月內',
    t_mon: '三月內',
    since: '更早',
  },
  transfer: {
    input_att_name: '請輸入附件名稱',
    upload: '上傳檔案',
    del: '刪除',
    del_ok: '刪除成功',
    send: '傳送',
    increace_time: '續期',
    increace_time_ok: '續期成功',
    save_drive: '儲存到網盤',
    file_name: '檔案名',
    no_file_name: '無檔案名稱',
    create_time: '建立時間',
    expired: '過期時間',
    forever: '永久有效',
    size: '大小',
    download_times: '下載次數',
    preview: '預覽',
    down: '下載',
    rename: '重命名',
    rename_ok: '重命名成功',
    select_files: '請選擇檔案',
    not_support: '不支持\ / : * ? < > |',
    delete_not_down: '刪除後收到大附件的人將無法下載,確定刪除所選檔案嗎？ ',
    unread: '未讀',
    read: '已讀',
    star: '星標',
    unstar: '取消星標',
    top: '置頂',
    untop: '取消置頂',
    select_tag: '選標籤',
    mark_type_ok: '標記為{type}郵件成功',
    select_mail: '請選擇郵件',
    select_untag_mail: '請選擇取消全部標籤的郵件',
    star_ok: '星標設定成功',
    star_not_ok: '星標取消成功',
    move_to_ok: '移動到{val}成功',
    del_not_recover: '確認徹底刪除郵件嗎？刪除後，郵件將無法恢復',
    clean_del: '徹底刪除成功',
    cancel_del: '已取消刪除',
    not_transfer: '郵件設定了安全屬性，不允許轉送',
    expired_not_fw: '過期郵件不允許轉寄',
    fw: '轉寄:',
    max_fw_20: '最多允許同時轉送20封郵件',
    not_att_transfer: '郵件設定了安全屬性，不允許作為附件轉寄',
    some_ok: '部分郵件下載成功，禁止下載類型的郵件不允許下載',
    undownload: '禁止下載類型的郵件不允許下載',
    unfw: '禁止轉寄類型的郵件不允許下載',
    select_download_mail: '請選擇要下載的郵件',
    max_down_times: '一次最多允許下載20封郵件',
  },
  mailmodule: {
    no_auth: '權限不符，無法編輯草稿',
    edit_later_send: '確定要編輯定時郵件嗎? 確定後，則會取消定時郵件的傳送',
    bzinfo: '備註資訊',
    newcontact: '新建聯絡人',
    support_type: '上傳郵件只支持zip類型',
    editcontact: '編輯聯絡人',
    check_import: '偵測到可以匯入的郵件{num1}封，成功匯入{num2}封，其他重複或格式錯誤郵件已忽略。 ',
    max_select_contact: '最多選擇{num}個位址',
    depart_list: '部門信箱清單',
    already_add: '該聯絡人已新增',
    uploading: '{num}個檔案上傳中',
    all_cancel: '全部取消',
    upoad_fail: '上傳失敗',
    pause: '暫停',
    goon: '繼續',
    options: '備選項',
    confirm_cancel_task: '確定取消全部上傳任務嗎？ ',
    oversize: '檔案大小超過2G上限',
    not_upload_type: '上傳類型受限',
    zone_small: '空間不足',
    transfer_small: '檔案中轉站空間不足',
    netdisk_small: '網盤空間不足',
    search_type: '請輸入搜尋條件',
    new_mail: '你有{num}封新郵件',
    go_view: '去查看',
    save_fail: '儲存失敗',
    depart: '部門',
    toggle: '切換',
    first_change: '首次修改密碼',
    init_psw: '初始密碼',
    newpsw: '新密碼',
    confirm_psw: '確認密碼',
    set_answer: '設定答案',
    reset: '重置',
    input_q: '請輸入問題',
    change_ok: '修改成功',
    cloud_att: '從發件人郵箱發來的雲附件',
    overtime_logout: '超時了，已登出登入',
  },
}

