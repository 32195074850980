<template>

  <div class="top_action flax-align-items-center">

    <!-- 返回,判断分栏还是通栏，true分栏,false通栏 -->
    <div
      v-if="!$myState.isColumns"
      class="svg-one"
      @click="goBack"
    >
      <svg-icon
        icon-class="view_left"
        class="action_icon icos-size-14 middle"
      />
      {{ $t('message.view.back') }}
    </div>
    <!-- 再次编辑 -->

    <div
      v-if="auth_authority !== 0 && newMailInfo.mbox == 'Sent' && !(bodyHtml.protect_type === 1 && bodyHtml.x_append !== '1') "
      class="svg-one"
      @click="toCompose('editagin')"
    >
      <svg-icon
        icon-class="editAgain"
        class="action_icon icos-size-14 middle"
      />
      {{ $t('message.view.edit_again') }}
    </div>
    <!-- 回复 -->

    <div
      v-if="auth_authority !== 0 &&replyBtn"
      class="svg-one"
      @click="toCompose('reply')"
    >
      <svg-icon
        icon-class="view_reply"
        class="action_icon icos-size-14 middle"
      />
      {{ $t('message.view.reply') }}
    </div>
    <!-- 回复全部 -->

    <div
      v-if="auth_authority !== 0 && replyBtn"
      class="Opea-svg-select-noBorder Opea-svg-select-noBorder-replyAll"
    >
      <svg-icon
        icon-class="view_replyAll"
        class="icos-size-14 middle"
      />
      <span class="Opea-svg-select-noBorder-span">{{ $t('message.view.reply_all') }}</span>
      <el-select
        v-model="replyValue"
        popper-class="Opea-svg-select-popper"
        :popper-append-to-body="false"
        style="width:135%;position: absolute;left:0;top:-5px;"
      >
        <el-option
          v-for="item in replyList"
          :key="item.value"
          :label="$t(`message.view.${item.label}`)"
          :value="item.value"
          @click.native="replyClick"
        />
      </el-select>
    </div>
    <!-- 转发 -->

    <div
      v-if="auth_authority !==0 && isforwardBtn "
      class="Opea-svg-select-noBorder"
    >
      <svg-icon
        icon-class="view_forward"
        class="icos-size-14 middle"
      />
      <span class="Opea-svg-select-noBorder-span">{{ $t('message.view.fw') }}</span>
      <el-select
        v-model="forwardValue"
        popper-class="Opea-svg-select-popper"
        :popper-append-to-body="false"
        style="width:135%;position: absolute;left:0;top:-5px;"
      >
        <el-option
          v-for="item in forwardList"
          :key="item.value"
          :label="$t(`message.view.${item.label}`)"
          :value="item.value"
          @click.native="fprwardClick"
        />
      </el-select>
    </div>
    <!-- 标记 -->

    <div
      v-if="auth_authority !== 0 && auth_authority !== 1"
      class="Opea-svg-select-noBorder"
    >
      <svg-icon
        icon-class="view_makk"
        class="icos-size-14 middle"
      />
      <span class="Opea-svg-select-noBorder-span">{{ $t('message.view.mark') }}</span>
      <el-select
        v-model="makkValue"
        popper-class="Opea-svg-select-popper-mark"
        :popper-append-to-body="false"
        style="width:135%;position: absolute;left:0;top:-5px;"
      >
        <el-option
          v-for="item in makkList"
          :key="item.value"
          :label="$t(`message.view.${item.label}`)"
          :value="item.value"
          @click.native="makkClick"
        />
      </el-select>
    </div>
    <!-- 移动 -->

    <div
      v-if="auth_authority !== 0 && auth_authority !== 1"
      class="Opea-svg-select-noBorder"
    >
      <svg-icon
        icon-class="view_move"
        class="icos-size-14 middle"
      />
      <span class="Opea-svg-select-noBorder-span">{{ $t('message.view.move') }}</span>
      <el-select
        v-model="moveValue"
        popper-class="Opea-svg-select-popper-move"
        :popper-append-to-body="false"
        style="width:135%;position: absolute;left:0;top:-5px;"
        class="product-style"
      >
        <el-option
          v-for="item in move_mailbox"
          :key="item.mbox"
          :label="!item.mbox_parent_name?item.mbox_name:item.mbox_real_name"
          :value="item.mbox"
          :title="!item.mbox_parent_name?item.mbox_name:item.mbox_real_name"
          @click.native="moveClick"
        >
          <span>{{ !item.mbox_parent_name?item.mbox_name:item.mbox_real_name }}</span>
        </el-option>

        <!--
          ie滑过会闪动
          <el-option
          v-for="item in move_mailbox"
          :key="item.mbox"
          :label="!item.mbox_parent_name?item.mbox_name:item.mbox_real_name"
          :value="item.mbox"
          @click.native="moveClick"
        >
          <el-tooltip
            class="item"
            effect="light"
            placement="left-start"
          >
            <div
              slot="content"
              style="max-width:300px"
            >{{ !item.mbox_parent_name?item.mbox_name:item.mbox_real_name }}</div>
            <span>{{ !item.mbox_parent_name?item.mbox_name:item.mbox_real_name }}</span>
          </el-tooltip>
        </el-option> -->
      </el-select>
    </div>
    <!-- 删除 -->

    <div
      v-if="auth_authority !== 0 && auth_authority !== 1"
      class="Opea-svg-select-noBorder"
    >
      <svg-icon
        icon-class="view_del"
        class="icos-size-14 middle"
      />
      <span class="Opea-svg-select-noBorder-span">{{ $t('message.view.del') }}</span>
      <el-select
        v-model="delValue"
        popper-class="Opea-svg-select-popper"
        :popper-append-to-body="false"
        style="width:135%;position: absolute;left:0;top:-5px;"
      >
        <el-option
          v-for="item in delList"
          :key="item.value"
          :label="$t(`message.view.${item.label}`)"
          :value="item.value"
          @click.native="delClick"
        />
      </el-select>
    </div>
    <!-- 更多 -->
    <div class="Opea-svg-select-noBorder">
      <svg-icon
        icon-class="view_more"
        class="icos-size-14 middle"
      />
      <span class="Opea-svg-select-noBorder-span">{{ $t('message.view.more') }}</span>
      <el-select
        v-model="moreValue"
        popper-class="Opea-svg-select-popper"
        :popper-append-to-body="false"
        style="width:135%;position: absolute;left:0;top:-5px;"
      >
        <el-option
          v-for="item in moreList"
          :key="item.value"
          :label="$t(`message.view.${item.label}`)"
          :value="item.value"
          @click.native="moreClick"
        />
      </el-select>
    </div>
    <!-- 查看信头 -->
    <el-dialog
      title=""
      :visible.sync="dialogLook"
      :modal-append-to-body="false"
      :show-close="false"
      width="800px"
    >
      <div v-html="lookHtml" />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="copypsw">{{ $t('message.view.copy') }}</el-button>
        <el-button
          type="primary"
          @click="dialogLook = false"
        >{{ $t('message.btnText.ok') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import { mboxListToTree } from '@/utils/mbox'
import serialize from '@/utils/serialize'
import { copy, transformClass } from '@/utils/utils.js'
export default {
  props: {
    mailInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    bodyHtml: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      replyList: [{
        value: 'replyall',
        label: 'reply_all',
      }, {
        value: 'replyallatt',
        label: 'reply_all_att',
      }],
      replyBtn: true,
      replyValue: '',
      delList: [],
      delValue: '',
      forwardList: [{
        value: 'forward',
        label: 'fw',
      }, {
        value: 'asattforward',
        label: 'as_att_fw',
      }],
      oldforwardList: [{
        value: 'forward',
        label: 'fw',
      }, {
        value: 'asattforward',
        label: 'as_att_fw',
      }],
      isforwardBtn: true,
      forwardValue: '',
      moveValue: '',
      makkList: [{
        value: 'seen:0',
        label: 'unread',
      }, {
        value: 'seen:1',
        label: 'read',
      }, {
        value: 'flagged:1',
        label: 'flagged',
      }, {
        value: 'flagged:0',
        label: 'cancel_flagged',
      }, {
        value: 'topping:1',
        label: 'top',
      }, {
        value: 'topping:0',
        label: 'un_top',
      }, {
        value: '7',
        label: 'select_tag',
      }, {
        value: '8',
        label: 'cacel_all_tag',
      }],
      makkValue: '',
      moreList: [{
        value: 'down',
        label: 'down_mail',
      }, {
        value: 'print',
        label: 'print',
      }, {
        value: 'look',
        label: 'look',
      }, {
        value: 'new',
        label: 'new_tab_view', // 地址/whole-view
      }],
      oldMoreList: [{
        value: 'down',
        label: 'down_mail',
      }, {
        value: 'print',
        label: 'print',
      }, {
        value: 'look',
        label: 'look',
      }, {
        value: 'new',
        label: 'new_tab_view', // 地址/whole-view
      }],
      moreValue: '1',
      newMailInfo: this.mailInfo,
      newBodyHtml: this.bodyHtml, // 改封信的数据
      lookHtlCopy: '', // 粘贴的心头内容
      lookHtml: '', // 信头内容
      dialogLook: false, // 信头弹窗
      downAttaReq: {}, // 下载邮件参数
      auth_authority: 9, // 授权邮箱权限
      composeSubject: this.$t('message.mbox.newmail'),
      move_mailbox: [], // 可移动文件夹
    }
  },
  computed: {
    /** 监听列表星标 */
    isAuthWatch() {
      return this.$store.state.app.auth_authority
    },
    /** 监听邮箱是否更改 */
    mbox_list_watch() {
      return this.$myState.mbox_list_plus_watch
    },
  },
  watch: {
    mailInfo: function(newVal, oldVal) {
      this.newMailInfo = newVal
    },
    bodyHtml: function(newVal, oldVal) {
      this.newBodyHtml = newVal
      // 是否是在回收站（已删除）和垃圾邮件
      if (this.newBodyHtml.mbox === 'trash' || this.newBodyHtml.mbox === 'rubbishs') {
        this.delList = [{
          value: 'delAll',
          label: 'real_del',
        }]
      } else {
        this.delList = [{
          value: 'del',
          label: 'del',
        }, {
          value: 'delAll',
          label: 'real_del',
        }]
      }

      // 禁止转发
      if (this.newBodyHtml.untransmit === '1' || this.newBodyHtml.undownload === '1') {
        this.isforwardBtn = false
      } else {
        this.isforwardBtn = true
      }
      // 禁止回复
      if (this.newBodyHtml.unresponse === '1') {
        this.replyBtn = false
      } else {
        this.replyBtn = true
      }
      this.moreList = this.oldMoreList
      // 是否阅读后销毁
      if (this.$store.state.mail.isDestroy) {
        this.moreList = this.moreList.filter(function check(value) {
          return value.value === 'look'
        })
      }
      // 禁止下载+禁止打印+禁止转发
      if ((this.newBodyHtml.undownload === '1' || this.newBodyHtml.untransmit === '1') && this.newBodyHtml.unprint === '1') {
        // console.log('禁止下载+禁止打印+禁止转发')
        this.moreList = this.moreList.filter(function check(value) {
          return value.value !== 'down' && value.value !== 'print'
        })
        return false
      }
      // 是否禁止下载
      if (this.newBodyHtml.undownload === '1' || this.newBodyHtml.untransmit === '1') {
        // console.log('单个禁止下载')
        this.moreList = this.moreList.filter(function check(value) {
          return value.value !== 'down'
        })
      }
      // 禁止打印
      if (this.newBodyHtml.unprint === '1') {
        // console.log('单个禁止打印')
        this.moreList = this.moreList.filter(function check(value) { return value.value !== 'print' })
        // this.forwardList = this.oldforwardList
      }
      // this.forwardList = this.oldforwardList
    },
    isAuthWatch(newVal, oldVal) {
      this.auth_authority = newVal
    },
    mbox_list_watch: {
      handler(value) {
        this.reset_mbox_list()
      },
      immediate: true,
    },
  },
  mounted() {
    // this.getListMenu()
    this.$nextTick(() => {
      this.auth_authority = this.$store.state.app.auth_authority// 授权邮箱权限 0 读信 1写信 2管理 9未开启
      if (this.newMailInfo.readed_destory === '1') {
        this.moreList.pop()
      }
      // this.reset_mbox_list()
    })
  },
  methods: {
    // 邮箱列表发生变化后更新本地数据
    reset_mbox_list() {
      const move_mailbox = this.$myData.mbox_list
      console.log(this.$myData.mbox_list, '移动文件夹')
      this.move_mailbox = []
      move_mailbox.forEach(item => {
        if (item.mbox !== 'Drafts' && item.mbox !== this.newMailInfo.mbox) this.move_mailbox.push(item)
      })
    },
    // 标记
    makkClick() {
      // 选标签
      if (this.makkValue === '7') {
        this.$parent.$refs.tabListBox.show()
        console.log('点击')
        // return false
      } else if (this.makkValue === '8') { // 取消全部标签
        // this.$message.success('移除全部')
        this.checkedTab = []
        this.isDel = false
        // 向父组件传值
        // this.$emit('showTabList', this.checkedTab)
        const req = {
          'module': 'mail',
          'require': {
            action: 'addMailTag',
            tag_id: [],
            uid: [this.newMailInfo.uid],
            uidvalidity: this.newMailInfo.uidvalidity,
            uv: this.newMailInfo.uv,
          },
        }
        this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
          if (res.result.status) {
            // this.$parent.tabList = []
            // this.$store.state.tagsView.mail_list.forEach(item => {
            //   if (this.newMailInfo.uid === item.uid && this.newMailInfo.uid === item.uid) item.tag = []
            // })
            this.$message.success(this.$t('message.view.cancel_tag_success'))
            const objList = [{
              uidvalidity: this.newMailInfo.uidvalidity,
              uid: this.newMailInfo.uid,
              data: { tags: 0 },
            }]
            this.$myApi.updateMailStatus(objList)
          }
        })
        return false
      } else {
        const flagsArr = this.makkValue.split(':')
        const outer = {
          seen: 'seenFlag',
          flagged: 'starFlag',
          topping: 'topping',
        }
        console.log(flagsArr, this.makkValue, outer[flagsArr[0]], 'flagsArr')
        // 数据同步
        /** 标为已读 */
        const mboxList = [{
          mbox: transformClass.mbox(this.mailInfo.uidvalidity, this.$myData.mbox_list),
          uidList: this.newMailInfo.uid,
          uidvalidity: this.newMailInfo.uidvalidity,
          uv: this.newMailInfo.uv,
        }]
        const mailFlags = {
          flagsName: flagsArr[0], // 操作类型
          flagsValue: flagsArr[1], // 值
          mboxList: mboxList,
          outer: outer[flagsArr[0]], // 自己定义的值--页签同步用
          isShowMessage: true, // 是否显示操作成功提示
        }
        this.$parent.flagSetAction(mailFlags)
      }

      this.makkValue = ''
    },
    // 删除
    delClick() {
      if (this.delValue === 'del') {
        this.delAndMovemail('trash')
      } else {
        this.$confirm(this.$t('message.view.real_del_not_find'), this.$t('message.btnText.ok'), {
          confirmButtonText: this.$t('message.btnText.ok'),
          cancelButtonText: this.$t('message.btnText.cancel'),
        }).then(() => {
          this.delAndMovemail('NsmailSending')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('message.view.cancel_del'),
          })
        })
      }
      this.delValue = ''
    },
    // getListMenu() {
    //   const params = {
    //     'module': 'mbox',
    //     'require': {
    //       'action': 'getList',
    //     },
    //   }
    //   this.$axios.post(this.$apiData.mail, { req: serialize(params) })
    //     .then((res) => {
    //       res.result.mboxList.forEach(el => {
    //         const isP = !(el.mbox === 'Drafts' || el.mbox === this.newMailInfo.mbox)
    //         if (isP) {
    //           this.moveList.push(el)
    //         }
    //       })
    //     })
    // },
    // 移动
    moveClick() {
      this.delAndMovemail(this.moveValue)
      this.moveValue = ''
    },
    downMail() {
      this.$emit('downMail')
    },
    // 查看更多
    moreClick() {
      if (this.moreValue === 'down') {
        this.downMail()
      }
      if (this.moreValue === 'print') {
        this.moreValue = ''
        // 禁止打印
        this.newMailInfo.isPrintMail = true
        const obj = {
          uid: this.$parent.mailInfo.uid,
          uidvalidity: this.$parent.mailInfo.uidvalidity,
        }
        window.open(window.location.origin + '/#/print?' + JSON.stringify(obj))
      }
      if (this.moreValue === 'look') {
        if (this.$store.state.mail.isDestroy) {
          this.$message.error(this.$t('message.view.set_destory_not_view'))
          return false
        }
        const req = {
          'module': 'mail',
          'require': {
            action: 'getHeader', mbox: this.newMailInfo.mbox, uid: this.newMailInfo.uid, uidvalidity: this.newMailInfo.uidvalidity, uv: this.newMailInfo.uv,
          },
        }
        this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
          this.dialogLook = true
          this.lookHtlCopy = res.result.header
          this.lookHtml = res.result.header.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '\n<br/>').replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
          this.moreValue = ''
        })
      }
      if (this.moreValue === 'new') {
        // 新标签页打开，加入标签栏
        this.moreValue = ''
        // this.$router.push({ path: '/whole-view', query: this.newMailInfo })
        console.log(this.$parent._props.readMailReqData, 'this.$parent._props.readMailReqData')
        this.$myApi.mainTab.showWholeMail('mail', this.$parent._props.readMailReqData)
      }
      this.moreValue = ''
    },
    // 回复全部、回复全部带附件
    replyClick() {
      this.toCompose(this.replyValue)
      this.replyValue = ''
    },
    // 转发、作为附件转发
    fprwardClick() {
      this.toCompose(this.forwardValue)
      this.forwardValue = ''
    },
    // 复制
    copypsw() {
      this.dialogLook = false
      this.$message({
        message: this.$t('message.view.copy_success'),
        type: 'success',
      })
      copy.copyText(this.lookHtlCopy)
    },
    // 删除、移动邮件
    delAndMovemail(mbox) {
      const uidvalidity = transformClass.uidvalidity(mbox, this.$myData.mbox_list)
      const req = {
        'module': 'mail',
        'require': {
          action: 'move',
          mbox: mbox,
          uidvalidity: uidvalidity,
          mboxList: [{
            mbox: this.newMailInfo.mbox,
            uidList: this.newMailInfo.uid,
            uidvalidity: this.newMailInfo.uidvalidity,
            uv: this.newMailInfo.uv,
          }],
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        // 移动完毕的删除标签栏，如果不删除标签栏，需要更新标签栏参数，需要移动接口给返回新uid值
        const key = mbox === 'NsmailSending' ? 'deleteMail' : 'moveMail'
        // 自己请求接口
        // const objList = [{
        //   uidvalidity: this.newMailInfo.uidvalidity,
        //   uid: this.newMailInfo.uid,
        //   data: { [key]: uidvalidity },
        // }]
        const objList = []
        if (mbox === 'NsmailSending') {
          objList.push({ uidvalidity: this.newMailInfo.uidvalidity, uid: this.newMailInfo.uid, data: { [key]: uidvalidity }})
        } else {
          const mboxList = res.result.mboxList
          mboxList.forEach(item => {
            item.uid_list.forEach(i => {
              objList.push({ uidvalidity: item.uidvalidity, uid: i.uid, org_uid: i.org_uid, data: { [key]: uidvalidity }})
            })
          })
        }
        this.$myApi.updateMailStatus(objList)
        this.$store.commit('app/isFoldersettClear_SET', true)
        // 删除后应该走下一封邮件  如果是最后一封 应该返回到列表
        this.$parent.prevMail('next')
      })

      // const data = {
      //   type: 'readMail',
      //   mbox: mbox,
      //   uidvalidity: this.newMailInfo.uidvalidity,
      //   uv: this.newMailInfo.uv,
      //   uid: this.newMailInfo.uid,

      // }
      // this.$store.commit('tagsView/TO_MOVE_MAIL_SET', data)
    },
    // 标记邮件
    // makkUpdate(actions, val) {
    //   const req = {
    //     'module': 'mail',
    //     'require': {
    //       action: 'updateFlags',
    //       flags: { [actions]: val },
    //       mboxList: [{ mbox: this.newMailInfo.mbox, uidList: this.newMailInfo.uid, uidvalidity: this.newMailInfo.uidvalidity, uv: this.newMailInfo.uv }],
    //     },
    //   }
    //   this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {

    //   })
    // },
    // 返回上一页、返回列表
    goBack() {
      this.$myApi.mainTab.goBackMain(this.$parent._props.readMailReqData.mailFolderType)
    },
    // 跳转到写信
    toCompose(type) {
      // debugger
      const arr = [{ reply: this.$t('message.view.reply') }, {
        value: 'reply',
        label: this.$t('message.view.reply') + ':',
      }, {
        value: 'replyall',
        label: this.$t('message.view.reply') + ':',
      }, {
        value: 'editagin',
        label: '', // 再次编辑
      }, {
        value: 'replyallatt',
        label: this.$t('message.view.reply') + ':',
      }, {
        value: 'forward',
        label: this.$t('message.view.fw') + ':',
      }, {
        value: 'asattforward',
        label: this.$t('message.view.fw') + ':',
      }]
      arr.map(item => {
        type === item.value ? this.composeSubject = item.label : ''
      })
      const data = this.$parent.bodyHtml
      if (type === 'editagin') {
        this.$myApi.mainTab.showComposeMail({ type: type, mbox: data.mbox, uid: data.uid, subject: this.composeSubject + data.subject, composeData: data })
      } else {
        this.$myApi.mainTab.showComposeMail({ type: type, subject: this.composeSubject + data.subject, composeData: data })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
/deep/ .el-input__inner {
  border: none;
  background: none;
  color: #171717;
  width: 60px;
  padding: 0;
  padding-right: 25px;
}

.top_action {
  padding: 12px 0 11px 0;
  border-bottom: 2px solid #e2e2e2;
  .svg-one{
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
  }
  .action_icon {
    margin-right: 4px;
  }
  .type_svgicon{
    vertical-align: middle;
  }
}

</style>
