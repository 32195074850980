<template>
  <div class="calendar">
    <div class="left">
      <!-- <p>{{ month[convert(cardInfo.meeting_time_str).cMonth] }}</p>
      <p class="day">{{ convert(cardInfo.meeting_time_str).cDay }}</p> -->
      <div class="calendar1">
        <div class="month theme-background-color">{{ $t(`message.view.${month[convert(cardInfo.meeting_time_str).cMonth]}`) }}</div>
        <div class="day">{{ convert(cardInfo.meeting_time_str).cDay }}</div>
      </div>
      <p class="week">{{ $t(`message.calendar.${week[convert(cardInfo.meeting_time_str).nWeek]}`) }}</p>
      <p style="white-space: nowrap;padding: 10px 0;">{{ $t('message.view.metting_num') }}：{{ cardInfo.meeting_num }}</p>
    </div>
    <div class="right">
      <p>{{ cardInfo.ky_organizer[0] }}，{{ $t('message.view.invite_metting') }}</p>
      <h2 style="word-break: break-all;font-size:114%">{{ cardInfo.title||$t('message.view.no_subject') }}</h2>
      <!-- <p>参会人：王五；mingming；123123123@qq.com</p> -->
      <p>{{ cardInfo.meeting_time_str }}</p>
      <p style="word-break: break-all;">{{ $t('message.view.location') }}：{{ cardInfo.ky_location||'---' }}</p>
      <p v-if="over===1">
        <button
          type="button"
          class="btn1"
        >{{ $t('message.view.metting_expire') }}</button>
      </p>
      <div v-else-if="cardInfo.is_organizer===1">
        <el-button
          v-if="meeting_status==='0'"
          size="mini"
          @click="cancelInviteMetting"
        >{{ $t('message.view.cancel_metting') }}</el-button>
        <button
          v-if="meeting_status==='5'"
          type="button"
          class="btn1"
        >{{ $t('message.view.al_cancel_metting') }}</button>
      </div>
      <div v-else>
        <responseMetting
          v-if="!['0'].includes(String(meeting_status))"
          :click-info="cardInfo"
          :status="meeting_status"
          @changeStatus="changeMettingStatus"
        />
      </div>

    </div>
  </div>
</template>

<script>
import responseMetting from '@/views/home-view/components/calendar-work/components/responseMetting.vue'
import serialize from '@/utils/serialize'
import calendar from 'js-calendar-converter'
export default {
  components: { responseMetting },
  props: {
    cardInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      meeting_status: '1',
      over: 2,
      month: {
        1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec',
      },
      week: {
        1: 'cyle18', 2: 'cyle19', 3: 'cyle20', 4: 'cyle21', 5: 'cyle22', 6: 'cyle23', 7: 'cyle24',
      },
    }
  },
  mounted() {
    console.log(this.cardInfo)
    this.over = this.cardInfo.over
    this.meeting_status = String(this.cardInfo.meeting_status)
  },
  methods: {
    changeMettingStatus(status) {
      this.meeting_status = String(status)
    },
    // 取消会议邀请
    cancelInviteMetting(id) {
      this.$confirm(this.$t('message.view.confirm_cancel_metting'), this.$t('message.view.cancel_metting'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
        type: 'warning',
      }).then(async() => {
        const params = {
          module: 'calDav',
          require: {
            action: 'cancel_meeting',
            cal_obj_id: this.cardInfo.cal_obj_id,
          },
        }
        const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
        if (res.status === 200) {
          this.meeting_status = '5'
        }
      }).catch(() => {

      })
    },
    convert(str) {
      const regex = /(\d{4}-\d{2}-\d{2})/
      const match = str.match(regex)
      const date = match ? match[1] : null
      const _date = date.split('-')
      const _dateF = calendar.solar2lunar(_date[0], _date[1], _date[2])
      return _dateF
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar{
    border: 1px solid #E2E2E2;
    display: flex;
    margin: 20px;
    .left{
      padding: 20px;
        background: #EEEEEE;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border-right: 1px solid #E2E2E2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .day{
            font-size: 28px;
            font-weight: 500;
            // padding: 30px;
        }
    }
    .right{
       padding: 20px;
       display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: stretch;
       .reselect{
            display: inline-block;
            padding: 3px 10px;
            border-left: 1px solid #dcdfe6;
            margin-left: 3px;
            .title{
                padding-left: 5px;
            }
        }
    }
}
.btn1{
  display: inline-block;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
}
.calendar1{
  border: 1px solid #ddd;
  width: 60px;
  margin-bottom: 30px;
  // height: 80px;
  .day{
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fff;
  }
  .month{
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-bottom: 1px solid #ddd;
    color: #fff;
  }
}
</style>
