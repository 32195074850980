import { transformClass } from '@/utils/utils'
let stateInfo = {}
const state = {
  originData: [],
  contact_type: '',
  contacttitle: '',
  personalCache: '',
  outCache: '',
  watchAddcontact: false,
  updateGroup: false,
  userLists: [],
  persongroup: [],
  personcontact: [],
  enterpriceCache: {},
  deptMails: {},
  allPersonListId: '',
  clickPersonNode: null,
  fromOrign: 'person',
  allandungroup: [],
}
const mutations = {
  SETTING_GROUP_ID: (state, data) => {
    state.originData = data
  },
  SETTING_CONTACT_TYPE: (state, data) => {
    state.contact_type = data
  },
  SETTING_CONTACT_TITLE: (state, data) => {
    state.contacttitle = data
  },
  SETTING_PERSONAL_CACHE: (state, data) => {
    state.personalCache = data
  },
  SETTING_OUT_CACHE: (state, data) => {
    state.outCache = data
  },
  SETTING_UPDATE_STATUS: (state) => {
    state.watchAddcontact = !state.watchAddcontact
  },
  SETTING_UPDATE_GROUP: (state) => {
    state.updateGroup = !state.updateGroup
  },
  SETTING_ENTERPRICE_CACHE: (state, data) => {
    state.enterpriceCache = data
  },
  SETTING_USERS_LIST: (state, data) => {
    state.userLists = data
  },
  SETTING_PERSON_GROUP: (state, data) => {
    state.persongroup = data
  },
  SETTING_PERSON_CONTACT: (state, data) => {
    state.personcontact = data
  },
  SETTING_DEPT_MAILS: (state, data) => {
    state.deptMails[data.id] = data
  },
  SETTING_ALLPERSONLISTID: (state, data) => {
    state.allPersonListId = data
  },
  SETTING_clickPersonNode: (state, data) => {
    state.clickPersonNode = data
  },
  SETTING_ALL_UNGROUP: (state, data) => {
    state.allandungroup = data
  },
  SETTING_FROM_ORIGIN: (state, data) => {
    state.fromOrign = data
  },
  CHANGE_CHECKED_STATUS: (state, data) => {
    state.userLists = state.userLists.map(user => {
      user.checked = false
      return user
    })
  },
  stateDataClear: (state, data) => {
    stateInfo = transformClass.deepClone(state)
  },
  stateDataSet: (state, data) => {
    for (const key in stateInfo) {
      state[key] = stateInfo[key]
    }
  },
}
export default {
  namespaced: true,
  // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
  // 例： SET_NAME => user/SET_NAME
  state,
  mutations,
}
