<template>
  <div
    v-if="selfActived"
    class="home-page-container"
  >
    <div class="home-page-top-container">
      <div class="top-one-user-inform-container">
        <div class="user-abbr user-abbr-theme"><span class="user-abbr-span">{{ userConfig.name.slice(0, 1) }}</span></div>
        <div class="user-inform-name">
          <div class="user-inform-name-one">{{ userConfig.name }}，{{ $t(`message.homepage.${timeState}`) }}</div>
          <div class="user-inform-name-two">({{ $store.state.app.user_mail_address }})</div>
        </div>
      </div>
      <div class="top-two-mail-num-container">
        <div
          v-for=" (val, key) in upper_part"
          :key="key"
          class="mail-num-item"
          @click="upperPartAction(val)"
        >
          <div class="svg-bg">
            <svg-icon
              :icon-class="val.icon"
            />
          </div>
          <div class="mail-num-label">
            <div
              class="flex1-items-center"
            >{{ $t(`message.homepage.${val.mbox_name}`) }}</div>
            <div
              class="flex1-items-center"
              style="font-size: 140%;"
            >{{ val.num }}</div>
          </div>
        </div>
      </div>
      <div class="top-three-volume-num-container">
        <div
          v-for=" (val, key, i) in volumeObj"
          :key="key"
          style="display: flex;align-items: center;"
        >
          <div
            v-if="val.show"
            class="home-page-volume-item-main"
          >
            <div>
              <div class="volume-item-label">{{ $t(`message.homepage.${val.label}`) }}</div>
              <div style="display: inline-block;">
                <span class="home-page-volume-item-span">{{ key==='max_mail_num'?$t('message.homepage.limit'):$t('message.homepage.total') }}</span>
                <span class="home-page-volume-item-spanright">
                  <span v-if="key==='max_mail_num'">{{ val.max_num }}{{ $t('message.homepage.envelope') }}</span>
                  <span v-else>{{ val.max_num | sizefilter }}</span>
                </span>
              </div>
            </div>
            <div style="margin-top: 10px;">
              <div class="volume-item-label">
                <el-progress
                  v-if="((val.used/val.max_num)*100)"
                  :percentage="((val.used/val.max_num)*100)"
                  :color="customColors"
                  :show-text="false"
                  style="width:90px;height:8px;display:inline-block;"
                />
                <span
                  v-else
                  style="height: 6px; background-color: rgb(235, 238, 245); width: 90px;display:block;border-radius:100px"
                />
              </div>
              <div style="display: inline-block;">
                <span class="home-page-volume-item-span">{{ $t(`message.homepage.used`) }}</span>
                <span class="home-page-volume-item-spanright">
                  <span v-if="key==='max_mail_num'">{{ val.used }}{{ $t('message.homepage.envelope') }}</span>
                  <span v-else>{{ val.used | sizefilter }}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="i!=3"
            style="width: 1px;height: 30px;margin-left: 40px;margin-right: 40px;
            border: 1px solid #BABABA;"
          />
        </div>
      </div>
      <div class="top-four-set-container">
        <div style="margin-right: 25px;">
          <div class="top-four-set-title">{{ $t('message.homepage.recentlogin') }}</div>
          <div class="top-four-set-main">
            <div style="display:inline-block">{{ $t('message.homepage.recentloginstatus') }}：</div>
            <div class="mar-top14">{{ recordsObj.last_login_time }}</div>
            <div
              v-show="recordsObj.last_login_ip_address"
              class="mar-top14"
            >
              {{ recordsObj.last_login_ip }}<span v-show="recordsObj.last_login_ip_address">（{{ recordsObj.last_login_ip_address }}）</span>
            </div>
          </div>
        </div>
        <div style="margin-right: 25px;">
          <div class="top-four-set-title">{{ $t('message.homepage.lastchangepsw') }}</div>
          <div class="top-four-set-main">
            <div style="display:inline-block">{{ $t('message.homepage.lastchangedpsw') }}：</div>
            <div class="mar-top14">{{ recordsObj.password_update_time }}</div>
            <div

              class="mar-top14"
            >
              {{ recordsObj.password_update_ip }}<span v-if="recordsObj.password_update_ip_address!==''">（{{ recordsObj.password_update_ip_address }}）</span>
            </div>
          </div>
        </div>
        <div>
          <div class="top-four-set-title">{{ $t('message.homepage.mysetting') }}</div>
          <div class="top-four-set-main">
            <div>{{ $t('message.homepage.autoreplaysetting') }}：{{ recordsObj.reply=='1'? $t('message.homepage.on'): $t('message.homepage.off') }}</div>
            <div
              v-if="$store.state.app.user_permissions.open_client==1"
              class="mar-top14"
            >
              {{ $t('message.homepage.clientauthcode') }}：{{ recordsObj.client_side=='1'? $t('message.homepage.on'): $t('message.homepage.off') }}
            </div>
            <div
              v-if="$store.state.app.user_permissions.autoForward==1"
              class="mar-top14"
            >
              {{ $t('message.homepage.autoforword') }}：{{ recordsObj.autoforward=='1'? $t('message.homepage.on'): $t('message.homepage.off') }}
            </div>
            <div
              v-if="$store.state.app.user_permissions.autoForward==1&&recordsObj.autoforward=='1'"
              class="mar-top14"
            >
              {{ $t('message.homepage.forword') }}：
              <el-tooltip
                class="item"
                effect="light"
                :content="recordsObj.autoforward_mail"
                placement="top-start"
              >
                <span>{{ recordsObj.autoforward_mail | autoforward_mailFilter }}</span>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { transformClass, deepCopy } from '@/utils/utils.js'
export default {
  filters: {
    // 大小（接口返回字节）
    sizefilter(size) {
      return transformClass.mailSize(size)
    },
    autoforward_mailFilter(val) {
      let str = ''
      if (val.length > 20) str = val.substr(0, 17) + '...'
      else str = val
      return str
    },
  },
  data() {
    return {
      selfActived: false,
      user_mail: this.$store.state.app.userInfo.user_mail,

      customColors: [
        { color: '#5E636D', percentage: 80 },
        { color: '#FF9900', percentage: 90 },
        { color: '#D0161B', percentage: 100 },
      ],
      upper_part: {
        unseen: {
          mbox_name: 'unread',
          icon: 'page-unseen',
          num: 0,
          path: 'unseen',
          mbox: 'unseen',
          'type': 'folder',
          'menuType': 'mbox',
          'uidvalidity': 'unseen',
          'uv': 'unseen',
        },
        flagged_flag: {
          mbox_name: 'stared',
          icon: 'page-start',
          num: 0,
          path: 'star',
          mbox: 'start',
          'type': 'folder',
          'menuType': 'mbox',
          'uidvalidity': 'start',
          'uv': 'start',
        },
        unaudit: {
          mbox_name: 'notification',
          icon: 'page-audit',
          path: 'audit',
          num: 0,
          to_audit_total: 'to_audit_total',
          type: 'folder',
          menuType: 'other',
          uidvalidity: 'audit',
        },
        deeptrack: {
          mbox_name: 'track',
          icon: 'page-tracking',
          path: 'tracking',
          num: 0,
          mbox: 'tracking',
          type: 'folder',
          menuType: 'other',
          uidvalidity: 'tracking',
        },
      },
      volumeObj: {
        max_mail_num: {
          label: 'maillimit',
          max_num: 0, // 上限
          used: 0, // 已用
          icon: 'home_page_max_mail_num',
          show: true,
        },
        max_mailbox_space: {
          label: 'mailcapacity',
          max_num: 0, // 上限
          used: 0, // 已用
          icon: 'home_page_max_mailbox_space',
          show: true,
        },
        max_transfer_station_space: {
          label: 'tansfercapacity',
          max_num: 0, // 上限
          used: 0, // 已用
          icon: 'home_page_max_transfer_station_space',
          show: true,
        },
        max_netdisk_space: {
          label: 'drivecapacity',
          max_num: 0, // 上限
          used: 0, // 已用
          icon: 'home_page_max_netdisk_space',
          show: true,
        },
      },
      recordsObj: { // 记录数据
        last_login_time: '', // 最近登录时间
        last_login_ip: '', // 最近登录ip
        last_login_ip_address: '',	 // 最近登录ip地址
        password_update_time: '',	 // 修改密码时间
        password_update_ip: '',	 // 修改密码ip
        password_update_ip_address: '', // 修改密码ip地址
        autoforward: '',	// 是否开启自动转发（1开启）
        autoforward_mail: '', // 自动转发邮箱
        reply: '', // 是否开启自动回复（1开启）
        client_side: '', // 是否开启客户端（1开启）
      },
      userConfig: window._kglobal_myState.userConfig,
    }
  },
  computed: {
    timeState() {
      /** 早上：6：00--8：59
       * 上午：9:00--11:59
       * 中午：12--13：59
       * 下午：14：00---17：59
       * 晚上：18：00--23，59
       * 凌晨：24：00--05：59
       * */
      // 获取当前时间
      const timeNow = new Date()
      // 获取当前小时
      const hours = timeNow.getHours()
      // 设置默认文字
      let text = ''
      // 判断当前时间段
      if (hours >= 0 && hours < 6) {
        text = 'lingchen'
      } else if (hours >= 6 && hours < 9) {
        text = 'zaoshang'
      } else if (hours >= 9 && hours < 12) {
        text = 'shangwu'
      } else if (hours >= 12 && hours < 14) {
        text = 'zhongwu'
      } else if (hours >= 14 && hours < 18) {
        text = 'xiawu'
      } else if (hours >= 18 && hours < 24) {
        text = 'wanshang'
      }
      // 返回当前时间段对应的状态
      return text
    },
  },
  mounted() {

  },
  created() {
    const thisobj = this
    this.$myApi.activeModuleHome = (data, options) => {
      if (!thisobj.selfActived) {
        thisobj.selfActived = true
      }
      thisobj.initLoadAll()
      this.$myApi.routeSet('home')
    }
  },
  methods: {
    initLoadAll() {
      const params = {
        'module': 'user',
        'require': {
          'action': 'indexPage',
        },
      }
      this.$axios.post(this.$apiData.mail, { req: JSON.stringify(params) })
        .then((res) => {
          const data = res.result
          this.upper_part.unseen.num = data.unseen // 未读邮件数
          this.upper_part.flagged_flag.num = data.flagged_flag // 星标邮件数
          this.upper_part.unaudit.num = data.unaudit // 待审核记录数
          this.upper_part.deeptrack.num = data.deeptrack // 邮件追踪数
          this.volumeObj.max_mail_num.max_num = data.max_mail_num // 邮件上限
          this.volumeObj.max_mail_num.used = data.used_mail_num // 已用邮件
          this.volumeObj.max_mailbox_space.max_num = data.max_mailbox_space // 邮箱空间上限
          this.volumeObj.max_mailbox_space.used = data.used_mailbox_space // 已用邮箱空间
          this.volumeObj.max_transfer_station_space.max_num = data.max_transfer_station_space // 中转站容量上限
          this.volumeObj.max_transfer_station_space.used = data.used_transfer_station_space // 已用中转站容量
          this.volumeObj.max_netdisk_space.show = this.$store.state.app.auth_info.curr_auth_mail ? (this.$store.state.app.auth_authority === 2 && this.$store.state.app.user_permissions.netdisk === 1) : this.$store.state.app.user_permissions.netdisk === 1
          this.volumeObj.max_netdisk_space.max_num = data.max_netdisk_space // 网盘空间上限
          this.volumeObj.max_netdisk_space.used = data.used_netdisk_space // 已用网盘空间
          // this.recordsObj = data
          for (const key in this.recordsObj) {
            if (data[key]) this.recordsObj[key] = data[key]
          }
        })
    },
    upperPartAction(val) {
      if (this.$store.state.app.user_permissions.mailbox !== 1) {
        return this.$message({
          type: 'error',
          message: this.$t('message.homepage.noEmailPermission'),
        })
      }
      const path = val.path
      val = deepCopy(val)
      val.mbox_name = this.$t(`message.homepage.${val.mbox_name}`)
      if (path === 'unseen') {
        this.$myData.tabShowMainFlag = false
        this.$myApi.mainTab.showMain(val)
      } else if (path === 'star') {
        this.$myData.tabShowMainFlag = false
        this.$myApi.mainTab.showMain(val)
      } else if (path === 'audit') {
        this.$myApi.mainTab.showAudit(val)
      } else if (path === 'tracking') {
        this.$myApi.mainTab.showTracking(val)
      } else {
        this.$myData.tabShowMainFlag = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.home-page-volume-item-span{
    display: inline-block;
    width: 45px;
    color: #bababa;
  }
.home-page-volume-item-spanright{
  display: inline-block;
  width: 70px;
  color: #bababa;
  text-align: right;
}
.home-page-volume-item-main {
  width: 220px;
}

.app-view-class14 {
  font-size: 14px !important;
  .home-page-volume-item-main {
        width: 220px;
    }

    .home-page-volume-item-span {
        width: 45px;
    }

    .home-page-volume-item-spanright {
        width: 70px;
    }
}
.app-view-class16{
  .home-page-volume-item-main {
        width: 230px;
    }

    .home-page-volume-item-span {
        width: 50px;
    }

    .home-page-volume-item-spanright {
        width: 75px;
    }
    .top-four-set-container{
      .top-four-set-title,
      .top-four-set-main {
        width: 370px;
      }
    }
}
.app-view-class18{
  // 首页样式调整
  .home-page-volume-item-main {
        width: 240px;
    }

    .home-page-volume-item-span {
        width: 55px;
    }

    .home-page-volume-item-spanright {
        width: 80px;
    }
    .top-four-set-container{
      .top-four-set-title,
      .top-four-set-main {
        width: 385px;
      }
    }
}
.home-page-container {
  width: 100%;
  // height: 100%;
  height: calc(100vh - 52px);
  // min-width: 1208px;
  overflow: auto;
  .flex1-items-center{
    flex: 1;
    display:flex;
    align-items: center;
  }
}
.home-page-top-container{
  width: 100%;
  padding: 40px;
}
.top-one-user-inform-container{
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  .user-abbr {
    width: 84px;
    height: 84px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .user-abbr-span {
      font-size: 170%;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .user-inform-name{
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 16px;
  }
.user-inform-name-one{
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    font-size: 150%;
    font-weight: bold;
  }
  .user-inform-name-two{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content:center;
    font-size: 128%;
    font-weight: 400;
    color: #B0B0B0;
  }
}
.top-two-mail-num-container {
  width: 100%;
  height: 92px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  .mail-num-item{
    width: 240px;
    height: 90px;
    padding-left: 20px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    .svg-bg {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background: #F2F2F2;
      border: 1px solid #DEDEDE;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mail-num-label{
      padding-left: 15px;
      flex: 1;
      height: 60px;
      display:flex;
      flex-direction: column;
    }
  }
  .mail-num-item:hover{
    background: #F5F5F5;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #E8E8E8;
  }
}
.top-three-volume-num-container{
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  .volume-item-label{
    width: 90px;
    display: inline-block;
    font-weight: bold;
    color: #1D1B1B;
    margin-right: 15px;
  }
}

.top-four-set-container{
  margin-top: 40px;
  display: flex;
 .top-four-set-title{
  width: 360px;
  height: 30px;
  line-height: 30px;
  background: #EDEDED;
  padding-left: 20px;
  font-weight: bold;
  color: #1D1B1B;
 }
 .top-four-set-main{
    width: 360px;
    height: 105px;
    margin-top: 5px;
    border: 1px solid #E1E1E1;
    padding-left:20px;
    padding-top:15px;
    color: #1D1B1B;
    overflow: auto;
    .mar-top14{
      margin-top: 10px;
    }
 }
}
</style>
