import serialize from '@/utils/serialize'
export default {
  data() {

  },
  methods: {
    // 获取公共通讯录组
    async getPublicContact(id) {
      if (id === 'out') {
        const params = {
          module: 'mail',
          require: {
            action: 'get_out_contact',
          },
        }
        return new Promise((resolve, reject) => {
          this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
            const cache = {}
            const list = result.map(item => {
              const persons = item.user_list.map(i => {
                i.checked = false
                i.type = 'out'
                i.path = this.$t('message.contact.outcontact')
                i.group = item.name
                return i
              })
              cache[item.id] = persons
              return {
                id: item.id,
                name: item.name,
                num: item.num,
                leaf: true,
                type: 'out',
              }
            })

            this.$store.commit('contact/SETTING_OUT_CACHE', cache)
            resolve(list)
          })
        })
      }
      const params = {
        module: 'mail',
        require: {
          action: 'get_pub',
          gd_id: id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          let groups = []
          if (result.group_list) {
            groups = result.group_list.map(group => {
              if (group.list_name !== '') {
                this.$store.commit('contact/SETTING_DEPT_MAILS', {
                  id: group.id,
                  name: group.name,
                  path: group.path,
                  mail: group.list_name,
                })
              }
              group.leaf = false
              group.type = 'org'
              return group
            })
          }
          let maillists = []
          if (result.list_names) {
            maillists = result.list_names?.map(list => {
              list.leaf = true
              list.checked = false
              list.type = 'maillist'
              return list
            })
          }

          if (id === 'maillist') {
            resolve([...maillists])
          } else {
            resolve([...groups])
          }
        })
      })
    },
    // 获取公共通讯列表
    async getPublicList(id) {
      const params = {
        module: 'mail',
        require: {
          action: 'get_pub',
          gd_id: id,
        },
      }
      if (id === 'maillist') {
        params.require.gd_id = ''
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          let maillists = []
          if (result.list_names) {
            maillists = result.list_names?.map(list => {
              list.leaf = true
              list.type = 'maillist'
              list.path = this.$t('message.contact.maillist')
              this.userStreamCache.setUserCache(list)
              return list
            })
          }
          let persons = []
          if (result.user_list) {
            persons = result.user_list.map(person => {
              person.leaf = true
              person.checked = false
              person.type = 'org'
              this.userStreamCache.setUserCache(person)
              return person
            })
          }

          if (id === 'maillist') {
            resolve([...maillists])
          } else {
            resolve([...persons])
          }
        })
      })
    },
    // 获取个人通讯录数据
    getPersonalContact() {
      const params = {
        module: 'contact',
        require: {
          action: 'getALL',
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          const cache = {}
          const list = result.map(item => {
            item.leaf = true
            const persons = item.user_list.map(i => {
              i.checked = false
              i.type = 'person'
              i.path = this.$t('message.contact.percontact')
              this.userStreamCache.setUserCache(i)
              return i
            })
            cache[item.id] = persons
            return {
              id: item.id,
              name: item.name,
              num: item.num,
              type: 'person', // person, group, maillist,
            }
          })

          this.$store.commit('contact/SETTING_PERSONAL_CACHE', cache)
          resolve(list)
        })
      })
    },
    // 获取个人通讯录组
    async getGroups() {
      const params = {
        module: 'contact',
        require: {
          action: 'getALL',
          get_type: 'group',
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.result !== '') {
        this.groups = res.result
        this.$store.commit('contact/SETTING_PERSON_GROUP', this.groups)
      }
    },
    // 新建联系人
    addNewContact(params) {
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((res) => {
          resolve(res)
        })
      })
    },
    // 删除联系人
    deleteContact(id, group_id, flag) {
      const params = {
        module: 'contact',
        require: {
          action: 'delete',
          contact_id: id,
          group_id: group_id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((res) => {
          resolve(res)
        })
      })
    },
    // 更新联系人信息
    getContactInfo(id) {
      const params = {
        module: 'contact',
        require: {
          action: 'getInfo',
          contact_id: id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          if (result.status === 200) {
            resolve(result)
          }
        })
      })
    },
    // 移动删除
    moveCopyConacts(obj) {
      const params = {
        module: 'contact',
        require: {
          action: 'move',
          contact_id: obj.ids,
          data: {
            act: obj.act,
            contact_list: 0,
            group_id: obj.group_id,
            group_name: obj.group_name,
            old_group_id: obj.old_group_id,
          },
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((res) => {
          resolve(res)
        })
      })
    },
    // 获取成员列表
    getMemberList(id) {
      const params = {
        module: 'mail',
        require: {
          action: 'show_list_members',
          list_id: id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        })
      })
    },
    // 联系人联想
    searchContact(words) {
      if (words === '') return
      const params = {
        module: 'mail',
        require: {
          action: 'key_search',
          key_word: words,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          result.result.map(item => {
            this.userStreamCache.setUserCache(item)
          })
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 通讯录全局搜索
    searchGlobalContact(word, flag) {
      const params = {
        module: 'mail',
        require: {
          action: 'search_all',
          key_word: word,
          type: flag,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
          if (result instanceof Array) {
            resolve(result)
          } else {
            this.$message.error(result.info)
            resolve([])
          }
        })
      })
    },
  },
}
