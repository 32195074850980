<template>
  <div
    class="netdisk-share-container"
    :style="{ backgroundImage: 'url(' + location_origin + login_background_url + ')' }"
    style="position: relative;"
  >
    <div
      style="position:absolute;top:0;left:0;height:80px;width: 100%;min-width:960px;padding-left:10%;background-color:#ffffff;"
    >
      <img
        :src="login_logo"
        style="height:100%"
      >
    </div>
    <div
      :style="isTable?{'margin-top': '25vh','width': '960px;','height': '70%;'}:{'margin-top': '25vh','width': '530px;','height': '250px;'}"
    >
      <!-- 填写密码 -->
      <div
        v-if="isPassWord"
        class="share-name-container"
      >
        <div class="share-name-header">{{ $t('message.netdisk.drivesharelink') }}</div>
        <div class="share-name-main">
          <div class="share-name-item1">
            <div class="one-line-ellipsis">
              <el-tooltip
                class="item"
                effect="light"
                :content="share_name"
                placement="top-start"
              >
                <span>{{ $t('message.netdisk.sharefile') }}：{{ share_name }}</span>
              </el-tooltip>
            </div>
          </div>
          <div class="share-name-item2">{{ $t('message.netdisk.sharepsw') }}：</div>
          <div class="share-name-item3">
            <input
              v-if="!eyes1"
              v-model="pwdCover1"
              class="lock-password-input noelInput"
              style="width: 100%;height:35px;"
              :placeholder="$t('message.netdisk.sharepsw')"
              autocomplete="off"
              @keydown="handlerKeydown"
              @input="setPassword"
              @focus="tips1 = true"
              @blur="tips1 = false"
            >
            <input
              v-else
              v-model="form.password"
              class="lock-password-input noelInput"
              :placeholder="$t('message.netdisk.sharepsw')"
              autocomplete="off"
              style="width: 100%;height:35px;"
              @input="setPwdCover"
              @focus="tips1 = true"
              @blur="tips1 = false"
            >
            <div style="width: 30px;position:absolute;right: 0;top:5px;cursor: pointer;">
              <svg-icon
                v-if="!eyes1"
                icon-class="hidePassword"
                @click="showPwd(true, 'eyes1')"
              />
              <svg-icon
                v-else
                icon-class="showPassword"
                @click="showPwd(false, 'eyes1')"
              />
            </div>
          </div>
          <div style="width: 100%;flex:1;margin-bottom: 10px;">
            <el-button
              type="primary"
              style="width:100%;height:46px;font-size: 16px;font-weight: bold;color: #FFFFFF;"
              :style="{ 'backgroundColor': '#D0161B', 'borderColor': '#D0161B' }"
              @click="getShareDetail('1')"
            >{{ $t('message.btnText.ok') }}</el-button>
          </div>
        </div>
      </div>
      <!-- 已取消或已失效 -->
      <div
        v-if="isError"
        class="share-name-container"
        style="height: 145px;"
      >
        <div class="share-name-header">{{ $t('message.netdisk.drivesharelink') }}</div>
        <div
          class="share-name-main"
          style="font-weight: 400;
            color: #D0161B;"
        >
          <div style="margin-bottom: 20px;"><i class="el-icon-warning" /></div>
          <div>{{ $t('message.netdisk.info5') }}</div>
        </div>
      </div>
      <!-- 分享文件 列表 -->
      <div
        v-if="isTable"
        class="share-name-container"
        style="width: 960px;height: 50vh"
      >
        <div class="share-name-header">{{ $t('message.netdisk.drivesharelink') }}</div>
        <div
          style="
            width: 100%;
            height: 56px;
            line-height: 56px;
            font-size: 18px;
            padding-left: 20px;
            display:flex;
            "
        >
          <span class="search-data-label">
            {{ $t('message.netdisk.sharefile') }}
          </span>
          <div
            class="Opea-svg-select-noBorder"
            @click="downAtta"
          >
            <svg-icon
              class="icos-size-14"
              icon-class="download"
            />
            <span class="Opea-svg-select-noBorder-span">{{ $t('message.netdisk.download') }}</span>
          </div>
        </div>
        <!-- 回退操作及打开文件夹 -->
        <div class="networkcontent-action">
          <el-button
            type="text"
            style="color:#171717;"
            @click="parentsFolderAction"
          ><i
            class="el-icon-arrow-left"
          />
          </el-button>
          <el-button
            type="text"
            style="color:#171717"
            @click="getShareDetail"
          ><i class="el-icon-refresh-left" />
          </el-button>
          <span style="width: 20px;display: inline-block;text-align: center;">|</span>
          <span
            v-for="(item, index) in lastFourItems"
            :key="index"
            style="cursor: pointer;"
            :title="item.nd_id===''?$t('message.netdisk.diskshare'):item.nd_name"
            @click="openFolderAction('id', item)"
          >{{ item.nd_id===''?$t('message.netdisk.diskshare'):item.nd_name | ellipsis }}
            <span
              v-show="index != (lastFourItems.length - 1)"
              style="display: inline-block;text-align: center;"
            ><b
              v-show="index === 0 && lastFourItems.length > 3"
            >...</b><span style="padding: 0 3px;">></span></span>
          </span>
        </div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%"
          highlight-current-row
          height="80%"
          class="table"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
          />
          <el-table-column
            :label="$t('message.netdisk.filename')"
            prop="nd_name"
          >
            <template slot-scope="scope">
              <p
                v-if="scope.row.nd_is_dir == 1"
                class="one-line-ellipsis"
                :title="scope.row.nd_name"
                style="cursor: pointer;"
                @click="openFolderAction('tab', scope.row)"
              >
                <svg-icon
                  class="attaClass middle"
                  icon-class="folder"
                  class-name="atticon"
                />
                {{ scope.row.nd_name == '' ? $t('message.netdisk.nofname') : scope.row.nd_name }}
              </p>
              <p
                v-else
                class="one-line-ellipsis"
                :title="scope.row.nd_name"
                style="cursor: pointer;"
              >
                <svg-icon
                  class="attaClass middle"
                  :icon-class="getFileType(scope.row.nd_name) + 'icon'"
                  class-name="atticon"
                />
                {{ scope.row.nd_name == '' ? $t('message.netdisk.noname') : scope.row.nd_name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="nd_size"
            :label="$t('message.netdisk.size')"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.nd_is_dir == 1">--</span>
              <span v-else>{{ scope.row.nd_size | mailSizefilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="nd_ctime"
            :label="$t('message.netdisk.modifiedtime')"
          >
            <template slot-scope="scope">
              {{ scope.row.nd_ctime | dateFilter }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('message.netdisk.download')">
            <template slot-scope="scope">
              <div style="cursor: pointer;">
                <span @click="oneDown(scope.row)">{{ $t('message.netdisk.download') }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          v-if="total > 0"
          class="trunPage"
          style="padding-left: 20px;"
        >
          <el-pagination
            :current-page="currentPage"
            :page-size="page_size"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div
      style="background-color: #ffffff;position: absolute;bottom: 0;width: 100%;min-width:960px;display: flex;align-items: center;justify-content: center;font-size: 14px;padding-top: 20px;padding-bottom: 20px;"
    >
      <div
        style="width:70%;text-align: center;"
        class="two-line-ellipsis"
      >{{ copyright }}</div>
    </div>
  </div>
</template>
<script>
// import serialize from '@/utils/serialize'
import { PassWord, PwdCover } from '@/utils/changePassword'
import { GetFileType, transformClass } from '@/utils/utils.js'
export default {
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
    ellipsis(value) {
      console.log(value, 'val')
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    },
  },
  data() {
    return {
      location_origin: location.origin,
      share_id: '',
      share_name: '',
      isPassWord: false,
      isError: false,
      isTable: false,
      pwdCover1: '',
      form: {
        password: '', // 4jjwo /netdisk_share?nsnode=s1_local&share_id=21qcnc33u59s
      },
      eyes1: false, // 是否查看密码
      tips1: false,
      tableData: [],
      multipleSelection: [],
      currentPage: 1, // 前往第几页
      page_size: 20,
      page: 1,
      total: 0,
      allFolderObj: [{
        nd_id: '',
        nd_name: '网盘共享',
      }], // 上一级文件夹
      parent_nd_id: '',
      login_background_url: '',
      login_logo: '',
      copyright: '',
      login_btn_color: '',
      login_btn_text_color: '',
      downAttaReq: {},
      nsnode: '',
    }
  },
  computed: {
    lastFourItems() {
      let arr = []
      if (this.allFolderObj.length > 4) {
        arr = this.allFolderObj.slice(-3)
      } else {
        arr = this.allFolderObj.slice(1)
      }
      return [this.allFolderObj[0], ...arr]
    },
    // scrollerHeight: function() { // 列表固定高度
    //   return (window.innerHeight - 230) + 'px'
    // },
  },
  created() {
    document.title = this.$t('message.netdisk.diskfshare')
    this.nsnode = this.getUrlKey('nsnode')
    // 获取登录设置
    this.$axios.get(this.$apiData.get_login_setting).then(res => {
      if (res.status === 200) {
        const data = res.result
        this.login_background_url = data.login_background
        this.login_logo = data.login_logo
        this.copyright = data.copyright
        this.login_btn_color = data.login_btn_color
        this.login_btn_text_color = data.login_btn_text_color
      }
    })
    this.getShareInfo()
    // const skin_colour = localStorage.getItem('skin_colour')
    // import(`@/theme/${skin_colour}/theme.scss`)
    // import(`@/theme/${skin_colour}/style/index.css`)
  },
  methods: {
    // 显示密码
    showPwd(status, eyes) {
      if (eyes === 'eyes1') {
        status ? this.eyes1 = true : this.eyes1 = false
        this.pwdCover1 = this.pwdCover1.slice(0, this.form.password.length)
      }
    },
    // 初始密码
    handlerKeydown(event) {
      this.cursorStart1 = event.target.selectionStart
      this.cursorEnd1 = event.target.selectionEnd
    },
    // 初始密码 用户登录密码框提换值
    setPassword(event) {
      // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
      // this.capsTooltip = false
      if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
        // this.err_msg = true
        // this.err_msg_txt = '禁止输入中文'
        this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
      } else {
        const b = new PassWord(event, this.pwdCover1, this.form.password, this.cursorStart1, this.cursorEnd1)
        this.pwdCover1 = b.toValue()[0]
        this.form.password = b.toValue()[1]
      }
    },
    // 初始密码 用户登录密码框提换值
    setPwdCover(event) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
        // this.err_msg = true
        // this.err_msg_txt = '禁止输入中文'
        this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
      } else {
        const a = new PwdCover(this.pwdCover1, this.form.password)
        this.pwdCover1 = a.toValue()
      }
    },
    getShareInfo() {
      this.share_id = this.getUrlKey('share_id')
      if (this.share_id) {
        const params = {
          reqire: {
            'share_id': this.share_id,
          },
        }
        this.$axios.post(this.$apiData.getShareInfo + '&nsnode=' + this.nsnode + '&', { req: JSON.stringify(params) })
          .then(res => {
            const data = res.result.data
            if (JSON.stringify(data) === '[]') {
              this.isError = true // 已过期或已取消
              this.isPassWord = false
              this.isTable = false
            } else {
              this.share_name = data.share_name
              this.isPassWord = true
              this.isError = false
              this.isTable = false
            }
          })
      }
    },
    /** 获取详细信息 */
    getShareDetail(is_log) {
      if (!this.form.password) {
        return this.$message.error(this.$t('message.login.placeholder2'))
      }
      const reqire = {
        'share_id': this.share_id,
        'share_password': this.form.password,
        'parent_nd_id': this.parent_nd_id,
        'page': this.page,
        'page_size': this.page_size,
      }
      if (is_log === '1') reqire.is_log = '1'
      this.$axios.post(this.$apiData.getShareDetail + '&nsnode=' + this.nsnode + '&', { req: JSON.stringify({ reqire }) })
        .then(res => {
          this.tableData = res.result.list
          this.total = res.result.total
          this.isTable = true
          this.isError = false
          this.isPassWord = false
        })
    },
    /** 将打开文件夹 */
    openFolderAction(type, val) {
      if (type === 'tab') {
        this.parent_nd_id = val.nd_id
        this.allFolderObj.push({
          nd_id: val.nd_id,
          nd_name: val.nd_name,
        })
      } else if (type === 'id') {
        this.parent_nd_id = val.nd_id
        let index = ''
        for (let i = 0; i < this.allFolderObj.length; i++) {
          if (this.allFolderObj[i].nd_id === val.nd_id) {
            index = i
            break
          }
        }
        if (this.allFolderObj.length >= (index + 1)) { this.allFolderObj = this.allFolderObj.slice(0, index + 1) }
      }
      this.page = 1
      this.getShareDetail()
    },
    /** 查看上一级文件夹 */
    parentsFolderAction() {
      const len = this.allFolderObj.length
      if (len > 1) {
        const arr = this.allFolderObj[len - 2]
        this.allFolderObj = this.allFolderObj.slice(0, len - 1)
        this.parent_nd_id = arr.nd_id
        this.page = 1
        this.getShareDetail()
      }
    },
    // 翻页
    handleCurrentChange(val) {
      this.page = `${val}`
      this.getShareDetail()
    },
    // 列表选择值
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    downAtta() {
      if (this.multipleSelection.length < 1) {
        this.$message.error(this.$t('message.netdisk.err5'))
        return false
      }
      let name = this.multipleSelection[0].nd_name
      const index = name.lastIndexOf('.')
      name = name.substring(0, index)
      // 打包下载
      const nd_id_list = this.rows()
      this.downAttaReq = {
        'nd_id_list': nd_id_list,
        'share_id': this.share_id,
        'share_password': this.form.password,
        is_mail_attachment: 0,
      }
      this.downZIP('all', nd_id_list)
    },
    oneDown(el) {
      if (el.nd_is_dir.toString() === '1') {
        let name = el.nd_name
        const index = name.lastIndexOf('.')
        name = name.substring(0, index)
        // 打包下载
        this.downAttaReq = {
          nd_id_list: el.nd_id,
          'share_id': this.share_id,
          'share_password': this.form.password,
          is_mail_attachment: 0,
        }
        this.downZIP('one', el.nd_id)
      } else {
        // 单个下载
        this.downAttaReq = {
          nd_id: el.nd_id,
          is_mail_attachment: 0,
          'share_id': this.share_id,
          'share_password': this.form.password,
        }
        const req = {
          'module': 'share',
          ...this.downAttaReq,
        }
        const url = '/nsmail/mail.php/fn.dat?task=download&&nsnode=' + this.nsnode + '&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
        window.open(url)
      }
    },
    // 打包下载前查询文件大小是否超过限制
    downZIP(type, nd_id_list) {
      const req = {
        reqire: {
          'nd_id_list': nd_id_list,
          'share_id': this.share_id,
          'share_password': this.form.password,
        },
      }
      this.$axios.post(this.$apiData.getFileSize + '&nsnode=' + this.nsnode + '&', { req: JSON.stringify(req) }).then(res => {
        const file_size = res.result.file_size
        const num = 1024
        const m = (file_size / Math.pow(num, 2)).toFixed(2)
        if (m > 200) {
          if (type === 'all' && this.multipleSelection.length === 1 && this.multipleSelection[0].nd_is_dir.toString() !== '1') this.oneDown(this.multipleSelection[0])
          else return this.$message.error(this.$t('message.netdisk.err7'))
        } else {
          const req = {
            'module': 'share',
            ...this.downAttaReq,
          }
          const url = '/nsmail/mail.php/fn.dat?task=download&nsnode=' + this.nsnode + '&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
          window.open(url)
        }
      })
    },
    rows() {
      const nums = []
      this.multipleSelection.forEach(el => {
        nums.push(el.nd_id)
      })
      return nums.toString()
    },
    // 文件类型
    getFileType(name) {
      const i = name.indexOf('...')
      const n = i < 0 ? name : name.slice(0, i)
      const type = GetFileType.fileType(n)
      return type
    },
    getUrlKey(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            decodeURIComponent(location.href),
            // eslint-disable-next-line no-sparse-arrays
          ) || [, ''])[1].replace(/\+/g, '%20'),
        ) || null
      )
    },
  },

}
</script>
<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
  .netdisk-share-container {
    font-size: 14px;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-size: 100% 100%;
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color: #d0161b !important;
      border-color: #d0161b !important;
    }
    /deep/ .el-checkbox__input.is-focus .el-checkbox__inner,
    /deep/ .el-checkbox__inner:hover {
      border-color: #d0161b !important;
    }
    /deep/ .el-pager li.active {
      color: #d0161b !important;
    }
  }

  .share-name-container {
    width: 530px;
    height: 260px;
    margin: auto;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #CACACA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .share-name-header {
    width: 100%;
    height: 32px;
    background: #F3F3F3;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #DBDBDB;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .share-name-main {
    width: 100%;
    flex: 1;
    padding: 16px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .share-name-item1 {
      width: 100%;
      flex: 1;
      background: #F6F6F6;
      border-radius: 5px;
      padding-left: 12px;
      display: flex;
      align-items: center;
    }

    .share-name-item2 {
      width: 100%;
      flex: 1;
      font-size: 14px;
      margin-top: 5px;
      font-weight: bold;
      color: #1D1B1B;
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .share-name-item3 {
      width: 100%;
      flex: 1.5;
      display: flex;
      position: relative;
      input{
        border: 1px solid #d0d0d0;
        border-radius: 3px;
        text-indent: 8px;
      }

      ::-ms-clear,
      ::-ms-reveal {
        display: none;
      }
    }
  }

  .search-data-label {
    width: 100px;
    height: 18px;
    font-size: 128%;
    font-weight: bold;
    margin-right: 10px;
  }

  .networkcontent-action {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    background: #E6E6E6;
  }
</style>
