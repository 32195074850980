import serialize from '@/utils/serialize'

export default {
  data() {
    return {
      calendarTagsList: [],
      calendarSubTagsList: [],
      showCreateTags: false,
      dialogVisible: false, // 创建日程弹窗
      title: '新建日历',
      edit_tag_id: '',
      myCreateShareList: [],
      myReieveShareList: [],
      shareUserList: [],
      searchShare: '', // 搜索分享成员详情
      showDetail: false,
      detailTitle: '', // 详情title
      shareperlist: [], // 选中成员
      formLabelAlign: {
        tag_id: '',
        privilege_level: 0,
        share_user_id: [],
      }, // 分享列表数据
      privilegeList: [{
        value: '0',
        label: 'info71',
      }, {
        value: '1',
        label: 'info72',
      }, {
        value: '2',
        label: 'info73',
      }],
      tipsModel: [
        {
          value: '0',
          label: 'cal48',
        },
        {
          value: '5M',
          label: 'cal16',
        }, {
          value: '10M',
          label: 'cal17',
        }, {
          value: '15M',
          label: 'cal18',
        }, {
          value: '30M',
          label: 'cal24',
        },
        {
          value: '1H',
          label: 'cal19',
        }, {
          value: '2H',
          label: 'cal20',
        }, {
          value: '6H',
          label: 'cal21',
        }, {
          value: '12H',
          label: 'cal22',
        }, {
          value: '18H',
          label: 'cal25',
        }, {
          value: '1D',
          label: 'cal49',
        }, {
          value: '2D',
          label: 'cal26',
        },
      ], // 提醒
      noticeReflet: {
        '5M': 'cal16',
        '10M': 'cal17',
        '15M': 'cal18',
        '30M': 'cal24',
        '1H': 'cal19',
        '6H': 'cal20',
        '12H': 'cal22',
        '18H': 'cal25',
        '1D': 'cal49',
        '2D': 'cal26',
      },
      weekobj: {
        'MO': 'cyle18',
        'TU': 'cyle19',
        'WE': 'cyle20',
        'TH': 'cyle21',
        'FR': 'cyle22',
        'SA': 'cyle23',
        'SU': 'cyle24',
      },
      carlendarReq: {
        subject: '',
        location: '',
        start_time: '',
        end_time: '',
        valarm_time: '',
        valarm_type: '',
        tag_id: '',
        rule: '',
        add_type: '',
        desc: '',
        transp: '',
      }, // 创建日程参数
    }
  },
  methods: {
    deepCopy(obj) {
      if (typeof obj !== 'object' || obj === null) {
        return obj
      }

      const copy = Array.isArray(obj) ? [] : {}

      Object.keys(obj).forEach((key) => {
        copy[key] = this.deepCopy(obj[key])
      })

      return copy
    },
    // 解析规则
    parsingRules(str) {
      const form = {
        Model: 1, // 重复模式
        day: {
          kind: 1,
          daynum: '',
        },
        week: {
          weeknum: '',
          weekList: [],
        },
        month: {
          monthnum: '',
          kind: 1,
          daymun: '',
          weeknum: '',
          week: '',
        },
        year: {
          yearnum: '',
          monthnum: '',
          daynum: '',
        },
        endDate: '',
        neverexpire: false,
      }
      const rule = {}
      const parts = str.split(';')
      for (const part of parts) {
        const [key, value] = part.split('=')
        rule[key] = value
      }
      console.log(rule)
      if (rule.FREQ === 'DAILY') {
        form.Model = 1
        if (rule.INTERVAL) {
          form.day.kind = 1
          form.day.daynum = rule.INTERVAL
        } else {
          form.day.kind = 2
        }
      } else if (rule.FREQ === 'WEEKLY') {
        form.Model = 2
        form.week.weeknum = rule.INTERVAL
        var arr = []
        rule.BYDAY.split(',').map(item => {
          arr.push(this.$t(`message.calendar.${this.weekobj[item]}`))
        })
        form.week.weekList = arr
      } else if (rule.FREQ === 'MONTHLY') {
        form.Model = 3
        form.month.monthnum = rule.INTERVAL
        if (rule.BYMONTHDAY) {
          form.month.kind = 1
          form.month.monthnum = rule.INTERVAL
          form.month.daymun = rule.BYMONTHDAY
        } else {
          const number = parseInt(rule.BYDAY.match(/^(\d+)/)[1])
          const string = rule.BYDAY.match(/[^0-9]+/)[0]
          form.month.kind = 2
          form.month.weeknum = number
          form.month.week = string
        }
      } else {
        form.Model = 4
        form.year.yearnum = rule.INTERVAL
        form.year.monthnum = rule.BYMONTH
        form.year.daynum = rule.BYMONTHDAY
      }
      form.endDate = rule.UNTIL || ''
      if (!rule.UNTIL) {
        form.neverexpire = true
      }
      console.log(form)
      return form
    },
    // 获取日历标签列表
    async getCalendarTags() {
      const params = {
        module: 'calendar',
        require: {
          action: 'getTagList',
          data: {
            page: 1,
            page_size: 200,
          },
        },
      }

      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        const arr = []
        res.result.list.map(item => {
          arr.push({
            ...item,
          })
        })
        this.calendarTagsList = arr
        const arr1 = []
        arr.map(item => {
          if (item.is_show === '1') {
            arr1.push(item.tag_id)
          }
        })
        this.$myData.calendar.displayTag = arr1
        const tag = res.result.list.find(item => item.tag_type === '1')
        if (tag) {
          this.$myData.calendar.defaultTag = tag
        }
        this.$myData.calendar.eventTags = this.calendarTagsList
      }
    },
    // 添加日历标签
    async addCalendarTags(obj) {
      const params = {
        module: 'calendar',
        require: {
          action: 'addTag',
          data: {
            tag_name: obj.tag_name,
            tag_color: obj.tag_color,
          },
        },
      }

      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      console.log(res)
      if (res.status === 200) {
        this.$message.success(this.$t('message.contact.info10'))
        this.showCreateTags = false
        this.getCalendarTags()
        this.getMyShareList()
      } else {
        this.$message.error(res.msg)
        this.showCreateTags = false
      }
    },
    // 编辑标签
    editCalendarTags(obj) {
      const params = {
        module: 'calendar',
        require: {
          action: 'editTag',
          data: {
            tag_id: obj.tag_id,
            tag_name: obj.tag_name,
            tag_color: obj.tag_color,
            is_show: obj.is_show,
          },
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 删除标签
    delCalendarTags(id) {
      const params = {
        module: 'calendar',
        require: {
          action: 'delTag',
          data: {
            tag_id: id,
          },
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 获取左侧订阅列表
    async getSubscribeTagList() {
      const params = {
        module: 'calDav',
        require: {
          action: 'subscribe_list',
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      const arr = []
      res.result.map(item => {
        arr.push({
          ...item,
        })
      })
      const arr1 = []
      arr.map(item => {
        if (item.is_show === '1') {
          arr1.push(item.from_id)
        }
      })
      this.$myData.calendar.displaySubTag = arr1
      this.calendarSubTagsList = arr
    },
    // 获取订阅人分享的标签列表

    async getSubscribeShareTagList(id) {
      const params = {
        module: 'calDav',
        require: {
          action: 'subscribe_tag_list',
          from_id: id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 我发起的分享列表
    async getMyShareList() {
      const params = {
        module: 'calDav',
        require: {
          action: 'share_tag_list',
          page: 1,
          limit: 200,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      this.myCreateShareList = res.result
    },
    // 我收到的分享列表
    async getMyReieveList() {
      const params = {
        module: 'calDav',
        require: {
          action: 'rec_share_tag_list',
          page: 1,
          limit: 200,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      this.myReieveShareList = res.result
    },
    // 分享日程
    async shareCalendar(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'share_calendar',
          tag_id: obj.tag_id,
          privilege_level: obj.privilege_level,
          share_user_id: obj.share_user_id.toString(),
        },
      }
      const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status) {
        this.getMyShareList()
        this.getEditShareInfo(this.formLabelAlign.tag_id)
        this.$message.success(res.result)
      } else {
        this.$message.error(res.result)
      }
    },
    // 取消分享日程
    async cancelShareCalendar(id) {
      const params = {
        module: 'calDav',
        require: {
          action: 'cancel_share',
          tag_id: id,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      console.log(res)
    },
    // 获取分享记录的编辑详情
    async getEditShareInfo(id) {
      const params = {
        module: 'calDav',
        require: {
          action: 'get_edit_share_info',
          tag_id: id,
        },
      }
      const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      this.showDetail = true
      this.shareUserList = res
    },
    // 获取分享记录的编辑详情
    async editShare(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'edit_share',
          share_tag_id: obj.tag_id,
          share_tag_list: obj.share_tag_list,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })

      if (res.status === 200) {
        this.$message.success(this.$t('message.setting.saveinfo'))
        this.getMyShareList()
        this.showDetail = false
      } else {
        this.$message.success(this.$t('message.mailmodule.save_fail'))
      }
      console.log(res)
    },
    // 忽略收到的全部分享
    async ignoreAllShare() {
      const params = {
        module: 'calDav',
        require: {
          action: 'ignore_all',
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        this.getMyReieveList()
        this.getSubscribeTagList()
      }
    },
    // 编辑收到的分享(接受或忽略)

    editRecShareTag(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'edit_rec_share_tag',
          share_from_id: obj.share_from_id,
          status: obj.status,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // (左侧)订阅记录编辑

    async editSubscribeTag(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'edit_rec',
          from_id: obj.from_id,
          show_name: obj.show_name,
          color: obj.color,
          is_show: obj.is_show,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        this.getSubscribeTagList()
        if (!obj.editShow) {
          this.$message.success(this.$t('message.contact.info16'))
        }
      }
    },
    // 切换订阅日程显示
    async switchShowSubscribeTag(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'switchShow',
          from_id: obj.from_id,
          is_show: obj.is_show,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        this.getSubscribeTagList()
      }
    },
    // (左侧)订阅记录编辑

    async delSubscribeTag(id) {
      const params = {
        module: 'calDav',
        require: {
          action: 'cancel_subscribe',
          from_id: id,
        },
      }
      const res = await this.$axios.post(this.$apiData.mail, { req: serialize(params) })
      if (res.status === 200) {
        this.getSubscribeTagList()
        this.$message.success(this.$t('message.contact.info16'))
      }
    },
    // 创建日程
    addCarlendar(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'add_cal',
          ...obj,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 编辑日程
    editCarlendar(id, fromid, obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'edit_cal',
          cal_obj_id: id,
          from_id: fromid,
          ...obj,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 删除日程
    deleteCaledar(id, from_id) {
      const params = {
        module: 'calDav',
        require: {
          action: 'del_cal',
          cal_obj_id: id,
          from_id: from_id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 事件列表
    getEventsList(date) {
      const params = {
        module: 'calDav',
        require: {
          action: 'cal_list',
          start_date: date[0],
          end_date: date[1],
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((res) => {
          res.result.map(item => {
            if (this.$myData.calendar.displayTag.includes(item.ky_tag_id)) {
              item.display = 'block'
            } else {
              item.display = 'none'
            }
          })
          console.log('getEventList', date, res.result)
          resolve(res.result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 事件列表
    getSubEventsList(date) {
      const params = {
        module: 'calDav',
        require: {
          action: 'cal_list',
          start_date: date[0],
          end_date: date[1],
          subscribe: 1,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((res) => {
          res.result.map(item => {
            if (this.$myData.calendar.displaySubTag.includes(item.from_id)) {
              item.display = 'block'
            } else {
              item.display = 'none'
            }
          })
          resolve(res.result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 事件列表
    getEventsDetail(id, from_id) {
      const params = {
        module: 'calDav',
        require: {
          action: 'get_cal_detail',
          cal_obj_id: id,
          from_id: from_id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 创建会议/编辑会议
    SendMetting(obj) {
      const params = {
        module: 'mail',
        require: {
          action: 'send',
          data: obj,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
    // 直接响应会议(不发邮件)
    directFeedbackMetting(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'direct_feedback_meeting',
          ...obj,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
    // 取消会议邀请
    cancelInviteMetting(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'cancel_meeting',
          ...obj,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
    // 获取日历提醒哦那个列表
    getValarmList() {
      const params = {
        module: 'calDav',
        require: {
          action: 'getValarmList',
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    laterValarm(obj) {
      const params = {
        module: 'calDav',
        require: {
          action: 'laterValarm',
          ...obj,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
    changeMettingTag(obj) {
      const params = {
        'module': 'calDav',
        'require': {
          'action': 'switch_meeting_tag',
          'tag_id': obj.tag_id,
          'cal_obj_id': obj.cal_obj_id,
        },
      }
      return new Promise((resolve, reject) => {
        this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then((result) => {
          resolve(result)
        }).catch((err) => {
          resolve(err)
        })
      })
    },
  },
}
