<template>
  <div
    class=""
    @mouseenter="getFouse"
  >
    <div
      ref="TabBar"
      class="tabs-header"
    >
      <!-- 循环遍历页签 -->
      <div
        v-for="item in tabs.slice(0, maxCount)"
        :key="item.uniqueId"
        class="tabs-item"
        :class="[item.isPopup ? 'text-color' : '', 'mail-operation-' + item.id]"
        @click="clickItem(item)"
      >
        <el-tooltip
          class="item"
          effect="light"
          :content="item.subject || $t('message.list.no_subject')"
          placement="top-start"
          :open-delay="300"
          popper-class="tooltip-width"
        >
          <div
            class="name one-line-ellipsis"
          >
            {{ item.subject || $t('message.list.no_subject') }}
          </div>
        </el-tooltip>
        <div
          v-if="item.id != 'main'"
          @click.stop="delItem(item)"
        >
          <i class="el-icon-close" />
        </div>
      </div>
      <div
        v-if="tabs.length > 2"
        class="tabs-down"
      >
        <el-dropdown>
          <span class="el-dropdown-link">
            <i class="el-icon-caret-bottom" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in tabs.slice(maxCount)"
              :key="index"
              class="tab-dropdown-item"
              @click.native="clickItem(item)"
            >
              <span>{{ item.subject || $t('message.list.no_subject') }}</span>
              <span><i
                class="el-icon-close"
                @click.stop="delItem(item)"
              /></span>
            </el-dropdown-item>
            <el-dropdown-item
              class="text-color"
              @click.native="clearItems"
            >{{
              $t('message.list.close_tag')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div
      id="main_tabs_id_1102"
      class="tabs-main"
    >
      <!-- 左侧菜单 -->
      <MainMenu v-show="$myState.menuShowFlag && menuShowFlag" />
      <!-- 左侧菜单打开关闭 -->
      <MainMenuOpen v-show="menuShowFlag" />
      <!-- bodys -->
      <div
        v-for="item in tabs"
        :key="item.uniqueId"
        :styles="{
          'visibility': item.isPopup ? 'inherit' : 'hidden',
          'z-index': item.isPopup ? 2 : 1,
        }"
        :style="{
          'top': item.isPopup ? '0px' : '-10000px',
          'bottom': item.isPopup ? '0px' : '10000px',
          'overflow':item.type == 'viewMail' ? 'auto' : 'hidden'
        }"
        class="tabs-body"
        :class="$myState.menuShowFlag && menuShowFlag ? '' : 'tabs-body-expand'"
      >
        <!-- <MailMain
          v-if="item.type=='main'"
          :menu-id="item.id"
        /> -->
        <!-- 通栏 -->
        <BannerMail
          v-if="item.type == 'main' && !$myState.isColumns"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :parent-obj="item"
          :menu-id="item.idPlus"
          @listItemClick="listItemClick"
        />
        <!-- 分栏列表 -->
        <MulticolumnMail
          v-if="item.type == 'main' && $myState.isColumns"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :parent-obj="item"
          :menu-id="item.idPlus"
        />
        <ViewMail
          v-if="item.type == 'viewMail'"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :read-mail-req-data="item.data.readMailReqData"
          :read-mail-uid-mbox="item.idPlus"
          :from-type="item.data.type"
          @changeMailData="changeMailData($event, item.id)"
          @closeMe="closeMe($event, item.id)"
          @changeSubject="changeSubject($event, item.id)"
        />
        <Tracking
          v-if="item.type == 'tracking'"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :id-plus="item.idPlus"
          @closeMe="closeMe($event, item.id)"
          @changeSubject="changeSubject($event, item.id)"
        />
        <Audit
          v-if="item.type == 'audit'"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :id-plus="item.idPlus"
          @closeMe="closeMe($event, item.id)"
          @changeSubject="changeSubject($event, item.id)"
        />
        <Attachment
          v-if="item.type == 'attachment'"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :id-plus="item.idPlus"
          @closeMe="closeMe($event, item.id)"
          @changeSubject="changeSubject($event, item.id)"
        />
        <FileWebDisk
          v-if="item.type == 'fileWebDisk'"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :id-plus="item.idPlus"
          @closeMe="closeMe($event, item.id)"
          @changeSubject="changeSubject($event, item.id)"
        />
        <ComposeMail
          v-if="item.type == 'composeMail'"
          ref="itemBodys"
          :main-ky-tab-id-1101="item.uniqueId"
          :compose-type="item.data.composeType"
          :compose-data="item.data.composeData"
          @saveDrafts="saveDrafts($event, item.id)"
          @closeMe="closeMe($event, item.id)"
          @changeSubject="changeSubject($event, item.id)"
        />
        <div
          v-if="item.type == 'dev'"
          style="margin: 200px"
        >
          {{ item.subject }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import MainMenu from './menu.vue'
import MainMenuOpen from './menu-open.vue'
import ViewMail from '../../../viewMail/index.vue'
import Tracking from '../../../../views/tracking/index.vue'
import Audit from '../../../../views/audit/index.vue'
import Attachment from '../../../../views/attachment/index.vue'
import FileWebDisk from '../../../../views/fileWebDisk/index.vue'
import ComposeMail from '../../../../views/compose/index.vue'
import BannerMail from '@/views/mail-module/banner-mail.vue'
import MulticolumnMail from '../../../mail-module/multicolumn-mail.vue'
import { deepCopy } from '@/utils/utils'
// import serialize from '@/utils/serialize'

export default {
  components: {
    MainMenu,
    MainMenuOpen,
    ViewMail,
    Tracking,
    Audit,
    Attachment,
    FileWebDisk,
    ComposeMail,
    BannerMail,
    MulticolumnMail,
  },
  filters: {
    subjectFilter(val) {
      const str = val || this.$t('message.list.no_subject')
      return str
    },
  },
  data() {
    return {
      selfActived: false,
      menuShowFlag: true,
      mainShowFlag: false,
      tabIdPlus: 100,
      tabs: [
        {
          type: 'main',
          id: 'main',
          routeOptions: {}, // 路由选项
          uniqueId: 1,
          isPopup: true,
          noMainMeunu: false,
          subject: '',
          data: { menuItem: {}},
        },
      ],
      maxCount: 10,
    }
  },
  computed: {
    expireVal() {
      return this.$store.state.mail.expire_mail
    },
  },
  watch: {
    expireVal(newVal, oldVal) {
      if (newVal !== 999) {
        this.delItemForced(newVal)
        this.$store.commit('mail/EXPIRE_MAIL', 999)
      }
    },
  },
  mounted() {
    this.computeSliceIndex()
  },
  // 先加载
  created() {
    const thisobj = this

    this.$myApi.activeModuleMail = async(data, options) => {
      if (!thisobj.selfActived) {
        thisobj.selfActived = true
        thisobj.$myApi.activeModuleMailMenu()
      }
      if (typeof data !== 'object') {
        if (!options || options.isHistory !== true) {
          thisobj.$myApi.routeSet('mail')
        }
        return
      }
      if (data.type === 'view') {
        data.tabType = 'showMail'
        data.data = { uidvalidity: data.uv, uid: data.uid }
      } else if (data.type === 'tracking') {
        data.tabType = 'showTracking'
        data.data = { }
      } else if (data.type === 'audit') {
        data.tabType = 'showAudit'
        data.data = { }
      } else if (data.type === 'attachment') {
        data.tabType = 'showAttachment'
        data.data = { }
      } else if (data.type === 'fileWebDisk') {
        data.tabType = 'showFileWebDisk'
        data.data = { }
      } else if (data.type === 'mbox') {
        data.tabType = 'showMain'
        data.data = { }
      }

      const type = data.tabType
      if (type === 'ignoreModule') {
        return
      }
      if (type === undefined) {
        return thisobj.showMain({})
      }
      if (type === 'showMain') {
        return thisobj.showMain(data.data, options)
      }
      if (type === 'showMail') {
        return thisobj.showMail(data.data, options)
      }
      if (type === 'showWholeMail') {
        return thisobj.showWholeMail(data.data, options)
      }
      if (type === 'showMailContact') {
        return thisobj.showMailContact(data.data, options)
      }
      if (type === 'showMailSearch') {
        return thisobj.showMailSearch(data.data, options)
      }
      if (type === 'showTracking') {
        return thisobj.showTracking(data.data, options)
      }
      if (type === 'showAudit') {
        return thisobj.showAudit(data.data, options)
      }
      if (type === 'showAttachment') {
        return thisobj.showAttachment(data.data, options)
      }
      if (type === 'showFileWebDisk') {
        return thisobj.showFileWebDisk(data.data, options)
      }
      if (type === 'showComposeMail') {
        return thisobj.showComposeMail(data.data, options)
      }
      if (type === 'showMailNew') {
        return thisobj.showMailNew(data.data, options)
      }
    }

    this.$myApi.updateMailStatus = (mailStatusList) => {
      thisobj.updateMailStatus(mailStatusList)
    }

    this.$myApi.mainTab.popupItem = (itemId) => {
      const item = thisobj.findItem(itemId)
      if (!item) {
        return
      }
      thisobj.popupItem(item)
    }
    this.$myApi.mainTab.setItemSubject = (itemId, subject) => {
      return thisobj.setItemSubject(itemId, subject)
    }
    this.$myApi.mainTab.goBackMain = (folderType) => {
      return thisobj.goBackMain(folderType)
    }
    this.$myApi.mainTab.showMail = (readMailReqData) => {
      return thisobj.gotoByTopGoto('showMail', readMailReqData)
    }
    this.$myApi.mainTab.showWholeMail = (fromType, readMailReqData) => {
      return thisobj.gotoByTopGoto('showWholeMail', [
        fromType,
        readMailReqData,
      ])
    }
    this.$myApi.mainTab.showMain = (menuItem, options) => {
      return thisobj.gotoByTopGoto('showMain', menuItem, options)
    }
    this.$myApi.mainTab.showMailContact = (menuItem) => {
      return thisobj.gotoByTopGoto('showMailContact', menuItem)
    }
    this.$myApi.mainTab.showMailSearch = (menuItem) => {
      return thisobj.gotoByTopGoto('showMailSearch', menuItem)
    }
    this.$myApi.mainTab.showMailNew = (menuItem) => {
      return thisobj.gotoByTopGoto('showMailNew', menuItem)
    }
    this.$myApi.mainTab.showTracking = (reqData) => {
      return thisobj.gotoByTopGoto('showTracking', reqData)
    }
    this.$myApi.mainTab.showAudit = (reqData) => {
      return thisobj.gotoByTopGoto('showAudit', reqData)
    }
    this.$myApi.mainTab.showAttachment = (reqData) => {
      return thisobj.gotoByTopGoto('showAttachment', reqData)
    }
    this.$myApi.mainTab.showFileWebDisk = (reqData) => {
      return thisobj.gotoByTopGoto('showFileWebDisk', reqData)
    }
    this.$myApi.mainTab.showComposeMail = (
      options, /* {mbox: -1, uid: -1, type: "", subject: "", composeData: ""} */ /* undefined for new */
    ) => {
      return thisobj.gotoByTopGoto('showComposeMail', options)
    }

    this.$myApi.mainTab.closeALL = async() => {
      return await thisobj.closeALL()
    }
  },
  methods: {
    gotoByTopGoto(tabType, data, options) {
      this.$myApi.topGoto('mail', { tabType, data }, options)
    },
    pushRoute(tabType, data, options) {
      data = deepCopy(data)
      if (!options || options.isHistory !== true) {
        this.$myApi.routeSet('mail', { tabType, data }, options)
      }
    },
    showMail(readMailReqData, options) {
      let id = 'viewMail-'
      id += readMailReqData.uidvalidity + '-' + readMailReqData.uid
      options = deepCopy(options || {})
      options.pathArgs = { type: 'view', uv: readMailReqData.uidvalidity, uid: readMailReqData.uid }
      this.addItemAndPopup({
        type: 'viewMail',
        id: id,
        routeType: 'showMail',
        routeData: readMailReqData,
        routeOptions: options,
        subject: readMailReqData.subject,
        data: {
          readMailReqData: readMailReqData,
        },
      })
    },
    showWholeMail(args, options) {
      const fromType = args[0]
      const readMailReqData = args[1]
      let id = 'viewWholeMail-'
      id += readMailReqData.uidvalidity + '-' + readMailReqData.uid
      options = deepCopy(options || {})
      options.pathArgs = { type: 'view', uv: readMailReqData.uidvalidity, uid: readMailReqData.uid }
      this.addItemAndPopup({
        type: 'viewMail',
        id: id,
        routeType: 'showWholeMail',
        routeData: args,
        routeOptions: options,
        noMainMeunu: true,
        subject: readMailReqData.subject,
        data: {
          isWholeMode: true,
          type: fromType,
          readMailReqData: readMailReqData,
        },
      })
    },
    goBackMain(folderType) {
      this.popupItem(this.findItem(folderType || 'main'))
    },
    showMain(menuItem, options) {
      options = deepCopy(options || {})
      options.pathArgs = { type: 'mbox' }
      this.addItemAndPopup({
        type: 'main',
        id: 'main',
        routeType: 'showMain',
        routeData: menuItem,
        routeOptions: options,
        subject: menuItem.mbox_name,
        data: {
          menuItem: { ...menuItem },
        },
      })
    },
    showMailContact(menuItem, options) {
      this.addItemAndPopup({
        type: 'main',
        id: 'contact',
        routeType: 'showMailContact',
        routeData: menuItem,
        routeOptions: options,
        subject: this.$t('message.list.income_out_mail'),
        isPopup: true,
        data: {
          menuItem: { ...menuItem },
        },
      })
    },
    showMailSearch(menuItem, options) {
      this.addItemAndPopup({
        type: 'main',
        id: 'search',
        routeOptions: options,
        subject: this.$t('message.netdisk.result'),
        isPopup: true,
        data: {
          menuItem: { ...menuItem },
        },
      })
    },
    showMailNew(menuItem, options) {
      const _this = this
      if (!menuItem) {
        menuItem = {
          type: 'newMail',
          mbox: 'newMail',
          uid: 'newMail',
        }
      }
      this.addItemAndPopup({
        type: 'main',
        id: 'newMailList',
        routeOptions: options,
        subject: _this.$t('message.setting.signature36'),
        isPopup: true,
        data: {
          menuItem: { ...menuItem },
        },
      })
    },
    showTracking(reqData, options) {
      options = deepCopy(options || {})
      options.pathArgs = { type: 'tracking' }
      this.addItemAndPopup({
        type: 'tracking',
        id: 'tracking',
        routeType: 'showTracking',
        routeData: reqData || {},
        routeOptions: options,
        subject: this.$t('message.list.tracking'),
        data: reqData,
      })
    },
    showAudit(reqData, options) {
      options = deepCopy(options || {})
      options.pathArgs = { type: 'audit' }
      this.addItemAndPopup({
        type: 'audit',
        id: 'audit',
        routeType: 'showAudit',
        routeData: reqData || {},
        routeOptions: options,
        subject: this.$t('message.list.audit'),
        data: reqData,
      })
    },
    showAttachment(reqData, options) {
      options = deepCopy(options || {})
      options.pathArgs = { type: 'attachment' }
      this.addItemAndPopup({
        type: 'attachment',
        id: 'attachment',
        routeType: 'showAttachment',
        routeData: reqData || {},
        routeOptions: options,
        subject: this.$t('message.list.attachment'),
        data: reqData,
      })
    },
    showFileWebDisk(reqData, options) {
      options = deepCopy(options || {})
      options.pathArgs = { type: 'fileWebDisk' }
      this.addItemAndPopup({
        type: 'fileWebDisk',
        id: 'fileWebDisk',
        routeType: 'showFileWebDisk',
        routeData: reqData || {},
        routeOptions: options,
        subject: this.$t('message.list.fileWebDisk'),
        data: reqData,
      })
    },
    showComposeMail(
      options /* {mbox: -1, uid: -1, type: "", subject: "", composeData: ""} */ /* undefined for new */,
      routeOptions,
    ) {
      const tmpid = this.tabIdPlus++
      if (!options) {
        options = {}
      }
      let mbox = 'Drafts'
      if (options.mbox !== undefined && options.mbox !== '') {
        mbox = options.mbox
      }
      let id = 'composeMail-tmp-' + tmpid
      if (parseInt(options.uid) > 0) {
        id = 'composeMail-' + mbox + '-' + options.uid
      }
      let subject = this.$t('message.list.write_mail')
      if (options.subject !== undefined && options.subject !== '') {
        subject = options.subject
      }
      let composeType = 'new'
      if (options.type !== undefined && options.type !== '') {
        composeType = options.type
      }
      this.addItemAndPopup({
        type: 'composeMail',
        id: id,
        noMainMeunu: true,
        softOpen: true,
        subject: subject,
        data: {
          isCompose: true,
          composeData: options.composeData || {},
          composeType: composeType,
        },
      })
    },
    getFouse() {
      this.computeSliceIndex()
    },
    computeSliceIndex() {
      /** 单标签宽133 */
      const TabBarWidth = this.$refs.TabBar.offsetWidth
      const tabListWidth = TabBarWidth - 128 - 30
      const tabItemNum = tabListWidth / 133
      this.maxCount = Math.trunc(tabItemNum)
      if (this.maxCount < 1) {
        this.maxCount = 1
      }
      return this.maxCount
    },
    findRefByUniqueId(id) {
      let ref = null
      const refs = this.$refs.itemBodys
      for (let i = 0; i < refs.length; i++) {
        if (refs[i].$attrs['main-ky-tab-id-1101'] === id) {
          ref = refs[i]
          break
        }
      }
      return ref
    },
    findItem(id) {
      return this.tabs.find((o) => o.id === id)
    },
    clickItem(item) {
      this.popupItem(item)
    },
    popupItem(item) {
      // debugger
      if (!item) {
        item = this.tabs[0]
      }
      this.maps = this.tabs.map((o) => {
        o.isPopup = o.uniqueId === item.uniqueId
      })
      item.isPopup = true
      if (item.noMainMeunu === true) {
        this.menuShowFlag = false
      } else {
        this.menuShowFlag = true
      }
      this.generalOnPopup(item)

      const ref = this.findRefByUniqueId(item.uniqueId)
      if (ref && ref.afterPopupMe) {
        ref.afterPopupMe()
      }
      if (item.routeData) {
        this.pushRoute(item.routeType, item.routeData, item.routeOptions)
      }
    },
    addItemAndPopup(item) {
      let o = this.tabs.find((o) => o.id === item.id)
      if (!o) {
        o = deepCopy(item)
        if (o.isPopup === undefined) {
          o.isPopup = false
        }
        if (!o.data) {
          o.data = {}
        }
        o.uniqueId = this.tabIdPlus++
        o.idPlus = String(this.tabIdPlus++)
        this.tabs.push(o)
        this.computeSliceIndex()
      } else {
        // 是不是浏览器前进后退
        let refresh = false
        if ((!item.routeOptions || item.routeOptions.isHistory !== true)) {
          refresh = true
        } else {
          if (item.type === 'main') {
            if (item.routeOptions && item.routeOptions.lastRoutePath && item.routeOptions.lastRoutePath.split('#mail?type=mbox').length > 1) {
              refresh = true
            }
          }
        }
        if (refresh) {
          // softopen， 如果存在则，popup， 不覆盖数据
          if (item.softOpen !== true) {
            Vue.set(o, 'subject', item.subject)
            Vue.set(o, 'data', deepCopy(item.data))
          }
          Vue.set(o, 'idPlus', String(this.tabIdPlus++))
        }
        o.routeType = item.routeType
        o.routeOptions = item.routeOptions
        o.routeData = item.routeData
      }
      if (o.id === 'main') {
        if (this.mainShowFlag === false) {
          this.mainShowFlag = true
          if (this.tabs.length > 1) {
            return
          }
        }
      }
      this.popupItem(o)
    },
    generalOnPopup(item) {
      if (!item) {
        return
      }
      if (item.type === 'main') {
        if (item.data.menuItem.menuType === 'mbox') {
          this.$myApi.mainMenu.highlightItem(
            'mbox',
            item.data.menuItem.uidvalidity,
          )
        } else if (item.data.menuItem.menuType === 'tag') {
          this.$myApi.mainMenu.highlightItem('tag', item.data.menuItem.tag_id)
        }
      } else if (
        item.type === 'tracking' ||
        item.type === 'audit' ||
        item.type === 'attachment' ||
        item.type === 'fileWebDisk'
      ) {
        this.$myApi.mainMenu.highlightItem('other', item.type)
      } else if (item.type === 'viewMail') {
        this.$myApi.mainMenu.highlightItem(
          'mbox',
          item.data.readMailReqData.uidvalidity,
        )
      }
    },
    setItemSubject(itemId, subject) {
      const item = this.findItem(itemId)
      if (!item) {
        return
      }
      item.subject = subject || this.$t('message.list.no_subject')
    },
    async delItem(item) {
      const ref = this.findRefByUniqueId(item.uniqueId)
      if (!ref || !ref.beforeCloseMe) {
        this.delItemForced(item)
        return
      }
      const thisobj = this
      const ret = await ref.beforeCloseMe()
      if (ret === false) {
        return
      }
      thisobj.delItemForced(item)
      return
    },
    delItemForced(item) {
      if (typeof item === 'string') {
        item = { id: item }
      }
      let idx = -1
      for (let i = 1; i < this.tabs.length; i++) {
        if (this.tabs[i].id === item.id) {
          idx = i
          break
        }
      }
      if (idx === -1) {
        return
      }
      item = this.tabs[idx]
      this.tabs.splice(idx, 1)
      if (!item.isPopup) {
        return
      }
      if (idx > 1) {
        this.popupItem(this.tabs[idx - 1])
        return
      }
      if (this.tabs.length > 1) {
        this.popupItem(this.tabs[this.tabs.length - 1])
        return
      }
      this.popupItem()
    },
    clearItems() {
      const tabs = []
      tabs.push(this.tabs[0])
      for (let i = 1; i < this.tabs.length; i++) {
        if (this.tabs[i].data && this.tabs[i].data.isCompose === true) {
          tabs.push(this.tabs[i])
        }
      }
      this.tabs = tabs
      if (tabs.findIndex((item) => item.isPopup) > -1) {
        return
      }
      this.popupItem(this.tabs[0])
    },
    //
    closeMe(data, id) {
      this.delItemForced(id)
    },
    changeSubject(subject, id) {
      this.setItemSubject(id, subject)
    },
    async closeALL() {
      while (this.tabs.length > 1) {
        const item = this.tabs.at(-1)
        await this.delItem(item)
      }
      return
    },
    updateMailStatus(mailStatusList) {
      const refs = this.$refs.itemBodys
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i]
        if (ref && ref.updateMailStatusFromOuter) {
          ref.updateMailStatusFromOuter(mailStatusList)
        }
      }
    },
    // 子组件读信的回传参数
    changeMailData(data, id) {
      // id 和 uniqueId 有点乱了, 记得整理下操作首选 uniqueId, 逻辑查找首选 id
      // let id = 'viewMail-' id += readMailReqData.uidvalidity + '-' + readMailReqData.uid
      this.setItemSubject(id, data.subject)
      const item = this.findItem(id)
      if (!item) {
        return
      }
      let type = 'viewMail'
      if (item.data && item.data.isWholeMode === true) {
        type = 'viewWholeMail'
      }
      const newId = type + '-' + data.uidvalidity + '-' + data.uid
      if (newId === item.id) {
        return
      }
      item.id = newId
      // 设置新路由, 这段代码需要修改, 需求看信组件返回请求数据
      const options = {}
      options.pathArgs = { type: 'view', uv: data.uidvalidity, uid: data.uid }
      this.pushRoute(item.routeType, { uidvalidity: data.uidvalidity, uid: data.uid }, options)
    },
    // 保存草稿后
    saveDrafts(data, id) {
      const item = this.findItem(id)
      if (!item) {
        return
      }
      item.id = 'composeMail-' + data.mbox + '-' + data.newUid
    },
    listItemClick(data) {
      const readMailReqData = {}
      Object.assign(readMailReqData, data)
      this.$myApi.mainTab.showMail(readMailReqData)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';

.tabs-header {
  position: absolute;
  top: 0px;
  height: 32px;
  width: 100%;
  display: flex;
  overflow: visible;
  flex-direction: row;
  align-items: center;
  background: #ededed;
  border-bottom: 1px solid #dcdcdc;
}

.tabs-main {
  position: absolute;
  top: 33px;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  flex: 1;
  display: flex;
}

.tabs-body {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 261px;
  right: 0px;
  overflow: hidden;
  z-index: 1;
  flex: 1;
  display: flex;
  background-color: transparent;
}

.tabs-body-expand {
  left: 0px;
}

.tabs-item {
  width: 127px;
  height: 26px;
  line-height: 26px;
  background: #fcfcfc;
  border-radius: 5px 5px 0px 0px;
  margin-left: 6px;
  margin-top: 6px;
  display: flex;
  cursor: pointer;

  .name {
    width: 92px;
    margin-left: 10px;
    font-weight: 400;
    // color: #1D1B1B;
  }
}

.tabs-down {
  width: 24px;
  height: 26px;
  line-height: 26px;
  margin-left: 6px;
  background: #fcfcfc;
  border-radius: 5px 5px 0px 0px;
  text-align: center;
  cursor: pointer;
  margin-top:6px
}

.tab-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.mail-operation-main {
  width: 128px;
  height: 31px;
  line-height: 31px;
  margin-top: 3px;
  background: #fcfcfc;
  border-radius: 5px 5px 0px 0px;
  text-align: center;
  font-weight: bold;
  position: relative;
  top: 1px;
  cursor: pointer;
  margin-left: 0px;

  .name {
    margin-left: 10px;
    font-weight: bold;
  }
}
</style>
