<template>
  <div class="attachment_box noBorder trunBottom">
    <div class="action">
      <p class="action_right">
        <el-input
          v-model="search"
          :placeholder="$t('message.transfer.input_att_name')"
          clearable
          class="input-with-select"
          :title="$t('message.transfer.input_att_name')"
          @clear="searchFun"
          @keydown.enter.native="searchFun"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchFun"
          />
        </el-input>
      </p>
      <div class="action_left">
        <div class="select">
          <div class="Opea-svg-select-noBorder">
            <span
              class="Opea-svg-select-noBorder-span"
              style="margin-right: 5px;"
            >{{ $t(`message.netdisk.${typeOptions[typeValue].label}`) }}</span>
            <el-select
              v-model="typeValue"
              popper-class="Opea-svg-select-popper"
              :popper-append-to-body="false"
              style="width:120%;position: absolute;left:0;top:0;"
              @change="selectType"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="$t(`message.netdisk.${item.label}`)"
                :value="item.value"
              />
            </el-select></div></div>
        <span
          class="uploadBtn cur"
          @click="SplitUploadClick"
        >
          <svg-icon
            class="svg-theme-color icos-size-14 action_icon "
            icon-class="uploadBtn"
          /> {{ $t('message.transfer.upload') }}</span>

        <span
          class="cur"
          @click="delRow('all','',tableData)"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon "
          icon-class="view_del"
        /> {{ $t('message.transfer.del') }}</span>
        <em />
        <span
          class="cur"
          @click="toCompose(multipleSelection,'all')"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon "
          icon-class="compose"
        /> {{ $t('message.transfer.send') }}</span>
        <em />
        <span
          class="cur"
          @click="renewal('all','')"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon"
          icon-class="xuqi"
        /> {{ $t('message.transfer.increace_time') }}</span>
        <em />
        <span
          v-if="$store.state.app.user_permissions.netdisk == 1"
          class="cur"
          @click="handleIcludClick('all')"
        ><svg-icon
          class="svg-theme-color icos-size-14 action_icon"
          icon-class="webdisk"
        /> {{ $t('message.transfer.save_drive') }}</span>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      highlight-current-row
      :height="fileWebDiskTabHeight"
      class="table"
      @selection-change="handleSelectionChange"
      @sort-change="sortTabl"
    >
      <el-table-column
        type="selection"
        align="center"
        width="55"
      />
      <el-table-column
        :label="$t('message.transfer.file_name')"
        prop="name"
        min-width="200px"
      >
        <template
          slot-scope="scope"
        >
          <p
            class="one-line-ellipsis"
          >
            <svg-icon
              class="attaClass middle attachmentIcon"
              :icon-class="getFileType(scope.row.name)+'icon'"
              class-name="atticon"
            />
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
              :open-delay="300"
            >
              <div
                slot="content"
                style="max-width:500px"
              >{{ scope.row.name }}</div>
              <span>{{ scope.row.name == '' ? $t('message.transfer.no_file_name') : scope.row.name }}</span>
            </el-tooltip>
          </p>
        </template>

      </el-table-column>
      <el-table-column
        prop="nd_ctime"
        :label="$t('message.transfer.create_time')"
        sortable="custom"
        width="230"
      >
        <template slot-scope="scope">
          <p
            v-if="scope.row.type != 'splitupload'"
            class="colorBD"
          >{{ scope.row.nd_ctime | dateFilter }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="expire_time"
        :label="$t('message.transfer.expired')"
        sortable="custom"
        width="230"
      >
        <template slot-scope="scope">
          <p
            v-if="scope.row.expire_time < 253402185600 "
            :class="Number(scope.row.residue_expire_day) <= 2 ? 'text-color' : 'colorBD'"
          >{{ scope.row.expire_time | dateFilter }}</p>
          <p
            v-else
            class="colorBD"
          >{{ $t('message.transfer.forever') }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="mail_attachment_size"
        :label="$t('message.transfer.size')"
        sortable="custom"
        width="140"
      >
        <template slot-scope="scope">
          <p class="colorBD">{{ scope.row.mail_attachment_size | mailSizefilter }}</p>
        </template>
      </el-table-column>

      <el-table-column
        prop="download_num"
        :label="$t('message.transfer.download_times')"
        sortable="custom"
        width="200"
      />
      <el-table-column
        label=" "
        width="360"
      >
        <template
          slot-scope="scope"
        >
          <div>
            <el-tooltip
              v-if="isPreView(scope.row.name)"
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.preview')"
              placement="top-start"
              :open-delay="300"
            >
              <span>
                <svg-icon
                  class="svgIcon"
                  icon-class="preview"
                  @click="downAtta('preview',scope.row)"
                />
              </span>
            </el-tooltip>
            <el-tooltip
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.down')"
              placement="top-start"
              :open-delay="300"
            >

              <span>
                <svg-icon
                  class="svgIcon cur"
                  icon-class="download"
                  @click="downAtta('download',scope.row)"
                />
              </span>
            </el-tooltip>
            <el-tooltip
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.send')"
              placement="top-start"
              :open-delay="300"
            >

              <span>
                <svg-icon
                  class="svgIcon"
                  icon-class="compose"
                  @click="toCompose(scope.row,'one')"
                />
              </span>
            </el-tooltip>
            <el-tooltip
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.del')"
              placement="top-start"
              :open-delay="300"
            >

              <span>
                <svg-icon
                  class="svgIcon"
                  icon-class="view_del"
                  @click="delRow('one',scope.row,tableData,scope.$index)"
                />
              </span>
            </el-tooltip>

            <el-tooltip
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.increace_time')"
              placement="top-start"
              :open-delay="300"
            >

              <span>
                <svg-icon
                  class="svgIcon"
                  icon-class="xuqi"
                  @click="renewal('one',scope.row)"
                />
              </span>
            </el-tooltip>

            <el-tooltip
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.rename')"
              placement="top-start"
              :open-delay="300"
            >
              <span>
                <svg-icon
                  class="svgIcon"
                  icon-class="chongming"
                  @click="renameNew(scope.row)"
                />
              </span>
            </el-tooltip>
            <el-tooltip
              v-if="$store.state.app.user_permissions.netdisk == 1"
              class="item cur tooltip"
              effect="light"
              :content="$t('message.transfer.save_drive')"
              placement="top-start"
              :open-delay="300"
            >
              <span>
                <svg-icon
                  class="svgIcon"
                  icon-class="webdisk"
                  @click="handleIcludClick(scope.row)"
                />
              </span>
            </el-tooltip>
          </div>
          <!-- <div
            v-else
            slot-scope="scope"
          >
            <span v-if="scope.row.hashProgress!==100&&scope.row.type==='splitupload'">{{ `文件扫描中${scope.row.hashProgress}%` }}</span>
            <div
              v-if="scope.row.hashProgress==100"
              class="progress"
            >
              <el-progress
                :stroke-width="3"
                :percentage="scope.row.percentage"
                style="flex:1"
              />
            </div>
            <div
              v-if="scope.row.status=='error'"
              class="progress"
            >上传失败</div>
            <svg-icon
              v-if="scope.row.hashProgress!=100 || scope.row.percentage !=100"
              class="closeFile cur"
              icon-class="closeFile"
              @click="handleRemove(scope.row,scope.$index, tableData)"
            />
          </div> -->
        </template>

      </el-table-column>
    </el-table>
    <!-- 翻页 -->
    <p
      v-if="total>0"
      class="trunPage"
    >
      <el-pagination
        :current-page="page"
        :page-size="page_size"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </p>
    <!-- 重命名 -->
    <el-dialog
      :title="$t('message.transfer.rename')"
      :visible.sync="dialogRename"
      :modal-append-to-body="false"
    >
      <el-form :model="form">
        <el-form-item>
          <p
            class="wtbox"
          >
            <el-input
              v-model="form.new_nd_name"
              autocomplete="off"
              class="noLimit"
              maxlength="128"
              show-word-limit
            />
          </p>
        </el-form-item>

      </el-form>
      <p class="error">{{ error_txt }}</p>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancel">{{ $t('message.btnText.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="submitRenameNew()"
        >{{ $t('message.btnText.ok') }}</el-button>
      </div>
    </el-dialog>
    <!-- 转存网盘 -->
    <saveDisk
      :file-list="fileListDisk"
      :is-show="showDialogDisk"
      :is-type="isType"
    />
  </div>
</template>
<script>
import { GetFileType, transformClass } from '@/utils/utils.js'
// import { getObjArr } from '@/utils/localstorage'
// import SplitUpload from '@/components/SplitUpload/index.vue'
import serialize from '@/utils/serialize'
import saveDisk from '../components/saveDisk.vue'
export default {
  name: 'FileWebDisk',
  components: {
    // SplitUpload,
    saveDisk,
  },
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
    dateFilter(time) {
      return transformClass.timeFormat(time, '年月日')
    },
  },

  props: {
    idPlus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      isAttaDown: false,
      showUpload: true, // 是否开启上传
      tableData: [],
      newTableRow: [], // 正在上传的文件
      multipleSelection: [],
      typeOptions: [{
        value: '0',
        label: 'all',
      }, {
        value: '1',
        label: 'image',
      }, {
        value: '2',
        label: 'audio',
      }, {
        value: '3',
        label: 'vodeo',
      }, {
        value: '4',
        label: 'doc',
      }, {
        value: '5',
        label: 'zip',
      }, {
        value: '6',
        label: 'other',
      },
      //  {
      //   value: '7',
      //   label: '文件夹',
      // }
      ],
      typeValue: '0',
      // currentPage: 1, // 前往第几页
      // page_size: this.$store.state.app.userGetConfig, // 每页多少条
      page_size: Number(this.$store.state.app.userGetConfig.page_size), // 每页多少条
      page: 1, // 第几页
      total: 0, // 总共多少条
      orderby: 'nd_ctime', // 默认排序
      sequence: 'DESC',
      dialogRename: false, // 重命名文件夹
      form: {
        new_nd_name: '', // 新文件名称
      },
      row: {}, // 需要的参数
      fileListDisk: [], // 保存网盘选中的文件
      showDialogDisk: false, // 是否打开保存网盘
      error_txt: '', // 错误消息
      isType: {
        file: 'fileWebdDisk',
        isAll: false,
      },
      fileWebDiskTabHeight: window.innerHeight - 200,
    }
  },
  computed: {
    /** 监听附件上传是否成功 */
    isUpload_success() {
      return this.$store.state.network.isUpload_fileWebDisk_success
    },
  },
  watch: {
    isUpload_success(newVal, oldVal) {
      if (newVal === true) {
        this.$store.commit('network/isUpload_fileWebDisk_SET', false)
        this.getList()
        // 实时请求文件中转站大小
      }
    },
    idPlus(newVal, oldVal) {
      if (newVal) {
        this.page = 1
        this.getList()
      }
    },
  },
  mounted() {
    this.getList()
    window.onresize = () => {
      return (() => {
        this.fileWebDiskTabHeight = window.innerHeight - 200
      })()
    }
  },
  created() {
  },
  methods: {
    SplitUploadClick() {
      this.$store.commit('network/UPLOAD_TYPE_SETTING', 'fileWebDisk') // 上传类型为网盘
      this.$store.commit('network/IS_NETWORK_UPLOAD_SETTING', true) // 触发上传
    },
    // 搜索
    searchFun() {
      console.log('jhjjj')
      this.page = 1
      this.typeValue = '0'
      this.getList()
    },
    // 跳转到写信
    toCompose(data, type) {
      let fileData = []
      if (type === 'all') {
        fileData = [...data]
      } else {
        fileData.push(data)
      }
      if (fileData.length === 0) {
        this.$message.error(this.$t('message.transfer.select_files'))
        return false
      }
      const ids = fileData.map(item => {
        return item.nd_id
      })
      const params = {
        module: 'netdisk',
        require: {
          action: 'setSuperatt',
          data: {
            nd_id_list: ids.toString(),
          },
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(res => {
        const list = res.result.list
        fileData.forEach(i => {
          i.expire = list[i.nd_id][1]
          i.nd_url = list[i.nd_id][0]
          i.nd_size = i.mail_attachment_size
        })
        const data = fileData
        this.$myApi.mainTab.showComposeMail({ type: 'transfer', subject: this.$t('message.mbox.newmail'), composeData: data })
      })
    },
    // 文件类型
    getFileType(name) {
      if (!name) {
        return false
      }
      const type = GetFileType.fileType(name)
      return type
    },
    // 列表选择值
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 保存网盘
    handleIcludClick(val) {
      if (val === 'all' && this.multipleSelection.length === 0) {
        this.$message.error(this.$t('message.transfer.select_files'))
        return false
      }
      this.showDialogDisk = true
      this.fileListDisk = []
      if (val === 'all') {
        this.fileListDisk = this.multipleSelection
        this.isType.isAll = true
      } else {
        this.fileListDisk.push(val)
        this.isType.isAll = false
      }
    },
    // 是否能够预览
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    // 文件中转站、网盘下载
    async downAtta(type, el) {
      if (type === 'allDown') {
        let name = this.multipleSelection[0].name
        const index = name.lastIndexOf('.')
        name = name.substring(0, index)
        // 打包下载
        this.downAttaReq = {
          nd_id_list: this.rows(),
          is_mail_attachment: 1,
        }
      } else {
        // 单个下载
        this.downAttaReq = {
          nd_id: el.nd_id,
          is_mail_attachment: 1,
        }
        if (type === 'preview') {
          this.downAttaReq.preview = 1
          this.downAttaReq.disposition = 'inline'
          this.downAttaReq.suffix = el.nd_name_suffix.toLowerCase()
          this.downAttaReq.length = el.nd_size
          const type = ['txt', 'pdf', 'jpe', 'jpeg', 'jpg', 'gif', 'png']
          if (!type.includes(this.downAttaReq.suffix)) {
            const t = {
              module: 'mail',
              require: {
                action: 'attachmentPreview',
                ...this.downAttaReq,
              },
            }
            const { result: res } = await this.$axios.post(this.$apiData.mail, { req: serialize(t) })
            if (Array.isArray(res)) {
              return this.$alert(this.$t('message.view.not_support_type'), this.$t('message.btnText.info'), {
                confirmButtonText: this.$t('message.btnText.ok'),
                type: 'warning',
              })
            } else {
              window.open(res.preview_url)
            }
            return false
          }
        }
      }
      const req = {
        'module': 'netdisk',
        ...this.downAttaReq,
      }
      const url = '/nsmail/mail.php/fn.dat?task=download&req=' +
            encodeURIComponent(JSON.stringify(req)) + '&req_type=json'
      window.open(url)
    },
    // 获取列表
    getList() {
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'getList',
          data: {
            words: this.search,
            limit: this.page_size,
            page: this.page,
            is_mail_attachment: 1, // 0 网盘 1文件中转站
            parent_nd_id: 'top',
            orderby: this.orderby,
            sequence: this.sequence,
            simpleMode: 0,
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        const table = res.result.list.map(item => {
          item.name = item.nd_name
          return item
        })
        this.tableData = table
        this.total = res.result.total
      })
    },
    rows() {
      const nums = []
      this.multipleSelection.forEach(el => {
        nums.push(el.nd_id)
      })
      return nums.toString()
    },
    // 续期
    renewal(type, row) {
      if (type === 'all' && this.multipleSelection.length === 0) {
        this.$message.error(this.$t('message.transfer.select_files'))
        return false
      }
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'renewal',
          data: {
            nd_id: type === 'one' ? row.nd_id : this.rows(),
            is_mail_attachment: 1, // 0 网盘 1文件中转站
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        this.$message.success(this.$t('message.transfer.increace_time_ok'))
        if (type === 'all') {
          this.multipleSelection.map(el => {
            res.result.list.map(item => {
              if (item.nd_id === el.nd_id) {
                el.expire_time = item.expire_time
              }
              return el.expire_time
            })
          })
        } else {
          row.expire_time = res.result.list[0].expire_time
        }
      })
    },
    // 重命名
    renameNew(row) {
      this.dialogRename = true
      this.row = row
      this.form.new_nd_name = row.name
    },
    submitRenameNew() {
      this.error_txt = ''
      const rule = '\/:*?<>|,'
      for (const i in rule) {
        if (this.form.new_nd_name.indexOf(rule[i]) !== -1) {
          this.error_txt = this.$t('message.transfer.not_support')
          return false
        }
      }
      if (this.form.new_nd_name === this.row.name) {
        this.dialogRename = false
        return false
      }
      const req = {
        'module': 'netdisk',
        'require': {
          action: 'rename',
          data: {
            nd_id: this.row.nd_id,
            is_mail_attachment: 1, // 0 网盘 1文件中转站
            new_nd_name: this.form.new_nd_name,
          },
        },
      }
      this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
        this.dialogRename = false
        this.row.name = this.form.new_nd_name
        this.$message.success(this.$t('message.transfer.rename_ok'))
        this.error_txt = ''
      })
    },
    // 取消
    cancel() {
      this.dialogRename = false
      this.error_txt = ''
    },
    // 删除
    delRow(type, row, rows, index) {
      if (type === 'all' && this.multipleSelection.length === 0) {
        this.$message.error(this.$t('message.transfer.select_files'))
        return false
      }
      this.$confirm(this.$t('message.transfer.delete_not_down'), this.$t('message.btnText.info'), {
        confirmButtonText: this.$t('message.btnText.ok'),
        cancelButtonText: this.$t('message.btnText.cancel'),
      }).then(() => {
        const req = {
          'module': 'netdisk',
          'require': {
            action: 'remove',
            data: {
              nd_id_list: type === 'one' ? row.nd_id : this.rows(),
              is_mail_attachment: 1, // 0 网盘 1文件中转站
            },
          },
        }
        this.$axios.post(this.$apiData.netdisk, { req: serialize(req) }).then(res => {
          this.$message.success(this.$t('message.transfer.del_ok'))
          if (type === 'one') {
            rows.splice(index, 1)
            this.total = this.total - 1
            if (rows.length === 0) {
              this.page = this.page === 1 ? 1 : this.page - 1
              this.getList()
            }
          } else {
            this.getList()

            if (this.tableData.length === 0 || this.tableData.length === 1) {
              this.page = this.page === 1 ? 1 : this.page - 1
              this.getList()
            }
            rows.map((el, index) => {
              if (this.multipleSelection.filter(item => item.nd_id === el.nd_id).length) {
                rows.splice(index, 1)
                this.total = this.total - 1
              }
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('message.view.cancel_del'),
        })
      })
    },
    // 切换格式
    selectType() {
      this.search = ''
      this.page = 1
      this.orderby = 'nd_ctime' // 默认排序
      this.sequence = 'DESC'
      if (this.typeValue === '0') {
        this.getList()
      } else {
        this.changeAtta()
      }
    },
    changeAtta() {
      const req = { 'module': 'netdisk', 'require': { action: 'getListByCategory',
        data: {
          nd_type: this.typeValue === '6' ? '0' : this.typeValue,
          page: this.page,
          limit: this.page_size,
          is_mail_attachment: 1, // 0网盘；1中转站
          orderby: this.orderby,
          sequence: this.sequence,
        }}}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        const table = res.result.list.map(item => {
          item.name = item.nd_name
          return item
        })
        this.tableData = table
        this.total = res.result.total
      })
    },
    // 翻页
    handleCurrentChange(val) {
      this.page = Number(`${val}`)
      if (this.typeValue === '' || this.typeValue === '0') {
        this.getList()
      } else {
        this.changeAtta()
      }
    },
    // 排序事件
    sortTabl(column) {
      this.orderby = column.prop
      this.sequence = column.order === 'ascending' ? 'ASC' : 'DESC'
      this.page = 1
      if (this.typeValue === '' || this.typeValue === '0') {
        this.getList()
      } else {
        this.changeAtta()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
@import '@/styles/tbNoBorder.scss';
.attachment_box {
  width: 100%;
  height: 100%;
  padding: 20px;
  // overflow: auto;
  .uploadBtn{
    // width: 90px;
    padding:0 10px;
    height: 28px;
    line-height: 28px!important;
    // background: #D0161B;
    border-radius: 2px 2px 2px 2px;
    color: #fff;
    text-align: center;
    margin: 0 30px;
  }
  .action {
    position: relative;
    .action_right{
      // float: right;
      position: absolute;
      right: 0;
      .el-input-group{
        z-index:1;
      }
    }
    .action_left{
      height: 32px;
      display:flex;
    }
    .select{
      float:left;
      min-width: 82px;
      // padding: 0 10px;
      font-weight: bold;
    }
    .Opea-svg-select-noBorder{
      margin:0 20px 0 0;
    }
    em {
      width: 1px;
      height: 20px;
      background: #c3c3c3;
      float: left;
      margin: 6px 14px 0;
    }
    span {
      float: left;
      line-height: 32px;
    }
    .action_icon{
      vertical-align: middle;
    }
  }
  .el-table__row{
      &:hover .svgIcon{
          display: block;  // 设置父元素hover时子元素的样式 【实现要点！！！！！】
      }
    }
  .tooltip{

    margin: 0 10px;
    float: left;
    width: 1.2em;
    height: 1.2em;
  }
  .svgIcon{
    display: none;
    width: 1.2em;
    height: 1.2em;
  }

  .attachmentIcon{
    width: 1.2em;
    height: 1.2em;
  }

  .table{
    margin-top: 8px;
  }
  .wtbox /deep/.el-input--small .el-input__inner {
    padding-right: 80px;
  }
  .wtbox {
    position: relative;
  }
  .wt_nums {
    position: absolute;
    right: 10px;
    bottom: 0;
  }
  .progress{
    width: 190px;
    display: inline-block;
  }
  .closeFile{
    width: 1em;
    vertical-align: middle;
  }
  .error{
    color:#d0161b;
  }
}
</style>
