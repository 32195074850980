import store from '@/store'
// import { getCookie } from '@/utils/cookie'
import axios from '@/api/axios-requery'
import apiData from '@/api/api-store'
import { saveObjArr } from '@/utils/localstorage'
import serialize from '@/utils/serialize'
// import { rolesSet } from '@/utils/rolesSet'

/** 登录初始化页面
 * 通栏、分栏
 * 菜单
 * 是否记住标签
 *  */
export const loginInit = (callback) => {
  // 获取系统配置信息
  axios.post(apiData.getConfig, {})
    .then(res => {
      if (res && res.result) {
        window._kglobal_myData.sysConfig = res.result
      }

      // 获取用户配置信息
      const params = {
        'module': 'user',
        'require': {
          'action': 'getConfig',
        },
      }
      axios.post(apiData.mail, { req: serialize(params) })
        .then(res => {
          if (res && res.result) {
            window._kglobal_myState.isLogonState = true
            store.commit('app/USER_GET_CONFIG_DATA__SETTING', res.result)
            console.log('执行--------------')
            window._kglobal_myApi.updateUserConfig(res.result)
            getBaseInfo(callback)
            window.vm.$i18n.locale = window._kglobal_myState.userConfig.show_language
            localStorage.setItem('lang', window._kglobal_myState.userConfig.show_language)
          }
        })
    })
}

/** 退出清空 */
const logginoutTrueDo = async(data) => {
  // if (store.state.mail.isDestroy) {
  //   // 刷新按钮
  //   Vue.prototype.$confirm('此邮件为阅读后销毁邮件，关闭后，则不能再次阅读', '警告', {
  //     confirmButtonText: '我知道了',
  //     cancelButtonText: '取消',
  //     type: 'warning',
  //     showClose: false,
  //     showCancelButton: false,
  //   }).then(() => {
  //     // 正常跳转
  //     // 标记是否是销毁邮件
  //     store.commit('mail/ISDESTROY', false)
  //     axios.post(apiData.logout, {}).then((res) => {
  //       sessionVuexClear()
  //       clearCookie()
  //       if (store.state.app.userGetConfig.prompt === 'tab_never') {
  //         store.commit('tagsView/TAB_VIEWS_SETTING', [])
  //       }
  //       router.push({
  //         path: '/login',
  //         query: { 'celar': true },
  //       })
  //     })
  //   })
  // } else
  // if (router.currentRoute.name === 'compose') {
  await window._kglobal_myApi.mainTab.closeALL()
  await window._kglobal_ajaxRequest(apiData.logout, {})
  // 清空数据
  sessionVuexClear()
  clearCookie()
  window._kglobal_myState.isLogonState = false
  window._kglobal_myApi.topGoto('login', { clear: true })
  window._kglobal_myApi.topGotoByRedirect('login')
}

export const loginOut = (data) => {
  logginoutTrueDo(data)
}
/** 清空会话缓存数据 */
export const sessionVuexClear = (data) => {
  sessionStorage.clear()
  store.commit('app/stateDataSet')
  store.commit('contact/stateDataSet')
  store.commit('mail/stateDataSet')
  store.commit('network/stateDataSet')
  store.commit('tagsView/stateDataSet')
  // 清空Cookie
  if (data !== 'not_clearCookie') clearCookie()
}

/** 切换授权清空会话缓存数据 */
export const sqVuexClear = (data) => {
  sessionStorage.clear()
  store.commit('contact/stateDataSet')
  store.commit('mail/stateDataSet')
  store.commit('network/stateDataSet')
  store.commit('tagsView/stateDataSet')
}
export const clearCookie = (data) => {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (var i = keys.length; i--;) {
      if (keys[i] === 'nsmail_6_0' || keys[i] === 'user_code') {
        document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString()// 清除当前域名下的,例如：m.kevis.com
        document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString()// 清除当前域名下的，例如 .m.kevis.com
        document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString()// 清除一级域名下的或指定的，例如 .kevis.com
      }
    }
  }
}
/** 切换账号，删除所有vuex及缓存数据 */
export const handoverUsers = (data) => {
  store.commit('tagsView/MAIL_VUEX_LS_CLEAR')
}
/** 获取用户权限 */
export const getBaseInfo = (callback) => {
  const data = {
    'module': 'user',
    'require': {
      'action': 'getBaseInfo',
      'login_type': 'password',
      'password': store.state.app.userInfo.user_pwd,
      'user_name': store.state.app.userInfo.user_mail,
    },
  }

  axios.post(apiData.mail, { 'req': serialize(data) }).then(res => {
    const resdata = res.result
    store.commit('app/OVER_TIME_SETTING', resdata.over_time) // 超时时间
    store.commit('app/LOCK_TIME_SETTING', resdata.lock_time) // 锁屏时间
    // 用户某些功能权限
    let netdisk = resdata.netdisk
    let home = 1
    let domainAddressbook = resdata.domainAddressbook
    if (resdata.auth_info.curr_auth_type && (resdata.auth_info.curr_auth_type === 1 || resdata.auth_info.curr_auth_type === 0)) {
      // 授权权限 1和0 为写信和读信权限
      netdisk = 0
      home = 0
      domainAddressbook = 0
    }
    // USER_PERMISSIONS_SETTING
    const user_permissions = {
      'autoForward': resdata.autoForward, // 自动转发
      'open_client': resdata.open_client, // 是否开启客户端
      'org_name': resdata.org_name, // 切换授权账号后被授权人邮箱
      'open_calendar': resdata.schedule, // 开启日历日程
      'home': home, // 首页
      'mailbox': resdata.mailbox, // 邮箱功能
      'netdisk': netdisk, // 网盘
      'domainAddressbook': domainAddressbook, // 通讯录
      'all_alias': resdata.all_alias, // 所有的别名
      'web_logo': resdata.web_logo,
      'web_title': resdata.web_title,
      'ent_use_client_code': resdata.ent_use_client_code, // 是否开启客户端授权码 1是开启 其他则是未开启
    }
    store.commit('app/USER_PERMISSIONS_SETTING', user_permissions)
    window._kglobal_myState.user_permissions = user_permissions
    // window._kglobal_myState.preview_atta_type = resdata.preview_atta_type
    // 客户端跳到审核页面sessionStorage数据全部丢失
    store.commit('app/preview_atta_type_SET', resdata.preview_atta_type)
    document.title = user_permissions.web_title
    store.commit('app/USER_MAIL_ADDRESS_SETTING', resdata.mail)
    /** 存储用户的邮件地址、别名，登录时校验，如何不是上一个人，清空浏览器存储信息 */
    const userAllmail_alias = [resdata.mail, ...resdata.all_alias]
    localStorage.setItem('userAllmail_alias', userAllmail_alias)
    // grantMail 是否支持授权邮箱管理 1为支持--代表设置列表中是否有该选项
    store.commit('app/WATER_MARK', resdata.water_config)
    store.commit('app/NODE', resdata.node)
    store.commit('app/MAIL_TO_MAX', resdata.mail_to_max)
    store.commit('app/LIMIT_ATTA_TYPE', resdata.limit_atta_type)
    store.commit('app/UPLOAD_MODE_LIMIT', resdata.upload_mode_limit)
    store.commit('app/MAIL_LIMIT', resdata.max_mail_size)
    store.commit('app/STATION_LIFESPAN', resdata.station_lifespan)
    store.commit('app/LIFESPAN_FOREVER', resdata.lifespan_forever)
    store.commit('app/OPEN_CLIENT', resdata.open_client)
    store.commit('app/ADDRESSBOOKDISPLAY', resdata.yes_no_public)
    // 上传文件限制类型
    saveObjArr('limit_atta_type', resdata.limit_atta_type)
    // 设置授权信息(userOfficeMail 授权列表)
    store.commit('app/AUTH_INFO', resdata.auth_info)
    // 是否有企业通讯录
    store.commit('app/ADDRESSBOOKDISPLAY', resdata.yes_no_public)
    // curr_auth_mail  true代表非本人登录邮箱  false代表本人登录
    if (resdata.auth_info.curr_auth_mail) store.commit('app/AUTH_AUTHORITY', resdata.auth_info.curr_auth_type)
    else store.commit('app/AUTH_AUTHORITY', 9)
    store.commit('app/IS_LOCK_SHOW_SETTING', true) // 是否显示锁屏(注意后期不是用户名密码登录的情况,需要修改添加判断)
    // 设置的一些信息
    const settingInfo = {
      is_ent_admin: resdata.is_ent_admin,
      move_mail_type: resdata.move_mail_type,
      move_mail_from: resdata.move_mail_from,
      weakPassowrd: resdata.code, // 是否需要修改密码
      force_recall: resdata.force_recall, // 是否开启强制召回
    }
    store.commit('app/SETTING_INFO', settingInfo)

    store.commit('app/REALNAME', resdata.mail)
    // rolesSet()
    // FIXME, 差audit
    if (typeof (callback) === 'function') {
      callback()
    } else {
      const userGetConfig = store.state.app.userGetConfig
      if (userGetConfig.page_inbox === 'yes') {
        window._kglobal_myApi.topGoto('mail')
      } else {
        window._kglobal_myApi.topGoto('home')
      }
    }
  })
}
