import { transformClass } from '@/utils/utils'
let stateInfo = {}
const state = {
  isLock: false, // 是否锁屏
  isLockShow: false, // 是否展示锁屏功能
  isFirstLlogin: false, // 是否首次登录
  userGetConfig: {
    page_inbox: 'no', // 登陆后是否打开收件箱 yes是（打开收件箱）；no否打开首页
    mail_view_type: 'tabulation', // 邮件视图 tabulation列表模式 subfield分栏模式
    list_view_type: '', // 展示模式 standard标准模式 conversation会话模式
    show_mail_diges: 'yes', // 是否显示邮件摘要 yes是 no否
    page_size: 20, // 每页显示条数
    show_att_image: 'yes', // 显示附件图片
    show_tag_type: 'long', // 收发件人显示格式 short短标签（姓名＋邮件地址） long长标签（姓名+所属组织）
    show_image: 'always', // 显示内嵌图片 always总是 never从不 contact来自通讯录的信件
    prompt: 'tab_never', // 恢复上次打开的标签页 tab_automatic自动恢复， tab_never从不恢复
    sent_autosave: 'yes', // 发信时自动保存到发件箱 yes是 no否
    draft_autosave: '60', // 自动保存到草稿箱时间（秒）
    prefix_re: '回复：', // 回复主题前缀
    prefix_forward: '转发：', // 转发主题前缀
    prefix_receipt: '', // 回执主题前缀
    reply_add_original_text: 'yes', // 回复时加入原文 yes是 no否
    auto_addcontact: 'no', // 自动添加收件人到通讯录 yes是 no否
    attachment_key: '', // 附件提醒关键字 多个之间用英文逗号分割
    req_receipt: 'no', // 默认请求回执 yes开启；no关闭
    word_size: '', // 字号 small小（默认）standard标准 big大 superBig超大
    write_font_size: '14', // 写信字体大小
    write_font: 'Microsoft YaHei', // 写信字体,默认微软雅黑
    write_font_color: '#C00000', // 色号
    reply_receipt: 'hand', // 阅读回执 ignore全部忽略，hand手动选择；auto自动回执
    archive_scope: '', // 归档范围
    archive_cycle: '0', // 归档周期（天）
    skin_colour: '', // 皮肤风格，GREY_RED简约风格(灰红色)
    font_size: 14, // 字体大小
    time_format: '24', // 时间格式 12小时制 24小时制
    show_language: 'simplified_chinese',
    timezone: '', // 时区
    new_message: 'yes', // 新消息到达通知 yes开 no关
    notice_scope: 'all', // 通知范围 inbox收件箱 all所有邮件
    notice_method: 'popup', // 通知方式 popup弹窗
    show_contact_type: '', // yes no
    lang: '', // 语言 zh_CN
    more_window: '', // 多窗口显示 no不多窗口 yes多窗口
    pubcontact: '', // 默认展开组功能
    open_trustadress: '', // 系统可信地址 yes是 no否
  }, // 用户配置
  // passwordRule: {}, // 密码规则
  auth_info: {}, // 授权邮箱规则
  auth_authority: 9, // 授权邮箱权限0读信 1读写 2管理
  open_client: 0, // 客户端设置管理权限 1开启
  water_mark: [], // 水印相关
  node: '', // 节点
  mail_to_max: 200, // 邮件地址最大个数
  limit_atta_type: '', // 附件限制类型
  upload_mode_limit: 20971520, // 附件最大类型
  mail_limit: 50971520, // 邮件最大类型
  settingInfo: {}, // 设置里面的一些信息
  yes_no_public: '',
  loginSetInfo: { // 自定义登录页背景图、logo、版权信息
    login_background_url: '',
    login_logo: '',
    copyright: '',
  },
  over_time: '', // 超时时间 单位分钟
  lock_time: '', // 锁屏时间，单位分钟
  user_permissions: {
    all_alias: [],
  }, // 用户某些功能权限判断
  user_mail_address: '', // 用户邮箱
  userInfo: {}, // 登录后的用户信息
  realName: '', // 登录后的邮箱
  station_lifespan: 14, // 有效期  天
  lifespan_forever: 1, // 是否永久有效 1是
  roles: ['all'], // 路由权限
  isPhishingEmail: false,
  preview_atta_type: [],
  isFoldersettClear: false,
}

const mutations = {
  IS_LOCK_SETTING: (state, data) => {
    state.isLock = data
  },
  IS_LOCK_SHOW_SETTING: (state, data) => {
    state.isLockShow = data
  },
  IS_FIRST_LOGIN: (state, data) => {
    state.isFirstLlogin = data
  },
  USER_GET_CONFIG_DATA__SETTING: (state, data) => {
    console.log('userConfig', data)
    for (const key in state.userGetConfig) {
      if (data[key]) {
        if (key === 'font_size' || key === 'page_size') state.userGetConfig[key] = Number(data[key])
        else state.userGetConfig[key] = data[key]
      }
    }
    localStorage.setItem('prompt', state.userGetConfig.prompt)
  },
  // PASSWORD_RULE: (state, data) => {
  //   state.passwordRule = data
  // },
  AUTH_INFO: (state, data) => {
    state.auth_info = data
  },
  AUTH_AUTHORITY: (state, data) => {
    state.auth_authority = data
  },
  OPEN_CLIENT: (state, data) => {
    state.open_client = data
  },
  WATER_MARK: (state, data) => {
    state.water_mark = data
  },
  NODE: (state, data) => {
    state.node = data
  },
  MAIL_TO_MAX: (state, data) => {
    state.mail_to_max = data
  },
  UPLOAD_MODE_LIMIT: (state, data) => {
    state.upload_mode_limit = data
  },
  MAIL_LIMIT: (state, data) => {
    state.mail_limit = data
  },
  STATION_LIFESPAN: (state, data) => {
    state.station_lifespan = data
  },
  LIFESPAN_FOREVER: (state, data) => {
    state.lifespan_forever = data
  },
  ISPHISHINGEMAIL: (state, data) => {
    state.isPhishingEmail = data
  },
  LIMIT_ATTA_TYPE: (state, data) => {
    state.limit_atta_type = data
  },
  ADDRESSBOOKDISPLAY: (state, data) => {
    state.yes_no_public = data
  },
  SETTING_INFO: (state, data) => {
    state.settingInfo = data
  },
  LOGIN_SETINFO_SETTING: (state, data) => {
    for (const key in state.loginSetInfo) {
      if (data[key]) {
        state.loginSetInfo[key] = data[key]
      }
    }
  },
  OVER_TIME_SETTING: (state, data) => {
    state.over_time = data
  },
  LOCK_TIME_SETTING: (state, data) => {
    state.lock_time = data
  },
  USER_PERMISSIONS_SETTING: (state, data) => {
    state.user_permissions = data
    document.title = state.user_permissions.web_title
  },
  USER_MAIL_ADDRESS_SETTING: (state, data) => {
    state.user_mail_address = data
  },
  USERINFO: (state, data) => {
    state.userInfo = data
  },
  REALNAME: (state, data) => {
    state.realName = data
  },
  ROLES_SET: (state, data) => {
    state.roles = data
  },
  preview_atta_type_SET: (state, data) => {
    state.preview_atta_type = data
  },
  isFoldersettClear_SET: (state, data) => {
    state.isFoldersettClear = data
  },
  stateDataClear: (state, data) => {
    stateInfo = transformClass.deepClone(state)
  },
  stateDataSet: (state, data) => {
    for (const key in stateInfo) {
      state[key] = stateInfo[key]
    }
  },
}

const actions = {
  themeNameSetting({ commit }, data) {
    commit('THEME_NAME_SETTING', data)
  },
}

export default {
  namespaced: true,
  // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
  // 例： SET_NAME => user/SET_NAME
  state,
  mutations,
  actions,
}
