// 传入时间戳获取当前年月日
const getYearMonthDay = (timeStamp) => {
  const date = new Date(timeStamp)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const weekDay = date.getDay()
  return { year, month, day, weekDay }
}
// 获取每个月1号为周几
const getStartMonthDay = (year, month) => {
  return new Date(year, month - 1, 1).getDay()
}
// 获取每个月的天数
const getMonthCount = (year, month) => {
  return new Date(year, month, 0).getDate()
}
const formatDate = (date) => {
  var year = date.getFullYear()
  var month = date.getMonth() + 1 // 注意：JavaScript中月份从0开始，因此需要加1
  var day = date.getDate()

  // 将月份和日期拼接为两位数
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  var formattedDate = year + '-' + month + '-' + day
  return formattedDate
}
export { getYearMonthDay, getStartMonthDay, getMonthCount, formatDate }
