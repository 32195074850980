<template>
  <div class="tracking_box noBorder trunBottom">
    <p class="tit">
      <el-input
        v-model="search"
        :placeholder="$t('message.audit.input_mail_sub')"
        clearable
        class="input-with-select"
        @clear="searchFun"
        @keydown.enter.native="searchFun"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchFun"
        />
      </el-input>
      <span>{{ $t('message.audit.audit_tips') }}</span>
    </p>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      :height="auditTabHeight"
      class="table"
    >
      <el-table-column
        prop="audit_time"
        :label="$t('message.audit.time')"
      />
      <el-table-column
        prop="from_mail"
        :label="$t('message.audit.to')"
      />
      <el-table-column
        prop="mail_subject"
        :label="$t('message.audit.sub')"
      >
        <template
          slot-scope="scope"
        >
          <p
            class="one-line-ellipsis"
          >
            <el-tooltip
              class="item"
              effect="light"
              placement="top"
            >
              <div
                slot="content"
                style="max-width:500px"
              >{{ scope.row.mail_subject }}</div>
              <span>{{ scope.row.mail_subject && scope.row.mail_subject != '' ? scope.row.mail_subject : $t('message.list.no_subject') }}</span>
            </el-tooltip>
          </p>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('message.setting.clientoperate')"
        width="230"
      >
        <template slot-scope="scope">

          <span
            class="cur"
            @click="goAudit(scope.row.url,scope.row.mail_subject)"
          >{{ $t('message.audit.auditing') }}</span>

        </template>
      </el-table-column></el-table>
    <mailRecall />
    <!-- 翻页 -->
    <p
      v-if="total>0"
      class="trunPage"
    >
      <el-pagination
        :current-page="page"
        :page-size="page_size"
        layout="total, prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </p>
  </div>
</template>
<script>
import mailRecall from '../components/mailRecall.vue'
import serialize from '@/utils/serialize'
import { getQueryVariable } from '@/utils/utils'
export default {
  name: 'Tracking',
  components: {
    mailRecall,
  },
  props: {
    idPlus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      tableData: [],
      // tableInfo: {
      //   uidvalidity: this.$route.query.uidvalidity,
      //   uv: this.$route.query.uv,
      // },
      page_size: Number(this.$store.state.app.userGetConfig.page_size), // 每页多少条
      page: 1, // 第几页
      total: 0, // 总共多少
      auditTabHeight: window.innerHeight - 200,
    }
  },
  watch: {
    idPlus(newVal, oldVal) {
      if (newVal) {
        this.page = 1
        this.getList()
      }
    },
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.auditTabHeight = window.innerHeight - 200
      })()
    }
  },
  created() {
    this.getList()
  },
  methods: {

    searchFun() {
      this.page = 1
      this.getList()
    },
    getList() {
      const req = { 'module': 'mail', 'require': { action: 'to_audit_list', limit: this.page_size, page: this.page, type: 'folder', search: this.search }}
      this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
        this.tableData = res.result.rows
        this.total = res.result.total
      })
    },
    goAudit(url, subject) {
      /** 跳转读信 */
      const req = {
        token: getQueryVariable.url('token', url),
        nsnode: getQueryVariable.url('nsnode', url),
        isAudit: getQueryVariable.url('isAudit', url),
        client_from: getQueryVariable.url('client_from', url),
      }
      console.log(url, '审核url')
      window.open(window.location.origin + '/#/audit?' + JSON.stringify(req))
      // this.$myApi.mainTab.showWholeMail('audit', req)
    },
    handleCurrentChange(val) {
      this.page = `${val}`
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>

/*无边框table*/

@import '@/styles/tbNoBorder.scss';
.tracking_box{
  width: 100%;
  height: 100%;
  padding: 20px;
  .tit{
    /deep/.el-input{
      float:right;
      width: 270px;
      height: 30px;
      background: #F9F9F9;
      // border: 1px solid rgba(166,166,166,0.17);
    }
    span{
      height: 30px;
      line-height: 30px;
      color: #1D1B1B;
      font-weight: bold;
    }
  }
  .cur{
    cursor: pointer;
    color: #D0161B;
  }
  .table{
    margin-top: 8px;
  }
}

</style>
