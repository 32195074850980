import { render, staticRenderFns } from "./EmailAddress.vue?vue&type=template&id=200d5140&scoped=true"
import script from "./EmailAddress.vue?vue&type=script&lang=js"
export * from "./EmailAddress.vue?vue&type=script&lang=js"
import style0 from "./EmailAddress.vue?vue&type=style&index=0&id=200d5140&prod&lang=scss"
import style1 from "./EmailAddress.vue?vue&type=style&index=1&id=200d5140&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200d5140",
  null
  
)

export default component.exports