// import axios from '@/api/axios-requery.js'
// import apiData from '@/api/api-store.js'
import { transformClass } from '@/utils/utils'
// import Vue from 'vue'
let stateInfo = {}
const state = {
  menu_open: true, // 二级菜单默认打开
  isColumns: true, // true分栏,false通栏
  menu_unseen: {}, // 各邮箱文件夹未读邮件数量
  menu_regular: [], // 左侧固定邮箱
  menu_label: [], // 标签邮件菜单文件夹
  // move_mailbox: [], // 移动邮箱
  // all_mailbox: [], // 全部文件夹(包含不订阅)
  tabMenu: {}, // 邮件模块选中邮箱
  tabFolder: {}, // 邮件模块选中邮箱
  tabViews: [], // 邮件标签导航栏
  isTabOpen: false, // 是否打开标签，用以标识tab文件夹是否标红
  is_tabViews_replace: true, // 是否替换标签，草稿箱删除和取消写信的，保存后不替换标签
  is_tabViewsDel: false, // 是否删除标签栏
  tabViewsDelIndex: '', // 删除标签栏的下标
  tabViews_isOpenIndex: '', // 当前打开的标签下标
  allCheak: false, // 全选状态
  allSelect: '当前页', // 全选类型
  mail_list: [], // 邮箱列表数据
  // reqMailData: {}, // 接口请求参数
  filter_query: {}, // 二次搜索下拉框选中值
  twoSearchSelectData: {}, // 二次搜索条件
  is_reqMail: true, // 是否接口请求
  mail_list_check: [], // 邮箱列表数据--选中
  mail_check_num: 0, // 邮箱列表数据--选中数量
  mail_total: 0, // 邮箱总条数
  mail_type: '', // 列表类型，folder(文件夹内邮件列表), search(搜索), highsearch(高级搜索), contac(往来邮件), conversation(会话邮件)
  stamp: '', // unix时间戳, 最新一次获取文件夹列表的时候会返回,获取新邮件时使用
  readMail: {}, // 读信请求数据
  // list_scrollTop: 0, // 滚动条位置
  list_page: 1, // 分页页码
  from_limit: 0, // 起始位置
  isMailListReload: false, // 邮件列表数据是否重新加载
  isDownFlag: false, // 是否拖拽移动
  isDownFlagHover: '', // 拖拽的区域位置
  menu_parent_uv: [], // 自定义文件夹父级文件夹uidvalidity
  // flags:{uidvalidity:{uid:{备注:'',星标:''}, ...}, ...}
  changMailFlags: { '1682673810': { '4': {}, '3': {}, '2': {}, '1': {}}, '1682846345': { '3': { 'comment': '我是备注3', 'flags': '01010000', 'tag': '' }, '2': { 'comment': '我是备注2', 'flags': '01000000', 'tag': [{ tag_color: '#8CBBEA', tag_id: '11ss61lfg6x', tag_name: '但是' }] }, '1': { 'comment': '我是备注1', 'flags': '01000000', 'tag': '' }}},
}
const mutations = {
  // MENU_OPEN_SETTING: (state, data) => {
  //   state.menu_open = !state.menu_open
  // },
  // 邮件模块菜单--固定文件夹
  // MENU_REGULAR_SETTING: (state, view) => {
  //   state.menu_regular = view
  // },
  // 邮件模块菜单--未读数量更新
  // MENU_UNSEEN_SET: (state, view) => {
  //   state.menu_unseen = view
  // },
  // 是否更新文件夹未读数量
  // MENU_UNSEEN_UPDATE: (state, view) => {
  //   const params = {
  //     'module': 'mbox',
  //     'require': {
  //       'action': 'getList',
  //       'force': '0', // 是否强制同步邮件数1是; 0否
  //     },
  //   }
  //   axios.post(apiData.mail, { req: JSON.stringify(params) })
  //     .then((res) => {
  //       const mboxList = res.result.mboxList
  //       const menu_unseen = {}
  //       mboxList.forEach(item => {
  //         menu_unseen[item.mbox] = item.unseen
  //       })
  //       mutations.MENU_UNSEEN_SET(state, menu_unseen)// 各邮箱未读数量
  //     })
  // },
  // 邮件模块菜单-- 标签文件夹
  // MENU_LABEL_SETTING: (state, view) => {
  //   state.menu_label = view
  // },
  // 邮件分栏通栏
  // IS_COLUMNS_SETTING: (state, data) => {
  //   state.isColumns = data
  // },
  // 移动邮箱数据
  // MOVE_MAILBOX_SETTING: (state, view) => {
  //   state.move_mailbox = view
  // },
  // 添加邮件模块选中菜单
  // TAB_MENU_SETTING: (state, view) => {
  //   // localStorage.setItem('tabMenu', JSON.stringify(view))
  //   state.tabMenu = view
  // },
  // 添加快捷操作
  // TAB_FOLDER_SETTING: (state, view) => {
  //   // localStorage.setItem('tabFolder', JSON.stringify(view))
  //   state.tabFolder = view
  // },
  // 清空邮件模块选中菜单
  // TAB_MENU_CLEAR: (state, view) => {
  //   // localStorage.setItem('tabMenu', JSON.stringify(view))
  //   // state.tabMenu = view
  //   localStorage.setItem('tabMenu', JSON.stringify({}))
  //   state.tabMenu = {}
  // },
  // 关闭浏览器重新打开标签导航栏赋值
  // TAB_VIEWS_SETTING: (state, view) => {
  //   state.tabViews = view
  //   localStorage.setItem('tabViews', JSON.stringify(state.tabViews))
  // },
  // 添加单个标签导航栏
  // TAB_VIEWS_ADD: (state, view) => {
  //   console.log('记得要删除 TAB_VIEWS_ADD')
  //   const tabViews = state.tabViews
  //   let isRepeat = false
  //   let index = ''
  //   tabViews.forEach((item, i) => {
  //     item.isOpen = false
  //     if (item.data.uid === view.data.uid && item.data.mbox === view.data.mbox) {
  //       isRepeat = true
  //       index = i
  //     }
  //   })
  //   if (isRepeat) {
  //     if (view.path === '/compose') view.data.cache = 'cache'
  //     state.tabViews.splice(index, 1, view)// 已有数据，同步更新
  //   } else {
  //     if (tabViews.length === 20) state.tabViews.splice(19, 1, view) // 最多可添加10个标签导航栏
  //     else state.tabViews.push(view)
  //   }
  //   mutations.TAB_VIEWS_isTabOpen(state, true)
  //   localStorage.setItem('tabViews', JSON.stringify(state.tabViews))
  // },
  /** 是否打开标签，用以标识tab文件夹是否标红 */
  // TAB_VIEWS_isTabOpen: (state, index) => {
  //   state.isTabOpen = index
  // },
  // is_tabViewsDel_Set: (state, data) => {
  //   state.is_tabViewsDel = data
  // },
  // 删除某个标签
  // TAB_VIEWS_DEL: (state, index) => {
  //   state.tabViews.splice(index, 1)
  //   localStorage.setItem('tabViews', JSON.stringify(state.tabViews))
  //   state.tabViewsDelIndex = index
  //   mutations.is_tabViewsDel_Set(state, true)
  // },
  // 清空标签导航栏
  // TAB_VIEWS_CLEAR: (state, data) => {
  //   state.tabViews = data
  //   localStorage.setItem('tabViews', JSON.stringify([]))
  // },
  // 全选状态同步
  // ALL_CHEAK__SETTING: (state, data) => {
  //   state.allCheak = data
  // },
  /** 全选类型同步 */
  // ALL_SELECT_SETTNG: (state, data) => {
  //   state.allSelect = data
  // },
  // 添加邮件列表数据
  // ADD_MAIL_LIST: (state, data) => {
  //   const isColumns = state.isColumns
  //   if (isColumns) {
  //     if (state.from_limit === 0)state.mail_list = data
  //     else if (state.from_limit >= state.mail_list.length)state.mail_list = state.mail_list.concat(data)
  //   } else {
  //     state.mail_list = data
  //   }
  // },
  // reqMailDataSet: (state, data) => {
  //   state.reqMailData = data
  // },
  filterQuerySet: (state, data) => {
    state.filter_query = data
  },
  twoSearchSelectDataSet: (state, data) => {
    state.twoSearchSelectData = data
  },
  // 删除邮件列表数据
  // DEL_MAIL_SETTING: (state, index) => {
  //   state.mail_list.splice(index, 1)
  // },
  // 列表数据更改状态
  // MAIL_LIST_SETTING: (state, data) => {
  //   if (data === '全部邮件' || data === '当前页') {
  //     state.mail_list.forEach(item => {
  //       item.checked = true
  //     })
  //     if (data === '当前页') state.mail_check_num = state.mail_list.length
  //   } else if (data === '不选') {
  //     state.mail_list.forEach(item => {
  //       item.checked = false
  //     })
  //     state.mail_check_num = 0
  //   } else {
  //     if (data === '反选') {
  //       state.mail_list.forEach(item => {
  //         item.checked = !item.checked
  //       })
  //     } else if (data === '未读') {
  //       state.mail_list.forEach(item => {
  //         const flags = item.flags.charAt(1)
  //         if (flags === '1') item.checked = false // 已读
  //         else item.checked = true // 未读
  //       })
  //     } else if (data === '紧急') {
  //       state.mail_list.forEach(item => {
  //         if (item.priority === '1') item.checked = true
  //         else item.checked = false
  //       })
  //     }
  //     let num = 0
  //     state.mail_list.forEach(item => {
  //       if (item.checked) num = num + 1
  //     })
  //     state.mail_check_num = num
  //   }
  // },
  // 总条数
  // MAIL_TOTAL_SETTING: (state, data) => {
  //   state.mail_total = data
  // },
  // 邮件选中数量
  // MAIL_CHECK_NUM_SETTING: (state, data) => {
  //   state.mail_check_num = data
  // },
  // 文件类型
  // MAIL_TYPE_SETTING: (state, data) => {
  //   state.mail_type = data
  // },
  // 读信请求数据
  // READ_MAIL_SETTING: (state, data) => {
  //   state.readMail = data
  // },
  /** 操作后列表数据同步 */
  /** flags 邮件标记, 形如 01000000, 共8位
     * 每个位置可选 0(否) 或 1(是),
     * 从左到右每个位置代表的意思:
     * unknown(保留),seen(已读),draft(草稿),flagged(星标),forwarded(转发),mdnsent(已自动回复收条),answered(已回复),attachment(有附件)
     */
  /** data格式
     * {
    //  type: 'readMail', // list
    //  flags: '', // 操作类型
    //  value: '', // 操作值
    //  uid: '',
    //  uidvalidity: '',
    // }
     */
  // MAIL_FLAGS_SET: (state, data) => {
  //   const flagsObj = {
  //     'unknown': 0, // 保留
  //     'seen': 1, // 已读
  //     'draft': 2, // 草稿
  //     'flagged': 3, // 星标
  //     'forwarded': 4, // 转发
  //     'mdnsent': 5, // 已自动回复收条
  //     'answered': 6, // 已回复
  //     'attachment': 7, // 有附件
  //   }
  //   const index = flagsObj[data.flags]
  //   // 置顶、取消置顶清空数据，重新加载
  //   if (data.flags.indexOf('topping') > -1) {
  //   } else {
  //     if (data.type === 'list') {
  //       state.mail_list.forEach(item => {
  //         if (item.checked) {
  //           let str = []
  //           str = item.flags.split('')
  //           str[index] = data.value
  //           item.flags = str.join('')
  //         }
  //       })
  //     } else if (data.type === 'readMail') {
  //       state.mail_list.forEach(item => {
  //         if (item.uid === data.uid && item.uidvalidity === data.uidvalidity) {
  //           let str = []
  //           str = item.flags.split('')
  //           str[index] = data.value
  //           item.flags = str.join('')
  //         }
  //       })
  //       let str = []
  //       str = state.readMail.data.flags.split('')
  //       str[index] = data.value
  //       state.readMail.data.flags = str.join('')
  //     }
  //   }
  // },
  // CHANGE_MAIL_FLAGS: (state, data) => {
  //   // 需要重新单个赋值给
  //   console.log(state.changMailFlags, data, 'changMailFlags')
  //   const flagsObj = {
  //     // 'unknown': 0, // 保留
  //     // 'seen': 1, // 已读
  //     // 'draft': 2, // 草稿
  //     'flagged': 3, // 星标
  //     // 'forwarded': 4, // 转发
  //     // 'mdnsent': 5, // 已自动回复收条
  //     // 'answered': 6, // 已回复
  //     // 'attachment': 7, // 有附件
  //   }
  //   const index = flagsObj[data.flags]
  //   if (data.type === 'flags') {
  //     // 点击星标时
  //     // const str = []
  //     // str = state.changMailFlags[data.uidvalidity][data.uid].flags.split('')
  //     // str[index] = data.value
  //     // state.changMailFlags[data.uidvalidity][data.uid].flags = str.join('')
  //     console.log(index, '2222')
  //   }
  // },
  /** 移动操作，移动到某个邮箱 */
  // TO_MOVE_MAIL_SET: (state, data) => {
  //   console.warn('TO_MOVE_MAIL_SET 将被清理')
  //   if (data.type === 'list') {
  //     // 判断标签里面有没有邮箱更改移动或删除
  //     state.mail_list.forEach(item => {
  //       if (item.checked) {
  //         const tabViews = state.tabViews
  //         tabViews.forEach((tv, i) => {
  //           if (item.uid === tv.data.uid && item.uidvalidity === tv.data.uidvalidity) {
  //             if (data.mbox === 'NsmailSending') { // 彻底删除时删除新标签页导航栏内的邮件
  //               mutations.TAB_VIEWS_DEL(state, i)
  //             } else { // 移动及删除时，更改标签导航栏内的邮件邮箱
  //               tv.data.mbox = data.mbox
  //               tv.data.uidvalidity = data.uidvalidity
  //             }
  //           }
  //         })
  //       }
  //     })
  //     // 列表页的重新请求数据
  //     mutations.IS_MAIL_LIST_RELOAD_SET(state)
  //   } else if (data.type === 'readMail') {
  //     const mail_list = state.mail_list
  //     const mbox = state.tabMenu.mbox
  //     // 列表数据同步
  //     for (let i = 0; i < mail_list.length; i++) {
  //       if (mail_list[i].uid === data.uid && mail_list[i].uidvalidity === data.uidvalidity) {
  //         // 单邮箱的删除列表此条
  //         if (state.mail_type === 'folder' && mbox !== 'unseent' && mbox !== 'tag' && mbox !== 'start') {
  //           mutations.DEL_MAIL_SETTING(state, i)
  //         } else {
  //           mail_list[i].uidvalidity = data.uidvalidity
  //           mail_list[i].mbox = data.mbox
  //         }
  //         break
  //       }
  //     }
  //     // 新标签页导航栏数据更新
  //     const tabViews = state.tabViews
  //     tabViews.forEach((tv, i) => {
  //       if (data.uid === tv.data.uid && data.uidvalidity === tv.data.uidvalidity) {
  //         if (data.mbox === 'NsmailSending') { // 彻底删除时删除新标签页导航栏内的邮件
  //           mutations.TAB_VIEWS_DEL(state, i)
  //         } else { // 移动及删除时，更改标签导航栏内的邮件邮箱
  //           tv.data.mbox = data.mbox
  //           tv.data.uidvalidity = data.uidvalidity
  //         }
  //       }
  //     })
  //   }
  // },
  /** 滚动条位置 */
  // SCROLL_TOP_ID_SET: (state, data) => {
  //   state.list_scrollTop = data
  // },
  /** 控制邮件列表数据是否重新加载,滚动条不变,页码不变，只重新加载数据，置顶、取消置顶、移动、删除时使用此方法 */
  // IS_MAIL_LIST_RELOAD_SET: (state, data) => {
  //   mutations.CLEAR_MAIL_LIST(state, [])
  //   state.isMailListReload = true
  // },
  // isMailListReloadSetFalse: (state, data) => {
  //   state.isMailListReload = false
  // },
  // 清空邮件列表数据
  // CLEAR_MAIL_LIST: (state, data) => {
  //   state.mail_list = []
  //   state.mail_check_num = 0
  //   state.mail_total = 0
  //   state.allCheak = false
  //   state.allSelect = '当前页'
  //   state.is_reqMail = false
  //   state.list_scrollTop = 0
  // },
  /** 是否拖拽移动 */
  // IS_DOWN_FLAG_SETTING: (state, data) => {
  //   state.isDownFlag = data
  // },
  /** 鼠标在推拽移动的何处，区域位置 */
  // IS_DOWN_FLAG_HOVER_SETTING: (state, data) => {
  //   state.isDownFlagHover = data
  // },
  /** 切换用户，删除所有数据，包括localStorage */
  MAIL_VUEX_LS_CLEAR: (state, data) => {
    // const userInfo = localStorage.getItem('userInfo')
    localStorage.clear() // 清空所有本地数据
    // localStorage.setItem('userInfo', userInfo)
    // sessionStorage.clear()
    // const userGetConfig = this.$store.state.app.userGetConfig
    state.menu_open = true // 二级菜单默认打开
    state.isColumns = false // true分栏,false通栏
    state.menu_regular = [] // 邮件模块菜单初始化
    state.move_mailbox = [] // 移动邮箱
    // state.all_mailbox = [] // 全部文件夹
    state.tabMenu = {} // 邮件模块选中邮箱
    state.tabViews = [] // 邮件标签导航栏
    state.allCheak = false // 全选状态
    state.allSelect = '当前页'
    state.mail_list = [] // 邮箱列表数据
    state.mail_list_check = [] // 邮箱列表数据--选中
    state.mail_check_num = 0 // 邮箱列表数据--选中数量
    state.mail_total = 0 // 邮箱总条数
    state.mail_type = '' // 列表类型，folder(文件夹内邮件列表), search(搜索), highsearch(高级搜索), contac(往来邮件), conversation(会话邮件)
    state.readMail = {} // 读信请求数据
    // state.list_scrollTop = 0 // 滚动条位置
    state.list_page = 1 // 分页页码
  },
  // /** 邮件菜单操作 */
  // menuLinkFun: (state, data) => {
  //   // mutations.TAB_MENU_SETTING(state, data)// 添加邮件模块选中文件夹
  //   // mutations.TAB_FOLDER_SETTING(state, data) // 添加快捷操作
  //   // 添加type列表类型folder(文件夹内邮件列表)
  //   const type = 'folder'
  //   // 星标邮件、标签邮件的列表类型为搜索
  //   // if (data.mbox === 'start' || data.mbox === 'tag') type = 'search'
  //   mutations.MAIL_TYPE_SETTING(state, type)
  // },
  // menu_nodeTree_Set: (state, data) => {
  //   state.menu_parent_uv = data
  // },
  /**
   * stateDataClear,stateDataSet清空缓存重置vuex数据
   */
  stateDataClear: (state, data) => {
    stateInfo = transformClass.deepClone(state)
  },
  stateDataSet: (state, data) => {
    for (const key in stateInfo) {
      state[key] = stateInfo[key]
    }
  },
}

const actions = {
  /** 标记操作 */
  // flagSetAction({ commit }, data) {
  //   const params = {
  //     'module': 'mail',
  //     'require': {
  //       action: 'updateFlags',
  //       flags: { [data.flagsName]: data.flagsValue },
  //       mboxList: data.mboxList,
  //     },
  //   }
  //   axios.post(apiData.mail, { req: JSON.stringify(params) })
  //     .then(res => {
  //       let dataObj = {}
  //       if (data.type === 'list') {
  //         dataObj = {
  //           type: 'list',
  //           flags: data.flagsName, // 操作类型
  //           value: data.flagsValue, // 值
  //         }
  //       } else {
  //         dataObj = {
  //           type: 'readMail',
  //           flags: data.flagsName, // 操作类型
  //           value: data.flagsValue, // 值
  //           uid: data.mboxList[0].uidList,
  //           uidvalidity: data.mboxList[0].uidvalidity,
  //         }
  //       }
  //       commit('MAIL_FLAGS_SET', dataObj)
  //       const marker_options = {
  //         'seen:0': '未读',
  //         'seen:1': '已读',
  //         'flagged:1': '星标',
  //         'flagged:0': '取消星标',
  //         'topping:1': '置顶',
  //         'topping:0': '取消置顶',
  //         'tag': '选标签',
  //       }
  //       const key = data.flagsName + ':' + data.flagsValue
  //       if (data.type === 'list' || data.isShowMessage) {
  //         Vue.prototype.$message({
  //           type: 'success',
  //           message: '标记为' + marker_options[key] + '邮件成功',
  //         })
  //       }
  //       // 标记为已读、未读更新文件夹的未读数量
  //       if (data.flagsName === 'seen') commit('MENU_UNSEEN_UPDATE', true)
  //     })
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

