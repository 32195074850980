<template>
  <div
    v-show="isNewNotice"
    class="inbox-new-notice-container"
  >
    <i
      class="el-icon-close"
      @click="isNewNotice=false"
    />
    <div class="inbox-new-notice-mail">
      <svg-icon
        class="icos-size-14"
        icon-class="unread"
      />
      <span style="margin-left: 6px;">{{ $t('message.mailmodule.new_mail',{num:newMailTotal}) }}</span>
    </div>
    <div
      class="inbox-new-notice-subject text-color"
      @click="CheckNewMail"
    >
      <span>{{ $t('message.mailmodule.go_view') }}>></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      isNewNotice: false,
      newMailTotal: 0,
    }
  },
  computed: {
  },
  watch: {
  },
  beforeCreate() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
    this.stopNewNotice()
  },
  methods: {
    /** 新邮件通知 */
    startNewNotice() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.getINBOXnewList()
      }, 60000)
    },
    stopNewNotice() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    async getINBOXnewList() {
      if (!this.$myState.isLogonState) {
        return
      }
      const params = {
        'module': 'mail',
        'require': {
          'action': 'getNew',
          'stamp': this.$myData.newMailStamp,
        }}
      const res = await this.$ajaxRequest(this.$apiData.mail, params)
      const mailList = res.result.mailList
      this.$myData.newMailStamp = res.result.stamp
      if (JSON.stringify(mailList) !== '[]') {
        const len = mailList.length
        this.newMailTotal = len
        if (len > 0) {
          this.isNewNotice = true
          this.$myData.newMailList = mailList
        }
        setTimeout(() => {
          this.isNewNotice = false
        }, 8000)
      }
      // 开启新邮件达到通知可设置通知范围和方式，关闭新邮件通知，收到新邮件不进行提示。
    },
    // 查看新邮件
    CheckNewMail() {
      this.isNewNotice = false
      this.$myApi.mainTab.showMailNew()
    },
  },
}
</script>
<style lang="scss" scoped>
.inbox-new-notice-container{
  position: absolute;
  z-index: 1;
  bottom: 37px;
  right: 24px;
  width: 280px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 5px;
  border: 1px solid #D4D4D4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px 16px;
  font-weight: bold;
  .el-icon-close{
    position: absolute;
    top:8px;
    right: 8px;
    font-weight: bold;
    color: #9a9999;
    cursor: pointer;
  }
}
.inbox-new-notice-mail{
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.inbox-new-notice-subject{
  width: 100%;
  flex: 1;
  padding-left: 20px;
  padding-top: 2px;
  cursor: pointer;
}
</style>
