const apiData = {
  getConfig: '/mail.php?task=getConfig',
  getUserList: '/mail.php?task=getUserList',
  get_login_setting: '/mail.php?task=get_login_setting',
  login: '/mail.php?task=login',
  logout: '/mail.php?task=logout',
  checklogin: '/mail.php?task=checklogin',
  setLogin: '/mail.php?task=setlogin',
  ckpassword_new: '/mail.php?task=ckpassword_new',
  get_pass_policy: '/mail.php?task=get_pass_policy',
  downloadAtta: '/mail.php?task=download&time=' + new Date() * 1,
  codeImg: '/checkCode/code.php',
  mailBrevity: '/mail.php',
  mail: '/mail.php?task=mail&time=' + new Date() * 1,
  netdisk: '/mail.php?task=netdisk&time=' + new Date() * 1,
  getShareInfo: '/mail.php?task=getShareInfo',
  getShareDetail: '/mail.php?task=getShareDetail',
  getFileSize: '/mail.php?task=getFileSize',
  convert_mail: '/mail.php?task=convert_mail',
  audit_mail: '/mail.php?task=audit_mail',
}
export default apiData
