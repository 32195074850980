<template>
  <!-- 分栏页面 -->
  <div class="three-columns-container">
    <div class="three-columns-mail-list disable-select">
      <!-- 全选 -->
      <div class="three-columns-operating-area">
        <div class="checked-all flax-align-items-center">
          <el-checkbox
            v-model="allCheak"
            @change="allCheakChange"
          />
          <el-select
            v-model="allSelect"
            popper-class="three-columns-operating-popper-select"
            :popper-append-to-body="false"
            style="width:45px;position: absolute;left: 15px;top:5px;"
            @click.native="allSelectClear"
          >
            <el-option
              v-for="item in allchecked_options"
              :key="item.value"
              :label="$t(`message.colmail.${item.label}`)"
              :value="item.value"
              @click.native="allSelectChange"
            />
          </el-select>
        </div>
        <!-- <div style="color: #ffffff;"><div ref="moveDiv">已选中{{ mail_check_num?mail_check_num:'1' }}封邮件</div></div> -->
        <!-- 筛选,新邮件无筛选功能 -->
        <div
          v-if="mail_type != 'newMail'"
          class="sieving-cascader"
        >
          <svg-icon
            class="icos-size-14"
            style="position: absolute;right:0;top:5px;"
            icon-class="mall_sieving"
          />
          <el-cascader
            v-model="cherry_pick"
            :options="cherry_pick_options"
            :props="{ expandTrigger: 'hover' }"
            popper-class="pc-sel-area-cascader"
            style="opacity: 0;"
            @change="cherryPickChange"
          > <template slot-scope="{ node }">
            <span>{{ $t(`message.maillist.${node.label}`) }}</span>
          </template>
          </el-cascader>
        </div>
      </div>
      <!-- 搜索结果,二次搜索 -->
      <div
        v-if="isTwoSearch"
        class="three-search-column"
      >
        <span style="font-size: 114%;">{{ $t('message.colmail.search_res') }}</span>
        <span v-if="mail_type === 'search'">
          （{{ $t('message.colmail.find_ok') }}“
          <span
            class="text-color"
            style="word-wrap: break-word;"
          >{{ words }}</span>
          ”{{ $t('message.colmail.total_num_mail',{num:mail_total}) }}）
        </span>
        <span v-else-if="mail_type === 'highsearch'">
          （{{ $t('message.colmail.hight_search',{total:mail_total}) }}）
        </span>
        <div>
          <el-select
            v-model="filter_query.mbox"
            clearable
            :placeholder="$t('message.colmail.an_mail')"
            class="search-column-tab-selset"
            style="width: 100px;"
            @clear="searchSelectClear('mbox')"
          >
            <el-option
              v-for="(val, key) in mboxSelect"
              :key="key"
              :label="key | mboxNameFilter"
              :value="key"
              @click.native="searchSelect"
            >
              <span>{{ key | mboxNameFilter }}（{{ val }}）</span>
            </el-option>
          </el-select>
          <el-select
            v-model="filter_query.from"
            clearable
            :placeholder="$t('message.colmail.an_from')"
            class="search-column-tab-selset"
            style="width: 120px;"
            @clear="searchSelectClear('from')"
          >
            <el-option
              v-for="(val, key) in filter_result.from"
              :key="key"
              :label="key"
              :value="key"
              @click.native="searchSelect"
            >
              <span>{{ key }}（{{ val }}）</span>
            </el-option>
          </el-select>
          <el-select
            v-model="filter_query.to"
            clearable
            :placeholder="$t('message.colmail.an_to')"
            class="search-column-tab-selset"
            style="width: 120px;"
            @clear="searchSelectClear('to')"
          >
            <el-option
              v-for="(val, key) in filter_result.to"
              :key="key"
              :label="key"
              :value="key"
              @click.native="searchSelect"
            >
              <span>{{ key }}（{{ val }}）</span>
            </el-option>
          </el-select>
          <el-select
            v-model="filter_query.date"
            clearable
            :placeholder="$t('message.colmail.an_time')"
            class="search-column-tab-selset"
            style="width: 100px;"
            @clear="searchSelectClear('date')"
          >
            <el-option
              v-for="(val, key) in filter_result.date"
              :key="key"
              :label="dateObj[key]"
              :value="key"
              @click.native="searchSelect"
            >
              <span>{{ dateObj[key] }}（{{ val }}）</span>
            </el-option>
          </el-select>
          <el-select
            v-model="filter_query.attachment"
            clearable
            :placeholder="$t('message.colmail.an_has_att')"
            class="search-column-tab-selset"
            style="width: 140px;"
            @clear="searchSelectClear('attachment')"
          >
            <el-option
              :label="$t('message.colmail.has_att')+'（' + filter_result.attachment + '）'"
              value="1"
              @click.native="searchSelect"
            />
            <el-option
              :label="$t('message.colmail.no_att')+'（' + filter_result.not_attachment + '）'"
              value="0"
              @click.native="searchSelect"
            />
          </el-select>
        </div>
      </div>
      <!-- 列表 -->
      <div
        id="three_multicolumn_mail"
        class="three-multicolumn-mail"
        style="position: relative;"
      >
        <div
          v-if="is_reqMail&&mail_list.length < 1"
          class="not-maillist-container"
        >
          {{ $t('message.colmail.foder_no_mail') }}
        </div>
        <div
          v-else
          id="three_mail"
          ref="three_mail"
          :class="isTwoSearch ? 'three-mail-overflow-search' : 'three-mail-overflow'"
          @scroll="scrollEvent($event)"
        >
          <!-- <div
            v-for="(item, index) in mail_list"
            :id="'three_mail_item' + (index + 1)"
            :key="index"
            class="column-tab-item theme-hover-background"
            :class="Number(item.topping)>0?'topping-background':''|| item.checked?'theme-click-background':''"
            style="font-weight: normal;"
            @click="mailLinkFun(item,index)"
          > -->
          <div
            v-for="(item, index) in mail_list"
            :id="'three_mail_item' + (index + 1)"
            :key="index"
            class="column-tab-item theme-hover-background"
            :class="Number(item.topping) > 0 ? 'topping-background' : '' || item.checked ? 'theme-click-background' : ''"
            style="font-weight: normal;"
            @click="listItemClick(item)"
            @mousedown="mousedownFn(item)"
          >
            <div class="column-tab-item-one">
              <div class="column-tab-item-div">
                <div @click.stop>
                  <el-checkbox
                    v-model="item.checked"
                    @change="cheakChange(item)"
                  />
                </div>
                <div class="img-item">
                  <!-- 定时邮件 -->
                  <svg-icon
                    v-if="item.is_timing == '1'"
                    class="icos-size-14"
                    icon-class="delaysend_date"
                  />
                  <!-- 转发、回复、转发并回复、未读，未读外还有其它状态的，只显示加粗 -->
                  <svg-icon
                    v-else
                    class="icos-size-14"
                    :icon-class="item | forwardReply"
                  />
                </div>
                <div
                  class="img-item"
                  style="padding-top:4px"
                >
                  <!-- 可疑标识/陌生标识,可疑优先 -->
                  <svg-icon
                    class="icos-size-14"
                    :icon-class="item | suspiciousFilters"
                  />
                </div>
                <!-- 未读、置顶加粗text-color-bold -->
                <!-- 发件人/收件人 -->
                <div class="column-tab-item-mail-time">
                  <div
                    class="one-line-ellipsis"
                    :class="Number(item.topping) > 0 || item.flags.charAt(1) === '0' ? 'text-color-bold' : ''"
                  >
                    <!-- 发件箱、草稿箱显示收件人，其它文件夹显示发件人  -->
                    <span v-if="isToFilter(item.uidvalidity)">
                      <span
                        v-for="(t, ti) in item.to_s"
                        :key="ti"
                      >
                        <mailContactCardPopover
                          :contact-name="t[1]"
                          :contact-mail="t[0]"
                          :contact-group="t[2]"
                          :text-content="user_mail.indexOf(t[0]) != -1 ? $t('message.colmail.me') : t[1]"
                        />
                        <span v-if="item.to_s.length > 1">；</span>
                      </span>
                    </span>
                    <span v-else>
                      <mailContactCardPopover
                        :contact-name="item.from_name"
                        :contact-mail="item.from_address"
                        :contact-group="item.from_name_l[2]"
                        :text-content="user_mail.indexOf(item.from_address) != -1 ? $t('message.colmail.me') : item.from_name"
                      />
                    </span>
                  </div>
                  <div
                    v-if="$store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
                    @click.stop
                  >
                    <span
                      v-for="(i, x) in item.tag"
                      :key="x"
                      class="banner-mail-label-item"
                    >
                      <el-tooltip
                        class="item"
                        effect="light"
                        :content="i.tag_name"
                        placement="top-start"
                      >
                        <el-dropdown
                          ref="tabListBox"
                          trigger="click"
                          class="tabBox"
                          @visible-change="handVisibleChange($event, index)"
                        >
                          <span class="el-dropdown-link">
                            <svg-icon
                              ref="tab"
                              class="tab"
                              style="width: 12px;height: 12px;margin-right: 3px;border-radius: 2px;"
                              :icon-class="i.tag_color.slice(1, 7)"
                            />
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <!-- 标签列表 -->
                            <lableList
                              ref="lableList"
                              :tab-list="item.tag"
                              :mail-info="item"
                              @showTabList="tab_changeList(arguments)"
                            />
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-tooltip>
                    </span>
                  </div>
                  <div
                    style="white-space: nowrap;"
                    class="color-bbb"
                  >{{ item.envelope_date | dateFilter }}</div>
                </div>
              </div>
            </div>
            <div class="column-tab-item-two">
              <div class="column-tab-item-div">
                <div class="img-item">
                  <!-- 阅后即焚 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    :content="$t('message.colmail.read_destory')"
                    placement="top-start"
                  >
                    <svg-icon
                      v-if="item.readed_destory.toString() == '1'"
                      class="icos-size-14"
                      icon-class="mail_burn"
                    />
                  </el-tooltip>
                  <!-- 过期 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    placement="top-start"
                  >
                    <div slot="content">
                      <span>{{ $t('message.colmail.mail_expired',{date:dateNYRFilter(item.expire_time)}) }}</span>
                    </div>
                    <svg-icon
                      v-if="item.readed_destory.toString() != '1' && Number(item.expire_time) > 0"
                      class="icos-size-14"
                      icon-class="mall_over_time"
                    />
                  </el-tooltip>

                  <!-- 安全邮件 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    :content=" $t('message.colmail.secrity_mail')"
                    placement="top-start"
                  >
                    <svg-icon
                      v-if="item.readed_destory != '1' && Number(item.expire_time) === 0 && item.protect_type === 1"
                      class="icos-size-14"
                      icon-class="secure"
                    />
                  </el-tooltip>

                  <!-- 密码邮件 -->
                  <el-tooltip
                    class="item cur"
                    effect="light"
                    :content=" $t('message.colmail.psw_mail')"
                    placement="top-start"
                  >
                    <svg-icon
                      v-if="item.readed_destory != '1' && Number(item.expire_time) === 0 && item.protect_type == 2"
                      class="icos-size-14"
                      icon-class="encipher"
                    />
                  </el-tooltip>
                </div>
                <div class="img-item">
                  <!-- 紧急 -->
                  <svg-icon
                    v-if="item.priority === '1'"
                    class="icos-size-14"
                    icon-class="mall_urgent"
                  />
                </div>
                <div class="column-tab-item-mail-two flax-align-items-center">
                  <!-- 未读加粗text-color-bold -->
                  <!-- 主题 -->
                  <div
                    class="item-mail-two one-line-ellipsis"
                    :class="Number(item.topping) > 0 || item.flags.charAt(1) === '0' ? 'text-color-bold' : ''"
                    style="cursor: pointer;"
                  >
                    <!-- 邮箱名称 -->
                    <span v-if="isMboxShow">[{{ item.uidvalidity | mboxNameFilter }}]</span>
                    <!-- 主题 -->
                    {{ item | subjectFilter }}
                  </div>
                  <div
                    class="img-item"
                    style="cursor: pointer;"
                    @click.stop="startSet(item)"
                  >
                    <!-- 星标(选中) -->
                    <svg-icon
                      v-if="item.flags.charAt(3) === '1' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
                      class="icos-size-14"
                      icon-class="start"
                    />
                    <!-- 星标(未选中) -->
                    <svg-icon
                      v-if="item.flags.charAt(3) === '0' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
                      class="icos-size-14"
                      icon-class="unstart"
                    />
                  </div>
                  <!-- 会话数量 -->
                  <!-- <div
                      v-if="item.csum"
                      class="conversation-num theme-background-color"
                    >
                      {{ item.csum }}
                    </div> -->
                </div>
                <div class="img-item">
                  <!-- 置顶-->
                  <svg-icon
                    v-if="Number(item.topping) > 0"
                    class="icos-size-14"
                    icon-class="mall_topping"
                  />
                </div>
              </div>
            </div>
            <div class="column-tab-item-three">
              <div class="column-tab-item-div">
                <!-- 摘要 -->
                <div
                  class="column-tab-item-mail one-line-ellipsis text-color-a3"
                  style="cursor: pointer;"
                >
                  <span v-show="$store.state.app.userGetConfig.show_mail_diges === 'yes'">
                    <span v-if="item.mail_psw">{{ $t('message.colmail.add_psw_mail') }}</span>
                    <span v-else>
                      <span
                        v-if="item.digest.charAt(0) == 'h'"
                        style="vertical-align: 0.3em;"
                        v-html="item.digest.slice(1)"
                      />
                      <span
                        v-else
                        style="vertical-align: 0.3em;"
                      >{{ item.digest.slice(1) }}</span>
                    </span>
                  </span>
                </div>
                <div
                  class="img-item remark "
                  :class="item.comment !== '' ? 'okRemark' : ''"
                >
                  <!-- 备注 -->
                  <el-tooltip
                    v-if="item.comment !== '' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
                    class="item"
                    effect="light"
                    placement="right-start"
                  >
                    <div
                      slot="content"
                      style="max-width:500px"
                    >{{ item.comment }}</div>
                    <span><svg-icon
                      class="icos-size-14 svgIcon svg-color"
                      icon-class="mail_bz"
                      @click.stop="remarkDia(item)"
                    /></span>
                  </el-tooltip>
                  <svg-icon
                    v-if="item.comment === '' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
                    class="icos-size-14 svgIcon svg-color"
                    icon-class="mail_bz"
                    @click.stop="remarkDia(item)"
                  />
                </div>
                <div class="img-item">
                  <!-- 附件 -->
                  <svg-icon
                    v-if="item.flags.charAt(7) === '1'"
                    class="icos-size-14"
                    icon-class="att"
                  />
                </div>
                <div class="column-tab-item-time color-bbb">{{ item.mail_size | mailSizefilter }}</div>
              </div>
            </div>
            <div
              v-if="item.is_timing == '1'"
              style="padding: 6px 6px 6px 56px;"
            >
              <span
                class="undertint-theme-background-color text-color"
                style="padding-left: 5px;padding-right: 5px;border-radius: 2px;"
              >{{ $t('message.colmail.will_send',{date:dateNYRFilter(item.delaysend_date)}) }}</span>
            </div>
          </div>
        </div>
        <!-- 回到顶部 -->
        <svg-icon
          v-show="isScrollTopShow"
          icon-class="scroll_top"
          class="scrollTop-position-class"
          style="bottom: 60px;"
          @click.stop="$refs.three_mail.scrollTop = 0"
        />
      </div>
    </div>
    <!-- 操作区域、分栏读信区域 -->
    <div
      class="three-columns-mail-body"
      :style="!isReadMailShow?'overflow: hidden;':'overflow: auto;'"
    >
      <ViewMail
        v-if="isReadMailShow"
        ref="isColumnsViewMail"
        :read-mail-req-data="readMailReqData"
        :read-mail-uid-mbox="readMailReqData.uid + readMailReqData.mbox"
        :from-type="parentObj.data.type"
        @changeMailData="changeMailData($event, parentObj.id)"
      />
      <div
        v-if="!isReadMailShow"
        class="empty-page-div"
      >
        <div
          v-if="mail_check_num < 1"
          class="empty-page"
        >
          <img src="@/assets/icons/mail/null_mail.png">
          <div
            class="click-mail-num"
            style="color: #b0b0b0;"
          >{{ $t('message.colmail.no_mail') }}</div>
        </div>
        <div
          v-else
          class="empty-page"
        >
          <img src="@/assets/icons/mail/have_mail.png">
          <div class="click-mail-num">{{ menuItem.mbox_name }} {{ $t('message.colmail.select_mails',{num:mail_check_num}) }}</div>
          <div class="click-mail-opea">
            <div
              v-if="isMailRelay && allSelect !== 'all' && $store.state.app.auth_authority !== 0"
              class="Opea-svg-select-noBorder"
            >
              <svg-icon
                class="icos-size-14"
                icon-class="view_forward"
              />
              <span class="Opea-svg-select-noBorder-span">{{ $t('message.colmail.transfer') }}</span>
              <el-select
                v-model="value"
                popper-class="Opea-svg-select-popper"
                :popper-append-to-body="false"
                style="width:135%;position: absolute;left:0;top:-2px;"
                @click.native="value = ''"
              >
                <el-option
                  :disabled="mail_check_num > 1"
                  :label="$t('message.colmail.transfer')"
                  value="asattforward"
                  @click.native="forwardAction"
                />
                <el-option
                  :label="$t('message.colmail.astt_transfer')"
                  value="asattsforward"
                  @click.native="forwardAsAttachment"
                />
              </el-select>
            </div>
            <div
              v-if="isBadge && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
              class="Opea-svg-select-noBorder"
            >
              <svg-icon
                class="icos-size-14"
                icon-class="view_makk"
              />
              <span class="Opea-svg-select-noBorder-span">{{ $t('message.colmail.mark') }}</span>
              <el-select
                v-model="flags"
                popper-class="Opea-svg-select-popper-mark"
                :popper-append-to-body="true"
                style="width:135%;position: absolute;left:0;top:-2px;"
                @click.native="flags = ''"
              >
                <el-option
                  v-for="item in marker_options"
                  :key="item.value"
                  :label="$t(`message.maillist.${item.label}`)"
                  :value="item.value"
                  @click.native="markerClick"
                />
                <el-option
                  :label="$t('message.maillist.untag')"
                  value="not_tag"
                  @click.native="notTagAllClick"
                />
              </el-select>
            </div>
            <div
              v-if="isMove && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
              class="Opea-svg-select-noBorder"
            >
              <svg-icon
                class="icos-size-14"
                icon-class="view_move"
              />
              <span class="Opea-svg-select-noBorder-span">{{ $t('message.colmail.move') }}</span>
              <!-- 移动 -->
              <el-select
                v-model="to_move_mailbox"
                popper-class="Opea-svg-select-popper-move"
                :popper-append-to-body="false"
                style="width:135%;position: absolute;left:0;top:-2px;"
                class="product-style"
                @click.native="to_move_mailbox = ''"
              >
                <el-option
                  v-for="item in move_mailbox"
                  :key="item.mbox"
                  :label="!item.mbox_parent_name ? item.mbox_name : item.mbox_real_name"
                  :value="item.mbox"
                  :title="!item.mbox_parent_name ? item.mbox_name : item.mbox_real_name"
                  @click.native="to_move_mailboxChange"
                >
                  <span>{{ !item.mbox_parent_name ? item.mbox_name : item.mbox_real_name }}</span>
                </el-option>
              </el-select>
            </div>
            <div
              v-if="$store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
              class="Opea-svg-select-noBorder"
            >
              <svg-icon
                class="icos-size-14"
                icon-class="view_del"
              />
              <span class="Opea-svg-select-noBorder-span">{{ $t('message.colmail.del') }}</span>
              <el-select
                v-model="delOpt"
                popper-class="Opea-svg-select-popper"
                :popper-append-to-body="false"
                style="width:135%;position: absolute;left:0;top:-2px;"
                @click.native="delOpt = ''"
              >
                <el-option
                  v-for="item in delete_options"
                  :key="item.value"
                  :label="$t(`message.maillist.${item.label}`)"
                  :value="item.value"
                  @click.native="deleteClick"
                />
              </el-select>
            </div>
            <div
              v-if="isMore && allSelect !== 'all' && $store.state.app.auth_authority !== 0 && $store.state.app.auth_authority !== 1"
              class="Opea-svg-select-noBorder"
            >
              <svg-icon
                class="icos-size-14"
                icon-class="view_more"
              />
              <span class="Opea-svg-select-noBorder-span">{{ $t('message.colmail.more') }}</span>
              <el-select
                v-model="value"
                popper-class="Opea-svg-select-popper"
                :popper-append-to-body="false"
                style="width:135%;position: absolute;left:0;top:-2px;"
                @click.native="value = ''"
              >
                <el-option
                  :label="$t('message.colmail.down_mail')"
                  value="下载邮件"
                  @click.native="downMail"
                />
                <el-option
                  v-if="isUploadMail"
                  label="$t('message.colmail.upload_mail')"
                  value="上传邮件"
                >
                  <SplitUpload
                    ref="uploadfils"
                    :on-start="handleStart"
                    :on-change="fileChange"
                    :on-success="fileUploadSuccess"
                    :before-upload="fileTypeFun"
                    auto-upload
                  >
                    <span
                      slot="trigger"
                      class="cur"
                    >
                      {{ $t('message.colmail.upload_mail') }}</span>
                  </SplitUpload>
                </el-option>
              </el-select>
            </div>
          </div>
          <div style="position: relative;left:-80px;top:-10px;">
            <el-dropdown
              ref="tabListTag"
              trigger="click"
              class="tabBox"
            >
              <span class="el-dropdown-link" />
              <el-dropdown-menu slot="dropdown">
                <!-- 标签列表 -->
                <lableList
                  v-show="lableListShow"
                  ref="lableList"
                  :tab-list="[]"
                  :mail-info="{}"
                  @showTabList="tabListAction(arguments)"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <downAttaForm
          ref="downMailForm"
          :is-show="isAttaDown"
        />
      </div>
    </div>
    <!-- 邮件备注弹窗 -->
    <remarkDialog
      v-if="showMailbzDialog"
      :is-show="showMailbzDialog"
      :bz-info="bzInfo"
      @showRemark="changeRemark(arguments)"
    />
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="498px"
      append-to-body
      :show-close="false"
      class="cutomDialog"
    >
      <NewContact
        v-if="dialogVisible"
        :on-closed="addUserClosed"
        :on-submit="addUserSubmit"
        :form="ruleForm"
        :cid="contact_id"
        :is-edit="idEdit"
        :title="addtitle"
        is-card
      />
    </el-dialog>
    <div
      v-if="$myState.isMousedownBox"
      :style="{ top: showPositionTop + 'px', left: showPositionLeft + 'px' }"
      class="dragable"
    >{{ $t('message.colmail.selectmails',{num:checkedMail.length>0?checkedMail.length-1:1}) }}</div>
  </div>
</template>
<script>
import getList from './components/getList.js'
import actionList from './components/action-list '
import mailListEmitOuter from './components/mailListEmitOuter.js'
import componentsAction from './components/componentsAction.js'
import ViewMail from '../viewMail/index.vue'
export default {
  components: {
    ViewMail,
  },
  mixins: [getList, actionList, mailListEmitOuter, componentsAction],
  data() {
    return {
      isAchiveBottom: false, // 滚动条是否到底部标志
      dialogVisible: false,
      checkedMail: [],
      count: 0,
      showPositionTop: -1000,
      showPositionLeft: '',
    }
  },
  mounted() {
  },
  created() {
  },
  beforeDestroy() {
  },
  methods: {
    changeMailData(data, id) {
    },
  },
}
</script>
<style>
.cutomDialog .el-dialog__body {
  padding: 0px 20px;
}
</style>
<style lang="scss" scoped>
@import '@/styles/deep_private_eleui.scss';
@import './components/style.scss';

.three-columns-mail-list {
  width: 32%;
  // min-width: 300px;
  // width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-right: 1px solid #dcdcdc;
}

.column-tab-item-click {
  background: #FFF8F9;
}

.column-tab-item {
  padding: 6px 10px 6px 16px;
  border-bottom: 1px solid #dcdfe6;
  cursor: pointer;
  min-width: 200px;

  .column-tab-item-one {
    padding: 1px 0;
  }

  .column-tab-item-two {
    padding: 4px 0;

    .column-tab-item-div {
      padding-left: 14px;
    }

    .column-tab-item-mail-two {
      width: calc(100% - 75px);

      .item-mail-two {
        max-width: calc(100% - 50px);
      }
    }
  }

  .column-tab-item-three {
    padding: 1px 0;

    .column-tab-item-mail {
      margin-left: 56px;
      font-weight: 400;
    }
  }
}

.column-tab-item-div {
  display: flex;
  align-items: center;
}

.column-tab-item-mail {
  width: calc(100% - 115px);
}

.column-tab-item-mail-time {
  width: calc(100% - 55px);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.column-tab-item-time {
  width: 80px;
  text-align: right;
  font-weight: 400;
  color: #A3A3A3;
}

.column-tab-item .remark {
  width: 15px;
  height: 21px;
  display: inline-block;
  margin-right: 2px;
}

.svg-color {
  fill: #BCBCBC !important;
}

.column-tab-item .okRemark .svg-color {
  fill: #6994D4 !important;
}

.column-tab-item .remark .svgIcon {
  display: none; // 设置父元素hover时子元素的样式 【实现要点！！！！！】
}

.column-tab-item:hover .remark .svgIcon,
.column-tab-item .okRemark .svgIcon {
  display: block !important; // 设置父元素hover时子元素的样式 【实现要点！！！！！】
}

.dragable {
  position: fixed;
  background: #fff;
  border: 1px solid #333;
  z-index: 1000;
}

.empty-page-div {
  width: 100%;
  height: 100%;
}

.empty-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .click-mail-num {
    margin-top: 30px;
    font-size: 128%;
  }

  .click-mail-opea {
    margin-top: 50px;
    height: 44px;
    line-height: 44px;
    display: flex;
    cursor: pointer;
  }
}
</style>

