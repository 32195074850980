<template>
  <div
    v-if="attachments.length"
    id="view_atta"
    class="view_atta"
  >
    <div class="atta_tit">{{ $t('message.view.att') }}(<span>{{ $t('message.view.have_att',{num:attachments.length}) }}</span>)
      <!-- <span
            v-if="ctx.atts.length>1"
            class="text-color cur"
          >(打包下载)</span> -->
    </div>
    <div class="atta_html">
      <div
        v-for="item in attachments"
        :key="item.upid"
        class="attachment-item"
      >
        <div style="display: flex">
          <div class="attachment-icon">
            <svg-icon
              :icon-class="getFileType(item.name)+'icon'"
              class-name="atticon"
            />
          </div>
          <div class="attachment-info">
            <div style="display:flex">
              <span class="title">{{ item.name }}</span>
              <span
                class="size"
                style="color: #8E8D8D;"
              >
                <span>({{ item.size|mailSizefilter }})</span>
              </span>
            </div>
            <div style="display:flex">
              <span
                class="delete text-color el-button--text"
                @click="downloadatt(item)"
              >{{ $t('message.compose.download') }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <downAttaForm
      ref="downMailForm"
      :is-show="isAttaDown"
    />
  </div>
</template>

<script>
import downAttaForm from '@/views/components/downAtta.vue'
import { GetFileType, transformClass } from '@/utils/utils.js'
import netdisk from '@/views/compose/components/netdisk'
export default {
  filters: {
    // 邮件大小（接口返回字节）
    mailSizefilter(size) {
      return transformClass.mailSize(size)
    },
  },
  components: { downAttaForm },
  mixins: [netdisk],
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAttaDown: false,
    }
  },
  methods: {
    getFileType(name) {
      const type = GetFileType.fileType(name)
      return type
    },
    isPreView(name) {
      const type = GetFileType.isPreView(name)
      return type
    },
    downloadatt(item) {
      this.$emit('download', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.atta_tit{
    padding: 5px 0;
}
.attachment-item{
        min-width: 290px;
        background: #F9F9F9;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #D7D7D7;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        display: inline-block;
        .attachment-icon{
          position: relative;
          margin-right: 8px;
          .atticon{
            width: 2.6em;
            height: 2.6em;
            margin: 5px 8px;
          }
        }
        .tag{
          background-color: #FF0000;
          display: inline-block;
          color: #fff;
          padding: 1px 4px;
          // font-size: 12px;
          border-radius: 11px;
          line-height: 17px;
          position: absolute;
          top: -11px;
          left: 9px;
        }
        .attachment-info{
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding-right: 3px;
          .title{
            margin-right: 5px;
            display: inline-block;
            max-width: 145px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: bottom;
          }
          .size{
            color: #A3A3A3;
          }
          .preview{
            margin-right: 17px;
             cursor: pointer;
          }
          .delete{
            cursor: pointer;
            margin-right: 2px;
          }
        }
      }
</style>
