<template>
  <div class="v-box">
    <div style="position:relative;top:0;">
      <img
        v-show="bigImg"
        ref="bgImg"
        :src="bigImg"
        class="bigImg"
      >
      <img
        v-show="smallImg"
        ref="sliderImg"
        :src="smallImg"
        class="smallImg"
        :style="{ top: imgCode.positionY+'px' }"
      >
      <i
        class="el-icon-refresh-right slide-verify-refresh-icon"
        @click="refresh"
      />
    </div>
    <div
      v-show="bigImg"
      class="slider-box"
    >
      <span class="slider-text">{{ $t('message.btnText.swipecode') }}</span>
      <span
        ref="btnImg"
        class="slider-icon"
        @mousedown="(e)=>moveBtn(e,1)"
      >>></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Puzzle',
  data() {
    return {
      imgCode: {
        dialogVisible: true,
        positionY: '',
      },
      loading: '',
      smallImg: '',
      bigImg: '',
      token: '',
    }
  },
  watch: {
    bigImg: {
      handler(n, o) {
        this.initPosition()
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  mounted() {
    // this.loading = this.$loading({
    //   lock: true,
    //   text: 'Loading',
    //   spinner: 'el-icon-loading',
    //   background: 'rgba(0, 0, 0, 0.6)',
    //   target: document.querySelector('.v-box'),
    // })
  },
  created() {
    // this.loading.close()

  },
  beforeDestroy() {

  },
  methods: {
    img() {
      this.$axios.post(this.$apiData.codeImg, {}).then(res => {
        this.bigImg = res.bg
        this.smallImg = res.slide
        this.token = res.token
      })
    },
    refresh: function() {
      this.img()
    },
    initPosition: function() {
      this.$refs.btnImg.style.transition = '.4s ease' // 初始化滑块位置
      this.$refs.btnImg.style.transform = 'translateX(0)'

      this.$refs.sliderImg.style.left = '1%'
      this.$refs.sliderImg.style.top = this.imgCode.positionY
      this.$refs.sliderImg.style.transition = '0.4s ease'

      // this.loading.close()
    },
    closeDialog: function() {
      this.imgCode.dialogVisible = false
    },
    // 滑动滑块
    moveBtn(e, key) {
      const ele = e.target // 获取事件触发元素
      const startX = e.clientX // 鼠标相对于浏览器窗口可视区域的X坐标（窗口坐标），可视区域不包括工具栏和滚动条。
      const eleWidth = ele.offsetWidth // 元素的布局宽度
      const parentWidth = this.$refs.bgImg.offsetWidth // 父元素的布局宽度 ---可用大图片的宽度
      const MaxX = parentWidth - eleWidth // 可滑动的最大距离
      this.$refs.sliderImg.style.transition = '' // 初始清空小图片动画
      ele.style.transition = '' // 初始清空滑块动画
      document.onmousemove = (e) => { // 鼠标开始滑动事件
        const endX = e.clientX // 滑动过程中鼠标相对于窗口的坐标
        this.disX = endX - startX // 鼠标的滑动距离

        this.$refs.sliderImg.style.left = this.disX + 'px' // 小图片的滑动距离
        if (this.disX <= 0) { // 滑动距离小于0，重置位置
          this.disX = 0
          this.$refs.sliderImg.style.left = 0
        }
        if (this.disX >= MaxX) { // 减去滑块的宽度,体验效果更好---》最大滑动距离减去滑块宽度便是真正的滑动距离
          this.disX = MaxX
          this.$refs.sliderImg.style.left = (parentWidth - this.$refs.sliderImg.width) + 'px'
        }
        ele.style.transform = 'translateX(' + this.disX + 'px)' // 加横向动画
        e.preventDefault() // 取消默认事件
      }
      document.onmouseup = () => {
        // this.loading = this.$loading({
        //   lock: true,
        //   text: '验证码已获取',
        //   spinner: 'el-icon-circle-check',
        //   background: 'rgba(0, 0, 0, 0.6)',
        //   target: document.querySelector('.vb-box'),
        // })

        // ele.style.transition = '.4s ease' // 初始化滑块位置
        // ele.style.transform = 'translateX(0)'
        // 清除滑动事件
        document.onmousemove = null
        document.onmouseup = null
        // if (key === 1) {
        //     // 鼠标松开复原小图片
        //     this.$refs.sliderImg.style.left = '1%'
        //     this.$refs.sliderImg.style.top = this.imgCode.positionY
        //     this.$refs.sliderImg.style.transition = '0.4s ease'
        // } else {
        //     this.$refs.btnImg.style.left = '0'
        //     this.$refs.btnImg.style.transition = '0.4s ease'
        // }
        this.$parent.inputCode = this.disX
        // this.$parent.login()
        const req = [this.disX, this.token]
        this.$emit('finishVerify', req)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$light_gray: #eee;
$btn: #547bd5;
$btnHover: #084ff3;
.v-box {
  width: 100%;
  margin: auto;
  .bigImg {
    width: 100%;
    height: 100%;
    /*border-radius: 5px;*/
  }

  .smallImg {
    width: 50px;
    // height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .slide-verify-refresh-icon {
    position: absolute;
    right: 3px;
    top: 3px;
    font-size: 26px;
    color: #fff;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    /*box-shadow:2px 2px 3px #fff;*/
    /*box-shadow: 0px 0px 3px #fff;*/
  }
}

.el-button--small.is-circle {
  padding: 5px;
}

.dialog-title {
  font-size: 15px;
  color: #2b3f57;
  text-align: left;
  font-weight: 600;
}

.el-dialog__footer {
  padding: 0px 12px 14px 0px !important;
}

.el-dialog__headerbtn {
  top: 15px !important;
}

.el-dialog__header {
  padding-bottom: 5px;
}

.slider-box {
  background: #f7f9fa;
  color: $light_gray;
  border: 1px solid #e1e1e1;
  position: relative;
  height: 40px;
  width: 100%;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 2px $btnHover;
  }

  .slider-text {
    white-space: nowrap;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999;
  }

  .slider-icon {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 54px;
    height: 38px;
    line-height: 38px;
    background: #ccc;
    text-align: center;
    font-size: 17px;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: 1px solid #ccc;
    float: left;
    border-radius: 5px;
    &:hover {
      background: $btn;
      border: 1px solid $btn;
    }
  }
}
</style>
