import { render, staticRenderFns } from "./firstPassword.vue?vue&type=template&id=bd46b9a6&scoped=true"
import script from "./firstPassword.vue?vue&type=script&lang=js"
export * from "./firstPassword.vue?vue&type=script&lang=js"
import style0 from "./firstPassword.vue?vue&type=style&index=0&id=bd46b9a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd46b9a6",
  null
  
)

export default component.exports