import { transformClass } from '@/utils/utils'
let stateInfo = {}
const state = {
  search_nd_type_isLoad: false, // 是否刷新类型搜索
  isNetworkUpload: false, // 是否触发上传
  openFolderId: '', // 打开的文件夹id
  upload_type: '', // 上传模块，中转站、网盘
  isUpload_success: false, // 上传成功
  isUpload_fileWebDisk_success: false, // 中转站上传成功
  quota: '', // 已用网盘容量
  quota_total: '', // 网盘总量
  allFolderObj: [], // 网盘全部路径
}
const mutations = {
  IS_NETWORK_UPLOAD_SETTING: (state, data) => {
    state.isNetworkUpload = data
  },
  /** 当前打开的文件夹,上传时使用,勿删 */
  OPEN_FOLDER_ID_SETTING: (state, data) => {
    state.openFolderId = data
  },
  UPLOAD_TYPE_SETTING: (state, data) => {
    state.upload_type = data
  },
  IS_UPLOAD_SUCCESS_SETTING: (state, data) => {
    state.isUpload_success = data
  },
  isUpload_fileWebDisk_SET: (state, data) => {
    state.isUpload_fileWebDisk_success = data
  },
  /** 更新进度条数据值，上传前校验大小 */
  GET_QUOTA_SETTING: (state, data) => {
    state.quota = data.quota
    state.quota_total = data.quota_total
  },
  /** 记录上传后文件的路径,上传弹窗tooltip使用,勿删 */
  Set_allFolderObj: (state, data) => {
    state.allFolderObj = data
  },
  stateDataClear: (state, data) => {
    stateInfo = transformClass.deepClone(state)
  },
  stateDataSet: (state, data) => {
    for (const key in stateInfo) {
      state[key] = stateInfo[key]
    }
  },
}
const actions = {

}

export default {
  namespaced: true,
  // namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
  // 例： SET_NAME => user/SET_NAME
  state,
  mutations,
  actions,
}
