<template>
  <div
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea
      :id="tinymceId"
      v-model="content"
      class="tinymce-textarea"
    />
  </div>
</template>

<script>
let tinymceCDN = ''
const nsmail = process.env.NODE_ENV === 'production' ? '/nsmail' : ''
tinymceCDN = window.location.origin + nsmail + '/tinymce/tinymce.min.js'
import { toolbar, tinymceplugins } from '../../utils/Tinymceplugins'
import load from '../../utils/dynamicLoadScript'
export default {
  name: 'Tinymce',
  props: {
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: String,
      default: '',
    },
    plugins: {
      type: String,
      default: '',
    },
    isWrite: {
      type: Boolean,
      default: false,
    },
    onInit: {
      type: Function,
      default: () => {},
    },
    dataChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      fullscreen: false,
      content: '',
      fontstyle: {
        write_font: '',
        write_font_size: '',
      },
      pageSize: '14px',
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    },
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() => {
          window.tinymce.get(this.tinymceId).setContent(val || '')
          this.dataChange()
        },
        )
      }
    },
  },
  created() {
    const { write_font, write_font_size, write_font_color } = this.$store.state.app.userGetConfig
    this.fontstyle.write_font = write_font
    this.fontstyle.write_font_size = write_font_size
    this.fontstyle.write_font_color = write_font_color
    this.pageSize = this.$store.state.app.userGetConfig.font_size
    // this.init()
  },
  mounted() {
    this.init()
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce()
    }
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    init() {
      load(tinymceCDN, (err) => {
        if (err) {
          console.error(err.message)
          return
        }
        window.tinymce.baseURL = window.location.origin + nsmail + '/tinymce'
        this.initTinymce()
      })
    },
    initTinymce() {
      const _this = this
      window.tinymce.init({
        content_style: this.isWrite ? '' : `body {font-family: ${this.fontstyle.write_font};font-size:${this.fontstyle.write_font_size}px;color:${this.fontstyle.write_font_color};margin:0;}img{max-width:100%}`,
        language: localStorage.getItem('lang') === 'english' ? 'en_US' : 'zh_CN',
        selector: `#${this.tinymceId}`,
        // skin: this.isWrite ? 'oxide' : 'CUSTOM',
        skin: this.isWrite ? 'CUSTOM' : 'CUSTOM',
        height: this.isWrite ? 240 : '',
        autoresize_bottom_margin: 100,
        autoresize_min_height: 600,
        autoresize_on_init: true,
        relative_urls: false,
        convert_urls: false,
        branding: false,
        // autoresize_bottom_margin: 50,
        resize: false,
        // paste_data_images: true,
        // paste_enable_default_filters: false,
        body_class: 'panel-body ',
        elementpath: false,
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        toolbar_items_size: 'small',
        menubar: '',
        plugins: this.plugins.length > 0 ? this.plugins : tinymceplugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'merge',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        contextmenu: 'link bold  copy',
        toolbar_mode: 'sliding',
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px',
        font_formats: '宋体=simsun,serif;黑体=SimHei,sans-serif;楷体=楷体;隶书=隶书;微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;仿宋体=FangSong,serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Comic Sans MS=Comic Sans MS;Courier=Courier;System=System;Times New Roman=Times New Roman;Verdana=Verdana;Georgia=Georgia;Calibri=Calibri;',
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        init_instance_callback: editor => {
          editor.setContent(_this.value)

          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          editor.on('init', (e) => {
            _this.onInit()
          })
        },
      })
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    getTextContent() {
      return window.tinymce.get(this.tinymceId).getContent({ 'format': 'text' })
    },
    getDocument() {
      return window.tinymce.get(this.tinymceId).contentDocument
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)

      if (tinymce) {
        tinymce.destroy()
      }
    },
  },
}
</script>
<style>
/* .tinymce-container {
  position: relative;
  line-height: normal;
  height: 100%;
} */

::v-deep .mce-fullscreen {
  z-index: 10000;
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
