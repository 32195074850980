//
// 这个模块只能由 main.js 使用
//
const myApi = {}

// 更新账号属性
myApi.updateUserConfig = (config) => {
  const where = window._kglobal_myState.userConfig
  for (const key in config) {
    if (key === 'font_size' || key === 'page_size') where[key] = Number(config[key])
    else where[key] = config[key]
  }
  window._kglobal_myState.isColumns = (where.mail_view_type === 'subfield')
}

// 跨组件移动邮件, mouseup后 执行的函数, 由功能发起者临时覆盖
myApi.mailMove_TMP_1102 = (mbox /* maybe string */) => { }

// 顶级模块切换，goto，module包括:
// pre-path, login, clientLogin, forgetPassword, lock-screen, netdisk_share, main, contact, calendar, netdisk, setting
myApi.topGoto = (module, data /* any */, options /* undefined or { isHistory?:boolean } */) => { }

// APP.vue 加载需要展示的第一个页面
myApi.appFirstGogo = () => {}

// 下面是模块激活函数, 由 topGoto 调用
myApi.activeModuleHOME = () => { }
myApi.activeModuleHome = () => { }
myApi.activeModuleMail = () => { }
myApi.activeModuleMailMenu = () => { }
myApi.activeModuleNetdisk = () => { }
myApi.activeModuleContact = () => { }
myApi.activeModuleCalendar = () => { }
myApi.activeModuleSetting = () => { }

// 清理账号数据,用于退出和切换授权邮箱
myApi.clearUserData = () => { }
// 下面是各个模块清理用户数据函数, 由 clearUserData 调用
myApi.clearUserDataModuleHOME = () => { }
myApi.clearUserDataModuleHome = () => { }
myApi.clearUserDataModuleMail = () => { }
myApi.clearUserDataModuleMailMenu = () => { }
myApi.clearUserDataModuleNetdisk = () => { }
myApi.clearUserDataModuleContact = () => { }
myApi.clearUserDataModuleCalendar = () => { }
myApi.clearUserDataModuleSetting = () => { }

//
myApi.mainMenu = {}

// 左侧菜单, 刷新文件夹列表
myApi.mainMenu.refreshMboxList = async(attrs /* { force?: boolean } */) => { }

// 左侧菜单, 刷新邮件标签列表
myApi.mainMenu.refreshTagList = async() => { }

// 左侧菜单, 刷新审核数
myApi.mainMenu.refreshAuditCount = async() => { }

// 左侧菜单, 高亮条目
myApi.mainMenu.highlightItem = (type, id) => { }

//
myApi.mainTab = {}

// 页签, 试着关闭全部
myApi.mainTab.closeALL = async() => { }

// 页签, 弹出
myApi.mainTab.popupItem = (itemId) => { }

// 页签, 设置 title
myApi.mainTab.setItemSubject = (itemId, subject) => { }

// 页签, 返回邮件列表
myApi.mainTab.goBackMain = () => { }

// 页签, 打开新页签, 看信件
myApi.mainTab.showMail = (readMailReqData) => { }

// 页签, 打开新页签, 看信件, 没左侧菜单
myApi.mainTab.showWholeMail = (readMailReqData) => { }

// 邮件各种列表
myApi.mainTab.showMain = (menuItem) => { }

// 查看新的信件
myApi.mainTab.showMailNew = (menuItem) => { }

// 页签, 打开新页签, 邮件追踪
myApi.mainTab.showTracking = (reqData) => { }

// 页签, 打开新页签, 审核通知
myApi.mainTab.showAudit = (reqData) => { }

// 页签, 打开新页签, 附件管理
myApi.mainTab.showAttachment = (reqData) => { }

// 页签, 打开新页签, 文件中转站
myApi.mainTab.showFileWebDisk = (reqData) => { }

// showComposeMail
myApi.mainTab.showComposeMail = (options /* {mbox: 'Drafts', uid: -1, type: "", subject: "", composeData: ""} */ /* undefined for new */) => { }

// 邮件标记,全局通知
myApi.updateMailStatus = (mailStatusList /* { uidvalidity, uid, {seenFlag?:boolean, starFlag?:boolean, tags?:unknown, topping?:boolean } }[] */) => { }

// 设置 打开设置
myApi.settingGoto = (moduleID /* undefined for lastSelected */) => {
}

// 网盘，打开网盘及传值,附件管理跳转到网盘：data格式 {parent_nd_id: ''}, header搜索跳转到网盘：data格式 {search: ''}
myApi.netdiskGoto = (data) => { }
myApi.netdiskSearch = (data) => { }

// 联系人搜索
myApi.contactSearch = (data) => { }

// 生成 url 的 fragment 部分
myApi.buildRoutePathFragment = (module, data) => { }
// 添加路由 data 必须是一个对象{} module 是模块路由, route.js
myApi.routeSet = (module, data, options) => { }
// // 根据路由跳转 isHistroy是否从点击历史记录调用, route.js
// myApi.routeGoto = (module, data, options /* { isHistory?:boolean } */) => { }
// 锁屏,true锁，false开
myApi.lockScreenSet = (data) => { }
export default myApi
