<template>
  <div class="firstBox">
    <el-dialog
      :title="title"
      width="600px"
      :visible.sync="$parent.isWeakPassowrd"
      :show-close="false"
      :before-close="handleClose"
    >
      <el-form
        ref="weakForm"
        :model="form"
        :rules="rules"
        style="padding:0 50px"
      >
        <el-form-item
          :label="$t('message.mailmodule.init_psw')"
          :label-width="formLabelWidth"
          prop="oldPassword"
        >
          <div
            class="showPass showPassHeight"
          >
            <!-- <p
              v-if="!eyes1"
            >
              <input
                v-model="pwdCover1"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown"
                @input="setPassword"
              >
              <span @click="showPwd(true,'eyes1')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.oldPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @input="setPwdCover"
              >
              <span @click="showPwd(false,'eyes1')"><svg-icon icon-class="showPassword" /></span>
            </p> -->

            <p>
              <input
                v-model="form.oldPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                :type="inputType1"
              >
              <span
                v-if="!eyes1"
                @click="showPwd(true,'eyes1')"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false,'eyes1')"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('message.mailmodule.newpsw')"
          :label-width="formLabelWidth"
          prop="newPassword"
        >
          <div
            class="showPass showPassHeight"
          >
            <div
              v-show="tips2"
              class="tips"
            >
              <div v-html="error_html" />
            </div>
            <!-- <p
              v-if="!eyes2"
            >
              <input
                v-model="pwdCover2"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown_new"
                @input="setPassword_new"
                @focus="tips2 = true"
                @blur="tips2 = false"
              >
              <span @click="showPwd(true,'eyes2')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.newPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @input="setPwdCover_new"
                @focus="tips2 = true"
                @blur="tips2 = false"
              >
              <span @click="showPwd(false,'eyes2')"><svg-icon icon-class="showPassword" /></span>
            </p> -->

            <p>
              <input
                v-model="form.newPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                :type="inputType2"
                @focus="tips2 = true"
                @blur="tips2 = false"
              >
              <span
                v-if="!eyes2"
                @click="showPwd(true,'eyes2')"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false,'eyes2')"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('message.mailmodule.confirm_psw')"
          :label-width="formLabelWidth"
          prop="okNewPassword"
        >
          <div
            class="showPass showPassHeight"
          >
            <div
              v-show="tips3"
              class="tips"
            >
              <div v-html="error_html" />
            </div>
            <!-- <p
              v-if="!eyes3"
            >
              <input
                v-model="pwdCover3"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @keydown="handlerKeydown_ok"
                @input="setPassword_ok"
                @focus="tips3 = true"
                @blur="tips3 = false"
              >
              <span @click="showPwd(true,'eyes3')"><svg-icon icon-class="hidePassword" /></span>
            </p>
            <p
              v-else
            >
              <input
                v-model="form.okNewPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                @input="setPwdCover_ok"
                @focus="tips3 = true"
                @blur="tips3 = false"
              >
              <span @click="showPwd(false,'eyes3')"><svg-icon icon-class="showPassword" /></span>
            </p> -->

            <p>
              <input
                v-model="form.okNewPassword"
                class="password noelInput"
                autocomplete="off"
                maxlength="32"
                :type="inputType3"
                @focus="tips3 = true"
                @blur="tips3 = false"
              >
              <span
                v-if="!eyes3"
                @click="showPwd(true,'eyes3')"
              ><svg-icon
                class="login-svg"
                icon-class="hidePassword"
              /></span>
              <span
                v-else
                @click="showPwd(false,'eyes3')"
              ><svg-icon
                class="login-svg"
                icon-class="showPassword"
              /></span>
            </p>
          </div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submitForm('weakForm')"
        >{{ $t('message.btnText.ok') }}</el-button>
        <el-button @click="resetForm('weakForm')">{{ $t('message.mailmodule.reset') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { PasswordSm4 } from '@/utils/changePassword'
// import { PassWord, PwdCover, PasswordSm4 } from '@/utils/changePassword'
import serialize from '@/utils/serialize'
import { transformClass } from '@/utils/utils.js'
export default ({
  data() {
    const checkPwd_ok = (rule, value, callback) => {
      if (value.trim().length === 0) {
        callback(new Error(this.$t('message.login.err1')))
      } else if (value !== this.form.newPassword) {
        callback(new Error(this.$t('message.login.err3')))
      } else {
        callback()
      }
    }
    return {
      title: this.$t('message.mainpage.changepassword'), // 修改弱密码
      // dialogWeakPassword: false,
      eyes1: false, // 是否查看密码
      eyes2: false, // 是否查看密码
      eyes3: false, // 是否查看密码
      // cursorStart1: 0,
      // cursorEnd1: 0,
      // cursorStart2: 0,
      // cursorEnd2: 0,
      // cursorStart3: 0,
      // cursorEnd3: 0,
      // pwdCover1: '',
      // pwdCover2: '',
      // pwdCover3: '',
      inputType1: 'password',
      inputType2: 'password',
      inputType3: 'password',
      tips2: false,
      tips3: false,
      form: {
        oldPassword: '',
        newPassword: '',
        okNewPassword: '',
      },
      formLabelWidth: this.$i18n.locale === 'english' ? '200px' : '100px',
      rules: {
        oldPassword: [
          { required: true, message: this.$t('message.setting.err1'), trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: this.$t('message.setting.err2'), trigger: 'blur' },
        ],
        okNewPassword: [{ validator: checkPwd_ok, required: true, trigger: 'blur' }],

      },
      error_html: '', // 密码提示
    }
  },
  created() {
  },
  mounted() {
    this.get_pass_policy()
  },
  methods: {

    handleClose() {
      return false
    },
    // 获取用户规则
    get_pass_policy() {
      const data = {
        'module': 'mail',
        'require': {
          'action': 'get_pass_policy',
        },
      }
      this.$axios.post(this.$apiData.get_pass_policy, { req: serialize(data) }).then(res => {
        this.error_html = transformClass.password_error_html(res.result.min_pass_lenth, res.result.max_pass_lenth, res.result.pass_filt, res.result.old_pass_same === 1 ? '不能' : '能', res.result.user_pass_same === 1 ? '不能' : '能', localStorage.getItem('lang'))
      })
    },
    // 显示密码
    showPwd(status, eyes) {
      if (eyes === 'eyes1') {
        status ? this.eyes1 = true : this.eyes1 = false
        // this.pwdCover1 = this.pwdCover1.slice(0, this.form.oldPassword.length)
        this.inputType1 = status ? 'text' : 'password'
      }
      if (eyes === 'eyes2') {
        status ? this.eyes2 = true : this.eyes2 = false
        // this.pwdCover2 = this.pwdCover2.slice(0, this.form.newPassword.length)
        this.inputType2 = status ? 'text' : 'password'
      }
      if (eyes === 'eyes3') {
        status ? this.eyes3 = true : this.eyes3 = false
        // this.pwdCover3 = this.pwdCover3.slice(0, this.form.okNewPassword.length)
        this.inputType3 = status ? 'text' : 'password'
      }
    },
    // // 初始密码
    // handlerKeydown(event) {
    //   this.cursorStart1 = event.target.selectionStart
    //   this.cursorEnd1 = event.target.selectionEnd
    // },
    // // 初始密码 用户登录密码框提换值
    // setPassword(event) {
    // // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    // // this.capsTooltip = false
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const b = new PassWord(event, this.pwdCover1, this.form.oldPassword, this.cursorStart1, this.cursorEnd1)
    //     this.pwdCover1 = b.toValue()[0]
    //     this.form.oldPassword = b.toValue()[1]
    //   }
    // },
    // // 初始密码 用户登录密码框提换值
    // setPwdCover(event) {
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover1 = this.pwdCover1.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover1, this.form.oldPassword)
    //     this.pwdCover1 = a.toValue()
    //   }
    // },

    // // 新密码
    // handlerKeydown_new(event) {
    //   this.cursorStart2 = event.target.selectionStart
    //   this.cursorEnd2 = event.target.selectionEnd
    // },
    // // 新密码 用户登录密码框提换值
    // setPassword_new(event) {
    // // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    // // this.capsTooltip = false
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover2 = this.pwdCover2.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const b = new PassWord(event, this.pwdCover2, this.form.newPassword, this.cursorStart2, this.cursorEnd2)
    //     this.pwdCover2 = b.toValue()[0]
    //     this.form.newPassword = b.toValue()[1]
    //   }
    // },
    // // 新密码 用户登录密码框提换值
    // setPwdCover_new(event) {
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover2 = this.pwdCover2.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover2, this.form.newPassword)
    //     this.pwdCover2 = a.toValue()
    //   }
    // },

    // // 确认密码
    // handlerKeydown_ok(event) {
    //   this.cursorStart3 = event.target.selectionStart
    //   this.cursorEnd3 = event.target.selectionEnd
    // },
    // // 确认密码 用户登录密码框提换值
    // setPassword_ok(event) {
    // // const reg = /[0-9a-zA-Z]/g // 只允许输入字母和数字
    // // this.capsTooltip = false
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover3 = this.pwdCover3.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const b = new PassWord(event, this.pwdCover3, this.form.okNewPassword, this.cursorStart3, this.cursorEnd3)
    //     this.pwdCover3 = b.toValue()[0]
    //     this.form.okNewPassword = b.toValue()[1]
    //   }
    // },
    // // 确认密码 用户登录密码框提换值
    // setPwdCover_ok(event) {
    //   if (/.*[\u4e00-\u9fa5]+.*$/.test(event.target.value)) {
    //     // this.err_msg = true
    //     // this.err_msg_txt = '禁止输入中文'
    //     this.pwdCover3 = this.pwdCover3.replace(/[\u4e00-\u9fa5]/g, '')
    //   } else {
    //     const a = new PwdCover(this.pwdCover3, this.form.okNewPassword)
    //     this.pwdCover3 = a.toValue()
    //   }
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const username = this.$store.state.app.realName
          const req = {
            'module': 'user',
            'require': {
              action: 'changePassword',
              password: new PasswordSm4(username, this.form.oldPassword).toValue(),
              newPassword: new PasswordSm4(username, this.form.okNewPassword).toValue(),
            },
          }
          this.$axios.post(this.$apiData.mail, { req: serialize(req) }).then(res => {
            this.$message.success(res.msg)
            const userInfo = {
              user_mail: username,
              user_pwd: new PasswordSm4(username, this.form.newPassword).toValue(),
              user_type: this.$store.state.app.userInfo.user_type, // 登录方式
            }
            this.$store.commit('app/USERINFO', userInfo)
            const settingInfo = this.$store.state.app.settingInfo
            settingInfo.weakPassowrd = 'no'
            this.$store.commit('app/SETTING_INFO', settingInfo)
            // this.dialogWeakPassword = false
            this.$parent.isWeakPassowrd = false
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      // this.pwdCover1 = ''
      // this.pwdCover2 = ''
      // this.pwdCover3 = ''
      this.inputType1 = 'password'
      this.inputType2 = 'password'
      this.inputType3 = 'password'
      this.eyes1 = false // 是否查看密码
      this.eyes2 = false // 是否查看密码
      this.eyes3 = false // 是否查看密码
      this.$refs[formName].resetFields()
    },
  },
})
</script>
<style lang="scss" scoped>
@import '@/styles/password.scss';
/deep/.el-form-item__label{
  word-break: normal;
}
</style>
