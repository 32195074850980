<template>
  <div class="add-contact">
    <div class="header">{{ $t(`message.contact.${title}`) }} </div>
    <div class="form">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="addGroupForm"
        label-position="top"
      >
        <div>
          <el-form-item
            prop="region"
            style="margin-top: 5px;"
          >
            <div slot="label">
              {{ $t('message.contact.selectgroup') }}
            </div>
            <el-select
              v-model="ruleForm.group"
              :placeholder="$t('message.contact.selectgroup')"
              class="width422"
              multiple
              popper-class="selectGroup"
              @visible-change="showselect"
            >
              <li
                slot="empty"

                class="el-select-dropdown__item addnewgroup"
              >
                <div
                  v-if="showCreateGroup"
                  class="addnewgroupitem"
                >
                  <el-input
                    v-model="newgroupname"
                    :placeholder="$t('message.contact.placeholder2')"
                    maxlength="25"
                    show-word-limit
                    class="input"
                    size="mini"
                  />
                  <div style="display:flex;margin-left:13px">
                    <svg-icon
                      icon-class="g_confirm"
                      style="cursor: pointer;margin-right:13px"
                      @click="createGroup"
                    />
                    <svg-icon
                      icon-class="g_cancel"
                      style="cursor: pointer"
                      @click="cancelGroup"
                    />
                  </div>
                </div>
                <span
                  v-else
                  style="flex:1"
                  @click="showCreateGroup=true"
                ><i
                  class="el-icon-plus"
                  style="margin-right:5px"
                />{{ $t('message.conatct.newfenzu') }}</span>
              </li>
              <li
                class="el-select-dropdown__item addnewgroup"
              >
                <div
                  v-if="showCreateGroup"
                  class="addnewgroupitem"
                >
                  <el-input
                    v-model="newgroupname"
                    :placeholder="$t('message.contact.placeholder2')"
                    maxlength="25"
                    show-word-limit
                    class="input"
                    size="mini"
                  />
                  <div style="display:flex;margin-left:13px">
                    <svg-icon
                      icon-class="g_confirm"
                      style="cursor: pointer;margin-right:13px"
                      @click="createGroup"
                    />
                    <svg-icon
                      icon-class="g_cancel"
                      style="cursor: pointer"
                      @click="cancelGroup"
                    />
                  </div>
                </div>
                <span
                  v-else
                  style="flex:1"
                  @click="showCreateGroup=true"
                ><i
                  class="el-icon-plus"
                  style="margin-right:5px"
                />{{ $t('message.contact.newfenzu') }}</span>
              </li>
              <el-option
                v-for="item in $store.state.contact.persongroup"
                :key="item.group_id"
                :label="item.group_name"
                :value="item.group_id"
                :disabled="normalgroup"
              >
                <span class="checkbox" />
                <span
                  class="label-name-box"
                  style="margin-left: 8px;"
                >{{ item.group_name }}</span>
              </el-option>
              <el-option
                :label="$t('message.contact.ungroup')"
                value="ungroup"
                :disabled="ungroup"
              >
                <span class="checkbox" />
                <span
                  class="label-name-box"
                  style="margin-left: 8px;"
                >{{ $t('message.contact.ungroup') }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <div
              slot="label"
              class="label-name"
            >
              {{ $t('message.contact.displayname') }}<span style="color: #d0161b">*</span>
            </div>
            <el-input
              v-model="ruleForm.name"
              :placeholder="$t('message.contact.displayname')"
              show-word-limit
              maxlength="25"
              class="width422"
            />
          </el-form-item>
          <el-form-item
            :label="$t('message.contact.mailaddress')"
            prop="mail"
          >
            <div
              slot="label"
              class="label-name"
            >
              {{ $t('message.contact.mailaddress') }}<span style="color: #d0161b">*</span>
            </div>
            <el-input
              v-model="ruleForm.mail"
              show-word-limit
              :placeholder="$t('message.contact.mailaddress')"
              maxlength="128"
              class="width422"
            />

          </el-form-item>
          <el-form-item
            v-show="expand"
            label="联系方式"
          >
            <div
              slot="label"
              class="label-name"
            >
              {{ $t('message.contact.phone') }}
            </div>
            <el-form-item
              v-for="(phone, index) in ruleForm.phones"
              :key="phone.key"
              style="margin-bottom:20px"
              :class="{addTile:ruleForm.phones.length-1===index}"
              :prop="'phones.' + index + '.value'"
              :rules="[{ pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: $t('message.contact.info11')}]"
            >
              <el-input
                v-model="phone.value"
                :placeholder="$t('message.contact.phone')"
                class="width422"
                maxlength="11"
                @input="handlePhoneInput(index)"
              /><span :class="['icon',isCard?'icon-pos':'']">
                <svg-icon
                  v-show="index!==0"
                  icon-class="cancel"
                  class-name="close"
                  style="width:1.3em"
                  @click="removePhone(phone)"
                />
              </span>
            </el-form-item>
            <span
              v-if="ruleForm.phones.length<5"
              class="text-color"
              @click="addPhone"
            >{{ $t('message.contact.addphone') }}</span>
          </el-form-item>
          <el-form-item
            v-show="expand"
            label="单位"
            prop="company"
          >
            <div
              slot="label"
              class="label-name"
            >
              {{ $t('message.contact.unit') }}
            </div>
            <el-input
              v-model="ruleForm.company"
              class="width422"
              :placeholder="$t('message.contact.unit')"
              show-word-limit
              maxlength="128"
            />
          </el-form-item>
          <el-form-item
            v-show="expand"
            label="备注"
            prop="desc"
          >
            <div
              slot="label"
              class="label-name"
            >
              {{ $t('message.contact.quote') }}
            </div>
            <el-input
              v-model="ruleForm.remarks"
              :placeholder="$t('message.contact.quote')"
              show-word-limit
              maxlength="128"
              class="width422"
            />
          </el-form-item>
          <el-row v-if="isCard">
            <el-col>
              <span
                class="text-color"
                style="cursor: pointer"
                @click="expand=!expand"
              >{{ !expand?$t('message.contact.more'):$t('message.contact.retract') }}<svg-icon
                :icon-class="!expand?'g_expand':'g_hide'"
                style="height:1.1em"
              /></span>
            </el-col>
          </el-row>
        </div>
        <el-form-item
          :class="['btn-bottom',isCard?'cardBottom':'']"
        >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >{{ $t('message.btnText.save') }}</el-button>
          <el-button @click="cancelAdd">{{ $t('message.btnText.cancel') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import requestContact from '@/views/home-view/components/contacts-work/contact-request'
import serialize from '@/utils/serialize'
import ClickOutSide from 'element-ui/src/utils/clickoutside'
export default {
  directives: { ClickOutSide },
  mixins: [requestContact],
  props: {
    title: {
      type: String,
      default: 'newcontact',
    }, // 显示title
    isEdit: {
      type: Boolean,
      default: false,
    }, // 是否编辑联系人
    cid: {
      type: String,
      default: '',
    }, // 联系人id
    onClosed: {
      type: Function,
      default: () => {},
    }, // 点击取消触发的函数, 用于处理点击取消按钮的事件。 如果不指定，则不处理取消

    onSubmit: {
      type: Function,
      default: () => {},
    }, // 点击保存按钮触发的函数, 用于处理保存按钮的事件。
    form: {
      type: Object,
      default: () => {},
    }, // 传入数据
    isCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var checkMail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('message.contact.info13')))
      }
      callback()
    }
    var checkName = (rule, value, callback) => {
      const regex = new RegExp('[%&...￥]')
      if (!value) {
        return callback(new Error(this.$t('message.contact.info12')))
      } else if (regex.test(value)) {
        return callback(new Error(this.$t('message.contact.info15')))
      }
      callback()
    }
    return {
      expand: true,
      ruleForm: {
        phones: [{
          key: Date.now(),
          value: '',
        }],
        group: '',
        name: '',
        mail: '',
        company: '',
        remarks: '',
      },
      rules: {
        mail: [
          { validator: checkMail, trigger: 'blur' },
          { type: 'email', message: this.$t('message.contact.info14'), trigger: ['blur'] },
        ],
        name: [
          { validator: checkName, trigger: 'blur' },
        ],
      },
      newgroupname: '',
      showCreateGroup: false,
      normalgroup: false,
      ungroup: false,
    }
  },
  watch: {
    'ruleForm.group': {
      handler(val) {
        if (val.length === 0) {
          this.normalgroup = false
          this.ungroup = false
        } else if (val.includes('ungroup')) {
          this.normalgroup = true
          this.ungroup = false
        } else {
          this.ungroup = true
          this.normalgroup = false
        }
      },
    },
  },
  created() {
    if (this.form) {
      this.ruleForm = this.form
    }
    if (this.isCard) {
      this.expand = false
    }
    this.getGroups()
  },
  methods: {
    // 新建联系人
    submitForm(formName) {
      if (this.form) {
        if (this.isEdit) {
          if (this.cid === '') {
            console.error('编辑模式下请传入联系人Id')
            return
          }
        }
      }
      const str = []
      this.ruleForm.phones.map(item => {
        if (item.value !== '') {
          str.push(item.value)
        }
      })
      const params = {
        module: 'contact',
        require: {
          data: {
            contact_attrs: {
              cellphone: str.toString(),
              company: this.ruleForm.company,
              mail: this.ruleForm.mail,
              name: this.ruleForm.name,
              notes: this.ruleForm.remarks,
            },
            contact_id: this.isEdit ? this.cid : '',
            contact_mail: this.ruleForm.mail,
            contact_name: this.ruleForm.name,
            group_list: [...this.ruleForm.group],
          },
          action: 'update',
          contact_id: this.isEdit ? this.cid : '',
        },

      }

      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const res = await this.addNewContact(params, 'add')
          if (res.status === 200) {
            if (this.isEdit) {
              this.$message.success(this.$t('message.contact.info16'))
            } else {
              this.$message.success(this.$t('message.contact.info10'))
            }
            if (!this.isCard) {
              this.onSubmit(res.result.contact_id)
            } else {
              this.onSubmit()
            }
          }
        }
      })
    },
    cancelAdd() {
      if (this.isEdit) {
        this.onClosed(1)
      } else {
        this.onClosed()
      }
    },
    removePhone(item) {
      var index = this.ruleForm.phones.indexOf(item)
      if (index !== -1) {
        this.ruleForm.phones.splice(index, 1)
      }
    },
    addPhone() {
      if (this.ruleForm.phones.length > 4) return false
      this.ruleForm.phones.push({
        value: '',
        key: Date.now(),
      })
    },
    createGroup() {
      if (this.newgroupname === '') {
        return this.$message.error(this.$t('message.contact.info17'))
      }
      if (!/^[a-zA-Z0-9_\u4e00-\u9fa5\-\(\)\【\】]+$/.test(this.newgroupname)) {
        return this.$message.error(this.$t('message.contact.info9'))
      }
      const params = {
        module: 'contact',
        require: {
          action: 'updateGroup',
          data: {
            contact_list: [],
            group_name: this.newgroupname,
          },
          group_id: 'new',
        },
      }
      this.$axios.post(this.$apiData.mail, { req: serialize(params) }).then(({ result }) => {
        this.$emit('updateGroup')
        this.showCreateGroup = false
        this.newgroupname = ''
        this.getGroups()
        this.$store.commit('contact/SETTING_UPDATE_GROUP')
        setTimeout(() => {
          this.ruleForm.group.push(result.group_id)
        }, 100)
      })
    },
    cancelGroup() {
      this.showCreateGroup = false
      this.newgroupname = ''
    },
    showselect(flag) {
      if (!flag) {
        this.showCreateGroup = false
        this.newgroupname = ''
      }
    },
    handlePhoneInput(index) {
      this.ruleForm.phones[index].value = this.ruleForm.phones[index].value.replace(/[^0-9]/g, '')
    },
  },
}
</script>

<style lang="scss" scoped>
/*清空原来的多选框样式*/
.el-select-dropdown.is-multiple .el-select-dropdown__item:after{
    content:"";
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected:after{
    content:"";
}
/*参考el-checkbox实现checkbox样式*/
.el-select-dropdown.is-multiple .el-select-dropdown__item .checkbox{
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #fff;
    z-index: 1;
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected .checkbox{
    background-color:#d0161b;
    border-color:#d0161b;
}

/*参考el-select多选对号样式实现checkbox中对号的样式*/
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected .checkbox::after{
    position: absolute;
    top: -10px;
    font-family: element-icons;
    content: "\e6da";
    font-size: 12px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color:#fff;
}

/*设置置灰内容样式*/
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.is-disabled .checkbox{
    background-color:#f2f6fc;
    border-color:#dcdfe6;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.is-disabled .checkbox::after{
    color:#c0c4cc;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.is-disabled .label-name-box{
    color:#c0c4cc;
    font-weight:400;
}

.el-select-dropdown__item {
    display: flex;
    align-items: center;
}

.add-contact{
    padding: 10px 15px;
    flex: 1;
    .header{
      font-size: 18px;
      font-weight: bold;
      color: #1D1B1B;
    }
    .form{
      margin-top: 40px;
      padding-bottom: 1px;
      .addGroupForm{
         /deep/.el-form-item{
            margin-bottom: 8px;
        }
        .label-name{
          padding-top: 22px;
        }
        .el-row{
          margin-bottom: 0;
        }
        .btn-bottom{
          margin-top: 50px;
          margin-bottom: 30px;
        }
        /deep/.cardBottom .el-form-item__content{
          float: right;
        }
        /deep/.el-input__inner{
          height: 34px;
          line-height: 34px;
          color: #1d1b1b;
          background-color: #fbfbfb;
          border-color: #d6d6d6;
          padding: 0 10px;
          padding-right:55px ;
        }
      }
      .width422{
        width: 422px;
      }
      .text-color{
        color: #d0161b;
        cursor: pointer;
      }

      .icon{
        margin-left: 15px;
      }
      .icon-pos{
        position: absolute;
        right: -3px;
        top: 0;
      }
      .close{
        vertical-align: middle;
      }
    }
  }
/deep/.el-form-item__label {
    line-height: 10px !important;
}
.addnewgroup{
  border-bottom: 1px solid #D4D4D4;
  padding: 2px 20px;
  .addnewgroupitem{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input{
      // width: 200px;
    }
  }
}
.addTile{
  margin-bottom: 9px!important;
}
</style>
<style>
.selectGroup .el-select-dropdown__list{
    padding: 0!important;
}
</style>
