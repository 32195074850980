import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhelLocale from 'element-ui/lib/locale/lang/zh-CN'
import enelLocale from 'element-ui/lib/locale/lang/en'
import zhfLocale from 'element-ui/lib/locale/lang/zh-TW'
import zhLocal from './lang/zh-cn'
import enLocal from './lang/en-us'
import zhfLocal from './lang/zh-ft'

// import store from '@/store/index'

Vue.use(VueI18n)
const messages = {
  'simplified_chinese': {
    ...zhelLocale,
    message: {
      ...zhLocal,
    },
  },
  'english': {
    ...enelLocale,
    message: {
      ...enLocal,
    },
  },
  'traditional_chinese': {
    ...zhfLocale,
    message: {
      ...zhfLocal,
    },
  },
}
export const i18n = new VueI18n({
  locale: localStorage.getItem('lang'),
  fallbackLocale: 'simplified_chinese',
  silentFallbackWarn: true,
  messages,
})
